/**
 * @module HealthMonitorModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */
import angular from 'angular';

import { Item } from 'ajs/modules/data-model';
import { isEmpty } from 'underscore';

import {
    IHealthMonitor,
    IHealthMonitorDNS,
    IHealthMonitorExternal,
    IHealthMonitorHttp,
    IHealthMonitorRadius,
    IHealthMonitorSIP,
    IHealthMonitorTcp,
    IHealthMonitorUdp,
} from 'generated-types';

type THealthMonitorSubConfig = (/* eslint-disable @typescript-eslint/indent */
    IHealthMonitorTcp |
    IHealthMonitorHttp |
    IHealthMonitorExternal |
    IHealthMonitorUdp |
    IHealthMonitorDNS |
    IHealthMonitorSIP |
    IHealthMonitorRadius);

/**
 * @desc Health Monitor Item.
 * @author Ashish Verma
 */
export class HealthMonitor extends Item<IHealthMonitor> {
    /**
     * List of HMon type.
     */
    private static types = {
        HEALTH_MONITOR_HTTPS: 'HEALTH_MONITOR_HTTPS',
        HEALTH_MONITOR_DNS: 'HEALTH_MONITOR_DNS',
    };

    /**
     * Correspondence hash between HMon type and it's config property.
     */
    private static typeToConfigProp = {
        HEALTH_MONITOR_PING: '', // no fields needed
        HEALTH_MONITOR_TCP: 'tcp_monitor',
        HEALTH_MONITOR_UDP: 'udp_monitor',
        HEALTH_MONITOR_HTTP: 'http_monitor',
        HEALTH_MONITOR_HTTPS: 'https_monitor',
        HEALTH_MONITOR_DNS: 'dns_monitor',
        HEALTH_MONITOR_EXTERNAL: 'external_monitor',
        HEALTH_MONITOR_SIP: 'sip_monitor',
        HEALTH_MONITOR_RADIUS: 'radius_monitor',
    };

    constructor(args: {}) {
        const extendedArgs = {
            ...args,
            objectName: 'healthmonitor',
            windowElement: 'prof-healthmonitor-create',
            params: {
                include_name: true,
            },
        };

        super(extendedArgs);
    }

    /**
     * Returns true if any field is present in https_monitor#ssl_attributes, either an
     * 'ssl_profile_ref', 'pki_profile_ref', or 'ssl_key_and_certificate_ref'.
     */
    public hasSslAttributes(): boolean {
        const { https_monitor: healthMonitor } = this.getConfig();

        return this.isHttpsType() &&
                !isEmpty(healthMonitor.ssl_attributes) &&
                Object.values(healthMonitor.ssl_attributes)
                    .some(value => !isEmpty(value));
    }

    /**
     * Get sub config of selected type.
     */
    public get subConfig(): THealthMonitorSubConfig {
        const config = this.getConfig();
        const type = this.getType();

        return config[HealthMonitor.typeToConfigProp[type]];
    }

    /**
     * Removes fields set on https_monitor#ssl_attributes.
     */
    public clearSslAttributes(): void {
        if (!this.isHttpsType()) {
            return;
        }

        this.getConfig().https_monitor.ssl_attributes = {};
    }

    /**
     * Remove http/https authentication values.
     */
    public clearHttpAuthentication(): void {
        delete this.getConfig().authentication;
    }

    /**
     * Reset exact_http_request
     */
    public resetExactHttpRequest(): void {
        const httpSubConfig = this.subConfig as IHealthMonitorHttp;

        httpSubConfig.exact_http_request = false;
    }

    /**
     * Handles Health Monitor type change.
     * Only works on HM creation.
     */
    public onTypeChange(): void {
        this.clearIrrelevantMonitorSubObjects();
        this.resetMonitorConfigIfUnset();
    }

    /**
     * @override
     */
    public beforeEdit(): void {
        this.clearIrrelevantMonitorSubObjects();
        // Note: Following needed only because currently existing HTTPS Health Monitors may not
        // have the HTTPS_MONITOR sub-object, which may cause error in UI
        this.resetMonitorConfigIfUnset();

        const config = this.getConfig();

        const { markers } = config;

        if (!markers) {
            config.markers = [];
        }
    }

    /**
     * @override
     */
    public dataToSave(): IHealthMonitor {
        const config = angular.copy(this.getConfig());

        if (this.isDnsType() && 'monitor_port' in config && !config.is_federated) {
            delete config.is_federated;
        }

        const { markers } = config;

        if (markers) {
            // filter out RBAC entries with an empty key
            config.markers = markers.filter(({ key }) => key);

            // delete empty RABC label list
            if (markers.length === 0) {
                delete config.markers;
            }
        }

        return config;
    }

    /**
     * Returns Health Monitor type.
     */
    public getType(): string {
        return this.getConfig().type;
    }

    /**
     * Checks if Health Monitor item is HTTPS type.
     */
    public isHttpsType(): boolean {
        const { HEALTH_MONITOR_HTTPS } = HealthMonitor.types;

        return this.getType() === HEALTH_MONITOR_HTTPS;
    }

    /**
     * Checks if Health Monitor item is HTTPS type.
     */
    public isDnsType(): boolean {
        const { HEALTH_MONITOR_DNS } = HealthMonitor.types;

        return this.getType() === HEALTH_MONITOR_DNS;
    }

    /**
     * Clears all existing and non-relevant monitor sub-objects (typeConfigProps).
     */
    private clearIrrelevantMonitorSubObjects(): void {
        const config = this.getConfig();
        const propNameToPreserve = HealthMonitor.typeToConfigProp[config.type];

        /**
         * Removes propName if exists, and if is not propNameToPreserve.
         */
        function deleteMonitor(propName: string): void {
            if (!(propName in config)) {
                return;
            }

            if (propNameToPreserve !== propName) {
                delete config[propName];
            }
        }

        const typeConfigProps = Object.values(HealthMonitor.typeToConfigProp)
            .filter(property => property !== '');

        typeConfigProps.forEach(deleteMonitor);
    }

    /**
     * Initializes default or empty monitor object (of type typeConfigProps), if applicable,
     * and if doesn't exist.
     */
    private resetMonitorConfigIfUnset(): void {
        const config = this.getConfig();
        const monitorConfig = HealthMonitor.typeToConfigProp[config.type];

        // Note: Following only needed because resetMonitorConfigIfUnset_
        // is used in beforeEdit,otherwise resetMonitorConfigIfUnset_ would override
        // existing sub-object with empty one.
        if (config[monitorConfig]) {
            return;
        }

        if (monitorConfig) {
            config[monitorConfig] = this.getDefaultConfig()[monitorConfig] || {};
        }
    }
}

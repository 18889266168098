/**
 * @module SystemModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    BackupConfiguration,
    BackupConfigurationCollection,
    EmailConfigurationConfigItem,
    EMAIL_CONFIGURATION_CONFIG_ITEM_TOKEN,
    SystemConfig,
} from '.';

const systemModule = angular.module('avi/system');

const factories = [
    {
        dependency: BackupConfiguration,
        name: 'BackupConfiguration',
    },
    {
        dependency: BackupConfigurationCollection,
        name: 'BackupConfigurationCollection',
    },
    {
        dependency: SystemConfig,
        name: 'SystemConfig',
    },
    {
        dependency: EmailConfigurationConfigItem,
        name: EMAIL_CONFIGURATION_CONFIG_ITEM_TOKEN,
    },
];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(systemModule, 'factory', name, dependency);
});

const moduleName = 'ajs-legacy';
const componentName = 'dns-ntp-modal';
const prefix = `${moduleName}.${componentName}`;

export const updateSystemSettingsHeader = `${prefix}.updateSystemSettingsHeader`;
export const dnsNtpSettingsHeader = `${prefix}.dnsNtpSettingsHeader`;
export const dnsResolverInputLabel = `${prefix}.dnsResolverInputLabel`;
export const dnsSearchDomainInputLabel = `${prefix}.dnsSearchDomainInputLabel`;
export const ntpAuthenticationKeysHeader = `${prefix}.ntpAuthenticationKeysHeader`;
export const keyNumberInputLabel = `${prefix}.keyNumberInputLabel`;
export const algorithmInputLabel = `${prefix}.algorithmInputLabel`;
export const keyInputLabel = `${prefix}.keyInputLabel`;
export const removeNtpAuthenticationKeyBtnLabel = `${prefix}.removeNtpAuthenticationKeyBtnLabel`;
export const addNtpAuthenticationKeyLinkLabel = `${prefix}.addNtpAuthenticationKeyLinkLabel`;
export const ntpServersHeader = `${prefix}.ntpServersHeader`;
export const keyNumberInputPlaceholder = `${prefix}.keyNumberInputPlaceholder`;
export const serverInputLabel = `${prefix}.serverInputLabel`;
export const removeNtpServerBtnLabel = `${prefix}.removeNtpServerBtnLabel`;
export const addNtpServerLinkLabel = `${prefix}.addNtpServerLinkLabel`;
export const saveBtnLabel = `${prefix}.saveBtnLabel`;

export const ENGLISH = {
    [updateSystemSettingsHeader]: 'Update System Settings',
    [dnsNtpSettingsHeader]: 'DNS / NTP Settings',
    [dnsResolverInputLabel]: 'DNS Resolver(s)',
    [dnsSearchDomainInputLabel]: 'DNS Search Domain',
    [ntpAuthenticationKeysHeader]: 'NTP Authentication Keys',
    [keyNumberInputLabel]: 'Key Number',
    [algorithmInputLabel]: 'Algorithm',
    [keyInputLabel]: 'Key',
    [removeNtpAuthenticationKeyBtnLabel]: 'Remove NTP Authentication Key',
    [addNtpAuthenticationKeyLinkLabel]: '+ Add NTP Authentication Key',
    [ntpServersHeader]: 'NTP Servers',
    [keyNumberInputPlaceholder]: 'Select Key Number for NTP Authentication',
    [serverInputLabel]: 'Server',
    [removeNtpServerBtnLabel]: 'Remove NTP Server',
    [addNtpServerLinkLabel]: '+ Add NTP Server',
    [saveBtnLabel]: 'Save',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc directive
 * @name chartWithOverlaysTables
 * @restrict E
 */
//TODO rebuild @am
angular.module('aviApp').directive('chartWithOverlaysTables', ['alertGridConfig', 'eventGridConfig',
'Timeframe', 'EventCollection', 'AlertCollection', 'AnomalyCollection', 'eventsContext',
'getGridMetricFieldConfig',
function(alertGridConfig, eventGridConfig, Timeframe, EventCollection, AlertCollection,
AnomalyCollection, eventsContext, getGridMetricFieldConfig) {
    function link(scope) {
        // Set to type to show
        scope.activeGrid = null;

        // Default to showing only those around the point
        scope.tableExpanded = false;

        // If the item is a server, we want to use the pool ID. Otherwise, just use the item ID.
        const itemId = scope.item.poolId || scope.item.id;

        const configEventsCollection = new EventCollection({
            params: {
                filter: [
                    `co(all,"${itemId}")`,
                    'ne(internal,EVENT_INTERNAL)',
                    `co(event_pages,${eventsContext()})`,
                    'eq(module,CONFIG)',
                ],
            },
            sortBy: '-report_timestamp',
        });

        const systemEventsCollection = new EventCollection({
            params: {
                filter: [
                    `co(all,"${itemId}")`,
                    'ne(internal,EVENT_INTERNAL)',
                    `co(event_pages,${eventsContext()})`,
                    'ne(module,CONFIG)'],
            },
            sortBy: '-report_timestamp',
        });

        const alertsCollection = new AlertCollection({
            params: {
                'related_refs.contains': itemId,
                'event_pages.contains': eventsContext(),
            },
            sortBy: '-timestamp',
        });

        const anomalyCollection = new AnomalyCollection({
            itemId: scope.item.id,
            itemPoolId: scope.item.poolId, //pool's server only
            itemObjectName: scope.item.objectName,
            defaultDataFields: ['config', 'anomaly_metric'],
        });

        const collections = {
            configEvents: configEventsCollection,
            systemEvents: systemEventsCollection,
            alerts: alertsCollection,
            anomalies: anomalyCollection,
        };

        function showSelectedPoint() {
            const { selectedDot } = scope.config;

            if (selectedDot.type) {
                const collection = collections[selectedDot.type];

                scope.activeGrid = selectedDot.type;
                // Reset the collection to show loading spinner not old data
                collection.emptyData();

                // Show the grid now that a activeGrid is bound to it
                // Load the data around the dot
                if (selectedDot.type === 'anomalies') {
                    const selectedTimeframe = Timeframe.selected(),
                        // check two ahead and behind for anomalies
                        delta = selectedTimeframe.step * 2,
                        start = moment(selectedDot.dot.timestamp),
                        stop = moment(selectedDot.dot.timestamp);

                    collection.highlightedMetric = selectedDot.series_name;

                    collection
                        .loadRange(start.subtract(delta, 'seconds'), stop.add(delta, 'seconds'));
                } else {
                    // Alerts and both types of events already are loaded as a range
                    // ask for all of the ones in that range
                    collection
                        .loadRange(selectedDot.dot.start, selectedDot.dot.end);
                }
            }
        }

        scope.$watchCollection('config.selectedDot', function() {
            scope.tableExpanded = false;
            showSelectedPoint();
        });

        const sharedAnomalyColumns = {
            entity: {
                name: 'entity_name',
                title: 'Entity',
                template: '{{ ::row.data.entity_name }}',
            },
            metricValue: getGridMetricFieldConfig({
                require: 'anomaly_metric',
                title: 'Metric Value',
            }),
            anomalyValue: {
                name: 'anomalous_value',
                title: 'Anomalous Value',
                template: '{{ ::row.data.value }}',
            },
        };

        //TODO figure out why grid config brakes on reuse, fix and remove this @am
        scope.$watch('activeGrid', function() {
            if (!scope.item) {
                return;
            }

            scope.gridConfigs = {
                systemEvents: eventGridConfig(collections['systemEvents']),
                configEvents: eventGridConfig(collections['configEvents']),
                anomalies: {
                    fields: [{
                        name: 'timestamp',
                        title: 'Timestamp',
                        template: '{{ ::row.data.timestamp | prettyTimeShort }}',
                    }, {
                        name: 'title',
                        title: 'Type',
                        template: '{{ ::row.data.title }}',
                    },
                    sharedAnomalyColumns.entity,
                    {
                        name: 'obj_id_type',
                        title: 'Entity Type',
                        template: '{{ ::row.data.obj_id_type | objectTypeFilter }}',
                    },
                    sharedAnomalyColumns.metricValue,
                    sharedAnomalyColumns.anomalyValue,
                    {
                        name: 'predict_interval',
                        title: 'Prediction Interval',
                        template: '{{ ::row.data.prediction_interval_low + ' +
                            '" &mdash; "+ row.data.prediction_interval_high }}',
                    }],
                    collection: collections['anomalies'],
                    rowClass(row) {
                        const mainAnomalySeriesName = scope.config.selectedDot &&
                            scope.config.selectedDot.series_name;

                        let className;

                        if (mainAnomalySeriesName) {
                            className =
                                row.data.metricId === mainAnomalySeriesName &&
                                (this.config.collection.itemPoolId ||
                                row.data.obj_id_type.toLowerCase() ===
                                this.config.collection.itemObjectName.toLowerCase()) ?
                                    'main-anomaly' : '';
                        }

                        return className;
                    },
                    layout: {
                        hideDisplaying: true,
                        hideSearch: true,
                        hideEditColumns: true,
                    },
                },
                alerts: alertGridConfig(collections['alerts']),
            };

            //If active grid is of systemEvents type then remove user column
            if (scope.activeGrid === 'systemEvents') {
                const { systemEvents: sysEventsGridConfig } = scope.gridConfigs;
                const { fields } = sysEventsGridConfig;

                sysEventsGridConfig.fields = fields.filter(({ name }) => name !== 'user');
            }

            // If scope.smallChart is defined,
            // remove unnecessary fields from the charts table.
            if (!_.isUndefined(scope.smallChart)) {
                scope.gridConfigs.systemEvents.fields.splice(1, 2);
                scope.gridConfigs.configEvents.fields.splice(1, 2);
                scope.gridConfigs.anomalies.fields =
                [
                    sharedAnomalyColumns.entity,
                    sharedAnomalyColumns.metricValue,
                    sharedAnomalyColumns.anomalyValue,
                ];
            }
        });

        // Expand and shrink are not expected to be called for anomalies
        scope.expandTable = function() {
            const selectedTimeframe = Timeframe.selected();

            scope.tableExpanded = true;

            collections[scope.config.selectedDot.type]
                .loadDuration(selectedTimeframe.step, selectedTimeframe.limit);
        };

        scope.shrinkTable = function() {
            scope.tableExpanded = false;
            showSelectedPoint();
        };

        scope.hideTable = function() {
            const { selectedDot } = scope.config;

            //params names are different for different collection types, let's reset all of them
            const resetParams = {
                ts_duration: undefined,
                duration: undefined,
                ts_start: undefined,
                ts_end: undefined,
                start: undefined,
                stop: undefined,
                end: undefined,
            };

            // Hide the grid
            scope.activeGrid = null;

            //resets the collection
            if (selectedDot.type) {
                collections[selectedDot.type].emptyData();
                collections[selectedDot.type].setParams(resetParams);
            }

            // Reset the active dot
            selectedDot.type = null;
            selectedDot.dot = null;
            selectedDot.series_name = undefined;
        };

        scope.$watch('config.active', scope.hideTable);

        scope.$on('$destroy', function() {
            scope.hideTable();

            _.each(collections, function(collection) {
                collection.destroy();
            });
        });
    }

    return {
        restrict: 'E',
        scope: {
            config: '=',
            item: '=',
            smallChart: '=',
        },
        templateUrl: 'src/views/components/chart-with-overlays-tables.html',
        link,
    };
}]);

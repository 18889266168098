/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const userCollectionFactory = (Collection, User) => {
    /**
     * @constructor
     * @memberOf module:avi/accounts
     * @extends module:avi/dataModel.Collection
     * @desc
     *     List of Users.
     *
     * @see {@link module:avi/accounts.User}
     * @author Aravindh Nagarajan
     */
    class UserCollection extends Collection {
        constructor(args = {}) {
            const { params = {}, ...restArgs } = args;
            const extendedArgs = {
                ...restArgs,
                params: {
                    include_activity: true,
                    ...params,
                },
            };

            super(extendedArgs);
        }
    }

    const dataSources = {
        list: {
            source: 'ListCollDataSource',
            transformer: 'ListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
        'keystone-auth': {
            source: 'KeystoneIsEnabledCollDataSource',
            transformer: 'RevisedDataTransformer',
            transport: 'KeystoneIsEnabledDataTransport',
            fields: ['keystone'],
            dependsOn: 'config',
        },
    };

    Object.assign(UserCollection.prototype, {
        objectName_: 'user',
        itemClass_: User,
        windowElement_: 'user-modal',
        isStatic_: false,
        defaultDataSources_: ['list', 'keystone-auth'],
        allDataSources_: dataSources,
        serverDefaultsOverride_: {
            require_password_confirmation: false,
            is_active: true,
        },
    });

    return UserCollection;
};

userCollectionFactory.$inject = [
    'Collection',
    'User',
];

angular.module('avi/accounts').factory('UserCollection', userCollectionFactory);

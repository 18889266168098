/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../../../less/pages/application/pool-server-summary.less';

angular.module('aviApp').controller('PoolServerListController', [
'$scope', 'ServerCollection', 'Server', '$templateCache', 'getGridMetricFieldConfig',
function($scope, ServerCollection, Server, $templateCache, getGridMetricFieldConfig) {
    const gridMetricFields = [
        {
            require: 'l7_server.avg_complete_responses',
            title: 'RPS',
            fullTitle: 'Requests per second',
            visibility: 'd',
        }, {
            require: 'l4_server.max_open_conns',
            visibility: 'd',
        }, {
            require: 'l4_server.avg_bandwidth',
            visibility: 'd',
        },
        'l4_server.avg_total_rtt',
        'l7_server.avg_error_responses',
        'l7_server.pct_response_errors',
    ];

    const serverCollection = new ServerCollection({ poolId: $scope.Pool.id });

    /**
     * Creates a hash from array of {@link Server} items. Used by {@link collectionGrid}
     * multiple actions.
     * @param {Server[]} servers
     * @returns {{string:boolean}} Where string is a {@link Server.id}.
     * @inner
     */
    function selectedServersHash(servers) {
        return _.reduce(servers, function(base, server) {
            base[server.id] = true;

            return base;
        }, {});
    }

    $scope.serversGrid = buildGridConfig();
    $scope.gracefulDisableTimeout = 1;

    /**
     * Returns fields for the gridConfig based on the ipGroupOrEPG boolean.
     * @param  {boolean} ipGroupOrEPG - True if server created by IP Group or EPG, false otherwise.
     * @return {Object[]} Array of fields to display in grid.
     */
    function getFields(ipGroupOrEPG) {
        const fields = [{
            name: 'hostname',
            title: 'Server Name',
            // ng-if or ui-sref gets evaluated before id assignment
            template:
                `<a ui-sref="^.server-detail.analytics({serverId: '{{ row.id }}'})">
                    {{ row.getConfig()['hostname'] }}
                </a>`,
            visibility: 'm',
        }, {
            name: 'data.config.ip.addr',
            title: 'IP Address',
            visibility: 'm',
        }, {
            name: 'description',
            title: 'Description',
            template: '{{ row.getConfig().description }}',
        }, {
            name: 'enabled',
            title: 'Status',
            template: '{{row.data.config.enabled ? "Enabled" : "Disabled"}}',
            visibility: 'm',
        }, {
            require: 'health,runtime',
            name: 'health',
            title: 'Health',
            template: '<avi-healthscore item="row" stop-async-on-hide="true"></avi-healthscore>',
            visibility: 'm',
        }];

        if (!ipGroupOrEPG) {
            fields.unshift({
                name: 'port',
                title: 'Port',
                template: '{{row.data.config.port || "Default Port: " + ' +
                    ' row.data.config.default_server_port}}',
                visibility: 'm',
            });

            fields.push({
                name: 'data.config.ratio',
                title: 'Ratio',
                visibility: 'm',
            },
            ...gridMetricFields.map(seriesName => getGridMetricFieldConfig(seriesName)));
        }

        return fields;
    }

    /**
     * Returns the multipleactions array for the grid based on ipGroupOrEPG boolean.
     * @param  {boolean} ipGroupOrEPG - True if server created by IP Group or EPG, false otherwise.
     * @return {Object[]} Array of multipleactions in grid.
     */
    function getMultipleactions(ipGroupOrEPG) {
        const multipleactions = [{
            title: 'Enable',
            className: 'sel-enable-server icon-check',
            disabled: servers => !$scope.Pool.isEditable() ||
                _.every(servers, server => server.getConfig()['enabled']),
            do: servers => {
                const
                    selectedHash = selectedServersHash(servers),
                    defaultServerPort = $scope.Pool.getDefaultServerPort(),
                    { servers: serversList } = $scope.Pool.getConfig();

                _.each(serversList, server => {
                    const serverId = Server.getServerUuid(server, defaultServerPort);

                    if (serverId in selectedHash) {
                        server.enabled = true;
                    }
                });

                $scope.Pool.save();

                return true;
            },
        }, {
            title: 'Disable',
            className: 'sel-disable-server icon-minus',
            disabled: servers => !$scope.Pool.isEditable() ||
                    _.every(servers, server => !server.getConfig()['enabled']),
            do: servers => {
                const
                    selectedHash = selectedServersHash(servers),
                    defaultServerPort = $scope.Pool.getDefaultServerPort(),
                    config = $scope.Pool.getConfig(),
                    { servers: serverList } = config;

                _.each(serverList, server => {
                    const serverId = Server.getServerUuid(server, defaultServerPort);

                    if (serverId in selectedHash) {
                        server.enabled = false;
                    }
                });

                $scope.Pool.save();

                return true;
            },
        }];

        if (!ipGroupOrEPG) {
            multipleactions.unshift({
                title: 'Delete',
                disabled: () => !$scope.Pool.isEditable(),
                do: servers => {
                    //servers - array of Items while in Pool.config we keep an array of objects
                    const
                        poolConfig = $scope.Pool.getConfig(),
                        selectedHash = selectedServersHash(servers),
                        defaultServerPort = $scope.Pool.getDefaultServerPort();

                    poolConfig.servers = _.reject(poolConfig.servers, server => {
                        const serverId = Server.getServerUuid(server, defaultServerPort);

                        return serverId in selectedHash;
                    });

                    $scope.Pool.save();

                    return true;
                },
            });
        }

        return multipleactions;
    }

    /**
     * Returns a gridConfig object.
     * @return {Object} gridConfig for Servers Collection.
     */
    function buildGridConfig() {
        const
            ipGroupOrEPG = $scope.Pool.autoPopulatedServers(),
            gridConfig = {
                id: 'pool-server',
                collection: serverCollection,
                layout: {
                    hideSearch: true,
                    lengthAsTotal: true,
                },
                checkboxDisable() {
                    return !$scope.Pool.isEditable();
                },
            };

        gridConfig.fields = getFields(ipGroupOrEPG);

        if (!$scope.Pool.hasAutoscaleGroups()) {
            gridConfig.multipleactions = getMultipleactions(ipGroupOrEPG);
        }

        return gridConfig;
    }

    $scope.Pool.addLoad(['health', 'alert']);

    function itemChangeHandler() {
        serverCollection.load();
    }

    $scope.Pool.on('itemChange', itemChangeHandler);

    $scope.$on('$destroy', () => {
        serverCollection.destroy();
        $scope.Pool.async.stop(true);
        $scope.Pool.unbind('itemChange', itemChangeHandler);
    });
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './dns-policy-rule-modal.less';

/**
 * @ngdoc component
 * @name dnsPolicyRuleModal
 * @description Modal for configuring a DNS Policy rule.
 * @param {DnsPolicyRuleConfig} rule - DNS Policy rule. DnsPolicyRuleConfig ConfigItem.
 * @param {Function} onSubmit - Called when the user saves the rule.
 * @param {Function} closeModal - Called to close the modal.
 */
class DnsPolicyRuleModalController {
    constructor(systemInfoService, Regex) {
        this.errors = null;
        this.systemInfoService = systemInfoService;
        this.Regex = Regex;

        this.matches = [{
            title: 'Client IP Address',
            value: 'client_ip_address',
        }, {
            title: 'Protocol',
            value: 'protocol',
        }, {
            title: 'Query Name',
            value: 'query_name',
        }, {
            title: 'Query Type',
            value: 'query_type',
        }, {
            title: 'Geographical Location',
            value: 'geo_location',
        }];

        //applicable to GSLB configurations only
        this.gslbSiteAction_ = {
            title: 'GSLB Site',
            value: 'gslb_site_selection',
        };

        this.actions = [{
            title: 'Allow/Drop Query',
            value: 'allow',
        }, {
            title: 'Query Response',
            value: 'response',
        }, {
            title: 'Pool Switching',
            value: 'pool_switching',
        }, {
            title: 'Rate Limit',
            value: 'dns_rate_limiter',
        }];

        this.newMatch = null;
        this.newAction = null;
        this.hiddenMatches = [];
        this.hiddenActions = [];
    }

    $onInit() {
        if (this.systemInfoService.haveGSLBConfig()) {
            this.actions.push(this.gslbSiteAction_);
        }

        this._setHiddenMatches();
        this._setHiddenActions();
    }

    /**
     * Sets the hidden matches in the Matches dropdown, since we want to hide matches that have
     * already been added.
     */
    _setHiddenMatches() {
        this.hiddenMatches = _.pluck(
            this.matches.filter(match => this.rule.hasMatch(match.value)), 'value',
        );
    }

    /**
     * Sets the hidden actions in the Actions dropdown, since we want to hide actions that have
     * already been added.
     */
    _setHiddenActions() {
        this.hiddenActions = _.pluck(
            this.actions.filter(action => this.rule.hasAction(action.value)), 'value',
        );
    }

    /**
     * Called when a match is selected from the dropdown. Adds the configuration component for
     * that match.
     */
    handleAddNewMatch() {
        this.rule.addMatch(this.newMatch);
        this._setHiddenMatches();
        this.newMatch = null;
    }

    /**
     * Removes a match configuration component.
     * @param {string} matchPropertyName
     */
    removeMatch(matchPropertyName) {
        this.rule.removeMatch(matchPropertyName);
        this._setHiddenMatches();
    }

    /**
     * Called when an action is selected from the dropdown. Adds the configuration template for
     * that action.
     */
    handleAddNewAction() {
        this.rule.addAction(this.newAction);
        this._setHiddenActions();
        this.newAction = null;
    }

    /**
     * Removes an action configuration component.
     * @param {string} actionType
     */
    removeAction(actionType) {
        this.rule.removeAction(actionType);
        this._setHiddenActions();
    }

    /**
     * Called when the Submit button is clicked. Saves the rule.
     */
    handleSubmit() {
        this.errors = null;
        this.onSubmit({ rule: this.rule })
            .then(() => this.closeModal())
            .catch(error => this.errors = error);
    }
}

DnsPolicyRuleModalController.$inject = [
        'systemInfoService',
        'Regex',
];

angular.module('aviApp').component('dnsPolicyRuleModal', {
    controller: DnsPolicyRuleModalController,
    bindings: {
        rule: '<',
        onSubmit: '&',
        closeModal: '&',
    },
    templateUrl: 'src/components/applications/virtualservice/virtualservice-dns-policy/' +
            'dns-policy-rule-modal/dns-policy-rule-modal.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './ssl-certificate-export.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  sslCertificateExport
 * @description  Displays the configured Key and Certificate to be copied/exported.
 * @param {string} uuid - UUID of the Certificate.
 * @param {function} closeModal - Closes modal.
 */
class SslCertificateExportController {
    constructor(
        Base,
        clipboardService,
        l10nService,
    ) {
        this.base_ = new Base();
        this.clipboardService_ = clipboardService;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this.busy = true;
        this.errors = null;

        this.base_.request('GET', `/api/sslkeyandcertificate/${this.uuid}?export_key=true`, null,
            null, 'sslcertificateexport')
            .then(rsp => {
                this.config = rsp.data;
            }).catch(rsp => {
                this.errors = rsp.data;
            }).finally(() => {
                this.busy = false;
            });
    }

    $onDestroy() {
        this.base_.cancelRequests('sslcertificateexport');
    }

    copyToClipboard(message, target) {
        this.clipboardService_.copy(message, target);
    }

    /**
     * Highlights the textarea text on focus.
     * @param  {Event} $event - jQuery event.
     */
    onFocus($event) {
        $event.target.select();
    }
}

SslCertificateExportController.$inject = [
    'Base',
    'clipboardService',
    'l10nService',
];

angular.module('aviApp').component('sslCertificateExport', {
    bindings: {
        uuid: '@',
        closeModal: '&',
    },
    controller: SslCertificateExportController,
    templateUrl: 'src/components/modals/templates/security/ssl-tls-certificates/' +
            'ssl-certificate-export/ssl-certificate-export.html',
});

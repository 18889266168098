/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */
import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { IExpanderAction } from 'ng/shared/components/expander-with-actions';
import { WafPSMLocationConfigItem } from 'ajs/modules/waf';
import './waf-policy-psm-location.less';

/**
 * @ngdoc component
 * @name wafPolicyPsmLocation
 * @module waf/wafPolicyPsmLocation
 * @desc Component for displaying a WAF Policy PSM Location in the WAF Policy PSM Group modal.
 * @param location - WAF PSM Location
 * @param onDelete - Called when the user clicks the delete rule button.
 * @param onEdit - Called when the user clicks the edit rule button.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-location',
    templateUrl: './waf-policy-psm-location.component.html',
})
export class WafPolicyPsmLocationComponent {
    @Input() public location: WafPSMLocationConfigItem;
    @Input() public preventEdit: boolean;
    @Input() public locationMatches: number;

    @Output() public onDelete: EventEmitter<void> = new EventEmitter();
    @Output() public onEdit: EventEmitter<void> = new EventEmitter();

    public expanderActions: IExpanderAction[];

    /**
     * @override
     */
    public ngOnInit(): void {
        this.expanderActions = [
            {
                confirmationButtonProps: {
                    actions: [
                        {
                            label: 'Delete',
                            onClick: (): void => this.handleDelete(),
                        },
                    ],
                    confirmationButtonClassName: 'avi-btn avi-btn-danger',
                },
                confirmationMessage: `Are you sure you want to delete ${this.location.getName()}?`,
                iconClassName: 'sl-icon-trash',
                title: 'Delete',
            },
            {
                iconClassName: 'sl-icon-note',
                onClick: (): void => this.handleEdit(),
                title: 'Edit',
            },
        ];
    }

    /**
     * Click handler for deleting a PSM location.
     */
    public handleDelete(): void {
        this.onDelete.emit();
    }

    /**
     * Click handler for editing a PSM location.
     */
    public handleEdit(): void {
        this.onEdit.emit();
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './AnalyticsProfileController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class AnalyticsProfileController {
    constructor(
        CRUDGridConfig,
        AnalyticsProfileCollection,
        l10nService,
    ) {
        this.collection_ = new AnalyticsProfileCollection();
        /**
        * Get keys from source bundles for template usage.
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);

        const { objectName } = this.collection_;

        const config = {
            id: `${objectName}-list-page`,
            collection: this.collection_,
            fields: [{
                name: 'data.config.name',
                title: l10nService.getMessage(l10nKeys.columnTitleName),
                sortBy: 'name',
            }],
            singleactions: [{
                title: l10nService.getMessage(l10nKeys.actionBtnEdit),
                class: 'icon-pencil-4',
                hidden: row => !row.isEditable() && !row.isClonable(),
                do(row) {
                    row.isEditable() ? row.edit() : this.config.collection.clone(row);
                },
            }],
        };

        this.gridConfig = CRUDGridConfig(config);
    }

    $onDestroy() {
        this.collection_.destroy();
    }
}

AnalyticsProfileController.$inject = [
    'CRUDGridConfig',
    'AnalyticsProfileCollection',
    'l10nService',
];

angular.module('aviApp').controller('AnalyticsProfileController', AnalyticsProfileController);

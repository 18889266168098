const moduleName = 'ajs-legacy';
const componentName = 'persistence-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const serverHmDownRecoveryInputLabel = `${prefix}.serverHmDownRecoveryInputLabel`;
export const immediateOption = `${prefix}.immediateOption`;
export const neverOption = `${prefix}.neverOption`;
export const persistenceTypeInputLabel = `${prefix}.persistenceTypeInputLabel`;
export const ipPersistenceTimeoutInputLabel = `${prefix}.ipPersistenceTimeoutInputLabel`;
export const ipPersistenceTimeoutInputPlaceholder = `${prefix}.ipPersistenceTimeoutInputPlaceholder`;
export const ipMaskInputLabel = `${prefix}.ipMaskInputLabel`;
export const persistenceHeaderNameInputLabel = `${prefix}.persistenceHeaderNameInputLabel`;
export const cookieNameInputLabel = `${prefix}.cookieNameInputLabel`;
export const timeoutInputLabel = `${prefix}.timeoutInputLabel`;
export const timeoutInputPlaceholder = `${prefix}.timeoutInputPlaceholder`;
export const minutesInnerText = `${prefix}.minutesInnerText`;
export const alwaysSendCookieInputLabel = `${prefix}.alwaysSendCookieInputLabel`;
export const appCookieNameInputLabel = `${prefix}.appCookieNameInputLabel`;
export const isFederatedInputLabel = `${prefix}.isFederatedInputLabel`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit Persistence Profile: {0}',
    [modalHeaderNew]: 'New Persistence Profile: {0}',
    [nameInputLabel]: 'Name',
    [serverHmDownRecoveryInputLabel]: 'Select New Server When Persistent Server Down',
    [immediateOption]: 'Immediate',
    [neverOption]: 'Never',
    [persistenceTypeInputLabel]: 'Type',
    [ipPersistenceTimeoutInputLabel]: 'Persistence Timeout',
    [ipPersistenceTimeoutInputPlaceholder]: 'Use Session Persistence',
    [ipMaskInputLabel]: 'IP Mask',
    [persistenceHeaderNameInputLabel]: 'Header Name',
    [cookieNameInputLabel]: 'HTTP Cookie Name',
    [timeoutInputLabel]: 'Persistence Timeout',
    [timeoutInputPlaceholder]: 'Use Session Persistence',
    [minutesInnerText]: 'Minutes',
    [alwaysSendCookieInputLabel]: 'Always Send',
    [appCookieNameInputLabel]: 'Application Cookie Name',
    [isFederatedInputLabel]: 'Is Federated',
    [descriptionInputLabel]: 'Description',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'authentication-rule-modal';
const prefix = `${moduleName}.${componentName}`;

export const editAuthenticationRuleHeader = `${prefix}.editAuthenticationRuleHeader`;
export const newAuthenticationRuleHeader = `${prefix}.newAuthenticationRuleHeader`;
export const generalTabTitle = `${prefix}.generalTabTitle`;
export const ruleEnableLabel = `${prefix}.ruleEnableLabel`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const matchTabTitle = `${prefix}.matchTabTitle`;
export const actionTabTitle = `${prefix}.actionTabTitle`;
export const actionInputLabel = `${prefix}.actionInputLabel`;
export const actionInputPlaceholder = `${prefix}.actionInputPlaceholder`;

export const ENGLISH = {
    [editAuthenticationRuleHeader]: 'Edit Authentication Rule: {0}',
    [newAuthenticationRuleHeader]: 'New Authentication Rule: {0}',
    [generalTabTitle]: 'General',
    [ruleEnableLabel]: 'Rule {0}',
    [nameInputLabel]: 'Name',
    [matchTabTitle]: 'Match',
    [actionTabTitle]: 'Action',
    [actionInputLabel]: 'Action',
    [actionInputPlaceholder]: 'Select Authentication action',
};

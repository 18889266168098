/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'virtualservice-traffic-capture-config';
const prefix = `${moduleName}.${componentName}`;

export const trafficCaptureHeader = `${prefix}.trafficCaptureHeader`;
export const virtualServiceInputLabel = `${prefix}.virtualServiceInputLabel`;
export const createVirtualServiceButtonLabel = `${prefix}.createVirtualServiceButtonLabel`;
export const virtualServiceInputPlaceholder = `${prefix}.virtualServiceInputPlaceholder`;
export const filterToCaptureHeader = `${prefix}.filterToCaptureHeader`;
export const allTrafficRadioInputLabel = `${prefix}.allTrafficRadioInputLabel`;
export const clientIpRangeSubnetMaskRadioInputLabel = `${prefix}.clientIpRangeSubnetMaskRadioInputLabel`;
export const debugIpPlaceholder = `${prefix}.debugIpPlaceholder`;
export const numberPacketsPerCoreInputLabel = `${prefix}.numberPacketsPerCoreInputLabel`;
export const durationInputLabel = `${prefix}.durationInputLabel`;
export const packetsInputInnerText = `${prefix}.packetsInputInnerText`;
export const minutesInputInnerText = `${prefix}.minutesInputInnerText`;
export const packetsSizeInputLabel = `${prefix}.packetsSizeInputLabel`;
export const packetsSizeInputPlaceholder = `${prefix}.packetsSizeInputPlaceholder`;
export const bytesInputInnerText = `${prefix}.bytesInputInnerText`;
export const advancedSettingsHeader = `${prefix}.advancedSettingsHeader`;
export const packetFlowsToCaptureLabel = `${prefix}.packetFlowsToCaptureLabel`;
export const healthMonitorAndDataRadioInputLabel = `${prefix}.healthMonitorAndDataRadioInputLabel`;
export const healthMonitorOnlyRadioInputLabel = `${prefix}.healthMonitorOnlyRadioInputLabel`;
export const dataOnlyRadioInputLabel = `${prefix}.dataOnlyRadioInputLabel`;
export const captureSessionKeyCheckboxLabel = `${prefix}.captureSessionKeyCheckboxLabel`;
export const startCaptureButtonLabel = `${prefix}.startCaptureButtonLabel`;

export const ENGLISH = {
    [trafficCaptureHeader]: 'Traffic Capture: {0}',
    [virtualServiceInputLabel]: 'Select Virtual Service',
    [createVirtualServiceButtonLabel]: 'Create Virtual Service',
    [virtualServiceInputPlaceholder]: 'Select a Virtual Service',
    [filterToCaptureHeader]: 'Filter to Capture',
    [allTrafficRadioInputLabel]: 'All Traffic',
    [clientIpRangeSubnetMaskRadioInputLabel]: 'Choose Client IP, IP Range, Subnet Mask',
    [debugIpPlaceholder]: 'IP Address, IP Range, Subnet Mask',
    [numberPacketsPerCoreInputLabel]: 'Number of Packets per Core',
    [durationInputLabel]: 'Duration',
    [packetsInputInnerText]: 'Packets',
    [minutesInputInnerText]: 'Minutes',
    [packetsSizeInputLabel]: 'Size of Packets',
    [packetsSizeInputPlaceholder]: 'Packet Size',
    [bytesInputInnerText]: 'Bytes',
    [advancedSettingsHeader]: 'Advanced Settings',
    [packetFlowsToCaptureLabel]: 'Packet flows to capture',
    [healthMonitorAndDataRadioInputLabel]: 'Health monitor and data',
    [healthMonitorOnlyRadioInputLabel]: 'Health monitor only',
    [dataOnlyRadioInputLabel]: 'Data only',
    [captureSessionKeyCheckboxLabel]: 'Capture Session Key',
    [startCaptureButtonLabel]: 'Start Capture',
};

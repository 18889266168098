/**
 * @module SystemModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { Item } from 'ajs/modules/data-model';
import { IBackupConfiguration } from 'generated-types';

/**
 * @desc Item for backup configuration.
 * @author Zhiqian Liu
 */
export class BackupConfiguration extends Item<IBackupConfiguration> {
    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'backupconfiguration',
        };

        super(extendedArgs);
    }

    /**
     * Getter for config#backup_passphrase.
     */
    public get backupPassphrase(): string {
        const config = this.getConfig();

        return config.backup_passphrase;
    }

    /**
     * Setter for config#backup_passphrase.
     */
    public set backupPassphrase(passphrase: string) {
        const config = this.getConfig();

        config.backup_passphrase = passphrase;
    }

    /**
     * Add backup passphrase data by making patch call.
     */
    public addBackupPassphrase(): ng.IPromise<any> {
        const { backupPassphrase } = this;

        return this.patch({ add: { backup_passphrase: backupPassphrase } });
    }
}

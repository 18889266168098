/** @module GslbModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { LimitedListCollDataSource } from 'ajs/modules/data-model';
import { GSLBPoolMemberCollection } from './gslb-pool-member.collection.factory';

/**
 * @description
 *
 *    Flat list of GslbService GslbPool members. Build from scratch every time since there are
 *    no unique uuids.
 *
 * @author Alex Malitsky, Ram Pal
 */
export class GSLBServicePoolMembersInventoryCollDataSource extends LimitedListCollDataSource {
    public owner_: GSLBPoolMemberCollection;
    public params_: any;

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public getRequestParams_(): any {
        const request = super.getRequestParams_(this.params_);

        // eslint-disable-next-line no-underscore-dangle
        request.objectName_ = [
            'gslbservice-inventory',
            this.owner_.gslbServiceId,
        ];

        return request;
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { WafPolicyConfirmChangeCrsVersionComponent } from 'ng/modules/waf';
import { DIALOG_SERVICE_TOKEN } from 'ng/modules/core';
import * as l10n from './waf-policy-modal.l10n';
import './waf-policy-modal.less';

const wafEnforcedModeEnumValue = 'WAF_MODE_ENFORCEMENT';
const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const WAF_POLICY_CONFIRM_CHANGE_CRS_VERSION_DIALOG_ID = 'waf-policy-confirm-change-crs-version';

/**
 * @memberOf module:aviApp
 * @typedef {Object} ICrsUpdate
 * @property {string} name
 * @property {string} url
 * @property {string} release_date
 * @property {string} version
 */

/** @alias wafPolicyModal **/
class WafPolicyModalController {
    constructor(
        Regex,
        WafProfileCollection,
        schemaService,
        latestWafCrsVersionService,
        WafCrsCollection,
        $q,
        AviModal,
        dropDownUtils,
        WafPolicyPsmGroupCollection,
        l10nService,
        dialogService,
    ) {
        this.Regex = Regex;
        this.schemaService = schemaService;
        this.$q_ = $q;
        this.AviModal_ = AviModal;
        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        this.wafModeEnumValues = schemaService.getEnumValues('WafMode');
        this.paranoiaLevelOptions = dropDownUtils.getEnumDropdownOptions('WafParanoiaLevel');
        WafPolicyModalController.sortParanoiaLevelOptions(this.paranoiaLevelOptions);
        this.wafProfileCollection = new WafProfileCollection();
        this.wafCrsCollection = new WafCrsCollection({
            params: {
                fields: 'name,tenant_ref',
            },
        });

        /**
         * WAF PSM group collection instance.
         */
        this.wafPolicyPsmGroupCollection = new WafPolicyPsmGroupCollection();

        /**
         * @protected
        */
        this.latestWafCrsVersionService_ = latestWafCrsVersionService;

        /**
         * @type {string|undefined}
         */
        this.wafCrsRef = undefined;

        /**
         * @type {boolean}
         */
        this.saveUncommitted = true;

        /**
         * @type {boolean}
         */
        this.newCrsIsAvailableOnController = true;

        /**
         * @type {ICrsUpdate}
        */
        this.newCrsInfo = null;

        /**
         * @type {DialogService}
         */
        this.dialogService_ = dialogService;
    }

    /**
     * Method to sort paranoiaLevelOptions
     * @param {DropdownOption[]} options - Array containing options
     */
    static sortParanoiaLevelOptions(options) {
        options.sort((current, next) => {
            return WafPolicyModalController.getParanoiaLevelPriority(current.label) -
                WafPolicyModalController.getParanoiaLevelPriority(next.label);
        });
    }

    /**
     * Method to get priority from option
     * @param {string} label - Label of paranoia level option
     * returns parsed number
     * @return {number}
     */
    static getParanoiaLevelPriority(label) {
        return parseInt(label.replace(/\D+/g, ''), 10);
    }

    /**
     * Sets this.wafCrsRef, which is related to updating the CRS Version. This field is tied to
     * the ngModel of a <collection-dropdown />, and is copied from config.waf_crs_ref. The
     * reason this is done is because this property can't be changed and saved as we normally
     * do with other editable objects - we need to make a separate request to update this field.
     * Therefore, we use this field as a proxy for updating config.waf_crs_ref.
     */
    $onInit() {
        const config = this.editable.getConfig();

        this.wafCrsRef = config.waf_crs_ref;

        if (this.editable.id) {
            this.latestWafCrsVersionService_.getLatestVersions().then(this.handleCrsUpdate_);
        }
    }

    /**
     * Method to populate newCrsInfo object if a latest updated CRS does not match latest Waf CRS
     * @param {ICRSDetails} latestPortalCrsInfo - Array containing latest portal Crs
     * @param {ICRSDetails} latestControllerCrsInfo - Array containing latest controller CRS
     * @protected
     */
    handleCrsUpdate_ = ([latestPortalCrsInfo, latestControllerCrsInfo]) => {
        const currentCrsName = this.wafCrsRef.name();

        if (latestPortalCrsInfo) {
            const portalCrsReleaseDate = new Date(latestPortalCrsInfo.release_date),
                controllerCrsReleaseDate = new Date(latestControllerCrsInfo.release_date);

            if (portalCrsReleaseDate > controllerCrsReleaseDate) {
                this.newCrsInfo = latestPortalCrsInfo;
                this.newCrsIsAvailableOnController = false;
            } else if (latestControllerCrsInfo.name !== currentCrsName) {
                this.newCrsInfo = latestControllerCrsInfo;
            }
        } else if (latestControllerCrsInfo && latestControllerCrsInfo.name !== currentCrsName) {
            // Controller is not connected to a portal or there is no CRS update event yet
            this.newCrsInfo = latestControllerCrsInfo;
        }
    }

    /**
     * Moves rule to a new index. All rules in-between need to have their indices shifted.
     * @param {string} groupsProperty - 'pre_crs_groups', 'crs_groups', or 'pre_crs_groups'.
     * @param {number} newIndex - Index of the new position.
     * @param {number} groupIndex - Index of the group that was moved.
     */
    handleDragAndDropGroup(groupsProperty, newIndex, groupIndex) {
        const oldIndex = this.editable.getArrayIndexFromGroupIndex(groupsProperty, groupIndex);

        if (oldIndex < newIndex) {
            newIndex--;
        }

        this.editable.moveGroup(groupsProperty, oldIndex, newIndex);
    }

    /**
     * Sets "allow delegation" flag to true on enforcement mode selection.
     * @param {string} mode - WafMode enum value.
     */
    onModeChange(mode) {
        if (mode === wafEnforcedModeEnumValue) {
            const { editable: wafPolicy } = this;

            wafPolicy.getConfig()['allow_mode_delegation'] = true;
        }
    }

    /**
     * Returns true if policy is in Enforced mode.
     * @return {boolean}
     */
    get modeDelegationIsDisabled() {
        return this.editable.getMode() === wafEnforcedModeEnumValue;
    }

    /**
     * Called when the user has selected from the CRS Version <collection-dropdown />, but
     * before updating the ngModel. Opens up a confirmation popup and allows the user to cancel
     * the change.
     * @param {WafCrs} selected - The selected WafCrs object.
     * @return {ng.$q.promise}
     */
    handleCrsVersionSelect(selected) {
        const [wafCrs] = selected;

        return new Promise((resolve, reject) => {
            this.dialogService_.add({
                id: WAF_POLICY_CONFIRM_CHANGE_CRS_VERSION_DIALOG_ID,
                component: WafPolicyConfirmChangeCrsVersionComponent,
                componentProps: {
                    crsUpdatePreviewPromise: new Promise(resolve => {
                        this.editable.updateCrsRules(wafCrs.id, false).then(({ data }) => {
                            resolve(data);
                        });
                    }),
                    modified: this.editable.modified(),
                    wafCrsName: wafCrs.getName(),
                    onSubmit: saveUncommitted => {
                        this.saveUncommitted = saveUncommitted;
                        this.dialogService_.remove(WAF_POLICY_CONFIRM_CHANGE_CRS_VERSION_DIALOG_ID);
                        resolve();
                    },
                    onClose: () => {
                        this.dialogService_.remove(WAF_POLICY_CONFIRM_CHANGE_CRS_VERSION_DIALOG_ID);
                        reject();
                    },
                },
            });
        });
    }

    /**
     * Called after the ngModel of the CRS Version <collection-dropdown /> has changed. If
     * this.saveUncommitted is set to true, we save editable first before updating the CRS rules
     * of editable, otherwise we only update the CRS rules.
     * @param {WafCrs} wafCrs - The selected WafCrs object
     * @return {ng.$q.promise}
     */
    handleCrsVersionChange(wafCrs) {
        const { id: wafCrsUuid } = wafCrs;
        const promise = this.saveUncommitted ? this.editable.save() : this.$q_.when(true);

        return promise
            .then(() => this.editable.updateCrsRules(wafCrsUuid, true))
            .then(() => this.editable.dismiss(true));
    }

    /**
     * Creates a new allowlist rule and opens the modal to edit it.
     */
    addAllowlistRule() {
        const newRule = this.editable.createNewAllowlistRule();

        this.editAllowlistRule(newRule);
    }

    /**
     * Opens the allowlist rule modal to edit an existing rule.
     * @param {WafPolicyAllowlistRule} rule
     */
    editAllowlistRule(rule) {
        this.AviModal_.open('waf-policy-allowlist-rule-modal', {
            rule: rule.clone(),
            onSubmit: ['rule', rule => this.editable.addAllowlistRule(rule)],
        });
    }

    /**
     * Click handler for new CRS update popup close button.
     */
    closeCrsAlert() {
        this.newCrsInfo = null;
        this.newCrsIsAvailableOnController = true;
    }

    /**
     * Creates a WafPolicyPsmGroup item and opens the PSM group modal. Group can be forced to have
     * learning enabled and the toggle disabled.
     * @param {boolean=} isLearningGroup - If true, the PSM group is overridden to have learning
     *     enabled and not changeable.
     */
    createPsmGroup(isLearningGroup = false) {
        const psmGroup = this.wafPolicyPsmGroupCollection.createNewItem(undefined, true);
        const disableLearningToggle = isLearningGroup || this.editable.hasLearningGroup();

        psmGroup.edit(psmGroup.windowElement, {
            breadcrumbs: [`WAF Policy: ${this.editable.getName()}`],
            isLearningGroup,
            disableLearningToggle,
            onSubmit: ['group', group => {
                group.submit().then(() => this.editable.psm.addGroup(psmGroup));
            }],
            onCancel: () => {
                psmGroup.dismiss(true);
                psmGroup.destroy();
            },
        });
    }

    /** @override */
    $onDestroy() {
        this.wafProfileCollection.destroy();
        this.wafCrsCollection.destroy();
        this.wafPolicyPsmGroupCollection.destroy();
    }
}

WafPolicyModalController.$inject = [
    'Regex',
    'WafProfileCollection',
    'schemaService',
    'latestWafCrsVersionService',
    'WafCrsCollection',
    '$q',
    'AviModal',
    'dropDownUtils',
    'WafPolicyPsmGroupCollection',
    'l10nService',
    DIALOG_SERVICE_TOKEN,
];

/**
 * @ngdoc component
 * @name wafPolicyModal
 * @param {WafPolicy} editable
 * @desc
 *     Modal component for Waf Policy
 */
angular.module('aviApp').component('wafPolicyModal', {
    controller: WafPolicyModalController,
    bindings: {
        editable: '<',
    },
    templateUrl: 'src/components/modals/waf-policy-modal/waf-policy-modal.html',
});

/**
 * @module IPReputationDBModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { ObjectTypeItem } from 'ajs/modules/data-model';
import { IIPReputationServiceStatus } from 'generated-types';

interface IIPReputationDB {
    base_file_refs?: string[];
    incremental_file_refs?: string[];
    service_status?: IIPReputationServiceStatus;
}

interface IIPReputationDBData {
    config: IIPReputationDB;
}

export enum IPReputationDBStatus {
    ACTIVE = 'ACTIVE',
    ERROR = 'ERROR',
}

export const enum FileRefType {
    BASE = 'BASE',
    INCREMENTAL = 'INCREMENTAL',
}

export interface IIPReputationDBFile {
    type: FileRefType,
    ref: string;
}

/**
 * @description IP Reputation DB item.
 * @author Aravindh Nagarajan, alextsg
 */
export class IPReputationDB extends ObjectTypeItem {
    public data: IIPReputationDBData;

    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'ipreputationdb',
            objectType: 'IPReputationDB',
            permissionName: 'PERMISSION_IPREPUTATIONDB',
        };

        super(extendedArgs);
    }

    /**
     * Returns the error status of the IP Reputation DB item. If the error is 'SUCCESS' then there
     * is no error.
     */
    public get status(): IPReputationDBStatus {
        return this.serviceStatusError === 'SUCCESS' ?
            IPReputationDBStatus.ACTIVE :
            IPReputationDBStatus.ERROR;
    }

    /**
     * Returns any error the IP Reputation DB service status might have.
     */
    public get serviceStatusError(): string {
        return this.config.service_status?.config.error;
    }

    /**
     * Returns a list of files belonging to the IP Reputation DB item. Both Base and Incremental
     * files are combined into the returned list, as objects with a type property describing the
     * file type.
     */
    public get files(): IIPReputationDBFile[] {
        const files: IIPReputationDBFile[] = [];
        const {
            base_file_refs: baseFileRefs = [],
            incremental_file_refs: incrementalFileRefs = [],
        } = this.config;

        baseFileRefs.forEach((ref: string) => {
            files.push({
                type: FileRefType.BASE,
                ref,
            });
        });

        incrementalFileRefs.forEach((ref: string) => {
            files.push({
                type: FileRefType.INCREMENTAL,
                ref,
            });
        });

        return files;
    }

    /**
     * Returns the last time the IPReputationDB was updated in seconds.
     */
    public get lastUpdate(): number {
        return this.config.service_status?.config.last_successful_update_check?.config.usecs;
    }
}

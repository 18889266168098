/**
 * Module containing core services.
 * @preferred
 * @module CoreModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { NgModule } from '@angular/core';
import {
    DevLoggerService,
    FullModalService,
    StatePermissionTreeService,
    TenantService,
} from '.';

const coreServices = [
    FullModalService,
    StatePermissionTreeService,
    TenantService,
    DevLoggerService,
];

@NgModule({
    providers: [
        ...coreServices,
    ],
})
export class CoreModule { }

const moduleName = 'ajs-legacy';
const componentName = 'fqdn-lookup';
const prefix = `${moduleName}.${componentName}`;

export const autoAllocateIPFieldLabelWithFqdn = `${prefix}.auto-allocate-ip-field-label-with-fqdn`;
export const autoAllocateIPFieldPlaceholder = `${prefix}.auto-allocate-ip-field-placeholder`;
export const prefixLengthFieldLabel = `${prefix}.prefix-length-field-label`;
export const enableWildcardVipCheckboxLabel = `${prefix}.enable-prefix-length-checkbox-label`;
export const autoAllocateCheckboxLabel = `${prefix}.autoAllocateCheckboxLabel`;
export const enableOrDisableVipAddressButtonLabel = `${prefix}.enableOrDisableVipAddressButtonLabel`;
export const ipv6VipAddressInputPlaceholder = `${prefix}.ipv6VipAddressInputPlaceholder`;

export const ENGLISH = {
    [autoAllocateIPFieldLabelWithFqdn]: 'FQDN or IPv4 Address',
    [autoAllocateIPFieldPlaceholder]: 'FQDN or IPv4 Address',
    [prefixLengthFieldLabel]: 'Prefix Length',
    [enableWildcardVipCheckboxLabel]: 'Enable Wildcard VIP',
    [autoAllocateCheckboxLabel]: 'Auto Allocate',
    [enableOrDisableVipAddressButtonLabel]: 'Click to {0} this VIP Address',
    [ipv6VipAddressInputPlaceholder]: 'VIP Address (IPv6)',
};

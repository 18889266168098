/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';
import { appStates } from './app-state.constants';

angular.module('avi/app')
    .constant('appStateConstants', appStates);

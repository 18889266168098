const moduleName = 'waf';
const componentName = 'waf-policy-psm-group-expander-content';
const prefix = `${moduleName}.${componentName}`;

export const hitActionLabel = `${prefix}.hitActionLabel`;
export const missActionLabel = `${prefix}.missActionLabel`;

export const ENGLISH = {
    [hitActionLabel]: 'Hit Action',
    [missActionLabel]: 'Miss Action',
};

/**
 * @module SecurityModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { IOCSPConfig } from 'generated-types';
import { IEnumValue, SchemaService } from 'ajs/modules/core';
import { L10nService } from '@vmw/ngx-vip';
import template from './ssl-tls-certificate-ocsp.component.html';
import './ssl-tls-certificate-ocsp.component.less';
import * as l10n from './ssl-tls-certificate-ocsp.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class SslTlsCertificateOcspComponentController {
    /** String user populates to append to responder_url_lists. */
    public newOcspResponderUrl: string;
    public ocspConfig: IOCSPConfig;
    /** Grid config for ocspResponderUrlListFromCerts. */
    public ocspResponderUrlListFromCertsGridConfig = {
        fields: [{
            name: 'url',
            title: 'URL',
            template: '{{ ::row }}',
        }],
        id: 'ssl-tls-certificate-ocsp-responder-url-list-from-certs',
        layout: {
            hideDisplaying: true,
            hideSearch: true,
        },
    };
    /** Grid config for responder_url_lists. */
    public responderUrlListGridConfig = {
        fields: [{
            name: 'url',
            title: 'URL',
            template: '{{ ::row }}',
        }],
        id: 'ssl-tls-certificate-ocsp-responder-url-list',
        layout: {
            hideDisplaying: true,
            hideSearch: true,
        },
        multipleactions: [{
            title: 'Delete',
            do: this.deleteMultipleRows.bind(this),
        }],
    };
    /** Enum name for object used prevalently in this component. */
    public objectType = 'OCSPConfig';
    public regex: any;
    public urlActionRadioOptions: IEnumValue[];

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        schemaService: SchemaService,
        Regex: any,
        l10nService: L10nService,
    ) {
        this.urlActionRadioOptions = schemaService.getEnumValues('OCSPResponderUrlAction');
        this.regex = Regex;

        l10nService.registerSourceBundles(dictionary);
    }

    /** Method to allow user to add urls. */
    public addOcspResponderUrl(): void {
        this.ocspConfig.responder_url_lists.push(this.newOcspResponderUrl);
        this.newOcspResponderUrl = '';
    }

    /** Internal function used by grid to delete multiple rows. */
    private deleteMultipleRows(rows: IOCSPConfig['responder_url_lists']): void {
        const { ocspConfig } = this;
        const { responder_url_lists: responderUrlLists } = ocspConfig;
        const rowSet = new Set(rows);

        ocspConfig.responder_url_lists = responderUrlLists.filter(row => !rowSet.has(row));
    }
}

SslTlsCertificateOcspComponentController.$inject = [
    'schemaService',
    'Regex',
    'l10nService',
];

/**
 * @mixin sslTlsCertificateOcspComponentBindings
 * @property {ISSLKeyAndCertificate['enable_ocsp_stapling']} enableOcspStapling
 * @property {IOCSPConfig} ocspConfig
 * @property {ISSLKeyAndCertificate['ocsp_responder_url_list_from_certs']}
 *     ocspResponderUrlListFromCerts
 */
const bindings = {
    enableOcspStapling: '=',
    ocspConfig: '<',
    ocspResponderUrlListFromCerts: '<',
};

export const sslTlsCertificateOcspComponentOptions = {
    bindings,
    controller: SslTlsCertificateOcspComponentController,
    template,
};

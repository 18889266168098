/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './application-profile-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Collection Grid Template Html.
 * @type {string}
 */
const templateHtml = `<div class="sel-app-profile-list panel templatesList">
                        <collection-grid config="$ctrl.gridConfig"></collection-grid>
                      </div>`;

/**
 * @constructor
 * @memberOf module:avi/profiles/application
 * @see {@link module:avi/profiles/application.applicationProfileListComponent}
 */
class ApplicationProfileListController {
    constructor(
        ApplicationProfileCollection,
        CRUDGridConfig,
        l10nService,
    ) {
        this.ApplicationProfileCollection_ = ApplicationProfileCollection;
        this.CRUDGridConfig_ = CRUDGridConfig;

        /**
         * Get keys from source bundles for template usage.
         */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
        this.l10nService_ = l10nService;
    }

    $onInit() {
        const { l10nService_: l10nService } = this;

        const gridConfigOptions = {
            collection: new this.ApplicationProfileCollection_(),
            singleactions: [{
                title: l10nService.getMessage(l10nKeys.actionBtnEdit),
                class: 'icon-pencil-4',
                hidden: row => !row.isEditable() && !row.isClonable(),
                do(row) {
                    row.isEditable() ? row.edit() : this.config.collection.clone(row);
                },
            }],
            fields: [{
                name: 'data-config-name',
                title: l10nService.getMessage(l10nKeys.columnTitleName),
                sortBy: 'name',
                template: '{{ row.getName() }}',
            },
            {
                name: 'type',
                title: l10nService.getMessage(l10nKeys.columnTitleType),
                template: '{{ row.getType() | lookup: "APPLICATION_PROFILE_TYPE_" }}',
            }],
            permission: 'PERMISSION_APPLICATIONPROFILE',
        };

        this.gridConfig = new this.CRUDGridConfig_(gridConfigOptions);

        const { objectName } = this.gridConfig.collection;

        this.gridConfig.id = `${objectName}-list-page`;
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

ApplicationProfileListController.$inject = [
    'ApplicationProfileCollection',
    'CRUDGridConfig',
    'l10nService',
];

/**
 * @name applicationProfileListComponent
 * @property {module:avi/profiles/application.ApplicationProfileListController} controller
 * @memberOf module:avi/profiles/application
 * @description Application profile listing component.
 * @author chitra
 */
angular.module('avi/profiles/application').component('applicationProfileList', {
    controller: ApplicationProfileListController,
    template: templateHtml,
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const poolInventoryDataSources = {
    inventory: {
        source: 'InventoryCollDataSource',
        transformer: 'InventoryDataTransformer',
        transport: 'ListDataTransport',
        fields: [
            'config',
            'health',
            'runtime',
            'alert',
        ],
    },
    'collection-metrics': {
        source: 'CollMetricsCollDataSource',
        transformer: 'CollMetricsDataTransformer',
        transport: 'CollMetricsDataTransport',
        fields: [
            'l4_server.avg_bandwidth',
            'l4_server.max_open_conns',
            'l4_server.avg_total_rtt',
            'l7_server.avg_complete_responses',
            'l7_server.avg_error_responses',
            'l7_server.pct_response_errors',
        ],
        dependsOn: 'config',
    },
};

function poolCollectionFactory(
    Collection,
    Pool,
) {
    /**
     * @class PoolCollection
     * @extends module:avi/dataModel.Collection
     * @desc Collection of {@link Pool pools}.
     * @author Alex Malitsky, Zhiqian Liu
     **/
    class PoolCollection extends Collection {
        constructor(args = {}) {
            const { params = {}, ...restArgs } = args;
            const extendedArgs = {
                ...restArgs,
                params: {
                    includeName_: true,
                    join: 'application_persistence_profile_ref',
                    ...params,
                },
            };

            const { objectName } = extendedArgs;

            // if the object name is pool-inventory, load by the inventory API
            // otherwise use default data source settings to load by the list (pool) API
            if (objectName === 'pool-inventory') {
                extendedArgs.allDataSources = poolInventoryDataSources;
                extendedArgs.defaultDataSources = 'inventory';
            }

            super(extendedArgs);
        }
    }

    const serverDefaultsOverride = {
        certificate: undefined,
        servers: [],
        networks: [],
        autoscale_policy: undefined,
        x509_cert: [],
        max_conn_rate_per_server: undefined,
    };

    Object.assign(PoolCollection.prototype, {
        objectName_: 'pool',
        itemClass_: Pool,
        windowElement_: 'app-pool-create',
        isStatic_: false,
        serverDefaultsOverride_: serverDefaultsOverride,
        searchFields_: [
            'name',
            'addr',
        ],
    });

    return PoolCollection;
}

poolCollectionFactory.$inject = [
    'Collection',
    'Pool',
];

angular.module('aviApp')
    .factory('PoolCollection', poolCollectionFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name  VirtualServiceSecurityService
 * @description Service for the Virtual Service Security page.
 */
class Service {
    constructor(Base) {
        this.base_ = new Base();
    }

    /**
     * Retrieves the SSL patches data.
     * @return {ng.$q.promise}
     */
    getSSLPatches() {
        return this.base_.request('GET', '/api/changelog', null, null, 'changelog')
            .then(rsp => {
                return _.reduce(rsp.data.openssl, (acc, value, key) => {
                    acc.push({
                        date: key,
                        info: value,
                    });

                    return acc;
                }, []).sort(({ date: a }, { date: b }) => moment(b) - moment(a));
            });
    }

    /**
     * Cancels HTTP request for SSL patches data.
     */
    cancelGetSSLPatches() {
        this.base_.cancelRequests('changelog');
    }
}

Service.$inject = [
    'Base',
];

angular.module('aviApp').service('VirtualServiceSecurityService', Service);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').controller('SideMenuController', [
'$scope', '$timeout', '$rootScope', 'myAccount',
function($scope, $timeout, $rootScope, myAccount) {
    $scope.myAccount = myAccount;

    $scope.displayValues = function() {
        const toShow = myAccount.uiProperty.valuesToDisplay;

        myAccount.saveUIProperty();
        $rootScope.$broadcast(`display-${toShow}`);
    };

    $scope.setColumn = function(side) {
        if (side === 'left') {
            myAccount.uiProperty.leftMenu = true;
            myAccount.saveUIProperty();
        } else if (side === 'right') {
            myAccount.uiProperty.leftMenu = false;
            myAccount.saveUIProperty();
        }
    };

    $scope.toggleRepaint = function() {
        // using timeout so that this resolves after things have new sizes
        myAccount.saveUIProperty();
        $timeout(function() {
            $rootScope.$broadcast('repaint');
        }, 250);
    };
},
]);

/**
 * @module Angular-Core
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { FormsModule } from '@angular/forms';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { UIRouterModule } from '@uirouter/angular';
import { HttpClientModule } from '@angular/common/http';
import {
    I18nContext,
    LocaleService,
    VIPModule,
    VIPService,
} from '@vmw/ngx-vip';

import { initVIPConfig } from 'ajs/modules/core';

import { CoreModule } from './modules/core/core.module';
import { SharedModule } from './shared/shared.module';
import { NotificationModule } from './modules/notification/notification.module';
import { WafModule } from './modules/waf/waf.module';
import { UpdateModule } from './modules/update/update.module';
import { NatModule } from './modules/nat-policy';
import { WebhookModule } from './modules/webhook/webhook.module';
import { LaunchConfigModule } from './modules/launch-config/launch-config.module';
import { LicensingModule } from './modules/licensing/licensing.module';
import {
    CloudConnectorUserModule,
} from './modules/cloud-connector-user/cloud-connector-user.module';
import { CloudModule } from './modules/cloud/cloud.module';
import { GslbModule } from './modules/gslb/gslb.module';
import { HeaderModule } from './modules/header/header.module';
import { LoginModule } from './modules/login/login.module';
import { IcapProfileModule } from './modules/icap-profile/icap-profile.module';
import { WelcomeModule } from './modules/welcome/welcome.module';
import { IPReputationDBModule } from './modules/ip-reputation-db/ip-reputation-db.module';
import { NetworkModule } from './modules/network/network.module';
import { IpamModule } from './modules/ipam/ipam.module';
import { DialogModule } from './modules/dialog/dialog.module';
import { SystemModule } from './modules/system/system.module';
import { AviFormsModule } from './modules/avi-forms/avi-forms.module';

import {
    $stateProvider,
    appStateTreeProvider,
    authServiceProvider,
    aviConfirmServiceProvider,
    aviModalProvider,
    backupConfigCollectionProvider,
    backupConfigItemProvider,
    cloudCollectionProvider,
    cloudConnectorUserCollectionProvider,
    cloudItemProvider,
    cportalServiceProvider,
    defaultValuesProvider,
    faultServiceProvider,
    HttpWrapperProvider,
    initialDataServiceProvider,
    ipamProfileCollectionProvider,
    licensingServiceProvider,
    macroStackProvider,
    matchTargetConfigItemProvider,
    myAccountProvider,
    poolGroupCollectionProvider,
    schemaServiceProvider,
    stateManagerServiceProvider,
    stringServiceProvider,
    systemConfigProvider,
    systemInfoServiceProvider,
    timeframeProvider,
    UpgradeNodeCollectionProvider,
    UpgradeServiceProvider,
    uploadProvider,
    vCenterServerCollectionProvider,
    wafPolicyPsmGroupCollectionProvider,
    wafPolicyPsmGroupMetricProvider,
} from './ajs-upgraded-providers';

import './shared/common/clarity-icons';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        UpgradeModule,
        FormsModule,
        HttpClientModule,
        UIRouterUpgradeModule.forRoot({}),
        VIPModule.forRoot(),
        UIRouterModule,
        CoreModule,
        SharedModule,
        NotificationModule,
        WafModule,
        UpdateModule,
        NatModule,
        WebhookModule,
        LaunchConfigModule,
        LicensingModule,
        CloudConnectorUserModule,
        CloudModule,
        GslbModule,
        HeaderModule,
        LoginModule,
        IcapProfileModule,
        NetworkModule,
        WelcomeModule,
        IPReputationDBModule,
        IpamModule,
        DialogModule,
        SystemModule,
        AviFormsModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initVIPConfig,
            deps: [
                VIPService,
                LocaleService,
                I18nContext,
            ],
            multi: true,
        },
        schemaServiceProvider,
        aviConfirmServiceProvider,
        aviModalProvider,
        cloudItemProvider,
        defaultValuesProvider,
        UpgradeNodeCollectionProvider,
        UpgradeServiceProvider,
        licensingServiceProvider,
        initialDataServiceProvider,
        matchTargetConfigItemProvider,
        myAccountProvider,
        wafPolicyPsmGroupCollectionProvider,
        wafPolicyPsmGroupMetricProvider,
        timeframeProvider,
        {
            provide: APP_BASE_HREF,
            useValue: '/',
        },
        appStateTreeProvider,
        authServiceProvider,
        $stateProvider,
        cloudConnectorUserCollectionProvider,
        cloudCollectionProvider,
        ipamProfileCollectionProvider,
        vCenterServerCollectionProvider,
        poolGroupCollectionProvider,
        stringServiceProvider,
        stateManagerServiceProvider,
        uploadProvider,
        macroStackProvider,
        systemConfigProvider,
        backupConfigItemProvider,
        backupConfigCollectionProvider,
        faultServiceProvider,
        systemInfoServiceProvider,
        cportalServiceProvider,
        HttpWrapperProvider,
    ],
})
export class AppModule {
    public constructor(private upgrade: UpgradeModule) {}

    public ngDoBootstrap(): void {
        this.upgrade.bootstrap(document.body, ['aviApp'], { strictDi: true });
    }
}

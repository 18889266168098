const moduleName = 'ajs-legacy';
const componentName = 'traffic-clone-profile-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleCloud = `${prefix}.columnTitleCloud`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleCloud]: 'Cloud',
};

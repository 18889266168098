/**
 * @module CloudModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { L10nService } from '@vmw/ngx-vip';
import { CloudType } from 'generated-types';
import { LicenseBasedFeaturesService } from 'ajs/modules/core';
import { Cloud } from 'ajs/js/services/items/Cloud';
import template from './cloud-type-selector.component.html';
import './cloud-type-selector.component.less';
import * as l10n from './cloud-type-selector.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCloud = typeof Cloud;

class CloudTypeSelectorController {
    /**
     * Currently selected cloud type.
     */
    public activeCloudType: CloudType;

    /**
     * Called when a cloud type is selected.
     */
    public onCloudTypeChange: (cloudType: { cloudType: CloudType }) => void;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Cloud type enums.
     */
    public CloudType = {
        [CloudType.CLOUD_VCENTER]: CloudType.CLOUD_VCENTER,
        [CloudType.CLOUD_OPENSTACK]: CloudType.CLOUD_OPENSTACK,
        [CloudType.CLOUD_AWS]: CloudType.CLOUD_AWS,
        [CloudType.CLOUD_LINUXSERVER]: CloudType.CLOUD_LINUXSERVER,
        [CloudType.CLOUD_AZURE]: CloudType.CLOUD_AZURE,
        [CloudType.CLOUD_GCP]: CloudType.CLOUD_GCP,
        [CloudType.CLOUD_NONE]: CloudType.CLOUD_NONE,
    };

    /**
     * Map of license-based supported cloud types.
     */
    private readonly supportedCloudTypesMap: Map<CloudType, boolean>;

    constructor(
        private readonly Cloud: TCloud,
        licenseBasedFeaturesService: LicenseBasedFeaturesService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.supportedCloudTypesMap = licenseBasedFeaturesService.getSupportedCloudTypesMap();
    }

    /**
     * Returns the name of the cloud defined by enums.
     */
    public getCloudTypeName(cloudType: CloudType): string {
        return this.Cloud.getCloudTypeName(cloudType);
    }

    /**
     * Called when a cloud type is selected.
     */
    public handleCloudTypeChange(cloudType: CloudType): void {
        this.onCloudTypeChange({ cloudType });
    }

    /**
     * If true, shows the cloud for selection.
     */
    public showCloudType(cloudType: CloudType): boolean {
        return this.supportedCloudTypesMap.get(cloudType);
    }

    /**
     * Returns true if the cloud type matches the active cloud type.
     */
    public isActive(cloudType: CloudType): boolean {
        return this.activeCloudType === cloudType;
    }
}

CloudTypeSelectorController.$inject = [
    'Cloud',
    'licenseBasedFeaturesService',
    'l10nService',
];

export const cloudTypeSelectorOptions = {
    bindings: {
        onCloudTypeChange: '&',
        activeCloudType: '@',
    },
    controller: CloudTypeSelectorController,
    template,
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function networkResolutionControllerFactory(
    $scope,
    Regex,
    SubnetListNetworkCollection,
    NetworkResolutionFactory,
    getSubnetObject,
) {
    $scope.Regex = Regex;

    $scope.NetworkResolution = new NetworkResolutionFactory();

    // Used to display network list
    $scope.networks = new SubnetListNetworkCollection({
        params: {
            discovered_only: true,
        },
    });

    const { NetworkResolution } = $scope;

    /*---------- When a subnet has been selected ------------*/
    /**
     * Called when user has selected a subnet from the subnet dropdown. Need to call setIpaddrTmp to
     * get list of IPs to display and call setSubnetRuntime to get free_ip_count and ip_alloced
     * properties.
     */
    $scope.selectSubnet = function() {
        const { configured_subnets: configuredSubnets } = $scope.network.data.config;
        const {
            selected_subnet: selectedSubnet,
            networkruntime: networkRuntime,
        } = $scope.data;

        setStaticIpaddrTmp(configuredSubnets, selectedSubnet);
        setSubnetRuntime(networkRuntime, selectedSubnet);
        $scope.data.pool_address = null;
    };

    /**
     * Sets $scope.data.static_ipaddr_tmp to the IP addresses listed in the selected configured
     * subnet to be displayed in a table. Only configured subnets will have a list of pool
     * addresses.
     * @param {object} configuredSubnets - List of configured subnets.
     * @param {string} subnet - Selected subnet.
     */
    function setStaticIpaddrTmp(configuredSubnets, subnet) {
        NetworkResolution.setStaticIpaddrTmp(configuredSubnets, subnet);
        $scope.data.static_ipaddr_tmp = NetworkResolution.static_ipaddr_tmp;
    }

    /**
     * Called by selectSubnet when a subnet has been selected. Sets $scope.data.subnet_runtime
     * which is used to check for free_ip_count and ip_alloced properties. If free_ip_count is > 0,
     * then user cannot add a new pool address and input is hidden. If ip_alloced > 0, display
     * the IP Address/SE table on the right side.
     * @param {object} networkRuntime - Network runtime object, used to get subnet runtime.
     * @param {string} subnet - Selected subnet.
     */
    function setSubnetRuntime(networkRuntime, subnet) {
        NetworkResolution.setSubnetRuntime(networkRuntime, subnet);
        $scope.data.subnet_runtime = NetworkResolution.subnet_runtime;
    }
    /*-------------------------------------------------------*/

    /*--------- Save settings ---------*/
    /**
     * Sets VS network_ref and subnet before saving VS.
     */
    function setVS() {
        const config = $scope.editable.getConfig();

        config.network_ref = $scope.data.network_ref;

        const { selected_subnet: subnet } = $scope.data;

        config.subnet = getSubnetObject(subnet);
    }

    /**
     * Saves VS after setting network_ref and subnet.
     * @return {promise} Promise from VirtualService.save();
     */
    $scope.saveVS = function() {
        setVS();

        return NetworkResolution.saveItem($scope.editable);
    };

    /**
     * Sets pool placement_networks list before saving.
     */
    function setPool() {
        const {
            network_ref: netRef,
            selected_subnet: selectedSubnet,
        } = $scope.data;

        const { pool } = $scope;

        NetworkResolution.setPool(netRef, selectedSubnet, pool);

        if (pool && pool.getConfig()) {
            pool.getConfig().placement_networks = NetworkResolution.placement_networks;
        }
    }

    /**
     * Saves pool after adding subnet and network_ref to pool's placement networks.
     * @return {promise} Promise from Pool.save();
     */
    $scope.savePool = function() {
        setPool();

        return NetworkResolution.saveItem($scope.pool);
    };

    /**
     * Sets network configured_subnets list before saving Network.
     */
    function setNetwork() {
        const { network } = $scope;
        const configuredSubnets = angular.copy(network.getConfig().configured_subnets);
        const {
            selected_subnet: selectedSubnet,
            pool_address: ip,
        } = $scope.data;

        NetworkResolution.setConfiguredSubnets(configuredSubnets, selectedSubnet, ip);

        network.getConfig().configured_subnets = NetworkResolution.configured_subnets;
    }

    /**
     * Saves network after adding subnet and pool IP address.
     * @return {promise} Promise from Network.save();
     */
    $scope.saveNetwork = function() {
        setNetwork();

        return NetworkResolution.saveItem($scope.network);
    };
}

networkResolutionControllerFactory.$inject = [
    '$scope',
    'Regex',
    'SubnetListNetworkCollection',
    'NetworkResolutionFactory',
    'getSubnetObject',
];

/**
 * @ngdoc controller
 * @name NetworkResolutionController
 */
angular.module('aviApp')
    .controller('NetworkResolutionController', networkResolutionControllerFactory);

/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { Directive, Input } from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { isUndefined } from 'underscore';
import { regexPatternValidator } from '../../validators';

@Directive({
    providers: [{
        multi: true,
        provide: NG_VALIDATORS,
        useExisting: RegexPatternDirective,
    }],
    selector: '[regexPattern][formControlName],[regexPattern][formControl],[regexPattern][ngModel]',
})
export class RegexPatternDirective implements Validator {
    /**
     * Regex pattern name to be tested. List of regex pattern names can be found in regex.utils.ts.
     */
    @Input('regexPattern') public regexPatternName: string;

    /**
     * @override
     * Check the regexPattern if the value is defined, since we don't want to invalidate a form with
     * empty input fields that are optional.
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        return !isUndefined(control.value) ?
            regexPatternValidator(this.regexPatternName)(control) :
            null;
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name HSMGroup
 * @description
 *
 *     HSMGroup Item
 */
angular.module('aviApp').factory('HSMGroup', [
'$q', 'Item', 'defaultValues',
function($q, Item, defaultValues) {
    class HSMGroup extends Item {
        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            if (!('hsm' in config)) {
                config['hsm'] = {};
            }

            const { hsm } = config;

            if (!('type' in hsm)) {
                hsm['type'] = 'HSM_TYPE_THALES_NETHSM';
            }

            this.onTypeChange();
        }

        /** @override */
        dataToSave() {
            const
                config = angular.copy(this.getConfig()),
                { hsm } = config;

            if ('rfs' in hsm) {
                const { enabled } = hsm['rfs'];

                if (!enabled) {
                    delete hsm['rfs'];
                } else {
                    delete hsm['rfs'].enabled;
                }
            }

            return config;
        }

        /**
         * Returns HSM type.
         * @returns {string|undefined}
         * @public
         */
        getType() {
            const { hsm } = this.getConfig();

            if (!hsm) {
                return;
            }

            return hsm.type.slice('HSM_TYPE_'.length);
        }

        /**
         * Tweaks config properties based on the current type.
         * @public
         */
        onTypeChange() {
            const
                { hsm } = this.getConfig(),
                type = this.getType(),
                propsToDrop = [];

            let typeConfig,
                typeConfigs;

            switch (type) {
                case 'THALES_NETHSM': {
                    hsm['nethsm'] = hsm['nethsm'] || [];
                    typeConfigs = hsm['nethsm'];

                    if (!typeConfigs.length) {
                        this.addNetHSM();
                    }

                    const { rfs } = hsm;

                    if (rfs) {
                        //UI only field
                        rfs.enabled = !!rfs['ip'];
                    }

                    propsToDrop.push(
                        'sluna',
                        'cloudhsm',
                    );

                    break;
                }

                case 'SAFENET_LUNA':
                    hsm['sluna'] = hsm['sluna'] || {};
                    typeConfig = hsm['sluna'];

                    if (!('server' in typeConfig)) {
                        typeConfig['server'] = [];
                    }

                    if (!typeConfig['server'].length) {
                        typeConfig['server'].push({});
                    }

                    propsToDrop.push(
                        'nethsm',
                        'rfs',
                        'cloudhsm',
                    );

                    break;

                case 'AWS_CLOUDHSM':
                    hsm['cloudhsm'] = hsm['cloudhsm'] || {};
                    typeConfig = hsm['cloudhsm'];

                    if (!('hsm_ip' in typeConfig)) {
                        typeConfig['hsm_ip'] = [];
                    }

                    propsToDrop.push(
                        'nethsm',
                        'rfs',
                        'sluna',
                    );

                    break;
            }

            propsToDrop.forEach(propName => delete hsm[propName]);
        }

        /**
         * Adds default netHSM into THALES_NETHSM configuration.
         * @public
         */
        addNetHSM() {
            if (this.getType() === 'THALES_NETHSM') {
                this.getConfig()['hsm']['nethsm'].push(
                    defaultValues.getDefaultItemConfigByType('hsmthalesnethsm'),
                );
            }
        }

        /**
         * Makes request to get a list of nodes available for SAFENET Luna HSM type.
         * @return {ng.$q.promise} to be fulfilled with the list of IP addresses.
         * @protected
         */
        getSLunaNodes_() {
            const
                seMgmtNetworkUrl = '/api/serviceengine?search=(is_mgmt,true)&fields=' +
                    'mgmt_vnic.vnic_networks.ip.ip_addr.addr,mgmt_vnics.is_mgmt',
                seDedicatedNetworkUrl = '/api/serviceengine?search=(is_hsm,true)&' +
                    'fields=data_vnics.vnic_networks.ip.ip_addr.addr,data_vnics.is_hsm',
                apis = [
                    '/api/cluster',
                ],
                { hsm } = this.getConfig();

            const useDedicatedNetwork =
                hsm && hsm['sluna'] && hsm['sluna']['use_dedicated_network'];

            apis.push(
                useDedicatedNetwork ? seDedicatedNetworkUrl : seMgmtNetworkUrl,
            );

            this.busy = true;
            this.errors = null;

            const requests =
                apis.map(api => this.request('GET', api, null, undefined, 'getLunaNodes'));

            return $q.all(requests)
                .then(HSMGroup.transformNodesResponse_)
                .catch(rsp => $q.reject(this.errors = rsp.data))
                .finally(() => this.busy = false);
        }

        /**
         * Replaces SAFENET LUNA nodes list with passed IPs.
         * @param {string[]} ipList
         * @protected
         */
        populateSLunaNodesList_(ipList) {
            if (this.getType() === 'SAFENET_LUNA') {
                const config = this.getConfig();

                config['hsm']['sluna']['node_info'] =
                    ipList.map(clientIp => ({ client_ip: clientIp }));
            }
        }

        /**
         * Populates SAFENET LUNA nodes list with loaded default values.
         * @returns {ng.$q.promise}
         * @public
         */
        populateSLunaNodesList() {
            if (this.getType() === 'SAFENET_LUNA') {
                return this.getSLunaNodes_()
                    .then(this.populateSLunaNodesList_.bind(this))
                    .catch(() => this.populateSLunaNodesList([]));
            }

            return $q.reject('Wrong HSM type');
        }

        /**
         * Combines responses into the list of IP addresses.
         * @returns {string[]}
         * @protected
         * @static
         */
        static transformNodesResponse_([{ data: clusterData }, se]) {
            const
                ips = clusterData.nodes.map(node => node.ip.addr),
                { url: seAPIUrl } = se.config,
                { results } = se.data;

            if (seAPIUrl.indexOf('is_hsm') !== -1) {
                results.forEach(({ data_vnics: dataVNICs }) => {
                    dataVNICs.forEach(vNIC => {
                        if (vNIC['is_hsm']) {
                            ips.push(vNIC['vnic_networks'][0]['ip']['ip_addr']['addr']);
                        }
                    });
                });
            } else {
                results.forEach(({ mgmt_vnic: { vnic_networks: vNICNets } }) =>
                    vNICNets.forEach(vNIC => ips.push(vNIC['ip']['ip_addr']['addr'])));
            }

            return ips;
        }
    }

    angular.extend(HSMGroup.prototype, {
        objectName: 'hardwaresecuritymodulegroup',
        windowElement: 'hsm-group-modal',
    });

    return HSMGroup;
}]);

/**
 * @module VCenterServerModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { IContentLibConfig } from 'generated-types';
import { IMessageItemData, MessageItem } from 'ajs/modules/data-model';

interface IContentLibData extends IMessageItemData {
    config: IContentLibConfig;
}

export class ContentLibConfigConfigItem extends MessageItem {
    public data: IContentLibData;

    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'ContentLibConfig',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public isValid(): boolean {
        return this.canFlatten();
    }

    /** @override */
    public canFlatten(): boolean {
        return Boolean(this.config.id || this.config.name);
    }
}

const moduleName = 'ajs-legacy';
const componentName = 'backup-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleTimestamp = `${prefix}.columnTitleTimestamp`;
export const columnTitleLocalFile = `${prefix}.columnTitleLocalFile`;
export const emptyDataObject = `${prefix}.emptyDataObject`;
export const columnTitleRemoteFile = `${prefix}.columnTitleRemoteFile`;
export const backupFrequencyLabel = `${prefix}.backupFrequencyLabel`;
export const serverAddressNameLabel = `${prefix}.serverAddressNameLabel`;
export const userNameLabel = `${prefix}.userNameLabel`;
export const directoryNameLabel = `${prefix}.directoryNameLabel`;
export const configurationBackupLabel = `${prefix}.configurationBackupLabel`;
export const enabledDataLabel = `${prefix}.enabledDataLabel`;
export const disabledDataLabel = `${prefix}.disabledDataLabel`;
export const localBackupLabel = `${prefix}.localBackupLabel`;
export const remoteBackupLabel = `${prefix}.remoteBackupLabel`;

export const ENGLISH = {
    [columnTitleTimestamp]: 'Timestamp',
    [columnTitleLocalFile]: 'Local File',
    [emptyDataObject]: 'N/A',
    [columnTitleRemoteFile]: 'Remote File',
    [backupFrequencyLabel]: 'Back up every {0} {1}(s), {2} maximum backups stored',
    [serverAddressNameLabel]: 'Server address: {0}',
    [userNameLabel]: 'User: {0}',
    [directoryNameLabel]: 'Directory: {0}',
    [configurationBackupLabel]: 'Configuration Backup:',
    [enabledDataLabel]: 'Enabled',
    [disabledDataLabel]: 'Disabled',
    [localBackupLabel]: 'Local Backup:',
    [remoteBackupLabel]: 'Remote Backup:',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @constructor
 * @memberOf module:avi/component-kit
 * @mixes module:avi/component-kit.enumOptionsBindings
 * @see {@link module:avi/component-kit.enumOptions}
 */
class EnumOptionsController {
    constructor(schemaService, dropDownUtils) {
        this.schemaService_ = schemaService;
        this.dropDownUtils_ = dropDownUtils;

        /**
         * Enum type
         * @type {string}
         */
        this.enumType = '';

        /**
         * Object type to be searched
         * @type {string}
         */
        this.objectType = '';

        /**
         * Field name of the provided object type
         * @type {string}
         */
        this.fieldName = '';
    }

    /**
     * @override
     */
    $onInit() {
        const {
            objectType,
            fieldName,
            enumOptionsExclude,
        } = this;

        let { enumType } = this;

        if (!enumType && (!objectType || !fieldName)) {
            throw new Error('enumType or objectType and fieldName are required');
        }

        const {
            schemaService_: schemaService,
        } = this;

        if (!enumType) {
            enumType = schemaService.getEnumFieldType(objectType, fieldName);
        }

        const options = this.dropDownUtils_.getEnumDropdownOptions(enumType);

        if (
            enumOptionsExclude &&
            Array.isArray(enumOptionsExclude) &&
            enumOptionsExclude.length > 0
        ) {
            this.ddCtrl.options = options.filter(({ value }) => {
                return !enumOptionsExclude.includes(value);
            });
        } else {
            this.ddCtrl.options = options;
        }
    }
}

EnumOptionsController.$inject = [
    'schemaService',
    'dropDownUtils',
];

const enumOptionsDirectiveConfig = {
    controller: EnumOptionsController,
    restrict: 'A',
    bindToController: {
        enumType: '@?enumOptions',
        objectType: '@?',
        fieldName: '@?',
        enumOptionsExclude: '<?',
    },
    require: {
        ddCtrl: 'dropdown',
    },
};

function enumOptionsDirectiveFactory() {
    return enumOptionsDirectiveConfig;
}

/**
 * @mixin enumOptionsBindings
 * @memberOf module:avi/component-kit
 * @property {string=} enumType - name of enum type.
 * @property {string=} objectType - name of object type.
 * @property {string=} fieldName - name of the field of given object type.
 * @property {Array.<string>=} enumOptionsExclude - list of enum values to be removed from options.
 */

/**
 * @name enumOptions
 * @memberOf module:avi/component-kit
 * @property {module:avi/component-kit.EnumOptionsController} controller
 * @property {module:avi/component-kit.enumOptionsBindings} bindings
 * @desc Directive used to get dropdown options from schema.enums. We can provide enum type or
 *       object type and field name to get its enums options.
 * @author Alex Malitsky, Ashish Verma
 * @see {@link module:avi/component-kit.dropdown}
 * @example <caption>Passing object-type and field-name to get options</caption>
 * <dropdown
 *     ng-model="obj.value"
 *     multiple
 *     enum-options
 *     object-type="objectType"
 *     field-name="fielName"
 * >
 * </dropdown>
 *
 * @example <caption>Passing enum type to get options</caption>
 * <dropdown
 *     ng-model="obj.value"
 *     multiple
 *     enum-options="enumType"
 * >
 * </dropdown>
 */
angular.module('avi/component-kit')
    .directive(
        'enumOptions',
        enumOptionsDirectiveFactory,
    );

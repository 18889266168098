/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../../../../less/pages/profile/certificate-list.less';
import * as l10n from './CertificateListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('CertificateListController', [
'CertificateCollection',
'$templateCache',
'CRUDGridConfig',
'AviModal',
'schemaService',
'l10nService',
function(
    CertificateCollection,
    $templateCache,
    CRUDGridConfig,
    AviModal,
    schemaService,
    l10nService,
) {
    /**
     * Get keys from source bundles for template usage.
     */
    this.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    /**
     * Grabs the enums for certificate types !== 'SSL_CERTIFICATE_TYPE_CA'.
     */
    const certCollectionEnums =
        schemaService.getEnumKeys('SSLCertificateType')
            .filter(type => type !== 'SSL_CERTIFICATE_TYPE_CA').join();

    const certCollection = new CertificateCollection({
        params: {
            type: certCollectionEnums,
        },
    });

    const authCollection = new CertificateCollection({
        params: {
            type: 'SSL_CERTIFICATE_TYPE_CA',
        },
    });

    authCollection.isCreatable = function() {
        return false;
    };

    this.collection = new CertificateCollection({
        bind: {
            collItemSave() {
                certCollection.load();
                authCollection.load();
            },
        },
    });

    // Mofify default grid configuration
    const certGridConfig = new CRUDGridConfig();
    const authGridConfig = new CRUDGridConfig();

    certGridConfig.id = 'ssl-cert-list-page';
    authGridConfig.id = 'ssl-cert-type-ca-list-page';

    certGridConfig.collection = certCollection;
    authGridConfig.collection = authCollection;

    certGridConfig.fields = getCertFields();
    authGridConfig.fields = getCertFields();

    certGridConfig.singleactions = getSingleActions();
    authGridConfig.singleactions = getSingleActions();

    const expandedContainerTemplate = '<certificate-expander certificate="row">' +
        '</certificate-expander>';

    certGridConfig.expandedContainerTemplate = expandedContainerTemplate;
    authGridConfig.expandedContainerTemplate = expandedContainerTemplate;

    /**
     * Called to check if expander required to be rendered.
     * @param {Certificate} certificate - Certificate Object.
     * @return {boolean}
     */
    function expanderDisabled(certificate) {
        return !certificate.isOcspApplicable;
    }

    certGridConfig.expanderDisabled = expanderDisabled;
    authGridConfig.expanderDisabled = expanderDisabled;

    certGridConfig.layout = {
        lengthAsTotal: true,
    };

    authGridConfig.layout = getLayout();

    certGridConfig.createActions = [{
        label: l10nService.getMessage(l10nKeys.btnLabelRootIntermediateCaCert),
        onClick() {
            authGridConfig.collection.setDefaultItemConfigProps({
                type: 'SSL_CERTIFICATE_TYPE_CA',
            });

            authGridConfig.collection.create('ssl-certificate-create-root');
        },
    }, {
        label: l10nService.getMessage(l10nKeys.btnLabelApplicationCert),
        onClick() {
            certGridConfig.collection.setDefaultItemConfigProps({
                type: 'SSL_CERTIFICATE_TYPE_VIRTUALSERVICE',
            });

            certGridConfig.collection.create('ssl-certificate-create-application-system');
        },
    }, {
        label: l10nService.getMessage(l10nKeys.btnLabelControllerCert),
        onClick() {
            certGridConfig.collection.setDefaultItemConfigProps({
                type: 'SSL_CERTIFICATE_TYPE_SYSTEM',
            });

            certGridConfig.collection.create('ssl-certificate-create-application-system');
        },
    }];

    this.certGridConfig = certGridConfig;
    this.authGridConfig = authGridConfig;

    /**
     * Returns grid fields for both certGridConfig and authGridConfig as both are identical.
     * @return {Object[]} gridConfig fields.
     */
    function getCertFields() {
        return [{
            name: 'name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            template: '<ssl-certificate-grid-name-column row="row" />',
            sortBy: 'name',
            visibility: 'd',
        }, {
            name: 'status',
            title: l10nService.getMessage(l10nKeys.columnTitleStatus),
            template: '<ssl-certificate-grid-status-column row="row" />',
            visibility: 'd',
        }, {
            name: 'common',
            title: l10nService.getMessage(l10nKeys.columnTitleCommonName),
            template: `
                <span class="sel-common-name">
                    {{ row.data.config.certificate.subject.common_name }}
                </span>`,
            visibility: 'm',
        }, {
            name: 'issuer',
            title: l10nService.getMessage(l10nKeys.columnTitleIssuerName),
            template: `
                <span class="sel-issuer-name">
                    {{ row.data.config.certificate.issuer.common_name }}
                </span>`,
            visibility: 'm',
        }, {
            name: 'algorithm',
            title: l10nService.getMessage(l10nKeys.columnTitleAlgorithm),
            template: require('./certificate-list-algorithm.partial.html'),
            visibility: 'd',
        }, {
            name: 'selfsigned',
            title: l10nService.getMessage(l10nKeys.columnTitleSelfSigned),
            template: `
                <span class="sel-self-signed">
                    {{ row.data.config.certificate.self_signed ?
                        '${l10nService.getMessage(l10nKeys.yesValue)}' :
                        '${l10nService.getMessage(l10nKeys.noValue)}' }}
                </span>`,
            visibility: 'm',
        }, {
            name: 'valid',
            title: l10nService.getMessage(l10nKeys.columnTitleValidUntil),
            template: `
                <span class="sel-valid">
                    {{row.data.config.status!="SSL_CERTIFICATE_PENDING" ?
                       row.data.config.certificate.not_after :
                       '${l10nService.getMessage(l10nKeys.awaitingCertificateValue)}'}}
                </span>`,
            visibility: 'd',
        }, {
            name: 'san_domain_names',
            title: l10nService.getMessage(l10nKeys.columnTitleSanDomainNames),
            template: '<span>{{ row.data.config.certificate.subject_alt_names.join(", ") }}</span>',
            visibility: 'optional',
        }];
    }

    /**
     * Returns singleactions for the gridConfig.
     * @return {Object[]} gridConfig singleactions.
     */
    function getSingleActions() {
        return [{
            title: l10nService.getMessage(l10nKeys.actionBtnExport),
            class: 'icon-download sel-download',
            hidden(row) {
                return row.data.config.status === 'SSL_CERTIFICATE_PENDING';
            },
            do(row) {
                AviModal.open('ssl-certificate-export', {
                    uuid: row.data.config.uuid,
                });
            },
        }, {
            title: l10nService.getMessage(l10nKeys.actionBtnEdit),
            class: 'icon-pencil-4',
            hidden(row) {
                return !row.isEditable();
            },
            do: row => row.edit(),
        }];
    }

    /**
     * Returns layout for the gridConfig.
     * @return {Object<string, boolean>} gridConfig layout.
     */
    function getLayout() {
        return {
            lengthAsTotal: true,
            includeCreateButton: false,
        };
    }
}]);

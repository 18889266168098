/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './file-upload-textarea.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const componentName = 'file-upload-textarea';

class FileUploadTextArea {
    constructor($element, $attrs, l10nService) {
        this.$element = $element;
        this.$attrs = $attrs;
        this.fileMode = false;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        /**
         * File content, ngModel.$viewValue.
         * @type {undefined|string}
         */
        this.fileContent = undefined;
    }

    $onInit() {
        this.$element.addClass(componentName);

        this.ngModelCtrl.$render = this.updateViewValue_.bind(this);

        const { $attrs } = this;

        this.isRequired = 'required' in $attrs;

        // ng-required is supported through this
        $attrs.$observe('required', required => {
            this.isRequired = !!required || required === '';
        });
    }

    /**
     * Updates ngModel viewValue.
     * @protected
     */
    updateViewValue_() {
        this.fileContent = this.ngModelCtrl.$modelValue;
    }

    /**
     * Updates ngModel modelValue.
     */
    updateModelValue() {
        this.ngModelCtrl.$setViewValue(this.fileContent || undefined);
    }
}

FileUploadTextArea.$inject = [
    '$element',
    '$attrs',
    'l10nService',
];

/**
 * @ngdoc component
 * @name fileUploadTextarea
 * @param {string} label
 * @param {string|Object=} helpText
 * @param {string=} placeholder
 * @param {string=} type - To be passed into fileUpload component
 * @see fileUpload
 * @author Alex Malitsky
 * @description
 *
 *     Component to populate string value though text-area or uploaded file.
 */
angular.module('aviApp').component('fileUploadTextarea', {
    bindings: {
        label: '<',
        helpText: '<?',
        placeholder: '@?',
        type: '@?',
    },
    controller: FileUploadTextArea,
    templateUrl: `src/components/forms/inputs/file-upload/${componentName}/` +
            `${componentName}.component.html`,
    require: {
        ngModelCtrl: 'ngModel',
    },
});

/**
 * @module LicensingModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import './license-tier-select-card.component.less';

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
} from '@angular/core';

import { LicenseTierType } from 'generated-types';
import { SchemaService } from 'ajs/modules/core';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './license-tier-select-card.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *     Provide selection functionality for a specific type of a license tier.
 *     This component doesn't touch any data-model. Selection is done by event binding.
 *
 * @author Zhiqian Liu
 */
@Component({
    selector: 'license-tier-select-card',
    templateUrl: './license-tier-select-card.component.html',
})
export class LicenseTierSelectCardComponent {
    /**
     * Type of the license tier.
     */
    @Input()
    public tierType: LicenseTierType;

    /**
     * Indicate if this card is selected.
     */
    @Input()
    public isSelected: boolean;

    /**
     * Fraction string of tier usage, in the form of (used/total).
     */
    @Input()
    public tierUsageFraction: string;

    /**
     * Decide whether to disable selection functionality of this card.
     */
    @Input()
    public disabled: boolean;

    /**
     * Called when this card is selected.
     */
    @Output()
    public onSelect: EventEmitter<LicenseTierType> = new EventEmitter();

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Dummy getter, to conform with use of [(ngModel)] in clarity radio input element.
     */
    public get selected(): string {
        return this.isSelected ? 'ACTIVE' : '';
    }

    /**
     * Dummy setter, to conform with use of [(ngModel)] in clarity radio input element.
     */
    public set selected(tierType: string) {
        // this line to supress the "block is empty" linting error
    }

    constructor(
        @Inject(SchemaService) private schemaService: SchemaService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Handler of clicking of the card. Trigger selection only when allowed.
     */
    public onCardClick(): void {
        if (!this.disabled) {
            this.onSelect.emit(this.tierType);
        }
    }

    /**
     * Get the label of a specific type of license tier.
     */
    public getTierTypeLabel(tierType: LicenseTierType): string {
        return this.schemaService.getEnumValue('LicenseTierType', tierType).label;
    }

    /**
     * Get the description of a specific type of license tier.
     */
    public getTierTypeDescription(tierType: LicenseTierType): string {
        return this.schemaService.getEnumValue('LicenseTierType', tierType).description;
    }
}

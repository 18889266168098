/**
 * Module for Analytics related components.
 * @preferred
 * @module AnalyticsModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';

import {
    timingTileComponentOptions,
    timingTilesComponentOptions,
} from './components';

const analyticsModule = angular.module('avi/analytics');

const components = [{
    name: 'timingTiles',
    options: timingTilesComponentOptions,
}, {
    name: 'timingTile',
    options: timingTileComponentOptions,
}];

components.forEach(({ name, options }) => analyticsModule.component(name, options));

const moduleName = 'legacy-ajs';
const componentName = 'user-profile-create';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const maxPasswordHistoryCountInputLabel = `${prefix}.maxPasswordHistoryCountInputLabel`;
export const maxLoginFailureCountInputLabel = `${prefix}.maxLoginFailureCountInputLabel`;
export const accountLockTimeoutInputLabel = `${prefix}.accountLockTimeoutInputLabel`;
export const inputFieldInnerTextMinutes = `${prefix}.inputFieldInnerTextMinutes`;
export const maxConcurrentSessionsInputLabel = `${prefix}.maxConcurrentSessionsInputLabel`;
export const credentialsTimeoutThresholdInputLabel = `${prefix}.credentialsTimeoutThresholdInputLabel`;
export const inputFieldInnerTextDays = `${prefix}.inputFieldInnerTextDays`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit User Profile: {0}',
    [modalTitleNew]: 'New User Profile: {0}',
    [nameInputLabel]: 'Name',
    [maxPasswordHistoryCountInputLabel]: 'Max Password History Count',
    [maxLoginFailureCountInputLabel]: 'Max Login Failure Count',
    [accountLockTimeoutInputLabel]: 'Account Lock Timeout',
    [inputFieldInnerTextMinutes]: 'minutes',
    [maxConcurrentSessionsInputLabel]: 'Max Concurrent Sessions',
    [credentialsTimeoutThresholdInputLabel]: 'Credentials Timeout Threshold',
    [inputFieldInnerTextDays]: 'days',
    [saveButtonLabel]: 'Save',
};

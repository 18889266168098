const moduleName = 'shared';
const componentName = 'avi-data-grid';
const prefix = `${moduleName}.${componentName}`;

export const numberOfItemsLabel = `${prefix}.numberOfItemsLabel`;
export const itemsPerPageLabel = `${prefix}.itemsPerPageLabel`;

export const ENGLISH = {
    [numberOfItemsLabel]: '{0} - {1} of {2} items',
    [itemsPerPageLabel]: 'Items per page',
};

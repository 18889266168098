/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name OnePoolServerInventoryCollDataSource
 * @author Alex Malitsky
 * @description
 *
 *     DataSource to get all servers within one pool. Faster than
 *     {@link PoolServerInventoryCollDataSource} API.
 */
angular.module('aviApp').factory('OnePoolServerInventoryCollDataSource', [
'InventoryCollDataSource',
function(InventoryCollDataSource) {
    return class OnePoolServerInventoryCollDataSource extends InventoryCollDataSource {
        constructor(args = {}) {
            if (!('defaultParams' in args)) {
                args.defaultParams = {};
            }

            args.defaultParams.all_se = true;

            super(args);

            this.hasSearch = false;
            this.hasSorting = false;
        }

        /** @override */
        getRequestParams_() {
            const params = super.getRequestParams_(this.params_);

            params['objectName_'] = ['pool-inventory', this.owner_.poolId, 'server'];

            return params;
        }
    };
}]);

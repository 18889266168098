/**
 * @module CloudModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { IMessageItemData, MessageItem } from 'ajs/modules/data-model';

import {
    NsxtTier1SegmentAutomaticModeConfigItem,
    NsxtTier1SegmentManualModeConfigItem,
} from '.';

interface INsxtTier1SegmentConfigData extends IMessageItemData {
    config: INsxtTier1SegmentConfigConfig;
}

export enum NsxtTier1SegmentConfigMode {
    TIER1_SEGMENT_MANUAL = 'TIER1_SEGMENT_MANUAL',
    TIER1_SEGMENT_AUTOMATIC = 'TIER1_SEGMENT_AUTOMATIC',
}

interface INsxtTier1SegmentConfigConfig {
    segment_config_mode: NsxtTier1SegmentConfigMode;
    manual?: NsxtTier1SegmentManualModeConfigItem;
    automatic?: NsxtTier1SegmentAutomaticModeConfigItem;
}

export class NsxtTier1SegmentConfigConfigItem extends MessageItem {
    public data: INsxtTier1SegmentConfigData;

    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'NsxtTier1SegmentConfig',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter for the Tier1 Segment config under manual mode.
     */
    public get manualModeConfig(): NsxtTier1SegmentManualModeConfigItem {
        return this.config.manual;
    }

    /**
     * Check current segment config mode with a give one.
     */
    public isConfigMode(mode: NsxtTier1SegmentConfigMode): boolean {
        return this.config.segment_config_mode === mode;
    }

    /**
     * Sets properties based on the config mode, either TIER1_SEGMENT_MANUAL or
     * TIER1_SEGMENT_AUTOMATIC.
     */
    public setConfigMode(): void {
        const { segment_config_mode: configMode } = this.config;

        switch (configMode) {
            case NsxtTier1SegmentConfigMode.TIER1_SEGMENT_MANUAL:
                this.safeSetNewChildByField('manual');
                delete this.config.automatic;
                break;

            case NsxtTier1SegmentConfigMode.TIER1_SEGMENT_AUTOMATIC:
                this.safeSetNewChildByField('automatic');
                delete this.config.manual;
                break;
        }
    }

    /** @override */
    public modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        this.setConfigMode();
    }

    /**
     * Removes all configured manual Tier1 LRs.
     */
    public removeManualTier1LRs(): void {
        this.config.manual.removeTier1LRs();
    }
}

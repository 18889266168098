/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc controller
 * @name  HSMGroupDownloadCertController
 * @description
 *     Controller for prof-hsmgroup-download-cert modal that allows the user to download client
 *     certificates of a Safenet Luna HSM Group.
 */
angular.module('aviApp').controller('HSMGroupDownloadCertController', [
'$scope', 'AviModal', '$document',
function($scope, AviModal, $document) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    this.gridConfig = {
        id: 'hsm-group-download-cert',
        fields: [{
            name: 'client_ip',
            title: 'IP Address',
        }],
        rowId: 'client_ip',
        layout: {
            hideDisplaying: true,
        },
        singleactions: [{
            title: 'Download as file',
            class: 'icon-download',
            do: row => {
                $document[0].location = `data:application/octet-stream,${
                    encodeURIComponent(row.client_cert)}`;
            },
        }],
    };

    this.closeModal = function() {
        AviModal.destroy('prof-hsmgroup-download-cert');
    };
}]);

const moduleName = 'waf';
const componentName = 'waf-policy-psm-location-expander-content';
const prefix = `${moduleName}.${componentName}`;

export const matchLabel = `${prefix}.matchLabel`;
export const argumentRulesLabel = `${prefix}.argumentRulesLabel`;

export const ENGLISH = {
    [matchLabel]: 'MATCH ({0})',
    [argumentRulesLabel]: 'ARGUMENT RULES ({0})',
};

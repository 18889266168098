/** @module WafModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import classnames from 'classnames';
import { isUndefined } from 'underscore';
import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { WafMode } from 'generated-types';
import {
    WafRuleConfigItem,
    WafRuleOverridesConfigItem,
} from 'ajs/modules/waf';
import * as l10n from './waf-crs-rule.l10n';
import './waf-crs-rule.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Component for the WAF CRS rule. This differs from the waf-rule component in that this
 *     component does not allow for editing the rule directly but instead works with overrides on
 *     top of the WAF rule.
 * @author alextsg
 */
@Component({
    selector: 'waf-crs-rule',
    templateUrl: './waf-crs-rule.component.html',
})
export class WafCrsRuleComponent {
    /**
     * WafRule messageItem instance.
     */
    @Input()
    public rule: WafRuleConfigItem;

    /**
     * Mode configuration for the parent WafPolicy.
     */
    @Input()
    public parentMode: WafMode;

    /**
     * WafRuleOverridesConfigItem instance. If this is defined, it means that a user overriide
     * exists for this CRS rule.
     */
    @Input()
    public crsRuleOverride?: WafRuleOverridesConfigItem;

    /**
     * Enabled status of the parent group. If the group is not enabled, even if this rule is enabled
     * this component should show the status in gray instead of green.
     */
    @Input()
    public groupEnabled = true;

    /**
     * Emitted when editing a rule override.
     */
    @Output()
    public onEditRuleOverride = new EventEmitter<WafRuleOverridesConfigItem>();

    /**
     * Emitted when adding a rule override.
     */
    @Output()
    public onAddRuleOverride = new EventEmitter<void>();

    /**
     * Emitted when removing a rule override.
     */
    @Output()
    public onRemoveRuleOverride = new EventEmitter<WafRuleOverridesConfigItem>();

    /**
     * Expanded state. When true, rule details are expanded.
     */
    public expanded = false;

    /**
     * When true, displays the full text of the rule.
     */
    public showRule = false;

    /**
     * When true, displays the original rule details.
     */
    public showOriginal = false;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Called when Show/Hide Rule is clicked.
     */
    public handleExpand(): void {
        this.expanded = !this.expanded;

        if (this.expanded) {
            this.showRule = false;
        }
    }

    /**
     * Called when the Show/Hide Rule button is clicked. Since the click handler is a child within a
     * parent element that contains another click handler, this prevents the parent handler from
     * getting triggered.
     */
    public handleShowRule($event: MouseEvent): void {
        $event.stopPropagation();
        this.showRule = !this.showRule;
    }

    /**
     * Handler for clicking the rule textarea. Selects the text.
     */
    public handleFocusRule($event: FocusEvent): void {
        // eslint-disable-next-line no-extra-parens
        ($event.target as HTMLInputElement).select();
    }

    /**
     * Returns true if the CRS rule has an override.
     */
    public get hasOverride(): boolean {
        return Boolean(this.crsRuleOverride);
    }

    /**
     * Returns the display value for a rule name.
     */
    public get wafRuleName(): string {
        const {
            rule,
            l10nService,
        } = this;
        const ruleId = rule.getId() || l10nService.getMessage(l10nKeys.emptyDataLabel);
        const ruleName = rule.getRuleName() || l10nService.getMessage(l10nKeys.emptyDataLabel);

        return `${ruleId} | ${ruleName}`;
    }

    /**
     * Returns the enabled display setting for a rule.
     */
    public get overallEnabledSettingLabel(): string {
        return this.getEnabledSettingLabel(this.isEnabled());
    }

    /**
     * Returns the class name to be set on the enabled display setting. Enabled should show as green
     * and deactivated should show as gray.
     */
    public get overallEnabledStateClassName(): string {
        return this.getEnabledSettingClassName(this.isEnabled());
    }

    /**
     * Returns the text to be displayed to the user for a given enabled setting.
     * this.rule.isEnabled() is set as the default value because this.crsRuleOverride.enable is
     * passed in as an argument, and if it's undefined then we take the rule's enable status
     * instead.
     */
    public getEnabledSettingLabel(enabled = this.rule.isEnabled()): string {
        return enabled ?
            this.l10nService.getMessage(this.l10nKeys.enabledLabel) :
            this.l10nService.getMessage(this.l10nKeys.deactivatedLabel);
    }

    /**
     * Returns the className for a given enabled setting. this.rule.isEnabled() is set as the
     * default value because this.crsRuleOverride.enable is passed in as an argument, and if it's
     * undefined then we take the rule's enable status instead.
     */
    public getEnabledSettingClassName(enabled = this.rule.isEnabled()): string {
        const baseClassName = 'waf-rule__enabled-setting';
        const enabledClassName = enabled && this.groupEnabled ?
            `${baseClassName}--enabled` :
            `${baseClassName}--deactivated`;

        return classnames(baseClassName, enabledClassName);
    }

    /**
     * Called when the user hits the edit button. If an override exists, edits that override,
     * otherwise creates a new override.
     */
    public handleEdit(): void {
        if (this.crsRuleOverride) {
            this.onEditRuleOverride.emit(this.crsRuleOverride);
        } else {
            this.onAddRuleOverride.emit();
        }
    }

    /**
     * Called when the user removes an override.
     */
    public handleRemove(): void {
        this.onRemoveRuleOverride.emit(this.crsRuleOverride);
    }

    /**
     * Called when the user clicks the button to show the original rule.
     */
    public handleShowOriginal(): void {
        this.showOriginal = !this.showOriginal;
    }

    /**
     * Returns true if the CRS rule is enabled. If there's an override for the rule, checks the
     * status of the override, otherwise checks the status of the actual rule.
     */
    private isEnabled(): boolean {
        return this.hasOverride && !isUndefined(this.crsRuleOverride.enable) ?
            this.crsRuleOverride.isEnabled() :
            this.rule.isEnabled();
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name VRFContextCollection
 */
angular.module('aviApp').factory('VRFContextCollection', [
'Collection', 'VRFContext', 'Auth',
function(Collection, VRFContext, Auth) {
    class VRFContextCollection extends Collection {
        /**
         * Used to show/hide create button on VRF context list page.
         * Show create button when hasTenantVrf is true.
         * Hide create button when hasTenantVrf is false.
         * @override
         * @return {Boolean}
         * @public
         */
        isCreatable() {
            return Auth.hasTenantVrf && super.isCreatable();
        }

        /**
         * Returns true if any custom VRF Contexts exist, as in there exists a VRF Context that is
         * neither the default 'management' or 'global' VRF Contexts.
         * @return {Boolean} True if custom VRF Contexts exist, false otherwise.
         * @public
         */
        hasCustomVRFContext() {
            return _.any(this.items, vrf => {
                const name = vrf.getName();

                return name !== 'management' && name !== 'global';
            });
        }
    }

    const dataSourcesHash = {
        list: {
            source: 'VRFContextCollDataSource',
            transformer: 'ListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    angular.extend(
        VRFContextCollection.prototype, {
            objectName_: 'vrfcontext',
            windowElement_: 'vrf-context-modal',
            itemClass_: VRFContext,
            allDataSources_: dataSourcesHash,
            defaultDataSources_: 'list',
            restrictCreateOnEssentialLicense_: true,
        },
    );

    return VRFContextCollection;
}]);

/**
 * @module IpamModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { IAviDataGridConfig } from 'ng/shared/components';
import { SECRET_STUB_STR } from 'ng/shared/directives';
import { MessageItem, RepeatedMessageItem } from 'ajs/modules/data-model';

import * as l10n from './custom-params-grid.l10n';

const { ENGLISH, ...l10nKeys } = l10n;

/**
 * @description
 *      Grid component for customParams instances with editable grid cells.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'custom-params-grid',
    templateUrl: './custom-params-grid.component.html',
})
export class CustomParamsGridComponent {
    /**
     * List of CustomParams ConfigItems.
     */
    @Input()
    public paramsList: RepeatedMessageItem<MessageItem>;

    /**
     * Add button label.
     */
    @Input()
    public addButtonLabel?: string = 'Add';

    /**
     * If true, sensitive checkbox column will be displayed.
     */
    @Input('hasSensitiveColumn')
    private set setSenstiveColumn(hasSensitiveColumn: boolean | '') {
        this.hasSensitiveColumn = hasSensitiveColumn === '' || Boolean(hasSensitiveColumn);
    }

    /**
     * If true, dynamic checkbox column will be displayed.
     */
    @Input('hasDynamicColumn')
    private set setDynamicColumn(hasDynamicColumn: boolean | '') {
        this.hasDynamicColumn = hasDynamicColumn === '' || Boolean(hasDynamicColumn);
    }

    /**
     * TemplateRef for name input field.
     */
    @ViewChild('nameFieldTemplateRef')
    public nameFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for value input field.
     */
    @ViewChild('valueFieldTemplateRef')
    public valueFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for sensitive checkbox field.
     */
    @ViewChild('sensitiveFieldTemplateRef')
    public sensitiveFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for dynamic checkbox field.
     */
    @ViewChild('dynamicFieldTemplateRef')
    public dynamicFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * If true, dynamic checkbox column will be displayed.
     */
    public hasDynamicColumn?: boolean;

    /**
     * If true, sensitive checkbox column will be displayed.
     */
    public hasSensitiveColumn?: boolean;

    /**
     * Custom params grid config.
     */
    public customParamsGridConfig: IAviDataGridConfig;

    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(ENGLISH);
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.customParamsGridConfig = this.getCustomParamsGridConfig();
    }

    /**
     * Adds a custom paran.
     */
    public addCustomParam(): void {
        this.paramsList.add();
    }

    /**
     * Returns true if a value is sensitive.
     */
    public isSensitiveValue(value: string): boolean {
        return value === SECRET_STUB_STR;
    }

    /**
     * Returns custom params grid config.
     */
    private getCustomParamsGridConfig(): IAviDataGridConfig {
        const customParamsGridConfig: IAviDataGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [{
                label: this.l10nService.getMessage(l10nKeys.columnTitleName),
                id: 'name',
                templateRef: this.nameFieldTemplateRef,
            }, {
                label: this.l10nService.getMessage(l10nKeys.columnTitleValue),
                id: 'value',
                templateRef: this.valueFieldTemplateRef,
            }],
            multipleactions: [{
                label: this.l10nService.getMessage(l10nKeys.removeActionLabel),
                onClick: () => this.paramsList.removeAll(),
            }],
            singleactions: [{
                label: this.l10nService.getMessage(l10nKeys.removeActionLabel),
                shape: 'trash',
                onClick: (item: MessageItem) => {
                    this.paramsList.removeByMessageItem(item);
                },
            }],
        };

        if (this.hasSensitiveColumn) {
            customParamsGridConfig.fields.push({
                label: this.l10nService.getMessage(l10nKeys.columntitleSensitive),
                id: 'is_sensitive',
                templateRef: this.sensitiveFieldTemplateRef,
            });
        }

        if (this.hasDynamicColumn) {
            customParamsGridConfig.fields.push({
                label: this.l10nService.getMessage(l10nKeys.columntitleDynamic),
                id: 'is_dynamic',
                templateRef: this.dynamicFieldTemplateRef,
            });
        }

        return customParamsGridConfig;
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'waf-policy-psm-location-modal';
const prefix = `${moduleName}.${componentName}`;

export const editLocationHeader = `${prefix}.editLocationHeader`;
export const newLocationHeader = `${prefix}.newLocationHeader`;
export const generalSectionHeader = `${prefix}.generalSectionHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const matchHeader = `${prefix}.matchHeader`;
export const argumentRulesHeader = `${prefix}.argumentRulesHeader`;

export const ENGLISH = {
    [editLocationHeader]: 'Edit Location: {0}',
    [newLocationHeader]: 'New Location: {0}',
    [generalSectionHeader]: 'General',
    [nameInputLabel]: 'Name',
    [descriptionInputLabel]: 'Description',
    [matchHeader]: 'Match ({0})',
    [argumentRulesHeader]: 'Argument Rules ({0})',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function autoScalePolicyCollectionFactory(Collection, AutoScalePolicyItem) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Collection
     * @author Ram Pal
     * @desc Collection of {@link module:avi/app.AutoScalePolicy AutoScalePolicies}.
     */
    class AutoScalePolicyCollection extends Collection {
        constructor(oArgs) {
            super(oArgs);
            this.objectName_ = 'serverautoscalepolicy';
            this.itemClass_ = AutoScalePolicyItem;
            this.windowElement_ = 'app-pool-autoscale-policy';
        }
    }

    return AutoScalePolicyCollection;
}

autoScalePolicyCollectionFactory.$inject = [
    'Collection',
    'AutoScalePolicyItem',
];

angular.module('avi/app')
    .factory('AutoScalePolicyCollection', autoScalePolicyCollectionFactory);

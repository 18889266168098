/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  SensitiveFieldRuleConfig
 * @description
 *     SensitiveFieldRuleConfig class, mapped to the protobuf message SensitiveFieldRule.
 *     Used when configuring rules in Sensitive Log Profile.
 */
const sensitiveFieldRuleConfigFactory = (
        PolicyRuleConfigItem,
        SensitiveFieldRuleStringMatchConfig,
        SensitiveFieldRuleLogActionConfig,
) => {
    class SensitiveFieldRuleConfig extends PolicyRuleConfigItem {
        constructor(args) {
            super(args);

            this.actionEnabled = this.hasActions();
        }

        /**
         * Set the unique action in Sensitive Field Rule.
         */
        addLogAction() {
            this.addAction('action');
        }

        /**
         * Remove the unique action in Sensitive Field Rule.
         */
        removeLogAction() {
            this.removeAction('action');
        }

        /**
         * @override
         */
        beforeEdit() {
            super.beforeEdit();

            if (!this.hasMatches()) {
                this.addMatch('match');
            }

            this.setEnable();
        }
    }

    angular.extend(SensitiveFieldRuleConfig.prototype, {
        type: 'SensitiveFieldRule',
        matchSettingsHash: {
            match: {
                className: SensitiveFieldRuleStringMatchConfig,
                isRepeated: false,
                isNested: false,
            },
        },
        actionSettingsHash: {
            action: {
                className: SensitiveFieldRuleLogActionConfig,
                isRepeated: false,
                isNested: false,
            },
        },
    });

    return SensitiveFieldRuleConfig;
};

sensitiveFieldRuleConfigFactory.$inject = [
        'PolicyRuleConfigItem',
        'SensitiveFieldRuleStringMatchConfig',
        'SensitiveFieldRuleLogActionConfig',
];

angular.module('aviApp').factory(
    'SensitiveFieldRuleConfig', sensitiveFieldRuleConfigFactory,
);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name  virtualserviceSecurityDdosTableGridRate
 * @param {MetricsDimension} row - MetricsDimension item.
 * @param {VirtualService.data} metricData - Virtual Service data object.
 * @description
 *     Displays the rate of MetricsDimension item. If the Virtual Service metric data is available,
 *     display the rate based off the metric. Otherwise, display the rate calculated from the
 *     item data, as it loads faster.
 */
class VsSecurityDdosTopListGridCellRateController {
    constructor(myAccount, GraphSync) {
        this.myAccount_ = myAccount;
        this.GraphSync_ = GraphSync;
    }

    /**
     * Returns the rate of the MetricsDimension item.
     * @returns {string}
     * @public
     */
    getRate() {
        const
            { row } = this,
            mouseOverTheChart = this.GraphSync_.mouseIsOnGraph(),
            valuesToDisplay = mouseOverTheChart ? 'exact' :
                this.myAccount_.getValuesDisplayType(),
            series = row.getSeriesBySeriesId('dos.avg_attack_count');

        if (valuesToDisplay === 'sum' || !series.hasData()) {
            return 'N/A';
        }

        const value = series.getValue(
            valuesToDisplay,
            this.GraphSync_.mouseOnGraph,
        );

        return angular.isNumber(value) ? value.toFixed(2) : 'N/A';
    }
}

VsSecurityDdosTopListGridCellRateController.$inject = [
    'myAccount',
    'GraphSync',
];

angular.module('avi/vs/security')
    .component('vsSecurityDdosTopListGridCellRate', {
        bindings: {
            row: '<',
            metricData: '<',
        },
        controller: VsSecurityDdosTopListGridCellRateController,
        template: '<span>{{ $ctrl.getRate() }}</span>',
    });

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  GeoProfile
 * @description  GeoProfile item.
 */
const GeoProfileFactory = (Item, defaultValues, systemInfo, $q) => {
    class GeoProfile extends Item {
        /** @override */
        isEditable() {
            return systemInfo.localSiteIsGSLBLeader && super.isEditable();
        }

        /**
         * Makes a request to retrieve a list of staging files. Appends a 'filename' property
         * to each item in the list, which is the filename string stripped from the file url.
         * @return {Object[]} Array of file objects.
         */
        getStagingFiles() {
            this.busy = true;
            this.errors = null;

            return this.request('GET', '/api/fileservice?uri=controller://gslb')
                .then(({ data }) => {
                    return data.results.map(result => {
                        result.filename = this.getStagingFilename(result.url);

                        return result;
                    });
                })
                .catch(({ data }) => {
                    this.errors = data;

                    return $q.reject();
                })
                .finally(() => this.busy = false);
        }

        /**
         * Adds a default geodbfile object to config.entries.
         */
        addEntry() {
            const
                config = this.getConfig(),
                gslbGeoDBEntry = defaultValues.getDefaultItemConfigByType('gslbgeodbentry');

            config.entries = config.entries || [];
            config.entries.push(gslbGeoDBEntry);
        }

        /**
         * Removes a selected entry object from config.entries.
         * @param  {number} index - Index of the entry object to remove.
         */
        removeEntry(index) {
            const { entries } = this.getConfig();

            entries.splice(index, 1);
        }

        /**
         * Strips the filename of the file from the file URL.
         * @param  {string} url - File URL.
         * @return {string}
         */
        getStagingFilename(url) {
            return url.split('fileservice?uri=controller://gslb/')[1];
        }
    }

    angular.extend(GeoProfile.prototype, {
        objectName: 'gslbgeodbprofile',
        windowElement: 'geo-profile-modal',
    });

    return GeoProfile;
};

GeoProfileFactory.$inject = [
        'Item',
        'defaultValues',
        'systemInfoService',
        '$q',
];

angular.module('aviApp').factory('GeoProfile', GeoProfileFactory);

/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { each } from 'underscore';
import {
    ICollMetricsRequest,
    IItemMetricTuple,
    ObjectTypeItem,
    RepeatedMessageItem,
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model';
import { WafPSMLocationConfigItem } from './waf-psm-location.config-item.factory';
import { WafAction } from '../waf.types';

interface IWafPolicyPsmGroupConfig {
    enable?: boolean;
    is_learning_group?: boolean;
    name?: string;
    locations?: RepeatedMessageItem<WafPSMLocationConfigItem>;
    hit_action?: WafAction;
    miss_action?: WafAction;
}

export class WafPolicyPsmGroup extends withEditChildMessageItemMixin(ObjectTypeItem) {
    public data: {
        config: IWafPolicyPsmGroupConfig;
    };

    public getConfig: () => this['data']['config'];

    constructor(args = {}) {
        const extendedArgs = {
            permissionName: 'PERMISSION_WAFPOLICYPSMGROUP',
            objectName: 'wafpolicypsmgroup',
            objectType: 'WafPolicyPSMGroup',
            windowElement: 'waf-policy-psm-group-modal',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns the enable state.
     */
    public get enable(): boolean {
        return this.config.enable;
    }

    /**
     * Sets the enable state.
     */
    public set enable(enabled: boolean) {
        this.config.enable = enabled;
    }

    /**
     * Returns the RepeatedConfigItem for WafPsmLocations.
     */
    public get locations(): RepeatedMessageItem<WafPSMLocationConfigItem> {
        return this.config.locations;
    }

    /**
     * Returns true if the PSM Group is a learning group.
     */
    public get isLearningGroup(): boolean {
        return this.config.is_learning_group;
    }

    /**
     * Sets the is_learning_group property.
     */
    public set isLearningGroup(learning: boolean) {
        this.config.is_learning_group = learning;
    }

    /**
     * We pass '*' as the entity_uuid because the metrics are based in the context of VSes, so
     * use '*' to gather all VS data. obj_id is the id of the PSM Group.
     * TODO: Use the inventory API to get a list of VSes for each PSM Group to replace '*'.
     * @override
     */
    public getMetricsTuple(): IItemMetricTuple {
        return {
            entity_uuid: '*',
            obj_id: this.id,
            dimension_filter_op: 'METRICS_FILTER_CONTAINS',
        };
    }

    /**
     * We are overriding this to pass in the dimension_aggregation to use AggSeries.
     * @override
     */
    public getCollMetricsRequests(fields: string[]): ICollMetricsRequest[] {
        const GroupCollMetric = this.getAjsDependency_('GroupCollMetric');
        const requests: ICollMetricsRequest[] = [];

        if (Array.isArray(fields)) {
            each(fields, fieldName => {
                if (this.dataFieldIsApplicable(fieldName)) {
                    if (!(fieldName in this.collMetricsHash)) {
                        this.collMetricsHash[fieldName] = new GroupCollMetric({
                            name: fieldName,
                            dimension_aggregation: 'METRICS_DIMENSION_AGG_SUM',
                            item: this,
                            subscribers: ['GroupCollMetricRequest'],
                            series: fieldName,
                        });
                    }

                    const request = this.collMetricsHash[fieldName].beforeCall();

                    if (request) {
                        requests.push(request.series);
                    }
                }
            });
        }

        return requests;
    }

    /**
     * Creates a new location and opens the modal to edit it.
     */
    public addLocation(modalBindings: Record<string, any>): void {
        this.addChildMessageItem({
            field: 'locations',
            modalBindings,
        });
    }

    /**
     * Edits a WafPSMLocation config item.
     */
    public editLocation(
        location: WafPSMLocationConfigItem,
        modalBindings: Record<string, any>,
    ): void {
        this.editChildMessageItem({
            field: 'locations',
            messageItem: location,
            modalBindings,
        });
    }
}

WafPolicyPsmGroup.ajsDependencies = [
    'GroupCollMetric',
];

const moduleName = 'ajs-legacy';
const componentName = 'health-monitor-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const columnTitleSendInterval = `${prefix}.columnTitleSendInterval`;
export const columnTitleReceiveTimeout = `${prefix}.columnTitleReceiveTimeout`;
export const columnTitleSuccessfulChecks = `${prefix}.columnTitleSuccessfulChecks`;
export const columnTitleFailedChecks = `${prefix}.columnTitleFailedChecks`;
export const columnTitleIsFederated = `${prefix}.columnTitleIsFederated`;
export const actionBtnEdit = `${prefix}.actionBtnEdit`;
export const secInnerText = `${prefix}.secInnerText`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleType]: 'Type',
    [columnTitleSendInterval]: 'Send Interval',
    [columnTitleReceiveTimeout]: 'Receive Timeout',
    [columnTitleSuccessfulChecks]: 'Successful Checks',
    [columnTitleFailedChecks]: 'Failed Checks',
    [columnTitleIsFederated]: 'Federated',
    [actionBtnEdit]: 'Edit',
    [secInnerText]: '{0} sec',
};

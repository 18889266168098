/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../../../less/pages/profile/pki-profile-list-page.less';

angular.module('aviApp').controller('PKIProfileListController', [
'$scope', 'CRUDGridConfig', 'PKIProfileCollection',
function($scope, CRUDGridConfig, PKIProfileCollection) {
    const gridConfig = new CRUDGridConfig({
        collection: new PKIProfileCollection(),
        options: {},
    });

    const { objectName } = gridConfig.collection;

    gridConfig.id = `${objectName}-list-page`;

    gridConfig.fields = [{
        name: 'name',
        title: 'Name',
        template: '{{row.getName()}}',
        sortBy: 'name',
    }, {
        name: 'data.config.ca_certs.length',
        title: 'CA Certification',
    }, {
        name: 'data.config.crls.length',
        title: 'Certificate Revocation List (CRL)',
    }];

    const getChildGridConfig = type => {
        const
            config = {
                fields: [],
                layout: {
                    hideSearch: true,
                    hideDisplaying: true,
                },
                expandedContainerTemplate:
                    '<pre class="cert-text__expanded">{{::row.text}}</pre>',
            },
            { fields } = config;

        switch (type) {
            case 'ca':
                fields.push({
                    name: 'subject.common_name',
                    title: 'Name',
                }, {
                    name: 'issuer.common_name',
                    title: 'Issued By',
                }, {
                    name: 'not_after',
                    title: 'Expiration Date',
                });

                config.rowId = row => [
                    row.subject.common_name,
                    row.issuer.common_name,
                    row.not_after,
                ].join();

                break;

            case 'crl':
                fields.push({
                    name: 'common_name',
                    title: 'Name',
                }, {
                    name: 'next_update',
                    title: 'Expiration Date',
                });

                config.rowId = row => [
                    row.common_name,
                    row.next_update,
                ].join();

                break;
        }

        return config;
    };

    gridConfig.executeBeforeContainerExpand = function() {
        const { options } = gridConfig;

        options.caCertsGridConfig = getChildGridConfig('ca');
        options.caCertsGridConfig.id = 'pkiprofile__ca-certs';
        options.crlsGridConfig = getChildGridConfig('crl');
        options.crlsGridConfig.id = 'pkiprofile__ca-certs-revocation';
    };

    gridConfig.expandedContainerTemplate =
        `<div>
            <h3>CA Certifications</h3>
            <grid config="config.options.caCertsGridConfig"
                rows="row.getConfig()['ca_certs']"></grid>

            <h3 class="mt5">Certificate Revocation List (CRL)</h3>
            <grid config="config.options.crlsGridConfig"
                rows="row.getConfig()['crls']"></grid>
        </div>`;

    $scope.gridConfig = gridConfig;
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'dos-rate-limiter';
const prefix = `${moduleName}.${componentName}`;

export const rateLimitsInputLabel = `${prefix}.rateLimitsInputLabel`;
export const rateLimitsInputPlaceholder = `${prefix}.rateLimitsInputPlaceholder`;
export const removeBtnLabel = `${prefix}.removeBtnLabel`;

export const ENGLISH = {
    [rateLimitsInputLabel]: 'Rate Limits',
    [rateLimitsInputPlaceholder]: 'Add Rate Limit',
    [removeBtnLabel]: 'Remove {0}',
};

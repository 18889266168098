/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    IPAMDNS_TYPE_INFOBLOX,
    IPAMDNS_TYPE_INFOBLOX_DNS,
} from 'ajs/js/constants/ipam-dns.constants';

/**
 * @typedef {Object} AvailableInfobloxSubnets - Set of available infoblox v4 and v6 subnets.
 * @property {?IpAddrPrefix[]} v4
 * @property {?IpAddrPrefix[]} v6
 */

/**
 * @typedef {Object} InfobloxSubnet - Set of infoblox v4 and v6 subnets.
 * @property {?IpAddrPrefix} subnet
 * @property {?IpAddrPrefix} subnet6
 */

/**
 * Creates a hash of v4 and v6 subnets.
 * @param {InfobloxSubnet[]} usableSubnets - List of configured usable subnets.
 * @return {AvailableInfobloxSubnets}
 */
const createUsableSubnetsHash = (usableSubnets = []) => {
    const subnetsHash = {
        v4: [],
        v6: [],
    };

    usableSubnets.forEach(usableSubnet => {
        const { subnet, subnet6 } = usableSubnet;

        if (subnet) {
            subnetsHash.v4.push(subnet);
        }

        if (subnet6) {
            subnetsHash.v6.push(subnet6);
        }
    });

    return subnetsHash;
};

const ipamDnsInfobloxProfileConfigItemFactory = (
    $q,
    $http,
    MessageItem,
    UpdatableItem,
) => {
    /**
     * @class
     * @extends module:avi/dataModel.MessageItem
     * @memberOf module:avi/ipam
     * @description IpamDnsInfobloxProfile ConfigItem class.
     * @author Aravindh Nagarajan
     */
    class IpamDnsInfobloxProfileConfigItem extends MessageItem {
        /**
        * Return list of credential property names of infoblox profile.
        * @param {string} type - 'IPAMDNS_TYPE_INFOBLOX' or 'IPAMDNS_TYPE_INFOBLOX_DNS'
        * @return {string[]}
        */
        static getInfobloxProfileFingerPrintPropNames(type) {
            const propNames = ['ip_address', 'username', 'password'];

            if (type === IPAMDNS_TYPE_INFOBLOX) {
                propNames.push('network_view');
            } else if (type === IPAMDNS_TYPE_INFOBLOX_DNS) {
                propNames.push('dns_view');
            }

            return propNames;
        }

        /**
         * @constructor
         */
        constructor(args) {
            const extendedArgs = {
                ...args,
                objectType: 'IpamDnsInfobloxProfile',
            };

            super(extendedArgs);
        }

        /**
         * @override
         * @protected
         */
        requiredFields() {
            return ['ip_address'];
        }

        /**
         * @override
         */
        modifyConfigDataAfterLoad() {
            super.modifyConfigDataAfterLoad();

            if (!this.config.usable_domains) {
                this.config.usable_domains = [];
            }
        }

        /**
         * @override
         */
        modifyConfigDataBeforeSave() {
            super.modifyConfigDataBeforeSave();

            if (!this.config.usable_domains?.length) {
                delete this.config.usable_domains;
            }
        }

        /**
         * Adds usable_alloc_subnet entry to Infoblox profiles.
         */
        addUsableSubnet() {
            this.config.usable_alloc_subnets.add();
        }

        /**
         * Removes current item at index from IpamDnsInfobloxProfile::usable_alloc_subnets.
         * @param {number} index
         */
        removeUsableSubnet(index) {
            this.config.usable_alloc_subnets.remove(index);
        }

        /**
         * Adds usable_domains entry to Infoblox profiles.
         */
        addUsableDomain() {
            const {
                usable_domains: usableDomains = [],
            } = this.config;

            usableDomains.push('');
        }

        /**
         * Removes usable_domains entry to Infoblox profiles.
         * @param {number} index
         */
        removeInfobloxUsableDomain(index) {
            const {
                usable_domains: usableDomains = [],
            } = this.config;

            usableDomains.splice(index, 1);
        }

        /**
         * Returns usable subnets of IPAM infoblox profile.
         * @return {InfobloxSubnet[]}
         */
        get configuredUsableSubnets() {
            return this.config.usable_alloc_subnets.config;
        }

        /**
         * Clear usable subnets of IPAM infoblox profile.
         */
        clearUsableSubnetList() {
            this.config.usable_alloc_subnets.removeAll();
        }

        /**
         * Returns usable domains of IPAM infoblox DNS profile.
         * @return {string[]|undefined}
         */
        getInfobloxDnsProfileUsableDomainList() {
            return this.config.usable_domains;
        }

        /**
         * Clear usable domains of IPAM infoblox DNS profile.
         */
        clearInfobloxDnsProfileUsableDomaintList() {
            this.config.usable_domains = [];
        }

        /**
         * Get list of infoblox profile subnets.
         * Fetch by uuid when the profile already exists and credentials are not being changed.
         * Fetch by credentials if no uuid or changes of credentials are made.
         * @param {string=} ipamProfileId - If defined, used to fetch the domain list.
         * @return {ng.$q.promise}
         */
        getAvailableSubnets(ipamProfileId) {
            this.busy = true;

            const promise = ipamProfileId ?
                this.getAvailableSubnetsWithId_(ipamProfileId) :
                this.getAvailableSubnetsWithCredentials_();

            return promise
                .then(({ data }) => createUsableSubnetsHash(data.alloc_subnets))
                .finally(() => this.busy = false);
        }

        /**
         * Makes request for the network list with uuid.
         * @param {string} ipamProfileId - IPAM Profile UUID used to fetch the domain list.
         * @return {ng.$q.promise}
         */
        getAvailableSubnetsWithId_(ipamProfileId) {
            const api = '/api/ipamdnsproviderprofilenetworklist';

            return $http.get(`${api}?ipamdnsprovider_uuid=${ipamProfileId}&provider=true`);
        }

        /**
         * Makes request for the network list with credentials.
         * @return {ng.$q.promise}
         */
        getAvailableSubnetsWithCredentials_() {
            const api = '/api/ipamdnsproviderprofilenetworklist';
            const url = this.getUrl(api, IPAMDNS_TYPE_INFOBLOX);

            return $http.get(url);
        }

        /**
         * Gets list of infoblox dns profile domains.
         * @param {string=} ipamProfileId - If defined, used to fetch the domain list.
         * @return {ng.$q.promise}
         */
        getInfobloxDnsProfileDomainList(ipamProfileId) {
            this.busy = true;
            this.errors = null;

            const promise = ipamProfileId ?
                this.getDomainListWithId_(ipamProfileId) :
                this.getDomainListWithCredentials_();

            return promise
                .then(({ data }) => data.domains || [])
                .catch(({ data }) => $q.reject(data))
                .finally(() => this.busy = false);
        }

        /**
         * Makes request for the domain list with uuid.
         * @param {string} ipamProfileId - IPAM Profile UUID used to fetch the domain list.
         * @return {ng.$q.promise}
         */
        getDomainListWithId_(ipamProfileId) {
            const api = '/api/ipamdnsproviderprofiledomainlist';

            return $http.get(`${api}?ipamdnsprovider_uuid=${ipamProfileId}&provider=true`);
        }

        /**
         * Makes request for the domain list with credentials.
         * @return {ng.$q.promise}
         */
        getDomainListWithCredentials_() {
            const
                api = '/api/ipamdnsproviderprofiledomainlist',
                url = this.getUrl(api, IPAMDNS_TYPE_INFOBLOX_DNS);

            return $http.get(url);
        }

        /**
         * Generate infoblox profile api call url based on api and type.
         * @param {string} api - The particular api/path that is used to make the call.
         * @param {string} type - Type of infoblox profile.
         *      Could be IPAMDNS_TYPE_INFOBLOX or IPAMDNS_TYPE_INFOBLOX_DNS.
         * @protected
         */
        getUrl(api, type) {
            const paramHash = this.getParams_(type);

            return UpdatableItem.getUrl(api, paramHash);
        }

        /**
         * Get infoblox profile param hash.
         * @param {string} type
         * @return {Object.<string, string>}
         * @protected
         */
        getParams_(type) {
            const paramHash = angular.copy(this.config);
            const { addr } = paramHash['ip_address']['config'];
            const paramsToKeep =
                IpamDnsInfobloxProfileConfigItem.getInfobloxProfileFingerPrintPropNames(type);

            paramHash['ip_address'] = addr;

            const filteredParamHash = _.pick(paramHash, paramsToKeep);

            filteredParamHash.type = type;

            return filteredParamHash;
        }

        /**
         * Removes element from extensible_attributes array by index.
         * @param {number} index
         */
        removeCustomParams(index) {
            this.config.extensible_attributes.remove(index);
        }

        /**
         * Adds new CustomParams to extensible_attributes array.
         */
        addCustomParams() {
            this.config.extensible_attributes.add();
        }

        /**
         * Clear password for the profile.
         */
        clearProfilePassword() {
            delete this.config.password;
        }
    }

    return IpamDnsInfobloxProfileConfigItem;
};

ipamDnsInfobloxProfileConfigItemFactory.$inject = [
    '$q',
    '$http',
    'MessageItem',
    'UpdatableItem',
];

angular
    .module('avi/ipam')
    .factory('IpamDnsInfobloxProfileConfigItem', ipamDnsInfobloxProfileConfigItemFactory);

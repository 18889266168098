/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name modalWizardTab
 * @description
 *     Component for a tab in the modalContainer component.
 * @param {string} tabTitle - Name of the tab.
 * @param {boolean} hideTab - True if the tab should be hidden.
 * @param {Object} helpPopver - Contains 'anchor' and 'position' properties to be passed to the
 *     subtab-nav component.
 */
class ModalWizardTabController {
    $onInit() {
        this.modalContainerCtrl.addModalWizardTab(this);
    }
}

angular.module('aviApp').component('modalWizardTab', {
    controller: ModalWizardTabController,
    transclude: true,
    require: {
        modalContainerCtrl: '^modalContainer',
    },
    bindings: {
        tabTitle: '@',
        hideTab: '<',
        helpPopover: '<',
    },
    template:
            '<div ng-if="$ctrl.modalContainerCtrl.isSelected($ctrl)" ng-transclude></div>',
});

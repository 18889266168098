const moduleName = 'ajs-legacy';
const componentName = 'health-monitor-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const columnTitleSendInterval = `${prefix}.columnTitleSendInterval`;
export const columnTitleReceiveTimeout = `${prefix}.columnTitleReceiveTimeout`;
export const columnTitleSuccessfulChecks = `${prefix}.columnTitleSuccessfulChecks`;
export const columnTitleFailedChecks = `${prefix}.columnTitleFailedChecks`;
export const columnTitleRequest = `${prefix}.columnTitleRequest`;
export const columnTitleResponseString = `${prefix}.columnTitleResponseString`;
export const columnTitleResponseCode = `${prefix}.columnTitleResponseCode`;
export const actionBtnEdit = `${prefix}.actionBtnEdit`;
export const secInnerText = `${prefix}.secInnerText`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleType]: 'Type',
    [columnTitleSendInterval]: 'Send Interval',
    [columnTitleReceiveTimeout]: 'Receive Timeout',
    [columnTitleSuccessfulChecks]: 'Successful Checks',
    [columnTitleFailedChecks]: 'Failed Checks',
    [columnTitleRequest]: 'Request',
    [columnTitleResponseString]: 'Response String',
    [columnTitleResponseCode]: 'Response Code',
    [actionBtnEdit]: 'Edit',
    [secInnerText]: '{0} sec',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './pool-group-create.less';

class Controller {
    constructor(
        Regex,
        defaultValues,
        PoolCollection,
        PoolGroup,
        PoolGroupDeploymentPolicyCollection,
        schemaService,
        dropDownUtils,
    ) {
        this.Regex = Regex;
        this.defaultValues_ = defaultValues;
        this.PoolGroup_ = PoolGroup;
        this.schemaService = schemaService;

        const deploymentStateEnums = schemaService.getEnumValues('PoolDeploymentState');

        this.deploymentStateOptions = dropDownUtils
            .createOptionsFromEnumProps(deploymentStateEnums);

        this.poolCollection = new PoolCollection({
            params: {
                referred_by: 'virtualservice:none,httppolicyset:none,datascriptset:none',
            },
        });

        this.poolGroupDeploymentPolicyCollection = new PoolGroupDeploymentPolicyCollection();
    }

    $onInit() {
        const { editable: poolGroup } = this;

        /**
         * Index of the row (member pool) being edited.
         * @type {number|undefined}
         */
        this.editIndex = undefined;

        const cloudRef = poolGroup.getCloudRef();

        // If cloudSelected is set, cloud is already preselected by User,
        // So we can skip cloud selection.
        this.cloudIsSet = this.cloudSelected || poolGroup.id;

        if (this.cloudIsSet) {
            this.setCloud(cloudRef);
        }

        /**
         * Inner controller variable, poolGroup doesn't have vrf_ref property.
         * @type {string|undefined}
         */
        this.vrfContextRef = this.vrfContextRef || '';

        /**
         * Flag to render vrf context selector after cloud selection.
         * Selector is shown for create only.
         * @type {boolean}
         */
        this.vrfContextIsSet = !!this.vrfContextRef;

        //passed by modal opener, for ex VS create modal could pass it
        if (this.vrfContextIsSet) {
            this.onVRFContextSet();
        } else if (poolGroup.id) {
            // for edit we need to figure out VRF context used by the poolGroup
            // once it is loaded (vrfContextRef is set) we will enable pool selection dropdown
            // at this point nothing prevents us from rendering main form

            this.vrfContextIsSet = true;

            poolGroup.getVRFContextRef()
                .then(vrfContextRef => {
                    this.vrfContextRef = vrfContextRef;
                    this.onVRFContextSet();
                })
                .catch(() => this.vrfContextIsSet = false);
        }

        this.gridConfig = {
            id: 'pool-group-members',
            rowId: row => `${row.pool_ref}-${row.priority_label}`,
            layout: {
                hideDisplaying: true,
            },
            fields: [{
                name: 'name',
                title: 'Name',
                template: '{{ row.pool_ref.name() }}',
            }, {
                name: 'ratio',
                title: 'Ratio',
                label: this.schemaService.getFieldDescription('PoolGroupMember', 'ratio'),
                sortBy: 'ratio',
            }, {
                name: 'priority_label',
                title: 'Priority',
                label: this.schemaService.getFieldDescription('PoolGroupMember', 'priority_label'),
                sortBy: this.PoolGroup_.gridSortByPriority,
            }, {
                name: 'deployment_state',
                title: 'Deployment State',
                template: '{{ row.deployment_state.enumeration() }}',
            }],
            rowClass: row => {
                const config = this.editable.getConfig();

                return config.members && config.members.indexOf(row) === this.editIndex ?
                    'selected' : null;
            },
            searchFields: [
                    'pool_ref.name()',
            ],
        };

        this.gridConfig.multipleactions = [{
            title: 'Remove',
            do: rows => {
                rows.forEach(row => {
                    const
                        config = this.editable.getConfig(),
                        index = config.members.indexOf(row);

                    config.members.splice(index, 1);
                });
                this.updateSelectedPoolsFilter();

                return true;
            },
            disabled: () => !angular.isUndefined(this.editIndex),
        }];

        this.gridConfig.singleactions = [{
            title: 'Edit',
            class: 'icon-pencil-4 sel-pool-edit',
            do: row => {
                const
                    config = this.editable.getConfig(),
                    index = config.members.indexOf(row);

                this.poolGroupMember = angular.copy(config.members[index]);
                this.editIndex = index;
                this.showPoolGroupMemberFields = true;
            },
        }];

        /**
         * Set poolGroupMember to default values to be shown in input fields.
         * @type {PoolGroupMember}
         */
        this.poolGroupMember =
                this.defaultValues_.getDefaultItemConfigByType('poolgroupmember');

        /**
         * Flag to show/hide input fields for adding group members.
         * @type {boolean}
         */
        this.showPoolGroupMemberFields = false;
        this.updateSelectedPoolsFilter();
    }

    /**
     * Update poolCollection to filter out already selected pools
     */
    updateSelectedPoolsFilter() {
        const { members: poolMembers = [] } = this.editable.getConfig();

        const poolIdList = poolMembers
            .map(member => member.pool_ref.slug())
            .join();

        this.poolCollection.setParams({
            'uuid.in': poolIdList,
            exclude: 'uuid.in',
        });
    }

    /**
     * Adds a pool group member to the list of members.
     */
    addPoolGroupMember() {
        const { poolGroupMember } = this;

        if (!poolGroupMember || !poolGroupMember.pool_ref) {
            return;
        }

        const
            { editable: poolGroup } = this,
            config = poolGroup.getConfig();

        if (!angular.isUndefined(this.editIndex)) {
            config.members[this.editIndex] = poolGroupMember;
            this.editIndex = undefined;
        } else {
            config.members = config.members || [];
            config.members.push(poolGroupMember);
        }

        this.poolGroupMember =
                this.defaultValues_.getDefaultItemConfigByType('poolgroupmember');
        this.showPoolGroupMemberFields = false;
        this.updateSelectedPoolsFilter();
    }

    /**
     * Cancels adding/editing pool group member.
     */
    cancelAddMember() {
        this.editIndex = undefined;
        this.poolGroupMember =
                this.defaultValues_.getDefaultItemConfigByType('poolgroupmember');
        this.showPoolGroupMemberFields = false;
    }

    /**
     * Called by cloudSetter component. Sets the "cloud_ref" property in this.editable
     * and sets the "cloud_ref.uuid" param in poolCollection so that we only get a list of
     * pools in the specified cloud.
     * @param {string} cloudRef - Cloud item url.
     */
    setCloud(cloudRef) {
        const { editable: poolGroup } = this;

        poolGroup.getConfig()['cloud_ref'] = cloudRef;

        const cloudId = cloudRef.slug();

        this.poolCollection.setParams({
            'cloud_ref.uuid': cloudId,
        });

        this.poolCollection.setDefaultItemConfigProps({
            cloud_ref: cloudId,
        });

        this.cloudIsSet = true;
    }

    /**
     * Called on init for edit or on "next" button click after VRF context selection
     * performed by the user.
     */
    onVRFContextSet() {
        const { vrfContextRef } = this;

        //FIXME switch this on once AV-45712 is done
        /*this.poolCollection.setParams({
                refers_to: `vrfcontext:${vrfContextRef.slug()}`,
                depth: 1
            });*/

        this.poolCollection.setDefaultItemConfigProps({
            vrf_ref: vrfContextRef,
        });

        this.vrfContextIsSet = true;
    }

    $onDestroy() {
        this.poolCollection.destroy();
        this.poolGroupDeploymentPolicyCollection.destroy();
    }
}

Controller.$inject = [
    'Regex',
    'defaultValues',
    'PoolCollection',
    'PoolGroup',
    'PoolGroupDeploymentPolicyCollection',
    'schemaService',
    'dropDownUtils',
];

/**
 * @ngdoc component
 * @name  poolGroupCreate
 * @param {PoolGroup} editable - PoolGroup item.
 * @param {string=} vrfContextRef - Pre selected VRF context ref, optional.
 * @param {Function} closeModal - Closes modal.
 * @param {boolean?} cloudSelected - True, if cloud is pre-selected VS-Create modal.
 * @description
 *     Creates/edits a pool group item.
 */
angular.module('aviApp').component('poolGroupCreate', {
    bindings: {
        editable: '=',
        vrfContextRef: '<',
        closeModal: '&',
        cloudSelected: '<?',
    },
    controller: Controller,
    templateUrl: 'src/components/modals/applications/poolgroup/pool-group-create.html',
});

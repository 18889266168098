const moduleName = 'ip-reputation-db';
const componentName = 'ip-reputation-change-request-card';
const prefix = `${moduleName}.${componentName}`;

export const header = `${prefix}.header`;
export const buttonLabel = `${prefix}.buttonLabel`;
export const changeButtonDescription = `${prefix}.changeButtonDescription`;
export const description = `${prefix}.description`;

export const ENGLISH = {
    [header]: 'Change Request',
    [buttonLabel]: 'Change Request',
    [changeButtonDescription]: 'Clicking will open in a new tab',
    [description]: 'Incorrectly identified URLs or IPs can be submitted for a change request through BrightCloud link below:',
};

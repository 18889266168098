/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name  metricValuesSelector
 * @description  Displays traffic capture status.
 */

import * as l10n from './metricValueSelector.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

function Controller(myAccount, l10nService) {
    this.$onInit = function() {
        this.displayValue = myAccount.uiProperty.valuesToDisplay;
    };

    this.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    this.onDisplayValueChange = function() {
        myAccount.setValuesToDisplay(this.displayValue);
    };
}

Controller.$inject = [
        'myAccount',
        'l10nService',
];

angular.module('aviApp').component('metricValuesSelector', {
    controller: Controller,
    template: require('./metrics-display-values.partial.html'),
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function seGroupInventoryDataTransformerFactory(InventoryDataTransformer, Auth) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.InventoryDataTransformer
     * @author Ashish Verma
     * @desc InventoryDataTransformer of {@link module:avi/app.SeGroupInventory
     *        SeGroupInventory}.
     */
    class SeGroupInventoryDataTransformer extends InventoryDataTransformer {
        //Need to support old weird data structure
        /** @override */
        processResponse(resp, request) {
            resp = this.responseListOffsetControl_(resp, request);

            if ('results' in resp.data && Array.isArray(resp.data.results)) {
                resp.data.results = resp.data.results.map(function(seGroup) {
                    if (this.owner_.showTenantName && Auth.allTenantsMode()) {
                        const tenantName = ` (${seGroup.config.tenant_ref.name()})`;

                        seGroup.config.name += tenantName;
                        seGroup.config.url += tenantName;
                    }

                    return {
                        config: seGroup.config,
                        inventory: seGroup,
                    };
                }, this);
            }

            return resp;
        }
    }

    return SeGroupInventoryDataTransformer;
}

seGroupInventoryDataTransformerFactory.$inject = [
    'InventoryDataTransformer',
    'Auth',
];

angular.module('avi/app').factory(
    'SeGroupInventoryDataTransformer',
    seGroupInventoryDataTransformerFactory,
);

/**
 * @module LoginModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Output,
} from '@angular/core';

import {
    Auth,
    InitialDataService,
} from 'ajs/modules/core';

import './alb-login.component.less';

import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './alb-login.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @desc
 *      Login component
 *          - contains username and password fields.
 *          - triggers login call to server.
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'alb-login',
    templateUrl: './alb-login.component.html',
})
export class AlbLoginComponent {
    /**
     * Fires on login success.
     */
    @Output() public onSuccess: EventEmitter<void> = new EventEmitter();

    /**
     * Fires when user clicks on Forgot password button.
     */
    @Output() public onForgotPassword: EventEmitter<void> = new EventEmitter();

    /**
     * ngModel for username field.
     */
    public username = '';

    /**
     * ngModel for password field.
     */
    public password = '';

    /**
     * Error message placeholder.
     */
    public errorMessage = '';

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Flag to track login call status.
     * Used to show spinner in Login button.
     */
    public loginInProgress = false;

    public constructor(
        private readonly authService: Auth,
        private readonly initialDataService: InitialDataService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Initiates login request
     */
    public login(): void {
        const { username, password } = this;

        this.errorMessage = null;
        this.loginInProgress = true;

        this.authService.login(username, password)
            .then(() => {
                this.onSuccess.emit();
            })
            .catch((errorObject: any) => {
                this.errorMessage = errorObject.error;

                this.loginInProgress = false;
            });
    }

    /**
     * Checks whether we can show forgot password button
     */
    public showForgotPassword(): boolean {
        return this.initialDataService.isEmailConfigurationSet;
    }

    /**
     * Fires when user clicks on Forgot password button.
     */
    public resetPassword(): void {
        this.onForgotPassword.emit();
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc directive
 * @name aviKnob
 * @description Dial chart by jQuery 'jquery-knob' plugin.
 * @see {@link https://github.com/aterrien/jQuery-Knob}
 */
angular.module('aviApp').directive('aviKnob', function() {
    return {
        restrict: 'A',
        scope: {
            data: '=',
            width: '=',
            height: '=',
            min: '=',
            max: '=',
            color: '@',
        },
        link(scope, element, attrs) {
            element.css('pointer-events', 'none');
            scope.$watch('data', function() {
                const knob = $('<input>')
                    .val(scope.data || 0)
                    .data('min', scope.min || 0)
                    .data('max', scope.max || 100)
                    .data('width', scope.width || 100)
                    .data('height', scope.height || 100)
                    .knob({
                        readOnly: !_.isUndefined(attrs.readonly),
                        fgColor: scope.color,
                    });

                element.empty().append(knob);

                if (!_.isUndefined(scope.data)) {
                    element.find('input').val(`${scope.data}%`);
                }
            });
        },
    };
});

/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
} from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { IDragAndDropIndices } from 'ng/shared/shared.types';
import {
    WafPolicyPsmGroup,
    WafPolicyPsmGroupMetric,
    WafPolicyPsmGroupMetricSumData,
    WafPSMRuleConfigItem,
} from 'ajs/modules/waf';
import './waf-policy-psm-rules-list.less';
import {
    WAF_PSMRULE_SUM_EVALUATED_METRIC,
    WAF_PSMRULE_SUM_MATCHED_METRIC,
} from '../../../../waf.constants';

type TWafPolicyPsmGroupMetric = typeof WafPolicyPsmGroupMetric;

/**
 * @desc Component for displaying a WAF Policy PSM rule in the WAF Policy modal.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-rules-list',
    templateUrl: './waf-policy-psm-rules-list.component.html',
})
export class WafPolicyPsmRulesListComponent {
    @Input() public rules: WafPSMRuleConfigItem[];
    @Input() public preventEdit: boolean;
    @Input() public psmGroupUuid: WafPolicyPsmGroup['id'];

    /**
     * Called to edit a rule.
     */
    @Output() public onEdit = new EventEmitter();

    /**
     * Called to delete a rule.
     */
    @Output() public onDelete = new EventEmitter<number>();

    /**
     * Called to move a rule.
     */
    @Output() public onMove = new EventEmitter<IDragAndDropIndices>();

    private ruleMetrics: WafPolicyPsmGroupMetric;

    public constructor(
        @Inject('Timeframe') private Timeframe: any,
        @Inject(WafPolicyPsmGroupMetric) private WafPolicyPsmGroupMetric: TWafPolicyPsmGroupMetric,
    ) {}

    /**
     * @override
     */
    public ngOnInit(): void {
        const { step, limit } = this.Timeframe.selected();

        this.ruleMetrics = new this.WafPolicyPsmGroupMetric({
            id: this.psmGroupUuid,
            metricIds: [WAF_PSMRULE_SUM_MATCHED_METRIC, WAF_PSMRULE_SUM_EVALUATED_METRIC],
            params: {
                limit,
                step,
            },
        });

        this.ruleMetrics.load();
    }

    /**
     * Handler for editing a PSM rule.
     */
    public handleEdit(rule: WafPSMRuleConfigItem): void {
        this.onEdit.emit(rule);
    }

    /**
     * Handler for deleting a PSM rule.
     * @param index - Index of the PSM rule to remove.
     */
    public handleDeleteRule(index: number): void {
        this.onDelete.emit(index);
    }

    /**
     * Handler for the drag-and-drop event.
     */
    public handleDrop(dragEvent: CdkDragDrop<void>): void {
        const { previousIndex, currentIndex } = dragEvent;

        this.onMove.emit({
            previousIndex,
            currentIndex,
        });
    }

    /**
     * Returns the number of location matches for a location.
     */
    public getMetricsByRuleId(ruleId: string): WafPolicyPsmGroupMetricSumData {
        return this.ruleMetrics.getMetricsById(ruleId);
    }

    /**
     * @override
     */
    public ngOnDestroy(): void {
        this.ruleMetrics.destroy();
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './nat-policy-modal.component.less';
import * as l10n from './nat-policy-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const componentName = 'nat-policy-modal';

/**
 * @constructor
 * @memberOf module:avi/policies/nat
 * @mixes module:avi/policies/nat.natPolicyModalComponentBindings
 * @see {@link module:avi/policies/nat.natPolicyModalComponent natPolicyModalComponent}
 */
class NatPolicyModalController {
    constructor(
        Regex,
        AviModal,
        l10nService,
    ) {
        this.Regex = Regex;
        /**
         * @protected
         */
        this.aviModal_ = AviModal;

        /**
        * Get keys from source bundles for template usage.
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Adds a new NAT rule to NAT Policy on save
     */
    addNatRule() {
        this.editRule(this.editable.createNewRule());
    }

    /**
     * Opens NatRule modal and creates/Edits a NatRule configItem.
     * @param {module:avi/policies/nat.NatRuleConfigItem} rule
     */
    editRule(rule) {
        this.aviModal_.open('nat-rule-modal', {
            rule: rule.clone(),
            onSubmit: ['rule', rule => {
                this.editable.addRule(rule);
            }],
        });
    }
}

NatPolicyModalController.$inject = [
    'Regex',
    'AviModal',
    'l10nService',
];

/**
 * @name natPolicyModalComponent
 * @memberOf module:avi/policies/nat
 * @property {module:avi/policies/nat.NatPolicyModalController} controller
 * @property {module:avi/policies/nat.natPolicyModalComponentBindings} bindings
 * @desc NAT Policy Modal Component
 * @author Aravindh Nagarajan
 */
angular.module('avi/policies/nat').component('natPolicyModal', {
    /**
     * @mixin natPolicyModalComponentBindings
     * @memberOf module:avi/policies/nat
     * @property {module:avi/policies/nat.NatPolicy} editable NAT Policy Item
     */
    bindings: {
        editable: '<',
    },
    controller: NatPolicyModalController,
    templateUrl:
        `src/components/modals/${componentName}/${componentName}.component.html`,
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './fqdn-lookup.l10n';
import './fqdn-lookup.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class FqdnLookupController {
    constructor(Regex, l10nService) {
        this.Regex = Regex;
        l10nService.registerSourceBundles(dictionary);
        this.l10nService_ = l10nService;
        this.l10nKeys = l10nKeys;
    }

    $onInit() {
        this.vip = this.vsVip.getVip(0);
        this.dnsInfo = this.vsVip.getDnsInfo(0);

        this.fqdnModel = this.getFqdnModel();
        this.ip = this.vip.getAnyVipAddress();
        this.resolutions = [];

        if (this.fqdnModel && !this.Regex.ip.test(this.fqdnModel)) {
            this.getResolutions();
        }

        const {
            l10nKeys,
            l10nService_: l10nService,
        } = this;

        /**
         * Returns Vip IP or Fqdn Label based on 'auto_allocate_ip'.
         * @return {string}
         */
        this.getVipIPorFqdnLabel = () => {
            return this.vip.getConfig().auto_allocate_ip ?
                'IPv4 VIP' :
                l10nService.getMessage(l10nKeys.autoAllocateIPFieldLabelWithFqdn);
        };

        // Prefix length input field is shown if it doesn't have the default value.
        this.isPrefixLengthEnabled = !this.vip.prefixLengthMatchesDefault();
    }

    /**
     * Returns the fqdnModel to be used, based on the "fqdn" and "ip" properties. If the input
     * is an IP address, we set the "ip_address" property on the Vip config and leave
     * the "fqdn" property undefined. If the input is a hostname or URL, we do a DNS lookup to
     * retrieve its IP address, then populate "fqdn" with the hostname and "ip_address" with the
     * IP Address.
     * @return {string}
     */
    getFqdnModel() {
        let output;
        const { fqdn } = this.dnsInfo.getConfig();
        const vipAddress = this.vip.getVipAddress();

        if (!angular.isUndefined(fqdn)) {
            output = fqdn;
        } else if (this.Regex.ip.test(vipAddress)) {
            output = vipAddress;
        }

        return output;
    }

    /**
     * Called by ngChange on the "fqdnModel" input. If the "fqdnModel" is an IP address, set
     * "fqdn" to undefined and "ip_address" to the "fqdnModel" IP address. Otherwise, do a DNS
     * lookup on the "fqdnModel" input.
     */
    lookupDomain() {
        this.resolutions = [];

        if (this.Regex.ip.test(this.fqdnModel)) {
            this.setFqdn(undefined);
            this.setIpAddr(this.fqdnModel);
            this.ip = this.fqdnModel;
        } else {
            this.setFqdn(this.fqdnModel);
            this.setIpAddr(undefined);
            this.ip = undefined;

            this.getResolutions()
                .then(() => {
                    if (this.resolutions.length > 0) {
                        this.setIpAddr(this.resolutions[0]);
                        [this.ip] = this.resolutions;
                    }
                });
        }
    }

    /**
     * Perform DNS.lookup on fqdnModel input.
     * @return {ng.$q.promise}
     */
    getResolutions() {
        return this.dnsInfo.domainLookup(this.fqdnModel)
            .then(ips => this.resolutions = ips);
    }

    /**
     * Sets the ip_address property in the vip object.
     * @param {string} address - IP address string
     */
    setIpAddr(address) {
        this.vip.setIpAddr(address);
    }

    /**
     * Sets the fqdn property in the dnsInfo object.
     * @param {string} fqdn - FQDN hostname string.
     */
    setFqdn(fqdn) {
        this.dnsInfo.setFqdn(fqdn);
    }

    /**
     * Handles IP selection from the dropdown of domain resolutions.
     */
    handleIpChange() {
        this.setIpAddr(this.ip);
    }

    /**
     * Handles Auto Allocate checkbox change. Clears fqdn and IP address properties.
     */
    handleAutoAllocateIpChange() {
        this.fqdnModel = undefined;
        this.ip = undefined;
        this.resolutions = [];
        this.setFqdn(undefined);
        this.setIpAddr(undefined);
        this.vip.setIpAddr(undefined, 'ip6_address');
        this.vip.clearVipNetwork();
        this.vip.clearVipSubnet();
    }

    /**
     * Handles prefix length checkbox change. If checkbox is unchecked, prefix_legth
     * value is restored to the default value.
     */
    handlePrefixLengthChange() {
        if (!this.prefixLengthEnabled) {
            this.vip.restoreDefaultPrefixLength();
        }
    }
}

FqdnLookupController.$inject = [
    'Regex',
    'l10nService',
];

/**
 * @ngdoc component
 * @name fqdnLookup
 * @description
 *     Allows the user to populate DnsInfo#data#config#fqdn, Vip#data#config#ip_address, or
 *     Vip.auto_allocate_ip_address properties.
 *     If the input is a domain name, a lookup is performed to retrieve a list of IP addresses.
 *     If there are multiple IPs, a dropdown is displayed for the user to select the address,
 *     otherwise Vip#data#config#ip_address is autopopulated with the address. If 'Auto Allocate'
 *     is allowed and is selected, all fields are cleared.
 * @param {boolean} allowAutoAllocate - True to allow selecting Auto Allocate. True when the cloud
 *     is OpenStack or if the cloud is not AWS and has a DNS provider profile configured.
 * @param {VsVip} vsVip - VsVip instance in VirtualService#data#config. Contains repeated VsVip
 *     and DnsInfo objects.
 * @param {string} createMode - 'advanced' or 'basic'. Used to differentiate between 'advanced' and
 *     'basic' Virtual Service creation. Vip sharing is only allowed in 'advanced' creation.
 * @param {string} cloudType - Supported Cloud type names. Used to determine the provision of
 *      IPV6's support.
 */
angular.module('aviApp').component('fqdnLookup', {
    bindings: {
        allowAutoAllocate: '<',
        vsVip: '<',
        createMode: '<',
        cloudType: '<',
    },
    controller: FqdnLookupController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-vip-address/' +
            'fqdn-lookup/fqdn-lookup.html',
});

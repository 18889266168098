/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { IGCPCredentials } from 'generated-types';
import { IMessageItemData, MessageItem } from 'ajs/modules/data-model';

interface IGCPCredentialsData extends IMessageItemData {
    config: IGCPCredentials;
}

export class GCPCredentialsConfigItem extends MessageItem {
    public data: IGCPCredentialsData;

    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'GCPCredentials',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public isValid(): boolean {
        return this.canFlatten();
    }

    /** @override */
    public canFlatten(): boolean {
        return Boolean(this.config.service_account_keyfile_data);
    }

    /**
     * Deletes configured fields.
     */
    public clearFields(): void {
        delete this.config.service_account_keyfile_data;
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').factory('ServerHealthScoreMonitorListDataTransformer', [
'RevisedDataTransformer', 'schemaService',
function(RevisedDataTransformer, schemaService) {
    return class ServerHealthScoreMonitorListDataTransformer extends RevisedDataTransformer {
        /** @override */
        processResponse(resp) {
            const
                results = [],
                { owner_: owner } = this,
                [{ data: hmonstatBySE }] = resp,
                [fullHmonStat] = hmonstatBySE,
                { server_hm_stat: [hmonStat] } = fullHmonStat,
                { shm_runtime: monitors } = hmonStat;

            if (Array.isArray(monitors)) {
                results.push(...monitors.map(monitor => {
                    const res = {
                        name: monitor.health_monitor_name,
                        type: monitor.health_monitor_type.enumeration('HEALTH_MONITOR_'),
                        state: !!monitor.state,
                        request_string: monitor.request_string,
                        resptime: monitor.recent_response_time,
                        respStr: monitor.response_string,
                        avgRespTime: monitor.avg_response_time,
                        respCode: `${monitor.response_code || ''}`,
                        success: Math.round((1 - monitor.total_failed_checks /
                            monitor.total_checks) * 100),
                    };

                    if (!monitor.state) {
                        if (monitor.bad_response_string !== monitor.response_string) {
                            res.bad_response_string = monitor.bad_response_string;
                        }

                        res.reason = monitor.curr_count && monitor.curr_count.length &&
                            monitor.curr_count[0].type &&
                            schemaService.getEnumValue(
                                'SHMFailure',
                                monitor.curr_count[0].type,
                            ).description;

                        res.sslErrorCode = monitor.ssl_error_code &&
                            schemaService.getEnumValue(
                                'AviDefinedFilters',
                                monitor.ssl_error_code,
                            ).label;
                    }

                    res.id = owner.getItemIdFromData(res);

                    return res;
                }));
            }

            const
                [, { data: internal }] = resp,
                [{ server_stats: serverStats }] = internal.results;

            if (serverStats) {
                const internalMonitorState = serverStats.inlhm_bad_ewma &&
                    serverStats.inlhm_good_ewma / (serverStats.inlhm_bad_ewma * 4) || 1;

                const internalMonitor = {
                    name: 'Passive',
                    type: 'Passive',
                    success: !_.isNaN(+internalMonitorState) && _.isFinite(+internalMonitorState) &&
                        Math.min(100, Math.round(100 * internalMonitorState)) || undefined,
                };

                internalMonitor.state = !!internalMonitor.success;

                internalMonitor.id = owner.getItemIdFromData(internalMonitor);

                results.push(internalMonitor);
            }

            results.sort(ServerHealthScoreMonitorListDataTransformer.sortHealthMonitors_);

            return {
                data: {
                    results: results.map(config => ({ config })),
                    count: results.length,
                },
            };
        }

        /**
         * Sorts Health Monitors to provide list order consistency.
         * @param {ServerHSmonitor[]} a
         * @param {ServerHSmonitor[]} b
         * @return {number} - 1, 0, -1
         * @private
         */
        static sortHealthMonitors_(a, b) {
            return a.state - b.state ||
                a.name.localeCompare(b.name, undefined, { numeric: true });
        }
    };
}]);

/**
 * @module SystemModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { Collection } from 'ajs/modules/data-model';

/**
 * @desc Collection for BackupConfiguration items.
 * @author Zhiqian Liu
 */
export class BackupConfigurationCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'backupconfiguration',
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('BackupConfiguration');
    }
}

BackupConfigurationCollection.ajsDependencies = [
    'BackupConfiguration',
];

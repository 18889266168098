/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './TenantSettingsService.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

function TenantSettingsService(
    Auth,
    $q,
    systemConfig,
    Base,
    defaultValues,
    l10nService,
) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Base
     * @description
     *      Tennant settings service.
     * @see {@link module:avi/app.tenantSettingsService}
     * @author Ashish Verma
     */
    class TenantSettingsService extends Base {
        constructor() {
            super();

            /**
             * DefaultValues service reference.
             * @type {DefaultValues}
             * @protected
             */
            this.defaultValues_ = defaultValues;

            /**
             * Event type triggered when tenant config is updated.
             * @const {string}
             */
            this.parseMessagesEvent = 'parseMessages';

            /**
             * IP Route Domain setting.
             * @type {string}
             */
            this.ipRoutesDomain = '';

            /**
             * Indicates if tenant config is present in the system settings.
             * @type {boolean}
             */
            this.hasConfig = false;

            this.l10nKeys = l10nKeys;

            this.l10nService_ = l10nService;

            l10nService.registerSourceBundles(dictionary);

            /**
             * IP Routes Domain message.
             * @type {string}
             */
            this.ipRoutesDomainMessage = l10nService.getMessage(l10nKeys.emptyDataLabel);
            /**
             * Service Engine message.
             * @type {string}
             */
            this.seProviderContextMessage = l10nService.getMessage(l10nKeys.emptyDataLabel);
            /**
             * SE access message
             * @type {string}
             */
            this.tenantAccessProviderSeMessage = l10nService.getMessage(l10nKeys.emptyDataLabel);

            /**
             * All messages for SE Group Privileges.
             * @type {Array<string>}
             */
            this.seProviderContextMessages = [
                l10nKeys.seProviderContextShareMessage,
                l10nKeys.seProviderContextNotShareMessage,
            ];

            /**
             * All messages for IP Route Domain.
             * @type {Array<string>}
             */
            this.ipRoutesDomainMessages = [
                l10nKeys.ipRoutesDomainPerTenantMessage,
                l10nKeys.ipRoutesDomainShareTenantMessage,
            ];

            /**
             * All SE Access messages.
             * @type {Array<string>}
             */
            this.tenantAccessProviderSeMessages = [
                l10nKeys.tenantReadAccessToSeMessage,
                l10nKeys.tenantNoAccessToSeMessage,
            ];
        }

        /**
         * Sets internal properties based on systemConfig#global_tenant_config meaning that
         * Auth#systemConfig must be present. If not present and user is authenticated this
         * method will load it.
         * @returns {ng.$q.promise}
         * @public
         */
        parseMessages() {
            let promise;

            if (Auth.isLoggedIn()) {
                if (systemConfig.isReady()) {
                    this.parseMessages_(systemConfig.getConfig()['global_tenant_config']);
                    promise = $q.when(true);
                } else {
                    promise = systemConfig.load().then(() => {
                        this.parseMessages_(systemConfig.getConfig()['global_tenant_config']);
                    });
                }
            } else {
                promise = $q.reject('User is not authenticated');
            }

            return promise;
        }

        /**
         * Sets service properties based on systemConfig#global_tenant_config.
         * @param {Object} tenantConfig - TenantConfiguration protobuf message.
         * @protected
         */
        parseMessages_(tenantConfig) {
            const { l10nService_: l10nService } = this;

            if (tenantConfig) {
                this.hasConfig = true;
                this.ipRoutesDomain = tenantConfig.tenant_vrf.toString();
                this.seProviderContext = tenantConfig.se_in_provider_context.toString();
                this.tenantAccessProviderSe = tenantConfig.tenant_access_to_provider_se.toString();

                this.ipRoutesDomainMessage = this.ipRoutesDomain === 'true' ?
                    l10nService.getMessage(this.ipRoutesDomainMessages[0]) :
                    l10nService.getMessage(this.ipRoutesDomainMessages[1]);

                this.seProviderContextMessage = this.seProviderContext === 'true' ?
                    l10nService.getMessage(this.seProviderContextMessages[0]) :
                    l10nService.getMessage(this.seProviderContextMessages[1]);

                this.tenantAccessProviderSeMessage = this.tenantAccessProviderSe === 'true' ?
                    l10nService.getMessage(this.tenantAccessProviderSeMessages[0]) :
                    l10nService.getMessage(this.tenantAccessProviderSeMessages[1]);

                this.trigger(this.parseMessagesEvent);
            }
        }

        /**
         * Submits current values to update system settings API.
         */
        saveConfig() {
            return systemConfig.updateTenantSettings({
                tenant_vrf: this.ipRoutesDomain === 'true',
                se_in_provider_context: this.seProviderContext === 'true',
                tenant_access_to_provider_se: this.tenantAccessProviderSe === 'true',
            }).then(() => {
                this.parseMessages();
                //To get the updated references of systemconfiguration.global_tenant_config.
                this.defaultValues_.load(true);
            });
        }
    }

    return new TenantSettingsService();
}

TenantSettingsService.$inject = [
    'Auth',
    '$q',
    'systemConfigService',
    'Base',
    'defaultValues',
    'l10nService',
];

angular.module('avi/app').service('tenantSettingsService', TenantSettingsService);

/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { isNaN } from 'underscore';
import {
    MatchCase,
    WafMode,
} from 'generated-types';
import {
    IMessageItemData,
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model';
import { WafPSMMatchElementConfigItem } from './waf-psm-match-element.config-item.factory';

export enum ValueType {
    PATTERN = 'PATTERN',
    STRING_GROUP = 'STRING_GROUP',
}

interface IWafPSMRuleData extends IMessageItemData {
    config: IWafPSMRuleConfig;
}

interface IWafPSMRuleConfig {
    enable?: boolean;
    match_case?: MatchCase;
    match_elements?: RepeatedMessageItem<WafPSMMatchElementConfigItem>;
    match_value_max_length?: number;
    match_value_pattern?: string;
    mode?: WafMode;
    rule_id?: string;
    match_value_string_group_ref?: string;
    match_value_string_group_key?: string;
}

export class WafPSMRuleConfigItem extends MessageItem {
    public data: IWafPSMRuleData;

    protected readonly windowElement = 'waf-policy-psm-rule-modal';

    constructor(args = {}) {
        super({
            objectType: 'WafPSMRule',
            ...args,
        });
    }

    /**
     * Returns the rule_id.
     */
    public get ruleId(): string {
        return this.config.rule_id;
    }

    /**
     * Returns the enable state.
     */
    public get enable(): boolean {
        return this.config.enable;
    }

    /**
     * Sets the enable state.
     */
    public set enable(enabled) {
        this.config.enable = enabled;
    }

    /**
     * Returns the mode.
     */
    public get mode(): WafMode {
        return this.config.mode;
    }

    /**
     * Sets the mode.
     */
    public set mode(wafMode: WafMode) {
        this.config.mode = wafMode;
    }

    /**
     * Returns the number of configured match elements.
     */
    public get matchElementsCount(): number {
        return this.config.match_elements.count;
    }

    /**
     * Adds a WafPSMMatchElement instance to the list of Waf PSM match_elements.
     */
    public addMatchElement(): void {
        const { match_elements: matchElements } = this.config;
        const maxIndex = matchElements.getMaxIndex();
        const newIndex = isNaN(+maxIndex) ? 0 : maxIndex + 1;
        const newMatchElement = this.createChildByField_(
            'match_elements',
            null,
            true,
        ) as MessageItem;

        newMatchElement.setIndex(newIndex);
        matchElements.add(newMatchElement);
    }

    /**
     * Called when the value type has been changed. Deletes non-applicable fields.
     */
    public onMatchValueTypeChange(type: ValueType): void {
        switch (type) {
            case ValueType.PATTERN:
                delete this.config.match_value_string_group_ref;
                delete this.config.match_value_string_group_key;
                break;

            case ValueType.STRING_GROUP:
                delete this.config.match_value_pattern;
                break;
        }
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import 'ajs/less/pages/dashboard.less';
import * as l10n from './InfraDashboardController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name InfraDashboardController
 */
angular.module('aviApp').controller('InfraDashboardController', [
'$scope', 'ServiceEngineCollection', 'l10nService',
function($scope, ServiceEngineCollection, l10nService) {
    const self = this;

    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    function search() {
        self.collection.search(self.searchString);
    }

    this.sortBy = 'name';
    this.searchString = '';

    this.collection = new ServiceEngineCollection({ sortBy: this.sortBy });

    this.collection.subscribe(['health', 'runtime', 'alert']);

    this.sort = function() {
        this.collection.sort(this.sortBy);
    };

    this.search = _.debounce(search, 250);//evoked by input

    $scope.dashLegendCollapsed = true;

    $scope.$on('$destroy', function() {
        self.collection.destroy();
    });
}]);

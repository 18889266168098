const moduleName = 'ajs-legacy';
const componentName = 'license-key-modal';
const prefix = `${moduleName}.${componentName}`;

export const applyLicenseKeyModalTitle = `${prefix}.applyLicenseKeyModalTitle`;
export const applyKeyBtnLabel = `${prefix}.applyKeyBtnLabel`;
export const licenseKeyLabel = `${prefix}.licenseKeyLabel`;

export const ENGLISH = {
    [applyLicenseKeyModalTitle]: 'Apply License Key',
    [applyKeyBtnLabel]: 'Apply Key',
    [licenseKeyLabel]: 'License Key',
};

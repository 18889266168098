/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'waf';
const componentName = 'waf-rule-modal';
const prefix = `${moduleName}.${componentName}`;

export const generalSectionHeader = `${prefix}.generalSectionHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const enableRuleCheckboxLabel = `${prefix}.enableRuleCheckboxLabel`;
export const columnTitleSubnet = `${prefix}.columnTitleSubnet`;
export const columnTitlePath = `${prefix}.columnTitlePath`;
export const columnTitleMatchElement = `${prefix}.columnTitleMatchElement`;
export const actionLabelRemove = `${prefix}.actionLabelRemove`;
export const actionLabelEdit = `${prefix}.actionLabelEdit`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const exceptionsHeader = `${prefix}.exceptionsHeader`;
export const ruleFieldLabel = `${prefix}.ruleFieldLabel`;
export const ruleFieldPlaceholder = `${prefix}.ruleFieldPlaceholder`;
export const ruleModeLabel = `${prefix}.ruleModeLabel`;
export const usePolicyModeLabel = `${prefix}.usePolicyModeLabel`;
export const detectionLabel = `${prefix}.detectionLabel`;
export const enforcementLabel = `${prefix}.enforcementLabel`;

export const ENGLISH = {
    [generalSectionHeader]: 'General',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [enableRuleCheckboxLabel]: 'Enable Rule',
    [columnTitleSubnet]: 'Subnet',
    [columnTitlePath]: 'Path',
    [columnTitleMatchElement]: 'Match Element',
    [actionLabelRemove]: 'Remove',
    [actionLabelEdit]: 'Edit',
    [addButtonLabel]: 'Add',
    [exceptionsHeader]: 'Exceptions ({0})',
    [ruleFieldLabel]: 'Rule',
    [ruleFieldPlaceholder]: 'Enter Rule',
    [ruleModeLabel]: 'Rule Mode',
    [usePolicyModeLabel]: 'Use Policy Mode ({0})',
    [detectionLabel]: 'Detection',
    [enforcementLabel]: 'Enforcement',
};

/**
 * @module HeaderModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Inject,
    Output,
} from '@angular/core';

import { StateService } from '@uirouter/core';

import { IAppState } from 'ajs/js/services/appStates.types';

import { L10nService } from '@vmw/ngx-vip';

import { StateManager } from 'ajs/modules/core';

import {
    ADMINISTRATION_PERMISSION,
    APPLICATION_PERMISSION,
    INFRASTRUCTURE_PERMISSION,
    OPERATIONS_PERMISSION,
    TEMPLATE_PERMISSION,
} from 'ajs/js/constants/permissions.constant';

import * as l10n from './main-menu-list.l10n';
import { StateNamePipe } from '../../pipes';
import './main-menu-list.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IMainMenuState {
    name: string;
    permission: string;
    partialState: string;
}

/**
 * @description
 *     Mainmenu options component.
 *
 * @author alextsg, Aravindh Nagarajan
 */
@Component({
    selector: 'main-menu-list',
    templateUrl: './main-menu-list.component.html',
    providers: [
        StateNamePipe,
    ],
})
export class MainMenuListComponent {
    /**
     * Fires on state-button click.
     */
    @Output() public onSelect: EventEmitter<void> = new EventEmitter();

    /**
     * List of main-menu options.
     */
    public states: IMainMenuState[];

    public constructor(
        @Inject('$state')
        private readonly $state: StateService,
        private readonly stateManagerService: StateManager,
        private readonly stateNamePipe: StateNamePipe,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const { l10nService } = this;

        this.states = [{
            name: l10nService.getMessage(l10nKeys.applications),
            permission: APPLICATION_PERMISSION,
            partialState: 'authenticated.application',
        }, {
            name: l10nService.getMessage(l10nKeys.operations),
            permission: OPERATIONS_PERMISSION,
            partialState: 'authenticated.operations',
        }, {
            name: l10nService.getMessage(l10nKeys.templates),
            permission: TEMPLATE_PERMISSION,
            partialState: 'authenticated.profile',
        }, {
            name: l10nService.getMessage(l10nKeys.infrastructure),
            permission: INFRASTRUCTURE_PERMISSION,
            partialState: 'authenticated.infrastructure',
        }, {
            name: l10nService.getMessage(l10nKeys.administration),
            permission: ADMINISTRATION_PERMISSION,
            partialState: 'authenticated.administration',
        }];
    }

    /**
     * Returns the full name of the state.
     * @param abstractStateName - Partial name of the state to go to, ex. authenticated.profile.
     */
    public getState(abstractStateName: string): IAppState['name'] {
        const {
            $state,
            stateManagerService,
            stateNamePipe,
        } = this;

        const abstractState = $state.get(abstractStateName) as IAppState;

        const targetStateName = stateNamePipe.transform(abstractState);

        if (stateManagerService.isAllowedState(targetStateName, null)) {
            return targetStateName;
        }

        return stateManagerService.getClosestAllowedState(targetStateName, null).name;
    }

    /**
     * Handler for state-button click.
     */
    public onMenuOptionSelect(): void {
        this.onSelect.emit();
    }

    /**
     * trackBy Function.
     */
    public trackByStateName(index: number, state: IMainMenuState): IMainMenuState['name'] {
        return state.name;
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'waf-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const editWafProfileHeader = `${prefix}.editWafProfileHeader`;
export const newWafProfileHeader = `${prefix}.newWafProfileHeader`;
export const settingsTabTitle = `${prefix}.settingsTabTitle`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const generalSectionHeader = `${prefix}.generalSectionHeader`;
export const allowedHttpVersionsInputLabel = `${prefix}.allowedHttpVersionsInputLabel`;
export const allowedHttpVersionsInputPlaceholder = `${prefix}.allowedHttpVersionsInputPlaceholder`;
export const allowedHttpMethodsInputLabel = `${prefix}.allowedHttpMethodsInputLabel`;
export const allowedHttpMethodsInputPlaceholder = `${prefix}.allowedHttpMethodsInputPlaceholder`;
export const allowedContentTypesInputLabel = `${prefix}.allowedContentTypesInputLabel`;
export const restrictedExtensionsInputLabel = `${prefix}.restrictedExtensionsInputLabel`;
export const restrictedHeadersInputLabel = `${prefix}.restrictedHeadersInputLabel`;
export const staticExtensionsInputLabel = `${prefix}.staticExtensionsInputLabel`;
export const defaultActionsSectionHeader = `${prefix}.defaultActionsSectionHeader`;
export const requestHeaderPhaseInputLabel = `${prefix}.requestHeaderPhaseInputLabel`;
export const requestBodyPhaseInputLabel = `${prefix}.requestBodyPhaseInputLabel`;
export const responseHeaderPhaseInputLabel = `${prefix}.responseHeaderPhaseInputLabel`;
export const responseBodyPhaseInputLabel = `${prefix}.responseBodyPhaseInputLabel`;
export const otherSettingsSectionHeader = `${prefix}.otherSettingsSectionHeader`;
export const clientRequestMaxBodySizeInputLabel = `${prefix}.clientRequestMaxBodySizeInputLabel`;
export const ignoreIncompleteRequestBodyErrorMessage = `${prefix}.ignoreIncompleteRequestBodyErrorMessage`;
export const serverResponseMaxBodySizeInputLabel = `${prefix}.serverResponseMaxBodySizeInputLabel`;
export const argumentSeparatorInputLabel = `${prefix}.argumentSeparatorInputLabel`;
export const regexMatchLimitInputLabel = `${prefix}.regexMatchLimitInputLabel`;
export const maxExecutionTimeInputLabel = `${prefix}.maxExecutionTimeInputLabel`;
export const cookieFormatVersionLabel = `${prefix}.cookieFormatVersionLabel`;
export const netscapeCookiesInputLabel = `${prefix}.netscapeCookiesInputLabel`;
export const cookieFormatVersionInputLabel = `${prefix}.cookieFormatVersionInputLabel`;
export const xxeProtectionCheckboxLabel = `${prefix}.xxeProtectionCheckboxLabel`;
export const filesTabTitle = `${prefix}.filesTabTitle`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const removeFileButtonLabel = `${prefix}.removeFileButtonLabel`;
export const dataInputLabel = `${prefix}.dataInputLabel`;
export const addFileButtonLabel = `${prefix}.addFileButtonLabel`;

export const ENGLISH = {
    [editWafProfileHeader]: 'Edit WAF Profile: {0}',
    [newWafProfileHeader]: 'New WAF Profile: {0}',
    [settingsTabTitle]: 'Settings',
    [nameInputLabel]: 'Name',
    [generalSectionHeader]: 'General',
    [allowedHttpVersionsInputLabel]: 'Allowed Versions',
    [allowedHttpVersionsInputPlaceholder]: 'Select Allowed HTTP Versions',
    [allowedHttpMethodsInputLabel]: 'Allowed Methods',
    [allowedHttpMethodsInputPlaceholder]: 'Select Allowed HTTP Methods',
    [allowedContentTypesInputLabel]: 'Allowed Content Types',
    [restrictedExtensionsInputLabel]: 'Restricted Extensions',
    [restrictedHeadersInputLabel]: 'Restricted Headers',
    [staticExtensionsInputLabel]: 'Static Extensions',
    [defaultActionsSectionHeader]: 'Default Actions',
    [requestHeaderPhaseInputLabel]: 'Request Header Phase',
    [requestBodyPhaseInputLabel]: 'Request Body Phase',
    [responseHeaderPhaseInputLabel]: 'Response Header Phase',
    [responseBodyPhaseInputLabel]: 'Response Body Phase',
    [otherSettingsSectionHeader]: 'Other Settings',
    [clientRequestMaxBodySizeInputLabel]: 'Maximum client request size',
    [ignoreIncompleteRequestBodyErrorMessage]: 'Ignore request body parsing errors due to partial scanning',
    [serverResponseMaxBodySizeInputLabel]: 'Maximum backend response size',
    [argumentSeparatorInputLabel]: 'Argument Separator',
    [regexMatchLimitInputLabel]: 'Regex Match Limit',
    [maxExecutionTimeInputLabel]: 'Max Execution Time',
    [cookieFormatVersionLabel]: 'Cookie Format Version',
    [netscapeCookiesInputLabel]: 'Netscape cookies',
    [cookieFormatVersionInputLabel]: 'Version 1 cookies',
    [xxeProtectionCheckboxLabel]: 'XXE Protection',
    [filesTabTitle]: 'Files',
    [typeInputLabel]: 'Type',
    [removeFileButtonLabel]: 'Remove File',
    [dataInputLabel]: 'Data',
    [addFileButtonLabel]: '+ Add File',
};

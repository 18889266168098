/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const portMatchConfigItemFactory = (MessageItem, schemaService) => {
    /**
     * @constructor
     * @memberOf module:avi/policies/nat
     * @extends module:avi/dataModel.MessageItem
     * @desc PortMatch MessageItem class.
     * @author Aravindh Nagarajan
     */
    class PortMatchConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'PortMatch',
                ...args,
            };

            super(extendedArgs);

            /**
             * Dropdown options for Match Operation
             * @type {Object}
             */
            this.matchOperationLabelsHash_ = schemaService.getEnumValueLabelsHash('MatchOperation');
        }

        /**
         * @override
         */
        get defaultConfigOverride_() { //eslint-disable-line class-methods-use-this
            return {
                match_criteria: 'IS_IN',
            };
        }

        /**
         * Returns the label for this.config.match_criteria.
         * @returns {string}
         */
        get matchCriteriaLabel() {
            const { match_criteria: matchCriteria } = this.config;

            return this.matchOperationLabelsHash_[matchCriteria];
        }

        /**
         * Getter for port numbers.
         * @returns {string[]}
         */
        get ports() {
            return this.config.ports;
        }

        /**
         * Returns a string representation of the config data.
         * @returns {string}
         */
        toString() {
            const ports = this.ports.join(', ');

            return `${this.matchCriteriaLabel} ${ports}`;
        }
    }

    return PortMatchConfigItem;
};

portMatchConfigItemFactory.$inject = [
    'MessageItem',
    'schemaService',
];

angular.module('avi/policies/nat').factory('PortMatchConfigItem', portMatchConfigItemFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

export const USER_SETTINGS_DISPLAY_TIMEZONE_UPDATE = 'userSettingDisplayTimezoneUpdate';
export const LANGUAGES = [
    {
        label: 'English',
        value: 'en_US',
    }, {
        label: '简体中文',
        value: 'zh-Hans',
    }, {
        label: '日本語',
        value: 'ja',
    },
];

const moduleName = 'waf';
const componentName = 'waf-application-rules-list-header';
const prefix = `${moduleName}.${componentName}`;

export const applicationRulesTitle = `${prefix}.applicationRulesTitle`;
export const registerControllerMessage = `${prefix}.registerControllerMessage`;
export const registerControllerButtonLabel = `${prefix}.registerControllerButtonLabel`;
export const enableAppRulesSyncMessage = `${prefix}.enableAppRulesSyncMessage`;
export const editPulseSettingsButtonLabel = `${prefix}.editPulseSettingsButtonLabel`;

export const ENGLISH = {
    [applicationRulesTitle]: 'Application Rules',
    [registerControllerMessage]: 'Register your controller and easily stay current with Application Rules.',
    [registerControllerButtonLabel]: 'Register Controller',
    [enableAppRulesSyncMessage]: 'Keep Application Rules up-to-date by enabling auto-update in Pulse.',
    [editPulseSettingsButtonLabel]: 'Edit Pulse Settings',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name  uploadGeoFilesPage
 * @description  Page for Uploading Geo Files.
 */
class UploadGeoFilesController {
    constructor(CollateUpload) {
        this.upload = new CollateUpload(
            {
                uri: 'controller://gslb',
                chunkIndexStartsWith: 1,
            },
        );
        this.file = null;
        this.uploadStarted = false;

        /** @type {?string} */
        this.fileFormat = null;
    }

    /**
     * Calls send method from upload instance to upload a file.
     */
    uploadFile() {
        this.uploadStarted = true;

        let destination = '/api/fileservice/gslb';

        if (this.fileFormat) {
            destination += `?geodbformat=${this.fileFormat}`;
        }

        this.upload.send(this.file, this.file.name, destination);
    }
}

UploadGeoFilesController.$inject = [
    'CollateUpload',
];

angular.module('aviApp').component('uploadGeoFilesPage', {
    controller: UploadGeoFilesController,
    templateUrl: 'src/components/pages/infrastructure/gslb/upload-geo-files-page/' +
        'upload-geo-files-page.html',
});

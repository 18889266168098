/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { AjsDependency } from 'ajs/utils/ajsDependency';
import moment from 'moment';

const INITIAL_DATA_URL = '/api/initial-data?include_name';

interface IVersionDataApiResponse {
    build: number;
    Date: string;
    patch?: string;
    Tag: string;
    Version: string;
}

interface ICommonInitialData {
    banner: string;
    current_time: string;
    email_configuration_is_set: boolean;
    error_message: string;
    is_aws_cloud: boolean;
    setup_failed: boolean;
    sso: boolean;
    sso_logged_in: boolean;
    user_initial_setup: boolean;
    welcome_workflow_complete: boolean;
}

interface IInitialDataApiResponse extends ICommonInitialData {
    version: IVersionDataApiResponse;
}

interface IVersionData {
    build: number;
    date: string;
    patch?: string;
    tag: string;
    version: string;
}

interface IInitialData extends ICommonInitialData {
    timeDifference: number;
    version: IVersionData;
}

/**
 * @description
 *     Service responsible for fetching application's initial data hash and
 *     has specific public methods to return the properties from initial data.
 * @author chitra, Alex Malitsky
 */
export class InitialDataService extends AjsDependency {
    private $http: ng.IHttpService;

    /** Initial Data fetched from initial-data API. */
    private data: IInitialData = null;

    /**
     * Flag to indicate whether initial-data API request is in process.
     */
    private loadPromise: Promise<void> = null;

    constructor() {
        super();

        this.$http = this.getAjsDependency_('$http');
    }

    /**
     * Returns HTTP Promise that is to be resolved with undefined.
     * @param force - Flag to force the initial data load request
     */
    public loadData(force = false): Promise<void> {
        if (this.loadPromise) {
            return this.loadPromise;
        }

        if (!force && this.hasData()) {
            return Promise.resolve();
        }

        const promise = this.$http.get(INITIAL_DATA_URL)
            .then(({ data }) => {
                this.processData(data as unknown as IInitialDataApiResponse);
            })
            .catch(error => Promise.reject(error.data))
            .finally(() => this.loadPromise = null) as Promise<void>;

        this.loadPromise = promise;

        return promise;
    }

    /**
     * Checks if application already has initial data.
     */
    public hasData(): boolean {
        return this.data !== null;
    }

    /**
     * Returns the controller's patch version.
     */
    public get controllerPatch(): string {
        return this.data?.version.patch || '';
    }

    /**
     * Returns version of the controller.
     */
    public get controllerVersion(): string {
        return this.data?.version.version || '';
    }

    /**
     * Returns version build of the controller.
     */
    public get controllerBuild(): number {
        return this.data?.version.build || NaN;
    }

    /**
     * Returns version date of the controller.
     */
    public get controllerBuildDate(): string {
        return this.data?.version.date || '';
    }

    /**
     * Returns timeDifference between client and server(controller) in seconds.
     */
    public get controllerTimeDifference(): number {
        return this.data?.timeDifference;
    }

    /**
     * Returns error message if present in initialData.
     */
    public get controllerErrorMessage(): string {
        return this.data?.error_message || '';
    }

    /**
     * Returns banner if present in initialData.
     */
    public get controllerBanner(): string {
        return this.data?.banner || '';
    }

    /**
     * Returns true if initial set up is failed.
     */
    public get isSetupFailed(): boolean {
        return this.data?.setup_failed || false;
    }

    /**
     * Returns true if administrator user account setup not yet done.
     */
    public get isAdminUserSetupRequired(): boolean {
        return this.data?.user_initial_setup || false;
    }

    /**
     * Returns true if the controller created in AWS cloud.
     */
    public get isAwsCloud(): boolean {
        return this.data?.is_aws_cloud || false;
    }

    /**
     * Returns true if sso flag is enabled.
     */
    public get isSsoEnabled(): boolean {
        return this.data?.sso || false;
    }

    /**
     * Returns true if sso_logged_in enabled.
     */
    public get isSsoLoggedIn(): boolean {
        return this.data?.sso_logged_in || false;
    }

    /**
     * Returns true if email_configuration_is_set is set.
     */
    public get isEmailConfigurationSet(): boolean {
        return this.data?.email_configuration_is_set || false;
    }

    /**
     * Returns true if the controller set up done.
     */
    public get isWelcomeWorkflowCompleted(): boolean {
        return this.data?.welcome_workflow_complete || false;
    }

    /**
     * Processes the initial Data received from API response.
     * @param data
     */
    private processData(data: IInitialDataApiResponse): void {
        const initialData = {} as unknown as IInitialData;

        // time difference between client and server(controller) in seconds
        // current_time comes with timezone info
        initialData.timeDifference = moment().diff(moment(data.current_time), 's');

        const {
            Version: versionStr,
            Date: buildDate,
            Tag: tag,
            build,
            patch,
        } = data.version;

        initialData.version = {
            version: versionStr,
            date: buildDate,
            build,
            tag,
            patch,
        };

        this.data = Object.assign(data, initialData);
    }
}

InitialDataService.ajsDependencies = [
    '$http',
];

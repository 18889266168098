/**
 * Module containing dialog components
 * @preferred
 * @module DialogModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { VIPModule } from '@vmw/ngx-vip';
import { SharedModule } from 'ng/shared/shared.module';
import {
    AviConfirmationComponent,
    AviDialogsPortalComponent,
} from '.';

const dialogComponents = [
    AviConfirmationComponent,
    AviDialogsPortalComponent,
];

@NgModule({
    declarations: [
        ...dialogComponents,
    ],
    imports: [
        CommonModule,
        VIPModule,
        SharedModule,
    ],
    exports: [
        AviConfirmationComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class DialogModule { }

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './ssl-certificate-create-root.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  sslCertificateCreateRoot
 * @param {Certificate} editable - Certificate item.
 * @param {Function} closeModal - Closes modal.
 * @description  Modal for SSL Root Certificate. User validates and imports a certificate.
 */
class CertificateCreateRootController {
    constructor(
        Regex,
        l10nService,
    ) {
        this.Regex = Regex;

        /**
        * Get keys from source bundles for template usage
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this.validated = false;
    }

    /**
     * Clears certificate key information when Paste/Upload radio buttons are selected.
     */
    clearCertificate() {
        this.editable.data.config.certificate = undefined;
        this.validated = false;
    }

    /**
     * Sends certificate validate request and sets the Certificate config.
     * @returns {promise}
     */
    validateCert() {
        this.editable.validateCertificate()
            .then(() => this.validated = true);
    }
}

CertificateCreateRootController.$inject = [
    'Regex',
    'l10nService',
];

angular.module('aviApp').component('sslCertificateCreateRoot', {
    bindings: {
        editable: '=',
        closeModal: '&',
    },
    controller: CertificateCreateRootController,
    templateUrl: 'src/components/templates/security/ssl-tls-certificates/modals/' +
            'ssl-certificate-create-root/ssl-certificate-create-root.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name TopWAFEnforcementsMetric
 * @description
 *
 *     Fetches one aggregated series for multiple objIds.
 */
angular.module('aviApp').factory('TopWAFEnforcementsMetric', [
'SingleSeriesPerEveryObjectMetric',
function(SingleSeriesPerEveryObjectMetric) {
    return class TopWAFEnforcementsMetric extends SingleSeriesPerEveryObjectMetric {
        constructor(args) {
            args.stackedSeries = false;// no need in total series
            super(args);
        }
    };
}]);

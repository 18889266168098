/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './user-modal-all-tenants-settings.component.less';

const componentName = 'user-modal-all-tenants-settings';

/**
 * @constructor
 * @memberOf module:avi/accounts
 * @mixes module:avi/accounts.userModalAllTenantsSettingsBindings
 */
class UserModalAllTenantsSettingsController {
    constructor(
        Auth,
        RoleCollection,
    ) {
        this.roleCollection = new RoleCollection({
            params: {
                tenant: Auth.getCurrentTenantRef().slug(),
                exclude: 'uuid.in',
            },
        });
    }

    /** @override */
    $onInit() {
        this.updateSelectedRolesFilter();
    }

    /**
     * Update roleCollection to filter out already selected roles.
     */
    updateSelectedRolesFilter() {
        const selectedRoles = this.allTenantRoles
            .reduce((selectedRoleRefs, roleRef) => {
                if (roleRef) {
                    selectedRoleRefs.push(roleRef.slug());
                }

                return selectedRoleRefs;
            }, []);

        this.roleCollection.setParams({
            'uuid.in': selectedRoles.join() || undefined,
        });
    }

    /**
     * Add a new role for all_tenants
     */
    addAllTenantRole() {
        const { allTenantRoles } = this;

        allTenantRoles.push(undefined);
    }

    /**
     * Removes a role from all_tenants roles.
     */
    removeAllTenantRole(index) {
        const { allTenantRoles } = this;

        allTenantRoles.splice(index, 1);

        this.updateSelectedRolesFilter();
    }

    /** @override */
    $onDestroy() {
        this.roleCollection.destroy();
    }
}

UserModalAllTenantsSettingsController.$inject = [
    'Auth',
    'RoleCollection',
];
/**
 * @name  userModalAllTenantsSettingsComponent
 * @memberOf module:avi/accounts
 * @property {module:avi/accounts.userModalAllTenantsSettingsBindings} bindings
 * @property {module:avi/accounts.UserModalAllTenantsSettingsController} controller
 * @description
 *      Component for multiple role selection for All tenants in
 *      Administration/Accounts/User/Create-Edit modal.
 * @author Aravindh Nagarajan
 */
angular.module('avi/accounts').component('userModalAllTenantsSettings', {
    /**
     * @mixin userModalAllTenantsSettingsBindings
     * @memberOf module:avi/accounts
     * @property {string[]} allTenantRoles - List of role_refs assigned for all tenants
     */
    bindings: {
        allTenantRoles: '<',
    },
    controller: UserModalAllTenantsSettingsController,
    templateUrl: 'src/components/modals/administration/accounts/' +
        `user/${componentName}/${componentName}.component.html`,
});

/**
 * @module PoliciesModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { without } from 'underscore';
import { IGridConfig } from 'ajs/components/common/grid/grid-data-model';
import { IDnsTxtRdata } from 'generated-types';

import template from './dns-txt-records.component.html';
import './dns-txt-records.component.less';

const txtTemplate = `<input
    type="text"
    class="full-width"
    placeholder="Text Record"
    ng-model="row.text_str"
    required
/>`;

/**
 * @description
 *     Component to render txt records in dns record config modal.
 * @author Amit Dubey
 */
class DnsTxtRecordsController {
    /**
     * Grid config for Txt Records
     */
    public txtRecordsGridConfig: Partial<IGridConfig>;

    /**
     * Txt records array.
     */
    public txtRecords: IDnsTxtRdata[];

    /**
     * @override
     */
    public $onInit(): void {
        this.txtRecordsGridConfig = {
            id: 'dns-mx-records',
            fields: [{
                name: 'text_str',
                title: 'Text Record',
                template: txtTemplate,
            }],
            multipleactions: [{
                title: 'Remove',
                do: (rows: IDnsTxtRdata[]) => {
                    this.txtRecords =
                        without(
                            this.txtRecords,
                            ...rows,
                        );

                    return true;
                },
            }],
            layout: {
                hideDisplaying: true,
                hideSearch: true,
            },
        };
    }

    /**
     * Function to add new field for mx record
     */
    public addTxtRecord(): void {
        this.txtRecords.push({
            text_str: '',
        });
    }
}

DnsTxtRecordsController.$inject = [
    'Regex',
];

export const dnsTxtRecordsComponentOptions = {
    bindings: {
        txtRecords: '=',
    },
    controller: DnsTxtRecordsController,
    template,
};

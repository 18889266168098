const moduleName = 'ajs-legacy';
const componentName = 'service-engine-group-list';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleVersion = `${prefix}.columnTitleVersion`;
export const columnTitleServiceEnginesNumber = `${prefix}.columnTitleServiceEnginesNumber`;
export const columnTitleServiceEnginesMaxNumber = `${prefix}.columnTitleServiceEnginesMaxNumber`;
export const columnTitleVirtualServicesNumber = `${prefix}.columnTitleVirtualServicesNumber`;
export const columnTitleVsPerSeMaxNumber = `${prefix}.columnTitleVsPerSeMaxNumber`;
export const columnTitleHaMode = `${prefix}.columnTitleHaMode`;
export const editBtnTitle = `${prefix}.editBtnTitle`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleVersion]: 'Version',
    [columnTitleServiceEnginesNumber]: '# Service Engines',
    [columnTitleServiceEnginesMaxNumber]: 'Maximum Number of Service Engines',
    [columnTitleVirtualServicesNumber]: '# Virtual Services',
    [columnTitleVsPerSeMaxNumber]: 'Maximum Number of Virtual Services per Service Engine',
    [columnTitleHaMode]: 'HA Mode',
    [editBtnTitle]: 'edit',
};

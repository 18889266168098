/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './virtualservice-traffic-capture-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  virtualserviceTrafficCaptureConfig
 * @description  Configures traffic capture settings.
 * @param {object} captureConfig - Contains config properties and methods.
 * @param {function} onSuccess - Called after successful config save.
 * @param {function} closeModal - Called to close config modal.
 */
class VirtualserviceTrafficCaptureConfigComponent {
    constructor(
        Regex,
        VirtualServiceCollection,
        schemaService,
        l10nService,
    ) {
        this.Regex = Regex;

        this.virtualServiceCollection = new VirtualServiceCollection({ isStatic: true });

        this.schemaService_ = schemaService;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        const { description: debugVsHmIncludeEnumDescription } = this.schemaService_
            .getEnumValue('DebugVirtualServiceHM', 'DEBUG_VS_HM_INCLUDE');
        const { description: debugVsHmOnlyEnumDescription } = this.schemaService_
            .getEnumValue('DebugVirtualServiceHM', 'DEBUG_VS_HM_ONLY');
        const { description: debugVsHmNoneEnumDescription } = this.schemaService_
            .getEnumValue('DebugVirtualServiceHM', 'DEBUG_VS_HM_NONE');

        this.config = this.captureConfig.config;
        this.debugVsHmIncludeEnumDescription = debugVsHmIncludeEnumDescription;
        this.debugVsHmOnlyEnumDescription = debugVsHmOnlyEnumDescription;
        this.debugVsHmNoneEnumDescription = debugVsHmNoneEnumDescription;
    }

    $onDestroy() {
        this.captureConfig.cancelRequests();
    }

    /**
     * Populates the config object with traffic capture fields.
     * @public
     */
    saveConfig() {
        this.captureConfig.save()
            .then(() => {
                this.onSuccess();
                this.closeModal();
            });
    }

    /**
     * Sets captureParams fields to default when user switches between Duration and Number of
     * Pkts.
     * @public
     */
    onParamsChange() {
        this.config.capture_params.duration = this.captureConfig.getDefaultCaptureParams().duration;
    }

    /**
     * Sets filter to '' when user switches between All and Filter.
     * @public
     */
    onFilterChange() {
        this.config.debug_ip = undefined;
    }
}

VirtualserviceTrafficCaptureConfigComponent.$inject = [
    'Regex',
    'VirtualServiceCollection',
    'schemaService',
    'l10nService',
];

angular.module('aviApp').component('virtualserviceTrafficCaptureConfig', {
    bindings: {
        captureConfig: '<',
        onSuccess: '&',
        closeModal: '&',
    },
    controller: VirtualserviceTrafficCaptureConfigComponent,
    templateUrl: 'src/components/modals/virtualservice-traffic-capture-config/' +
            'virtualservice-traffic-capture-config.html',
});

/**
 * @module DataModelModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { IIpAddrRange } from 'generated-types';

import {
    IMessageItemData,
    MessageItem,
} from '.';

interface IIpAddrRangeData extends IMessageItemData {
    config: IIpAddrRange;
}

/**
 * @description IpAddrRange MessageItem.
 *
 * @author Alex Tseung, Aravindh Nagarajan
 */
export class IpAddrRangeConfigItem extends MessageItem {
    public data: IIpAddrRangeData;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'IpAddrRange',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns a string representation of the config data.
     */
    public toString(): string {
        const { begin, end } = this.config;

        return `${begin.toString()} - ${end.toString()}`;
    }
}

const moduleName = 'waf';
const componentName = 'waf-application-rules-list';
const prefix = `${moduleName}.${componentName}`;

export const applicationRulesDBHeader = `${prefix}.applicationRulesDBHeader`;
export const nameFieldLabel = `${prefix}.nameFieldLabel`;
export const applicationNameLabel = `${prefix}.applicationNameLabel`;
export const lastUpdateVersionLabel = `${prefix}.lastUpdateVersionLabel`;
export const numberOfRulesLabel = `${prefix}.numberOfRulesLabel`;

export const ENGLISH = {
    [applicationRulesDBHeader]: 'Application Rules DB',
    [nameFieldLabel]: 'Name',
    [applicationNameLabel]: 'Application Name',
    [lastUpdateVersionLabel]: 'Last Update Version',
    [numberOfRulesLabel]: '# of Rules',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../../less/components/scatter-plot-card.less';

/**
 * @ngdoc directive
 * @name scatterPlotCard
 */
angular.module('aviApp').directive('scatterPlotCard', function() {
    return {
        restrict: 'E',
        template:
            `<h3>
                <span class="sel-server-count">{{item.getConfig().servers.length}}</span> Servers
            </h3>
            <div scatter-plot small="true" item="item"
                metrics="item.async.collMetricsHash.pools_servers_hs_scatter"></div>`,
    };
});

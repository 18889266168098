/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'logs';
const componentName = 'icap-logs';
const prefix = `${moduleName}.${componentName}`;

export const gridColumnHeaderActionLabel = `${prefix}.gridColumnHeaderActionLabel`;
export const gridColumnHeaderReasonLabel = `${prefix}.gridColumnHeaderReasonLabel`;
export const gridColumnHeaderViolationsLabel = `${prefix}.gridColumnHeaderViolationsLabel`;

export const ENGLISH = {
    [gridColumnHeaderActionLabel]: 'Request Action',
    [gridColumnHeaderReasonLabel]: 'Reason',
    [gridColumnHeaderViolationsLabel]: 'Violations',
};

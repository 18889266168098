/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './rate-profile.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class RateProfile {
    constructor(
        Regex,
        rateService,
        dropDownUtils,
        schemaService,
        l10nService,
    ) {
        this.Regex = Regex;
        this.rateService = rateService;
        this.dropDownUtils_ = dropDownUtils;

        this.httpProtocolEnumKeys = schemaService.getEnumKeys('HTTPProtocol');

        /**
        * Get keys from source bundles for template usage.
        */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    $onInit() {
        const { actions } = this;

        /**
         * RateProfile action types for the relevant dropdown.
         * @type {DropDownOption[]}
         */
        this.rateProfileActionTypeOptions = actions.map(({ id, label }) =>
            this.dropDownUtils_.createOption(id, label));
    }

    /**
     * Handles protocol change.
     */
    protocolChange() {
        const { action } = this.profile;

        if (action.redirect && angular.isString(action.redirect.protocol)) {
            const { redirect } = action;

            redirect.port = redirect.protocol === 'HTTPS' ? 443 : 80;
        }
    }
}

RateProfile.$inject = [
    'Regex',
    'dosRateLimiterService',
    'dropDownUtils',
    'schemaService',
    'l10nService',
];

/**
 * @ngdoc component
 * @name rateProfile
 * @param {Object[]} actions - List of RateLimiterAction for RateProfile action field
 *      to display in Action dropdown.
 * @param {Object} profile - RateProfile object to modify.
 * @param {string} profName - RateProfile name to display as title.
 * @description
 *      UI component to modify instance of RateProfile object.
 */
angular.module('aviApp').component('rateProfile', {
    bindings: {
        actions: '<',
        profile: '<',
        profName: '@',
    },
    controller: RateProfile,
    templateUrl: 'src/components/templates/profiles/rate-limiter/rate-profile.html',
});

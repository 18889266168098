/**
 * @module CloudModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { IMessageItemData, MessageItem } from 'ajs/modules/data-model';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { Tier1LogicalRouterInfoConfigItem } from '.';

interface INsxtTier1SegmentManualModeConfig {
    tier1_lrs: RepeatedMessageItem<Tier1LogicalRouterInfoConfigItem>;
}

interface INsxtTier1SegmentManualModeData extends IMessageItemData {
    config: INsxtTier1SegmentManualModeConfig;
}

export class NsxtTier1SegmentManualModeConfigItem extends MessageItem {
    public data: INsxtTier1SegmentManualModeData;

    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'NsxtTier1SegmentManualMode',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Removes all configured Tier1 LRs.
     */
    public removeTier1LRs(): void {
        this.config.tier1_lrs.removeAll();
    }
}

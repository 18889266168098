/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name authProfileVerifySaml
 * @param {AuthProfile} config
 * Auth Profile modal popup triggered by a singleaction. Shows details for the SAML config.
 */
class AuthProfileVerifySamlController {}

angular.module('aviApp').component('authProfileVerifySaml', {
    controller: AuthProfileVerifySamlController,
    bindings: {
        config: '<',
        closeModal: '&',
    },
    templateUrl: 'src/components/pages/templates/profiles/auth-profile-page/' +
        'auth-profile-verify-saml/auth-profile-verify-saml.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import Tether from 'tether';

angular.module('aviApp').controller('aviMenuCtrl', MenuController);

/**
 * @ngdoc directive
 * @name aviMenu
 * @element any
 * @description
 *     Creates drop-down menu.
 * @example
 *      <div avi-menu>
 *          <span avi-menu-button>
 *              Open Menu
 *          </span>
 *          <ul avi-menu-list>
 *              <li class="avi-menu-list-item
 *                  ng-repeat="item in items">
 *                  {item}
 *              </li>
 *          </ul>
 *      </div>
 */
angular.module('aviApp').directive('aviMenu', function() {
    return {
        restrict: 'EA',
        controller: 'aviMenuCtrl',
        controllerAs: 'menuCtrl',
        link($scope, $element) {
            $element.addClass('avi-menu');
        },
    };
});

/**
 * Menu controller for Menu directive.
 * @param {angular.$scope} $scope
 * @param {angular.$compile} $compile
 * @constructor
 */
function MenuController($scope, $element, $compile, $attrs) {
    /**
     * @type {?angular.element}
     */
    this.toggleElement = null;
    /**
     * @type {?angular.element}
     */
    this.menuListElement = null;
    /**
     * @type {?TetherClass}
     */
    this.tether = null;
    /**
     * @type {Function}
     */
    this.Tether = Tether;
    /**
     * @type {angular.$scope}
     */
    this.$scope = $scope;
    /**
     * @type {angular.$compile}
     */
    this.$compile = $compile;

    this._$attrs = $attrs;

    this._$element = $element;

    $scope.$on('$destroy', this.destroy.bind(this));
    this.mouseReleaseOutsideHandler = this.mouseReleaseOutsideHandler.bind(this);
    this.windowBlurHandler = this.windowBlurHandler.bind(this);
}

MenuController.$inject = ['$scope', '$element', '$compile', '$attrs'];

/**
 * Attaches {@link toggleElement} to {@link menuListElement} using {@link TetherClass}
 * instance.
 * @returns {TetherClass}
 */
MenuController.prototype.createTether = function() {
    if (!this.menuListElement || !this.toggleElement || this.tether) {
        return this.tether;
    }

    let { menuListElement } = this;
    const contents = menuListElement.contents();

    menuListElement.remove();
    menuListElement = angular.element('<div></div>');
    menuListElement.addClass('avi-menu-list');

    if (this._$attrs.aviMenu) {
        menuListElement.addClass(this._$attrs.aviMenu);
    }

    const compiled = this.$compile(contents)(this.$scope);

    menuListElement.appendTo(document.body);
    menuListElement.append(compiled);
    this.menuListElement = menuListElement;
    menuListElement.on('click', function() {
        this.closeMenu();
    }.bind(this));

    const tetherConfig = {
        element: menuListElement.get(0),
        target: this.toggleElement.get(0),
        attachment: 'top left',
        targetAttachment: 'bottom left',
        constraints: [
                {
                    to: 'window',
                    attachment: 'together',
                },
        ],
    };

    const [{ dataset }] = this._$element;

    if (dataset.tetherAttachment) {
        tetherConfig.attachment = dataset.tetherAttachment;
    }

    if (dataset.tetherTargetAttachment) {
        tetherConfig.targetAttachment = dataset.tetherTargetAttachment;
    }

    if (dataset.tetherOffset) {
        tetherConfig.offset = dataset.tetherOffset;
    }

    const tether = new this.Tether(tetherConfig);

    this.tether = tether;

    return tether;
};

/**
 * Toggles {@link menuListElement} visibility.
 */
MenuController.prototype.toggleMenu = function() {
    if (!this.tether) {
        this.createTether();
    }

    const { tether, menuListElement } = this;

    menuListElement.toggleClass('open');

    if (menuListElement.hasClass('open')) {
        window.addEventListener('blur', this.windowBlurHandler);
        document.documentElement.addEventListener(
            'click', this.mouseReleaseOutsideHandler, true,
        );
        tether.position();
    }
};

/**
 * Handles window blur event.
 * @param {MouseEvent} e
 */
MenuController.prototype.windowBlurHandler = function(e) {
    this.closeMenu();
};

/**
 * Handles document click event when menu is open and checks if click position is
 * outside of menu list element and hides it.
 * @param {MouseEvent} e
 */
MenuController.prototype.mouseReleaseOutsideHandler = function(e) {
    const aviMenuButton = $(e.target).closest('[avi-menu-button]')[0];

    if (aviMenuButton === this.toggleElement[0]) {
        return;
    }

    const boundingBox = this.menuListElement[0].getBoundingClientRect();

    if (e.pageX < boundingBox.left || e.pageX > boundingBox.right ||
            e.pageY < boundingBox.top || e.pageY > boundingBox.bottom) {
        this.closeMenu();
    }
};

/**
 * Hides menu list.
 */
MenuController.prototype.closeMenu = function() {
    this.menuListElement.removeClass('open');
    this.removeDomListeners();
};

/**
 * Removes all global listeners used outside of menu element.
 */
MenuController.prototype.removeDomListeners = function() {
    window.removeEventListener('blur', this.windowBlurHandler);
    document.documentElement.removeEventListener(
        'click', this.mouseReleaseOutsideHandler, true,
    );
};

/**
 * Removes any remaining DOM elements and listeners.
 */
MenuController.prototype.destroy = function() {
    if (this.toggleElement) {
        this.toggleElement.off();
    }

    if (this.menuListElement) {
        this.menuListElement.remove();
    }

    if (this.tether) {
        this.tether.destroy();
    }

    this.removeDomListeners();
};

/**
 * @ngdoc directive
 * @name aviMenuButton
 * @requires aviMenu
 * @restrict EA
 * @description
 *     Menu button directive when creating aviMenu.
 */
angular.module('aviApp').directive('aviMenuButton', function() {
    return {
        require: '^aviMenu',
        restrict: 'EA',
        scope: true,
        link($scope, $element, $attrs, menuCtrl) {
            $element.addClass('avi-menu-button');

            if (menuCtrl) {
                menuCtrl.toggleElement = $element;
                $element.on('click', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    menuCtrl.toggleMenu();
                });
            }
        },
    };
});

/**
 * @ngdoc directive
 * @name aivMenuList
 * @requires aviMenu
 * @restrict EA
 * @description
 *     Menu list directive when creating aviMenu.
 */
angular.module('aviApp').directive('aviMenuList', function() {
    return {
        require: '^aviMenu',
        restrict: 'EA',
        link($scope, $element, $attrs, menuCtrl) {
            $element.addClass('avi-menu-list');

            if (menuCtrl) {
                menuCtrl.menuListElement = $element;
            }
        },
    };
});

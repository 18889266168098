/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function wafPolicyPsmGroupMetricDataSourceFactory(DataSource) {
    /**
     * @alias module:services/WafPolicyPsmGroupMetricDataSource
     * @extends DataSource
     */
    class WafPolicyPsmGroupMetricDataSource extends DataSource {
        constructor(args) {
            Object.assign(args, {
                defaultFields: [
                    {
                        id: 'config',
                        preserved: true,
                        subscribers: ['__default_field'],
                    },
                ],
                defaultParams: {
                    dimension_aggregation: 'METRICS_DIMENSION_AGG_SUM',
                    dimension_filter_op: 'METRICS_FILTER_CONTAINS',
                    entity_uuid: '*',
                },
            });

            super(args);
        }

        /** @override */
        processResponse_(response) {
            this.owner_.config = response;
        }

        /** @override */
        getRequestParams_() {
            const params = super.getRequestParams_();
            const { metricIds, id: objId } = this.owner_;

            return metricIds.map(metricId => ({
                metric_id: metricId,
                obj_id: objId,
                id: `${objId}:${metricId}`,
                ...params,
            }));
        }
    }

    return WafPolicyPsmGroupMetricDataSource;
}

wafPolicyPsmGroupMetricDataSourceFactory.$inject = [
    'DataSource',
];

/**
 * @ngdoc service
 * @name WafPolicyPsmGroupMetricDataSource
 * @module services/WafPolicyPsmGroupMetricDataSource
 * @author alextsg
 * @description Data source for WafPolicyPsmGroupMetric
 */
angular
    .module('avi/waf')
    .factory('WafPolicyPsmGroupMetricDataSource', wafPolicyPsmGroupMetricDataSourceFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name hsInsights
 * @author Alex Malitsky
 * @param {VirtualService=} resolveVS
 * @param {Pool=} resolvePool
 * @param {ServiceEngine=} resolveSE
 * @description
 *
 *     Top most level component for new HealthScore insights page. Usually contains 2
 *     smaller components - HS tree and top factors affecting health score.
 *
 *     Used by VS, Pool and SE details pages. Loaded Item is provided by ui-router.
 *
 **/
import './hs-insights.less';
import * as l10n from './hs-insights.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class HsInsightsController {
    constructor($scope, HSGraph, ChartSync, HSGraphMetrics, l10nService) {
        this.itemFields_ = [
                'config',
                'health',
                'alert',
                'config_events',
                'system_events',
                'alerts',
                'faults',
        ];

        this.scope_ = $scope;
        this.HSGraph_ = HSGraph;
        this.HSGraphMetricsUpdateEventName_ = HSGraphMetrics.GROUP_UPDATE_EVENT;
        /** @type {?Metric[]} */
        this.sideMetrics = null;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        // TODO: Dynamically generate title based on node selected? Related AV-18461
        this.sideChartsTitle =
            l10nService.getMessage(l10nKeys.topFactorsAffectingHealthScoreMessage);

        this.chartSync = new ChartSync();
    }

    $onInit() {
        this.item = this.resolvePool || this.resolveVS || this.resolveSE;

        this.graph = new this.HSGraph_({
            rootItem: this.item,
            loadOnCreate: true,
        });

        const { graph } = this;

        graph.metrics.on(this.HSGraphMetricsUpdateEventName_, (updateType, metricsList) => {
            if (updateType === 'child') {
                this.sideMetrics = metricsList.concat();
            }
        });
    }

    $onDestroy() {
        const { item, itemFields_, graph } = this;

        item.async.stop(true);
        item.removeLoad(itemFields_);

        graph.destroy();

        this.chartSync.destroy();
    }
}

HsInsightsController.$inject = [
        '$scope',
        'HSGraph',
        'ChartSync',
        'HSGraphMetrics',
        'l10nService',
];

angular.module('aviApp').component('hsInsights', {
    bindings: {
        resolveVS: '<',
        resolvePool: '<',
        resolveSE: '<',
    },
    controller: HsInsightsController,
    templateUrl: 'src/components/applications/hs-insights/hs-insights.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './nat-rule-modal.component.less';
import * as l10n from './nat-rule-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const componentName = 'nat-rule-modal';

/**
 * @constructor
 * @memberOf module:avi/policies/nat
 * @mixes module:avi/policies/nat.natRuleModalComponentBindings
 * @see {@link module:avi/policies/nat.natRuleModalComponent}
 */
class NatRuleModalController {
    constructor(
        schemaService,
        Regex,
        l10nService,
    ) {
        this.Regex = Regex;

        /**
         * List of Nat policy types.
         * @type {DropDownOption[]}
         */
        this.natPolicyActionTypeOptions =
            schemaService.getEnumValues('NatPolicyActionType');

        /**
        * Get keys from source bundles for template usage.
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for supported matches for NAT Rule.
     * @return {string[]}
     */
    get supportedMatches() {
        return this.rule.match.supportedMatches;
    }

    /**
     * Handler for the user clicking the close button.
     */
    handleCancel() {
        this.onCancel();
        this.closeModal();
    }

    /**
     * Handler for the user clicking the submit button.
     */
    handleSubmit() {
        this.onSubmit();
        this.closeModal();
    }

    /**
     * Returns true if NAT Rule is being edited.
     * @return {boolean}
     */
    isEditing() {
        return !_.isUndefined(this.rule.getIndex());
    }

    /**
     * Returns the state of the enable switch, 1 for enabled, 0 for disabled.
     * @return {number}
     */
    getEnableState() {
        return this.rule.enable ? 1 : 0;
    }

    /**
     * Click handler for the enable switch. Flips the current value.
     */
    handleEnableClick() {
        this.rule.config.enable = !this.rule.enable;
    }
}

NatRuleModalController.$inject = [
    'schemaService',
    'Regex',
    'l10nService',
];

/**
 * @name natRuleModalComponent
 * @memberOf module:avi/policies/nat
 * @property {module:avi/policies/nat.NatRuleModalController} controller
 * @property {module:avi/policies/nat.natRuleModalComponentBindings} bindings
 * @desc NAT Rule Modal Component
 * @author Aravindh Nagarajan
 */
angular.module('avi/policies/nat').component('natRuleModal', {
    /**
     * @mixin natRuleModalComponentBindings
     * @memberOf module:avi/policies/nat
     * @property {module:avi/policies/nat.NatRuleConfigItem} rule
     * @property {Function} onSubmit
     * @property {Function} onCancel
     * @property {Function} closeModal
     */
    bindings: {
        rule: '<',
        onSubmit: '&',
        onCancel: '&',
        closeModal: '&',
    },
    controller: NatRuleModalController,
    templateUrl: `src/components/modals/${componentName}/${componentName}.component.html`,
});

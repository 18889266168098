const moduleName = 'ajs-legacy';
const componentName = 'events-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const totalEventsPlural = `${prefix}.totalEventsPlural`;
export const includeInternalCheckboxLabel = `${prefix}.includeInternalCheckboxLabel`;
export const zoomToSelectedLink = `${prefix}.zoomToSelectedLink`;
export const reloadMoreAvailableEventsLink = `${prefix}.reloadMoreAvailableEventsLink`;

export const ENGLISH = {
    [totalEventsPlural]: '{0, plural, one{Total <span class="sel-total-items"><strong>#</strong></span> Event} other{Total <span class="sel-total-items"><strong>#</strong></span> Events}}',
    [includeInternalCheckboxLabel]: 'Include Internal',
    [zoomToSelectedLink]: 'Zoom to selected',
    [reloadMoreAvailableEventsLink]: 'More events available. Please ##0reload list##1',
};

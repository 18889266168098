/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'waf';
const componentName = 'waf-crs-expander-opener';
const prefix = `${moduleName}.${componentName}`;

export const editIconTitle = `${prefix}.editIconTitle`;
export const removeIconTitle = `${prefix}.removeIconTitle`;

export const ENGLISH = {
    [editIconTitle]: 'Edit',
    [removeIconTitle]: 'Remove',
};

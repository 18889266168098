/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @typedef {Object} IpAddrRange - protobuf message.
 * @property {IpAddr} begin
 * @property {IpAddr} end
 */

/**
 * @typedef {Object} IpAddrPrefix - protobuf message.
 * @property {IpAddr} ip_addr
 * @property {integer} mask - From 0 to 32.
 */

/**
 * @typedef {Object} Subnet
 * @property {IpAddrPrefix} prefix
 * @property {IpAddr} static_ips
 * @property {IpAddrRange} static_ranges
 */

/**
 * @typedef {Object} IpAddrGroupConfig
 * @property {IpAddr[]} addrs
 * @property {IpAddrRange[]} ranges
 * @property {IpAddrPrefix[]} prefixes
 */

angular.module('aviApp').factory('IpAddrGroup', [
'Item', 'RangeParser',
function(Item, RangeParser) {
    const IpAddrGroup = class extends Item {
        beforeEdit() {
            const servers = [];

            if (this.data.config.addrs) {
                _.each(this.data.config.addrs, function(o) {
                    if (o.addr) {
                        servers.push({ addr: o.addr });
                    }
                });
            }

            if (this.data.config.ranges) {
                _.each(this.data.config.ranges, function(o) {
                    if (o.begin) {
                        servers.push({ addr: `${o.begin.addr}-${o.end.addr}` });
                    }
                });
            }

            if (this.data.config.prefixes) {
                _.each(this.data.config.prefixes, function(o) {
                    if (o.ip_addr) {
                        servers.push({ addr: `${o.ip_addr.addr}/${o.mask}` });
                    }
                });
            }

            this.data.config.servers = servers;
        }

        dataToSave() {
            const data = angular.copy(this.data.config);

            data.ranges = [];
            data.prefixes = [];
            data.addrs = [];

            if (!data.apic_epg_name && !data.country_codes) {
                _.each(data.servers, function(server) {
                    if (!server || !server.addr) {
                        return;
                    }

                    const ips = server.addr.split(',');

                    ips.forEach(function(ip) {
                        const rangeOrIp = RangeParser.ipRange2Json(ip.trim());

                        if (!rangeOrIp) {
                            return; // Not recognized piece
                        }

                        if (rangeOrIp.begin) { // assume this is range
                            data.ranges.push(rangeOrIp);
                        } else if (rangeOrIp.mask) { // assume prefix
                            data.prefixes.push(rangeOrIp);
                        } else { // otherwise ip
                            data.addrs.push(rangeOrIp);
                        }
                    });
                });
            } else if (data.apic_epg_name && data.tenant) {
                data.apic_epg_name = `${data.tenant}:${data.apic_epg_name}`;
                delete data.tenant;
            }

            delete data.servers;

            return data;
        }

        /**
         * Gets number of IP addresses owned by this IpAddrGroup.
         * @returns {number}
         * @public
         */
        getNumberOfIPs() {
            const { addrs, prefixes, ranges } = this.getConfig();

            let res = 0;

            // Checks number of single IP addresses and calculates number generated by prefix mask.
            if (addrs) {
                res += addrs.length;
            }

            if (prefixes) {
                res += prefixes.reduce((acc, { mask }) => {
                    return acc + RangeParser.getNumberOfIpsFromPrefixLength(mask);
                }, 0);
            }

            if (ranges) {
                res += ranges.reduce((acc, range) => {
                    return acc + RangeParser.getNumberOfIpsFromRange(range);
                }, 0);
            }

            return res;
        }

        /**
         * Returns all IpAddr objects owned by this IpAddrGroup.
         * @returns {IpAddr[]}
         * @public
         */
        //FIXME add prefix support
        getIPs() {
            const
                { addrs, ranges } = this.getConfig(),
                ips = [];

            if (addrs) {
                ips.push(...addrs);
            }

            if (ranges) {
                ranges.forEach(
                    range => ips.push(...RangeParser.getIpsFromRange(range)),
                );
            }

            return ips;
        }
    };

    angular.extend(IpAddrGroup.prototype, {
        objectName: 'ipaddrgroup',
        windowElement: 'prof-ipaddrgroup-create',
    });

    return IpAddrGroup;
}]);

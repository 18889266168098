/**
 * Module containing Webhook components.
 * @preferred
 * @module WebhookModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */
import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
    ClrFormsModule,
    ClrInputModule,
    ClrTextareaModule,
} from '@clr/angular';
import { SharedModule } from 'ng/shared/shared.module';

import { WebhookModalComponent } from '.';

const webhookComponents = [
    WebhookModalComponent,
];

@NgModule({
    declarations: [
        ...webhookComponents,
    ],
    imports: [
        FormsModule,
        SharedModule,
        ClrInputModule,
        ClrTextareaModule,
        ClrFormsModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class WebhookModule {}

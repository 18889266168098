/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @param {Object} parse - Object for property resolution.
 */
const propLookupFactory = $parse => {
    return (path, scope) => {
        return $parse(path)(scope);
    };
};

propLookupFactory.$inject = [
    '$parse',
];

angular.module('avi/utils').factory('propLookup', propLookupFactory);

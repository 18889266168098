/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const prefix = 'system';

export const emailConfigurationModalBreadcrumbTitle =
    `${prefix}.emailConfigurationModalBreadcrumbTitle`;

export const ENGLISH = {
    [emailConfigurationModalBreadcrumbTitle]: 'Email/SMTP',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { createDropdownOption } from 'ng/utils/dropdown.utils';
import { CLOUD_NSXT } from 'ajs/js/services/items/Cloud';
import * as l10n from './virtualservice-vip-address.l10n';
import './virtualservice-vip-address.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  virtualserviceVipAddress
 * @param {Cloud} cloud
 * @param {VirtualService} editable
 * @param {string} createMode - 'advanced' or 'basic'. Used to differentiate between 'advanced' and
 *     'basic' Virtual Service creation. Vip sharing is only allowed in 'advanced' creation.
 * @description
 *     Handles inputs for VIP Address section of Virtual Service create/edit. Wrapper component for
 *     the various cases of VIP address configuration, by cloud type and IPAM/DNS provider profile.
 */
class VipAddressController {
    constructor(
        Regex,
        AzureConfigurationConfig,
        l10nService,
    ) {
        this.Regex = Regex;
        this._AzureConfigurationConfig = AzureConfigurationConfig;

        /**
         * Tier1 Logical Router ID options for NSX-T Virtual Services.
         * @type {string[]|undefined}
         */
        this.tier1LROptions = undefined;

        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onInit() {
        const config = this.editable.getConfig();

        this.vsVip = config['vsvip_ref_data'];
        this.vsRefs = config['shared_vs_refs'];
        this.showAdvanced = !angular.isUndefined(this.editable.getConfig().vsvip_ref);
        this.setNsxTTier1LROptions_();
    }

    /**
     * Toggles the view between 'Basic' and 'Advanced'. 'Basic' allows the user to configure
     * the Virtual Service VIP address(es), while 'Advanced' allows the user to select an
     * existing VsVip object and populate the 'vsvip_ref' property.
     */
    toggleAdvanced() {
        this.showAdvanced = !this.showAdvanced;
        delete this.editable.getConfig()['vsvip_ref'];
    }

    /**
     * Displays the FQDN input(s) if the cloud has a DNS provider profile or if the cloud is AWS
     * and has route53_integration enabled.
     * @return {boolean} True to show, false to hide.
     */
    showFqdn() {
        const cloudConfig = this.cloud.getConfig();
        const { aws_configuration: awsConfig } = cloudConfig;
        let { azure_configuration: azureConfig } = cloudConfig;

        if (azureConfig instanceof this._AzureConfigurationConfig) {
            azureConfig = azureConfig.getConfig();
        }

        return this.cloud.hasDnsProviderProfile() ||
                this.cloud.getVtype() === 'CLOUD_AWS' && awsConfig.route53_integration ||
                this.cloud.getVtype() === 'CLOUD_AZURE' &&
                (azureConfig.use_azure_dns || azureConfig.dns_provider_ref);
    }

    /**
     * If true, allows the user to add an FQDN input. True when the cloud has a DNS provider
     * profile and when the cloud is AWS, has route53_integration enabled, and currently has
     * 0 FQDNs configured, since AWS+route53_integration doesn't yet support multiple FQDN.
     * @return {boolean}
     */
    showAddFqdn() {
        const cloudConfig = this.cloud.getConfig();
        const {
            aws_configuration: awsConfig,
            azure_configuration: azureConfig,
        } = cloudConfig;

        const { dns_info: dnsList } = this.vsVip.getConfig();
        let hasDns = false;

        if (Array.isArray(dnsList)) {
            hasDns = dnsList.length > 0;
        }

        const vtype = this.cloud.getVtype();
        const isAws = vtype === 'CLOUD_AWS';
        const isAzure = vtype === 'CLOUD_AZURE';

        return !hasDns && (isAws && awsConfig.route53_integration || isAzure &&
                    (azureConfig.use_azure_dns || cloudConfig.dns_provider_ref));
    }

    /**
     * Returns a comma-separated list of Virtual Service names that the VsVip is being shared
     * with, ie. modifying the VsVip will affect these Virtual Services. The Virtual Service
     * refs come from the read-only 'shared_vs_refs' property returned by /api/virtualservice.
     * @return {string}
     */
    getSharedVipVirtualservices() {
        return this.vsRefs.map(ref => ref.name()).join(', ');
    }

    /**
     * Returns true if an address needs to be manually added, as opposed to cases in Openstack
     * and AWS where a network can be selected.
     * @return {boolean}
     */
    allowManualAddressOnly() {
        const autoClouds = {
            CLOUD_OPENSTACK: true,
            CLOUD_AWS: true,
            CLOUD_AZURE: true,
        };

        const vtype = this.cloud.getVtype();

        return !(vtype in autoClouds);
    }

    /**
     * Wrapper for getting cloud type.
     * @return {string}
     */
    getCloudType() {
        return this.cloud.getVtype();
    }

    /**
     * Sets this.tier1LROptions to a list of Tier1 Logical Router ID dropdown options.
     */
    async setNsxTTier1LROptions_() {
        if (!this.cloud.isVtype(CLOUD_NSXT)) {
            return;
        }

        try {
            const tier1LRs = await this.cloud.getNsxtTier1LogicalRoutersByID();

            this.tier1LROptions = tier1LRs.map(({ id, name }) => createDropdownOption(id, name));
        } catch (errors) {
            this.tier1LROptions = [];
        }
    }

    /**
     * Returns true if the cloud is type CLOUD_NSXT with overlay transport type.
     * @returns {boolean}
     */
    isNsxtOverlay() {
        return this.cloud.isVtype(CLOUD_NSXT) &&
            this.cloud.getCloudConfig().isOverlayTransportZone();
    }
}

VipAddressController.$inject = [
    'Regex',
    'AzureConfigurationConfig',
    'l10nService',
];

angular.module('aviApp').component('virtualserviceVipAddress', {
    bindings: {
        editable: '<',
        cloud: '<',
        createMode: '@',
    },
    controller: VipAddressController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-vip-address/' +
        'virtualservice-vip-address.html',
});

/**
 * Pool module
 * @module Pool
 * @preferred
 */
/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */
import angular from 'angular';

import { poolAutoScalePolicyController, poolCreateController } from '.';

const poolModule = angular.module('avi/pool');

const controllers = [
    {
        controller: poolAutoScalePolicyController,
        name: 'PoolAutoScalePolicyController',
    },
    {
        controller: poolCreateController,
        name: 'PoolCreateController',
    },
];

controllers.forEach(({ name, controller }) => poolModule.controller(name, controller));

/**
 * @module WelcomeModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
    ClrStepperModule,
} from '@clr/angular';

import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'ng/shared/shared.module';
import { CoreModule } from 'ng/modules/core/core.module';

import {
    WelcomeBackupPassphraseComponent,
    WelcomeEmailAnonymousServerSettingsComponent,
    WelcomeEmailLocalhostSettingsComponent,
    WelcomeEmailSettingsComponent,
    WelcomeEmailSmtpServerSettingsComponent,
    WelcomeModalComponent,
    WelcomeModalContainerComponent,
    WelcomeService,
    WelcomeSystemSettingsComponent,
    WelcomeTenantSettingsComponent,
} from '.';

const welcomeComponents = [
    WelcomeBackupPassphraseComponent,
    WelcomeEmailAnonymousServerSettingsComponent,
    WelcomeEmailLocalhostSettingsComponent,
    WelcomeEmailSettingsComponent,
    WelcomeEmailSmtpServerSettingsComponent,
    WelcomeModalComponent,
    WelcomeModalContainerComponent,
    WelcomeSystemSettingsComponent,
    WelcomeTenantSettingsComponent,
];

/**
 * @description
 *
 *      Module for Welcome-flow related services and components.
 *
 * @author Aravindh Nagarajan
 */
@NgModule({
    declarations: [
        ...welcomeComponents,
    ],
    providers: [
        WelcomeService,
    ],
    imports: [
        BrowserAnimationsModule,
        ClrCheckboxModule,
        ClrFormsModule,
        ClrInputModule,
        ClrStepperModule,
        CoreModule,
        FormsModule,
        SharedModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class WelcomeModule {}

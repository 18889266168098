const moduleName = 'ajs-legacy';
const componentName = 'segroup-create-controller';
const prefix = `${moduleName}.${componentName}`;

export const basicSettingsLabel = `${prefix}.basicSettingsLabel`;
export const advancedLabel = `${prefix}.advancedLabel`;
export const serviceEngineGroupNameInputLabel = `${prefix}.serviceEngineGroupNameInputLabel`;
export const metricUpdateFrequencyInputLabel = `${prefix}.metricUpdateFrequencyInputLabel`;
export const realTimeMetricsCheckboxLabel = `${prefix}.realTimeMetricsCheckboxLabel`;
export const highAvailabilityPlacementSettings = `${prefix}.highAvailabilityPlacementSettings`;
export const highAvailabilityMode = `${prefix}.highAvailabilityMode`;
export const legacyHaInputLabel = `${prefix}.legacyHaInputLabel`;
export const activeStandbyRadioLabel = `${prefix}.activeStandbyRadioLabel`;
export const elasticHaInputLabel = `${prefix}.elasticHaInputLabel`;
export const activeRadioLabel = `${prefix}.activeRadioLabel`;
export const nmBufferRadioLabel = `${prefix}.nmBufferRadioLabel`;
export const vsPerServiceEngineInputLabel = `${prefix}.vsPerServiceEngineInputLabel`;
export const maximumInnerText = `${prefix}.maximumInnerText`;
export const selfElectionCheckboxLabel = `${prefix}.selfElectionCheckboxLabel`;
export const vsPlacementAcrossSesInputLabel = `${prefix}.vsPlacementAcrossSesInputLabel`;
export const compactRadioLabel = `${prefix}.compactRadioLabel`;
export const distributedRadioLabel = `${prefix}.distributedRadioLabel`;
export const routeAggregationSubnetsInputLabel = `${prefix}.routeAggregationSubnetsInputLabel`;
export const removeSubnetButtonTitle = `${prefix}.removeSubnetButtonTitle`;
export const addSubnetLink = `${prefix}.addSubnetLink`;
export const healthMonitorStandbyInputLabel = `${prefix}.healthMonitorStandbyInputLabel`;
export const distributeLoadCheckboxLabel = `${prefix}.distributeLoadCheckboxLabel`;
export const autoRedistributeLoadCheckboxLabel = `${prefix}.autoRedistributeLoadCheckboxLabel`;
export const seCapacityLimitSettings = `${prefix}.seCapacityLimitSettings`;
export const maxNumberOfServiceEnginesInputLabel = `${prefix}.maxNumberOfServiceEnginesInputLabel`;
export const memoryPerServiceEngineInputLabel = `${prefix}.memoryPerServiceEngineInputLabel`;
export const memorySizeInputPlaceholder = `${prefix}.memorySizeInputPlaceholder`;
export const memoryReserveCheckboxLabel = `${prefix}.memoryReserveCheckboxLabel`;
export const vcpuPerServiceEngineInputLabel = `${prefix}.vcpuPerServiceEngineInputLabel`;
export const cpuReserveCheckboxLabel = `${prefix}.cpuReserveCheckboxLabel`;
export const diskPerServiceEngineInputLabel = `${prefix}.diskPerServiceEngineInputLabel`;
export const diskSizeInputPlaceholder = `${prefix}.diskSizeInputPlaceholder`;
export const memoryAllocationSettings = `${prefix}.memoryAllocationSettings`;
export const hostGeolocationProfileCheckboxLabel = `${prefix}.hostGeolocationProfileCheckboxLabel`;
export const memoryForCachingInputLabel = `${prefix}.memoryForCachingInputLabel`;
export const percentageInputPlaceHolder = `${prefix}.percentageInputPlaceHolder`;
export const availableMemoryForConnectionsBuffersInputLabel = `${prefix}.availableMemoryForConnectionsBuffersInputLabel`;
export const connectionsBuffersMemoryDistributionInputLabel = `${prefix}.connectionsBuffersMemoryDistributionInputLabel`;
export const connectionsLabel = `${prefix}.connectionsLabel`;
export const buffersLabel = `${prefix}.buffersLabel`;
export const serviceEngineNamePrefixInputLabel = `${prefix}.serviceEngineNamePrefixInputLabel`;
export const deleteUnusedServiceEnginesAfterLabel = `${prefix}.deleteUnusedServiceEnginesAfterLabel`;
export const serviceEngineFolderInputLabel = `${prefix}.serviceEngineFolderInputLabel`;
export const pathInputPlaceholder = `${prefix}.pathInputPlaceholder`;
export const hostDataStoreScopeLabel = `${prefix}.hostDataStoreScopeLabel`;
export const includeInputLabel = `${prefix}.includeInputLabel`;
export const excludeInputLabel = `${prefix}.excludeInputLabel`;
export const dataStoreScopeInputLabel = `${prefix}.dataStoreScopeInputLabel`;
export const dataStoreInputLabel = `${prefix}.dataStoreInputLabel`;
export const selectDataStoresInputPlaceholder = `${prefix}.selectDataStoresInputPlaceholder`;
export const advancedHaPlacementSettings = `${prefix}.advancedHaPlacementSettings`;
export const bufferServiceEnginesInputLabel = `${prefix}.bufferServiceEnginesInputLabel`;
export const overrideDataNetworkInputLabel = `${prefix}.overrideDataNetworkInputLabel`;
export const selectNetworkInputPlaceholder = `${prefix}.selectNetworkInputPlaceholder`;
export const overrideManagementNetworkInputLabel = `${prefix}.overrideManagementNetworkInputLabel`;
export const scalePerVirtualServiceInputLabel = `${prefix}.scalePerVirtualServiceInputLabel`;
export const minimumInnerText = `${prefix}.minimumInnerText`;
export const cpuSocketAffinityCheckboxLabel = `${prefix}.cpuSocketAffinityCheckboxLabel`;
export const dedicatedDispatcherCpuCheckboxLabel = `${prefix}.dedicatedDispatcherCpuCheckboxLabel`;
export const serviceEnginePlacementLabel = `${prefix}.serviceEnginePlacementLabel`;
export const seScopeWithinAvailabilityZonesLabel = `${prefix}.seScopeWithinAvailabilityZonesLabel`;
export const anyInputLabel = `${prefix}.anyInputLabel`;
export const availabilityZoneInputLabel = `${prefix}.availabilityZoneInputLabel`;
export const availabilityZonesInputLabel = `${prefix}.availabilityZonesInputLabel`;
export const selectAvailabilityZonesInputPlaceholder = `${prefix}.selectAvailabilityZonesInputPlaceholder`;
export const overrideOpenStackManagementNetworkInputLabel = `${prefix}.overrideOpenStackManagementNetworkInputLabel`;
export const securitySettings = `${prefix}.securitySettings`;
export const hsmGroupInputLabel = `${prefix}.hsmGroupInputLabel`;
export const selectHsmGroupInputPlaceholder = `${prefix}.selectHsmGroupInputPlaceholder`;
export const logCollectionStreamingSettings = `${prefix}.logCollectionStreamingSettings`;
export const significantLogThrottleInputLabel = `${prefix}.significantLogThrottleInputLabel`;
export const throttleInputPlaceholder = `${prefix}.throttleInputPlaceholder`;
export const udfLogThrottleInputLabel = `${prefix}.udfLogThrottleInputLabel`;
export const logsSecondInnerText = `${prefix}.logsSecondInnerText`;
export const nonSignificantLogThrottleInputLabel = `${prefix}.nonSignificantLogThrottleInputLabel`;
export const numberOfStreamingThreadsInputLabel = `${prefix}.numberOfStreamingThreadsInputLabel`;
export const threadsInnerText = `${prefix}.threadsInnerText`;
export const otherSettings = `${prefix}.otherSettings`;
export const aviManagedSecurityGroupCheckboxLabel = `${prefix}.aviManagedSecurityGroupCheckboxLabel`;
export const managementVnicCustomSecurityGroupsInputLabel = `${prefix}.managementVnicCustomSecurityGroupsInputLabel`;
export const selectManagementVnicCustomSecurityGroupsInputPlaceholder = `${prefix}.selectManagementVnicCustomSecurityGroupsInputPlaceholder`;
export const dataVnicCustomSecurityGroupsInputLabel = `${prefix}.dataVnicCustomSecurityGroupsInputLabel`;
export const selectDataVnicCustomSecurityGroupsInputPlaceholder = `${prefix}.selectDataVnicCustomSecurityGroupsInputPlaceholder`;
export const editServiceEngineGroupLabel = `${prefix}.editServiceEngineGroupLabel`;
export const newServiceEngineGroupLabel = `${prefix}.newServiceEngineGroupLabel`;
export const memoryAvailableBesidesCachingLabel = `${prefix}.memoryAvailableBesidesCachingLabel`;
export const minutesInnerText = `${prefix}.minutesInnerText`;
export const saveLabel = `${prefix}.saveLabel`;
export const cancelLabel = `${prefix}.cancelLabel`;

export const ENGLISH = {
    [basicSettingsLabel]: 'Basic Settings',
    [advancedLabel]: 'Advanced',
    [serviceEngineGroupNameInputLabel]: 'Service Engine Group Name',
    [metricUpdateFrequencyInputLabel]: 'Metric Update Frequency',
    [realTimeMetricsCheckboxLabel]: 'Real-Time Metrics',
    [highAvailabilityPlacementSettings]: 'High Availability & Placement Settings',
    [highAvailabilityMode]: 'High Availability Mode',
    [legacyHaInputLabel]: 'Legacy HA',
    [activeStandbyRadioLabel]: 'Active/Standby',
    [elasticHaInputLabel]: 'Elastic HA',
    [activeRadioLabel]: 'Active/Active',
    [nmBufferRadioLabel]: 'N + M (buffer)',
    [vsPerServiceEngineInputLabel]: 'Virtual Services per Service Engine',
    [maximumInnerText]: 'Maximum',
    [selfElectionCheckboxLabel]: 'SE Self-Election',
    [vsPlacementAcrossSesInputLabel]: 'VS Placement across SEs',
    [compactRadioLabel]: 'Compact',
    [distributedRadioLabel]: 'Distributed',
    [routeAggregationSubnetsInputLabel]: 'Route Aggregation Subnets',
    [removeSubnetButtonTitle]: 'Remove Subnet',
    [addSubnetLink]: '+ Add Subnet',
    [healthMonitorStandbyInputLabel]: 'Health Monitoring on Standby SE(s)',
    [distributeLoadCheckboxLabel]: 'Distribute Load',
    [autoRedistributeLoadCheckboxLabel]: 'Auto-redistribute Load',
    [seCapacityLimitSettings]: 'Service Engine Capacity and Limit Settings',
    [maxNumberOfServiceEnginesInputLabel]: 'Max Number of Service Engines',
    [memoryPerServiceEngineInputLabel]: 'Memory per Service Engine',
    [memorySizeInputPlaceholder]: 'Memory size',
    [memoryReserveCheckboxLabel]: 'Memory Reserve',
    [vcpuPerServiceEngineInputLabel]: 'vCPU per Service Engine',
    [cpuReserveCheckboxLabel]: 'CPU Reserve',
    [diskPerServiceEngineInputLabel]: 'Disk per Service Engine',
    [diskSizeInputPlaceholder]: 'Disk size',
    [memoryAllocationSettings]: 'Memory Allocation',
    [hostGeolocationProfileCheckboxLabel]: 'Host Geolocation Profile',
    [memoryForCachingInputLabel]: 'Memory for Caching',
    [percentageInputPlaceHolder]: 'Percentage',
    [availableMemoryForConnectionsBuffersInputLabel]: 'Available Memory for Connections and Buffers',
    [connectionsBuffersMemoryDistributionInputLabel]: 'Connections and Buffers Memory Distribution (slide the bar left or right)',
    [connectionsLabel]: 'Connections: {0}%',
    [buffersLabel]: 'Buffers: {0}%',
    [serviceEngineNamePrefixInputLabel]: 'Service Engine Name Prefix',
    [deleteUnusedServiceEnginesAfterLabel]: 'Delete Unused Service Engines After',
    [serviceEngineFolderInputLabel]: 'Service Engine Folder',
    [pathInputPlaceholder]: 'Path',
    [hostDataStoreScopeLabel]: 'Host & Data Store Scope',
    [includeInputLabel]: 'Include',
    [excludeInputLabel]: 'Exclude',
    [dataStoreScopeInputLabel]: 'Data Store Scope for Service Engine Virtual Machine',
    [dataStoreInputLabel]: 'Data Store',
    [selectDataStoresInputPlaceholder]: 'Select Data Stores',
    [advancedHaPlacementSettings]: 'Advanced HA & Placement',
    [bufferServiceEnginesInputLabel]: 'Buffer Service Engines',
    [overrideDataNetworkInputLabel]: 'Override Data Network',
    [selectNetworkInputPlaceholder]: 'Select a Network',
    [overrideManagementNetworkInputLabel]: 'Override Management Network',
    [scalePerVirtualServiceInputLabel]: 'Scale per Virtual Service',
    [minimumInnerText]: 'Minimum',
    [cpuSocketAffinityCheckboxLabel]: 'CPU socket Affinity',
    [dedicatedDispatcherCpuCheckboxLabel]: 'Dedicated dispatcher CPU',
    [serviceEnginePlacementLabel]: 'Service Engine Placement',
    [seScopeWithinAvailabilityZonesLabel]: 'Service Engine scope within Availability Zones',
    [anyInputLabel]: 'Any',
    [availabilityZoneInputLabel]: 'Availability Zone',
    [availabilityZonesInputLabel]: 'Availability Zones',
    [selectAvailabilityZonesInputPlaceholder]: 'Select Availability Zones',
    [overrideOpenStackManagementNetworkInputLabel]: 'Override OpenStack Management Network',
    [securitySettings]: 'Security',
    [hsmGroupInputLabel]: 'HSM Group',
    [selectHsmGroupInputPlaceholder]: 'Select HSM Group',
    [logCollectionStreamingSettings]: 'Log Collection and Streaming Settings',
    [significantLogThrottleInputLabel]: 'Significant Log Throttle',
    [throttleInputPlaceholder]: 'Throttle',
    [udfLogThrottleInputLabel]: 'UDF Log Throttle',
    [logsSecondInnerText]: 'Logs/Second',
    [nonSignificantLogThrottleInputLabel]: 'Non-significant Log Throttle',
    [numberOfStreamingThreadsInputLabel]: 'Number of Streaming Threads',
    [threadsInnerText]: 'Threads',
    [otherSettings]: 'Other Settings',
    [aviManagedSecurityGroupCheckboxLabel]: 'Avi Managed Security Group',
    [managementVnicCustomSecurityGroupsInputLabel]: 'Management vNIC Custom Security Groups',
    [selectManagementVnicCustomSecurityGroupsInputPlaceholder]: 'Select Management vNIC Custom Security Groups',
    [dataVnicCustomSecurityGroupsInputLabel]: 'Data vNIC Custom Security Groups',
    [selectDataVnicCustomSecurityGroupsInputPlaceholder]: 'Select Data vNIC Custom Security Groups',
    [editServiceEngineGroupLabel]: 'Edit Service Engine Group: {0}',
    [newServiceEngineGroupLabel]: 'New Service Engine Group:',
    [memoryAvailableBesidesCachingLabel]: 'Memory available besides caching',
    [minutesInnerText]: 'min',
    [saveLabel]: 'Save',
    [cancelLabel]: 'Cancel',
};

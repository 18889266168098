/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    createCrudCollectionGridConfig,
} from 'ajs/js/utilities/create-crud-collection-grid-config.factory';

/**
 * @ngdoc factory
 * @name  CRUDGridConfig
 * @description  Returns a basic CRUD gridConfig that allows for Editing and Deleting rows.
 *               Collection and field list should be added before use.
 */
angular
    .module('avi/component-kit/grid')
    .constant('CRUDGridConfig', createCrudCollectionGridConfig);

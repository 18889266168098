/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const collectionFactory = (Collection, AutoScaleLaunchConfig) => {
    class AutoScaleLaunchConfigCollection extends Collection {
        constructor(args) {
            const extendedArgs = {
                ...args,
                permissionName: 'PERMISSION_AUTOSCALE',
            };

            super(extendedArgs);
        }
    }

    Object.assign(AutoScaleLaunchConfigCollection.prototype, {
        objectName_: 'autoscalelaunchconfig',
        itemClass_: AutoScaleLaunchConfig,
        windowElement_: 'launch-config-modal',
    });

    return AutoScaleLaunchConfigCollection;
};

collectionFactory.$inject = [
    'Collection',
    'AutoScaleLaunchConfig',
];

/**
 * @ngdoc service
 * @description
 *     AutoScale Launch Config collection.
 */
angular.module('avi/app').factory('AutoScaleLaunchConfigCollection', collectionFactory);

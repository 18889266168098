/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name SingleSeriesPerEveryObjectMetric
 * @description
 *
 *     Multiple obj_id with just one seriesId.
 *
 *     By default uses * as object id but when list of object ids is provided will load only
 *     passed ones. If list is not provided and whiteListOnly_ is set to true Metric won't make a
 *     call.
 *
 */
angular.module('aviApp').service('SingleSeriesPerEveryObjectMetric', [
'CollMetric', function(CollMetric) {
    return class SingleSeriesPerEveryObjectMetric extends CollMetric {
        constructor(args) {
            args.stackedSeries = true;
            args.staticSeriesList = false;

            super(args);

            /**
             * White list of object ids to be loaded by Metric.
             * @type {string[]}
             * @private
             */
            this.objIds_ = [];

            if (args.objIds) {
                this.setObjIdsList(args.objIds);
            }

            /**
             * When set to true loads object ids present in this.objIds_ only.
             * @type {boolean}
             * @private
             */
            this.whiteListOnly_ =
                angular.isUndefined(args.whiteListOnly) ? false : !!args.whiteListOnly;
        }

        /**
         * ObjId (network interface name) is the short series id form.
         * @override
         */
        seriesIdToConfig_(objId) {
            return {
                objId,
                seriesId: this.seriesId_,
            };
        }

        /** @override */
        requestConfig() {
            const config = super.requestConfig();

            if (config) {
                const { objIds_: objIds } = this;

                config['obj_id'] = objIds.join() || '*';

                if (config['obj_id'] === '*' && this.whiteListOnly_) {
                    return null;
                }
            }

            return config;
        }

        /** @override */
        getSeriesConfigBySeriesHeader_(header) {
            const config = super.getSeriesConfigBySeriesHeader_(header);

            config['title'] = config['objId'];

            return config;
        }

        /**
         * Sets the white list of object ids to be fetched by the Metric.
         * @param {Array<string>=} objIds
         * @public
         */
        setObjIdsList(objIds) {
            const { objIds_ } = this;

            objIds_.length = 0;

            this.removeAllSeries();//mb need to do this conditionally

            if (Array.isArray(objIds) && objIds.length) {
                objIds_.push(...objIds);
            }
        }
    };
}]);

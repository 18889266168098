/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function alertConfigFactory(Item, defaultValues) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Item
     * @author Ashish Verma
     * @desc Item of {@link module:avi/app.AlertConfig AlertConfig}.
     */
    class AlertConfig extends Item {
        /** @override **/
        beforeEdit() {
            const { config } = this.data;

            if (!config.source) {
                config.source = 'EVENT_LOGS';
            }

            if (!config.alert_rule) {
                config.alert_rule = {
                    operator: 'OPERATOR_AND',
                };
            }

            if (config.alert_rule.sys_event_rule) {
                const alert = config.alert_rule;

                if (Array.isArray(alert.sys_event_rule) && alert.sys_event_rule.length === 2) {
                    if (alert.sys_event_rule[1].not_cond === true) {
                        alert.operator = 'OPERATOR_NOT';
                    }
                }
            } else {
                config.alert_rule.sys_event_rule = [
                    defaultValues.getDefaultItemConfigByType('alertruleevent'),
                ];
            }

            if (!config.alert_rule.metrics_rule) {
                config.alert_rule.metrics_rule = [
                    defaultValues.getDefaultItemConfigByType('alertrulemetric'),
                ];
            }

            if (angular.isUndefined(config.object_type)) {
                config.object_type = 'none';
            }
        }

        dataToSave() {
            const config = angular.copy(this.data.config);

            if (config.source === 'EVENT_LOGS') {
                const alert = config.alert_rule;

                if (alert.operator === 'OPERATOR_NOT') {
                    alert.operator = 'OPERATOR_AND';
                    alert.sys_event_rule[1].not_cond = true;
                } else {
                    alert.sys_event_rule.forEach(function(rule) {
                        rule.not_cond = false;
                    });
                }

                alert.sys_event_rule = alert.sys_event_rule.filter(function(rule) {
                    return !_.isUndefined(rule.event_id);
                });
            } else {
                config.alert_rule.sys_event_rule = undefined;
            }

            if (config.source !== 'METRICS') {
                config.alert_rule.metrics_rule = undefined;
            }

            if (config.object_type === 'none') {
                delete config.object_type;
            }

            return config;
        }
    }

    Object.assign(AlertConfig.prototype, {
        objectName: 'alertconfig',
        windowElement: 'adm-alertconfig-create',
    });

    return AlertConfig;
}

alertConfigFactory.$inject = [
    'Item',
    'defaultValues',
];

angular.module('avi/app').factory('AlertConfig', alertConfigFactory);

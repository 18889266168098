/**
 * @module Angular-Core
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import base64js from 'base64-js';
import TextDecoderLite from 'text-encoder-lite';

/**
 * @desc Converts ArrayBuffer with file content into regular or base64-encoded string.
 * @author Alex Malitsky, alextsg
 */
export function arrayBufferDecoder(arrayBuffer: ArrayBuffer, intoBase64 = false): string {
    // need this for base64js and TextDecoderLite, TextDecoder doesn't seem to care
    const uint8arr = new Uint8Array(arrayBuffer);

    if (intoBase64) {
        return base64js.fromByteArray(uint8arr);
    } else {
        // setting this in runtime so that we can unit test both options
        const Decoder = TextDecoder || TextDecoderLite;
        const textDecoder = new Decoder('utf-8');

        return textDecoder.decode(uint8arr);
    }
}

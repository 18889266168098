const moduleName = 'ajs-legacy';
const componentName = 'cloud-create-controller';
const prefix = `${moduleName}.${componentName}`;

export const seInProviderContextTooltip = `${prefix}.seInProviderContextTooltip`;
export const cloudSelectionTitle = `${prefix}.cloudSelectionTitle`;
export const selectCloudTitle = `${prefix}.selectCloudTitle`;
export const infrastructureTitle = `${prefix}.infrastructureTitle`;
export const dataCenterTitle = `${prefix}.dataCenterTitle`;
export const networkTitle = `${prefix}.networkTitle`;
export const roleMappingTitle = `${prefix}.roleMappingTitle`;
export const vpcNetworkEncryptionTitle = `${prefix}.vpcNetworkEncryptionTitle`;
export const dhcpSettingsTitle = `${prefix}.dhcpSettingsTitle`;
export const linuxServerConfigurationTitle = `${prefix}.linuxServerConfigurationTitle`;
export const credentialsTitle = `${prefix}.credentialsTitle`;
export const locationNetworkTitle = `${prefix}.locationNetworkTitle`;
export const generalTitle = `${prefix}.generalTitle`;
export const separateManagementInterfaceToolTip = `${prefix}.separateManagementInterfaceToolTip`;
export const vcenterVsphereLoginLabel = `${prefix}.vcenterVsphereLoginLabel`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const usernameInputPlaceholder = `${prefix}.usernameInputPlaceholder`;
export const vcenterAddressInputLabel = `${prefix}.vcenterAddressInputLabel`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const passwordInputPlaceholder = `${prefix}.passwordInputPlaceholder`;
export const accessPermissionInputLabel = `${prefix}.accessPermissionInputLabel`;
export const readButtonLabel = `${prefix}.readButtonLabel`;
export const writeButtonLabel = `${prefix}.writeButtonLabel`;
export const cloudNameInputPlaceholder = `${prefix}.cloudNameInputPlaceholder`;
export const licenseModelLabel = `${prefix}.licenseModelLabel`;
export const ipamProfileInputLabel = `${prefix}.ipamProfileInputLabel`;
export const selectIpamProfilePlaceholder = `${prefix}.selectIpamProfilePlaceholder`;
export const dnsProfileInputLabel = `${prefix}.dnsProfileInputLabel`;
export const selectDnsProfilePlaceholder = `${prefix}.selectDnsProfilePlaceholder`;
export const createIpamDnsProfileButtonLabel = `${prefix}.createIpamDnsProfileButtonLabel`;
export const selectDataCenterInputLabel = `${prefix}.selectDataCenterInputLabel`;
export const dataCenterInputLabel = `${prefix}.dataCenterInputLabel`;
export const selectDataCenterInputPlaceholder = `${prefix}.selectDataCenterInputPlaceholder`;
export const systemIpAddressManagementSettingLabel = `${prefix}.systemIpAddressManagementSettingLabel`;
export const defaultNetworkIpAddressManagementLabel = `${prefix}.defaultNetworkIpAddressManagementLabel`;
export const dhcpEnabledCheckboxLabel = `${prefix}.dhcpEnabledCheckboxLabel`;
export const ipv6AutoConfigurationCheckboxLabel = `${prefix}.ipv6AutoConfigurationCheckboxLabel`;
export const virtualServicePlacementSettingsLabel = `${prefix}.virtualServicePlacementSettingsLabel`;
export const preferStaticRoutesVsDirectlyConnectedNetworkLabel = `${prefix}.preferStaticRoutesVsDirectlyConnectedNetworkLabel`;
export const useStaticRoutesForNetworkResolutionOfVipLabel = `${prefix}.useStaticRoutesForNetworkResolutionOfVipLabel`;
export const selectManagementNetworkLabel = `${prefix}.selectManagementNetworkLabel`;
export const managementNetworkInputLabel = `${prefix}.managementNetworkInputLabel`;
export const selectManagementNetworkInputPlaceholder = `${prefix}.selectManagementNetworkInputPlaceholder`;
export const serviceEngineLabel = `${prefix}.serviceEngineLabel`;
export const ipAddressManagementNetworkLabel = `${prefix}.ipAddressManagementNetworkLabel`;
export const ipSubnetInputLabel = `${prefix}.ipSubnetInputLabel`;
export const defaultGatewayLabel = `${prefix}.defaultGatewayLabel`;
export const addStaticIpAddressPoolInputLabel = `${prefix}.addStaticIpAddressPoolInputLabel`;
export const stateBasedDnsRegistrationCheckboxLabel = `${prefix}.stateBasedDnsRegistrationCheckboxLabel`;
export const templateServiceEngineGroupInputLabel = `${prefix}.templateServiceEngineGroupInputLabel`;
export const templateSegGroupDropdownPlaceholder = `${prefix}.templateSegGroupDropdownPlaceholder`;
export const createServiceEngineGroupLabel = `${prefix}.createServiceEngineGroupLabel`;

export const ENGLISH = {
    [seInProviderContextTooltip]: 'Provider mode is turned on in the Tenant Settings. Hence, all SEs can only be created in the provider tenant.',
    [cloudSelectionTitle]: 'Cloud Selection',
    [selectCloudTitle]: 'Select Cloud',
    [infrastructureTitle]: 'Infrastructure',
    [dataCenterTitle]: 'Data Center',
    [networkTitle]: 'Network',
    [roleMappingTitle]: 'Role Mapping',
    [vpcNetworkEncryptionTitle]: 'VPC/Network/Encryption',
    [dhcpSettingsTitle]: 'DHCP Settings',
    [linuxServerConfigurationTitle]: 'Linux Server Configuration',
    [credentialsTitle]: 'Credentials',
    [locationNetworkTitle]: 'Location/Network',
    [generalTitle]: 'General',
    [separateManagementInterfaceToolTip]: 'Use this option to use a separate NIC for control-plane communication between Service Engines and Controllers.When this option is not selected, a single NIC is used for both data and control plane traffic.',
    [vcenterVsphereLoginLabel]: 'vCenter / vSphere Login',
    [nameInputLabel]: 'Name',
    [cloudNameInputPlaceholder]: 'Name',
    [usernameInputLabel]: 'Username',
    [usernameInputPlaceholder]: 'Username',
    [vcenterAddressInputLabel]: 'vCenter Address',
    [passwordInputLabel]: 'Password',
    [passwordInputPlaceholder]: 'Password',
    [accessPermissionInputLabel]: 'Access Permission',
    [readButtonLabel]: 'Read',
    [writeButtonLabel]: 'Write',
    [licenseModelLabel]: 'License Model',
    [ipamProfileInputLabel]: 'IPAM Profile',
    [selectIpamProfilePlaceholder]: 'Select IPAM Profile',
    [dnsProfileInputLabel]: 'DNS Profile',
    [selectDnsProfilePlaceholder]: 'Select DNS Profile',
    [createIpamDnsProfileButtonLabel]: 'Create IPAM/DNS Profile',
    [selectDataCenterInputLabel]: 'Select a Data Center',
    [dataCenterInputLabel]: 'Data Center',
    [selectDataCenterInputPlaceholder]: 'Select a Data Center',
    [systemIpAddressManagementSettingLabel]: 'System IP Address Management Setting',
    [defaultNetworkIpAddressManagementLabel]: 'Default Network IP Address Management',
    [dhcpEnabledCheckboxLabel]: 'DHCP Enabled',
    [ipv6AutoConfigurationCheckboxLabel]: 'IPv6 Auto Configuration',
    [virtualServicePlacementSettingsLabel]: 'Virtual Service Placement Settings',
    [preferStaticRoutesVsDirectlyConnectedNetworkLabel]: 'Prefer Static Routes vs Directly Connected Network',
    [useStaticRoutesForNetworkResolutionOfVipLabel]: 'Use Static Routes for Network Resolution of VIP',
    [selectManagementNetworkLabel]: 'Select Management Network',
    [managementNetworkInputLabel]: 'Management Network',
    [selectManagementNetworkInputPlaceholder]: 'Select Management Network',
    [serviceEngineLabel]: 'Service Engine',
    [ipAddressManagementNetworkLabel]: 'IP Address Management for Management Network',
    [ipSubnetInputLabel]: 'IP Subnet',
    [defaultGatewayLabel]: 'Default Gateway',
    [addStaticIpAddressPoolInputLabel]: 'Add Static IP Address Pool',
    [stateBasedDnsRegistrationCheckboxLabel]: 'State Based DNS Registration',
    [templateServiceEngineGroupInputLabel]: 'Template Service Engine Group',
    [templateSegGroupDropdownPlaceholder]: 'None',
    [createServiceEngineGroupLabel]: 'Create Service Engine Group',
};

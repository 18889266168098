/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function aggEventCollectionFactory(
    EventCollection,
    AggEvent,
) {
    /**
     * @constructor
     * @memberOf module:avi/events
     * @extends module:avi/events.EventCollection
     * @author Ram Pal
     * @desc Collection of {@link module:avi/events.AggEvent AggEvents}.
     */
    class AggEventCollection extends EventCollection {}

    const dataSources = {
        list: {
            source: 'EventListCollDataSource',
            transformer: 'ListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    Object.assign(AggEventCollection.prototype, {
        itemClass_: AggEvent,
        allDataSources_: dataSources,
    });

    return AggEventCollection;
}

aggEventCollectionFactory.$inject = [
    'EventCollection',
    'AggEvent',
];

angular.module('avi/events')
    .factory('AggEventCollection', aggEventCollectionFactory);

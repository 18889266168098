const moduleName = 'ajs-legacy';
const componentName = 'user-menu';
const prefix = `${moduleName}.${componentName}`;

export const signOutLabel = `${prefix}.signOutLabel`;
export const aboutAviVantageLabel = `${prefix}.aboutAviVantageLabel`;
export const customerPortalLabel = `${prefix}.customerPortalLabel`;
export const aviApiDocumentationLabel = `${prefix}.aviApiDocumentationLabel`;
export const generateTokenLabel = `${prefix}.generateTokenLabel`;

export const ENGLISH = {
    [signOutLabel]: 'Sign Out',
    [aboutAviVantageLabel]: 'About Avi Vantage',
    [customerPortalLabel]: 'Customer Portal',
    [aviApiDocumentationLabel]: 'Avi API Documentation',
    [generateTokenLabel]: 'Generate Token',
};

/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */
import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import './full-modal-config-confirmation.component.less';
import * as l10n from './full-modal-config-confirmation.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for displaying a prompt to the user to confirm whether or not to discard
 *     unsaved changes.
 * @author alextsg
 */
@Component({
    selector: 'full-modal-config-confirmation',
    templateUrl: './full-modal-config-confirmation.component.html',
})
export class FullModalConfigConfirmationComponent {
    /**
     * Message to be displayed for the user to confirm.
     */
    @Input()
    public confirmationMessage: string;

    /**
     * Text to show for the Cancel button.
     */
    @Input() public cancelButtonText: string;

    /**
     * Text to show for the confirmation button.
     */
    @Input() public confirmButtonText: string;

    /**
     * Called when the user clicks the Cancel button.
     */
    @Output() public onCancel = new EventEmitter();

    /**
     * Called when the user clicks the button to confirm discarding changes.
     */
    @Output() public onConfirm = new EventEmitter();

    /**
     * Handler for clicking the Confirm button.
     */

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);

        this.confirmationMessage = l10nService.getMessage(l10nKeys.confirmationMessage);

        this.cancelButtonText = l10nService.getMessage(l10nKeys.cancelBtnLabel);

        this.confirmButtonText = l10nService.getMessage(l10nKeys.discardChangesBtnLabel);
    }

    public handleConfirm(): void {
        this.onConfirm.emit();
    }

    /**
     * Handler for clicking the Cancel button.
     */
    public handleCancel(): void {
        this.onCancel.emit();
    }
}

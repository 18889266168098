/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    Input,
} from '@angular/core';
import { debounce, pluck } from 'underscore';
import { IFuseOptions } from 'fuse.js';
import { IDragAndDropIndices } from 'ng/shared/shared.types';
import {
    WafPolicyPsmGroup,
    WafPSMLocationConfigItem,
} from 'ajs/modules/waf';
import './waf-policy-psm-locations-config.less';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './waf-policy-psm-locations-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const fuseOptions = {
    threshold: 0.2,
    keys: [
        'config.name',
        'config.description',
        'config.match.config.path.searchableValues',
        'config.match.config.methods.searchableValues',
        'config.match.config.host.searchableValues',
    ],
};

/**
 * @desc Component for displaying a list of WAF PSM locations.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-locations-config',
    templateUrl: './waf-policy-psm-locations-config.component.html',
})
export class WafPolicyPsmLocationsConfigComponent {
    @Input() public breadcrumbs: string[];
    @Input() public psmGroup: WafPolicyPsmGroup;
    @Input() public preventEdit: boolean;

    /**
     * List of locations filtered by the searchTerm input.
     */
    public filteredLocations: WafPSMLocationConfigItem[] = [];

    /**
     * Cache the state of the search input value since Fuse is dynamically loaded.
     */
    public searchValue: string;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Fuse instance.
     */
    private fuse: Fuse<WafPSMLocationConfigItem, IFuseOptions<WafPSMLocationConfigItem>>;

    /**
     * True to show the filtered locations instead of the full list.
     */
    private showSearchResults = false;

    public constructor(private l10nService: L10nService) {
        this.handleSearch = debounce(this.handleSearch, 250);
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Creates a new location and opens the modal to edit it.
     */
    public addLocation(): void {
        this.psmGroup.addLocation(this.getWafPsmLocationModalBindings());
    }

    /**
     * Edits a WafPSMLocation config item.
     */
    public editLocation(location: WafPSMLocationConfigItem): void {
        this.psmGroup.editLocation(location, this.getWafPsmLocationModalBindings());
    }

    /**
     * Deletes a WafPSMLocation config item.
     */
    public deleteLocation(index: number): void {
        this.psmGroup.locations.remove(index);
    }

    /**
     * Moves a WafPSMLocation from one index to another index.
     */
    public moveLocation(indices: IDragAndDropIndices): void {
        const { previousIndex, currentIndex } = indices;
        const psmGroupLocation = this.psmGroup.locations.at(previousIndex);

        this.psmGroup.locations.moveItem(psmGroupLocation, currentIndex);
    }

    /**
     * Returns a list of rules to be displayed.
     */
    public get locations(): WafPSMLocationConfigItem[] {
        return this.showSearchResults ? this.filteredLocations : this.psmGroup.locations.config;
    }

    /**
     * Dynamically loads the fuse.js library.
     * A new Fuse instance is created on focus, to ensure that the search is performed on the most
     * recent list, as the list is required for creating a new instance.
     */
    public async handleSearchFocus(): Promise<void> {
        const module = await import(/* webpackChunkName: "async-fusejs" */ 'fuse.js');
        const Fuse = module.default;

        this.fuse = new Fuse(this.psmGroup.locations.config, fuseOptions);

        if (this.searchValue) {
            this.handleSearch();
        }
    }

    /**
     * Removes the Fuse instance on blur.
     */
    public handleSearchBlur(): void {
        this.fuse = undefined;
    }

    /**
     * Called on search.
     */
    public handleSearch = (): void => {
        if (this.fuse) {
            this.showSearchResults = Boolean(this.searchValue);
            this.filteredLocations = pluck(this.fuse.search(this.searchValue || ''), 'item');
        }
    };

    /**
     * Returns bindings used in editing a PSM Location.
     */
    private getWafPsmLocationModalBindings(): Record<string, any> {
        const breadcrumb = this.l10nService.getMessage(
            l10nKeys.psmGroupBreadcrumb,
            [this.psmGroup.getName()],
        );

        const breadcrumbs = Array.isArray(this.breadcrumbs) ?
            this.breadcrumbs.concat(breadcrumb) :
            [breadcrumb];

        return {
            breadcrumbs,
            preventEdit: this.preventEdit,
            psmGroupId: this.psmGroup.id,
        };
    }
}

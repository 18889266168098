/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function gatewayMonitorFactory(Item) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Item
     * @author Ram Pal
     * @desc GatewayMonitor item
     */
    class GatewayMonitor extends Item {
        /** @override */
        getIdFromData_(data) {
            return data && data.config && data.config.id || '';
        }

        /**
         * @override
         */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            if (config.gateway_ip && config.gateway_ip.addr) {
                config.id = config.gateway_ip.addr;
            }

            return config;
        }

        /**
         * @override
         */
        saveRequest() {
            if (!this.collection) {
                return;
            }

            const { entireObject: vrfContext } = this.collection;

            vrfContext.gateway_mon = vrfContext.gateway_mon || [];

            this.checkForEdit(vrfContext, this.data.config);

            const config = this.dataToSave();

            vrfContext.gateway_mon.push(config);

            return this.request('PUT', vrfContext.url, vrfContext, null, 'save')
                .then(function() {
                    return config;
                });
        }

        /**
         * If id exists, it means that a gateway_ip is being edited, so we remove
         * the original from the gateway_mon list before pushing new gateway_ip.
         * @param  {Object} vrfContext - VRFContext object.
         * @param  {Object} config - GatewayMonitor config object.
         */
        checkForEdit(vrfContext, config) {
            // If id exists, it means that a gateway_ip is being edited, so we remove it from the
            // original list before pushing.
            if (config && config.id) {
                vrfContext.gateway_mon = _.reject(vrfContext.gateway_mon, function(gateway) {
                    return gateway.gateway_ip.addr === config.id;
                });
            }
        }

        /**
         * @override
         */
        drop() {
            if (!this.collection) {
                return;
            }

            const
                { entireObject: vrfContext } = this.collection,
                { addr } = this.data.config.gateway_ip;

            vrfContext.gateway_mon = _.reject(vrfContext.gateway_mon, function(gateway) {
                return gateway.gateway_ip.addr === addr;
            });

            if (vrfContext.gateway_mon.length === 0) {
                vrfContext.gateway_mon = undefined;
            }

            return this.request('PUT', vrfContext.url, vrfContext);
        }
    }

    Object.assign(GatewayMonitor.prototype, {
        objectName: 'vrfcontext',
        windowElement: 'infra-gateway-monitor-create',
        loadOnEdit: false,
        restrictEditOnEssentialLicense_: false,
    });

    return GatewayMonitor;
}

gatewayMonitorFactory.$inject = [
    'Item',
];

angular.module('avi/app')
    .factory('GatewayMonitor', gatewayMonitorFactory);

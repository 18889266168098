/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
    WafPolicyPsmGroup,
    WafPositiveSecurityModelConfigItem,
} from 'ajs/modules/waf';
import './waf-policy-psm-groups-list.less';

/**
 * @desc Component for displaying a list of WAF PSM groups.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-groups-list',
    templateUrl: './waf-policy-psm-groups-list.component.html',
})
export class WafPolicyPsmGroupsListComponent implements OnInit {
    @Input() public psm: WafPositiveSecurityModelConfigItem;

    @Output() public onEdit = new EventEmitter();
    @Output() public onDelete = new EventEmitter();

    public groups: WafPolicyPsmGroup[];

    /** @override */
    public ngOnInit(): void {
        this.groups = this.psm.config.group_refs_data;
    }

    /**
     * Handler for editing a PSM group.
     */
    public handleEdit(group: WafPolicyPsmGroup, index: number): void {
        this.onEdit.emit({
            group,
            index,
        });
    }

    /**
     * Handler for deleting a PSM group.
     */
    public handleDelete(group: WafPolicyPsmGroup): void {
        this.onDelete.emit(group);
    }

    /**
     * Handler for the drag-and-drop event.
     */
    public handleDrop(dragEvent: CdkDragDrop<void>): void {
        const { previousIndex, currentIndex } = dragEvent;

        this.psm.moveGroup(previousIndex, currentIndex);
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'ssl-tls-profile-version-type-selector';
const prefix = `${moduleName}.${componentName}`;

export const versionTypesLabel = `${prefix}.versionTypesLabel`;

export const ENGLISH = {
    [versionTypesLabel]: 'Version',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './licensing-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Module for licensing.
 * @module avi/licensing
 */

/**
 * Fires upon successful license upload.
 * @event module:avi/licensing#UPDATE_LICENSE_SUMMARY
 * @see module:avi/licensing#UPDATE_LICENSE_SUMMARY
 */

/**
 * @constructor
 * @memberOf module:avi/licensing
 * @mixes module:avi/licensing.licensingPageComponentBindings
 * @see {@link module:avi/licensing.licensingPageComponent licensingPageComponent}
 */
class LicensingPageController {
    /**
     * Event name for license upload success.
     * @type {string}
     */
    static UPDATE_LICENSE_SUMMARY = 'UPDATE_LICENSE_SUMMARY';

    /**
     * UI properties.
     * @type {Object}
     */
    ui = {
        busy: true,
        errors: null,
    };

    constructor(
        $scope,
        $q,
        schemaService,
        dropDownUtils,
        licensingService,
        initialDataService,
        l10nService,
    ) {
        this.$scope = $scope;
        this.$q = $q;
        this.schemaService = schemaService;
        this.dropDownUtils = dropDownUtils;
        this.licensingService_ = licensingService;

        this.l10nKeys = l10nKeys;
        this.l10nService_ = l10nService;
        l10nService.registerSourceBundles(dictionary);

        /**
         * Time difference between controller and client clock in seconds
         * @type {number}
         * @protected
         */
        this.controllerTimeDifference_ = initialDataService.controllerTimeDifference;

        this.setGridConfig_();
        this.loadGridData_();
        this.createLicenseTierDropdownOptions_();
    }

    setGridConfig_() {
        const { l10nService_: l10nService } = this;
        const fields = [{
            name: 'license_name',
            title: l10nService.getMessage(l10nKeys.columnTitleDescription),
        }, {
            name: 'license_id',
            title: l10nService.getMessage(l10nKeys.columnTitleLicenseId),
        }, {
            name: 'tier',
            title: l10nService.getMessage(l10nKeys.columnTitleTier),
            template: '{{ row.tier_type || enum }}',
        }, {
            name: 'serviceCores',
            title: l10nService.getMessage(l10nKeys.columnTitleServiceCores),
            template: '{{ row.service_cores || 0 }}',
        }, {
            name: 'cores',
            title: 'SE vCPUs',
            template: '{{ row.cores || 0 }}',
        }, {
            name: 'sockets',
            title: l10nService.getMessage(l10nKeys.columnTitleSeSockets),
            template: '{{ row.sockets || 0 }}',
        }, {
            name: 'start_on',
            title: l10nService.getMessage(l10nKeys.columnTitleStartDate),
            transform: license => this.startOn_(license).format('ll'),
        }, {
            name: 'valid_until',
            title: l10nService.getMessage(l10nKeys.columnTitleExpiry),
            transform: license => {
                const validUntil = this.validUntil_(license);

                if (validUntil.isBefore()) {
                    return l10nService.getMessage(l10nKeys.expiredLabel);
                }

                return validUntil.format('ll');
            },
        }];

        const multipleActions = [{
            title: l10nService.getMessage(l10nKeys.actionTitleDelete),
            do: rows => {
                this.ui.busy = true;
                this.resetErrors_();

                this.deleteLicenses(rows)
                    .then(() => {
                        this.reload_();
                    })
                    .catch(this.handleLoadError_.bind(this))
                    .finally(this.handleLoadFinally_.bind(this));

                return true;
            },
            disabled: rows => {
                let disabled = this.ui.busy;

                if (!disabled) {
                    disabled = rows.length === 1 && rows[0].license_id === 'Trial';
                }

                return disabled;
            },
        }];

        /**
         * Configures grid directive
         * @type {object}
         */
        this.gridConfig = {
            id: componentSelector,
            fields,
            rowId: 'license_id',
            layout: {
                hideSearch: true,
            },
            searchFields: ['license_name', 'license_id', 'license_type'],
            rowClass: license => {
                return this.validUntil_(license).isBefore() ? 'disabled' : '';
            },
            checkboxDisable: row => row.license_id === 'Trial',
            multipleactions: multipleActions,
        };
    }

    createLicenseTierDropdownOptions_() {
        const enumVals = this.schemaService.getEnumValues('LicenseTierType');

        this.licenseTierDropdownOptions = enumVals.map(({ value, label, description }) => {
            return this.dropDownUtils.createOption(value, label, description);
        });
    }

    /**
     * Updates page's tables.
     * @fires module:avi/licensing#UPDATE_LICENSE_SUMMARY
     * @protected
     */
    reload_ = () => {
        this.resetErrors_();
        this.loadGridData_();
        this.$scope.$broadcast(LicensingPageController.UPDATE_LICENSE_SUMMARY);
    };

    /**
     * Fires after a license is uploaded.
     */
    reloadLicenseGrid() {
        this.reload_();
    }

    /**
     * Updates data for bottom License table.
     * @return {Promise<any>}
     * @protected
     */
    loadGridData_() {
        this.ui.busy = true;

        return this.licensingService_.loadLicenseList()
            .then(licenseList => this.data = licenseList)
            .catch(this.handleLoadError_.bind(this))
            .finally(this.handleLoadFinally_.bind(this));
    }

    /**
     * @param {Object} rsp
     * @protected
     */
    handleLoadError_(rsp) {
        this.ui.errors = rsp.data;
    }

    /**
     * @protected
     */
    handleLoadFinally_() {
        this.ui.busy = false;
    }

    /**
     * @protected
     */
    resetErrors_() {
        this.ui.errors = null;
    }

    /**
     * Returns date of license expiration.
     * @param  {License} license - license item.
     * @return {Moment} Moment object.
     */
    validUntil_(license) {
        return moment(license.valid_until).add(this.controllerTimeDifference_, 'seconds');
    }

    /**
     * Returns start date.
     * @param  {License} license - license item.
     * @return {Moment} Moment object.
     */
    startOn_(license) {
        return moment(license.start_on).add(this.controllerTimeDifference_, 'seconds');
    }

    /**
     * Deletes passed license(s).
     * @param {Object[]} rows
     * @returns {ng.$q.promise} To be resolved with list of Response objects.
     */
    deleteLicenses(rows) {
        const deletePromises =
            rows.map(({ license_id: licenseId }) =>
                this.licensingService_.deleteLicense(licenseId));

        return this.$q.all(deletePromises);
    }
}

LicensingPageController.$inject = [
    '$scope',
    '$q',
    'schemaService',
    'dropDownUtils',
    'licensingService',
    'initialDataService',
    'l10nService',
];

const componentSelector = 'licensing-page';
const templateUrl = 'src/components/pages/administration/settings/' +
    `${componentSelector}/${componentSelector}.component.html`;

/**
 * @mixin licensingPageComponentBindings
 * @memberOf module:avi/licensing
 * @property {SystemConfig} systemConfig
 */
const bindings = {
    systemConfig: '<loadedSystemConfigService',
};

/**
 * @name licensingPageComponent
 * @memberOf module:avi/licensing
 * @property {module:avi/licensing.licensingPageComponentBindings} bindings
 * @property {module:avi/licensing.LicensingPageController} controller
 * @description System>Settings>Licensing page component.
 * @author Akul Aggarwal, Aravindh Nagarajan
 */
angular.module('avi/licensing').component('licensingPage', {
    bindings,
    controller: LicensingPageController,
    templateUrl,
});

export { LicensingPageController };

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './static-route-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const componentName = 'static-route-list';

/**
 * @ngdoc component
 * @name staticRouteList
 * @description Component for the Static Route page.
 */
class StaticRouteListController {
    constructor(StaticRouteCollection, CRUDGridConfig, infraCloudState, getSubnetString,
        l10nService) {
        this._StaticRouteCollection = StaticRouteCollection;
        this._CRUDGridConfig = CRUDGridConfig;
        this._infraCloudState = infraCloudState;
        this._getSubnetString = getSubnetString;
        this.l10nService_ = l10nService;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this.gridConfigs = [];

        /**
         * When the user is in either the VRF Context or Gateway Monitor state, and changes the
         * Cloud to one that doesn't allow viewing those tabs, they get redirected to this
         * Static Route page. At that point, the VRF Context collection loads due to the cloud
         * change, so we only care about it after it's done loading.
         */
        if (!this._infraCloudState.busy) {
            this.vrfContextCollection = this._infraCloudState.getVrfContextCollection();

            this.loadVRFCollection_();
        }

        this.handleVrfCollectionChange = this.handleVrfCollectionChange.bind(this);
        this._infraCloudState.on('vrfContextCollectionChange', this.handleVrfCollectionChange);
    }

    /**
     * Sets the gridConfigs, since there is a Collection Grid for each VRF Context.
     */
    setGridConfigs() {
        const cloudRef = this._infraCloudState.getCloudRef();
        const slug = cloudRef && cloudRef.slug();

        this.vrfContextCollection.items.forEach(vrf => {
            const name = vrf.getName();

            if (name !== 'management') {
                const collection = new this._StaticRouteCollection({
                    params: {
                        'cloud_ref.uuid': slug,
                    },
                    defaults: {
                        cloud_ref: cloudRef,
                    },
                    vrf: name,
                    bind: {
                        collItemSaveSuccess: () => this.loadStaticRouteCollections(),
                    },
                });

                this.gridConfigs.push({
                    vrf: name,
                    gridConfig: this.buildGridConfig(collection),
                });
            }
        });
    }

    /**
     * Builds gridConfig, since multiple are needed when custom VRFs exist.
     * @param  {StaticRouteCollection} collection - Instances of StaticRouteCollection.
     * @return {Object} - gridConfig.
     */
    buildGridConfig(collection) {
        const gridConfig = new this._CRUDGridConfig();

        gridConfig.collection = collection;

        gridConfig.id = `${componentName}-page`;

        const { l10nService_: l10nService } = this;

        gridConfig.fields = [{
            title: l10nService.getMessage(l10nKeys.columnTitlePrefix),
            name: 'prefix',
            transform: row => {
                const { config } = row.data;

                if (!config.prefix) {
                    return '';
                }

                const subnet = this._getSubnetString(config.prefix);

                return subnet === '0.0.0.0/0' ?
                    l10nService.getMessage(l10nKeys.defaultGatewayLabel) : subnet;
            },
            visibility: 'd',
        }, {
            name: 'data.config.next_hop.addr',
            title: l10nService.getMessage(l10nKeys.columnTitleNextHop),
            visibility: 'd',
        }];

        gridConfig.singleactions = [{
            title: l10nService.getMessage(l10nKeys.editBtnTooltip),
            class: 'icon-pencil-4',
            hidden(row) {
                return !row.isEditable();
            },
            do: row => {
                row.edit(null, {
                    Cloud: this._infraCloudState.getCloud(),
                });
            },
        }];

        gridConfig.multipleactions = [{
            title: l10nService.getMessage(l10nKeys.deleteBtnTooltip),
            disabled(rows) {
                return !_.some(rows, row => row.isDroppable());
            },
            do(rows) {
                const routeIdHash = {};

                rows.forEach(row => routeIdHash[row.data.config.route_id] = true);

                const { entireObject } = this.config.collection;

                entireObject.static_routes = _.reject(entireObject.static_routes, route => {
                    return route.route_id in routeIdHash;
                });

                this.config.collection.drop();

                return true;
            },
        }];

        gridConfig.layout = {
            includeCreateButton: true,
            hideSearch: true,
        };

        return gridConfig;
    }

    /**
     * Destroys each collection in the list of grid configs before resetting this.gridConfigs.
     */
    resetGridConfigs() {
        this.gridConfigs.forEach(config => config.gridConfig.collection.destroy());
        this.gridConfigs.length = 0;
    }

    /**
     * Loads the Static Route collection for each gridConfig.
     */
    loadStaticRouteCollections() {
        this.gridConfigs.forEach(config => config.gridConfig.collection.load());
    }

    /**
     * Handler for the 'vrfContextCollectionChange' event. Resets the gridConfigs and loads the
     * collections.
     */
    handleVrfCollectionChange() {
        this.vrfContextCollection = this._infraCloudState.getVrfContextCollection();
        this.resetGridConfigs();
        this.loadVRFCollection_();
        this.loadStaticRouteCollections();
    }

    /**
     * Since collection is carried over all tabs of Routing section we want to make sure it
     * loads what we need it to load for the component. Also kicks off page rendering.
     * @protected
     */
    loadVRFCollection_() {
        this.vrfContextCollection.updateViewportSize(200, true, true);
        this.vrfContextCollection.load()
            .then(() => this.setGridConfigs());
    }

    /**
     * Returns true if the infraCloudState service is busy, meaning that the VRF Context
     * collection is loading.
     * @return {ng.$q.promise}
     */
    isBusy() {
        return this._infraCloudState.busy;
    }

    $onDestroy() {
        const {
            _infraCloudState: infraCloudState,
        } = this;

        this.resetGridConfigs();

        infraCloudState.unbind(
            'vrfContextCollectionChange',
            this.handleVrfCollectionChange,
        );
    }
}

StaticRouteListController.$inject = [
    'StaticRouteCollection',
    'CRUDGridConfig',
    'infraCloudState',
    'getSubnetString',
    'l10nService',
];

angular.module('aviApp').component('staticRouteList', {
    controller: StaticRouteListController,
    templateUrl: 'src/components/pages/infrastructure/static-route-list/' +
        'static-route-list.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc directive
 * @name parseList
 * @restrict A
 * @param {string=} min - Minimum value used for validation.
 * @param {string=} max - Maximum value used for validation.
 * @param {string=} parseListPattern - RegExp expression or Regex property used for validation.
 *     Can be set to a scope variable to be parsed into a RegExp.
 * @description Parses and formats comma-separated string. Differs from ngList directive in that we
 * can set custom validation.
 */
angular.module('aviApp').directive('parseList', ['$parse', 'Regex', function($parse, Regex) {
    function link(scope, elm, attr, ngModel) {
        scope.Regex = Regex;

        ngModel.$parsers.push(parseValues);
        ngModel.$formatters.push(formatValues);
        setValidators(scope, elm, attr, ngModel);
    }

    /**
     * Parses string into an array .
     * @param  {string} val - Comma-separated string values.
     * @return {Object[]} Array of strings.
     */
    function parseValues(val) {
        if (!val) {
            return [];
        }

        const values = val.split(',');

        //ignore last empty value when it is not the only one
        if (values.length > 1 && values[values.length - 1].trim() === '') {
            values.pop();
        }

        return values.map(function(value) {
            return value.trim();
        });
    }

    /**
     * Formats array of values into comma-separated string.
     * @param  {string[]} val - Array of values.
     * @return {string} Comma-separated string.
     */
    function formatValues(val) {
        if (!Array.isArray(val)) {
            return '';
        }

        return val.join(', ');
    }

    /**
     * Sets $validators for ngModel.
     */
    function setValidators(scope, elm, attr, ngModel) {
        setMinMaxValidator(attr, ngModel);
        setPatternValidator(scope, attr, ngModel);
    }

    /**
     * Sets $validator when min or max attribute values are specified. Compares each value in the
     * list with min and max values.
     */
    function setMinMaxValidator(attr, ngModel) {
        if (attr.min || attr.max) {
            const min = attr.min ? +attr.min : -Infinity;
            const max = attr.max ? +attr.max : Infinity;

            ngModel.$validators.minMax = function(values) {
                if (!Array.isArray(values) || !values.length) {
                    return true;
                }

                return _.every(values, function(val) {
                    return +val >= min && +val <= max;
                });
            };
        }
    }

    /**
     * Sets $validator when parseListPattern attribute is specified. Compares each value in the
     * list with min and max values.
     */
    function setPatternValidator(scope, attr, ngModel) {
        if (attr.parseListPattern) {
            const pattern = attr.parseListPattern;
            let regex;

            if (typeof pattern === 'string' && pattern.length > 0) {
                if (pattern.charAt(0) === '/' && pattern.charAt(pattern.length - 1) === '/' &&
                    pattern.length > 2) {
                    // Slice to remove first and last '/' characters.
                    regex = new RegExp(pattern.slice(1).slice(0, -1));
                } else {
                    try {
                        regex = $parse(pattern)(scope);
                    } catch (e) {
                        console.warn('parse-list-pattern is not a valid RegExp');
                    }
                }
            }

            if (_.isUndefined(regex) || !(regex instanceof RegExp)) {
                return;
            }

            ngModel.$validators.pattern = function(values) {
                if (!Array.isArray(values) || !values.length) {
                    return true;
                }

                return _.every(values, function(val) {
                    return regex.test(val);
                });
            };
        }
    }

    return {
        restrict: 'A',
        scope: false,
        require: 'ngModel',
        link,
    };
}]);

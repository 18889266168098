/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../../less/components/sidewaysStackingBarChart.less';

angular.module('aviApp').directive('sidewaysStackingBarChart',
    ['$timeout',
    '$stateParams',
    function(
        $timeout,
        $stateParams,
    ) {
        const render = function(scope, elm) {
            if (!scope.data || !scope.data.length) return;

            scope.chart = {};
            scope.chart.maxSum = scope.data.maxSum;

            if (!scope.barheight) {
                scope.chart.height = $(elm).height() || 20 * scope.data.length;
                scope.chart.barheight = scope.chart.height / scope.data.length - 5;
            } else {
                scope.chart.barheight = scope.barheight;
                scope.chart.height = (scope.chart.barheight + 5) * scope.data.length;
            }

            scope.chart.max = _.max(scope.data, function(d) {
                return d.value;
            }).value || 1;

            scope.chart.sum = _.reduce(scope.data, function(prev, d) {
                return prev + (d.value || 0);
            }, 0);

            // to make border-radiuses pretty
            if (scope.oneline) {
                scope.displayData = _.filter(scope.data, function(p) { return p.value || p.y; });
            }

            scope.data.sum = scope.chart.sum;
        };

        const findTextWidth = function(text, elm) {
            const el = $('<span style = "position: absolute; left: -99999px;' +
                ` white-space: nowrap; opacity: 0;">${text}</span>`);

            elm.append(el);

            const width = el.width() + 12;

            el.remove();

            return width;
        };

        return {
            restrict: 'AE',
            scope: {
                data: '=',
                oneline: '=',
                barheight: '=',
                maxSum: '=',
                titles: '=',
            },
            link(scope, elm) {
                scope.$watch('data.length', function() {
                    render(scope, elm);
                });

                scope.$watch('data', function() {
                    render(scope, elm);
                });

                scope.$on('renderSidewaysStackingBarChart', function() {
                    if (!scope.data) { return; }

                    render(scope, elm);
                });

                scope.$on('resizeSidewaysStackingBarChart', assignTitles);
                scope.$on('assignTitles', assignTitles);

                function assignTitles() {
                    if (scope.titles) {
                        $timeout(function() {
                            _.each(scope.data, function(d) {
                                const el = elm.find(`.${d.metric}`);
                                const w = el.width();
                                const titles = ['', d.title, `${d.title}: ${d.value}ms`];

                                _.each(titles, function(t) {
                                    const tw = findTextWidth(t, elm);

                                    if (tw < w) d.viewableTitle = t;
                                });
                            });
                        });
                    }
                }
            },
            templateUrl: 'src/views/components/sidewaysStackingBarChart.html',
        };
    }]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './UploadPackagesController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name UploadPackagesController
 * @description - Controller for Upload Packages page in Administration.
 *
 */
angular.module('aviApp').controller('UploadPackagesController', ['CollateUpload', 'l10nService',
function(CollateUpload, l10nService) {
    const vm = this;

    vm.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    vm.ui = {
        file: null,
        uploadStarted: false,
    };

    vm.upload = new CollateUpload(
        {
            uri: 'controller://hsmpackages',
            chunkIndexStartsWith: 1,
        },
    );

    /**
     * Calls send method from upload instance to upload a file.
     * @public
     * @return {ng.$q.promise}
     */
    vm.uploadFile = function() {
        vm.ui.uploadStarted = true;

        let uriName = vm.ui.file.name;
        const extensionIndex = uriName.lastIndexOf('.');

        // Remove file extension from filename
        if (extensionIndex > -1) {
            uriName = uriName.slice(0, extensionIndex);
        }

        vm.upload.send(
            vm.ui.file,
            vm.ui.file.name,
            `/api/fileservice/hsmpackages?hsmtype=${uriName}`,
        );
    };
}]);

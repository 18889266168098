/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './ordered-grid-controls.less';
import * as l10n from './ordered-grid-controls.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name orderedGridControls
 * @description
 *     Component managing the controls section of the orderedGrid, including the create button and
 *     search button.
 * @param {Function} onCreate - Called when the Create button is clicked.
 */
class OrderedGridControlsController {
    constructor(l10nService) {
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        /**
         * ngModel property for the Search input.
         */
        this.searchTerm = '';
    }

    /**
     * Handler for ngChange event on the search input. Calls this.onSearch with the searchTerm.
     */
    handleSearch() {
        const searchTerm = this.searchTerm || '';

        this.onSearch({ searchTerm });
    }
}

OrderedGridControlsController.$inject = [
    'l10nService',
];

angular.module('aviApp').component('orderedGridControls', {
    controller: OrderedGridControlsController,
    bindings: {
        create: '<',
        onSearch: '&',
        hideSearch: '<',
    },
    templateUrl: 'src/components/common/ordered-grid/ordered-grid-controls/' +
            'ordered-grid-controls.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { copy } from 'angular';

const caseFactory = Item => {
    /**
     * @class
     * @name module:items/Case
     * @extends module:avi/dataModel.Item
     * @desc Case item
     */
    class Case extends Item {
        /**
         * @override
         */
        getIdFromData_(data) {
            return data.config && data.config['id'] ? data.config['id'] : '';
        }

        /**
         * For existing case get tagRows from
         * customTags string.
         */
        createTagRowsFromCustomTag() {
            const config = this.getConfig();
            const { custom_tag: customTags } = config;

            config.tagRows = [];

            if (customTags) {
                customTags
                    .split(',')
                    .forEach(tag => {
                        const [type, value] = tag.split('=');
                        const tagObject = {
                            type,
                            value,
                        };

                        config.tagRows.push(tagObject);
                    });
            }
        }

        /**
         * Add new tag row.
         */
        addTagRow() {
            const config = this.getConfig();

            const newTag = {
                type: '',
                value: '',
            };

            config.tagRows.push(newTag);
        }

        /**
         * Remove tag option for provided index
         * @param {number} index
         */
        removeTagRow(index) {
            const config = this.getConfig();

            config.tagRows.splice(index, 1);
        }

        /**
         * @override
         */
        beforeEdit() {
            const config = this.getConfig();

            //Initialise the tagRows array used to hold values of newly added tags
            config.tagRows = [];

            if (config.id) {
                const { email } = config.contact_info;

                //We are getting contact_info object while case update.
                //mapping desired value.
                config.email = email;
                this.createTagRowsFromCustomTag();
            }
        }

        /**
         * @override
         */
        dataToSave() {
            const config = copy(this.getConfig());

            //Deleting UI only fields.
            delete config.tagRows;

            return config;
        }

        /**
         * Get all case comments
         * @return {ng.$q.promise}
         */
        loadCaseComments() {
            const url = `/api/${this.objectName}/${this.id}/comment`;

            return Case.handleHttpResponse_(this.request('GET', url));
        }

        /**
         * Get Status of the case. Returns closed or active.
         * @return {string}
         */
        getStatus() {
            return this.getConfig().status === 'Closed' ? 'closed' : 'active';
        }

        /**
         * Returns true if case is closed.
         * @returns {boolean}
         */
        isClosed() {
            return this.getStatus() === 'closed';
        }

        /**
         * Get type of case
         * @returns {string} - Value can be one of them [Bug, Configuration Help,
         *      Feature Request, Issue, Question]
         */
        getType() {
            return this.getConfig().type;
        }

        /**
         * This function handles http response and throws error in case of exception.
         * @param {ng.$q.promise} request - Http request object.
         * @return {ng.$q.promise} Handles response returned from Http request.
         * @protected
         */
        static handleHttpResponse_(request) {
            return request
                .then(response => response.data)
                .catch(error => { throw error.data; });
        }

        /**
         * Returns case number and subject of the case.
         * @returns {string} - Case number with subject. Empty string when not ready.
         * @override
         */
        getName() {
            const config = this.getConfig();
            let name = '';

            if (config) {
                if (config.case_number) {
                    name = `${config.case_number} | ${config.subject}`;
                } else {
                    name = config.subject;
                }
            }

            return name;
        }
    }

    Object.assign(Case.prototype, {
        objectName: 'albservices/case',
        windowElement: 'case-modal',
        permissionName_: 'PERMISSION_CONTROLLER',
    });

    return Case;
};

caseFactory.$inject = [
    'Item',
];

/**
 * @ngdoc service
 * @alias Case
 * @author Ram Pal
 * @desc Case item
 */
angular.module('avi/cportal')
    .factory('Case', caseFactory);

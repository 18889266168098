/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { Collection } from 'ajs/modules/data-model';

export class CloudConnectorUserCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'cloudconnectoruser',
            permissionName: 'PERMISSION_USER_CREDENTIAL',
            windowElement: 'user-credentials-modal',
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('CloudConnectorUser');
    }
}

CloudConnectorUserCollection.ajsDependencies = [
    'CloudConnectorUser',
];

/**
 * @module VsModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    extend,
    IHttpResponse,
    IPromise,
} from 'angular';

import {
    any,
    compact,
    isEmpty,
} from 'underscore';

import {
    IIpAddr,
    IVipRuntimeDetail,
    OperationalState,
} from 'generated-types';

import { Item } from 'ajs/modules/data-model';
import { DnsInfoConfig } from 'ajs/js/services/config-items/DnsInfoConfig';
import { VipConfig } from 'ajs/js/services/config-items/VipConfig';
import { ConfigItem } from 'ajs/js/services/ConfigItem';

interface IVsVipArgs {
    data: IVsVipData;
}

interface IVsVipData {
    config: IVsVipConfig;
}

interface IVsVipConfig {
    name: string;
    vip: VipConfig[];
    cloud_ref: string;
    dns_info: DnsInfoConfig[];
    vrf_context_ref: string;
}

interface INetworkSubnetListResponse {
    count: number;
}

interface INetworkSubnetListErrorResponse {
    data: string;
}

/**
 * @description
 *
 *   VsVip item
 *
 * @author Ram Pal
 */
export class VsVip extends Item<IVsVipConfig> {
    public getDefaultConfig_: () => object | null;
    public getRuntimeData: () => IVipRuntimeDetail | null;

    constructor(args: IVsVipArgs) {
        const extendedArgs = {
            ...args,
            objectName: 'vsvip',
        };

        super(extendedArgs);

        this.data.config = extend(
            this.getDefaultConfig_() || {},
            args && args.data && args.data.config,
        );

        const config = this.getConfig();

        if (Array.isArray(config.vip)) {
            const {
                cloud_ref: cloudRef,
                vip: vipList,
            } = config;

            const VipConfig = this.getAjsDependency_('VipConfig');

            config.vip = vipList.map((vipConfig: VipConfig) => {
                return vipConfig instanceof VipConfig ?
                    vipConfig :
                    new VipConfig({
                        data: {
                            config: vipConfig,
                            cloudRef,
                        },
                    });
            });
        }

        if (Array.isArray(config.dns_info)) {
            const { dns_info: dnsInfo } = config;
            const DnsInfoConfig = this.getAjsDependency_('DnsInfoConfig');

            config.dns_info = dnsInfo.map((dnsConfig: DnsInfoConfig) => {
                return dnsConfig instanceof DnsInfoConfig ?
                    dnsConfig :
                    new DnsInfoConfig({ data: { config: dnsConfig } });
            });
        }
    }

    /**
     * Parses the list of instances and calls dataToSave on each instance, then filters out
     * undefined results. If the resulting list is empty, return undefined as the final
     * output. Used in optional Repeated lists of objects.
     */
    public static filterRepeatedInstances(instances: ConfigItem[]): object[] {
        let dataToSave = compact(instances.map((instance: ConfigItem) => instance.dataToSave()));

        if (!dataToSave.length) {
            dataToSave = undefined;
        }

        return dataToSave;
    }

    /**
     * Returns OperationalState for current Vip object.
     * @param index - Index of the Vip object from the vip list.
     */
    public getVipOperState(index: number): OperationalState {
        const runtimeList = this.getRuntimeData() as IVipRuntimeDetail[];

        return runtimeList[index].oper_status.state;
    }

    /**
     * Returns the Vip object from the vip list based on the index passed in. Defaults to 0.
     * @param index - Index of the Vip object from the vip list.
     */
    public getVip(index: number): VipConfig {
        const vipList = this.getVipList();

        return vipList[index];
    }

    /**
     * Returns a reference to the list of Vip objects current VsVip has.
     */
    public getVipList(): VipConfig[] {
        const { vip: vipList } = this.getConfig();

        return vipList;
    }

    /**
     * Sets the name of the VsVip object.
     * @param name - Name to set.
     */
    public setName(name: string): void {
        const config = this.getConfig();

        config.name = config.name || name;
    }

    /**
     * The cloud_ref for a VsVip object can be set only once.
     * @param cloudRef - Cloud ref url.
     */
    public setCloudRef(cloudRef: string): void {
        const config = this.getConfig();

        config.cloud_ref = cloudRef;

        const vipList = this.getVipList();

        vipList.forEach((vip: VipConfig) => vip.setCloudRefOnData(cloudRef));
    }

    /**
     * The vrf_context_ref for a VsVip object can be set only once.
     * @param vrfContextRef - VRF Context ref url.
     */
    public setVrfContext(vrfContextRef: string): void {
        const config = this.getConfig();

        config.vrf_context_ref = vrfContextRef;
    }

    /**
     * Adds a Vip object to the VirtualService vip list.
     * @param networkRef - Network ref to populate the next Vip with.
     * @param subnetUuid - Subnet uuid to populate the next Vip with.
     */
    public addVip(networkRef?: string, subnetUuid?: string): void {
        const vipList = this.getVipList();

        const id = vipList.reduce((acc: number, vip: VipConfig) => {
            return Math.max(acc, +vip.id || 0);
        }, 0) + 1;

        const VipConfig = this.getAjsDependency_('VipConfig');

        vipList.push(new VipConfig({
            data: {
                config: {
                    vip_id: id,
                    network_ref: networkRef,
                    subnet_uuid: subnetUuid,
                },
            },
        }));
    }

    /**
     * Removes a Vip object from the VirtualService vip list based on the vip_id.
     * @param index - vip_id of the Vip to be removed.
     */
    public removeVip(index: number): void {
        this.getVipList().splice(index, 1);
    }

    /**
     * Returns an array of configured Vip addresses.
     */
    public getVipAddresses(): string[] {
        const vipList = this.getVipList();
        const vips: string[] = [];

        vipList.forEach((vip: VipConfig) => {
            const ipv4 = vip.getVipAddress();
            const ipv6 = vip.getIP6VipAddress();

            if (ipv4) {
                vips.push(ipv4);
            }

            if (ipv6) {
                vips.push(ipv6);
            }
        });

        return compact(vips);
    }

    /**
     * Returns a list of VIP ip address hashes.
     */
    public getVipAddressHashes(): Array<Record<string, string | IIpAddr>> {
        const vipList = this.getVipList();

        return vipList.map((vip: VipConfig) => {
            const hash: Record<string, string | IIpAddr> = vip.getVipAddressHash();

            hash.vip_id = vip.id;

            return hash;
        });
    }

    /**
     * Returns the DnsInfo object from the dns_info list based on the index passed in.
     * Defaults to 0.
     * @param index - Index of the dnsInfo object.
     */
    public getDnsInfo(index = 0): DnsInfoConfig {
        const { dns_info: dnsInfoList } = this.getConfig();

        return dnsInfoList[index];
    }

    /**
     * Adds a default DnsInfo object to the dns_info list.
     */
    public addDnsInfo(): void {
        const { dns_info: dnsInfoList } = this.getConfig();
        const DnsInfoConfig = this.getAjsDependency_('DnsInfoConfig');

        dnsInfoList.push(new DnsInfoConfig());
    }

    /**
     * Removes a DnsInfo object from the dns_info list.
     * @param index - Index of the dnsInfo object to remove.
     */
    public removeDnsInfo(index = 0): void {
        const { dns_info: dnsInfoList } = this.getConfig();

        dnsInfoList.splice(index, 1);
    }

    /**
     * Sets the fqdn field. If no index is passed in, sets config.fqdn. Otherwise, sets
     * config.dns_info[index].fqdn.
     * @param fqdn - fqdn string.
     * @param index - Index of the dnsInfo object in the dns_info list.
     */
    public setFqdn(fqdn: string, index = 0): void {
        const dnsConfig = this.getDnsInfo(index);

        dnsConfig.setFqdn(fqdn);
    }

    /**
     * Returns an array of configured fqdns.
     */
    public getFqdns(): string[] {
        const { dns_info: dnsInfo } = this.getConfig();

        if (dnsInfo) {
            return compact(dnsInfo.map((dnsConfig: DnsInfoConfig) => dnsConfig.getFqdn()));
        }

        return [];
    }

    /**
     * Sets the auto_allocate_floating_ip field on every Vip object. Also needs to verify
     * that the network_refs in every Vip object are fip_capable, by calling
     * this.getFipNetworksCount if auto_allocate_floating_ip is set to true.
     * If not, remove that network and subnet.
     * @param enabled - Boolean value to set auto_allocate_floating_ip to.
     */
    public setAutoAllocateFloatingIpForAllVips(enabled = false): Promise<void> {
        const $q = this.getAjsDependency_('$q');
        const vipList = this.getVipList();
        const promises: Array<IPromise<void>> = [];

        vipList.forEach((vip: VipConfig) => {
            const { networkRef } = vip;

            vip.setAutoAllocateFloatingIp(enabled);
            vip.clearFloatingIp();

            if (enabled && networkRef) {
                const promise = this.getFipNetworksCount(networkRef)
                    .then((count: number): void => {
                        if (!count) {
                            vip.clearVipNetwork();
                            vip.clearVipSubnet();
                            vip.setIpAddr(undefined);
                        }
                    });

                promises.push(promise);
            }
        });

        this.busy = true;

        return $q.all(promises)
            .finally(() => this.busy = false);
    }

    /**
     * Returns true if the VsVip or any child ConfigItem instances are busy. First checks
     * this.busy, then any VipConfig instance, then any DnsInfoConfig.
     */
    public isBusy(): boolean {
        if (this.busy) {
            return true;
        }

        const config = this.getConfig();
        const vipsAreBusy = 'vip' in config && any(config.vip, (vip: VipConfig) => vip.busy);

        if (vipsAreBusy) {
            return true;
        }

        const dnsInfosAreBusy = 'dns_info' in config &&
            any(config.dns_info, (dnsInfo: DnsInfoConfig) => dnsInfo.busy);

        if (dnsInfosAreBusy) {
            return true;
        }

        return false;
    }

    /**
     * @override
     */
    public beforeEdit(): void {
        const config = this.getConfig();

        if (!Array.isArray(config.vip)) {
            config.vip = [];
            this.addVip();
        }

        if (!Array.isArray(config.dns_info)) {
            config.dns_info = [];
            this.addDnsInfo();
        }

        config.vip.forEach((vip: VipConfig) => vip.beforeEdit());
    }

    /**
     * @override
     */
    public dataToSave(): IVsVipConfig {
        const config = this.getConfig();

        if ('vip' in config) {
            /**
             * Set all auto_allocate_floating_ip fields to match the first Vip object's.
             */
            const vipList = this.getVipList();

            if (vipList.length > 1) {
                const vip0: VipConfig = this.getVip(0);
                const { autoAllocateFloatingIp } = vip0;

                vipList.forEach((vip: VipConfig) => vip
                    .setAutoAllocateFloatingIp(autoAllocateFloatingIp));
            }

            // eslint-disable-next-line no-underscore-dangle
            config.vip = Item.filterRepeatedInstances(vipList) as VipConfig[];

            config.vip.forEach((vip: VipConfig) => {
                if (isEmpty(vip.ipamNetworkSubnet)) {
                    delete vip.ipamNetworkSubnet;
                }
            });
        }

        if ('dns_info' in config) {
            // eslint-disable-next-line no-underscore-dangle
            config.dns_info = Item.filterRepeatedInstances(config.dns_info) as DnsInfoConfig[];
        }

        return config;
    }

    /**
     * Gets number of networks that are fip_capable.
     * @param networkRef - network_ref URL.
     */
    private getFipNetworksCount(networkRef: string): IPromise<number> {
        const { cloud_ref: cloudRef } = this.getConfig();
        const stringService = this.getAjsDependency_('stringService');
        const cloudUuid = stringService.slug(cloudRef);
        const networkUuid = stringService.slug(networkRef);

        const api = '/api/networksubnetlist/?auto_allocate_only=true&fip_capable=true&' +
            `cloud_uuid=${cloudUuid}&search=${networkUuid}`;

        return this.request<INetworkSubnetListResponse>('GET', api)
            .then(({ data }: IHttpResponse<INetworkSubnetListResponse>) => data.count)
            .catch(({ data }: INetworkSubnetListErrorResponse) => {
                this.errors = data;

                return Promise.reject(data);
            });
    }
}

VsVip.ajsDependencies = [
    '$q',
    'VipConfig',
    'DnsInfoConfig',
    'stringService',
];

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './vrf-context-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @constructor
 * @memberOf module:avi/vrf
 * @mixes module:avi/vrf.vrfContextModalBindings
 * @see {@link module:avi/vrf.vrfContextModalComponent vrfContextModalComponent}
 */
class VrfContextModalController {
    constructor(Regex, l10nService) {
        /**
         * @type {Regex}
         */
        this.regex = Regex;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }
}

VrfContextModalController.$inject = [
    'Regex',
    'l10nService',
];

/**
 * @name vrfContextModalComponent
 * @memberOf module:avi/vrf
 * @property {module:avi/vrf.vrfContextModalBindings} bindings
 * @property {module:avi/vrf.VrfContextModalController} controller
 * @desc
 *      Modal component for creating/editing an VRF Context.
 * @author Aravindh Nagarajan
 */
angular.module('avi/vrf').component('vrfContextModal', {
    /**
     * @mixin vrfContextModalBindings
     * @memberOf module:avi/vrf
     * @property {VRFContext} editable VRFContext item config
     */
    bindings: {
        editable: '<',
    },
    controller: VrfContextModalController,
    templateUrl: 'src/components/modals/infrastructure/routing/vrf-context-modal.component.html',
});

/**
 * @module NotificationModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './cloud-status.l10n';
import './cloud-status.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Cloud-status notification component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'cloud-status',
    templateUrl: './cloud-status.component.html',
})
export class CloudStatusComponent {
    /**
     * Cloud UUID.
     */
    @Input() public cloudId: string;

    /**
     * Name of the cloud
     */
    @Input() public cloudName: string;

    /**
     * Cloud status.
     */
    @Input() public status: string;

    /**
     * Handler to close the notification.
     */
    @Output() public onClose = new EventEmitter<void>();

    /**
     * To show cloud-status-details modal.
     */
    @Output() public onDisplayStatusDetail = new EventEmitter<string>();

    public clickHereForDetailsMessageList: string[] | undefined;

    public constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);

        this.clickHereForDetailsMessageList =
            l10nService.getSplitedMessage(l10nKeys.clickHereForDetails);
    }

    /**
     * Close handler for closing the notification.
     */
    public handleClose(): void {
        this.onClose.emit();
    }

    /**
     * Opens cloud-status modal.
     */
    public displayCloudStatusDetail(): void {
        this.onDisplayStatusDetail.emit(this.cloudId);
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    each,
    isUndefined,
    reduce,
} from 'underscore';
import { withIndexMixin } from 'ajs/js/utilities/mixins';
import { MessageBase } from './message-base.factory';
import { RepeatedMessageItem } from './repeated-message-item.factory';
import { withEditMixin } from '../mixins';

type TMessageItemConfig = Record<string, any>;

type TErrors = Record<string, string> | string | null;

interface IMessageMapProps {
    objectType: string;
    ConfigItemClass: TMessageItem;
    isRepeated: boolean;
}

type IConfigDataReducerProps = (
    configItems: Record<string, MessageItem | RepeatedMessageItem<MessageItem>>,
    messageMapProps: IMessageMapProps,
    field: string
) => Record<string, MessageItem | RepeatedMessageItem<MessageItem>>;

export interface IMessageItem {
    clone(): MessageBase | RepeatedMessageItem<MessageItem>;
    destroy(): void;
    getDataToSave(): object | object[];
    updateConfig(newConfig: object, skipDataTransformation?: boolean): void;
}

export interface IEditableChildren {
    config: any;
    createChildByField(
        fieldName: string,
        childConfig?: TMessageItemConfig,
        skipRepeated?: boolean,
        skipTransformation?: boolean,
        optionalArgs?: Record<string, any>,
    ): MessageItem | RepeatedMessageItem<MessageItem>;
    save?(): ng.IPromise<ng.IHttpResponse<any>>;
}

/**
 * @description
 *     ConfigItem class. Intended to mirror protobuf objects (ex. Vip, DnsInfo) used in
 *     configuration. The idea is to add some separation in managing data
 *     when a top-level object, like VirtualService,
 *     contains many sub-objects like Vip and DnsInfo, such that methods
 *     dealing with those sub-objects can be defined on their ConfigItem classes
 *     instead of having everything on the top-level object class.
 *
 *     ConfigItems have methods similar to Item methods
 *     in order to keep the data flow consistent.
 * @author alextsg
 */
export class MessageItem extends withEditMixin(withIndexMixin(MessageBase))
    implements IMessageItem, IEditableChildren {
    /**
     * Busy flag. Typically true if the MessageItem is making an HTTP request.
     */
    public busy = false;

    /**
     * Errors. Typically set when an HTTP request returns an error.
     */
    public errors: TErrors = null;

    /**
     * Updates the current config with new config data, and calls lifecycle hooks used to modify
     * data after loading.
     * @param newConfig - New config data.
     * @param skipDataTransformation - True to skip modifying the data on update.
     */
    public updateConfig(newConfig: object, skipDataTransformation = false): void {
        if (skipDataTransformation) {
            this.setConfigData(newConfig, skipDataTransformation);
        } else {
            this.setConfigData(this.dataAfterLoad(newConfig));

            this.modifyConfigDataAfterLoad();
        }
    }

    /**
     * Created as a public wrapper around createChildByField_. Given a field name of a config,
     * create a MessageItem corresponding to that field.
     */
    public createChildByField(
        ...args: [string, TMessageItemConfig?, boolean?, boolean?, object?]
    ): MessageItem | RepeatedMessageItem<MessageItem> {
        return this.createChildByField_(...args);
    }

    /**
     * Calls a method on this, then calls the same method on each child MessageItem.
     * @param methodName - Name of the method to be called on this and children.
     */
    public recursiveConfigItemCall(methodName: string): void {
        this[methodName]();
        this.eachChildConfigItem(childConfigItem => {
            childConfigItem.recursiveConfigItemCall(methodName);
        });
    }

    /**
     * Calls this.dataToSave to get a copy of data.config, then flattens every nested
     * MessageItem into config data and removes empty repeated values.
     * @returns Flat config data object.
     */
    public getDataToSave(): TMessageItemConfig {
        this.recursiveConfigItemCall('modifyConfigDataBeforeSave');

        return this.dataToSave(this.flattenConfig());
    }

    /**
     * Returns the MessageItem name.
     * TODO: Remove this method, since not all MessageItems have a name.
     */
    public getName(config: TMessageItemConfig = this.config): string {
        return config && (config.name || config.url && config.url.name()) || '';
    }

    /**
     * Returns plain object config data from this instance.
     * @param bypassCheck - True to bypass the canFlatten check, used for cloning.
     */
    public flattenConfig(bypassCheck = false): TMessageItemConfig {
        if (!bypassCheck && !this.canFlatten()) {
            return undefined;
        }

        const { config, messageMap } = this;

        const flattenedChildConfigItems = reduce(messageMap, (acc, base, field) => {
            const configItem = config[field];

            if (!isUndefined(configItem)) {
                acc[field] = configItem.flattenConfig(bypassCheck);
            }

            return acc;
        }, {});

        return {
            ...config,
            ...flattenedChildConfigItems,
        };
    }

    /**
     * Unbind all events and cancel all pending requests of this instance and call destructor on
     * every child MessageItem.
     * @override
     */
    public destroy(): void {
        this.eachChildConfigItem(childConfigItem => childConfigItem.destroy());
    }

    /**
     * Sets the busy flag.
     */
    public setBusy(busy = false): void {
        this.busy = busy;
    }

    /**
     * Sets errors.
     */
    public setErrors(errors: TErrors = null): void {
        this.errors = errors;
    }

    /**
     * List of fields that should always be present in the config. If that field's value is
     * undefined, it will be set to the default value in this.modifyConfigDataAfterLoad.
     */
    protected requiredFields(): string[] {
        return [];
    }

    /** @override */
    protected modifyConfigDataAfterLoad(): void {
        this.requiredFields().forEach(field => {
            if (isUndefined(this.config[field])) {
                this.setNewChildByField(field);
            }
        });
    }

    /**
     * If this returns false, this.flattenConfig will return undefined.
     */
    protected canFlatten(): boolean { // eslint-disable-line class-methods-use-this
        return true;
    }

    /**
     * Update the current config with new config data.
     * @param newConfig - New config data.
     */
    protected setConfigData(newConfig = {}, skipDataTransformation = false): void {
        const updatedConfigItems = reduce(
            this.messageMap,
            this.getSetConfigDataReducer(newConfig, skipDataTransformation),
            {},
        );

        this.data.config = {
            ...newConfig,
            ...updatedConfigItems,
        };
    }

    /**
     * Given a field name of a config, create a MessageItem corresponding to that field.
     * @param fieldName - name of the property in the config.
     * @param childConfig - Config data object to be set in the child MessageItem.
     * @param skipRepeated - True if RepeatedMessageItem should not be created, and the
     *     ConfigItemClass should be created directly.
     * @param optionalArgs - Optional arguments to pass to the child constructor.
     */
    // eslint-disable-next-line no-underscore-dangle
    protected createChildByField_(
        fieldName: string,
        childConfig?: TMessageItemConfig,
        skipRepeated = false,
        skipDataTransformation = false,
        optionalArgs = {},
    ): MessageItem | RepeatedMessageItem<MessageItem> {
        const messageMapProps = this.messageMap[fieldName] as IMessageMapProps;
        const InjectedRepeatedMessageItem = this.getAjsDependency_('RepeatedMessageItem');

        if (!messageMapProps) {
            throw new Error(`'${fieldName}' is not a message field.`);
        }

        const { ConfigItemClass, isRepeated, objectType } = messageMapProps;
        const args = {
            objectType,
            fieldName,
            config: childConfig,
            isClone: skipDataTransformation,
            ...optionalArgs,
        };

        if (isRepeated && !skipRepeated) {
            const { config, ...messageItemArgs } = args;

            return new InjectedRepeatedMessageItem({
                messageItemArgs: { ...messageItemArgs },
                MessageItemConstructor: ConfigItemClass,
                config,
            });
        }

        return new ConfigItemClass(args);
    }

    /**
     * Creates a new RepeatedMessageItem or MessageItem instance and sets it as a property on
     * the config.
     * @param fieldName - Property of a MessageItem to set on the config.
     */
    protected setNewChildByField(
        fieldName: string,
        ...args: [TMessageItemConfig?, boolean?, boolean?, object?]
    ): void {
        this.config[fieldName] = this.createChildByField_(fieldName, ...args);
    }

    /**
     * Only creates a new MessageItem instance and sets it as a property on the config if it does
     * not already exist.
     * @param fieldName - Property of a MessageItem to set on the config.
     */
    protected safeSetNewChildByField(
        fieldName: string,
        ...args: [TMessageItemConfig?, boolean?, boolean?, object?]
    ): void {
        if (!(this.data.config[fieldName] instanceof MessageItem)) {
            this.setNewChildByField(fieldName, ...args);
        }
    }

    /** @override */
    protected modifyConfigDataBeforeSave(): void {} // eslint-disable-line class-methods-use-this

    /**
     * Calls a callback on each MessageItem within the config.
     * @param callback - Callback to be called on each MessageItem.
     */
    private eachChildConfigItem(callback: (messageItem: MessageItem) => void): void {
        each(this.messageMap, (messageMapProps, field) => {
            const configItem = this.config[field];

            if (!isUndefined(configItem)) {
                callback(configItem);
            }
        });
    }

    /**
     * Reducer used when updating or creating new MessageItems.
     * @param newConfig - New config object to update the current config with.
     * @returns Reducer function to set MessageItem instances.
     */
    private getSetConfigDataReducer(
        newConfig: object,
        skipDataTransformation = false,
    ): IConfigDataReducerProps {
        return (
            configItems: Record<string, MessageItem | RepeatedMessageItem<MessageItem>>,
            messageMapProps: IMessageMapProps,
            field: string,
        ) => {
            const { isRepeated } = messageMapProps;
            const newValue = newConfig[field];

            // We check isRepeated here since we want to instantiate RepeatedMessageItems
            // even if their configs are not present.
            if (isUndefined(newValue) && !isRepeated) {
                return configItems;
            }

            if (!isUndefined(this.config[field])) {
                const configItem = this.config[field];

                configItem.updateConfig(newValue);
                configItems[field] = configItem;

                return configItems;
            }

            configItems[field] =
                this.createChildByField_(field, newValue, false, skipDataTransformation);

            return configItems;
        };
    }
}

MessageItem.ajsDependencies = [
    'RepeatedMessageItem',
];

type TMessageItem = typeof MessageItem;

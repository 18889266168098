/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'shared';
const componentName = 'async-file-upload';
const prefix = `${moduleName}.${componentName}`;

export const browseBtnLabel = `${prefix}.browseBtnLabel`;
export const uploadBtnLabel = `${prefix}.uploadBtnLabel`;

export const ENGLISH = {
    [browseBtnLabel]: 'Browse',
    [uploadBtnLabel]: 'Upload',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './cell-sparkline.less';
import * as sparkPopupTemplate from './sparkline-popup.partial.html';
import * as cellSparklineTemplate from './cell-sparkline.partial.html';

//TODO use the proper popover service here
function cellSparklineDirectiveFactory(chartValueController, $compile, timeframe) {
    function cellSparklineLink(scope, element) {
        const compiled = $compile(sparkPopupTemplate)(scope);
        const body = $('body');

        element
            .on('mouseover', () => {
                if (scope.isMetricLoadInProgress()) {
                    return;
                }

                body.append(compiled);

                const { top: elementTop, left: elementLeft } = element.offset();
                const elementHeight = element.innerHeight();
                const elementWidth = element.innerWidth();

                let popupLeft;

                if (scope.hasData()) {
                    popupLeft = elementLeft + elementWidth * 0.5 - 75;
                } else {
                    const popupWidth = compiled.innerWidth();

                    popupLeft = elementLeft - popupWidth * 0.48;
                }

                compiled.offset({
                    top: elementTop + elementHeight + 10,
                    left: popupLeft,
                });
            })
            .on('mouseout', () => compiled.remove());

        scope.$watch('series.getUnits()', units => {
            scope.fractionSize = !units || units === 'METRIC_COUNT' ? 0 : 1;
        });

        scope.$watch('isLoading', isMetricLoading => {
            if (!isMetricLoading) {
                scope.noDataCell = !scope.hasData();
            }
        });

        scope.$on('$destroy', () => {
            timeframe.unbind('change', onTimeframeChange_);

            compiled.remove();
        });

        /**
         * Returns true when we have value to display in cell
         * @return {boolean}
         */
        scope.hasData = () => scope.series.hasData();

        /**
         * Returns true when metrics loading is in progress.
         * @return {boolean}
         */
        scope.isMetricLoadInProgress = () => scope.isLoading;

        /**
         * True when the cell has no-data after the load.
         * Used to skip loader for such cells.
         * @type {boolean}
         */
        scope.noDataCell = false;

        /**
         * Returns true for the cells without data after load.
         * @returns {boolean}
         */
        scope.isNoDataSeries = () => !!scope.noDataCell;

        /**
         * Fires on timeframe change.
         * Clears noData state of cells.
         */
        const onTimeframeChange_ = () => {
            scope.noDataCell = false;
        };

        timeframe.on('change', onTimeframeChange_);
    }

    return {
        scope: {
            series: '<',
            mouseUpdatesCharts: '@',
            isLoading: '<',
        },
        link: cellSparklineLink,
        restrict: 'E',
        template: cellSparklineTemplate,
        controller: ['$scope', chartValueController],
    };
}

cellSparklineDirectiveFactory.$inject = [
    'chartValueController',
    '$compile',
    'Timeframe',
];

/**
 * @name cellSparklineDirective
 * @memberOf module:avi/metrics
 * @param {Series} series - Timeseries to be passed to Sparkline directive.
 * @param {boolean} mouseUpdatesCharts
 * @param {boolean} isLoading - True when metrics load in progress
 * @description
 *
 *     Layer between {@link cell | Cell directive} and {@link sparkline | Sparkline directive}.
 *
 * @author Alex Malitsky, Aravindh Nagarajan
 *
 */
angular.module('avi/metrics').directive('cellSparkline', cellSparklineDirectiveFactory);

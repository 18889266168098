/** @module VsModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import angular from 'angular';
import { Collection } from 'ajs/modules/data-model';
import {
    IServerConfig,
    IVsConfig,
    VirtualServiceType,
} from 'generated-types';

// IVirtualService is defined here because VirtualService class can't be exported as of now.
interface IVirtualService {
    getConfig: () => any;
    getDefaultServiceConfig: (config: IServerConfig) => IVsConfig;
    id: string;
}

interface IVSDropParams {
    withChildren: boolean;
}

const allDataSources = {
    inventory: {
        source: 'InventoryCollDataSource',
        transformer: 'VSInventoryDataTransformer',
        transport: 'ListDataTransport',
        fields: [
            'config',
            'health',
            'runtime',
            'alert',
            'faults',
        ],
    },
    'collection-metrics': {
        source: 'CollMetricsCollDataSource',
        transformer: 'CollMetricsDataTransformer',
        transport: 'CollMetricsDataTransport',
        fields: [
            'l4_client.avg_bandwidth',
            'l4_client.max_num_active_se',
            'l4_client.max_open_conns',
            'l4_client.avg_total_rtt',
            'l4_server.avg_total_rtt',
            'l4_client.avg_complete_conns',
            'l4_client.avg_rx_pkts',
            'l4_client.avg_tx_pkts',
            'l4_client.avg_policy_drops',
            'l4_client.avg_dos_attacks',
            'l4_client.avg_errored_connections',
            'l7_client.avg_complete_responses',
            'l7_client.avg_error_responses',
            'l7_client.pct_response_errors',
        ],
        dependsOn: 'config',
    },
};

const listConfigDataSource = {
    source: 'ListCollDataSource',
    transformer: 'ListDataTransformer',
    transport: 'ListDataTransport',
    fields: ['config'],
};

/**
 * @description
 *
 *   Collection of Virtual Service items.
 *
 * @author Ram Pal
 */
export class VirtualServiceCollection extends Collection {
    public serverDefaultsOverride_: Record<string, any>;

    constructor(args: any = {}) {
        // when no inventory needed lets use regular list API
        if (args.objectName === 'virtualservice') {
            args.defaultDataSources = 'list';
            args.dataFields = args.dataFields || ['config'];
            args.allDataSources = angular.copy(allDataSources);
            delete args.allDataSources.inventory;
            args.allDataSources.list = listConfigDataSource;
        }

        super(args);

        const VirtualService = this.getAjsDependency_('VirtualService');

        this.itemClass_ = VirtualService;

        this.serverDefaultsOverride_ = {
            services: [VirtualService.getDefaultServiceConfig({ port: 80 })],
            connections_rate_limit: undefined,
            requests_rate_limit: undefined,
            se_group_ref: undefined,
            saml_sp_config: undefined,
            jwt_config: undefined,
            analytics_policy: {
                client_log_filters: [],
                full_client_logs: {},
                metrics_realtime_update: {},
            },
        };
    }

    /**
     * Virtual Services cannot be dropped by any order. We need to make sure that:
     * 1. Child VSes are dropped first.
     * 2. VSes with auto_allocate_ip = true are dropped last.
     * @param items - List of VSes.
     * @param force - True if deleting VS along with children.
     */
    public dropByPriority(items: IVirtualService[], force: boolean): Promise<void> {
        const childVirtualServices: string[] = [];
        const autoAllocateIpVirtualServices: string[] = [];
        const otherVirtualServices: string[] = [];

        items.forEach((item: IVirtualService) => {
            const config = item.getConfig();

            if (config.type === VirtualServiceType.VS_TYPE_VH_CHILD) {
                childVirtualServices.push(item.id);
            } else if (config.auto_allocate_ip) {
                autoAllocateIpVirtualServices.push(item.id);
            } else {
                otherVirtualServices.push(item.id);
            }
        });

        let dropParams: IVSDropParams;

        if (force) {
            dropParams = {
                withChildren: true,
            };
        }

        const promise = childVirtualServices.length ?
            this.dropItems(childVirtualServices, false, false, dropParams) : Promise.resolve(true);

        return promise
            .then(() => {
                return otherVirtualServices.length ?
                    this.dropItems(otherVirtualServices, false, false, dropParams) :
                    Promise.resolve(true);
            }).then(() => {
                return autoAllocateIpVirtualServices.length ?
                    this.dropItems(autoAllocateIpVirtualServices, false, false, dropParams) :
                    Promise.resolve(true);
            });
    }
}

Object.assign(VirtualServiceCollection.prototype, {
    objectName_: 'virtualservice-inventory',
    windowElement_: 'app-vs-create',
    defaultDataSources_: 'inventory',
    isStatic_: false,
    allDataSources_: allDataSources,
    defaultDataFields_: ['config', 'runtime', 'health'],
    sortBy_: 'name',
});

VirtualServiceCollection.ajsDependencies = [
    'VirtualService',
];

/**
 * Data model layer of avi ui: item, collection, data-* stuff.
 * @module avi/dataModel
 * @preferred
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import { Webhook } from './Webhook';
import { AutoScaleLaunchConfig } from './AutoScaleLaunchConfig';
import { Cloud } from './Cloud';

const items = [
    {
        itemClass: Webhook,
        name: 'Webhook',
    },
    {
        itemClass: AutoScaleLaunchConfig,
        name: 'AutoScaleLaunchConfig',
    },
    {
        itemClass: Cloud,
        name: 'Cloud',
    },
];

items.forEach(({ name, itemClass }) => {
    initAjsDependency(angular.module('avi/dataModel'), 'factory', name, itemClass);
});

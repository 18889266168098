const moduleName = 'header';
const componentName = 'main-menu-list';
const prefix = `${moduleName}.${componentName}`;

export const administration = `${prefix}.administration`;
export const applications = `${prefix}.applications`;
export const infrastructure = `${prefix}.infrastructure`;
export const operations = `${prefix}.operations`;
export const templates = `${prefix}.templates`;

export const ENGLISH = {
    [applications]: 'Applications',
    [administration]: 'Administration',
    [infrastructure]: 'Infrastructure',
    [operations]: 'Operations',
    [templates]: 'Templates',
};

/**
 * @module CoreModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

/* eslint-disable no-console */

import { Injectable } from '@angular/core';

/**
 * @desc Service used to log info, errors and warnings to console in development mode.
 * @author Aravindh Nagarajan, Satish Pednekar
 */

@Injectable()
export class DevLoggerService {
    // Flag to indicate if dev mode is enabled and it is checked before logging data to console.
    private readonly isDevModeEnabled: boolean = false;

    constructor() {
        this.isDevModeEnabled = process.env.NODE_ENV !== 'production';
    }

    /**
     * Logs passed args in dev-tool console.
     */
    public log(...args: any): void {
        if (this.isDevModeEnabled) {
            console.log(...args);
        }
    }

    /**
     * Logs passed args as info in dev-tool console.
     */
    public info(...args: any): void {
        if (this.isDevModeEnabled) {
            console.info(...args);
        }
    }

    /**
     * Logs passed args as warning in dev-tool console.
     */
    public warn(...args: any): void {
        if (this.isDevModeEnabled) {
            console.warn(...args);
        }
    }

    /**
     * Logs passed args as error in dev-tool console.
     */
    public error(...args: any): void {
        if (this.isDevModeEnabled) {
            console.error(...args);
        }
    }
}

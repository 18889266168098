const moduleName = 'ajs-legacy';
const componentName = 'se-analytics-controller';
const prefix = `${moduleName}.${componentName}`;

export const serviceEngineMetricsHeader = `${prefix}.serviceEngineMetricsHeader`;
export const disableInProgressState = `${prefix}.disableInProgressState`;
export const errorDisabledState = `${prefix}.errorDisabledState`;
export const disabledForPlacementState = `${prefix}.disabledForPlacementState`;

export const ENGLISH = {
    [serviceEngineMetricsHeader]: 'Service Engine Metrics',
    [disableInProgressState]: 'Disabling',
    [errorDisabledState]: 'Failed to Disable',
    [disabledForPlacementState]: 'Disabled for Placement',
};

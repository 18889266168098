/**
 * Module for DataScripts, ControlScripts and ProtocalParserScripts.
 * @module avi/scripts
 * @preferred
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import { Item } from 'ajs/modules/data-model';
import { IProtocolParser } from 'generated-types';

class ProtocolParser extends Item<IProtocolParser> {}

Object.assign(ProtocolParser.prototype, {
    objectName: 'protocolparser',
    windowElement: 'protocol-parser-modal',
});

initAjsDependency(
    angular.module('avi/scripts'),
    'factory',
    'ProtocolParser',
    ProtocolParser,
);

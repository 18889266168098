/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name snmpV3User
 * @param {Object} config - Config object where SnmpV3UserParams user is configured.
 * @description
 *      Configures SnmpV3UserParams object inside "config" object "user" property.
 */
angular.module('aviApp').component('snmpV3User', {
    bindings: {
        config: '<',
    },
    templateUrl: 'src/components/administration/snmp-v3-user/snmp-v3-user.html',
});

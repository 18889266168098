/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import TextDecoderLite from 'text-encoder-lite';

/**
 * @ngdoc service
 * @name TextDecoderLite
 * @link https://github.com/coolaj86/TextEncoderLite
 */
angular.module('avi/deps').factory('TextDecoderLite', () => TextDecoderLite);

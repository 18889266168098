/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').factory('NetworkSecurityPolicyDosDataTransformer', [
'RevisedDataTransformer',
RevisedDataTransformer => {
    const NetworkSecurityPolicyDosDataTransformer = class extends RevisedDataTransformer {
        processResponse(rsp) {
            let results = [];

            if (rsp.data && 'rules' in rsp.data && !_.isEmpty(rsp.data.rules)) {
                this.owner_.blockedAddressesHash = rsp.data.rules.reduce((acc, result) => {
                    result.match.client_ip.addrs.forEach(addr => acc[addr.addr] = true);

                    return acc;
                }, {});

                results = rsp.data.rules;
            }

            rsp.data.count = results.length;
            rsp.data.results = results;

            return rsp;
        }
    };

    return NetworkSecurityPolicyDosDataTransformer;
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc constant
 * @type function
 * @name fqdnSplit
 * @author Alex Malitsky
 * @description
 *     Splits FQDN string into subdomain and domain by search for longest domain match in FQDN.
 *     Domain starts with a dot. Subdomain can't have a trailing dot.
 */
angular.module('aviApp').constant('fqdnSplit',
    function(fullDomainName = '', domains = []) {
        let subdomain = '',
            appDomainName = '';

        if (fullDomainName) {
            subdomain = fullDomainName;

            if (domains.length) {
                const fullDomainNameLength = fullDomainName.length;
                let match,
                    matchPos = +Infinity;

                //we are looking for the longest match (in case domains are .com and .smth.com)
                domains.forEach(domainName => {
                    const pos = fullDomainName.lastIndexOf(domainName);

                    if (pos !== -1 && pos === fullDomainNameLength - domainName.length &&
                    pos < matchPos) {
                        matchPos = pos;
                        match = domainName;
                    }
                });

                if (match && matchPos >= 0) {
                    subdomain = fullDomainName.slice(0, matchPos);
                    appDomainName = match;
                }
            }
        }

        return [subdomain, appDomainName];
    });

/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    Component,
    Input,
} from '@angular/core';
import './waf-policy-confirm-change-crs-version-counter.component.less';

/**
 * Component displaying the number of CRS rules affected.
 */
@Component({
    selector: 'waf-policy-confirm-change-crs-version-counter',
    templateUrl: './waf-policy-confirm-change-crs-version-counter.component.html',
})
export class WafPolicyConfirmChangeCrsVersionCounterComponent {
    /**
     * Name of the type of rule.
     */
    @Input()
    public name: string;

    /**
     * Number of rules affected.
     */
    @Input()
    public number: number;
}

const moduleName = 'webhook';
const componentName = 'webhook-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const generalHeader = `${prefix}.generalHeader`;
export const nameFieldLabel = `${prefix}.nameFieldLabel`;
export const callbackUrlFieldLabel = `${prefix}.callbackUrlFieldLabel`;
export const verificationTokenFieldLabel = `${prefix}.verificationTokenFieldLabel`;
export const descriptionFieldLabel = `${prefix}.descriptionFieldLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Webhook: {0}',
    [modalTitleNew]: 'New Webhook: {0}',
    [generalHeader]: 'General',
    [nameFieldLabel]: 'Name',
    [callbackUrlFieldLabel]: 'Callback URL',
    [verificationTokenFieldLabel]: 'Verification Token',
    [descriptionFieldLabel]: 'Description',
};

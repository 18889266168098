/**
 * @module Angular-Core
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

/**
 * @fileoverview Angular services need to be specified here to be downgraded and used in
 *     AngularJS.
 */
import angular from 'angular';

import { downgradeInjectable } from '@angular/upgrade/static';
import {
    I18nContext,
    L10nService,
    LocaleService,
    VIPService,
} from '@vmw/ngx-vip';

import {
    DevLoggerService,
    DialogService,
    DIALOG_SERVICE_TOKEN,
    FullModalService,
    FULL_MODAL_SERVICE_TOKEN,
    NotificationService,
    StatePermissionTreeService,
    TenantService,
} from './angular/modules/core';

import { WelcomeService } from './angular/modules/welcome';

const downgradeServices = [
    {
        ajsName: 'StatePermissionTreeService',
        service: StatePermissionTreeService,
    },
    {
        ajsName: 'TenantService',
        service: TenantService,
    },
    {
        ajsName: 'notificationService',
        service: NotificationService,
    },
    {
        ajsName: FULL_MODAL_SERVICE_TOKEN,
        service: FullModalService,
    },
    {
        ajsName: 'l10nService',
        service: L10nService,
    },
    {
        ajsName: 'welcomeService',
        service: WelcomeService,
    },
    {
        ajsName: 'localeService',
        service: LocaleService,
    },
    {
        ajsName: 'vipService',
        service: VIPService,
    },
    {
        ajsName: 'i18nContext',
        service: I18nContext,
    },
    {
        ajsName: 'devLoggerService',
        service: DevLoggerService,
    },
    {
        ajsName: DIALOG_SERVICE_TOKEN,
        service: DialogService,
    },
];

downgradeServices.forEach((serviceData: any): void => {
    const { ajsName, service } = serviceData;

    if (!ajsName) {
        throw new Error(`Angular JS name needed for ${service}`);
    }

    angular.module('aviApp').service(ajsName, downgradeInjectable(service) as any);
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { pick } from 'underscore';

/**
 * Holds values needed for single cell of license summary table.
 * @typedef {Object} module:avi/licensing.LicenseSummaryCellData
 * @property {number} used - total licenses currently in use
 * @property {number} total - total licenses available
 */

/**
 * Holds values needed for single row of license summary table.
 * @memberOf module:avi/licensing
 * @name LicenseSummaryRowData
 * @typedef {Object.<
 *     module:avi/licensing.LicenseTierType,
 *     module:avi/licensing.LicenseSummaryCellData
 * >}
 */

/**
 * Lookup key used in {@link module:avi/licensing.LicenseHash}.
 * Constructed using {@link module:avi/licensing.LicenseTierType}
 * and {@link module:avi/licensing.LicenseType},
 * resulting in something like 'ENTERPRISE-service_cores'.
 * @typedef {string} module:avi/licensing.LicenseHashKey
 */

/**
 * Holds final formatted data for one row of License Summary table.
 * @typedef {Object} module:avi/licensing.LicenseUsageByType
 * @property {string} description - from {@link module:avi/licensing.LicenseHashVal}
 * @property {module:avi/licensing.LicenseType} key_
 * @property {string} label - from {@link module:avi/licensing.LicenseHashVal}
 * @property {module:avi/licensing.LicenseSummaryRowData} tiers
 */

/**
 * @constructor
 * @memberOf module:avi/licensing
 * @author Akul Aggarwal
 * @description Service to construct/lookup {@link module:avi/licensing.LicenseHashKey}
 * and Array.<{@link module:avi/licensing.LicenseUsageByType}> ultimately to be used in
 * License Summary table.
 */
class LicenseSummaryService {
    /**
     * Used to make or lookup prop key used in {@link module:avi/licensing.LicenseHash}.
     * @param {module:avi/licensing.LicenseTierType} licenseTierType
     * @param {module:avi/licensing.LicenseType} licenseType
     * @returns {module:avi/licensing.LicenseHashKey}
     */
    static getLicenseHashKey(licenseTierType, licenseType) {
        return `${licenseTierType}-${licenseType}`;
    }

    /**
     * Constructs list of licenses organized by {@link module:avi/licensing.LicenseType},
     * with {@link module:avi/licensing.LicenseTierType} inside. Holds final formatted data
     * for license summary table.
     * @param {module:avi/licensing.LicenseHash} licenseHash
     * @param {module:avi/licensing.LicenseType[]} licenseTypes
     * @param {module:avi/licensing.LicenseTierType[]} licenseTierTypes
     * @returns {module:avi/licensing.LicenseUsageByType[]}
     */
    static getLicenseListByType(
        licenseHash,
        licenseTypes,
        licenseTierTypes,
    ) {
        return licenseTypes.map(licenseType => {
            const licenseHashKey =
                LicenseSummaryService.getLicenseHashKey(licenseTierTypes[0], licenseType);
            const tiers =
                LicenseSummaryService.populateTiers_(licenseHash, licenseType, licenseTierTypes);

            return {
                description: licenseHash[licenseHashKey].description,
                key_: licenseType,
                label: licenseHash[licenseHashKey].label,
                tiers,
            };
        });
    }

    /**
     * Returns data for one row in license summary table, by tier.
     * @param {module:avi/licensing.LicenseHash} licenseHash
     * @param {module:avi/licensing.LicenseType} licenseType
     * @param {module:avi/licensing.LicenseTierType[]} licenseTierTypes
     * @return {module:avi/licensing.LicenseSummaryRowData}
     * @protected
     */
    static populateTiers_(
        licenseHash,
        licenseType,
        licenseTierTypes,
    ) {
        return licenseTierTypes.reduce((accumulator, licenseTierType) => {
            const licenseHashKey =
                LicenseSummaryService.getLicenseHashKey(licenseTierType, licenseType);

            const licenseHashVal = licenseHash[licenseHashKey];

            accumulator[licenseTierType] = pick(licenseHashVal, ['total', 'used']);

            return accumulator;
        }, {});
    }
}

angular.module('avi/licensing').service('licenseSummaryService', LicenseSummaryService);

export { LicenseSummaryService };

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name CloudLicenseTypes
 * @returns {Object} - Hash where CloudType enum is a key and value is a list of LicenseType enums.
 */
angular.module('aviApp').factory('CloudLicenseTypes', [
'schemaService', function(schemaService) {
    const licenses = {};

    schemaService.getEnumValues('CloudType').forEach(cloudTypeEnumValue => {
        if ('cloud_type_options' in cloudTypeEnumValue) {
            const { cloud_type_options: cloudTypeOptions } = cloudTypeEnumValue;
            const { allowed_licenses: allowedLicenses } = cloudTypeOptions;

            if (allowedLicenses) {
                const { value } = cloudTypeEnumValue;

                licenses[value] = allowedLicenses.concat();
            }
        }
    });

    return licenses;
}]);

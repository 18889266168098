const moduleName = 'ajs-legacy';
const componentName = 'datascriptset-modal';
const prefix = `${moduleName}.${componentName}`;

export const editDataScriptSetModalHeader = `${prefix}.editDataScriptSetModalHeader`;
export const newDataScriptSetModalHeader = `${prefix}.newDataScriptSetModalHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const l4EventsHeader = `${prefix}.l4EventsHeader`;
export const fileUploadInputPlaceholder = `${prefix}.fileUploadInputPlaceholder`;
export const dnsEventsHeader = `${prefix}.dnsEventsHeader`;
export const sslEventsHeader = `${prefix}.sslEventsHeader`;
export const httpEventsHeader = `${prefix}.httpEventsHeader`;
export const poolsInputLabel = `${prefix}.poolsInputLabel`;
export const poolsInputPlaceholder = `${prefix}.poolsInputPlaceholder`;
export const poolGroupsInputLabel = `${prefix}.poolGroupsInputLabel`;
export const poolGroupsInputPlaceholder = `${prefix}.poolGroupsInputPlaceholder`;
export const ipGroupsInputLabel = `${prefix}.ipGroupsInputLabel`;
export const ipGroupsInputPlaceholder = `${prefix}.ipGroupsInputPlaceholder`;
export const stringGroupsInputLabel = `${prefix}.stringGroupsInputLabel`;
export const stringGroupsInputPlaceholder = `${prefix}.stringGroupsInputPlaceholder`;
export const protocolParserInputLabel = `${prefix}.protocolParserInputLabel`;
export const protocolParserInputPlaceholder = `${prefix}.protocolParserInputPlaceholder`;
export const createProtocolParserBtnLabel = `${prefix}.createProtocolParserBtnLabel`;
export const ipReputationDbInputLabel = `${prefix}.ipReputationDbInputLabel`;
export const ipReputationDbInputPlaceholder = `${prefix}.ipReputationDbInputPlaceholder`;
export const rateLimitersHeader = `${prefix}.rateLimitersHeader`;
export const fileUploadInputLabel = `${prefix}.fileUploadInputLabel`;

export const ENGLISH = {
    [editDataScriptSetModalHeader]: 'Edit DataScript Set: {0}',
    [newDataScriptSetModalHeader]: 'New DataScript Set: {0}',
    [nameInputLabel]: 'Name',
    [l4EventsHeader]: 'L4 Events',
    [fileUploadInputPlaceholder]: 'Enter your DataScript Here',
    [dnsEventsHeader]: 'DNS Events',
    [sslEventsHeader]: 'SSL Events',
    [httpEventsHeader]: 'HTTP Events',
    [poolsInputLabel]: 'Pools',
    [poolsInputPlaceholder]: 'Select Pools',
    [poolGroupsInputLabel]: 'Pool Groups',
    [poolGroupsInputPlaceholder]: 'Select Pool Groups',
    [ipGroupsInputLabel]: 'IP Groups',
    [ipGroupsInputPlaceholder]: 'Select IP Groups',
    [stringGroupsInputLabel]: 'String Groups',
    [stringGroupsInputPlaceholder]: 'Select String Groups',
    [protocolParserInputLabel]: 'Protocol Parser',
    [protocolParserInputPlaceholder]: 'Select Protocol Parser',
    [createProtocolParserBtnLabel]: 'Create Protocol Parser',
    [ipReputationDbInputLabel]: 'IP Reputation DB',
    [ipReputationDbInputPlaceholder]: 'Select IP Reputation DB',
    [rateLimitersHeader]: 'Rate Limiters',
    [fileUploadInputLabel]: '{0} Event Script',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './HealthMonitorController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('HealthMonitorController', [
'$scope',
'CRUDGridConfig',
'HealthMonitorCollection',
'l10nService',
function(
    $scope,
    CRUDGridConfig,
    HealthMonitorCollection,
    l10nService,
) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    /**
    * Get keys from source bundles for template usage.
    */
    $scope.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    $scope.gridConfig = new CRUDGridConfig();

    $scope.collection = new HealthMonitorCollection();

    $scope.gridConfig.collection = $scope.collection;

    $scope.gridConfig.props = { l10nKeys };

    $scope.gridConfig.fields = [
        {
            name: 'data.config.name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            sortBy: 'name',
            visibility: 'm',
        }, {
            name: 'type',
            title: l10nService.getMessage(l10nKeys.columnTitleType),
            template: '<span class="sel-type">' +
            '{{ row.data.config.type | enum:"HEALTH_MONITOR_"}}</span>',
            visibility: 'm',
        }, {
            name: 'send',
            title: l10nService.getMessage(l10nKeys.columnTitleSendInterval),
            template: '<span class="sel-send-interval">' +
            '{{ config.props.l10nKeys.secInnerText | vtranslate' +
            ': false : row.data.config.send_interval}}</span>',
            visibility: 'd',
        }, {
            name: 'recv',
            title: l10nService.getMessage(l10nKeys.columnTitleReceiveTimeout),
            template: '<span class="sel-receive-timeout">' +
            '{{ config.props.l10nKeys.secInnerText | vtranslate' +
            ': false : row.data.config.receive_timeout}}</span>',
            visibility: 'd',
        }, {
            name: 'data.config.successful_checks',
            title: l10nService.getMessage(l10nKeys.columnTitleSuccessfulChecks),
            visibility: 'd',
        }, {
            name: 'data.config.failed_checks',
            title: l10nService.getMessage(l10nKeys.columnTitleFailedChecks),
            visibility: 'd',
        }, {
            name: 'request',
            title: l10nService.getMessage(l10nKeys.columnTitleRequest),
            template: '{{ config.templateFunctions.getString(row.data.config, \'request\') }}',
            visibility: 'optional',
        }, {
            name: 'response_string',
            title: l10nService.getMessage(l10nKeys.columnTitleResponseString),
            template: '{{ config.templateFunctions.getString(row.data.config, \'response\') }}',
            visibility: 'optional',
        }, {
            name: 'response_code',
            title: l10nService.getMessage(l10nKeys.columnTitleResponseCode),
            template: '{{ config.templateFunctions.getString(row.data.config, \'code\') }}',
            visibility: 'optional',
        },
    ];

    $scope.gridConfig.singleactions = [{
        title: l10nService.getMessage(l10nKeys.actionBtnEdit),
        class: 'icon-pencil-4',
        hidden: row => !row.isEditable() && !row.isClonable(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }];

    $scope.gridConfig.templateFunctions = {
        getString,
    };

    /**
     * Used to return a string based on the type of Health Monitor and the desired property.
     * @param  {Object} config - Health monitor config object.
     * @param  {string} target - Desired property. 'request', 'response', or 'code'.
     * @return {string} String to be displayed in grid.
     */
    function getString(config, target) {
        const hash = {
            HEALTH_MONITOR_HTTP: {
                obj: 'http_monitor',
                request: 'http_request',
                response: 'http_response',
                code: 'http_response_code',
            },
            HEALTH_MONITOR_TCP: {
                obj: 'tcp_monitor',
                request: 'tcp_request',
                response: 'tcp_response',
            },
            HEALTH_MONITOR_UDP: {
                obj: 'udp_monitor',
                request: 'udp_request',
                response: 'udp_response',
            },
            HEALTH_MONITOR_DNS: {
                obj: 'dns_monitor',
                request: 'query_name',
                response: 'response_string',
                code: 'rcode',
            },
        };

        let output = 'N/A';

        if (hash[config.type] && hash[config.type][target]) {
            const { obj } = hash[config.type];
            const prop = hash[config.type][target];

            output = config[obj] && config[obj][prop] || 'N/A';
        }

        if (target === 'code') {
            if (config.type === 'HEALTH_MONITOR_DNS') {
                output = output.enumeration('RCODE_');
            }

            if (config.type === 'HEALTH_MONITOR_HTTP') {
                if (Array.isArray(output)) {
                    output = output.map(function(code) {
                        return code.enumeration('HTTP_');
                    });

                    output = output.join(', ');
                }
            }
        }

        return output;
    }

    /* to show code text view onOpen */
    $scope.ui = {
        externalCodeAsText: 'true',
    };
}]);

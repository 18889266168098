/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

class CloudSetterController {
    constructor(Collection, Cloud) {
        this.cloudCollection = new Collection({
            objectName: 'cloud',
            itemClass: Cloud,
            isStatic: true,
            params: {
                fields: 'vtype,tenant_ref',
            },
        });

        this.cloudCollection.one('collectionLoadSuccess', () => {
            if (this.cloudCollection.getTotalNumberOfItems() === 1) {
                const [singleItem] = this.cloudCollection.items;

                this.onCloudSelect(singleItem);

                this.submit();
            }
        });
    }

    $onInit() {
        this.cloudRef = this.defaultCloudRef || '';

        this.gridConfig = {
            collection: this.cloudCollection,
            id: 'cloud-setter',
            layout: {
                hideDisplaying: true,
                hideEditColumns: true,
                includeCreateButton: false,
            },
            fields: [{
                name: 'cloud_ref',
                title: 'Clouds',
                template:
                        `<div ng-click="config.options.onCloudSelect(row)" class="clickable">
                            <a href="">{{ ::row.getName() }}</a>
                        </div>`,
            }],
            rowClass: row => (row.getRef() === this.cloudRef ? 'selected' : null),
            options: {
                onCloudSelect: this.onCloudSelect.bind(this),
            },
        };
    }

    /**
     * Sets inner params on cloud selection event.
     * @param {Cloud} cloud
     * @public
     */
    onCloudSelect(cloud) {
        this.cloudRef = cloud.getRef();
        this.cloudConfig = angular.copy(cloud.getConfig());
    }

    /**
     * Calls setCloud to pass selected cloud info up the chain.
     * @public
     */
    submit() {
        const {
            cloudRef,
            cloudConfig,
        } = this;

        this.setCloud({
            cloudRef,
            cloudConfig,
        });
    }

    $onDestroy() {
        this.cloudCollection.destroy();
    }
}

CloudSetterController.$inject = [
        'Collection',
        'Cloud',
];

/**
 * @ngdoc component
 * @name  cloudSetter
 * @param {Function} setCloud - Called with the selected cloudRef passed in. Used to set
 *     editable.getConfig().cloud_ref on the parent.
 * @param {string?} defaultCloudRef - It comes from defaultValues. Its read only and
 *     used only in $onInit to highlight the selected cloud.
 * @description
 *     Used to set the cloud_ref when creating an application item when multiple clouds exist.
 *     User must first select the cloud to proceed.
 * @author alextsg, Alex Malitsky, Aravindh Nagarajan
 */
angular.module('aviApp').component('cloudSetter', {
    bindings: {
        setCloud: '&',
        defaultCloudRef: '<?',
    },
    controller: CloudSetterController,
    templateUrl: 'src/components/common/cloud-setter/cloud-setter.html',
});

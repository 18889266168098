const moduleName = 'ajs-legacy';
const componentName = 'ssl-certificate-create-root';
const prefix = `${moduleName}.${componentName}`;

export const addCertificateSslTlsModalHeader = `${prefix}.addCertificateSslTlsModalHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const certificateInputLabel = `${prefix}.certificateInputLabel`;
export const pasteTextRadioOption = `${prefix}.pasteTextRadioOption`;
export const uploadfileRadioOption = `${prefix}.uploadfileRadioOption`;
export const pasteCertificateInputPlaceholder = `${prefix}.pasteCertificateInputPlaceholder`;
export const validateButtonLabel = `${prefix}.validateButtonLabel`;
export const importButtonLabel = `${prefix}.importButtonLabel`;

export const ENGLISH = {
    [addCertificateSslTlsModalHeader]: 'Add Certificate (SSL/TLS): {0}',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'SSL/TLS Certificate Name',
    [certificateInputLabel]: 'Certificate',
    [pasteTextRadioOption]: 'Paste text',
    [uploadfileRadioOption]: 'Upload File',
    [pasteCertificateInputPlaceholder]: 'Paste your certificate here',
    [validateButtonLabel]: 'Validate',
    [importButtonLabel]: 'Import',
};

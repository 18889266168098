/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc Controller
 * @name  VerifyLDAPController
 * @description
 * Controller for modal verifying Auth Profile LDAP credentials.
 */
angular.module('aviApp').controller('VerifyLDAPController', [
'$scope', 'Base', function($scope, Base) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    const base = new Base();

    const vm = this;

    $scope.init = function() {
        vm.config = {
            profile: $scope.ldap.name,
        };
        vm.mode = $scope.ldap.ldap.bind_as_administrator ? 'admin' : 'anon';
        vm.busy = false;
        vm.errors = '';
        vm.results = null;
        vm.search = 'user';
    };

    /**
     * Set config payload properties based on search-related radio buttons.
     */
    function setSearchParams() {
        switch (vm.search) {
            case 'user':
                vm.config.usersearch = true;
                vm.config.groupsearch = false;
                break;
            case 'group':
                vm.config.usersearch = false;
                vm.config.groupsearch = true;
                break;
            case 'none':
                vm.config.usersearch = false;
                vm.config.groupsearch = false;
                break;
        }
    }

    /**
     * Make API call to verify LDAP credentials.
     */
    vm.verifyLDAP = function() {
        const api = vm.mode === 'admin' ? '/api/ldap-test-admin' : '/api/ldap-test-user';

        setSearchParams();

        vm.busy = true;
        vm.errors = '';
        vm.results = null;

        vm.config.uuid = $scope.ldap.uuid;

        base.request('POST', api, vm.config, null, 'ldap').then(function(rsp) {
            vm.results = rsp.data.output;
        }).catch(function(rsp) {
            vm.errors = rsp.data;

            if (rsp.data.output) {
                vm.results = rsp.data.output;
            }
        }).finally(function() {
            vm.busy = false;
        });
    };

    /**
     * Watchers
     */
    $scope.$on('$destroy', function() {
        base.cancelRequests('ldap');
    });
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './waf-policy-child-mode-setter.l10n';

const componentName = 'waf-policy-child-mode-setter';
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/** @alias wafPolicyChildModeSetter **/
class WafPolicyChildModeSetterController {
    constructor(
        $element,
        schemaService,
        WafRuleGroupConfigItem,
        l10nService,
    ) {
        this.$element = $element;
        this.schemaService = schemaService;
        this.WafRuleGroupConfigItem = WafRuleGroupConfigItem;

        this.detectionModeEnumValue = schemaService.getEnumValue(
            'WafMode',
            'WAF_MODE_DETECTION_ONLY',
        );

        this.enforcementModeEnumValue = schemaService.getEnumValue(
            'WafMode',
            'WAF_MODE_ENFORCEMENT',
        );

        this.useParentModeValue = {
            label: '',
            value: undefined,
        };

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;
        this.l10nService_ = l10nService;
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onInit() {
        this.$element.addClass(componentName);

        const { l10nService_: l10nService } = this;

        // as of now used only by rule
        this.childType = this.editable instanceof this.WafRuleGroupConfigItem ? 'group' : 'rule';

        this.useParentModeValue.label =
            l10nService.getMessage(
                l10nKeys.useParentModeValueLabel,
                [this.getParentPolicyModeLabel()],
            );
    }

    /**
     * Returns label of the passed parentMode enum.
     * @return {string}
     */
    getParentPolicyModeLabel() {
        return this.schemaService.getEnumValue(
            'WafMode',
            this.parentMode,
        ).label;
    }
}

WafPolicyChildModeSetterController.$inject = [
    '$element',
    'schemaService',
    'WafRuleGroupConfigItem',
    'l10nService',
];

/**
 * @ngdoc component
 * @name wafPolicyChildModeSetter
 * @param {wafRule} editable
 * @param {string} parentMode - WafMode enum value.
 * @param {boolean?} isDisabled
 * @author Alex Malitsky
 * @desc
 *
 *     Renders three radio buttons to choose WAF rule mode with a header.
 *     Disables radio inputs when true is passed as isDisabled attribute value.
 *     Shows warning message when mode had been set but is not applied due to the
 *     "allow override" WAF policy flag value.
 *
 *     Can be easily extended to work with wafRuleGroup.
 */
angular.module('aviApp').component('wafPolicyChildModeSetter', {
    bindings: {
        editable: '<',
        parentMode: '<',
        isDisabled: '<?',
    },
    controller: WafPolicyChildModeSetterController,
    templateUrl: `src/components/modals/waf-policy-modal/${componentName}/${componentName}.html`,
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc controller
 * @name ServerAutoScaleListController
 * @description Controller for Server AutoScale page.
 **/
angular.module('aviApp').controller('ServerAutoScaleListController', [
'$scope', 'CRUDGridConfig', 'AutoScalePolicyCollection', 'Regex',
function($scope, CRUDGridConfig, AutoScalePolicyCollection, Regex) {
    this.Regex = Regex;

    this.collection = new AutoScalePolicyCollection();

    this.gridConfig = new CRUDGridConfig();

    this.gridConfig.collection = this.collection;

    const { objectName } = this.gridConfig.collection;

    this.gridConfig.id = `${objectName}-list-page`;

    this.gridConfig.fields = [{
        name: 'data.config.name',
        title: 'Name',
        sortBy: 'name',
    }, {
        name: 'data.config.min_size',
        title: 'Min Size',
        template: '{{ row.data.config.min_size || \'None\' }}',
    }, {
        name: 'data.config.max_size',
        title: 'Max Size',
        template: '{{ row.data.config.max_size || \'None\' }}',
    }, {
        name: 'data.config.intelligent_autoscale',
        title: 'Intelligent AutoScale',
        template: '{{ row.data.config.intelligent_autoscale ? \'Enabled\' : \'Disabled\'}}',
    }];
}]);

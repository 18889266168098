/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * Module for System page related services & components.
 * @module avi/system
 */

const schedulerFactory = Item => {
    /**
     * @constructor
     * @memberOf module:avi/system
     * @extends module:avi/dataModel.Item
     * @desc Scheduler {@link module:avi/dataModel.Item Item}.
     * @author alextsg, Aravindh Nagarajan
     */
    class Scheduler extends Item {
        /**
         * @override
         */
        dataToSave() {
            const config = angular.copy(this.data.config);

            return {
                model_name: 'scheduler',
                data: config,
            };
        }

        /**
         * @override
         */
        urlToSave() {
            return '/api/macro';
        }

        /**
         * Getter for backup_passphrase
         * @returns {string}
         */
        get backupPassphrase() {
            return this.backupConfigRefData.backup_passphrase;
        }

        /**
         * Setter for backup_passphrase
         */
        set backupPassphrase(passphrase) {
            this.backupConfigRefData.backup_passphrase = passphrase;
        }

        /**
         * Getter for backup_config_ref_data
         * @returns {Object}
         */
        get backupConfigRefData() {
            return this.getConfig().backup_config_ref_data;
        }
    }

    angular.extend(Scheduler.prototype, {
        objectName: 'scheduler',
        windowElement: 'scheduler-create',
        params: {
            include_name: true,
            join: 'backupconfiguration:backup_config_ref',
        },
    });

    return Scheduler;
};

schedulerFactory.$inject = [
    'Item',
];

angular.module('avi/system').factory('Scheduler', schedulerFactory);

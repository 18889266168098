/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */
import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { IExpanderAction } from 'ng/shared/components/expander-with-actions';
import {
    WafPolicyPsmGroupMetricSumData,
    WafPSMRuleConfigItem,
} from 'ajs/modules/waf';
import './waf-policy-psm-rule.less';
import {
    WAF_PSMRULE_SUM_EVALUATED_METRIC,
    WAF_PSMRULE_SUM_MATCHED_METRIC,
} from '../../../../../waf.constants';

enum EnableState {
    Disabled = 0,
    Enabled = 1,
}

/**
 * @ngdoc component
 * @name WafPolicyPsmRuleComponent
 * @module waf/WafPolicyPsmRuleComponent
 * @desc Component for displaying a WAF PSM rule in the WAF Policy modal.
 * @param onDelete - Called when the user clicks the delete rule button.
 * @param onEdit - Called when the user clicks the edit rule button.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-rule',
    templateUrl: './waf-policy-psm-rule.component.html',
})
export class WafPolicyPsmRuleComponent {
    @Input() public rule: WafPSMRuleConfigItem;
    @Input() public preventEdit: boolean;
    @Input() public metricsData: WafPolicyPsmGroupMetricSumData;

    @Output() public onDelete: EventEmitter<void> = new EventEmitter();
    @Output() public onEdit: EventEmitter<void> = new EventEmitter();

    public expanderActions: IExpanderAction[];

    /**
     * @override
     */
    public ngOnInit(): void {
        this.expanderActions = [
            {
                confirmationButtonProps: {
                    actions: [
                        {
                            label: 'Delete',
                            onClick: (): void => this.handleDelete(),
                        },
                    ],
                    confirmationButtonClassName: 'avi-btn avi-btn-danger',
                },
                confirmationMessage: `Are you sure you want to delete ${this.rule.getName()}?`,
                iconClassName: 'sl-icon-trash',
                title: 'Delete',
            },
            {
                iconClassName: 'sl-icon-note',
                onClick: (): void => this.handleEdit(),
                title: 'Edit',
            },
        ];
    }

    /**
     * Returns the state of the enable switch, 1 for enabled, 0 for disabled.
     */
    public getEnableState(): EnableState {
        return this.rule.enable ? 1 : 0;
    }

    /**
     * Click handler for the enable switch. Flips the current value.
     */
    public handleClickEnable(): void {
        this.rule.enable = !this.rule.enable;
    }

    /**
     * Click handler for deleting a PSM rule.
     */
    public handleDelete(): void {
        this.onDelete.emit();
    }

    /**
     * Click handler for editing a PSM rule.
     */
    public handleEdit(): void {
        this.onEdit.emit();
    }

    /**
     * Returns the sum for WAF_PSMRULE_SUM_EVALUATED_METRIC.
     */
    public get evaluatedCount(): number {
        return this.metricsData[WAF_PSMRULE_SUM_EVALUATED_METRIC] || NaN;
    }

    /**
     * Returns the sum for WAF_PSMRULE_SUM_MATCHED_METRIC.
     */
    public get matchedCount(): number {
        return this.metricsData[WAF_PSMRULE_SUM_MATCHED_METRIC] || NaN;
    }
}

/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { each, findIndex } from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import { FullModalTabSectionComponent } from './full-modal-tab-section';
import './full-modal-config.component.less';
import * as l10n from './full-modal-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const ESCAPE_KEY_CODE = 27;
const ENTER_KEY_CODE = 13;

export interface IFullModalTab {
    title: string;
    id: string;
}

/**
 * @description Component for displaying a configuration modal.
 * @author alextsg
 */
@Component({
    selector: 'full-modal-config',
    templateUrl: './full-modal-config.component.html',
})
export class FullModalConfigComponent implements OnInit, OnDestroy, AfterViewInit {
    /**
     * Title of the modal, typically `${objectType}: ${name}`.
     */
    @Input()
    public modalTitle: string;

    /**
     * Text to be shown on the Cancel button.
     */
    @Input()
    public cancelButtonText: string;

    /**
     * Text to be shown on the Submit button.
     */
    @Input()
    public submitButtonText: string;

    /**
     * True if the data from the parent form has been modified. Used to determine whether the
     * 'Discard changes' prompt should be shown.
     */
    @Input()
    public modified = false;

    /**
     * True if the spinner should be shown in the footer, disabling the action buttons.
     */
    @Input()
    public busy = false;

    /**
     * Errors to be shown on top of the modal.
     */
    @Input()
    public errors: string | object;

    /**
     * If false, will disable the Submit button.
     */
    @Input()
    public valid = true;

    /**
     * Optional class name for full-modal.
     */
    @Input()
    public modalClassName ?= '';

    /**
     * Called when the user wants to cancel or close the modal.
     */
    @Output()
    public onCancel = new EventEmitter();

    /**
     * Called when the user clicks the Submit button.
     */
    @Output()
    public onSubmit = new EventEmitter();

    /**
     * If true, shows the confirmation overlay. Used when the user has made changes and tries to
     * close the modal, and a prompt is shown asking if changes should be discarded.
     */
    public showConfirmCancel = false;

    /**
     * List of tabs to be rendered by the FullModalConfigHeaderTabsComponent.
     */
    public tabs: IFullModalTab[] = [];

    /**
     * Tab ID of the active tab.
     */
    public activeTabId: IFullModalTab['id'];

    /**
     * List of FullmodalTabSectionComponents.
     */
    private tabComponents: FullModalTabSectionComponent[] = [];

    /**
     * Observer to track intersections of tab sections. This is used to change the active navigation
     * tab as the user scrolls through the modal.
     */
    private tabObserver: IntersectionObserver;

    /**
     * Used to keep track of the intersection ratios of each tab section. We want the tab section
     * with the highest intersection ratio to be set as the active navigation tab.
     */
    private tabsIntersectionRatioHash: Record<string, number> = {};

    constructor(
        private elementRef: ElementRef,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.cancelButtonText = l10nService.getMessage(l10nKeys.cancelBtnLabel);
        this.submitButtonText = l10nService.getMessage(l10nKeys.saveBtnLabel);
    }

    /**
     * Listens for keydown events.
     * With FullModal, we might have a stack of modals where only the last modal is attached to the
     * DOM, so we check the isConnected property of the nativeElement to know if this modal is the
     * one attached.
     * If the Escape key is pressed, cancels the current modal. If the Enter key is pressed, submit
     * the modal if valid.
     */
    @HostListener('document:keydown', ['$event'])
    private onKeyDown(event: KeyboardEvent): void {
        if (!this.elementRef.nativeElement.isConnected) {
            return;
        }

        switch (event.which) {
            case ESCAPE_KEY_CODE:
                this.handleCancelAttempt();
                break;

            case ENTER_KEY_CODE: {
                if (this.valid) {
                    this.handleSubmit();
                }

                break;
            }
        }
    }

    /** @override */
    public ngOnInit(): void {
        // Puts focus on the modal, so that if a button was clicked to open this modal, pressing
        // the enter key does not trigger that button.
        this.elementRef.nativeElement.querySelector('.full-modal-config').focus();
    }

    /** @override */
    public ngAfterViewInit(): void {
        if (this.tabComponents.length) {
            const { nativeElement } = this.elementRef;

            this.tabObserver = new IntersectionObserver(this.registerIntersectionObserver, {
                root: nativeElement.querySelector('.full-modal-config__body'),
                threshold: 0.5,
            });

            this.tabComponents.forEach(tab => {
                const { tabId, tabTitle } = tab;
                const tabElement = this.getTabElementById(tabId);

                this.tabObserver.observe(tabElement);
                this.tabs.push({
                    title: tabTitle,
                    id: tabId,
                });
            });
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        if (this.tabObserver) {
            this.tabObserver.disconnect();
        }
    }

    /**
     * Called to hide the confirmation prompt.
     */
    public hideConfirmCancel(): void {
        this.showConfirmCancel = false;
    }

    /**
     * Called when the user tries to cancel or exit out of the modal. If the data has been modified,
     * we show a 'Confirm discard' confirmation in case the user wants to save changes. Otherwise,
     * the cancel handler is called.
     */
    public handleCancelAttempt(): void {
        if (this.modified) {
            this.showConfirmCancel = true;
        } else {
            this.handleCancel();
        }
    }

    /**
     * Handler for cancelling or exiting out of the modal.
     */
    public handleCancel(): void {
        this.hideConfirmCancel();
        this.onCancel.emit();
    }

    /**
     * Handler for submitting or saving the modal.
     */
    public handleSubmit(): void {
        this.onSubmit.emit();
    }

    /**
     * Called by child FullModalTabSectionComponent to add themselves to the list of tabs.
     */
    public addTab(tab: FullModalTabSectionComponent): void {
        this.tabComponents.push(tab);
    }

    /**
     * Called by child FullModalTabSectionComponent to remove itself from the list of tabs.
     */
    public removeTab(tabToRemove: FullModalTabSectionComponent): void {
        const index = findIndex(this.tabComponents, tab => tab === tabToRemove);

        this.tabComponents.splice(index, 1);
    }

    /**
     * Called when selecting a tab. Scrolls to the DOM element with the tab's ID.
     */
    public handleSelectTab(tab: IFullModalTab): void {
        const tabElementRef = this.getTabElementById(tab.id);

        tabElementRef.scrollIntoView({ behavior: 'smooth' });
    }

    /**
     * Returns the ID of the tab that should be marked as active. If no tabs have an intersection
     * ratio greater than 0, returns undefined.
     */
    private getActiveTabId(): string | undefined {
        let intersectionRatio = 0;
        let activeId: string;

        each(this.tabsIntersectionRatioHash, (ratio: number, id: string) => {
            if (ratio > intersectionRatio) {
                intersectionRatio = ratio;
                activeId = id;
            }
        });

        return activeId;
    }

    /**
     * Returns the HTML element with the tab ID.
     */
    private getTabElementById(tabId: string): HTMLElement {
        return this.elementRef.nativeElement.querySelector(`#${tabId}`);
    }

    /**
     * Method passed to the IntersectionObserver as the callback.
     */
    private registerIntersectionObserver = (entries: IntersectionObserverEntry[]): void => {
        entries.forEach(entry => {
            const id = entry.target.getAttribute('id');

            this.tabsIntersectionRatioHash[id] = entry.intersectionRatio;
        });

        const activeId = this.getActiveTabId();

        if (activeId) {
            this.activeTabId = activeId;
        }
    };
}

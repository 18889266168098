const moduleName = 'service-engine-group';
const componentName = 'segroup-nsxt-placement-scope';
const prefix = `${moduleName}.${componentName}`;

export const header = `${prefix}.header`;
export const addVcenterButtonLabel = `${prefix}.addVcenterButtonLabel`;

export const ENGLISH = {
    [header]: 'Placement Scope',
    [addVcenterButtonLabel]: 'Add vCenter',
};

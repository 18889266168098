/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * White list of VS inventory fields/properties to be copied over into vs.data.
 * @type {string[]}
 * @inner
 */
const vsInventoryFields = [
    'has_pool_with_realtime_metrics',
    'pools',
    'poolgroups',
    'faults',
];

/**
 * White list of Pool inventory fields/properties to be copied over into pool.data.
 * @type {string[]}
 * @inner
 */
const poolInventoryFields = [
    'app_profile_type',
    'virtualservices',
];

const objectSpecificInventoryFields = vsInventoryFields.concat(poolInventoryFields);

function healthDataTransformerFactory(DataTransformer) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.DataTransformer
     * @author Ashish Verma
     * @desc dataTransformer of {@link module:avi/app.HealthDataTransformer
     *       HealthDataTransformer}.
     */
    class HealthDataTransformer extends DataTransformer {
        /**
         * Model is executing this function to ask the field to participate in a call group
         * @param data - model data hash, used to figure out the earliest start time for the call
         *     and also may do other preparations
         * @param groupCall - the object that the fields put their parameters in to participate
         *     in the request
         */
        beforeCall(data, groupCall) {
            if (groupCall.inventory) {
                groupCall.inventory.include.health_score = true;
                groupCall.inventory.include.runtime = true;
            }

            return null;
        }

        /**
         * Model is executing this function when the call already happened and the purpose of the
         * call is to share the response data between the fields
         * each field is taking whatever data it needs to generate the right value
         *
         * @param data - model data hash, here used to update fields value
         * @param groupCall - the group call object, at this point it contains server response that
         *     the field want to access to generate value
         */
        afterCall(data, groupCall) {
        // Just puts the result into data. If there is no result, preserves previous version.
            if (groupCall.inventory) {
                const { response } = groupCall.inventory;

                if (response) {
                    data.health_score = response.health_score;
                    data.runtime = response.runtime;

                    //  child VS has a list of parent VS vips here
                    //  let's merge it into runtime.vip_summary
                    if ('parent_vs_vip' in response && 'vip_summary' in data.runtime) {
                        const
                            { parent_vs_vip: vips } = response,
                            { runtime } = data,
                            vipsHash = {};

                        vips.forEach(vipConfig => vipsHash[vipConfig.vip_id] = vipConfig);

                        runtime['vip_summary'].forEach(vip => {
                            if (vip.vip_id in vipsHash) {
                                angular.extend(vip, vipsHash[vip.vip_id]);
                            }
                        });
                    }

                    objectSpecificInventoryFields.forEach(fieldName => {
                        if (fieldName in response) {
                            data[fieldName] = response[fieldName];
                        }
                    });
                }
            }
        }
    }

    HealthDataTransformer.prototype.name = 'health';

    return HealthDataTransformer;
}

healthDataTransformerFactory.$inject = [
    'DataTransformer',
];

angular.module('avi/app').factory('HealthDataTransformer', healthDataTransformerFactory);

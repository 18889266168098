/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  ErrorPageBody
 * @description  ErrorPageBody item.
 */
const ErrorPageBody = Item => {
    class ErrorPageBody extends Item {}

    angular.extend(ErrorPageBody.prototype, {
        objectName: 'errorpagebody',
        windowElement: 'error-page-body-modal',
    });

    return ErrorPageBody;
};

ErrorPageBody.$inject = [
        'Item',
];

angular.module('aviApp').factory('ErrorPageBody', ErrorPageBody);

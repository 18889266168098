/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'avi-forms';
const componentName = 'credentials-verification';
const prefix = `${moduleName}.${componentName}`;

export const changeCredentialsButtonLabel = `${prefix}.changeCredentialsButtonLabel`;

export const ENGLISH = {
    [changeCredentialsButtonLabel]: 'Change Credentials',
};

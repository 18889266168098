/**
 * @module CloudModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'cloud';
const componentName = 'management-network-config';
const prefix = `${moduleName}.${componentName}`;

export const dataNetworksSectionTitle = `${prefix}.dataNetworksSectionTitle`;
export const dataNetworkSegmentsHeader = `${prefix}.dataNetworkSegmentsHeader`;
export const transportZoneSelectLabel = `${prefix}.transportZoneSelectLabel`;
export const transportZoneSelectPlaceholder = `${prefix}.transportZoneSelectPlaceholder`;
export const transportZoneSelectHelperText = `${prefix}.transportZoneSelectHelperText`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const vlanSegmentSelectLabel = `${prefix}.vlanSegmentSelectLabel`;
export const tier1LogicalRouterFieldLabel = `${prefix}.tier1LogicalRouterFieldLabel`;
export const tier1LogicalRouterSelectPlaceholder = `${prefix}.tier1LogicalRouterSelectPlaceholder`;
export const segmentConfigModeInputLabel = `${prefix}.segmentConfigModeInputLabel`;
export const manualSegmentTooltipLabel = `${prefix}.manualSegmentTooltipLabel`;
export const autoSegmentTooltipLabel = `${prefix}.autoSegmentTooltipLabel`;
export const overlaySegmentFieldLabel = `${prefix}.overlaySegmentFieldLabel`;
export const segmentSelectPlaceholder = `${prefix}.segmentSelectPlaceholder`;
export const segmentDropdownHelperText = `${prefix}.segmentDropdownHelperText`;

export const ENGLISH = {
    [dataNetworksSectionTitle]: 'Data Networks',
    [dataNetworkSegmentsHeader]: 'Data Network Segment(s)',
    [transportZoneSelectLabel]: 'Transport Zone',
    [transportZoneSelectPlaceholder]: 'Select Transport Zone',
    [transportZoneSelectHelperText]: 'Connect credentials to populate Transport Zones',
    [addButtonLabel]: 'Add',
    [removeButtonLabel]: 'Remove',
    [vlanSegmentSelectLabel]: 'VLAN Segment',
    [tier1LogicalRouterFieldLabel]: 'Logical Router',
    [tier1LogicalRouterSelectPlaceholder]: 'Select Tier1 Logical Router',
    [segmentConfigModeInputLabel]: 'Logical Segments Config Mode',
    [manualSegmentTooltipLabel]: 'Manual',
    [autoSegmentTooltipLabel]: 'Automatic',
    [overlaySegmentFieldLabel]: 'Overlay Segment',
    [segmentSelectPlaceholder]: 'Select Segment',
    [segmentDropdownHelperText]: 'Configure Tier1 Logical Router ID to populate Segment IDs',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function snmpProfileFactory(Item) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Item
     * @author Ram Pal
     * @desc SnmpProfile item.
     */
    class SnmpProfile extends Item {
        /** @override */
        beforeEdit() {
            const { data } = this;

            if (!data.config.trap_servers) {
                data.config.trap_servers = [{
                    ip_addr: {
                        type: 'V4',
                        addr: '',
                    },
                    community: '',
                }];
            }
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            const { trap_servers: trapServers } = config;

            if (Array.isArray(trapServers)) {
                trapServers.forEach(server => {
                    switch (server.version) {
                        case 'SNMP_VER2':
                            delete server.user;
                            break;
                        case 'SNMP_VER3':
                            delete server.community;
                            break;
                    }
                });
            }

            return config;
        }
    }

    Object.assign(SnmpProfile.prototype, {
        objectName: 'snmptrapprofile',
        windowElement: 'adm-snmp-create',
    });

    return SnmpProfile;
}

snmpProfileFactory.$inject = [
    'Item',
];

angular.module('avi/app')
    .factory('SnmpProfile', snmpProfileFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './category-navbar.less';

import * as l10n from './category-navbar.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const componentTag = 'category-navbar';

const pathPrefix = `src/components/avi-header/${componentTag}`;

const stateTemplatePathHash = {
    application: `${pathPrefix}/${componentTag}-applications.html`,
    operations: `${pathPrefix}/${componentTag}-operations.html`,
    profile: `${pathPrefix}/${componentTag}-templates.html`,
    infrastructure: `${pathPrefix}/${componentTag}-infrastructure.html`,
    administration: `${pathPrefix}/${componentTag}-administration.html`,
};

/**
 * @class
 * @constructor
 * @memberOf module:avi/navigation
 * @see {@link  module:avi/navigation.categoryNavbarComponent}
 */
class CategoryNavbarController {
    constructor($state, systemInfoService, l10nService) {
        this.$state_ = $state;
        l10nService.registerSourceBundles(dictionary);
        this.systemInfoService_ = systemInfoService;
        this.l10nKeys = l10nKeys;
    }

    /**
     * Returns true if a GSLB config exists.
     * @returns {boolean}
     */
    hasGSLBConfig() {
        return this.systemInfoService_.haveGSLBConfig();
    }

    /**
     * Returns the template path of the current category.
     * @returns {string}
     */
    getNavbarTemplatePath() {
        const stateParts = this.$state_.$current.name.split('.');

        return stateTemplatePathHash[stateParts[1]];
    }
}

CategoryNavbarController.$inject = [
    '$state',
    'systemInfoService',
    'l10nService',
];

/**
 * @name categoryNavbarComponent
 * @memberOf module:avi/navigation
 * @property {module:avi/navigation.CategoryNavbarController} controller
 * @desc
 *      Component in the header showing the substates of a category.
 * @author alextsg
 */
angular.module('avi/navigation').component('categoryNavbar', {
    controller: CategoryNavbarController,
    templateUrl: `${pathPrefix}/${componentTag}.html`,
});

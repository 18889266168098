/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * Module for IPAM/DNS profile.
 * @module avi/ipam
 */

import {
    IPAMDNS_TYPE_INTERNAL,
    IPAMDNS_TYPE_INTERNAL_DNS,
    IPAMDNS_TYPE_INFOBLOX,
    IPAMDNS_TYPE_INFOBLOX_DNS,
    IPAMDNS_TYPE_AWS,
    IPAMDNS_TYPE_AWS_DNS,
    IPAMDNS_TYPE_OPENSTACK,
    IPAMDNS_TYPE_CUSTOM,
    IPAMDNS_TYPE_CUSTOM_DNS,
    IPAMDNS_TYPE_AZURE_DNS,
    IPAMDNS_TYPE_OCI,
} from 'ajs/js/constants/ipam-dns.constants';

const IPAMProfileFactory = (
    ObjectTypeItem,
    $http,
    $q,
    secretStubStr,
    defaultValues,
    IpamDnsAwsProfileConfig,
) => {
    /**
     * @constructor
     * @extends module:avi/dataModel.ObjectTypeItem
     * @memberOf module:avi/ipam
     * @description
     *     IPAMProfile item. Also responsible for making requests to
     *     '/api/ipamdnsproviderprofiledomainlist/' and '/api/ipamdnsproviderprofilenetworklist/' to
     *     get network and domain lists for IPAM profile configuration. When creating a VS, a GET
     *     request to '/api/ipamdnsproviderprofilenetworklist/' is used to retrieve networks from
     *     the IPAM Profile configuration.
     * @author alextsg
     */
    class IPAMProfile extends ObjectTypeItem {
        constructor(args) {
            const extendedArgs = {
                ...args,
                permissionName: 'PERMISSION_IPAMDNSPROVIDERPROFILE',
                objectType: 'IpamDnsProviderProfile',
                whitelistedFields: [
                    'internal_profile',
                    'infoblox_profile',
                ],
                restrictEditOnEssentialLicense: false,
            };

            super(extendedArgs);

            const config = this.getConfig();

            /**
             * Config may not be present when creating a new instance of IPAMProfile.
             */
            if (angular.isObject(config)) {
                if (!(config.aws_profile instanceof IpamDnsAwsProfileConfig)) {
                    config.aws_profile = new IpamDnsAwsProfileConfig({
                        data: {
                            config: config.aws_profile,
                        },
                    });
                }
            }
        }

        /**
         * Returns the IPAMDNS profile type.
         * @returns {string}
         */
        get type() {
            return this.config.type;
        }

        /**
         * Returns true if the match string is contained within the type. For example,
         * this.typeContains('IPAMDNS_TYPE_INTERNAL') would check for both 'IPAMDNS_TYPE_INTERNAL'
         * and 'IPAMDNS_TYPE_INTERNAL_DNS'.
         * @param {string} matchString - string to check against the type.
         * @returns {boolean}
         */
        typeContains(matchString) {
            return this.type.indexOf(matchString) > -1;
        }

        /**
         * Returns the profile configuration according to the IPAMDNS profile type.
         * @returns {Object|null}
         */
        get currentProfile() {
            const profileField = IPAMProfile.typeToConfig[this.type];

            return this.config[profileField] || null;
        }

        /**
         * @override
         */
        transformAfterLoad() {
            const config = this.getConfig();

            if (!_.isEmpty(config.aws_profile)) {
                const awsConfig = this.getAwsProfile();

                config.aws_profile = new IpamDnsAwsProfileConfig({
                    data: {
                        config: awsConfig,
                    },
                    parentId: this.id,
                });
            }
        }

        /**
         * @override
         */
        beforeEdit() {
            const config = this.getConfig();

            if (config.aws_profile && config.aws_profile instanceof IpamDnsAwsProfileConfig) {
                config.aws_profile.beforeEdit();
            }

            if (!('custom_profile' in config)) {
                config.custom_profile = {};
            }

            if (!('dynamic_params' in config.custom_profile)) {
                config.custom_profile.dynamic_params = [];
            }
        }

        /**
         * @override
         */
        dataToSave() {
            const config = super.dataToSave();
            const { type } = config;

            if (type !== IPAMDNS_TYPE_INFOBLOX && type !== IPAMDNS_TYPE_INFOBLOX_DNS) {
                delete config.infoblox_profile;
            }

            if (type !== IPAMDNS_TYPE_INTERNAL && type !== IPAMDNS_TYPE_INTERNAL_DNS) {
                delete config.internal_profile;
            }

            if (type !== IPAMDNS_TYPE_AWS && type !== IPAMDNS_TYPE_AWS_DNS) {
                delete config.aws_profile;
            }

            if (type !== IPAMDNS_TYPE_OPENSTACK) {
                delete config.openstack_profile;
            }

            if (type !== IPAMDNS_TYPE_CUSTOM && type !== IPAMDNS_TYPE_CUSTOM_DNS) {
                delete config.custom_profile;
            }

            switch (config.type) {
                case IPAMDNS_TYPE_AWS:
                    config.aws_profile.clearUsableDomains();
                    config.aws_profile = config.aws_profile.dataToSave();
                    break;

                case IPAMDNS_TYPE_AWS_DNS:
                    config.aws_profile.clearUsableNetworks();
                    config.aws_profile = config.aws_profile.dataToSave();
                    break;

                case IPAMDNS_TYPE_INFOBLOX: {
                    const infoblox = config.infoblox_profile;

                    delete infoblox.usable_domains;
                    break;
                }

                case IPAMDNS_TYPE_INFOBLOX_DNS:
                    delete config.infoblox_profile.usable_alloc_subnets;
                    break;

                case IPAMDNS_TYPE_AZURE_DNS: {
                    const { azure_profile: azureProfile } = config;

                    delete azureProfile.usable_network_uuids;
                    break;
                }
            }

            return config;
        }

        /**
         * Called on IPAM Profile type change. Resets config fields.
         */
        onTypeChange() {
            this.resetProfiles_();
        }

        /**
         * Reset profiles. Called on IPAM type change.
         * @protected
         */
        resetProfiles_() {
            const config = this.getConfig();
            const defaultConfigHash = this.getDefaultConfig_();
            const { profileConfigPropNames } = IPAMProfile;

            profileConfigPropNames.forEach(propName => {
                const defaultConfig = defaultConfigHash[propName] || {};
                let profile = defaultConfig;

                switch (propName) {
                    case 'internal_profile':
                        profile = this.createChildByField_(propName);

                        break;

                    case 'infoblox_profile':
                        profile = this.createChildByField_(propName);

                        break;

                    case 'aws_profile':
                        profile = new IpamDnsAwsProfileConfig({
                            data: {
                                config: defaultConfig,
                            },
                            parentId: this.id,
                        });

                        profile.beforeEdit();

                        break;

                    case 'custom_profile':
                        profile.dynamic_params = [];

                        break;
                }

                config[propName] = profile;
            });
        }

        /**
         * Called on change when user selects between credentials used for Azure configuration.
         */
        clearAzureCredentials() {
            const { azure_profile: azureProfile } = this.getConfig();

            if (!_.isEmpty(azureProfile)) {
                delete azureProfile.azure_userpass;
                delete azureProfile.azure_serviceprincipal;
            }
        }

        /**
         * Adds usable_network_refs entry to the profile based on the IPAM type.
         * @param {string} prop - Usable network property.
         */
        addUsableNetwork(prop) {
            const config = this.getConfig();
            const { type } = config;
            const configProperty = IPAMProfile.typeToConfig[type];

            config[configProperty] = config[configProperty] || {};

            const configObject = config[configProperty];

            configObject[prop] = configObject[prop] || [];
            configObject[prop].push(undefined);
        }

        /**
         * Removes a usable_network_ref entry from the profile.
         * @param {string} prop - Usable network property.
         * @param {number=} index - Index of the network to remove.
         */
        removeUsableNetwork(prop, index = 0) {
            const config = this.getConfig();
            const { type } = config;
            const configProperty = IPAMProfile.typeToConfig[type];
            const configObject = config[configProperty];

            configObject[prop].splice(index, 1);
        }

        /**
         * Clears proxy configuration.
         */
        clearProxyConfiguration() {
            delete this.getConfig().proxy_configuration;
        }

        /**
         * @override
         */
        getSecretStubPayload_(payload, key = 'uuid') {
            if (this.id && (!payload.password || payload.password === secretStubStr)) {
                payload[key] = this.id;
                delete payload.username;
                delete payload.password;
                delete payload.proxy_pass;
            }

            return payload;
        }

        /**
         * Adds new element to custom_profile.dynamic_params array.
         */
        addCustomDynamicParam() {
            const {
                dynamic_params: dynamicParams = [],
            } = this.getProfileConfig();

            const entry = defaultValues.getDefaultItemConfigByType('CustomParams');

            entry.name = entry.name || '';
            entry.value = entry.value || '';

            dynamicParams.push(entry);
        }

        /**
         * Removes element from custom_profile.dynamic_params array at index.
         * @param {number} index
         */
        removeCustomDynamicParam(index) {
            const config = this.getConfig();

            config.custom_profile.dynamic_params.splice(index, 1);
        }

        /**
         * Removes element from custom_profile.usable_domains array at index.
         * @param {number} index
         */
        removeCustomProfileDomain(index) {
            IPAMProfile.removeDomain(index, this.getConfig().custom_profile);
        }

        /**
         * Adds empty domain to custom_profile.usable_domains.
         */
        addCustomProfileDomain() {
            const config = this.getConfig();

            IPAMProfile.addDomain(config.custom_profile);
        }

        /**
         * Returns an object of params used for Azure requests. If editing, includes the profile
         * ID.
         * @return {Object}
         */
        _getAzureParams() {
            const { azure_profile: azureProfile } = this.getConfig();
            const {
                azure_userpass: userpass,
                azure_serviceprincipal: serviceprincipal,
            } = azureProfile;

            const params = {
                subscription_id: azureProfile.subscription_id,
            };

            if (!_.isEmpty(azureProfile.virtual_network_ids)) {
                [params.vnet_id] = azureProfile.virtual_network_ids;
            }

            if (!_.isEmpty(userpass)) {
                angular.extend(params, userpass);

                if (params.password === secretStubStr) {
                    params.ipamdnsprovider_uuid = this.id;
                    delete params.password;
                }
            } else {
                angular.extend(params, serviceprincipal);

                if (params.authentication_token === secretStubStr) {
                    params.ipamdnsprovider_uuid = this.id;
                    delete params.authentication_token;
                }

                // Workaround for inconsistent API for now.
                params.authentication_key = params.authentication_token;
            }

            return params;
        }

        /**
         * Returns an object of params based on the type of IPAM profile.
         * @return {Object}
         * @protected
         */
        _getParams() {
            const config = this.getConfig();
            let params;

            switch (config.type) {
                case IPAMDNS_TYPE_AZURE_DNS:
                    params = this._getAzureParams();
                    break;
            }

            delete params.usable_alloc_subnets;
            delete params.usable_domains;
            delete params.usable_network_uuids;
            delete params.access_key_id;
            delete params.secret_access_key;
            delete params.vpc_id;
            params.type = config.type;
            params.provider = true;

            return params;
        }

        /**
         * Public method for returning an object of params based on the type of IPAM profile.
         * @return {Object}
         */
        getProfileParams() {
            return this._getParams();
        }

        /**
         * Returns lists of AWS networks and VPCs.
         * @param {string} list - Specifies which list to request (usable networks or domains)
         *     in addition to the list of VPCs.
         * @return {ng.$q.promise}
         */
        getAwsLists(list) {
            const awsProfile = this.getAwsProfile();

            this.busy = true;

            return awsProfile.getLists(list, this.getProxyConfig())
                .finally(() => this.busy = false);
        }

        /**
         * Requests AWS AssumeRole list.
         * @returns {ng.$q.promise}
         */
        getAwsIamAssumeRoles() {
            const awsProfile = this.getAwsProfile();

            this.busy = true;

            return awsProfile.getIamAssumeRoles(this.getProxyConfig())
                .finally(() => this.busy = false);
        }

        /**
         * Requests the list of availability zones to be used for usable networks.
         * @return {ng.$q.promise}
         */
        getAwsAvailabilityZones() {
            const awsProfile = this.getAwsProfile();

            this.busy = true;

            return awsProfile.getAvailabilityZones(this.getProxyConfig())
                .finally(() => this.busy = false);
        }

        /**
         * Returns a list of AWS domains.
         * @return {ng.$q.promise}
         */
        getAwsDomains() {
            const awsProfile = this.getAwsProfile();

            this.busy = true;

            return awsProfile.getDomains(this.getProxyConfig())
                .finally(() => this.busy = false);
        }

        /**
         * Makes request for Azure domains while setting this.busy and this.errors.
         * @public
         * @return {ng.$q.promise}
         */
        getDomainList() {
            if (!this.id) {
                return $q.reject('No ipamdnsprovider_uuid');
            }

            this.busy = true;
            this.errors = null;

            return this._getDomainList()
                .then(({ data }) => data.domains || [])
                .catch(({ data }) => {
                    this.errors = data;

                    return $q.reject(this.errors);
                })
                .finally(() => this.busy = false);
        }

        /**
         * Check azure credentials for validation.
         * @return {ng.$q.promise}
         */
        verifyAzureCredentials() {
            this.busy = true;
            this.errors = null;

            return this._getAzureVirtualNetworks()
                .then(rsp => rsp)
                .catch(({ data }) => $q.reject(data))
                .finally(() => this.busy = false);
        }

        /**
         * Makes request for Virtual Networks.
         * @return {ng.$q.promise}
         */
        _getAzureVirtualNetworks() {
            const api = '/api/azure-get-virtual-networks';
            const params = this._getParams();

            delete params.vnet_id;

            return this.request('POST', api, params);
        }

        /**
         * Makes request for the domains list without dealing with this.busy or this.errors, so
         * that they can be managed by the caller.
         * @return {ng.$q.promise}
         * @protected
         */
        _getDomainList() {
            const api = '/api/ipamdnsproviderprofiledomainlist/';

            return this.request('GET', `${api}?ipamdnsprovider_uuid=${this.id}`);
        }

        /**
         * Clear password for the profile.
         */
        clearProfilePassword() {
            const profileConfig = this.getProfileConfig();

            if (this.isType(IPAMDNS_TYPE_AZURE_DNS)) {
                const { azure_userpass: azureUserpass = {} } = profileConfig;

                delete azureUserpass.password;
            } else {
                delete profileConfig.password;
            }
        }

        /**
         * Checks if current IPAM Profile of specified type.
         * @param {string} type - IPAM/DNS type (enum IpamDnsType).
         * @return {boolean}
         */
        isType(type) {
            return this.type === type;
        }

        /**
         * Returns true if Cloud or its ConfigItems are busy.
         * @return {boolean}
         */
        isBusy() {
            const {
                aws_profile: awsProfile,
                infoblox_profile: infobloxProfile,
            } = this.getConfig();

            return this.busy || awsProfile && awsProfile.busy ||
                infobloxProfile && infobloxProfile.busy || false;
        }

        /**
         * Returns the errors from Cloud or its ConfigItems.
         * @return {string|Object}
         */
        getErrors() {
            const { aws_profile: awsProfile } = this.getConfig();

            return this.errors || awsProfile && awsProfile.errors;
        }

        /**
         * Returns this.data.config.aws_profile.
         * @return {IpamDnsAwsProfileConfig}
         */
        getAwsProfile() {
            return this.getConfig().aws_profile;
        }

        /**
         * Returns this.data.config.proxy_configuartion.
         * @return {Object}
         */
        getProxyConfig() {
            return this.getConfig().proxy_configuration;
        }

        /**
         * Returns profile configuration object depending of IPAMProfile type.
         * @return {Object|null}
         */
        getProfileConfig() {
            const config = this.getConfig();
            const { type } = config;
            const configProperty = IPAMProfile.typeToConfig[type];

            return config[configProperty] || null;
        }

        /**
         * Adds empty string to usable_domains array inside config object.
         * @param {Object} config
         */
        static addDomain(config) {
            if (!Array.isArray(config.usable_domains)) {
                config.usable_domains = [];
            }

            config.usable_domains.push('');
        }

        /**
         * Removes element from usable_domains array inside config object.
         * @param {number} index
         * @param {Object} config
         */
        static removeDomain(index, config) {
            if (Array.isArray(config.usable_domains)) {
                config.usable_domains.splice(index, 1);
            }
        }

        /**
         * Returns IpamDnsType enum types hash.
         */
        static get typeHash() {
            return {
                IPAMDNS_TYPE_OCI,
                IPAMDNS_TYPE_INTERNAL,
                IPAMDNS_TYPE_INTERNAL_DNS,
                IPAMDNS_TYPE_INFOBLOX,
                IPAMDNS_TYPE_INFOBLOX_DNS,
                IPAMDNS_TYPE_AWS,
                IPAMDNS_TYPE_AWS_DNS,
                IPAMDNS_TYPE_OPENSTACK,
                IPAMDNS_TYPE_CUSTOM,
                IPAMDNS_TYPE_CUSTOM_DNS,
                IPAMDNS_TYPE_AZURE_DNS,
            };
        }

        /**
         * Gets list of domains for a specific cloud. Since the domains don't come prepended
         * with a '.', it is done here manually.
         * @param  {string} cloudRef - Cloud URL string.
         * @return {ng.$q.promise<string[]>}
         */
        static getIpamDomains(cloudRef) {
            const api = `/api/ipamdnsproviderprofiledomainlist/?cloud_uuid=${cloudRef.slug()}`;

            return $http.get(api)
                .then(({ data }) => data.domains.map(domainName => `.${domainName}`));
        }

        /**
         * Get list of profile property config names.
         * @return {string[]}
         * @protected
         */
        static getProfileConfigPropNames_() {
            const propNameValues = _.values(IPAMProfile.typeToConfig);

            return _.uniq(propNameValues);
        }

        /**
         * Hash mapping the IPAM/DNS profile type to the configuration property.
         * @type {Object.<string, string>}
         */
        static typeToConfig = {
            [IPAMDNS_TYPE_INTERNAL]: 'internal_profile',
            [IPAMDNS_TYPE_INTERNAL_DNS]: 'internal_profile',
            [IPAMDNS_TYPE_INFOBLOX]: 'infoblox_profile',
            [IPAMDNS_TYPE_INFOBLOX_DNS]: 'infoblox_profile',
            [IPAMDNS_TYPE_AWS]: 'aws_profile',
            [IPAMDNS_TYPE_AWS_DNS]: 'aws_profile',
            [IPAMDNS_TYPE_OPENSTACK]: 'openstack_profile',
            [IPAMDNS_TYPE_CUSTOM]: 'custom_profile',
            [IPAMDNS_TYPE_CUSTOM_DNS]: 'custom_profile',
            [IPAMDNS_TYPE_AZURE_DNS]: 'azure_profile',
        }

        /**
         * List of profile property config names.
         * @type {string[]}
         */
        static profileConfigPropNames = IPAMProfile.getProfileConfigPropNames_();
    }

    Object.assign(IPAMProfile.prototype, {
        objectName: 'ipamdnsproviderprofile',
        windowElement: 'ipam-dns-profiles-modal',
    });

    return IPAMProfile;
};

IPAMProfileFactory.$inject = [
    'ObjectTypeItem',
    '$http',
    '$q',
    'secretStubStr',
    'defaultValues',
    'IpamDnsAwsProfileConfig',
];

angular.module('avi/ipam').factory('IPAMProfile', IPAMProfileFactory);

/**
 * @module NatModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import './nat-rule-expander-content.component.less';
import {
    Component, Inject, Input,
} from '@angular/core';
import { findWhere } from 'underscore';

import {
    NatAddrInfoConfigItem,
    NatMatchTargetConfigItem,
    NatRuleConfigItem,
} from 'ajs/js/services/config-items/nat-policy';

import { IEnumValue, SchemaService } from 'ajs/modules/core';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './nat-rule-expander-content.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

@Component({
    selector: 'nat-rule-expander-content',
    templateUrl: './nat-rule-expander-content.component.html',
})
export class NatRuleExpanderContentComponent {
    /**
     * NatRuleConfigItem instance.
     */
    @Input() public rule: NatRuleConfigItem;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Will keep selected rule's match configItem.
     */
    private match_: NatMatchTargetConfigItem;

    /**
     * Enum values for NatPolicyAction
     */
    private actionEnums_: IEnumValue[];

    constructor(
        @Inject(SchemaService) private schemaService: SchemaService,
        l10nService: L10nService,
    ) {
        this.match_ = null;
        this.actionEnums_ = this.schemaService.getEnumValues('NatPolicyActionType');

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.match_ = this.rule.config.match;
    }

    /**
     * Returns the list of Supported match field names.
     */
    public get matchFields(): string[] {
        // Pick only the fields that are configured in rule
        return this.match_ ?
            this.match_.supportedMatches.filter((field: string) => this.hasMatch(field)) : [];
    }

    /**
     * Getter for NATPolicyAction type enumvalue.
     */
    public get natActionType(): IEnumValue {
        const natActionType = findWhere(this.actionEnums_, {
            value: this.rule.action.type,
        });

        return natActionType;
    }

    /**
     * Returns comma seperated string of Configured IP addresses.
     */
    public get natActionAddrs(): string {
        const natInfoConfig =
            this.rule.action.natInfo.config as any as NatAddrInfoConfigItem[];

        return natInfoConfig
            .map((natAddr: NatAddrInfoConfigItem) => natAddr.toString()).join(', ');
    }

    /**
     * Returns true if the current rule has NatAddrInfo configured.
     */
    public get hasNatAddrInfo(): boolean {
        return !this.rule.action.natInfo.isEmpty();
    }

    /**
     * Method to filter out unused matches.
     * Returns true, if Rule is configured with the match field.
     */
    public hasMatch(field: string): boolean {
        return this.match_.hasMatchByField(field);
    }

    /**
     * Returns value for a match field.
     * @param field Any one of the supportedMatches (path/client_ip/host_hdr)
     */
    public getMatchValue(field: string): string {
        const {
            config: matchConfig,
        } = this.match_;

        return field === 'source_ip' || field === 'destination_ip' ?
            matchConfig[field].flatDataToString() :
            matchConfig[field];
    }

    /**
     * Returns label for a match field.
     * @param field Any one of the supportedMatches.
     */
    public getMatchLabel(field: string): string {
        return this.match_.getMatchLabelByField(field);
    }
}

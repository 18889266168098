/**
 * @module CoreModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

export enum AviModalType {
    AVI_MODAL,
    AVI_ALERT_OR_CONFIRM,
}

const moduleName = 'ajs-legacy';
const componentName = 'item-alert-popover';
const prefix = `${moduleName}.${componentName}`;

export const viewAllAlertsListLabel = `${prefix}.viewAllAlertsListLabel`;
export const goToAlertsPageListLabel = `${prefix}.goToAlertsPageListLabel`;
export const alertsHeader = `${prefix}.alertsHeader`;
export const currentlyNoAlertsLabel = `${prefix}.currentlyNoAlertsLabel`;
export const onLabel = `${prefix}.onLabel`;

export const ENGLISH = {
    [viewAllAlertsListLabel]: 'View All Alerts',
    [goToAlertsPageListLabel]: 'Go to Alerts Page',
    [alertsHeader]: 'alerts',
    [currentlyNoAlertsLabel]: 'Currently no Alerts',
    [onLabel]: 'on',
};

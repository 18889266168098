/**
 * @module IcapProfileModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
} from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';

import { ICAPProfileModalComponent } from '.';

const icapProfileComponents = [
    ICAPProfileModalComponent,
];

/**
 * @description
 *
 *      Angular Module for ICAP-Profile related components.
 *
 * @author Aravindh Nagarajan
 */
@NgModule({
    declarations: [
        ...icapProfileComponents,
    ],
    imports: [
        FormsModule,
        SharedModule,
        BrowserAnimationsModule,
        ClrCheckboxModule,
        ClrInputModule,
        ClrFormsModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class IcapProfileModule {}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const componentName = 'sso-policy-list';

class SSOPolicyListController {
    constructor($element, SSOPolicyCollection, CRUDGridConfig) {
        this.$element_ = $element;
        this.SSOPolicyCollection_ = SSOPolicyCollection;
        this.CRUDGridConfig_ = CRUDGridConfig;
    }

    $onInit() {
        this.$element_.addClass(componentName);

        const gridConfigOptions = {
            collection: new this.SSOPolicyCollection_(),
            fields: [{
                name: 'name',
                title: 'Name',
                template: '{{row.getName()}}',
                sortBy: 'name',
            },
            {
                name: 'default_auth_profile',
                title: 'Auth Profile',
                template: '{{row.getDefaultAuthProfileRef() | name}}',
            }],
            permission: 'PERMISSION_SSOPOLICY',
        };

        const { objectName } = gridConfigOptions.collection;

        gridConfigOptions.id = `${objectName}-list-page`;

        this.gridConfig = new this.CRUDGridConfig_(gridConfigOptions);
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

SSOPolicyListController.$inject = [
    '$element',
    'SSOPolicyCollection',
    'CRUDGridConfig',
];

/**
 * @ngdoc component
 * @name SSOPolicyList
 * @author Aravindh Nagarajan
 * @description SSO Policy list page with options to create/delete/edit a policy.
 */
angular.module('aviApp').component('ssoPolicyList', {
    controller: SSOPolicyListController,
    template:
        `<div class="templatesList">
            <collection-grid config="$ctrl.gridConfig"></collection-grid>
        </div>`,
});

/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

/**
 * Returns uniqueness validatorFunction for AviRepeatedStringsComponent.
 *
 * @author Aravindh Nagarajan
 */
export function aviRepeatedStringsUniquenessValidator(): ValidatorFn {
    /**
     * Returns ValidationErrors object if value list has duplicates.
     * otherwise null.
     */
    return (control: AbstractControl): ValidationErrors | null => {
        const { value } = control;
        const { length: actualCount } = value;
        const { size: uniqueCount } = new Set(value);

        if (actualCount !== uniqueCount) {
            return {
                aviRepeatedStringsUniquenessValidation: value,
            };
        } else {
            return null;
        }
    };
}

const moduleName = 'licensing';
const componentName = 'license-summary-card';
const prefix = `${moduleName}.${componentName}`;

export const title = `${prefix}.title`;
export const usagePercentage = `${prefix}.usagePercentage`;
export const serviceCoreLicensesLabel = `${prefix}.serviceCoreLicensesLabel`;

export const ENGLISH = {
    [title]: 'License',
    [usagePercentage]: '({0}% Used)',
    [serviceCoreLicensesLabel]: 'Service Core Licenses',
};

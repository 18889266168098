/**
 * @module DataModelModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { ListCollDataSource } from './list-coll-data-source.factory';

export class LimitedListCollDataSource extends ListCollDataSource {}

Object.assign(LimitedListCollDataSource.prototype, {
    hasSearch: false,
    hasSorting: false,
    hasPagination: false,
});

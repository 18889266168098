/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name policyGridMatchColumn
 * @description Component for displaying the match configuration in a grid field.
 * @param {DnsRuleConfig} rule
 */
class DnsPolicyGridMatchColumnController {
    /**
     * Returns true if rule has any matches
     * @return {boolean}
     */
    hasMatches() {
        return this.rule.hasMatches();
    }

    /**
     * Returns all Matches in the rule.
     * @return {MatchConfigItem[]}
     */
    getMatches() {
        return this.rule.getMatches();
    }
}

angular.module('aviApp').component('dnsPolicyGridMatchColumn', {
    bindings: {
        rule: '<',
    },
    controller: DnsPolicyGridMatchColumnController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-dns-policy/' +
            'dns-policy-grid-match-column/dns-policy-grid-match-column.html',
});

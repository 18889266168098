/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const l4RuleProtocolMatchConfigItemFactory = (MessageItem, schemaService) => {
    /**
     * @constructor
     * @memberOf module:avi/policies/nat
     * @extends module:avi/dataModel.MessageItem
     * @desc L4RuleProtocolMatch MessageItem class.
     * @author Aravindh Nagarajan
     */
    class L4RuleProtocolMatchConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'L4RuleProtocolMatch',
                ...args,
            };

            super(extendedArgs);

            /**
             * Dropdown options for Match Operation
             * @type {Object}
             */
            this.matchOperationLabelsHash_ = schemaService.getEnumValueLabelsHash('MatchOperation');
        }

        /** @override */
        get defaultConfigOverride_() { //eslint-disable-line class-methods-use-this
            return {
                match_criteria: 'IS_IN',
            };
        }

        /**
         * Returns the label for this.config.match_criteria.
         * @returns {string}
         */
        get matchCriteriaLabel() {
            const { match_criteria: matchCriteria } = this.config;

            return this.matchOperationLabelsHash_[matchCriteria];
        }

        /**
         * Returns a string representation of the config data.
         * @returns {string}
         */
        toString() {
            return `${this.matchCriteriaLabel} ${this.config.protocol}`;
        }
    }

    return L4RuleProtocolMatchConfigItem;
};

l4RuleProtocolMatchConfigItemFactory.$inject = [
    'MessageItem',
    'schemaService',
];

angular.module('avi/policies/nat')
    .factory('L4RuleProtocolMatchConfigItem', l4RuleProtocolMatchConfigItemFactory);

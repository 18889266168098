/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const componentName = 'scheduler-create';

/**
 * @constructor
 * @memberOf module:avi/system
 * @mixes module:avi/system.schedulerCreateComponentBindings
 * @see {@link module:avi/system.schedulerCreateComponent schedulerCreateComponent}
 */
class SchedulerCreateController {
    constructor(Regex, CloudConnectorUserCollection, schemaService) {
        this.regex = Regex;

        /**
         * @type {CloudConnectorUserCollection}
         */
        this.sshUserCollection = new CloudConnectorUserCollection();

        /**
         * Scheduler frequency-unit dropdown options.
         * @type {DropDownOption[]}
         */
        this.schedulerFrequencyUnitOptions = schemaService.getEnumValues('SchedulerFrequencyUnit');
    }

    /** @override */
    $onDestroy() {
        this.sshUserCollection.destroy();
    }
}

SchedulerCreateController.$inject = [
        'Regex',
        'CloudConnectorUserCollection',
        'schemaService',
];

/**
 * @name schedulerCreateComponent
 * @memberOf module:avi/system
 * @property {module:avi/system.schedulerCreateComponentBindings} bindings
 * @property {module:avi/system.SchedulerCreateController} controller
 * @desc Scheduler create/edit modal component.
 * @author alextsg, Aravindh Nagarajan
 */
angular.module('avi/system').component('schedulerCreate', {
    /**
     * @mixin schedulerCreateComponentBindings
     * @memberof module:avi/system
     * @property {module:avi/system.Scheduler} editable Scheduler item
     */
    bindings: {
        editable: '=',
    },
    controller: SchedulerCreateController,
    templateUrl:
        'src/components/modals/administration/system/' +
        `${componentName}/${componentName}.component.html`,
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  WafPolicyCollection
 * @description  Collection of {WafPolicy} items.
 */
const CollectionFactory = (RepeatedMessageItem, defaultValues, Collection, WafPolicy, $q) => {
    class WafPolicyCollection extends Collection {
        constructor(args) {
            const extendedArgs = {
                ...args,
                permissionName: 'PERMISSION_WAFPOLICY',
            };

            super(extendedArgs);
        }

        /**
         * Used to set serverDefaultsOverride_ with the system default Waf Policy.
         * @return {ng.$q.promise}
         */
        setServerDefaultsOverride() {
            this.serverDefaultsOverride_ = this.serverDefaultsOverride_ || {};

            if ('crs_groups' in this.serverDefaultsOverride_) {
                return $q.when();
            } else {
                const defaultWafPolicyRef = defaultValues.getSystemObjectRefByName(
                    'wafpolicy',
                    'System-WAF-Policy',
                );

                const defaultWafPolicy = new WafPolicy({ id: defaultWafPolicyRef.slug() });

                return defaultWafPolicy.load()
                    .then(() => {
                        const {
                            waf_crs_ref_data: defaultWafCrsGroups,
                            waf_crs_ref: defaultWafCrsRef,
                        } = defaultWafPolicy.getConfig();

                        angular.extend(this.serverDefaultsOverride_, {
                            waf_crs_ref: defaultWafCrsRef,
                            waf_crs_ref_data: defaultWafCrsGroups.flattenConfig(),
                        });
                    });
            }
        }

        /**
         * @override
         * @description
         *     When creating a new Waf Policy, we need to make a request to get the groups and
         *     rules from the default Waf Policy, 'System-WAF-Policy', and add them to the new
         *     WafPolicy item as defaults.
         */
        create(windowElement, params) {
            return this.setServerDefaultsOverride()
                .then(() => super.create(windowElement, params));
        }
    }

    angular.extend(WafPolicyCollection.prototype, {
        objectName_: 'wafpolicy',
        itemClass_: WafPolicy,
        windowElement_: 'waf-policy-modal',
    });

    return WafPolicyCollection;
};

CollectionFactory.$inject = [
    'RepeatedMessageItem',
    'defaultValues',
    'Collection',
    'WafPolicy',
    '$q',
];

angular.module('aviApp').factory('WafPolicyCollection', CollectionFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './log-filter-number-popover.less';

/**
 * @ngdoc component
 * @name logFilterNumberPopover
 * @description
 *
 *     Shows a list of applicable filtering operators along with the value. On click gonna pass
 *     selected operator up the chain.
 */
const componentName = 'log-filter-number-popover';

const operators = [
        '=',
        '>',
        '>=',
        '!=',
        '<',
        '<=',
];

class LogFilterNumberPopoverController {
    constructor($element) {
        this.$element = $element;
    }

    $onInit() {
        this.$element.addClass(componentName);
        this.operators = operators;
    }

    getLabel(operator) {
        return `${operator} ${this.value}`;
    }
}

LogFilterNumberPopoverController.$inject = [
        '$element',
];

angular.module('aviApp').component('logFilterNumberPopover', {
    bindings: {
        onClick: '&',
        value: '<',
    },
    controller: LogFilterNumberPopoverController,
    template:
            `<a ng-repeat="operator in ::$ctrl.operators track by $index"
                ng-click="$ctrl.onClick({operator: operator})"
                class="${componentName}_filter">
                {{::$ctrl.getLabel(operator)}}</a>`,
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'cloud';
const componentName = 'vcenter-server-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const nameLabel = `${prefix}.nameLabel`;
export const cloudSelectLabel = `${prefix}.cloudSelectLabel`;
export const cloudSelectPlaceholder = `${prefix}.cloudSelectPlaceholder`;
export const credentialSectionHeader = `${prefix}.credentialSectionHeader`;
export const vCenterAddressLabel = `${prefix}.vCenterAddressLabel`;
export const vCenterNotSetPlaceholder = `${prefix}.vCenterNotSetPlaceholder`;
export const vCenterCredentialsSelectLabel = `${prefix}.vCenterCredentialsSelectLabel`;
export const contentLibrarySelectLabel = `${prefix}.contentLibrarySelectLabel`;
export const contentLibrarySelectPlaceholder = `${prefix}.contentLibrarySelectPlaceholder`;
export const contentLibrarySelectHelperText = `${prefix}.contentLibrarySelectHelperText`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit vCenter Server: {0}',
    [modalTitleNew]: 'New vCenter Server: {0}',
    [sectionTitleGeneral]: 'General',
    [nameLabel]: 'Name',
    [cloudSelectLabel]: 'Cloud',
    [cloudSelectPlaceholder]: 'Select Cloud',
    [credentialSectionHeader]: 'Credentials',
    [vCenterNotSetPlaceholder]: 'Not Set',
    [vCenterAddressLabel]: 'vCenter Address',
    [vCenterCredentialsSelectLabel]: 'vCenter Credentials',
    [contentLibrarySelectLabel]: 'Content Library',
    [contentLibrarySelectPlaceholder]: 'Select Content Library',
    [contentLibrarySelectHelperText]: 'Connect credentials to populate Content Libraries',
};

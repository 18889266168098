/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { any } from 'underscore';
import { IAviDropdownOption } from './avi-dropdown.types';

/**
 * Returns the value from a dropdownOption.
 */
export const getValue = (dropdownOption: IAviDropdownOption): IAviDropdownOption['value'] => {
    return dropdownOption.value;
};

/**
 * Returns a string to be used as the dropdown option label.
 */
export const getDisplayLabel = (dropdownOption: IAviDropdownOption): string => {
    return dropdownOption.label || getValue(dropdownOption).toString();
};

/**
 * Returns true if a list of dropdownOptions contains a particular dropdownOption, compared by
 * value.
 */
export const containsDropdownOptionByValue = (
    dropdownOption: IAviDropdownOption,
    dropdownOptions: IAviDropdownOption[] = [],
): boolean => {
    const optionValue = getValue(dropdownOption);

    return any(
        dropdownOptions,
        (selectedDropdownOption: IAviDropdownOption): boolean => {
            return getValue(selectedDropdownOption) === optionValue;
        },
    );
};

/**
 * Returns the height of the entire list of options, given the number of options, height of each
 * option, and the maximum allowable height of the list of options.
 */
export const getOptionsHeight = (options = 0, optionHeight = 0, maxHeight: number): number => {
    const optionsHeight = Math.max(optionHeight, options * optionHeight);

    return Math.min(maxHeight, optionsHeight);
};

/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { IAzureUserPassCredentials } from 'generated-types';
import { IMessageItemData, MessageItem } from 'ajs/modules/data-model';

interface IAzureUserPassCredentialsData extends IMessageItemData {
    config: IAzureUserPassCredentials;
}

export class AzureUserPassCredentialsConfigItem extends MessageItem {
    public data: IAzureUserPassCredentialsData;

    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'AzureUserPassCredentials',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public isValid(): boolean {
        return this.canFlatten();
    }

    /** @override */
    public canFlatten(): boolean {
        return Boolean(this.config.username);
    }

    /**
     * Deletes configured fields.
     */
    public clearFields(): void {
        delete this.config.username;
        delete this.config.password;
        delete this.config.tenant_name;
    }
}

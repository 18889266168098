/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  SensitiveLogProfileConfig
 * @description
 *     SensitiveLogProfileConfig, inherits PolicyConfigItem class,
 *     mapped to the protobuf message SensitiiveLogProfile.
 */
function sensitiveLogProfileConfigFactory(
        PolicyConfigItem, SensitiveFieldRuleConfig,
) {
    class SensitiveLogProfileConfig extends PolicyConfigItem {}

    angular.extend(SensitiveLogProfileConfig.prototype, {
        type: 'SensitiveLogProfile',
        windowElement_: 'sensitive-field-rule-modal',
        ruleClass_: SensitiveFieldRuleConfig,
        rulesPropertyName_: 'header_field_rules',
    });

    return SensitiveLogProfileConfig;
}

sensitiveLogProfileConfigFactory.$inject = [
        'PolicyConfigItem',
        'SensitiveFieldRuleConfig',
];

angular.module('aviApp').factory(
    'SensitiveLogProfileConfig', sensitiveLogProfileConfigFactory,
);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name IpAddrMatchConfig
 * @description IpAddrMatchConfig MatchConfigItem class.
 */
const ipAddrMatchConfigFactory = (
        ConfigItem, MatchConfigItem, schemaService, getSubnetString,
) => {
    class IpAddrMatchConfig extends MatchConfigItem {
        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            config.match_criteria = config.match_criteria || 'IS_IN';
            config.addrs = config.addrs || [];
            config.ranges = config.ranges || [];
            config.prefixes = config.prefixes || [];
            config.group_refs = config.group_refs || [];
        }

        /** @override */
        dataToSave() {
            const configCopy = angular.copy(this.getConfig());
            let config = ConfigItem._removeEmptyRepeated(configCopy);

            if (!config.addrs && !config.ranges && !config.prefixes && !config.group_refs) {
                config = undefined;
            }

            return config;
        }

        /** @override */
        toString() {
            const
                {
                    match_criteria: matchCriteria,
                    addrs,
                    ranges,
                    prefixes,
                    group_refs: groupRefs,
                } = this.getConfig(),
                value = schemaService.getEnumValue('MatchOperation', matchCriteria).label,
                resultList = [];

            if (addrs) {
                addrs.forEach(
                    item => resultList.push(item.addr),
                );
            }

            if (ranges) {
                ranges.forEach(item => {
                    resultList.push(`${item.begin.addr}-${item.end.addr}`);
                });
            }

            if (prefixes) {
                prefixes.forEach(
                    item => resultList.push(getSubnetString(item)),
                );
            }

            if (groupRefs) {
                groupRefs.forEach(item => {
                    resultList.push(`group ${item.name() || item.slug()}`);
                });
            }

            return `${value.toLowerCase()} (${resultList.join(', ')})`;
        }
    }

    angular.extend(IpAddrMatchConfig.prototype, {
        type: 'IpAddrMatch',
        name: 'Client IP',
    });

    return IpAddrMatchConfig;
};

ipAddrMatchConfigFactory.$inject = [
        'ConfigItem',
        'MatchConfigItem',
        'schemaService',
        'getSubnetString',
];

angular.module('aviApp').factory('IpAddrMatchConfig', ipAddrMatchConfigFactory);

const moduleName = 'waf';
const componentName = 'waf-policy-application-rules';
const prefix = `${moduleName}.${componentName}`;

export const addLabel = `${prefix}.addLabel`;
export const applicationRulesHeader = `${prefix}.applicationRulesHeader`;
export const applicationNameLabel = `${prefix}.applicationNameLabel`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const lastUpdateVersionLabel = `${prefix}.lastUpdateVersionLabel`;
export const numberOfRulesLabel = `${prefix}.numberOfRulesLabel`;
export const emptyGridPlaceholder = `${prefix}.emptyGridPlaceholder`;

export const ENGLISH = {
    [addLabel]: 'Add',
    [applicationRulesHeader]: 'Application Rules ({0})',
    [applicationNameLabel]: 'Application Name',
    [removeButtonLabel]: 'Remove',
    [lastUpdateVersionLabel]: 'Last Update Version',
    [numberOfRulesLabel]: '# of Rules',
    [emptyGridPlaceholder]: 'No Application Selected!',
};

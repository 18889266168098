/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './analytics-http-resp-codes.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  analyticsHttpRespCodes
 * @description
 *     Responsible for passing 3 types of HTTP response codes to the onUpdateCodes function. Parses
 *     input string and formats code data from the backend.
 * @param {AnalyticsProfile.config} config
 * @param {function} onUpdateCodes - Called when user updates input field.
 */
class Controller {
    constructor(
        Regex,
        schemaService,
        l10nService,
    ) {
        this.Regex_ = Regex;

        this.codeBlockEnumHash_ =
            schemaService.getEnumValues('AnalyticsProfileRespCodeBlock')
                .reduce((hash, { value, label }) => {
                    hash[label] = value;

                    return hash;
                }, {});

        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Called when digest life cycle of the component is called.
     */
    $onChanges() {
        this.ngModel = this.codeFormatter();
    }

    /**
     * Called on ng-change of input. Parses input for an object of response codes to be passed
     * to the onUpdateCodes function.
     */
    handleInputChange() {
        const output = {
            exclude_http_error_codes: [],
            ranges: [],
            resp_code_block: [],
        };

        if (!angular.isUndefined(this.ngModel)) {
            const codes = this.ngModel.replace(/\s+/g, '').split(',');

            codes.forEach(code => {
                const result = this.codeParser(code);

                if (!angular.isUndefined(result)) {
                    output[result.type].push(result.value);
                }
            });
        }

        this.onUpdateCodes({
            respCodes: output,
        });
    }

    /**
     * Parses code, determining whether it's a single code, code range, or code block. Returns
     * an object with type and value properties.
     * @param  {string} code - HTTP Response code string.
     * @return {Object}
     */
    codeParser(code) {
        if (this.Regex_.listOfStatusCodeOrRanges.test(code)) {
            // Range
            if (code.indexOf('-') !== -1) {
                const
                    range = code.split('-'),
                    begin = +range[0],
                    end = +range[1];

                return {
                    type: 'ranges',
                    value: {
                        begin,
                        end,
                    },
                };
            // Single code
            } else {
                return {
                    type: 'exclude_http_error_codes',
                    value: +code,
                };
            }
        // Code block
        } else if (code.toUpperCase() in this.codeBlockEnumHash_) {
            return {
                type: 'resp_code_block',
                value: this.codeBlockEnumHash_[code.toUpperCase()],
            };
        }
    }

    /**
     * Formats HTTP Response code properties from the backend to return a comma-separated
     * string.
     * @return {string}
     */
    codeFormatter() {
        const output = [];

        if (angular.isArray(this.config.exclude_http_error_codes)) {
            output.push(...this.config.exclude_http_error_codes);
        }

        if (angular.isArray(this.config.ranges)) {
            this.config.ranges.forEach(({ begin, end }) => {
                output.push(`${begin}-${end}`);
            });
        }

        if (angular.isArray(this.config.resp_code_block)) {
            this.config.resp_code_block.forEach(block => {
                output.push(block.enumeration('AP_HTTP_RSP_').toUpperCase());
            });
        }

        return output.join(', ');
    }
}

Controller.$inject = [
    'Regex',
    'schemaService',
    'l10nService',
];

angular.module('aviApp').component('analyticsHttpRespCodes', {
    bindings: {
        config: '<',
        onUpdateCodes: '&',
    },
    controller: Controller,
    template:
        `<avi-form-item
            object-type="AnalyticsProfile"
            field-name="exclude_http_error_codes"
            label="{{ ::$ctrl.l10nKeys.excludeHttpStatusCodesFromErrorClassificationInputLabel | 
                   vtranslate }}"
        >
            <input
                type="text"
                class="sel-http-status-code"
                ng-model="$ctrl.ngModel"
                ng-change="$ctrl.handleInputChange()"
                placeholder="400, 401-408, 5XX"
            />
        </avi-form-item>`,
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'error-page-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const editErrorPageProfileHeader = `${prefix}.editErrorPageProfileHeader`;
export const newErrorPageProfileHeader = `${prefix}.newErrorPageProfileHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const errorPagesHeader = `${prefix}.errorPagesHeader`;
export const indexInputLabel = `${prefix}.indexInputLabel`;
export const minutesInnerText = `${prefix}.minutesInnerText`;
export const enabledInputLabel = `${prefix}.enabledInputLabel`;
export const htmlBodyInputLabel = `${prefix}.htmlBodyInputLabel`;
export const redirectToUrlInputLabel = `${prefix}.redirectToUrlInputLabel`;
export const pageBodyInputPlaceholder = `${prefix}.pageBodyInputPlaceholder`;
export const pageBodyCreateInputLabel = `${prefix}.pageBodyCreateInputLabel`;
export const redirectUrlInputPlaceholder = `${prefix}.redirectUrlInputPlaceholder`;
export const removePageButtonLabel = `${prefix}.removePageButtonLabel`;
export const addPageButtonLabel = `${prefix}.addPageButtonLabel`;

export const ENGLISH = {
    [editErrorPageProfileHeader]: 'Edit Error Page Profile: {0}',
    [newErrorPageProfileHeader]: 'New Error Page Profile: {0}',
    [nameInputLabel]: 'Name',
    [errorPagesHeader]: 'Error Pages',
    [indexInputLabel]: 'Index',
    [minutesInnerText]: 'min',
    [enabledInputLabel]: 'Enabled',
    [htmlBodyInputLabel]: 'HTML Body',
    [redirectToUrlInputLabel]: 'Redirect to URL',
    [pageBodyInputPlaceholder]: 'Select Page Body',
    [pageBodyCreateInputLabel]: 'Create Page Body',
    [redirectUrlInputPlaceholder]: 'Redirect URL',
    [removePageButtonLabel]: 'Remove Page',
    [addPageButtonLabel]: '+ Add Page',
};

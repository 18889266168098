const moduleName = 'ajs-legacy';
const componentName = 'user-card';
const prefix = `${moduleName}.${componentName}`;

export const myAccountButtonLabel = `${prefix}.myAccountButtonLabel`;
export const lastLoginLabel = `${prefix}.lastLoginLabel`;

export const ENGLISH = {
    [myAccountButtonLabel]: 'My Account',
    [lastLoginLabel]: 'Last Login: {0}',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name  virtualserviceVipAzure
 * @description
 *     Allows the user to select a network and subnet for a Virtual Service in the AWS cloud.
 * @param {VsVip} vsVip
 * @param {Cloud} cloud
 */
class VirtualserviceVipAzureController {
    constructor(
        Regex,
        SubnetListNetworkCollection,
    ) {
        this.Regex = Regex;
        this.SubnetListNetworkCollection_ = SubnetListNetworkCollection;
    }

    $onInit() {
        const vip0 = this.vsVip.getVip(0);
        const vip0Config = vip0.getConfig();
        const vipConfig = this.vsVip.getConfig();
        const { auto_allocate_floating_ip: autoAllocateFloatingIp } = vip0Config;

        const networkCollectionParams = {
            auto_allocate_only: true,
            cloud_uuid: this.cloud.id,
            fip_capable: autoAllocateFloatingIp || undefined,
        };

        const { vrf_context_ref: vrfContextRef } = vipConfig;

        if (vrfContextRef) {
            networkCollectionParams.vrf_context_uuid = vrfContextRef.slug();
        }

        this.subnetListNetworkCollection = new this.SubnetListNetworkCollection_({
            params: networkCollectionParams,
        });
    }

    /**
     * Sets subnetListNetworkCollection to get only fip_capable networks if Elastic IP is
     * selected.
     */
    handleAssignElasticIpChange() {
        const vip0 = this.vsVip.getVip(0);
        const vip0Config = vip0.getConfig();
        const { auto_allocate_floating_ip: autoAllocateFloatingIp } = vip0Config;

        this.vsVip.setAutoAllocateFloatingIpForAllVips(autoAllocateFloatingIp);
        this.subnetListNetworkCollection.setParams({
            fip_capable: autoAllocateFloatingIp || undefined,
        });
    }

    /**
     * Sets the "auto_allocate_ip" boolean along with the "subnet_uuid" when the network is
     * selected.
     * @param {VipConfig} vip - VirtualService vip object.
     * @param {SubnetListNetwork} selected
     */
    handleNetworkChange(vip, selected) {
        if (angular.isUndefined(selected)) {
            return;
        }

        const subnet = selected.data.config.subnet[0];

        vip.setAutoAllocateIp(true);
        vip.setIpAddr(undefined);
        vip.setVipSubnet(subnet.prefix, subnet.uuid);
    }

    /**
     * Handler for clicking Add Vip. Adds a Vip object to the list of vips.
     */
    handleAddVip() {
        this.vsVip.addVip();
    }

    /** @override */
    $onDestroy() {
        this.subnetListNetworkCollection.destroy();
    }
}

VirtualserviceVipAzureController.$inject = [
    'Regex',
    'SubnetListNetworkCollection',
];

angular.module('aviApp').component('virtualserviceVipAzure', {
    bindings: {
        vsVip: '<',
        cloud: '<',
    },
    controller: VirtualserviceVipAzureController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-vip-address/' +
        'virtualservice-vip-azure/virtualservice-vip-azure.html',
});

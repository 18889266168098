/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

/* eslint max-len: [error, { code: 100, ignoreComments: true }] */

import {
    HookMatchCriteria,
    HookRegOptions,
    StateService,
    TargetState,
    Transition,
    TransitionHookFn,
    TransitionService,
} from '@uirouter/core';

import { appStates } from 'ajs/js/constants/app-config/app-state.constants';

import { MessageOfTheDayComponent } from 'ng/modules/notification';

import {
    FullModalService,
    NotificationService,
} from 'ng/modules/core';

import {
    TRANS_ERROR_CONTROLLER_DOWN,
    TRANS_ERROR_INSUFFICIENT_PERMISSIONS,
    TRANS_ERROR_UNNECESSARY_LOGIN,
    TRANS_ERROR_UNNECESSARY_WELCOME,
    TRANS_ERROR_USER_NOT_LOGGED_IN,
} from './app-state-handler.const';

import {
    Auth,
    AviAlertService,
    AviModalService,
    HorizonIframeService,
    InitialDataService,
    IStateParams,
    StateManager,
} from '..';

const {
    LOGIN_STATE,
    PRE_WELCOME_AWS_STATE,
    WELCOME_STATE,
    CONTROLLER_DOWN_STATE,
    ADMIN_USER_SETUP_STATE,
} = appStates;

// arbitrary numbers to spread hooks out via priority
const HOOK_PRIORITY_LOW = 100; // generic hooks
const HOOK_PRIORITY_NORMAL = 200; // all normal/regular hooks
const HOOK_PRIORITY_HIGH = 300; // app initialization hooks

const SAML_LOGIN_URL = '/sso/login';

const ON_BEFORE_HOOK_TYPE = 'onBefore';
const ON_ERROR_HOOK_TYPE = 'onError';

const TRANSITION_REDIRECTS_LIMIT = 10;
const INFINITE_REDIRECTS_ERROR_MESSAGE =
    `Potential infinite redirect loop. Hit ${TRANSITION_REDIRECTS_LIMIT} subsequent redirects.`;

/**
 * @constructor
 * @author Alex Malitsky
 * @desc
 *
 *   Sets app state change events. Initialized via init method call from app.js
 *
 *   Redirects are handled by error handler only on purpose - to introduce better separation of
 *   concerns and to facilitate better logging capabilities.
 *
 *   Guards throwing errors or delaying transitions (to do some initialization or cleanup) are
 *   fine however error handlers "fixing" previously cancelled transition (by emitting another
 *   transition from error handler) are of no good.
 *
 *   We need to have minimal amount of such "fixers" but for app initialization phase those are
 *   necessary since there is no explicit caller meaning no custom code which would be able
 *   to take care of failing transition.
 *
 * @todo rename guards to hooks
 * @todo remove underscores
 * @todo add guard for TRANSITION_REDIRECTS_LIMIT hit error. Error handler won't do
 *
 * @see {@link https://ui-router.github.io/ng2/docs/latest/classes/__uirouter_core_src_transition_transitionservice_.transitionservice.html UI router docs}
 */
export class AppStateHandler {
    private initialized = false;

    /**
     * Counter to prevent infinite redirect loop.
     * Incremented on every transition redirect from guard/hook, reset on any successful
     * transition.
     *
     * Limited by TRANSITION_REDIRECTS_LIMIT.
     */
    private redirectCounter = 0;

    constructor(
        private readonly $state: StateService,
        private readonly $transitions: TransitionService,
        private readonly authService: Auth,
        private readonly timeframeService: any,
        private readonly aviMessageService: any,
        private readonly aviModalService: AviModalService,
        private readonly aviAlertService: AviAlertService,
        private readonly stateManagerService: StateManager,
        private readonly notificationService: NotificationService,
        private readonly fullModalService: FullModalService,
        private readonly initialDataService: InitialDataService,
        private readonly windowLocation: Window['location'],
        private readonly horizonIframeService: HorizonIframeService,
    ) {}

    /**
     * Sets up transition hooks. To be called by app run.
     */
    public init(): void {
        if (this.initialized) {
            throw Error('already initialized');
        }

        this.initialized = true;

        // call order does not matter
        this.setGlobalHooks();

        this.setAppInitHooks();

        this.setBeforeLoginStateHooks();

        this.setBeforeWelcomeStateHooks();

        this.setBeforeAuthStateHooks();

        this.setFromAuthToNonAuthStateHooks();

        this.setOnSuccessAuthStateHooks();
    }

    /**
     * Set generic state change success and failure event hooks.
     */
    private setGlobalHooks(): void {
        const { $transitions } = this;

        $transitions.onSuccess(
            {},
            this.onTransitionSuccess,
            { priority: HOOK_PRIORITY_LOW },
        );

        const errorHooks = [
            this.onTransitionError,
        ];

        this.setTransitionHooks(
            errorHooks,
            ON_ERROR_HOOK_TYPE,
            {},
            HOOK_PRIORITY_LOW,
        );
    }

    /**
     * Set app initialization hooks. These are supposed to fire only once per UI session
     * regardless of login/logouts.
     * Pretty much a hack/workaround since I couldn't figure API to pause ui-router until required
     * data is loaded via AJAX calls.
     *
     * Throwing errors in these to be handled later(!) by transition error event listeners
     * is fine here because there is no `then` or `catch` callback attached on initial app
     * transition (to the best of my knowledge).
     *
     * Do the following:
     *   - load initialData required for other hooks
     *   - try to authenticate with user profile from the local storage
     *   - special POST for login API if user is authenticated and redirected back to UI
     *     from SAML provider:
     *   - attempt to authenticate with sessionId and csrfToken passed as query params
     *     (openstack horizon iframe mode).
     *   - check whether welcome flow got completed and redirect if not
     * @todo Consider using urlService.defer for those
     */
    private setAppInitHooks(): void {
        const initialTransitionCriteria = { from: '' };

        const hooks = [
            this.loadInitialData,
            this.retrieveUserProfileFromStorage,
            this.loginWithSamlSession,
            this.loginWithHorizon,
            this.initialUserSetupGuard,
        ] as unknown as TransitionHookFn[];

        this.setTransitionHooks(
            hooks,
            ON_BEFORE_HOOK_TYPE,
            initialTransitionCriteria, // special case for app initialization
            HOOK_PRIORITY_HIGH,
        );

        const errorHooks = [
            this.onInitialTransitionHookError,
        ];

        this.setTransitionHooks(
            errorHooks,
            ON_ERROR_HOOK_TYPE,
            initialTransitionCriteria,
            HOOK_PRIORITY_HIGH,
        );
    }

    /**
     * Set hooks for auth to non auth state transition.
     * Such transitions should be always allowed (no exceptions/redirects).
     * Please note that there is a flow where we require welcome after(!) login.
     * This hook should not affect that flow cause redirect happens right after the login.
     */
    private setFromAuthToNonAuthStateHooks(): void {
        this.$transitions.onBefore(
            {
                from: 'authenticated.**',
                to: ({ name }) => !name.split('.').includes('authenticated'),
            },
            this.failSafeLogout as unknown as TransitionHookFn,
            { priority: HOOK_PRIORITY_NORMAL },
        );
    }

    /**
     * Set before hooks for transition to any auth state.
     * Before every "authenticated" state transition:
     *   - check if user is logged in
     *   - make sure "tenantName" param is set
     *   - check if user has permission to visit the state
     */
    private setBeforeAuthStateHooks(): void {
        const beforeHooks = [
            this.loggedInGuard,
            this.tenantParamGuard,
            this.userPermissionsGuard,
        ];

        this.setTransitionHooks(
            beforeHooks,
            ON_BEFORE_HOOK_TYPE,
            { to: 'authenticated.**' },
            HOOK_PRIORITY_NORMAL,
        );
    }

    /**
     * Set onSuccess hooks for authorization required states.
     * After every successful "authenticated" state transition:
     *   - change time-frame group (when applicable)
     *   - render "message of the day message" - only once, after login
     */
    private setOnSuccessAuthStateHooks(): void {
        const { $transitions } = this;

        $transitions.onSuccess(
            { to: ({ params }) => 'timeframe' in params },
            this.handleTimeFrameGroupSwitch,
            { priority: HOOK_PRIORITY_NORMAL },
        );

        // TODO: move to appState tree
        $transitions.onSuccess(
            {
                from: ({ name }) => !name.split('.').includes('authenticated'),
                to: 'authenticated.**',
            },
            this.messageOfTheDayCheckAndDisplay,
            { priority: HOOK_PRIORITY_NORMAL },
        );
    }

    /**
     * Set before hooks for the login state.
     */
    private setBeforeLoginStateHooks(): void {
        const onBeforeHooks = [
            this.loggedInOnLoginGuard,
            this.samlAuthRedirectGuard,
        ];

        this.setTransitionHooks(
            onBeforeHooks,
            ON_BEFORE_HOOK_TYPE,
            { to: LOGIN_STATE },
            HOOK_PRIORITY_NORMAL,
        );
    }

    /**
     * Set before hooks for welcome state.
     * Do the following:
     *   - check if welcome hadn't been completed
     */
    private setBeforeWelcomeStateHooks(): void {
        const { $transitions } = this;

        $transitions.onBefore(
            {
                to({ name }) {
                    switch (name) {
                        case WELCOME_STATE:
                        case ADMIN_USER_SETUP_STATE:
                        case PRE_WELCOME_AWS_STATE:
                            return true;
                    }

                    return false;
                },
            },
            this.welcomeNotAllowedGuard,
            { priority: HOOK_PRIORITY_NORMAL },
        );
    }

    /**
     * Set hooks passed on hookType event for the matchCriteria starting with priority passed.
     * @param hooks
     * @param hookType - i.e. 'onSuccess' or 'onBefore'
     * @param matchCriteria
     * @param priority
     */
    private setTransitionHooks(
        hooks: TransitionHookFn[],
        hookType: string,
        matchCriteria: HookMatchCriteria,
        priority: HookRegOptions['priority'],
    ): void {
        const { $transitions } = this;

        hooks.forEach((
            hook: TransitionHookFn,
            index: number,
        ) => {
            $transitions[hookType](
                matchCriteria,
                hook,
                { priority: priority - index }, // decreasing priority
            );
        });
    }

    /**
     * Event handler for app state transition success.
     * Remove the backdrop on the first call and save the last active state
     * to the sessionStorage for "authenticated" states.
     * @param transition
     */
    private onTransitionSuccess = (transition: Transition): void => {
        const toState = transition.to();

        const { name: targetStateName } = toState;
        const targetStatePathChunks = targetStateName.split('.');

        if (targetStatePathChunks.includes('authenticated')) {
            const { authService } = this;
            const toParams = transition.params('to');

            authService.saveLastActiveState(targetStateName, toParams as IStateParams);
        }

        this.redirectCounter = 0;
    };

    /**
     * Event handler for the initial(!) app state transition error caused by our own hooks/guards.
     * Fixing failing transition with redirect is not great idea cause transition initiator
     * (service or component calling state.go) might have its own idea on how failing transition
     * has to be "fixed". Hence global "fixers" for failing transition is no go.
     *
     * However for initial app transition there is no explicit "caller" and hence no place to add
     * custom error handling code. That's the only reason why we have this method in the first
     * place.
     *
     * We can't use local `catch()` listeners here since then we could hit the issue
     * described above.
     *
     * @param transition
     */
    private onInitialTransitionHookError = (transition: Transition): void => {
        const { detail } = transition.error();

        // check for error to be of our own custom type
        if (!(detail instanceof Error)) {
            return;
        }

        if (this.redirectCounter >= TRANSITION_REDIRECTS_LIMIT) {
            this.redirectCounter = 0;
            console.error(INFINITE_REDIRECTS_ERROR_MESSAGE);

            return;
        }

        const toState = transition.to();
        const toParams = transition.params();
        const { name: fromStateName } = transition.from();

        console.warn(
            `Transition hook error from: '${fromStateName}' to '${toState.name}'.`,
        );

        const {
            $state,
            authService,
            stateManagerService,
        } = this;

        const { message: errorType } = detail;

        switch (errorType) {
            case TRANS_ERROR_INSUFFICIENT_PERMISSIONS: {
                const allowedState = stateManagerService.getClosestAllowedState(toState, toParams);

                this.redirectCounter++;

                // no .catch() by design
                $state.go(allowedState.name);

                break;
            }

            case TRANS_ERROR_USER_NOT_LOGGED_IN: {
                this.redirectCounter++;

                authService.logout(true, true);

                break;
            }

            case TRANS_ERROR_UNNECESSARY_WELCOME: {
                const isLoggedOut = !authService.isLoggedIn();

                this.redirectCounter++;

                if (isLoggedOut) {
                    authService.logout(true, true);
                } else {
                    // no .catch() by design
                    $state.go(appStates.DEFAULT_STATE);
                }

                break;
            }

            case TRANS_ERROR_UNNECESSARY_LOGIN: {
                const targetState = this.authService.getAfterLoginTargetState();

                // no .catch() by design
                $state.go(targetState.name(), targetState.params());

                break;
            }

            case TRANS_ERROR_CONTROLLER_DOWN: {
                $state.go(CONTROLLER_DOWN_STATE);

                break;
            }
        }
    };

    /**
     * Event handler for app state transition error not caused by transition hooks.
     * For instance when dependency to be resolved fails to load failure will be handled here.
     * @param transition
     * @see https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html#defaulterrorhandler
     */
    private onTransitionError = (transition: Transition): void => {
        const { detail } = transition.error();

        // we aren't handling hook errors here cause there seems to be no need to
        if (detail instanceof Error) {
            return;
        }

        if (this.redirectCounter >= TRANSITION_REDIRECTS_LIMIT) {
            this.redirectCounter = 0;
            console.error(INFINITE_REDIRECTS_ERROR_MESSAGE);

            return;
        }

        const { name: fromStateName } = transition.from();
        const { name: toStateName } = transition.to();

        console.warn(
            `Transition error from: '${fromStateName}' to '${toStateName}'`,
        );

        // some errors (i.e. "ignored transition") don't have details
        if (!detail) {
            return;
        }

        const {
            authService,
            $state,
        } = this;

        switch (detail.status) {
            case 401:
                this.redirectCounter++;

                authService.logout(true, true);

                return;

            // TODO 403 should never happen since we check permission before loading the state
            // TODO 404 could happen but should we use stateManagerService.getClosestAllowedState?
            case 403:
            case 404: {
                const {
                    aviMessageService,
                    aviModalService,
                    fullModalService,
                    aviAlertService,
                    notificationService,
                } = this;

                aviMessageService.destroyAll();
                aviModalService.destroyAll();
                notificationService.removeAll();
                fullModalService.removeAllModals();
                aviAlertService.clear();

                this.redirectCounter++;

                $state.go(appStates.DEFAULT_STATE)
                    .catch(() => {
                        authService.logout(true);
                    });

                break;
            }
        }
    };

    /**
     * Check if user is logged in and throw if not.
     * @throws {Error<TRANS_ERROR_USER_NOT_LOGGED_IN>} - When user is not logged in.
     */
    private loggedInGuard = (): void => {
        if (!this.authService.isLoggedIn()) {
            throw Error(TRANS_ERROR_USER_NOT_LOGGED_IN);
        }
    };

    /**
     * Try to set profile from the local storage and don't complain if that didn't happen.
     * @param transition
     */
    private retrieveUserProfileFromStorage =
    (transition: Transition): ng.IPromise<void> | undefined => {
        const { authService } = this;

        if (!authService.isLoggedIn() && Auth.haveStoredProfile()) {
            const { tenantName } = transition.params();

            // no issue when fails, following transition hooks should take care
            return authService.setUserProfileFromLocalStorage(tenantName)
                .catch(() => undefined);
        }
    };

    /**
     * Throw if user doesn't have access to target page based on permissions assigned.
     * @param transition
     * @throws {Error<TRANS_ERROR_INSUFFICIENT_PERMISSIONS>} - When user doesn't have sufficient
     *   permissions to access the state.
     */
    private userPermissionsGuard = (transition: Transition): void => {
        const toState = transition.to();
        const { data } = toState;

        // nothing to check case
        if (!data || !('permission' in data)) {
            return;
        }

        const toParams = transition.params();

        if (!this.stateManagerService.isAllowedState(toState, toParams)) {
            throw Error(TRANS_ERROR_INSUFFICIENT_PERMISSIONS);
        }
    };

    /**
     * Throw if tenant is not set in transition params or if tenant set in params doesn't match
     * the tenant set in auth service.
     * @param transition
     */
    private tenantParamGuard = (transition: Transition): TargetState | undefined => {
        const toParams = transition.params();
        const { tenantName: toTenant } = toParams;
        const currentTenant = this.authService.getTenantName();

        if (!toTenant || toTenant !== currentTenant) {
            const { $state } = this;
            const { name: toStateName } = transition.to();

            console.log('tenantName redirect');

            this.redirectCounter++;

            return $state.target(
                toStateName,
                {
                    ...toParams,
                    tenantName: currentTenant,
                },
            );
        }
    };

    /**
     * Check whether initial user setup was completed and throw if not.
     * Requires initial-data to be loaded.
     *
     * App instantiation hook only, doesn't throw error but redirects when required.
     */
    private initialUserSetupGuard = (transition: Transition): TargetState | undefined => {
        const { name: toStateName } = transition.to();

        // Return if toState is one of initialUserSetup states
        // to avoid infinite loop.
        if (
            toStateName === WELCOME_STATE ||
            toStateName === PRE_WELCOME_AWS_STATE ||
            toStateName === ADMIN_USER_SETUP_STATE
        ) {
            return;
        }

        const { initialDataService } = this;

        if (initialDataService.isAdminUserSetupRequired) {
            let targetState = ADMIN_USER_SETUP_STATE;

            if (initialDataService.isAwsCloud) {
                targetState = PRE_WELCOME_AWS_STATE;
            }

            this.redirectCounter++;

            return this.$state.target(targetState);
        }
    };

    /**
     * Check if welcome flow is required and throw if not.
     * @throws {Error<TRANS_ERROR_UNNECESSARY_WELCOME>} - Throws if welcome flow had been completed.
     */
    private welcomeNotAllowedGuard = (): void => {
        const {
            authService,
            initialDataService,
        } = this;

        if (
            !initialDataService.isAdminUserSetupRequired &&
            (initialDataService.isWelcomeWorkflowCompleted || !authService.isLoggedIn())
        ) {
            throw Error(TRANS_ERROR_UNNECESSARY_WELCOME);
        }
    };

    /**
     * Log user out. If logout API call fails (i.e. had been performed already) falls back to UI
     * only logout.
     *
     * Won't perform any state routing.
     */
    private failSafeLogout = (): ng.IPromise<void> | undefined => {
        const { authService } = this;

        if (authService.isLoggedIn()) {
            return authService.failSafeLogout();
        }
    };

    /**
     * Log in and set up user's profile based on query params received from URL.
     * OS Horizon iframe scenario. If this is not Horizon Iframe setup don't complain.
     * @return Undefined when not applicable (ok case).
     */
    private loginWithHorizon = (): ng.IPromise<TargetState> | undefined => {
        const { authService } = this;

        if (!this.horizonIframeService.active || authService.isLoggedIn()) {
            return;
        }

        // no .catch() by design
        return authService.horizonIframeLogin().then(() => {
            return authService.getAfterLoginTargetState();
        });
    };

    /**
     * Log in and set up user profile based on session and token received from SAML
     * authentication provider. Don't complain if not applicable.
     * Use case: user had been redirected back to UI from SAML provider website.
     * @todo catch error is last active state is unavailable
     */
    private loginWithSamlSession = (): ng.IPromise<TargetState> | undefined => {
        const { authService } = this;

        if (!this.initialDataService.isSsoLoggedIn || authService.isLoggedIn()) {
            return;
        }

        return authService.loginWithActiveSamlSession()
            .then(() => {
                return authService.getAfterLoginTargetState();
            });
        // no .catch() by design
    };

    /**
     * Switch timeframe group upon successful transition (when applicable).
     * @param transition
     */
    private handleTimeFrameGroupSwitch = (transition: Transition): void => {
        const toState = transition.to();
        const toStateParams = transition.params();
        const { params: stateParams } = toState.$$state();
        const isTimeframeState = 'timeframe' in stateParams;

        if (!isTimeframeState) {
            return;
        }

        const { timeframeService } = this;

        const timeframeGroupToSelect = toState.data && toState.data.tfGroup || 'default';

        if (timeframeService.selectedGroupId === timeframeGroupToSelect) {
            return;
        }

        timeframeService.setGroup(
            timeframeGroupToSelect,
            toStateParams.timeframe,
            'appStateHandler',
        );
    };

    /**
     * Load initial data via API call.
     * Required for the rest of transition hooks.
     * Highest priority within an app.
     * @throws TRANS_ERROR_CONTROLLER_DOWN exception when loadData call fails.
     */
    private loadInitialData = (transition: Transition): Promise<any> | undefined => {
        const { initialDataService } = this;
        const toState = transition.to();

        const { name: targetStateName } = toState;

        if (initialDataService.hasData() || targetStateName === CONTROLLER_DOWN_STATE) {
            return;
        }

        return initialDataService.loadData()
            .catch(() => {
                throw Error(TRANS_ERROR_CONTROLLER_DOWN);
            });
    };

    /**
     * Event handler for onBefore transition to check whether SAML authentication redirect is
     * required or no.
     *
     * Will perform hard redirect if required.
     *
     * Doesn't complain when not applicable.
     */
    private samlAuthRedirectGuard = (): void => {
        const {
            authService,
            initialDataService,
        } = this;

        if (!initialDataService.isSsoEnabled || authService.localAuthRequestedForSaml) {
            return;
        }

        // we don't throw an error here since it is login screen somewhere else
        // most likely it doesn't matter cause UI will get destroyed anyway
        this.windowLocation.assign(SAML_LOGIN_URL);
    };

    /**
     * Prevents landing on login screen with active session. Happens on login URL reload.
     */
    private loggedInOnLoginGuard = (): void => {
        if (this.authService.isLoggedIn()) {
            throw Error(TRANS_ERROR_UNNECESSARY_LOGIN);
        }
    };

    /**
     * Renders "message of the day" when applicable.
     */
    private messageOfTheDayCheckAndDisplay = (): void => {
        const { messageOfTheDay } = this.authService;

        if (!messageOfTheDay) {
            return;
        }

        const { notificationService } = this;
        const notificationId = 'message-of-the-day';

        if (notificationService.has(notificationId)) {
            return;
        }

        const notificationProps = {
            id: notificationId,
            component: MessageOfTheDayComponent as any,
            componentProps: {
                message: messageOfTheDay,
            },
        };

        notificationService.add(notificationProps);
    };
}

AppStateHandler.$inject = [
    '$state',
    '$transitions',
    'Auth',
    'Timeframe',
    'AviMessage',
    'AviModal',
    'aviAlertService',
    'stateManagerService',
    'notificationService',
    'fullModalService',
    'initialDataService',
    'windowLocation',
    'horizonIframeService',
];

const moduleName = 'ajs-legacy';
const componentName = 'infra-gateway-monitor-controller';
const prefix = `${moduleName}.${componentName}`;

export const editGatewayMonitorHeader = `${prefix}.editGatewayMonitorHeader`;
export const newGatewayMonitorHeader = `${prefix}.newGatewayMonitorHeader`;
export const ipAddressToMonitorInputLabel = `${prefix}.ipAddressToMonitorInputLabel`;
export const ipAddressToMonitorInputPlaceholder = `${prefix}.ipAddressToMonitorInputPlaceholder`;
export const saveBtnLabel = `${prefix}.saveBtnLabel`;

export const ENGLISH = {
    [editGatewayMonitorHeader]: 'Edit Gateway Monitor',
    [newGatewayMonitorHeader]: 'New Gateway Monitor',
    [ipAddressToMonitorInputLabel]: 'IP Address to Monitor',
    [ipAddressToMonitorInputPlaceholder]: 'Name',
    [saveBtnLabel]: 'Save',
};

/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    Input,
} from '@angular/core';
import { debounce, pluck } from 'underscore';
import { IFuseOptions } from 'fuse.js';
import { IDragAndDropIndices } from 'ng/shared/shared.types';
import {
    WafPSMLocationConfigItem,
    WafPSMRuleConfigItem,
} from 'ajs/modules/waf';
import './waf-policy-psm-rules-config.less';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './waf-policy-psm-rules-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const fuseOptions = {
    threshold: 0.2,
    keys: [
        'config.name',
        'config.description',
        'config.match_elements.config.searchableValues',
    ],
};

/**
 * @desc Component for configuring WAF PSM rules.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-rules-config',
    templateUrl: './waf-policy-psm-rules-config.component.html',
})
export class WafPolicyPsmRulesConfigComponent {
    @Input()
    public location: WafPSMLocationConfigItem;

    @Input()
    public preventEdit: boolean;

    @Input()
    public breadcrumbs: string[] = [];

    /**
     * PSM Group UUID.
     */
    @Input()
    public psmGroupId: string;

    /**
     * List of rules filtered by the searchTerm input.
     */
    public filteredRules: WafPSMRuleConfigItem[] = [];

    /**
     * Cache the state of the search input value since Fuse is dynamically loaded.
     */
    public searchValue: string;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Fuse instance.
     */
    private fuse: Fuse<WafPSMRuleConfigItem, IFuseOptions<WafPSMRuleConfigItem>>;

    /**
     * True to show the filtered rules instead of the full list.
     */
    private showSearchResults = false;

    constructor(private l10nService: L10nService) {
        this.handleSearch = debounce(this.handleSearch, 250);
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Creates a new rule and opens the modal to edit it.
     */
    public addRule(): void {
        this.location.addRule(this.getWafPsmRuleModalBindings());
    }

    /**
     * Edits a WafPSMRule config item.
     */
    public editRule(rule: WafPSMRuleConfigItem): void {
        this.location.editRule(rule, this.getWafPsmRuleModalBindings());
    }

    /**
     * Deletes a WafPSMRuleConfigItem config item.
     */
    public deleteRule(index: number): void {
        this.location.rules.remove(index);
    }

    /**
     * Moves a WafPSMRuleConfigItem from one index to another index.
     */
    public moveRule(indices: IDragAndDropIndices): void {
        const { previousIndex, currentIndex } = indices;
        const locationRule = this.location.rules.at(previousIndex);

        this.location.rules.moveItem(locationRule, currentIndex);
    }

    /**
     * Returns a list of rules to be displayed.
     */
    public get rules(): WafPSMRuleConfigItem[] {
        return this.showSearchResults ? this.filteredRules : this.location.rules.config;
    }

    /**
     * Dynamically loads the fuse.js library.
     * A new Fuse instance is created on focus, to ensure that the search is performed on the most
     * recent list, as the list is required for creating a new instance.
     */
    public async handleSearchFocus(): Promise<void> {
        const module = await import(/* webpackChunkName: "async-fusejs" */ 'fuse.js');
        const Fuse = module.default;

        this.fuse = new Fuse(this.location.rules.config, fuseOptions);

        if (this.searchValue) {
            this.handleSearch();
        }
    }

    /**
     * Removes the Fuse instance on blur.
     */
    public handleSearchBlur(): void {
        this.fuse = undefined;
    }

    /**
     * Called on search.
     */
    public handleSearch = (): void => {
        if (this.fuse) {
            this.showSearchResults = Boolean(this.searchValue);
            this.filteredRules = pluck(this.fuse.search(this.searchValue || ''), 'item');
        }
    };

    /**
     * Returns bindings to be passed to the WAF PSM Rule modal.
     */
    private getWafPsmRuleModalBindings(): Record<string, any> {
        const breadcrumb = this.l10nService.getMessage(
            l10nKeys.locationBreadcrumb,
            [this.location.getName()],
        );

        return {
            breadcrumbs: this.breadcrumbs.concat(breadcrumb),
            preventEdit: this.preventEdit,
        };
    }
}

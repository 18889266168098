/** @module LogsModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    IGridConfig,
    IGridRow,
} from 'ajs/components/common/grid/grid-data-model';

import { IDSRequestLog } from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './out-of-band-logs.l10n';
import template from './out-of-band-logs.component.html';
import './out-of-band-logs.component.less';

/**
 * Token for outOfBandLogs component.
 */
export const OUT_OF_BAND_COMPONENT_TOKEN = 'outOfBandLogs';

export interface IDSRequestLogRow extends IGridRow {
    ds_name: {
        display: string;
        val: IDSRequestLog['ds_name'];
    };
    event: {
        display: string;
        val: IDSRequestLog['event'];
    };
    pool_name: {
        display: string;
        val: IDSRequestLog['pool_name'];
    };
    pool_uuid: {
        display: string;
        val: IDSRequestLog['pool_uuid'];
    };
    source_port: {
        display: string;
        val: IDSRequestLog['source_port'];
    };
    total_time: {
        display: string;
        val: IDSRequestLog['total_time'];
    };
    server_name: {
        display: string;
        val: IDSRequestLog['server_name'];
    };
    server_ip: {
        display: string;
        val: IDSRequestLog['server_ip'];
    };
    server_port: {
        display: string;
        val: IDSRequestLog['server_port'];
    };
    servers_tried: {
        display: string;
        val: IDSRequestLog['servers_tried'];
    };
    method: {
        display: string;
        val: IDSRequestLog['method'];
    };
    http_version: {
        display: string;
        val: IDSRequestLog['http_version'];
    };
    uri_path: {
        display: string;
        val: IDSRequestLog['uri_path'];
    };
    uri_query: {
        display: string;
        val: IDSRequestLog['uri_query'];
    };
    request_length: {
        display: string;
        val: IDSRequestLog['request_length'];
    };
    http_response_code: {
        display: string;
        val: IDSRequestLog['http_response_code'];
    };
    response_length: {
        display: string;
        val: IDSRequestLog['response_length'];
    };
    headers_sent_to_server: {
        display: string;
        val: IDSRequestLog['headers_sent_to_server'];
    };
    headers_received_from_server: {
        display: string;
        val: IDSRequestLog['headers_received_from_server'];
    };
}

export interface IOutOfBandData {
    eventCount: number,
    logs: IDSRequestLogRow[],
}

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @desc
 *
 *      Component for OutOfBand logs present in VS for L7. OutOfBand logs contains repeated
 *      DSRequestLog messages which are displayed in expandable grid. Each row has additional
 *      information associated with it which is displayed in different sections.
 *
 * @author Satish Pednekar
 */

class OutOfBandComponentController {
    /**
     * Config option for out of band logs grid. It shows expandable rows, which has aditional
     * fields defined in IDSRequestLogRow.
     */
    public gridConfig: IGridConfig;

    /**
     * gridRows holds IDSRequestLogRow entries which will be displayed with expandable
     * panel. The 'display' and 'value' properties are used for displaying and filtering
     * results respectively.
     */
    public gridRows: IDSRequestLogRow[];

    public readonly l10nKeys = l10nKeys;

    /**
     * outOfBandData contains transformed DSRequestLog data which is passed to the grid.
     */
    public readonly outOfBandData: IOutOfBandData;

    /**
     * Parent function passed in to handle filter by clicked field.
     */
    public updateSearch: (args: Record<string, any>) => void;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onInit(): void {
        this.gridRows = this.outOfBandData.logs || [];
        this.gridConfig = this.createOutOfBandLogsGrid();
    }

    /**
     * Creates and returns grid config.
     */
    private createOutOfBandLogsGrid(): IGridConfig {
        return {
            expandedContainerTemplate:
                `<out-of-band-log-list-expander
                    out-of-band-log="::row"
                    update-search="::config.props.updateSearch(str)"
                 ></out-of-band-log-list-expander>`,
            props: {
                updateSearch: (str: string) => this.updateSearch({ str }),
            },
            fields: [{
                name: 'datascriptName',
                title: this.l10nService.getMessage(l10nKeys.gridColumnHeaderNameLabel),
                template: `<div
                               class="out-of-band-logs__clickable-filter"
                               log-filter-click
                               key="oob_log.ds_req_logs.ds_name"
                               display-value="{{ ::row.ds_name.display }}"
                               value="::row.ds_name.val"
                               on-update="::config.props.updateSearch(str)"
                           ></div>`,
            }, {
                name: 'datascriptEvent',
                title: this.l10nService.getMessage(l10nKeys.gridColumnHeaderEventLabel),
                template: `<div
                               class="out-of-band-logs__clickable-filter"
                               log-filter-click
                               key="oob_log.ds_req_logs.event"
                               display-value="{{ ::row.event.display }}"
                               value="::row.event.val"
                               on-update="::config.props.updateSearch(str)"
                           ></div>`,
            }],
            id: 'out-of-band-logs',
            layout: {
                hideDisplaying: true,
                hideSearch: true,
            },
        };
    }
}

OutOfBandComponentController.$inject = [
    'l10nService',
];

export const outOfBandLogsComponentOptions = {
    bindings: {
        outOfBandData: '<',
        updateSearch: '&',
    },
    controller: OutOfBandComponentController,
    template,
};

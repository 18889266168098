/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  WafProfile
 * @description  WafProfile item.
 */
const WafProfileFactory = (Item, defaultValues) => {
    class WafProfile extends Item {
        /** Adds an entry to config.files. */
        addFile() {
            const config = this.getConfig();
            const defaultFileType = defaultValues.getDefaultItemConfigByType('WafDataFile');

            config.files = config.files || [];
            config.files.push(defaultFileType);
        }

        /**
         * Removes an entry from config.files.
         * @param {number=} index - Index of entry to remove.
         */
        removeFile(index = 0) {
            const { files } = this.getConfig();

            files.splice(index, 1);
        }
    }

    angular.extend(WafProfile.prototype, {
        objectName: 'wafprofile',
        windowElement: 'waf-profile-modal',
    });

    return WafProfile;
};

WafProfileFactory.$inject = [
    'Item',
    'defaultValues',
];

angular.module('aviApp').factory('WafProfile', WafProfileFactory);

/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Output,
} from '@angular/core';
import './avi-dropdown-multiple-selected-value.component.less';

/**
 * Component for a selected value in the AviDropdownComponent.
 * @author alextsg
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'avi-dropdown-multiple-selected-value',
    templateUrl: './avi-dropdown-multiple-selected-value.component.html',
})
export class AviDropdownMultipleSelectedValueComponent {
    /**
     * Event emitter for removing the value.
     */
    @Output() public onRemove = new EventEmitter();

    /**
     * Called when the X button is clicked.
     */
    public handleRemove($event: MouseEvent): void {
        $event.stopPropagation();

        this.onRemove.emit();
    }
}

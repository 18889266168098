/**
 * Module for all things gslb related.
 * @preferred
 * @module GslbModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import { gslbCheckpointsPageOptions } from './components';
import {
    FederationCheckpoint,
    FederationCheckpointCollection,
    GSLB,
    GSLBCollection,
    GSLBPoolMember,
    GSLBPoolMemberCollection,
    GSLBService,
    GSLBServiceCollection,
    GSLBServiceFQDN,
    GSLBServiceFQDNCollection,
    GSLBServiceFQDNListCollDataSource,
    GSLBServiceFQDNListDataTransformer,
    GSLBServicePoolMembersInventoryCollDataSource,
    GSLBServicePoolMembersInventoryDataTransformer,
    GSLBSite,
    GSLBSiteCollection,
    GSLBSiteInventoryCollDataSource,
    GSLBSiteInventoryDataTransformer,
    GSLBVSCollection,
} from '.';

const gslbModule = angular.module('avi/gslb');

const factories = [
    {
        factory: FederationCheckpoint,
        name: 'FederationCheckpoint',
    },
    {
        factory: FederationCheckpointCollection,
        name: 'FederationCheckpointCollection',
    },
    {
        factory: GSLB,
        name: 'GSLB',
    },
    {
        factory: GSLBCollection,
        name: 'GSLBCollection',
    },
    {
        factory: GSLBPoolMember,
        name: 'GSLBPoolMember',
    },
    {
        factory: GSLBPoolMemberCollection,
        name: 'GSLBPoolMemberCollection',
    },
    {
        factory: GSLBService,
        name: 'GSLBService',
    },
    {
        factory: GSLBServiceCollection,
        name: 'GSLBServiceCollection',
    },
    {
        factory: GSLBServiceFQDN,
        name: 'GSLBServiceFQDN',
    },
    {
        factory: GSLBServiceFQDNCollection,
        name: 'GSLBServiceFQDNCollection',
    },
    {
        factory: GSLBServiceFQDNListCollDataSource,
        name: 'GSLBServiceFQDNListCollDataSource',
    },
    {
        factory: GSLBServiceFQDNListDataTransformer,
        name: 'GSLBServiceFQDNListDataTransformer',
    },
    {
        factory: GSLBServicePoolMembersInventoryCollDataSource,
        name: 'GSLBServicePoolMembersInventoryCollDataSource',
    },
    {
        factory: GSLBServicePoolMembersInventoryDataTransformer,
        name: 'GSLBServicePoolMembersInventoryDataTransformer',
    },
    {
        factory: GSLBSite,
        name: 'GSLBSite',
    },
    {
        factory: GSLBSiteCollection,
        name: 'GSLBSiteCollection',
    },
    {
        factory: GSLBSiteInventoryCollDataSource,
        name: 'GSLBSiteInventoryCollDataSource',
    },
    {
        factory: GSLBSiteInventoryDataTransformer,
        name: 'GSLBSiteInventoryDataTransformer',
    },
    {
        factory: GSLBVSCollection,
        name: 'GSLBVSCollection',
    },
];

const components = [
    {
        name: 'gslbCheckpointsPage',
        options: gslbCheckpointsPageOptions,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(
        gslbModule,
        'factory',
        name,
        factory,
    );
});

components.forEach(({ name, options }) => gslbModule.component(name, options));

/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { IVCenterCredentials } from 'generated-types';
import { IMessageItemData, MessageItem } from 'ajs/modules/data-model';

interface IVCenterCredentialsData extends IMessageItemData {
    config: IVCenterCredentials;
}

export class VCenterCredentialsConfigItem extends MessageItem {
    public data: IVCenterCredentialsData;

    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'VCenterCredentials',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public isValid(): boolean {
        return this.canFlatten();
    }

    /** @override */
    public canFlatten(): boolean {
        return Boolean(this.config.username);
    }

    /**
     * Deletes configured fields.
     */
    public clearFields(): void {
        delete this.config.username;
        delete this.config.password;
    }
}

const moduleName = 'cloud-connector-user';
const componentName = 'nsxt-credentials';
const prefix = `${moduleName}.${componentName}`;

export const usernameFieldLabel = `${prefix}.usernameFieldLabel`;
export const passwordFieldLabel = `${prefix}.passwordFieldLabel`;

export const ENGLISH = {
    [usernameFieldLabel]: 'Username',
    [passwordFieldLabel]: 'Password',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './protocol-parser-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class ProtocolParserModalController {
    constructor(
        schemaService,
        Regex,
        l10nService,
    ) {
        this.fileUploadHelpText = schemaService
            .getFieldDescription('ProtocolParser', 'parser_code');
        this.Regex = Regex;

        /**
        * Get keys from source bundles for template usage.
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }
}

ProtocolParserModalController.$inject = [
    'schemaService',
    'Regex',
    'l10nService',
];

/**
 * @ngdoc component
 * @name protocolParserModal
 * @param {ProtocolParser} editable
 * @author Aravindh Nagarajan
 * @desc Modal component for creating/editing a protocol parser
 */
angular.module('aviApp').component('protocolParserModal', {
    bindings: {
        editable: '<',
    },
    controller: ProtocolParserModalController,
    templateUrl: 'src/components/modals/protocol-parser-modal/protocol-parser-modal.html',
});

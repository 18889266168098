/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name dnsPolicyGridActionColumn
 * @description Component for displaying the action configuration in a grid field.
 * @param {DnsRuleConfig} rule
 */
class DnsPolicyGridActionColumnController {
    /**
     * Returns true is the log is enabled, otherwise returns false.
     * @return {boolean}
     */
    isLogEnabled() {
        return this.rule.getConfig().log;
    }

    /**
     * Returns all actions in the rule.
     * @return {ActionConfigItem[]}
     */
    getActions() {
        return this.rule.getActions();
    }
}

angular.module('aviApp').component('dnsPolicyGridActionColumn', {
    bindings: {
        rule: '<',
    },
    controller: DnsPolicyGridActionColumnController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-dns-policy/' +
            'dns-policy-grid-action-column/dns-policy-grid-action-column.html',
});

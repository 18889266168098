/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './license-key-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const componentName = 'license-key-modal';

/**
 * @constructor
 * @memberOf module:avi/licensing
 * @mixes module:avi/licensing:licenseKeyModalBindings
 * @see {@link module:avi/licensing.licenseKeyModalComponent licenseKeyModalComponent}
 */
class LicenseKeyModalController {
    constructor(Regex, licensingService, l10nService) {
        /**
         * Regex for license key field.
         * @type {RegExp}
         */
        this.licenseKeyRegex = Regex.licenseSerialKey;

        /**
         * @type {module:avi/licensing.licensingService}
         * @protected
         */
        this.licensingService_ = licensingService;

        /**
         * License serial key model.
         * @type {string}
         */
        this.licenseKey = '';

        /**
         * True when license key is being uploaded.
         * @typedef {boolean}
         * @protected
         */
        this.busy_ = false;

        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for busy flag.
     * @returns {boolean}
     */
    get busy() {
        return this.busy_;
    }

    /**
     * Setter for busy flag.
     * @param {boolean} isBusy
     */
    set busy(isBusy) {
        this.busy_ = !!isBusy;
    }

    /**
     * Uploads Serial key to server.
     */
    applyKey() {
        this.busy = true;
        this.errors = null;

        this.licensingService_.applyLicenseKey(this.licenseKey)
            .then(() => {
                this.onKeyApplySuccess();
                this.closeModal();
            })
            .catch(({ data }) => {
                this.errors = data.result;
            })
            .finally(() => {
                this.busy = false;
            });
    }
}

LicenseKeyModalController.$inject = [
    'Regex',
    'licensingService',
    'l10nService',
];

/**
 * @name licenseKeyModalComponent
 * @memberOf module:avi/licensing
 * @property {module:avi/licensing:licenseKeyModalBindings} bindings
 * @property {module:avi/licensing:LicenseKeyModalController} controller
 * @desc Modal Component to apply license key.
 * @author Aravindh Nagarajan
 */
angular.module('avi/licensing').component('licenseKeyModal', {
    /**
     * @mixin licenseKeyModalBindings
     * @memberOf module:avi/licensing
     * @property {Function} onKeyApplySuccess Success handler for License Key/File upload.
     * @property {Function} closeModal Method fired upon user selecting to close modal
     */
    bindings: {
        onKeyApplySuccess: '&',
        closeModal: '&',
    },
    controller: LicenseKeyModalController,
    templateUrl:
        'src/components/pages/administration/settings/licensing-page/license-upload-panel/' +
        `${componentName}/${componentName}.component.html`,
});

const moduleName = 'ajs-legacy';
const componentName = 'bgp-peering-list';
const prefix = `${moduleName}.${componentName}`;

export const typeLabel = `${prefix}.typeLabel`;
export const bgpAsLabel = `${prefix}.bgpAsLabel`;
export const editEnableBgpBtnLabel = `${prefix}.editEnableBgpBtnLabel`;
export const ipv4PrefixLabel = `${prefix}.ipv4PrefixLabel`;
export const ipv4PeerLabel = `${prefix}.ipv4PeerLabel`;
export const ipv6PrefixLabel = `${prefix}.ipv6PrefixLabel`;
export const ipv6PeerLabel = `${prefix}.ipv6PeerLabel`;
export const remoteAsLabel = `${prefix}.remoteAsLabel`;
export const advertiseVipLabel = `${prefix}.advertiseVipLabel`;
export const advertiseSnatLabel = `${prefix}.advertiseSnatLabel`;
export const bgpPeeringForVrfMessage = `${prefix}.bgpPeeringForVrfMessage`;
export const isDisabledLabel = `${prefix}.isDisabledLabel`;

export const ENGLISH = {
    [typeLabel]: 'Type',
    [bgpAsLabel]: 'BGP AS',
    [editEnableBgpBtnLabel]: 'Edit/Enable BGP',
    [ipv4PrefixLabel]: 'IPv4 Prefix',
    [ipv4PeerLabel]: 'IPv4 Peer',
    [ipv6PrefixLabel]: 'IPv6 Prefix',
    [ipv6PeerLabel]: 'IPv6 Peer',
    [remoteAsLabel]: 'Remote AS',
    [advertiseVipLabel]: 'Advertise VIP',
    [advertiseSnatLabel]: 'Advertise SNAT',
    [bgpPeeringForVrfMessage]: 'BGP Peering ##0for ##1 VRF Context',
    [isDisabledLabel]: 'is disabled',
};

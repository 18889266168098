const moduleName = 'ajs-legacy';
const componentName = 'analytics-profile-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const actionBtnEdit = `${prefix}.actionBtnEdit`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [actionBtnEdit]: 'Edit',
};

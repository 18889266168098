const moduleName = 'ajs-legacy';
const componentName = 'network-profile-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const columnTitleAutoLearn = `${prefix}.columnTitleAutoLearn`;
export const actionBtnEdit = `${prefix}.actionBtnEdit`;
export const tcpProxyValue = `${prefix}.tcpProxyValue`;
export const tcpFastPathValue = `${prefix}.tcpFastPathValue`;
export const udpFastPathValue = `${prefix}.udpFastPathValue`;
export const yesLabel = `${prefix}.yesLabel`;
export const udpProxyValue = `${prefix}.udpProxyValue`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleType]: 'Type',
    [columnTitleAutoLearn]: 'Auto Learn',
    [actionBtnEdit]: 'Edit',
    [tcpProxyValue]: 'TCP Proxy',
    [tcpFastPathValue]: 'TCP Fast Path',
    [udpFastPathValue]: 'UDP Fast Path',
    [udpProxyValue]: 'UDP Proxy',
    [yesLabel]: 'Yes',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name StringGroupCollection
 */
angular.module('aviApp').factory('StringGroupCollection', [
'Collection', 'StringGroup',
function(Collection, StringGroup) {
    class StringGroupCollection extends Collection {}

    angular.extend(StringGroupCollection.prototype, {
        objectName_: 'stringgroup',
        itemClass_: StringGroup,
        windowElement_: 'prof-stringgroup-create',
        sortBy_: 'name',
        serverDefaultsOverride_: {
            kv: [],
        },
    });

    return StringGroupCollection;
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './persistence-profile-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name persistenceProfileModal
 * @description
 *
 *     Modal for Persistence profile.
 */
class PersistenceProfileModal {
    constructor(
        Regex,
        schemaService,
        l10nService,
    ) {
        this.Regex = Regex;
        this.schemaService_ = schemaService;

        /**
        * Get keys from source bundles for template usage
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        const { editable } = this;
        const profileType = editable.getType();

        this.allTypes = this.schemaService_.getEnumValues('PersistenceProfileType');

        //for create case drops non-GSLB types from the list except gslb one
        if (!editable.id && profileType === 'PERSISTENCE_TYPE_GSLB_SITE') {
            this.allTypes = this.allTypes.filter(
                ({ value }) => value === profileType,
            );
        }
    }
}

PersistenceProfileModal.$inject = [
        'Regex',
        'schemaService',
        'l10nService',
];

angular.module('aviApp').component('persistenceProfileModal', {
    controller: PersistenceProfileModal,
    bindings: {
        editable: '=',
        closeModal: '&',
    },
    templateUrl:
            'src/components/modals/persistence-profile-modal/persistence-profile-modal.html',
});

/** @module LogsModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { L10nService } from '@vmw/ngx-vip';
import { IIcapViolation } from 'generated-types';
import * as l10n from './icap-log-violations.l10n';
import template from './icap-log-violations.component.html';
import './icap-log-violations.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/** Formatted object containing values needed for Application>VS>Logs violations section. */
export interface IIcapLogViolation {
    file_name: {
        display: string;
        val: IIcapViolation['file_name'];
    };
    threat_name: {
        display: string;
        val: IIcapViolation['threat_name'];
    };
    resolution: {
        display: string;
        val: IIcapViolation['resolution'];
    };
}

/**
 * @author akulaggarwal
 * @desc Sub-component representing 1 item in ICAP log drawer violations.
 */
class IcapLogViolationsComponentController {
    public readonly l10nKeys = l10nKeys;
    public readonly violations: IIcapViolation;
    /**
     * Parent function passed in to handle filter by new prop.
     */
    public updateSearch: (str: string) => void;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Calls parent function to handle user click to filter by clicked prop.
     */
    public onClick(str: string): void {
        this.updateSearch(str);
    }
}

IcapLogViolationsComponentController.$inject = [
    'l10nService',
];

export const icapLogViolationsComponentOptions = {
    bindings: {
        violations: '<',
        updateSearch: '<',
    },
    controller: IcapLogViolationsComponentController,
    template,
};

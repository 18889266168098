/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../waf-log-entry.less';
import '../waf-logs-list.less';
import { WafLogsService } from '../waf-logs.service';
import * as l10n from './waf-app-rule-log-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @constructor
 * @memberOf module:avi/logs
 * @mixes module:avi/logs.wafAppRuleLogsListBindings
 * @see {@link  module:avi/logs.wafAppRuleLogsListComponent}
 */
class WafAppRuleLogsListController {
    constructor(l10nService) {
        /**
        * Get keys from source bundles for template usage.
        */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        /**
         * Expanded state. Used for expanding/collapsing all.
         */
        this.expanded = false;
    }

    $onInit() {
        this.ruleGroupsHash = this.getRuleGroupsHash_();
    }

    /**
     * Click handler for expanding/collapsing all.
     */
    toggleExpand() {
        this.expanded = !this.expanded;
    }

    /**
     * Creates a hash of rule group names to the rule group's rules.
     * @protected
     * @returns {Object}
     */
    getRuleGroupsHash_() {
        const { logs = [] } = this;

        return logs.reduce(WafLogsService.ruleGroupReducer_, {});
    }
}

WafAppRuleLogsListController.$inject = [
    'l10nService',
];

/**
 * @name wafAppRuleLogsListComponent
 * @memberOf module:avi/logs
 * @property {module:avi/logs.WafAppRuleLogsListController} controller
 * @property {module:avi/logs.wafAppRuleListBindings} bindings
 * @description Component for a list of Application Rule Logs.
 * @author Rajawant Prajapati
 */
angular.module('avi/logs').component('wafAppRuleLogsList', {
    /**
     * @mixin wafAppRuleListBindings
     * @memberOf module:avi/logs
     * @property {Object[]} logs - List of Application Rule Logs.
     */
    bindings: {
        logs: '<',
    },
    controller: WafAppRuleLogsListController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-logs/waf-logs/' +
        'waf-app-rule-logs-list/waf-app-rule-logs-list.component.html',
});

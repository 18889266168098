/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './authentication-rule-modal.component.less';
import * as l10n from './authentication-rule-modal.l10n';

const componentName = 'authentication-rule-modal';
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @constructor
 * @memberOf module:avi/policies/sso
 * @mixes module:avi/policies/sso.authenticationRuleModalComponentBindings
 * @see {@link module:avi/policies/sso.authenticationRuleModalComponent}
 */
class AuthenticationRuleModalController {
    constructor(
        schemaService,
        Regex,
        l10nService,
    ) {
        /**
         * Authentication action dropdown options
         * @type {EnumValue[]}
         */
        this.actionTypes = schemaService.getEnumValues('AuthenticationActionEnum');

        /**
         * @type {Regex}
         */
        this.regex = Regex;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Handler for the user clicking the close button.
     */
    handleCancel() {
        this.onCancel();
        this.closeModal();
    }

    /**
     * Getter for the supported matches.
     * @return {string[]}
     */
    get supportedMatches() {
        return this.rule.match.supportedMatches;
    }

    /**
     * Handler for the user clicking the submit button.
     */
    handleSubmit() {
        this.onSubmit();
        this.closeModal();
    }

    /**
     * Returns true if the location is being edited, contrary to creation.
     * @return {boolean}
     */
    isEditing() {
        return !_.isUndefined(this.rule.getIndex());
    }

    /**
     * Returns the state of the enable switch, 1 for enabled, 0 for disabled.
     * @return {number}
     */
    getEnableState() {
        return this.rule.enable ? 1 : 0;
    }

    /**
     * Click handler for the enable switch. Flips the current value.
     */
    handleEnableClick() {
        this.rule.config.enable = !this.rule.enable;
    }
}

AuthenticationRuleModalController.$inject = [
    'schemaService',
    'Regex',
    'l10nService',
];

/**
 * @name authenticationRuleModalComponent
 * @memberOf module:avi/policies/sso
 * @property {module:avi/policies/sso.AuthenticationRuleModalController} controller
 * @property {module:avi/policies/sso.authenticationRuleModalComponentBindings} bindings
 * @description
 *      Authentication Rule modal component.
 * @author Aravindh Nagarajan
 */
angular.module('avi/policies/sso').component('authenticationRuleModal', {
    /**
     * @mixin authenticationRuleModalComponentBindings
     * @memberOf module:avi/policies/sso
     * @property {module:avi/policies/sso.AuthenticationRuleConfigItem} rule
     * @property {Function} onSubmit
     * @property {Function} onCancel
     * @property {Function} closeModal
     */
    bindings: {
        rule: '<',
        onSubmit: '&',
        onCancel: '&',
        closeModal: '&',
    },
    controller: AuthenticationRuleModalController,
    templateUrl:
        `src/components/modals/${componentName}/${componentName}.component.html`,
});

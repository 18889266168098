/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { pick, reduce } from 'underscore';
import { MessageItem } from 'ajs/modules/data-model';
import { SchemaService, TPbMessageName } from 'ajs/modules/core';

type TMessageItem = typeof MessageItem;

export interface IMessageMapProps {
    objectType: TPbMessageName;
    ConfigItemClass: TMessageItem;
    isRepeated: boolean;
}

export type IMessageMap = Record<string, IMessageMapProps>;

/**
 * Filters out a messageMap by a list of fields.
 */
export function filterMessageMap(messageMap: IMessageMap, fields: string[]): IMessageMap {
    return pick(messageMap, fields);
}

/**
 * @constructor
 * @memberOf module:avi/core
 * @description
 *     Service for creating a messageMap for MessageItems/ObjectTypeItems, which contains fields
 *     that are messages and their objectType and constructor functions.
 */
export class MessageMapService {
    constructor(
        private $injector: ng.auto.IInjectorService,
        private schemaService: SchemaService,
    ) {}

    /**
     * Creates a messageMap for a MessageItem, which is a hash of the properties within the
     * MessageItem's config that are messages to their MessageMapProps.
     */
    public createMessageMap(objectType: string): IMessageMap {
        /**
         * To store fields of message.
         */
        let messageFields;

        try {
            messageFields = this.schemaService.getMessageFields(objectType);
        } catch (error) {
            messageFields = {};
        }

        const InjectedMessageItem = this.$injector.get('MessageItem');

        return reduce(messageFields, (messageMap, messageFieldProp, field) => {
            const { objectType: childObjectType } = messageFieldProp;

            messageMap[field] = {
                ...messageFieldProp,
                ConfigItemClass: this.getConfigItemClass(childObjectType) || InjectedMessageItem,
            };

            return messageMap;
        }, {});
    }

    /**
     * Gets the MessageItem constructor function based on the `${objectType}ConfigItem` naming
     * scheme.
     */
    private getConfigItemClass(objectType: string): TMessageItem | undefined {
        const configItemClassName = `${objectType}ConfigItem`;

        try {
            return this.$injector.get(configItemClassName);
        } catch (error) {
            // empty block (eslint)
        }
    }
}

MessageMapService.$inject = [
    '$injector',
    'schemaService',
];

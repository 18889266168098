/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'update';
const componentName = 'system-update-about-card';
const prefix = `${moduleName}.${componentName}`;

export const aboutHeader = `${prefix}.aboutHeader`;
export const rollbackBtnLabel = `${prefix}.rollbackBtnLabel`;
export const versionLabel = `${prefix}.versionLabel`;
export const buildLabel = `${prefix}.buildLabel`;
export const patchLabel = `${prefix}.patchLabel`;
export const dateLabel = `${prefix}.dateLabel`;
export const fipsLabel = `${prefix}.fipsLabel`;
export const commonCriteriaLabel = `${prefix}.commonCriteriaLabel`;
export const complianceModeBtnLabel = `${prefix}.complianceModeBtnLabel`;
export const inProgressLabel = `${prefix}.inProgressLabel`;

export const ENGLISH = {
    [aboutHeader]: 'About',
    [rollbackBtnLabel]: 'Rollback',
    [versionLabel]: 'Version',
    [buildLabel]: 'Build',
    [patchLabel]: 'Patch',
    [dateLabel]: 'Date',
    [fipsLabel]: 'FIPS',
    [commonCriteriaLabel]: 'Common Criteria',
    [complianceModeBtnLabel]: 'Compliance Mode',
    [inProgressLabel]: 'In Progress',
};

/**
 * @module IpamModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { Collection } from 'ajs/modules/data-model';

export class IPAMProfileCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'ipamdnsproviderprofile',
            windowElement: 'ipam-dns-profiles-modal',
            restrictCreateOnEssentialLicense: false,
            ...args,
        };

        super(extendedArgs);

        this.serverDefaultsOverride_ = {
            type: 'IPAMDNS_TYPE_INTERNAL',
        };

        this.itemClass_ = this.getAjsDependency_('IPAMProfile');
    }
}

IPAMProfileCollection.ajsDependencies = [
    'IPAMProfile',
];

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name policyGridPromptIndex
 * @description
 *     Component for a modal prompt opened when a user wants to move or create a rule at a specific
 *     index.
 * @param {Function} onSubmit - Function to call after completing prompt form.
 * @param {Function} onCancel - Function to call when exiting prompt.
 * @param {Function} closeModal - Closes modal.
 * @param {Object} rule - Policy rule being moved.
 */
class PolicyGridPromptIndexController {
    constructor() {
        this.mode = 'create';
        this.placement = 'auto';
        this.position = 'above';

        this.submit.bind(this.submit);
    }

    $onInit() {
        if (angular.isObject(this.rule)) {
            this.mode = 'edit';
            this.placement = 'manual';
        }
    }

    /**
     * Calls onSubmit with the new position and index that the rule should be moved to.
     */
    submit() {
        let data;

        if (this.placement === 'manual') {
            data = {
                position: this.position,
                index: +this.index,
            };
        }

        this.onSubmit({ data });
        this.closeModal();
    }

    /**
     * Cancels and closes modal.
     */
    cancel() {
        this.onCancel();
        this.closeModal();
    }
}

angular.module('aviApp').component('policyGridPromptIndex', {
    controller: PolicyGridPromptIndexController,
    bindings: {
        onSubmit: '&',
        onCancel: '&',
        closeModal: '&',
        rule: '<',
        rules: '<',
    },
    templateUrl: 'src/components/applications/virtualservice/policy/policy-grid/' +
            'policy-grid-prompt-index/policy-grid-prompt-index.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as caseCommentListTemplate from './case-comment-list.component.partial.html';
import './case-comment-list.component.less';

/**
 * @constructor
 * @memberOf module:avi/cportal
 * @desc component to show list of comments
 * @mixes module:avi/cportal.caseCommentListBindings
 * @author Ashish Verma
 * @see {@link module:avi/cportal.CaseCommentListController}
 */
class CaseCommentListController {}

/**
 * @mixin caseCommentListBindings
 * @memberOf module:avi/cportal
 * @property {Array.<module:avi/cportal.CaseComment>} comments
 */
const caseCommentListBindings = {
    comments: '<',
};

/**
 * @ngdoc component
 * @name caseCommentListComponent
 * @property {module:avi/cportal.CaseCommentListController} controller
 * @property {module:avi/cportal.caseCommentListBindings} bindings
 */
angular.module('avi/cportal').component('caseCommentList', {
    bindings: caseCommentListBindings,
    controller: CaseCommentListController,
    template: caseCommentListTemplate,
});

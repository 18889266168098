/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name HSGraph
 * @author Alex Malitsky
 * @description
 *
 *     HealthScore graph based on Collection keeping two sets of links and edges. Updated by
 *     appending and removing edges (one by one). Nodes have an children array with references
 *     to children.
 */
//TODO destroy? reset series on emptyData call?
//TODO metrics list should be a separate class with it's own dataTransformers and dataSources
angular.module('aviApp').factory('HSGraph', [
'UpdatableBase', 'HSGraphNode', 'HSGraphEdge', 'HSGraphMetrics',
function(UpdatableBase, HSGraphNode, HSGraphEdge, HSGraphMetrics) {
    /**
     * @class
     * @extends UpdatableBase
     */
    return class HealthScoreGraph extends UpdatableBase {
        constructor(args = {}) {
            angular.extend(args, {
                isStatic: false,
                defaultDataSources: 'list',
                allDataSources: {
                    list: {
                        source: 'HSGraphDataSource',
                        transformer: 'HSGraphDataTransformer',
                        transport: 'ListDataTransport',
                        fields: ['config'],
                    },
                },
            });

            super(args);

            this.rootItem_ = args.rootItem;

            this.nodeClass = HSGraphNode;
            this.nodeById = {};

            /**
             * Graph's root node.
             * @type {HSGraphNode|null}
             */
            this.rootNode_ = null;

            this.edgeClass = HSGraphEdge;
            this.edgeById = {};

            /** @type {HSGraphMetrics} */
            this.metrics = new HSGraphMetrics({ graph: this });

            /**
             * Raw unprocessed metrics data is kept here.
             * @type {Object}
             * @protected
             */
            this.metricsData_ = {};

            const configDataSource = this.getDataSourceByFieldName('config');

            configDataSource
                .on('activeNodeChanged', this.onActiveNodeChange_.bind(this));

            configDataSource
                .on('DataSourceAfterLoad', this.onDataUpdate_.bind(this));

            if (this.loadOnCreate_) {
                this.load();
            }
        }

        /**
         * We build HS graph for some Item, such as VS, Pool or SE. This is a getter for such
         * root node.
         * @returns {Item}
         * @public
         */
        getRootItem() {
            return this.rootItem_;
        }

        /**
         * Returns a root node of graph.
         * @returns {null|HSGraphNode}
         * @public
         */
        getRootNode() {
            return this.rootNode_;
        }

        /**
         * Returns node by id.
         * @param {string} nodeId - Node id.
         * @returns {HSGraphNode}
         */
        getItemById(nodeId) {
            if (nodeId && nodeId in this.nodeById) {
                return this.nodeById[nodeId];
            }
        }

        /** @override */
        getNumberOfItems() {
            return _.size(this.nodeById);
        }

        /**
         * Returns Node id when Item's data is passed.
         * @param {Item.data} data
         * @returns {Item.id}
         * @public
         */
        getNodeIdFromData(data) {
            return this.nodeClass.prototype.getIdFromData_.call(undefined, data);
        }

        /**
         * Adds node to the graph.
         * @param {HSGraphNode|HSGraphNode.data} node
         * @public
         */
        appendNode(node) {
            if (angular.isObject(node) && !(node instanceof this.nodeClass) &&
                this.getNodeIdFromData(node)) {
                node = new this.nodeClass({ data: node });//eslint-disable-line new-cap
            }

            if (node instanceof this.nodeClass && !(node.id in this.nodeById)) {
                this.nodeById[node.id] = node;

                if (!this.rootNode_ && node.isRootNode()) {
                    this.rootNode_ = node;
                }
            }
        }

        /**
         * Updates the node's data if found.
         * @param {HSGraphNode.data} nodeData
         */
        updateNode(nodeData) {
            let nodeId;

            if (angular.isObject(nodeData) &&
                (nodeId = this.getNodeIdFromData(nodeData)) &&
                nodeId in this.nodeById) {
                this.nodeById[nodeId].updateItemData(nodeData);
            }
        }

        /**
         * Sets an active node context of the graph. When falsy argument or undefined is passed
         * root node is considered to be an active node.
         * @param {string=} nodeId
         * @public
         */
        setActiveNode(nodeId) {
            if (nodeId && this.rootNode_ && nodeId === this.rootNode_.id) {
                nodeId = undefined;
            }

            this.getDataSourceByFieldName('config').setActiveNode(nodeId) && this.load();
        }

        /**
         * Returns the clicked/activated node (when set). Undefined when not set.
         * @returns {HSGraphNode|undefined}
         */
        getActiveNode() {
            const nodeId = this.getDataSourceByFieldName('config').getParams('activenode');

            if (nodeId) {
                return this.nodeById[nodeId];
            }
        }

        /**
         * Event listener to remove irrelevant metrics.
         * @param {HSGraphNode.id=} activeNodeId
         * @private
         */
        onActiveNodeChange_(activeNodeId) {
            this.trigger('activeNodeChanged', activeNodeId);
        }

        /**
         * Removes node from collection if found.
         * @param nodeId
         */
        removeNode(nodeId) {
            if (nodeId in this.nodeById) {
                const
                    node = this.nodeById[nodeId];

                if (!node.isRootNode()) { //rootNode has no parent
                    const
                        { parentid } = node.getConfig(),
                        parent = this.nodeById[parentid];

                    if (parent) { //might have been dropped before and it is fine
                        parent.dropChildRef(node);
                    }
                } else {
                    this.rootNode_ = null;
                }

                node.destroy();

                delete this.nodeById[nodeId];
            }
        }

        /**
         * Returns Edge id when Edge's data is passed.
         * @param {Item.data} data
         * @returns {Item.id}
         * @public
         */
        getEdgeIdFromData(...data) {
            return this.edgeClass.getIdFromData(...data);
        }

        /**
         * Adds node to the graph.
         * @param {HSGraphNode|HSGraphNode.data} edge
         * @public
         */
        appendEdge(edge) {
            if (angular.isObject(edge) && !(edge instanceof this.edgeClass) &&
                this.getEdgeIdFromData(edge)) {
                //eslint-disable-next-line new-cap
                edge = new this.edgeClass({
                    data: edge,
                });
            }

            if (edge instanceof this.edgeClass && !(edge.id in this.edgeById)) {
                this.edgeById[edge.id] = edge;

                const
                    { source: parentId, target: childId } = edge.getConfig(),
                    parent = this.nodeById[parentId],
                    child = this.nodeById[childId];

                if (parent && child) { //nodes should be added first
                    parent.addChildRef(child);
                }
            }
        }

        /**
         * Updates Edge's data if found.
         * @param {HSGraphEdge.data} edgeData
         */
        updateEdge(edgeData) {
            let edgeId;

            if (angular.isObject(edgeData) &&
                (edgeId = this.getEdgeIdFromData(edgeData)) &&
                edgeId in this.edgeById) {
                this.edgeById[edgeId].updateItemData(edgeData);
            }
        }

        /**
         * Removes edge from Graph if
         * @param edgeId
         */
        removeEdge(edgeId) {
            if (edgeId in this.edgeById) {
                const
                    edge = this.edgeById[edgeId],
                    { source: parentId, target: childId } = edge.getConfig(),
                    parent = this.nodeById[parentId],
                    child = this.nodeById[childId];

                if (parent && child) { //any or both might have been dropped and that is fine
                    parent.dropChildRef(child);
                }

                edge.destroy();
                delete this.edgeById[edgeId];
            }
        }

        /**
         * Returns raw metrics data. Supposed to be used by dataSource only.
         * @returns {Object}
         * @public
         */
        getMetricsData() {
            return this.metricsData_;
        }

        /** @override */
        emptyData(update = true) {
            _.each(this.nodeById, node => node.destroy());

            this.nodeById = {};
            this.rootNode_ = null;

            _.each(this.edgeById, edge => edge.destroy());

            this.edgeById = {};
            this.metricsData_ = {};

            if (update) {
                this.getDataSourceByFieldName('config').onDataFlush();
            }

            super.emptyData();
        }

        /**
         * Propagating the HSGraphDataSource graphUpdate event.
         * @param updateType
         * @private
         */
        onDataUpdate_(updateType) {
            this.trigger('graphUpdate', updateType);
        }
    };
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { svg as d3Svg } from 'd3';

const d3 = { svg: d3Svg };

/* used by logBarChart and eventsChart */
angular.module('aviApp').factory('logChartBrush', function() {
    return function() {
        const self = this;

        let haveRange = false; //or array with brush.extent

        const brushStart = function() {
            //console.log('brushStart');
            self.active = true;
            self.onBrushStart();
        };

        const brush = function() {
            let range;

            self.active = true;

            if (!self.obj.empty() && self.obj.extent()) {
                range = self.obj.extent();

                if (moment(range[1]).isBefore(range[0])) {
                    range = [range[1], range[0]];
                }

                self.onBrushUpdate(range);
            }
            //elem.find('.detail').css('display', self.sample.empty() ? '' : 'none');
        };

        const brushEnd = function() {
            let range;

            self.active = false;

            if (self.obj.empty()) {
                if (haveRange) {
                    haveRange = false;
                    self.onBrushEnd();
                } else {
                    self.onClickNoBrush();
                }
            } else {
                range = self.extent();

                //console.log('range: %O', range);
                if (range && (+range[0] !== +haveRange[0] || +range[1] !== +haveRange[1])) {
                    if (moment(range[1]).isBefore(range[0])) {
                        range = [range[1], range[0]];
                    }

                    haveRange = self.checkBrushSelection(range);
                    //console.log(range[0], range[1]);
                    self.onBrushEnd(haveRange);
                }
                // else{ console.log('brushEnd: same boundaries, do nothing.'); }
            }
        };

        this.active = false;

        this.clear = function() {
            haveRange = false;

            return this.obj.clear();
        };

        this.extent = function(dates) {
            let res;

            if (dates) {
                haveRange = dates;
                res = this.obj.extent(dates);
            } else {
                res = this.obj.extent();
                //checking for equal dates:
                /*if(res.length && res[0].toString() === res[1].toString()){
                 res = false;
                 }*/
            }

            return res;
        };

        /* these should be defined where used */
        this.onBrushStart = angular.noop;
        this.onBrushEnd = angular.noop;
        this.onBrushUpdate = angular.noop;
        /* when clicked on a chart with no active or previous brush. */
        this.onClickNoBrush = angular.noop;

        /* check range and modify smth if needed */
        this.checkBrushSelection = function(range) {
            return range;
        };

        this.obj = d3.svg.brush()
            .on('brushstart', brushStart)
            .on('brush', brush)
            .on('brushend', brushEnd);
    };
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name getItemConstructorByRef
 * @description
 *
 *     To create an Item we need to have an appropriate constructor.
 *     We can get it by Item's ref\URL using this factory.
 */
angular.module('aviApp').factory('getItemConstructorByRef', [
'$injector', 'itemObjectTypeToClassName',
function($injector, itemObjectTypeToClassName) {
    const refRegex = /.*\/api\/(\w+)/i;

    return function(itemRef) {
        let matches;

        if (matches = refRegex.exec(itemRef)) {
            const
                [, objectName] = matches,
                className = itemObjectTypeToClassName[objectName];

            if (className && $injector.has(className)) {
                return $injector.get(className);
            }
        }

        return null;
    };
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'waf-policy-psm-group-modal';
const prefix = `${moduleName}.${componentName}`;

export const editPositiveSecurityGroupHeader = `${prefix}.editPositiveSecurityGroupHeader`;
export const newPositiveSecurityGroupHeader = `${prefix}.newPositiveSecurityGroupHeader`;
export const generalSectionHeader = `${prefix}.generalSectionHeader`;
export const groupEnableLabel = `${prefix}.groupEnableLabel`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const learningGroupCheckboxLabel = `${prefix}.learningGroupCheckboxLabel`;
export const hitActionInputLabel = `${prefix}.hitActionInputLabel`;
export const hitActionInputPlaceholder = `${prefix}.hitActionInputPlaceholder`;
export const missActionInputLabel = `${prefix}.missActionInputLabel`;
export const missActionInputPlaceholder = `${prefix}.missActionInputPlaceholder`;
export const locationsSectionHeader = `${prefix}.locationsSectionHeader`;

export const ENGLISH = {
    [editPositiveSecurityGroupHeader]: 'Edit Positive Security Group: {0}',
    [newPositiveSecurityGroupHeader]: 'New Positive Security Group: {0}',
    [generalSectionHeader]: 'General',
    [groupEnableLabel]: 'Group {0}',
    [nameInputLabel]: 'Name',
    [descriptionInputLabel]: 'Description',
    [learningGroupCheckboxLabel]: 'Learning Group',
    [hitActionInputLabel]: 'Hit Action',
    [hitActionInputPlaceholder]: 'Select Hit Action',
    [missActionInputLabel]: 'Miss Action',
    [missActionInputPlaceholder]: 'Select Miss Action',
    [locationsSectionHeader]: 'Locations',
};

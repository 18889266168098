/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name infrastructureDetail
 * @param {Cloud} resolveCloud - Cloud item loaded by resolve.
 * @param {Object} $transition$ - Injected by angular ui-router.
 * @desc
 *     Component that manages the Infrastructure pages Service Engine List, Service Engine Group
 *     List, Network List, and Routing pages. Shows a dropdown for the user to select a cloud, with
 *     the Default-Cloud selected by default. On cloud change, adds the cloud uuid to the URL params
 *     and checks the state to make sure it's valid based on the Cloud, else redirects.
 */
import * as l10n from './infrastructure-detail.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const staticRouteState = '^.staticroute-list';
const vrfState = '**.vrf-context-list';
const gatewayMonitorState = '**.gateway-monitor-list';
const componentName = 'infrastructure-detail';

class InfrastructureDetailController {
    constructor($state, CloudCollection, infraCloudState, l10nService) {
        this._$state = $state;
        this._CloudCollection = CloudCollection;
        this._infraCloudState = infraCloudState;

        this.cloudRef = '';
        this.showSelectCloudDropdown = false;
        this._stateParams = {};
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this._stateParams = this.$transition$.params();
        this._infraCloudState.setCloud(this.cloud);
        this.cloudRef = this.cloud.getRef();

        this.cloudCollection = new this._CloudCollection({
            isStatic: true,
            params: {
                sort: 'name',
            },
        });

        const { cloudCollection } = this;

        cloudCollection.load()
            .then(() => {
                this.showSelectCloudDropdown = cloudCollection.getNumberOfItems() > 1;
            });
    }

    /**
     * Returns true if the state needs to be redirected.
     * @return {boolean}
     */
    isInvalidState() {
        const { _$state: $state, _infraCloudState: infraCloudState } = this;

        return $state.includes(vrfState) && !infraCloudState.showVrfContextTab() ||
                $state.includes(gatewayMonitorState) && !infraCloudState.showGatewayMonitorTab();
    }

    /**
     * Handles the changing of clouds from the Select Cloud dropdown.
     * @param {Cloud} cloud - cloud Cloud object.
     */
    handleCloudChange(cloud) {
        const cloudId = cloud.id;
        const params = angular.extend({}, this._stateParams, { cloudId });

        const $state = this._$state;
        const toState = this.isInvalidState() ? staticRouteState : $state.current;

        $state.go(toState, params)
            .finally(() => this._infraCloudState.setCloud(cloud));
    }

    $onDestroy() {
        this.cloud.destroy();
        this.cloudCollection.destroy();
    }
}

InfrastructureDetailController.$inject = [
    '$state',
    'CloudCollection',
    'infraCloudState',
    'l10nService',
];

angular.module('aviApp')
    .component('infrastructureDetail', {
        bindings: {
            cloud: '<resolveCloud',
            $transition$: '<',
        },
        controller: InfrastructureDetailController,
        templateUrl: `src/components/pages/infrastructure/${componentName}/${componentName}.html`,
    });

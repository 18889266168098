/**
 * @module ServiceEngineGroup
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { L10nService } from '@vmw/ngx-vip';
import {
    INsxtCluster,
    INsxtTransportnode,
    IPlacementScopeConfig,
    IVcenterFolder,
    IVcenterSharedDatastore,
} from 'generated-types';
import { IAviDropdownOption } from 'ng/shared/components';
import { createDropdownOption } from 'ng/utils/dropdown.utils';
import { StringService } from 'ajs/modules/core';
import { SEGroup } from '../../factories/se-group.item';
import template from './segroup-nsxt-placement-scope-config.component.html';
import './segroup-nsxt-placement-scope-config.component.less';
import * as l10n from './segroup-nsxt-placement-scope-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

enum HostScope {
    ANY = 'ANY',
    HOST = 'HOST',
    CLUSTER = 'CLUSTER',
}

enum DatastoreScope {
    ANY = 'ANY',
    SHARED = 'SHARED',
}

class SegroupNsxtPlacementScopeConfigController {
    /**
     * SEGroup Item. Component binding.
     */
    public segroup: SEGroup;

    /**
     * PlacementScopeConfig object to be configured. Component binding.
     */
    public config: IPlacementScopeConfig;

    /**
     * Called when a vcenterserver has been selected. Component binding.
     */
    public onSelectVcenterServer: () => void;

    /**
     * Called to remove a PlacementScopeConfig. Component binding.
     */
    public onRemoveVcenterPlacementScopeConfig: () => void;

    /**
     * HostScope enum as a property.
     */
    public readonly HostScope = HostScope;

    /**
     * ngModel of Host Scope radio buttons.
     */
    public hostScope: HostScope = HostScope.ANY;

    /**
     * DatastoreScope enum as a property.
     */
    public readonly DatastoreScope = DatastoreScope;

    /**
     * ngModel of Data Store Scope radio buttons.
     */
    public datastoreScope: DatastoreScope = DatastoreScope.ANY;

    /**
     * List of Service Engine folder dropdown options.
     */
    public vcenterFolderOptions: IAviDropdownOption[];

    /**
     * Dropdown options for Host Scope.
     */
    public hostOptions: IAviDropdownOption[];

    /**
     * Dropdown options for Data Store Scope.
     */
    public datastoreOptions: IAviDropdownOption[];

    /**
     * Dropdown options for Cluster Scope.
     */
    public clusterOptions: IAviDropdownOption[];

    /**
     * Flag to indicate that dropdown options are loading. Disables the dropdowns.
     */
    public busy = false;

    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly stringService: StringService,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onInit(): void {
        if (this.config.nsxt_hosts) {
            this.hostScope = HostScope.HOST;
        } else if (this.config.nsxt_clusters) {
            this.hostScope = HostScope.CLUSTER;
        }

        if (this.config.nsxt_datastores) {
            this.datastoreScope = DatastoreScope.SHARED;
        }

        this.setDropdownOptions();
    }

    /**
     * Called when a vcenterserver has been selected.
     */
    public handleSelectVcenterServer(): void {
        this.setDropdownOptions();
        this.onSelectVcenterServer();
    }

    /**
     * Called when user toggles between ANY and HOST Host Scoping.
     */
    public handleHostScopeChange(): void {
        switch (this.hostScope) {
            case HostScope.ANY:
                delete this.config.nsxt_hosts;
                delete this.config.nsxt_clusters;
                break;

            case HostScope.HOST:
                delete this.config.nsxt_clusters;

                this.config.nsxt_hosts = {
                    include: true,
                };

                break;

            case HostScope.CLUSTER:
                delete this.config.nsxt_hosts;

                this.config.nsxt_clusters = {
                    include: true,
                };

                break;
        }
    }

    /**
     * Called when user toggles between ANY and SHARED Data Store Scoping.
     */
    public handleDatastoreScopeChange(): void {
        switch (this.datastoreScope) {
            case DatastoreScope.ANY:
                delete this.config.nsxt_datastores;
                break;

            case DatastoreScope.SHARED:
                this.config.nsxt_datastores = {
                    include: true,
                };

                break;
        }
    }

    /**
     * Returns the text to be displayed for a vcenterserver.
     */
    public get vcenterServerDisplayValue(): string {
        const { vcenter_ref: vcenterRef } = this.config;

        return vcenterRef ?
            this.stringService.name(vcenterRef) :
            this.l10nService.getMessage(l10nKeys.vcenterServerNotAvailable);
    }

    /**
     * Called to remove a vcenterserver PlacementScopeConfig.
     */
    public removeVcenterPlacementScopeConfig(): void {
        this.onRemoveVcenterPlacementScopeConfig();
    }

    /**
     * Called to set all dropdown options based on the selected vcenterserver.
     */
    private async setDropdownOptions(): Promise<void> {
        this.busy = true;

        try {
            await Promise.all([
                this.setVcenterFolderOptions(),
                this.setHostOptions(),
                this.setClusterOptions(),
                this.setDatastoreOptions(),
            ]);
        } finally {
            this.busy = false;
        }
    }

    /**
     * Sets the Service Engine Folder dropdown options.
     */
    private async setVcenterFolderOptions(): Promise<void> {
        const { vcenter_ref: vcenterServerRef } = this.config;

        if (!vcenterServerRef) {
            return;
        }

        const vcenterId = this.stringService.slug(vcenterServerRef);
        const folders = await this.segroup.getVCenterFolders(vcenterId);

        this.vcenterFolderOptions = folders.map((folder: IVcenterFolder) => {
            return createDropdownOption(folder.vc_mobj_id, folder.name);
        });
    }

    /**
     * Sets the Host Scoping dropdown options.
     */
    private async setHostOptions(): Promise<void> {
        const { vcenter_ref: vcenterServerRef } = this.config;

        if (!vcenterServerRef) {
            return;
        }

        const vcenterServerId = this.stringService.slug(vcenterServerRef);
        const transportNodes = await this.segroup.getNsxtTransportNodes(vcenterServerId);

        this.hostOptions = transportNodes.map((node: INsxtTransportnode) => {
            return createDropdownOption(node.vc_mobj_id, node.name);
        });
    }

    /**
     * Sets the Data Store Scoping dropdown options.
     */
    private async setDatastoreOptions(): Promise<void> {
        const { vcenter_ref: vcenterServerRef } = this.config;

        if (!vcenterServerRef) {
            return;
        }

        const vcenterServerId = this.stringService.slug(vcenterServerRef);
        const datastores = await this.segroup.getVCenterDatastores(vcenterServerId);

        this.datastoreOptions = datastores.map((store: IVcenterSharedDatastore) => {
            return createDropdownOption(store.vc_mobj_id, store.name);
        });
    }

    /**
     * Sets the Cluster Scoping dropdown options.
     */
    private async setClusterOptions(): Promise<void> {
        const { vcenter_ref: vcenterServerRef } = this.config;

        if (!vcenterServerRef) {
            return;
        }

        const vcenterServerId = this.stringService.slug(vcenterServerRef);
        const clusters = await this.segroup.getNsxtClusters(vcenterServerId);

        this.clusterOptions = clusters.map((cluster: INsxtCluster) => {
            return createDropdownOption(cluster.vc_mobj_id, cluster.name);
        });
    }
}

SegroupNsxtPlacementScopeConfigController.$inject = [
    'stringService',
    'l10nService',
];

/**
 * @name segroupNsxtPlacementScopeConfig
 * @memberOf module:avi/service-engine-group
 * @property {module:avi/service-engine-group.SegroupNsxtPlacementScopeConfigController} controller
 * @property {module:avi/service-engine-group.segroupNsxtPlacementScopeConfigBindings} bindings
 * @description Component for configuring VCenter Scoping in the SEGroup modal.
 * @author alextsg
 */
export const segroupNsxtPlacementScopeConfigOptions = {
    /**
     * @mixin segroupNsxtPlacementScopeConfigBindings
     * @memberOf module:avi/service-engine-group
     * @property {SEGroup} segroup - SEGroup item.
     * @property {object} config - PlacementScopeConfig object to be configured.
     * @property {DropDownOption[]} vcenterServerDropdownOptions - Vcenterserver dropdown options.
     * @property {string[]} selectedVcenterServerRefs - List of already selected vcenterserver refs
     *     to hide from available vcenterserver dropdown options.
     * @property {Function} onSelectVcenterServer - Called when a vcenterserver has been selected.
     * @property {Function} onRemoveVcenterPlacementScopeConfig - Called to remove a
     *     PlacementScopeConfig.
     */
    bindings: {
        segroup: '<',
        config: '<',
        vcenterServerDropdownOptions: '<',
        selectedVcenterServerRefs: '<',
        onSelectVcenterServer: '&',
        onRemoveVcenterPlacementScopeConfig: '&',
    },
    controller: SegroupNsxtPlacementScopeConfigController,
    template,
};

const moduleName = 'ajs-legacy';
const componentName = 'ipam-dns-profiles-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const btnLabelIpamProfile = `${prefix}.btnLabelIpamProfile`;
export const btnLabelDnsProfile = `${prefix}.btnLabelDnsProfile`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleType]: 'Type',
    [btnLabelIpamProfile]: 'IPAM Profile',
    [btnLabelDnsProfile]: 'DNS Profile',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc directive
 * @name smartTitle
 * @restrict A
 * @params {string} smartTitle - Value to display on hover, passed by argument.
 * @author Alex M
 * @description
 *
 *     Adds title attribute to an element when clipping has been detected.
 *     Very naive approach is used to detect overflow event, confirmed to work with display:
 *     block or inline-block elements and always shown for inline elements.
 */
angular.module('aviApp').directive('smartTitle', function() {
    const overflowCheck = $elem => {
        if ($elem.css('display') === 'inline') {
            return true;
        }

        const [elem] = $elem;

        //might not work on IE11
        return elem.offsetWidth < elem.scrollWidth;
    };

    const eventName = 'mouseenter';

    const smartLabelLink = function($scope, $elem, attr) {
        const { smartTitle: label } = attr;

        const mouseEnter = () => {
            if (overflowCheck($elem)) {
                $elem.attr('title', label);
                //let's leave it alone once added
                $elem.off(eventName, mouseEnter);
            }
        };

        $elem.on(eventName, mouseEnter);
    };

    return {
        restrict: 'A',
        scope: false,
        link: smartLabelLink,
    };
});

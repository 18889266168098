/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

export enum WafAction {
    WAF_ACTION_NO_OP = 'WAF_ACTION_NO_OP',
    WAF_ACTION_BLOCK = 'WAF_ACTION_BLOCK',
    WAF_ACTION_ALLOW_PARAMETER = 'WAF_ACTION_ALLOW_PARAMETER',
}

enum WafPolicyPsmGroupMetricType {
    WAF_PSMLOCATION_SUM_MATCHED_METRIC = 'WAF_PSMLOCATION_SUM_MATCHED_METRIC',
    WAF_PSMRULE_SUM_MATCHED_METRIC = 'WAF_PSMRULE_SUM_MATCHED_METRIC',
    WAF_PSMRULE_SUM_EVALUATED_METRIC = 'WAF_PSMRULE_SUM_EVALUATED_METRIC',
}

export type WafPolicyPsmGroupMetricSumData = Partial<Record<WafPolicyPsmGroupMetricType, number>>;

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './pool-fail-action-setter.less';
import * as l10n from './pool-fail-action-setter.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const componentName = 'pool-fail-action-setter';

/** @alias poolFailActionSetter **/
class PoolFailActionSetterController {
    constructor($element, $scope, schemaService, Regex, l10nService) {
        this.$elem = $element;
        this.$scope = $scope;
        this.schemaService = schemaService;
        this.Regex = Regex;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this.$elem.addClass(componentName);

        const { schemaService } = this;

        this.types = schemaService.getEnumValues('FailActionEnum');

        if (this.noHttpTypes) {
            this.types = this.types.filter(({ value }) => value.indexOf('HTTP') === -1);
        }

        this.localRespStatusCodes =
                schemaService.getEnumValues('FailHttpStatusCode');

        this.httpProtocols =
                schemaService.getEnumValues('HTTPProtocol');

        this.httpRedirectStatusCodes =
                schemaService.getEnumValues('HTTPRedirectStatusCode');

        // for initial setup and external changes tracking (if any)
        // need local 'type' variable here so that onTypeChange event handler to do the job
        this.$scope.$watch(
            () => this.failAction.type,
            type => this.type = type,
        );
    }
}

PoolFailActionSetterController.$inject = [
        '$element',
        '$scope',
        'schemaService',
        'Regex',
        'l10nService',
];

/**
 * @ngdoc component
 * @name poolFailActionSetter
 * @author Alex Malitsky
 * @param {PoolFailActionConfig} failAction
 * @param {boolean=} noHttpTypes - Pass true to hide HTTP specific fail actions.
 * @param {Function} onTypeChange - To be called on new fail action type selection/removal.
 * @param {string} objectType - 'Pool' or 'PoolGroup'
 * @desc
 *     Form component to set up FailAction protobuf message for
 *     {@link Pool} and {@link PoolGroup}.
 */
angular.module('aviApp').component('poolFailActionSetter', {
    bindings: {
        failAction: '<',
        noHttpTypes: '<?',
        onTypeChange: '&',
        objectType: '@',
    },
    controller: PoolFailActionSetterController,
    templateUrl: `src/components/applications/pool/${componentName}/${componentName}.html`,
});

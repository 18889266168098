/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { difference } from 'underscore';
import {
    AviDropdownButtonPosition,
    BOTTOM_LEFT_ALIGNED_CONNECTED_POSITION,
    BOTTOM_RIGHT_ALIGNED_CONNECTED_POSITION,
    TOP_LEFT_ALIGNED_CONNECTED_POSITION,
    TOP_RIGHT_ALIGNED_CONNECTED_POSITION,
} from './avi-dropdown-button.constants';
import './avi-dropdown-button.component.less';

export interface IAviDropdownButtonAction {
    label: string;
    onClick(): void;
    disabled?(): boolean;
    hidden?(): boolean;
}

const connectedPositionMap = new Map([
    [AviDropdownButtonPosition.BOTTOM_RIGHT, BOTTOM_RIGHT_ALIGNED_CONNECTED_POSITION],
    [AviDropdownButtonPosition.BOTTOM_LEFT, BOTTOM_LEFT_ALIGNED_CONNECTED_POSITION],
    [AviDropdownButtonPosition.TOP_RIGHT, TOP_RIGHT_ALIGNED_CONNECTED_POSITION],
    [AviDropdownButtonPosition.TOP_LEFT, TOP_LEFT_ALIGNED_CONNECTED_POSITION],
]);

/**
 * @description Dropdown button component with auto-repositioning functionality. With the styles as
 * Clarity dropdown component.
 * @author alextsg, Zhiqian Liu
 */
@Component({
    selector: 'avi-dropdown-button',
    templateUrl: './avi-dropdown-button.component.html',
})
export class AviDropdownButtonComponent implements OnInit {
    /**
     * True to hide the dropdown caret.
     */
    @Input()
    public hideCaret = false;

    /**
     * List of actions shown in the dropdown menu.
     */
    @Input()
    public actions: IAviDropdownButtonAction[] = [];

    /**
     * True to use icon styling.
     */
    @Input()
    public useIcon = false;

    /**
     * True to not make menu automatically reposition according to the viewport.
     */
    @Input()
    public fixedPosition = false;

    /**
     * Disables and prevents interaction with the dropdown component.
     */
    @Input('disabled')
    private set setDisabled(disabled: boolean | '') {
        this.disabled = disabled === '' || disabled;
    }

    /**
     * @param position - Relative position to the button for showing the dropdown.
     */
    @Input('position')
    private set setPrimePosition(position: AviDropdownButtonPosition) {
        this.primePosition = position || AviDropdownButtonPosition.BOTTOM_RIGHT;
    }

    /**
     * Disabled state of the button.
     */
    public disabled = false;

    /**
     * Action variable used in *ngFor.
     */
    public action: IAviDropdownButtonAction;

    /**
     * List of dropdown positions to prioritize.
     */
    public positionsPriority: ConnectedPosition[];

    /**
     * The position with the highest priority when re-positioning the dropdown. If fixedPosition is
     * set to true, this will serve as the only position.
     */
    private primePosition: AviDropdownButtonPosition = AviDropdownButtonPosition.BOTTOM_RIGHT;

    /**
     * Subject to control dropdown button.
     */
    private dropdownButtonControlSubject = new Subject<boolean>();

    /** @override */
    public ngOnInit(): void {
        this.setPositionsPriority();
    }

    /**
     * Trackby function used in *ngFor.
     */
    public trackByAction(
        index: number,
        action: IAviDropdownButtonAction,
    ): IAviDropdownButtonAction['label'] {
        return action.label;
    }

    /**
     * Calls the action if it's not disabled and then hides the dropdown.
     */
    public onActionClick(action: IAviDropdownButtonAction): void {
        if (!this.disableAction(action)) {
            action.onClick();
            this.hideDropdown();
        }
    }

    /**
     * Disables an action if action.disabled exists and returns true.
     */
    public disableAction(action: IAviDropdownButtonAction): boolean {
        if (typeof action.disabled !== 'function') {
            return false;
        }

        try {
            return action.disabled();
        } catch (error) {
            throw new Error(`action.disabled failed: ${error}`);
        }
    }

    /**
     * Hides an action if action.hidden exists and returns true.
     */
    public hideAction(action: IAviDropdownButtonAction): boolean {
        if (typeof action.hidden !== 'function') {
            return false;
        }

        try {
            return action.hidden();
        } catch (error) {
            throw new Error(`action.hidden failed: ${error}`);
        }
    }

    /**
     * Return an observable to allow subscriptions to show and hide dropdown.
     */
    public get dropdownButtonControl$(): Observable<boolean> {
        return this.dropdownButtonControlSubject.asObservable();
    }

    /**
     * Returns the button classnames.
     */
    public get classNames(): string {
        return this.useIcon ? 'avi-dropdown-button__button--icon' : 'btn btn-primary';
    }

    /**
     * Set the priority of positions for auto-reposition.
     */
    private setPositionsPriority(): void {
        const rightAlignedPositions = [
            BOTTOM_RIGHT_ALIGNED_CONNECTED_POSITION,
            TOP_RIGHT_ALIGNED_CONNECTED_POSITION,
        ];

        const leftAlignedPositions = [
            BOTTOM_LEFT_ALIGNED_CONNECTED_POSITION,
            TOP_LEFT_ALIGNED_CONNECTED_POSITION,
        ];

        const primeConnectedPosition = connectedPositionMap.get(this.primePosition);

        if (this.fixedPosition) {
            this.positionsPriority = [primeConnectedPosition];
        } else {
            const isPrimePositionLeftAligned =
                leftAlignedPositions.includes(primeConnectedPosition);

            if (isPrimePositionLeftAligned) {
                // set primePosition to the top of the priority list
                this.positionsPriority = [
                    primeConnectedPosition,
                    ...difference(leftAlignedPositions, [primeConnectedPosition]),
                    ...rightAlignedPositions,
                ];
            } else { // primePosition is right-aligned
                this.positionsPriority = [
                    primeConnectedPosition,
                    ...difference(rightAlignedPositions, [primeConnectedPosition]),
                    ...leftAlignedPositions,
                ];
            }
        }
    }

    /**
     * Hide the dropdown.
     */
    private hideDropdown(): void {
        this.dropdownButtonControlSubject.next(false);
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  DnsRecordConfig
 * @description  DnsRecordConfig ConfigItem class.
 *
 *     Depending on type DNSRecord is using different properties to store main configuration
 *     portion, which is usually a repeated list or an object (for DNS_RECORD_CNAME).
 *     DNS Record with type A will be labelled as A/AAAA in UI,
 *     if it has both IPV4 and IPV6 IP addresses.
 */
function dnsRecordConfigFactory(ConfigItem, defaultValues, getIpAddr) {
    const DNS_RECORD_A = 'DNS_RECORD_A';
    const DNS_RECORD_SRV = 'DNS_RECORD_SRV';
    const DNS_RECORD_CNAME = 'DNS_RECORD_CNAME';
    const DNS_RECORD_NS = 'DNS_RECORD_NS';
    const DNS_RECORD_MX = 'DNS_RECORD_MX';
    const DNS_RECORD_TXT = 'DNS_RECORD_TXT';
    const DNS_RECORD_AAAA = 'DNS_RECORD_AAAA';

    class DnsRecordConfig extends ConfigItem {
        constructor(args) {
            const extendedArgs = angular.extend({ type: 'DnsRecord' }, args);

            super(extendedArgs);

            this.beforeEdit();
        }

        /**
         * Returns type of DNSRecord, value of DnsRecordType enum.
         * @returns {string}
         * @public
         */
        getType() {
            return this.getConfig()['type'];
        }

        /**
         * Used as transformAfterLoad.
         * @override
         **/
        beforeEdit() {
            const config = this.getConfig();

            if (!this.getType()) {
                [config['type']] = DnsRecordConfig.recordTypes;
            }

            this.onTypeChange();

            return config;
        }

        /** @override */
        dataToSave() {
            const config = super.dataToSave();

            if (this.isAOrAAAARecord()) {
                if (this.hasAAAARecord() && !this.hasARecord()) {
                    config.type = DNS_RECORD_AAAA;
                } else {
                    config.type = DNS_RECORD_A;
                }
            } else if (!this.isAOrAAAARecord()) {
                delete config.ip6_address;
                delete config.ip_address;
            }

            return config;
        }

        /**
         * Returns a string of record data based on the type.
         * @return {string}
         * @public
         */
        getRecordDataString() {
            const type = this.getType();
            const data = this.getRecordData_();
            const config = this.getConfig();

            switch (type) {
                case DNS_RECORD_A:
                case DNS_RECORD_AAAA: {
                    let ips = [];

                    if (Array.isArray(config.ip_address)) {
                        ips = ips.concat(config.ip_address);
                    }

                    if (Array.isArray(config.ip6_address)) {
                        ips = ips.concat(config.ip6_address);
                    }

                    const text = ips.map(ip => {
                        if (ip.ip6_address) {
                            return ip.ip6_address.addr;
                        } else if (ip.ip_address) {
                            return ip.ip_address.addr;
                        }

                        return '';
                    }).join(', ');

                    return `IP address${ips.length > 1 ? 'es' : ''}: ${text}`;
                }

                case DNS_RECORD_SRV: {
                    const { length } = data;

                    return `${length} Service Locator${length > 1 ? 's' : ''} ` +
                            'configured';
                }

                case DNS_RECORD_CNAME: {
                    return `CNAME: ${data['cname']}`;
                }

                case DNS_RECORD_NS: {
                    return data.map(({ ip_address: ipAddress, nsname }) => {
                        const ipLabel = ipAddress ? ipAddress['addr'] : 'N/A';

                        return `${nsname}: ${ipLabel}`;
                    }).join(', ');
                }

                case DNS_RECORD_MX: {
                    const dataString = data.reduce(
                        (acc, record) => `${acc}${record.host}: ${record.priority},`, '',
                    );

                    return `MX Recods: ${dataString}`;
                }

                case DNS_RECORD_TXT: {
                    const dataString = data.reduce(
                        (acc, record) => `${acc}${record.text_str},`, '',
                    );

                    return `Text Recods: ${dataString}`;
                }
            }
        }

        /**
         * Adds a new entry with the main configuration part of DNSConfig.
         * @public
         */
        addRecordDataEntry() {
            const data = this.getRecordData_();

            if (Array.isArray(data)) {
                data.push(this.getDefaultListElement_());
            } else {
                console.error(`Can't append an entry to the data of ${this.getType()} type`);
            }
        }

        /**
         * Removes entry from the list of main configuration part of DNSConfig.
         * @param {number} index
         * @public
         */
        removeRecordDataEntry(index) {
            if (angular.isUndefined(index)) {
                return;
            }

            const data = this.getRecordData_();

            if (Array.isArray(data)) {
                data.splice(index, 1);
            } else {
                console.error(`Can't remove an entry from the data of ${this.getType()} type`);
            }
        }

        /**
         * Updates properties based on current DNSRecord type.
         * @public
         */
        onTypeChange() {
            const
                config = this.getConfig(),
                type = this.getType();

            DnsRecordConfig.recordTypes.forEach(currentType => {
                const
                    { [currentType]: fieldName } = DnsRecordConfig.propNameByType,
                    fieldPresent = fieldName in config;

                if (type === currentType && type !== DNS_RECORD_A) {
                    if (!fieldPresent) {
                        config[fieldName] =
                                type === DNS_RECORD_CNAME ? this.getDefaultListElement_() : [];
                    }

                    const data = this.getRecordData_();

                    if (Array.isArray(data) && !data.length) {
                        data.push(this.getDefaultListElement_());
                    }
                } else if (fieldPresent) {
                    delete config[fieldName];
                }
            });
        }

        /**
         * Returns default configuration of usually repeated subobject of DNSRecord
         * configuration.
         * @returns {Object}
         * @protected
         */
        getDefaultListElement_() {
            const type = this.getType();

            switch (type) {
                case DNS_RECORD_SRV:
                    return defaultValues.getDefaultItemConfigByType('dnssrvrdata');

                default:
                    return {};
            }
        }

        /**
         * Returns the most meaningful part of DNSConfig configuration based on it's type.
         * @returns {Object|Object[]}
         * @protected
         */
        getRecordData_() {
            const
                config = this.getConfig(),
                type = this.getType(),
                { [type]: propName } = DnsRecordConfig.propNameByType;

            return config[propName];
        }

        /**
         * Adds empty IP for A Record.
         */
        addARecord() {
            const config = this.getConfig();

            if (!Array.isArray(config.ip_address)) {
                config.ip_address = [];
            }

            config.ip_address.push({
                ip_address: getIpAddr(),
            });
        }

        /**
         * Removes A Record at index.
         * @param {number} index
         */
        removeARecord(index = 0) {
            const config = this.getConfig();

            config.ip_address.splice(index, 1);
        }

        /**
         * Adds empty IP for AAAA Record.
         */
        addAAAARecord() {
            const config = this.getConfig();

            if (!Array.isArray(config.ip6_address)) {
                config.ip6_address = [];
            }

            config.ip6_address.push({
                ip6_address: getIpAddr(undefined, 'V6'),
            });
        }

        /**
         * Removes AAAA Record at index.
         * @param {number} index
         */
        removeAAAARecord(index = 0) {
            const config = this.getConfig();

            config.ip6_address.splice(index, 1);
        }

        /**
         * Checks if A Record is configured.
         * @return {boolean}
         */
        hasARecord() {
            const { ip_address: ips } = this.getConfig();

            return Array.isArray(ips) && ips.length > 0;
        }

        /**
         * Checks if AAAA Record is configured.
         * @return {boolean}
         */
        hasAAAARecord() {
            const { ip6_address: ips } = this.getConfig();

            return Array.isArray(ips) && ips.length > 0;
        }

        /**
         * Checks if DNS Record type is A or AAAA.
         * @return {boolean}
         */
        isAOrAAAARecord() {
            const { type } = this.getConfig();

            return type === DNS_RECORD_A || type === DNS_RECORD_AAAA;
        }
    }

    DnsRecordConfig.recordTypes = [
        DNS_RECORD_A,
        DNS_RECORD_SRV,
        DNS_RECORD_CNAME,
        DNS_RECORD_NS,
        DNS_RECORD_MX,
        DNS_RECORD_TXT,
    ];

    DnsRecordConfig.propNameByType = {};

    DnsRecordConfig.propNameByType[DNS_RECORD_SRV] = 'service_locator';
    DnsRecordConfig.propNameByType[DNS_RECORD_CNAME] = 'cname';
    DnsRecordConfig.propNameByType[DNS_RECORD_NS] = 'ns';
    DnsRecordConfig.propNameByType[DNS_RECORD_MX] = 'mx_records';
    DnsRecordConfig.propNameByType[DNS_RECORD_TXT] = 'txt_records';

    return DnsRecordConfig;
}

dnsRecordConfigFactory.$inject = [
    'ConfigItem',
    'defaultValues',
    'getIpAddr',
];

angular.module('aviApp').factory('DnsRecordConfig', dnsRecordConfigFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'logs';
const componentName = 'out-of-band-logs';
const prefix = `${moduleName}.${componentName}`;

export const outOfBandHeaderLabel = `${prefix}.outOfBandHeaderLabel`;
export const totalEventsPluralLabel = `${prefix}.totalEventsPluralLabel`;
export const gridColumnHeaderNameLabel = `${prefix}.gridColumnHeaderNameLabel`;
export const gridColumnHeaderEventLabel = `${prefix}.gridColumnHeaderEventLabel`;

export const ENGLISH = {
    [outOfBandHeaderLabel]: 'Out of Band Request',
    [totalEventsPluralLabel]: '{0, plural, one{# Event} other{# Events}}',
    [gridColumnHeaderNameLabel]: 'Datascript Name',
    [gridColumnHeaderEventLabel]: 'VS Datascript Event',
};

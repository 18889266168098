/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    behavior,
    bisector,
    layout as d3Layout,
    scale as d3Scale,
    time as d3Time,
    svg as d3Svg,
    format as d3Format,
    select as d3Select,
} from 'd3';

const d3 = {
    behavior,
    bisector,
    layout: d3Layout,
    scale: d3Scale,
    time: d3Time,
    svg: d3Svg,
    format: d3Format,
    select: d3Select,
};

//TODO drop
angular.module('aviApp').factory('ChartService', [
'$q', '$timeout', 'NamesHelper', 'myAccount',
function($q, $timeout, NamesHelper, myAccount) {
    /** Chart creates a chart object
     * Inputs:
     *     elm -- where we're appending to
     *     settings: object with
     *         padding -- padding object with top, left, bottom, right
     *         axisSettings -- object with any axis settings you feel like
     *                         (see createScales function)
     *         height, width -- if we don't want to have our chart try to calculate it
     *
     * Chart Properties:
     *     x, y -- d3 scales
     *         scale(value) -- > pixels
     *         scale.invert(pixels) --> value
     *     paths -- holds all the d3 paths so that we can remove, modify them, and
     *              transition them as necessary
     *     elm -- where we're append an svg
     *     g -- group element that all of our paths append to (it's got padding)
     *
     * Chart Prototype Methods:
     *     destroy -- destroys self
     *     createScales -- create scales based on collection that's passed in
     *     createAxes -- graphs axes
     *     area -- return d3 area function with useful defaults
     *     line -- returns d3 line function with useful defaults
     *     graphReasonCircles -- if settings allow it, will draw little circles at all
     *                           points where there is a reason string
     *     removeAllPaths -- transitions all paths to 0, returns promise
     *     updateCollection -- transitions current collection paths to new areas
     *     graphNewCollection -- generates scales, and graphs flat paths on the chart,
     *                           then calls updateCollection
     *     graphNewBarChartCollection, updateBarChartCollection, graphNewSingleArea,
     *     updateSingleArea -- same as graphNewCollection and updateCollection
     *     graphSingleLine -- used by healthscore sparkline to graph a single line
     */

    // --------- Helpers and Settings ---------- //
    const animationDuration = 250;

    // ---------- End Helpers and Settings ---------//

    // ============ Main Chart Class =============== //
    // elm -- dom element to append chart to
    // settings -- padding, height, width
    function Chart(elm, settings) {
        this.elm = elm;
        this.paths = {};
        this.settings = settings || {};
        settings.padding = settings.padding || {};

        const { padding } = settings;

        _.each(['top', 'left', 'bottom', 'right'], function(k) {
            padding[k] = padding[k] || 0;
        });

        // --------- Figuring out height and width ----------------//
        let h,
            w,
            count,
            curr;

        if (settings.height) {
            h = settings.height - padding.top - padding.bottom;
        } else {
            h = elm.height() - padding.top - padding.bottom;
            count = 0;
            curr = elm;

            while (h <= 0 && count < 5) {
                curr = curr.parent();
                h = elm.parent().height() - padding.top - padding.bottom;
                count++;
            }
        }

        if (settings.width) {
            w = settings.width - padding.left - padding.right;
        } else {
            w = elm.width() - padding.left - padding.right;

            // // grabbing parents in case it's been ng-hidden
            curr = elm;
            count = 0;

            // find a parent with a non-zero width
            while (w <= 0 && count < 5) {
                curr = curr.parent();
                w = curr.width() - padding.left - padding.right;
                count++;
            }
        }

        this.h = h;
        this.w = w;
        // -------------------- End Figuring out height and width --------------//

        this.el = d3.select(elm[0]);
        // creating the chart
        this.chartarea = this.el
            .append('svg')
            .classed('chart', true)
            .attr('width', w + padding.left + padding.right)
            .attr('height', h + padding.top + padding.bottom);

        this.g = this.chartarea
            .append('g')
            .attr('transform', `translate(${padding.left},${padding.top})`);

        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.zoomScale = 1;
    }

    Chart.prototype.setZoom = function(scale = this.zoomScale) {
        // Record the coordinates (in data space) of the center (in screen space).
        const { zoom } = this;
        const [center0, translate] = [zoom.center(), zoom.translate()];
        const coordinates = [this.w * 0.5, this.h];

        zoom.scale(scale);
        this.zoomScale = zoom.scale();

        const point = coordinates => {
            const [scale, translate] = [zoom.scale(), zoom.translate()];

            return [coordinates[0] * scale + translate[0], coordinates[1] * scale + translate[1]];
        };

        // Translate back to the center.
        const center1 = point(coordinates);

        zoom.translate([
            translate[0] + center0[0] - center1[0],
            translate[1] + center0[1] - center1[1]]);
        this.chartarea.transition().duration(250).call(zoom.event);
    };

    Chart.prototype.manualZoom = function(direction = 1) {
        direction = direction <= -1 ? -1 : 1;

        const { zoom } = this;

        this.chartarea.call(zoom.event); // https://github.com/mbostock/d3/issues/2387
        this.setZoom(zoom.scale() * 1.2 ** direction);
    };

    Chart.prototype.zoomIn = function() {
        this.manualZoom(1);
    };

    Chart.prototype.zoomOut = function() {
        this.manualZoom(-1);
    };

    Chart.prototype.destroy = function() {
        const self = this;

        _.each(self, function(val, key) {
            self[key] = null;
        });

        if (this.zoom && this.zoomEvents.length) {
            this.zoomEvents.forEach(function(event) {
                self.zoom.on(event, null);
            });
            this.zoom.on('zoom', null);
            this.zoom.on('zoomend', null);
        }

        self.destroyed = true;
    };

    // Creates scales
    // It will create the scales based on the collection
    Chart.prototype.createScales = function(coll) {
        const
            { series: seriesList } = coll,
            settings = {},
            [series] = seriesList,
            hiddenSeriesHash = {};

        let data;

        if (seriesList.length === 1) {
            const header = series.getHeader();

            settings.yMax = Math.max(
                header['metrics_min_scale'],
                series.getValue('max'),
            );

            data = series.getData();
        } else {
            coll.hiddenSeries.forEach(seriesName => hiddenSeriesHash[seriesName] = true);

            const shownSeries = seriesList.filter(series =>
                series && !(series.getId() in hiddenSeriesHash));

            if (shownSeries.length === 1) {
                const header = series.getHeader();

                settings.yMax = Math.max(
                    header['metrics_min_scale'],
                    series.getValue('max'),
                );

                data = shownSeries[0].getData();
            } else {
                if (!coll.totalSeries) {
                    console.error(
                        'ChartService.createScales: coll must include total series ' +
                        'if more than one series',
                    );
                }

                //check scales at first
                let max = shownSeries.reduce((base, series) =>
                    Math.max(base, series.getHeader()['metrics_min_scale']), 0);

                //time to go through datapoints
                shownSeries[0].getData().values
                    .forEach(({ timestamp }) => {
                        const summedValue = shownSeries.reduce((base, series) => {
                            const value = series.getValue('exact', timestamp);

                            return base + (angular.isNumber(value) ? value : 0);
                        }, 0);

                        max = Math.max(max, summedValue);
                    });

                data = coll.totalSeries.getData();//?
                settings.yMax = max;
            }
        }

        const { values } = data;

        settings.xMax = settings.xMax || values && values.length && values.slice(-1)[0].timestamp;
        settings.xMin = settings.xMin || values && values.length && values[0].timestamp;

        settings.yMin = 0;
        settings.yMax *= 1.1;

        // --------------- Creating Scales ----------------//
        // x-scale (either using utc or local time)
        this.x = myAccount.uiProperty.useUTCTime ?
            d3.time.scale.utc() : d3.time.scale();

        this.x.domain([settings.xMin, settings.xMax])
            .range([0, this.w])
            .clamp(true);

        // y-scale
        this.y = d3.scale.linear()
            .domain([settings.yMin, settings.yMax])
            .rangeRound([this.h, 0]);

        /**
         * Holds all zoom events attached to zoom listener.
         * @type {Array<string>}
         */
        this.zoomEvents = [];

        if (this.settings.useZoom) {
            this.zoom = d3.behavior.zoom()
                .center([this.w * 0.5, this.h])
                .scaleExtent([1, 50])
                .y(this.y);
            // Attach zoom event listener and remove panning events
            this.chartarea
                .call(this.zoom)
                .on('mousedown.zoom', null)
                .on('mousemove.zoom', null)
                .on('dblclick.zoom', null)
                .on('touchstart.zoom', null)
                .on('wheel.zoom', null)
                .on('mousewheel.zoom', null)
                .on('MozMousePixelScroll.zoom', null);
        }
    };

    /**
     * Adds listener callback to d3's zoom listener if chart is configured to useZoom.
     * @param {Function} callback - Zoom callback event listener.
     */
    Chart.prototype.onZoomEnd = function(callback) {
        if (this.zoom) {
            const zoomEventKey = (+new Date().getTime() * Math.random()).toFixed(0);
            const zoomEvent = `zoomend.${zoomEventKey}`;

            this.zoom.on(zoomEvent, callback);
            this.zoomEvents.push(zoomEvent);
        }
    };

    // Uses this.x and this.y to create axes
    Chart.prototype.createAxes = function() {
        const self = this;
        let format;

        if (this.settings['timeTickFormat']) {
            format = myAccount.uiProperty.useUTCTime ?
                d3.time.format.utc.multi(angular.copy(this.settings.timeTickFormat)) :
                d3.time.format.multi(angular.copy(this.settings.timeTickFormat));
        }

        if (this.destroyed) {
            $q.reject(false);
        }

        const deferred = $q.defer();

        this.axisSettings = {
            xTicks: 10,
            xTickPadding: 5,
            xTickSize: 1,
            xOrient: 'bottom',

            yTicks: Math.ceil(this.h / 50) + 1,
            yTickPadding: 10,
            yTickSize: -1,
            yOrient: 'left',
        };
        // If you want to pass in any axis settings
        _.extend(this.axisSettings, this.settings.axisSettings);

        const { axisSettings, h } = this;

        const numberOfAxes = 2;
        let timesCalled = 0;

        const areWeDoneYet = function() {
            if (++timesCalled === numberOfAxes) {
                $timeout(function() {
                    if (self.destroyed) {
                        deferred.reject();
                    } else {
                        deferred.resolve();
                    }
                });
            }
        };

        // Creating the axis
        if (!this.settings.noAxes) {
            const xAxis = d3.svg.axis()
                .scale(this.x)
                .ticks(axisSettings.xTicks)
                .tickSize(axisSettings.xTickSize)
                .tickPadding(axisSettings.xTickPadding)
                .orient(axisSettings.xOrient)
                .tickFormat(format);

            this.xAxis = xAxis;

            if (!this.xSvg) {
                this.xSvg = this.g.append('g')
                    .style('shape-rendering', 'crisp-edges')
                    .attr('class', 'x axis')
                    .attr('transform', `translate(${0},${h})`);

                this.xSvg.call(xAxis);
                areWeDoneYet();
            } else {
                this.xSvg
                    .transition()
                    .duration(animationDuration)
                    .call(xAxis)
                    .each(areWeDoneYet);
            }

            const yAxis = d3.svg.axis()
                .scale(this.y)
                .tickFormat(d3.format('s'))
                .ticks(axisSettings.yTicks)
                .tickSize(axisSettings.yTickSize)
                .orient(axisSettings.yOrient);

            this.y0Axis = yAxis;

            if (!this.ySvg) {
                this.ySvg = this.g.append('g')
                    .style('shape-rendering', 'crisp-edges')
                    .attr('class', 'y axis')
                    .attr('transform', `translate(${axisSettings.yTickSize},${0})`)
                    .classed('axis', true);

                this.ySvg.call(yAxis);
                areWeDoneYet();
            } else {
                this.ySvg
                    .transition()
                    .duration(animationDuration)
                    .call(yAxis)
                    .each(areWeDoneYet);
            }
        } else {
            deferred.resolve();
        }

        return deferred.promise;
    };

    // Easy access to d3.svg.area() with nice defaults
    Chart.prototype.area = function() {
        const { x, y } = this;

        return d3.svg.area()
            .interpolate('linear')
            .x(({ timestamp }) => x(timestamp))
            .y(({ value }) => y(value))
            .y0(({ y0 }) => y(y0 || 0))
            .y1(d => y(d.value + (d.y0 || 0)));
    };

    // Easy access to d3.svg.line() with nice defaults
    Chart.prototype.line = function() {
        const { x, y } = this;

        return d3.svg.line()
            .interpolate('linear')
            .x(({ timestamp }) => x(timestamp))
            .y(({ value }) => y(value));
    };

    // For all the values within the collection, this will graph a little circle
    // at any point where there is a reason string
    Chart.prototype.graphReasonCircles = function(coll) {
        const self = this;

        if (!this.settings.showReasons) {
            return;
        }

        const reasonArray = _.filter(coll.values, function(d) {
            return d.reason;
        });
        const reasonCircles = self.g.selectAll('circle.reasonShape')
            .data(reasonArray)
            .enter()
            .append('circle')
            .classed(coll.display.className, true)
            .attr('r', 0);

        reasonCircles
            .exit()
            .remove();

        reasonCircles
            .transition()
            .duration(animationDuration)
            .attr('cx', function(d) {
                return self.x(d.x);
            })
            .attr('cy', function(d) {
                return self.y(d.y);
            })
            .attr('r', 3);
    };

    // Animates all the paths in a collections from whatever they were previously
    // to their new values
    // Recreates scales and axes
    // Returns a promise
    Chart.prototype.updateCollection = function(coll) {
        const
            deferred = $q.defer(),
            self = this;

        let
            count,
            callbackCount,
            toGraph,
            toStack;// stacked error series

        if (this.destroyed || !coll) {
            return $q.reject(false);
        }

        if (coll.series.length > 1 && coll.errorSeries && coll.errorSeries.length) {
            return $q.reject('Can not graph errors with more than one primary series');
        }

        self.createScales(coll);
        self.createAxes();

        if (coll.errorSeries && coll.errorSeries.length) {
            toGraph = coll.series.concat(coll.errorSeries);
        } else {
            toGraph = coll.series;
        }

        const mappedSeries = _.map(toGraph, series => {
            if (coll.hiddenSeries.indexOf(series.getId()) !== -1) {
                return _.map(series.getData().values,
                    ({ timestamp }) => ({
                        timestamp,
                        value: 0,
                    }));
            } else {
                return series.getData().values.concat();
            }
        });

        // If we have more than one series, we need to stack it
        if (mappedSeries.length > 1) {
            if (coll.errorSeries && coll.errorSeries.length) {
                toStack = mappedSeries.slice(1);
            } else {
                toStack = mappedSeries;
            }

            d3.layout
                .stack()
                .offset('zero')
                .y(({ value }) => value)
                .x(({ timestamp }) => timestamp)(toStack);
        }

        const area = self.area();

        area
            .x(({ timestamp }) => this.x(timestamp))
            .y0(function(d) {
                return self.y(d.y0 || 0);
            })
            .y1(function(d) {
                return self.y(d.value + (d.y0 || 0));
            });

        const callback = function() {
            if (++callbackCount === count && !self.destroyed) {
                // Because sometimes the transition leaves some oddities
                _.each(coll.series, (series, i) => {
                    self.paths[series.getId()]
                        .attr('d', area(mappedSeries[i]));
                });

                self.graphReasonCircles(coll);

                $timeout(function() {
                    if (self.destroyed) {
                        deferred.reject();
                    } else {
                        deferred.resolve();
                    }
                });
            }
        };

        const drawAll = (function(graphsData, coll) {
            return function() {
                // Used to figure out when we're done with animation so that we can return promise
                callbackCount = 0;
                count = 0;

                self.graphReasonCircles(coll);

                _.each(graphsData, (series, i) => {
                    count++;

                    if (!self.paths[series.getId()]) {
                        console.warn(
                            'Got request to update but paths did not exist: ',
                            series.getId(),
                        );

                        return;
                    }

                    self.paths[series.getId()]
                        .attr('d', area(mappedSeries[i]))
                        .attr('transform', null)
                        .transition()
                        .each('end', callback);
                });
            };
        })(toGraph, coll);

        if (this.zoom) {
            this.zoom.on('zoom', () => {
                if (this.chartarea) {
                    this.chartarea.select('g.x.axis').call(this.xAxis);
                    this.chartarea.select('g.y.axis').call(this.y0Axis);
                    drawAll();
                }
            });
            this.setZoom();
        }

        drawAll();

        return deferred.promise;
    };

    // Animates the removal of all paths
    // Returns a promise
    Chart.prototype.removeAllPaths = function() {
        const
            self = this,
            deferred = $q.defer();

        let
            area,
            count,
            callbackCount;

        if (self.destroyed) {
            return $q.reject(false);
        }

        callbackCount = 0;
        count = 0;

        const callback = function() {
            if (++callbackCount === count) {
                _.each(self.paths, function(p) {
                    p.remove();
                });
                self.paths = {};
                // $q only updates during a digest cycle
                $timeout(function() {
                    if (self.destroyed) {
                        deferred.reject();
                    } else {
                        deferred.resolve();
                    }
                });
            }
        };

        if (this.limitLine) {
            this.limitLine.remove();
        }

        if (_.isEmpty(this.paths)) {
            deferred.resolve();
        } else {
            area = this.area();
            area.y1(function() {
                return self.h;
            })
                .y0(function() {
                    return self.h;
                });

            _.each(this.paths, function(path) {
                count++;
                path.transition()
                    .duration(animationDuration)
                    .attr('d', area(path.datum()))
                    .each('end', callback);
            });
        }

        return deferred.promise;
    };

    // Removes all paths that are currently on the graph, and then creates new paths
    // for all of the series in the coll
    // Returns a promise
    Chart.prototype.graphNewCollection = function(coll) {
        const self = this;

        if (!coll || !coll.series || !coll.series.length || this.destroyed) {
            return $q.reject('chart was destroyed or series are empty');
        }

        self.createScales(coll);
        self.createAxes();

        const area = this.area();

        return this.removeAllPaths().then(function() {
            let toCreate;

            if (self.destroyed) {
                return $q.reject('chart was destroyed');
            }

            area.y1(function() {
                return self.h;
            });
            area.y0(function() {
                return self.h;
            });

            if (coll.series.length === 1 && coll.errorSeries) {
                toCreate = coll.series.concat(coll.errorSeries);
            } else {
                toCreate = coll.series;
            }

            _.each(toCreate, series => {
                self.paths[series.getId()] = self.g.append('path')
                    .datum(series.getData().values)
                    .classed('area', true)
                    .classed(series.getColorClassName(), true)
                    .attr('d', area(series.getData().values));
            });

            return self.updateCollection(coll);
        });
    };

    Chart.prototype.graphNewBarChartCollection = function(coll) {
        const self = this;

        if (!coll || !coll.series || !coll.series.length || this.destroyed) {
            return $q.reject('chart was destroyed or series are empty');
        }

        self.createScales(coll);
        self.createAxes();

        const exampleSeries = coll.series[0].getData();
        const barWidth = +(this.w / (exampleSeries.values.length * 2)).toFixed(2);

        return this.removeAllPaths().then(() => {
            let toCreate;

            if (this.destroyed) {
                return $q.reject('chart was destroyed');
            }

            if (coll.series.length === 1 && coll.errorSeries) {
                toCreate = coll.series.concat(coll.errorSeries);
            } else {
                toCreate = coll.series;
            }

            _.each(toCreate, series => {
                const
                    { values } = series.getData(),
                    id = series.getId();

                _.each(values, (v, i) => {
                    this.paths[id + i] = this.g.append('rect');
                    this.paths[id + i]
                        .datum(v)
                        .attr('x', function(d) {
                            return self.x(d.timestamp) - (i ? barWidth / 2 : 0);
                        })
                        .attr('y', this.h)
                        .attr('width',
                            i || i === values.length - 1 ? barWidth : barWidth / 2)
                        .attr('height', 0)
                        .classed(series.getColorClassName(), true);
                });
            });

            return self.updateBarChartCollection(coll);
        });
    };

    Chart.prototype.updateBarChartCollection = function(coll) {
        const
            self = this,
            deferred = $q.defer();

        let
            toGraph,
            count,
            callbackCount;

        if (this.destroyed || !coll) {
            return $q.reject(false);
        }

        if (coll.series.length > 1 && coll.errorSeries && coll.errorSeries.length) {
            const errMsg = 'Can not graph errors with more than one primary series';

            console.error(errMsg);

            return $q.reject(errMsg);
        }

        const exampleSeries = coll.series[0].getData();
        const barWidth = this.w / (exampleSeries.values.length * 2);

        this.createScales(coll);
        this.createAxes();

        if (coll.series.length === 1 && coll.errorSeries && coll.errorSeries.length) {
            toGraph = coll.series.concat(coll.errorSeries);
        } else {
            toGraph = coll.series;
        }

        const mappedSeries = _.map(toGraph, series => {
            if (coll.hiddenSeries.indexOf(series.getId()) !== -1) {
                return _.map(
                    series.getData().values,
                    ({ timestamp }) => ({
                        timestamp,
                        value: 0,
                    }),
                );
            } else {
                return series.getData().values.concat();
            }
        });

        if (mappedSeries.length > 1) {
            let toStack;

            if (coll.errorSeries && coll.errorSeries.length) {
                // Only stack the errors, check at beginning of function
                // makes sure there is only one series if there are errors
                toStack = mappedSeries.slice(1);
            } else {
                toStack = mappedSeries;
            }

            d3.layout
                .stack()
                .offset('zero')
                .y(({ value }) => value)
                .x(({ timestamp }) => timestamp)(toStack);
        }

        callbackCount = 0;
        count = 0;

        const callback = function() {
            if (++callbackCount === count && !self.destroyed) {
                self.graphReasonCircles(coll);

                $timeout(function() {
                    if (self.destroyed) {
                        deferred.reject();
                    } else {
                        deferred.resolve();
                    }
                });
            }
        };

        _.each(toGraph, function(series, index) {
            const id = series.getId();

            _.each(mappedSeries[index], function(v, i) {
                count++;

                if (!self.paths[id + i]) {
                    console.warn('Got request to update but paths did not exist: ', id + i);

                    return;
                }

                self.paths[id + i]
                    .datum(v)
                    .transition()
                    .each('end', callback)
                    .duration(animationDuration)
                    .attr('x', function(d) {
                        return self.x(d.timestamp) - (i ? barWidth / 2 : 0);
                    })
                    .attr('y', function(d) {
                        return self.y((d.y0 || 0) + d.value);
                    })
                    .attr('width',
                        i || i === series.getData().values.length - 1 ? barWidth : barWidth / 2)
                    .attr('height', function(d) {
                        return self.h - self.y(d.value);
                    });
            });
        });

        this.graphReasonCircles();

        return deferred.promise;
    };

    // ------------ Single Area Code ------------- //
    // Note: We don't ever worry about animating other than the initial animation
    // And then the animations on update
    Chart.prototype.updateSingleArea = function(dataSeries) {
        const deferred = $q.defer();

        if (!dataSeries) {
            return $q.reject(false);
        }

        this.createScales({
            series: [dataSeries],
        });

        if (this.singleArea && this.singleAreaLine) {
            const
                self = this,
                { x } = this;

            const area = self.area()
                .x(({ timestamp }) => x(timestamp))
                .y0(self.h)
                .y1(function(d) {
                    return self.y(d.value);
                });

            let callbackCount = 0;

            const callback = function() {
                if (++callbackCount === 2) {
                    $timeout(function() {
                        deferred.resolve();
                    });
                }
            };

            const draw = function() {
                self.singleArea
                    .attr('d', area(dataSeries.getData().values))
                    .attr('transform', null)
                    .transition()
                    .ease('linear')
                    .attr('transform', `translate(${x(-1)})`)
                    .duration(animationDuration)
                    .each('end', callback);

                self.singleAreaLine
                    .attr('d', self.line()(dataSeries.getData().values))
                    .attr('transform', null)
                    .transition()
                    .ease('linear')
                    .attr('transform', `translate(${x(-1)})`)
                    .duration(animationDuration)
                    .each('end', callback);
            };

            draw();
        } else {
            $timeout(function() {
                deferred.resolve();
            });
        }

        return deferred.promise;
    };

    Chart.prototype.graphNewSingleArea = function(series) {
        this.createScales({
            series: [series],
        });

        const area = this.area()
            .y0(this.h)
            .y1(this.h);

        const line = this.line().y(this.h);

        this.singleArea = this.g.append('path')
            .attr('d', area(series.getData().values))
            .classed('area', true)
            .classed(series.getColorClassName(), true);

        this.singleAreaLine = this.g.append('path')
            .attr('d', line(series.getData().values))
            .classed('line', true)
            .classed(series.getColorClassName(), true);

        return this.updateSingleArea(series);
    };
    // ----------- End Single Area Code ----------- //

    // This animates from left to right -- it's currently only used by healthscore-sparkline
    Chart.prototype.graphSingleLine = function(series) {
        const self = this;

        this.createScales({
            series: [series],
        });

        const line = this.line();

        _.each(['clipPath', 'singleLine'], function(l) {
            if (self[l]) {
                self[l].remove();
            }
        });

        // Using clipping paths to animate the line
        this.clipPath = this.g.append('clipPath');
        this.clipPath.attr('id', 'myClippingRect');

        this.clippingRect = this.clipPath.append('rect');
        this.clippingRect
            .attr('width', 0)
            .attr('height', this.h)
            .attr('x', 0)
            .attr('y', 0);

        this.singleLine = this.g.append('path');
        this.singleLine
            .datum(series.getData().values)
            .classed(series.getColorClassName(), true)
            .attr('d', line)
            .classed('line', true)
            .attr('clip-path', 'url(#myClippingRect)');

        this.clippingRect
            .transition()
            .duration(animationDuration / 2)
            .attr('width', this.w)
            .ease('linear');
    };

    Chart.prototype.findByX = function(series, xCoord) {
        xCoord -= 0;

        const { values: array } = series.getData();

        let i;

        if (!array || !array.length) {
            return {};
        }

        if (array.length < 2) {
            return array[0];
        }

        const bisect = d3.bisector(function(a) {
            return a.timestamp;
        }).right;

        i = bisect(array, xCoord);

        if (i < 1) {
            i = 1;
        } else if (i > array.length - 1) {
            i = array.length - 1;
        }

        if (Math.abs(xCoord - array[i].timestamp) < Math.abs(xCoord - array[i - 1].timestamp)) {
            return array[i];
        } else {
            return array[i - 1];
        }
    };

    return Chart;
}]);

/**
 * @module LoginModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Output,
} from '@angular/core';

import { Auth } from 'ajs/modules/core';

import './reset-password.component.less';

import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './reset-password.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Password recovery component
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent {
    /**
     * Fires when recovery email is sent to the user.
     */
    @Output()
    public onEmailSent: EventEmitter<void> = new EventEmitter();

    /**
     * Fires when User wants to go back to login screen
     */
    @Output()
    public backToSignIn: EventEmitter<void> = new EventEmitter();

    /**
     * Flag to track passwordChangeRequest status.
     */
    public mailingInProgress = false;

    /**
     * ngModel for recovery email
     */
    public recoveryEmail = '';

    /**
     * Placeholder for error message from server.
     */
    public errorMessage = '';

    /**
     * Get keys from souce bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    public constructor(
        private readonly authService: Auth,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }
    /**
     * Initiates request to Send password recovery procedures to the mail
     * provided by the user.
     */
    public sendRecoveryMail(): void {
        this.mailingInProgress = true;

        this.authService.passwordChangeRequest(this.recoveryEmail)
            .then(() => {
                this.onEmailSent.emit();
            })
            .catch((errorObject: any) => {
                this.errorMessage = errorObject.error;

                this.mailingInProgress = false;
            });
    }

    /**
     * Navigates back to login screen.
     */
    public goToSignIn(): void {
        this.backToSignIn.emit();
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { portalStatus } from 'ajs/modules/cportal/constants/cportal.constants';
import './case-list.less';
import * as l10n from './case-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const componentName = 'case-list';

class CaseListController {
    constructor(
        $element,
        CaseCollection,
        CRUDGridConfig,
        l10nService,
    ) {
        this.$element_ = $element;
        this.CaseCollection_ = CaseCollection;
        this.CRUDGridConfig_ = CRUDGridConfig;

        /**
         * Default Avi cloud connected status.
         * @type {boolean}
         */
        this.aviCloudConnected = false;

        /**
         * Default controller registration status.
         * @type {boolean}
         */
        this.controllerRegistered = false;

        /**
        * Get keys from source bundles for template usage.
        */
        this.l10nKeys = l10nKeys;
        this.l10nService_ = l10nService;
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    $onInit() {
        const { l10nService_: l10nService } = this;

        this.$element_.addClass(componentName);

        const gridConfigOptions = {
            collection: new this.CaseCollection_({ isStatic: false }),
            id: 'case-list-page',
            multipleactions: [],
            singleactions: [{
                title: l10nService.getMessage(l10nKeys.attachmentBtnLabel),
                class: 'sl-icon-paper-clip',
                hidden: row => !row.getConfig().case_attachments,
            }, {
                title: l10nService.getMessage(l10nKeys.editBtnLabel),
                class: 'icon-pencil-4',
                do: row => row.edit(),
            }],
            defaultSorting: '',
            fields: [
                {
                    name: 'created_date',
                    title: l10nService.getMessage(l10nKeys.columnTitleDateOpened),
                    template: '{{ row.getConfig().created_date | aviDate }}',
                },
                {
                    name: 'case_number',
                    title: l10nService.getMessage(l10nKeys.columnTitleCaseNumber),
                    template: '{{ row.getConfig().case_number }}',
                },
                {
                    name: 'subject',
                    title: l10nService.getMessage(l10nKeys.columnTitleSubject),
                    template: '{{ row.getConfig().subject }}',
                },
                {
                    name: 'type',
                    title: l10nService.getMessage(l10nKeys.columnTitleType),
                    template: '{{ row.getConfig().type }}',
                },
                {
                    name: 'last_modified_date',
                    title: l10nService.getMessage(l10nKeys.columnTitleLastUpdated),
                    template: '{{ row.getConfig().last_modified_date | aviDate }}',
                },
                {
                    name: 'status',
                    title: l10nService.getMessage(l10nKeys.columnTitleStatus),
                    template: '<span class="case-list__case-status">{{ ::row.getStatus() }}</span>',
                },
            ],
            permission: 'PERMISSION_CONTROLLER',
            layout: {
                hideSearch: true,
                hideEditColumns: true,
            },
        };

        this.updateStatus_(this.cportalService.getRegistrationStatusDetails());

        this.gridConfig = new this.CRUDGridConfig_(gridConfigOptions);
    }

    /**
     * Update's Controller registration and AlbServices connectivity status
     * @protected
     */
    updateStatus_(data) {
        const { ALBSERVICES_REGISTERED, ALBSERVICES_CONNECTED } = portalStatus;

        this.controllerRegistered =
            data.registration_status === ALBSERVICES_REGISTERED;
        this.aviCloudConnected =
            data.connectivity_status === ALBSERVICES_CONNECTED;
    }

    /**
     * @override
     */
    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

CaseListController.$inject = [
    '$element',
    'CaseCollection',
    'CRUDGridConfig',
    'l10nService',
];

/**
 * @ngdoc component
 * @name CaseList
 * @author Ashish Verma, Ram Pal
 * @description Case list page with options to create/edit a case.
 */
angular.module('avi/cportal').component('caseList', {
    bindings: {
        cportalService: '<loadedPulseRegistrationStatus',
    },
    controller: CaseListController,
    templateUrl: 'src/components/pages/administration/support/portal/case-list.html',
});

/**
 * @module CoreModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

interface IBooleanLabelPairs {
    enabled: string[];
    allowed: string[];
    switch: string[];
    yes: string[];
    active: string[];
}

const pairs: IBooleanLabelPairs = {
    enabled: ['Enabled', 'Not Enabled'],
    allowed: ['Allowed', 'Disallowed'],
    switch: ['Disable', 'Enable'],
    yes: ['Yes', 'No'],
    active: ['Active', 'Suspended'],
};

/**
 * @desc
 *
 *   Translates boolean values into text labels. Default is "True" and "False".
 *
 * @author
 *   Akul Aggarwal, Alex Malitsky
 */
export function booleanLabelFilter(boolVal: boolean, type: string, lowerCase: boolean): string {
    let output: string;

    if (type && type in pairs) {
        const pair = pairs[type];

        output = boolVal ? pair[0] : pair[1];
    } else {
        output = boolVal ? 'True' : 'False';
    }

    if (lowerCase) {
        output = output.toLowerCase();
    }

    return output;
}

/**
 * @desc AngularJs filter factory to be registered on the module.
 */
export function booleanLabelFilterFactory(): typeof booleanLabelFilter {
    return booleanLabelFilter;
}

/**
 * @module ScriptModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { IAlertScriptConfig } from 'generated-types';
import { Item } from 'ajs/modules/data-model';

/**
 * @description
 *
 *   Control Script item.
 *
 * @author Rachit Aggarwal
 */
export class ControlScript extends Item<IAlertScriptConfig> {
    constructor(args: {}) {
        const extendedArgs = {
            ...args,
            objectName: 'alertscriptconfig',
            objectType: 'Alertscriptconfig',
            permissionName: 'PERMISSION_ALERTCONFIG',
            windowElement: 'prof-controlscripts-create',
        };

        super(extendedArgs);
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './ipam-dns-internal-profile-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @typedef {('IPAMDNS_TYPE_INTERNAL'|'IPAMDNS_TYPE_INTERNAL_DNS')} IpamDnsInternalProfileType
 * @memberOf module:avi/ipam
 */

/**
 * @constructor
 * @memberOf module:avi/ipam
 * @mixes module:avi/ipam.ipamDnsInternalProfileConfigComponentBindings
 * @see {@link module:avi/ipam.ipamDnsInternalProfileConfigComponent}
 */
class IpamDnsInternalProfileConfigController {
    constructor(
        Regex,
        CloudCollection,
        SubnetListNetworkCollection,
        l10nService,
    ) {
        this.Regex = Regex;
        this.cloudCollection = new CloudCollection();
        this.subnetListNetworkCollection = new SubnetListNetworkCollection();
        /**
        * Get keys from source bundles for template usage
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    $onInit() {
        this.cloudRef = '';
    }

    /**
     * Sets Cloud UUID for Network list collection.
     * @param {module:avi/cloud.Cloud} cloud - Cloud instance.
     */
    setCloud(cloud) {
        this.subnetListNetworkCollection.setParams({ cloud_uuid: cloud.id });
    }

    /**
     * @override
     */
    $onDestroy() {
        this.cloudCollection.destroy();
        this.subnetListNetworkCollection.destroy();
    }
}

IpamDnsInternalProfileConfigController.$inject = [
    'Regex',
    'CloudCollection',
    'SubnetListNetworkCollection',
    'l10nService',
];

/**
 * @name ipamDnsInternalProfileConfigComponent
 * @memberOf module:avi/ipam
 * @property {module:avi/ipam.IpamDnsInternalProfileConfigController} controller
 * @property {module:avi/ipam.ipamDnsInternalProfileConfigComponentBindings} bindings
 * @description Config component for Internal IPAM types.
 * @author alextsg
 */
angular.module('avi/ipam').component('ipamDnsInternalProfileConfig', {
    /**
     * @mixin ipamDnsInternalProfileConfigComponentBindings
     * @memberOf module:avi/ipam
     * @property {module:avi/ipam.IpamDnsInternalProfileConfigItem} editable
     * @property {module:avi/ipam.IpamDnsInternalProfileType} type
     */
    bindings: {
        editable: '<profile',
        type: '@',
    },
    controller: IpamDnsInternalProfileConfigController,
    templateUrl: 'src/components/templates/profiles/ipam-dns-profiles-modal/' +
        'ipam-dns-internal-profile-config/ipam-dns-internal-profile-config.component.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  TrafficCloneProfileCollection
 * @description  Collection of {TrafficCloneProfile} items.
 */
const CollectionFactory = (Collection, TrafficCloneProfile) => {
    class TrafficCloneProfileCollection extends Collection {}

    angular.extend(TrafficCloneProfileCollection.prototype, {
        objectName_: 'trafficcloneprofile',
        itemClass_: TrafficCloneProfile,
        windowElement_: 'traffic-clone-profile-modal',
        serverDefaultsOverride_: {
            cloud_ref: undefined,
        },
    });

    return TrafficCloneProfileCollection;
};

CollectionFactory.$inject = [
        'Collection',
        'TrafficCloneProfile',
];

angular.module('aviApp').factory('TrafficCloneProfileCollection', CollectionFactory);

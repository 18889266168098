/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc constant
 * @name analyticsProfileAnalyticsTypeLabelHash
 * @author Chitra
 * @description Hashmap for AnalyticsType used for gathering analytics in AnalyticsProfile creation.
 */
export const analyticsProfileAnalyticsTypeLabelHash = {
    enable_vs_analytics: 'Virtual Services',
    enable_server_analytics: 'Servers',
    enable_se_analytics: 'Service Engines',
};

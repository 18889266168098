/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../../less/components/autocomplete.less';

/**
 * @ngdoc directive
 * @name eAutoComplete
 * @restrict E
 *
 * @param {*} ngModel
 * @param {string=} isCustom - Boolean flag whether it is custom or not.
 * @param {Object[]} suggestions - Predefined options used for search suggestions.
 * @param {string} suggestions[].id
 * @param {*} suggestions[].value
 * @param {string} suggestions[].displayValue
 * @param {boolean=} loading - Boolean flag to show a loading spinner inside input field.
 * @param {Function=} onSelect - Callback on predefined value selection event.
 * @param {string=} placeholder - Placeholder text for input field.
 * @param {string=} ngPattern
 * @param {string=} ngRequired
 * @param {string=} preselectOnlyOption - When set to true and we will take the only option from
 *     suggestions when ngModel is not set.
 *
 * @description
 * Dropdown with ability to save custom user's input.
 * Supports separation between displayed values and actually populated into 'ngModel' property.
 * So that you can replace text input with any other value (possibly of a different type)
 * associated with this string (displayValue property) by one of the suggestions.
 * When 'isCustom' attribute is set will update corresponding value on every input change to
 * distinguish between user provided input and predefined values.
 * Predefined values are being passed by 'suggestions' attribute with an array or dictionary of
 * objects { id(optional), value, displayValue } Letter "e" means that you don't need to ng-repeat
 * options inside directive, but can pass appropriate array into suggestions property.
**/

//TODO add ng-required support (it breaks suggestions now)
angular.module('aviApp').directive('eAutoComplete', function() {
    function link(scope, elem, attr, ngModel) {
        scope.isRequired = !_.isUndefined(attr.required);
        scope.inputSize = false;

        function findValueInSuggestions(value) {
            return _.find(scope.suggestions, function(suggestion) {
                return suggestion.displayValue === value || suggestion.value === value;
            });
        }

        function updateIsCustom(val) {
            if (!_.isUndefined(attr.isCustom)) {
                scope.isCustom = val;
            }
        }

        //sync between intNgModel and just ngModel
        function updateNgModel(oldVal, newVal) {
            if (oldVal !== newVal) { //no need to do it on init, ngModal value has priority
                if (scope.intNgModel) {
                    const predefinedValue = findValueInSuggestions(scope.intNgModel);

                    ngModel.$setViewValue(
                        predefinedValue ? predefinedValue.value : scope.intNgModel,
                    );
                    updateIsCustom(!predefinedValue);
                } else {
                    ngModel.$setViewValue(scope.intNgModel);
                    updateIsCustom(undefined);
                }
            }
        }

        //check out this awesome infinite loop here:
        scope.$watch(() => scope.intNgModel, updateNgModel);

        ngModel.$render = function() {
            scope.intNgModel = ngModel.$viewValue;

            if (ngModel.$viewValue) {
                const predefinedValue = findValueInSuggestions(scope.intNgModel);

                if (predefinedValue) { //predefined
                    if (scope.intNgModel !== predefinedValue.value &&
                        scope.intNgModel !== predefinedValue.displayValue) {
                        scope.intNgModel = predefinedValue.displayValue || predefinedValue.value;
                    }
                } else { //custom
                    scope.intNgModel = ngModel.$viewValue;
                }
            } else { //ngModel is not set
                scope.intNgModel = ngModel.$viewValue;
            }
        };

        scope.$watchCollection('suggestions', function(suggestions) {
            if (scope.intNgModel) {
                const predefinedValue = findValueInSuggestions(scope.intNgModel);

                updateIsCustom(!predefinedValue);

                if (predefinedValue || ngModel.$viewValue !== scope.intNgModel) {
                    ngModel.$setViewValue(
                        predefinedValue ? predefinedValue.value : scope.intNgModel,
                    );
                }
            } else if (!_.isUndefined(scope.preselectOnlyOption) && suggestions &&
                Array.isArray(suggestions) && suggestions.length === 1) {
                scope.intNgModel = suggestions[0].displayValue || suggestions[0].value;
            }
        });

        elem.on('mousedown mouseup click', function(e) {
            e.stopPropagation();
        });

        const mediator = {};

        const childTempl = $('<div/>')
            .addClass('suggestions')
            .on('mouseleave', function() {
                setSelected(null);
            });

        const listTempl = $('<div/>').addClass('list');

        let list,
            isOpened,
            selected,
            child,
            input,
            filtered = [],
            activeScroll = false,
            scrollTimeout = false;

        const setSelected = function(val) {
            selected = val;

            if (list) {
                list.find('tr').each(function(key) {
                    if (val !== null && key === +val) {
                        $(this).addClass('selected');
                    } else {
                        $(this).removeClass('selected');
                    }
                });
            }

            //console.log('ta setSelected: %s, got value: %s', selected, val);
            return selected;
        };

        const hide = function() {
            mediator.opened = false;
            isOpened = false;

            setSelected(null);

            if (child) {
                child.hide();
            }
        };

        const resize = function() {
            let height,
                width;

            if (child) {
                height = elem.outerHeight();
                width = elem.innerWidth();
                child.css({
                    top: height + 3,
                    width: width - 4,
                });
            }
        };

        const render = function() {
            const quotesTrim = /^['"](.*?)['"]?$/g;

            let template;

            const provided = (ngModel.$viewValue || '').replace(quotesTrim, '$1');

            filtered = (scope.suggestions || []).map(function(val, key) {
                if (typeof val.id === 'undefined') {
                    val.id = key;
                }

                /*if(typeof val.value === 'undefined'){
                 val.value = val.name;
                 }*/
                if (typeof val.displayValue === 'undefined') {
                    val.displayValue = val.value.replace(quotesTrim, '$1');
                }

                if (provided) {
                    val.pos = val.displayValue.toLowerCase().indexOf(provided.toLowerCase());
                }

                return val;
            });

            if (provided) {
                filtered = filtered.filter(function(obj) {
                    return obj.displayValue && obj.pos !== -1 &&
                        obj.displayValue.length !== provided.length;
                });
            }

            list.children().remove();

            if (!filtered.length) {
                child.hide();
                mediator.opened = false;
                isOpened = false;
            } else {
                //console.log(filtered);
                template = '<table>';

                filtered.forEach(function(obj, i) {
                    template += `<tr${i === selected ? ' class="selected"' : ''
                    }><td class="value">`;

                    if (provided && obj.pos !== -1) {
                        template +=
                            `<span>${obj.displayValue.slice(0, obj.pos)}</span>` +
                            `<span class="provided">${obj.displayValue.slice(
                                obj.pos, obj.pos + provided.length,
                            )}</span>` +
                            `<span>${obj.displayValue.slice(obj.pos + provided.length)
                            }</span>`;
                    } else {
                        template += obj.displayValue;
                    }

                    template += '</td>';

                    if (typeof obj.descr !== 'undefined') {
                        template += `<td class="descr">${obj.descr}</td>`;
                    }

                    if (typeof obj.percent !== 'undefined') {
                        template += `<td class="percent">${obj.percent.toFixed(1)}%</td>`;
                    }

                    if (typeof obj.descr !== 'undefined' || typeof obj.descr !== 'undefined') {
                        template += '<td class="padding"></td>';
                    }

                    template += '</tr>';
                });

                template += '</table>';

                $(template).appendTo(list);

                child.show();
                mediator.opened = true;
                isOpened = true;
                setSelected(0);

                list.find('tr')
                    .on('mousedown', function(e) {
                        setEventTimeout('suggClicked');
                        select();
                        setTimeout(function() {
                            input.trigger('focus');
                        });
                        //console.log('TA list mousedown, selected: %s', selected);
                    })
                    .on('mouseover', function(e) {
                        if (!activeScroll) {
                            setSelected($(this).data('key'));
                        }
                    })
                    .each(function(key) {
                        $(this).data({ key });
                    });
            }
        };

        const up = function() {
            const { length } = list.find('tr');

            if (selected === null) {
                setSelected(0);
            } else if (selected === 0) {
                setSelected(length - 1);
            } else {
                setSelected(selected - 1);
            }

            checkIfVisible();
        };

        const down = function() {
            const { length } = list.find('tr');

            if (selected === null || selected + 1 === length) {
                setSelected(0);
            } else {
                setSelected(selected + 1);
            }

            checkIfVisible();
        };

        const tab = function(event) {
            if (!filtered.length) { return; }

            if (selected === null) {
                setSelected(0);
                event.preventDefault();/* prevent outOfFocus */
            }

            select();
            event.stopPropagation();
        };

        const select = function() {
            if (attr.onSelect) {
                scope.onSelect({ id: filtered[selected].id });
            } else {
                scope.intNgModel = filtered[selected].displayValue;
            }

            hide();
            scope.$apply();
            //render();
        };

        const autoScrollFinished = function() {
            if (scrollTimeout) {
                clearTimeout(scrollTimeout);
            }

            scrollTimeout = setTimeout(function() {
                activeScroll = false;
            }, 150);
        };

        const checkIfVisible = function() {
            const { length } = list.find('tr');

            let parent,
                pos,
                shift = false,
                elem;

            if (selected === null) {
                child.scrollTop(0);
                console.warn('TA.checkIfVisible: Asked to scroll for null value.');
            } else if (length && selected < length) {
                elem = $(list.find('tr')[selected]);
                pos = {
                    top: elem.position().top,
                    height: elem.outerHeight(),
                };

                parent = {
                    scroll: child.scrollTop(),
                    height: child.innerHeight(),
                };

                if (pos.top < 0) {
                    shift = pos.top;
                } else if (pos.top + pos.height > parent.height) {
                    shift = pos.top + pos.height - parent.height;
                }

                if (shift) {
                    if (scrollTimeout) {
                        clearTimeout(scrollTimeout);
                        scrollTimeout = false;
                    }

                    activeScroll = true;
                    child.animate({
                        scrollTop: `+=${shift}`,
                    }, {
                        duration: 49,
                        complete: autoScrollFinished,
                    });
                }
            }
        };

        /* used for filtering focusout and enter events in different wrappers */
        const setEventTimeout = function(name) {
            if (mediator[name]) {
                clearTimeout(mediator[name]);
            }

            mediator[name] = setTimeout(function() {
                mediator[name] = false;
            });
        };

        input = elem.find('input');

        input
            .on('focusin', function() {
                //console.log('TA: input focusIn event fired.');
                if (!child) {
                    //console.log('TA input focusIn: Child creation.');
                    child = childTempl.appendTo(elem[0]);
                    list = listTempl.appendTo(child);
                }

                resize();
                render();
            })
            .on('focusout', function(e) {
                hide();

                if (!mediator.suggClicked) {
                    //console.log('TA focusout remove fired.');
                    child.remove();
                    child = false;
                    list = false;
                }
            })
            .on('keyup', function(event) {
                if (event.which === 38 || event.which === 40 ||
                    event.which === 9 || event.which === 13 ||
                    event.which === 27) {
                    event.stopPropagation();

                    return;
                }

                render();
            })
            .on('keypress keydown', function(event) {
                //console.log('TA.key event, type: %s, button: %s', event.type, event.which);
                if (event.which === 38) {
                    if (isOpened) {
                        up();
                        event.preventDefault();
                    } else {
                        render();
                        //console.log('TA: up button with closed popover.');
                    }
                } else if (event.which === 40) {
                    if (isOpened) {
                        down();
                        event.preventDefault();
                    } else {
                        render();
                        //console.log('TA: down button with closed popover.');
                    }
                } else if (event.which === 9) {
                    if (isOpened) {
                        setEventTimeout('tabClicked');
                        tab(event);
                        event.preventDefault();
                    }
                } else if (event.which === 13) {
                    if (event.type === 'keydown' && isOpened) {
                        if (selected === null) {
                            hide();
                        } else {
                            setEventTimeout('suggClicked');
                            select();
                        }
                    }
                // esc
                } else if (event.which === 27) {
                    if (isOpened) {
                        setEventTimeout('escClicked');
                        hide();
                    }

                    event.stopPropagation();
                }
            });

        scope.$on('$repaintViewport', function() {
            if (child) { resize(); }
        });

        scope.$on('userLoggedOut', function() {
            if (child) { child.remove(); }
        });

        scope.$on('$destroy', function() {
            if (child) { child.remove(); }
        });
    }

    return {
        restrict: 'E',
        require: 'ngModel',
        scope: {
            ngModel: '=',
            isCustom: '=?', //custom or selected from the list
            suggestions: '=', //[{id(optional), value, displayValue(optional)}]
            loading: '=',
            onSelect: '&',
            placeholder: '@',
            ngPattern: '=',
            ngRequired: '=',
            preselectOnlyOption: '@',
        },
        link,
        templateUrl: 'src/views/components/e-auto-complete.html',
    };
});

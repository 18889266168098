const moduleName = 'ajs-legacy';
const componentName = 'ipam-dns-profiles-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const customParamNameInputLabel = `${prefix}.customParamNameInputLabel`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const typeInputPlaceholder = `${prefix}.typeInputPlaceholder`;
export const allocateIpInVrfInputLabel = `${prefix}.allocateIpInVrfInputLabel`;
export const awsProfileConfigurationHeader = `${prefix}.awsProfileConfigurationHeader`;
export const useIamRolesOption = `${prefix}.useIamRolesOption`;
export const useAccessKeysOption = `${prefix}.useAccessKeysOption`;
export const accessKeyIdInputLabel = `${prefix}.accessKeyIdInputLabel`;
export const secretAccessKeyInputLabel = `${prefix}.secretAccessKeyInputLabel`;
export const regionInputLabel = `${prefix}.regionInputLabel`;
export const accessAwsThroughProxyInputLabel = `${prefix}.accessAwsThroughProxyInputLabel`;
export const proxyHostInputLabel = `${prefix}.proxyHostInputLabel`;
export const proxyPortInputLabel = `${prefix}.proxyPortInputLabel`;
export const proxyUsernameInputLabel = `${prefix}.proxyUsernameInputLabel`;
export const proxyUsernameInputPlaceholder = `${prefix}.proxyUsernameInputPlaceholder`;
export const proxyPasswordInputLabel = `${prefix}.proxyPasswordInputLabel`;
export const proxyPasswordPlaceholder = `${prefix}.proxyPasswordPlaceholder`;
export const useCrossAccountAssumeRoleInputLabel = `${prefix}.useCrossAccountAssumeRoleInputLabel`;
export const iamAssumeRoleInputLabel = `${prefix}.iamAssumeRoleInputLabel`;
export const vpcNamePlaceholder = `${prefix}.vpcNamePlaceholder`;
export const availabilityZoneInputLabel = `${prefix}.availabilityZoneInputLabel`;
export const availabilityZoneInputPlaceholder = `${prefix}.availabilityZoneInputPlaceholder`;
export const usableNetworkUuidsInputLabel = `${prefix}.usableNetworkUuidsInputLabel`;
export const usableNetworkUuidsInputPlaceholder = `${prefix}.usableNetworkUuidsInputPlaceholder`;
export const removeUsableNetworkButtonLabel = `${prefix}.removeUsableNetworkButtonLabel`;
export const addUsableNetworkButtonLabel = `${prefix}.addUsableNetworkButtonLabel`;
export const usableDomainInputLabel = `${prefix}.usableDomainInputLabel`;
export const removeUsableDomainButtonLabel = `${prefix}.removeUsableDomainButtonLabel`;
export const addUsableDomainButtonLabel = `${prefix}.addUsableDomainButtonLabel`;
export const openStackProfileConfigurationHeader = `${prefix}.openStackProfileConfigurationHeader`;
export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const tenantInputLabel = `${prefix}.tenantInputLabel`;
export const keystoneHostInputLabel = `${prefix}.keystoneHostInputLabel`;
export const vipNetworkNameInputLabel = `${prefix}.vipNetworkNameInputLabel`;
export const azureProfileConfigurationHeader = `${prefix}.azureProfileConfigurationHeader`;
export const pleaseCheckCredentialsErrorMessage = `${prefix}.pleaseCheckCredentialsErrorMessage`;
export const subscriptionIdInputLabel = `${prefix}.subscriptionIdInputLabel`;
export const useUsernamPasswordCredentialsOption = `${prefix}.useUsernamPasswordCredentialsOption`;
export const useApplicationIdCredentialsOption = `${prefix}.useApplicationIdCredentialsOption`;
export const tenantNameInputLabel = `${prefix}.tenantNameInputLabel`;
export const applicationIdInputLabel = `${prefix}.applicationIdInputLabel`;
export const authenticationTokenInputLabel = `${prefix}.authenticationTokenInputLabel`;
export const tenantIdInputLabel = `${prefix}.tenantIdInputLabel`;
export const customDnsProfileConfigurationHeader = `${prefix}.customDnsProfileConfigurationHeader`;
export const customIpamDnsProfileInputLabel = `${prefix}.customIpamDnsProfileInputLabel`;
export const customIpamDnsProfileInputPlaceholder = `${prefix}.customIpamDnsProfileInputPlaceholder`;
export const pluAddUsableDomainButtonLabel = `${prefix}.pluAddUsableDomainButtonLabel`;
export const customParamValueInputLabel = `${prefix}.customParamValueInputLabel`;
export const customParamSensitiveInputLabel = `${prefix}.customParamSensitiveInputLabel`;
export const customParamDynamicInputLabel = `${prefix}.customParamDynamicInputLabel`;
export const removeScriptParamButtonLabel = `${prefix}.removeScriptParamButtonLabel`;
export const addScriptParamButtonLabel = `${prefix}.addScriptParamButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;
export const nextButtonLabel = `${prefix}.nextButtonLabel`;
export const previousButtonLabel = `${prefix}.previousButtonLabel`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit IPAM/DNS Profile: {0}',
    [modalHeaderNew]: 'New IPAM/DNS Profile: {0}',
    [customParamNameInputLabel]: 'Name',
    [typeInputLabel]: 'Type',
    [typeInputPlaceholder]: 'Select Type',
    [allocateIpInVrfInputLabel]: 'Allocate IP in VRF',
    [awsProfileConfigurationHeader]: 'AWS Profile Configuration',
    [useIamRolesOption]: 'Use IAM Roles',
    [useAccessKeysOption]: 'Use Access Keys',
    [accessKeyIdInputLabel]: 'Access Key ID',
    [secretAccessKeyInputLabel]: 'Secret Access Key',
    [regionInputLabel]: 'Region',
    [accessAwsThroughProxyInputLabel]: 'Access AWS through Proxy',
    [proxyHostInputLabel]: 'Proxy Host',
    [proxyPortInputLabel]: 'Proxy Port',
    [proxyUsernameInputLabel]: 'Proxy Username',
    [proxyUsernameInputPlaceholder]: 'Username',
    [proxyPasswordInputLabel]: 'Proxy Password',
    [proxyPasswordPlaceholder]: 'Password',
    [useCrossAccountAssumeRoleInputLabel]: 'Use Cross-Account AssumeRole',
    [iamAssumeRoleInputLabel]: 'IAM AssumeRole',
    [vpcNamePlaceholder]: 'VPC Name',
    [availabilityZoneInputLabel]: 'Availability Zone',
    [availabilityZoneInputPlaceholder]: 'Select Availability Zone',
    [usableNetworkUuidsInputLabel]: 'Usable Network',
    [usableNetworkUuidsInputPlaceholder]: 'Select Network',
    [removeUsableNetworkButtonLabel]: 'Remove Usable Network',
    [addUsableNetworkButtonLabel]: 'Add Usable Network',
    [usableDomainInputLabel]: 'Usable Domain',
    [removeUsableDomainButtonLabel]: 'Remove Usable Domain',
    [addUsableDomainButtonLabel]: 'Add Usable Domain',
    [openStackProfileConfigurationHeader]: 'OpenStack Profile Configuration',
    [usernameInputLabel]: 'Username',
    [passwordInputLabel]: 'Password',
    [tenantInputLabel]: 'Tenant',
    [keystoneHostInputLabel]: 'Keystone Host',
    [vipNetworkNameInputLabel]: 'Network',
    [azureProfileConfigurationHeader]: 'Azure Profile Configuration',
    [pleaseCheckCredentialsErrorMessage]: 'Please check credentials.',
    [subscriptionIdInputLabel]: 'Subscription ID',
    [useUsernamPasswordCredentialsOption]: 'Use Username/Password credentials',
    [useApplicationIdCredentialsOption]: 'Use Application ID credentials',
    [tenantNameInputLabel]: 'Tenant Name',
    [applicationIdInputLabel]: 'Application ID',
    [authenticationTokenInputLabel]: 'Authentication Key',
    [tenantIdInputLabel]: 'Tenant ID',
    [customDnsProfileConfigurationHeader]: 'Custom DNS Profile Configuration',
    [customIpamDnsProfileInputLabel]: 'Custom IPAM/DNS Profile',
    [customIpamDnsProfileInputPlaceholder]: 'Select Custom IPAM/DNS Profile',
    [pluAddUsableDomainButtonLabel]: '+ Add Usable Domain',
    [customParamValueInputLabel]: 'Value',
    [customParamSensitiveInputLabel]: 'Sensitive',
    [customParamDynamicInputLabel]: 'Dynamic',
    [removeScriptParamButtonLabel]: 'Remove Script Param',
    [addScriptParamButtonLabel]: '+ Add Script Param',
    [saveButtonLabel]: 'Save',
    [nextButtonLabel]: 'Next',
    [previousButtonLabel]: 'Previous',
};

/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    Inject,
    Input,
} from '@angular/core';
import { WafPolicy } from 'ajs/modules/waf';
import { AviModalService } from 'ajs/modules/core';
import './waf-learning-link.component.less';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './waf-learning-link.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the WAF Learning Link.
 * @author alextsg
 */
@Component({
    selector: 'waf-learning-link',
    templateUrl: './waf-learning-link.component.html',
})
export class WafLearningLinkComponent {
    /**
     * VirtualService UUID, used to retrieve learning data.
     */
    @Input() public vsId: string;

    /**
     * WafPolicy Item.
     */
    @Input() public wafPolicy: WafPolicy;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        @Inject(AviModalService) private aviModalService: AviModalService,
        private l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Called when View Learning Data button is clicked. Opens a modal to show the learning data.
     */
    public viewLearningData(): void {
        this.aviModalService.open('waf-learning-data-modal', {
            vsId: this.vsId,
            wafPolicy: this.wafPolicy,
        });
    }

    /**
     * Returns the update interval text to be displayed.
     */
    public getUpdateInterval(): string {
        const { config } = this.wafPolicy;
        // eslint-disable-next-line no-extra-parens
        const updateInterval = (config as any).learning_params?.config.update_interval;

        return updateInterval ?
            this.l10nService.getMessage(l10nKeys.minUpdateIntervalText, [updateInterval]) :
            this.l10nService.getMessage(l10nKeys.emptyUpdateIntervalText);
    }

    /**
     * Returns the sampling percentage text to be displayed.
     */
    public getSamplingPercentage(): string {
        const { config } = this.wafPolicy;
        // eslint-disable-next-line no-extra-parens
        const samplingPercentage = (config as any).learning_params?.config.sampling_percent;

        return samplingPercentage ? `${samplingPercentage}%` :
            this.l10nService.getMessage(l10nKeys.samplingPercentageEmptyText);
    }
}

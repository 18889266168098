/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').factory('BackupCollection', ['Collection', 'Backup',
(Collection, Backup) => {
    const BackupCollection = class extends Collection {};

    angular.extend(BackupCollection.prototype, {
        objectName_: 'backup',
        itemClass_: Backup,
        windowElement_: 'backup-create',
        isStatic_: false,
    });

    return BackupCollection;
}]);

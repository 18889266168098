/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    CLOUD_NSXT,
    CLOUD_VCENTER,
} from 'ajs/js/services/items/Cloud';
import * as l10n from './virtualservice-vip-placement.l10n';
import './virtualservice-vip-placement.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @inner
 */
const componentName = 'virtualservice-vip-placement';

/**
 * @typedef {Object} ISubnetHash
 * @memberOf module:avi/vs
 * @property {string[]} ipv4
 * @property {string[]} ipv6
 */
/**
 * @typedef {Object<string, module:avi/vs.ISubnetHash>} IPlacementNetworkToSubnetHash
 * @memberOf module:avi/vs
 */

/**
 * @constructor
 * @memberOf module:avi/vs
 * @see {@link module:avi/vs.virtualserviceVipPlacementComponent}
 */
class VirtualserviceVipPlacementController {
    constructor(
        getSubnetString,
        Regex,
        schemaService,
        SubnetListNetwork,
        l10nService,
    ) {
        /**
         * @type {getSubnetString}
         * @protected
         */
        this.getSubnetString_ = getSubnetString;

        /**
         * @type {Regex}
         */
        this.regex = Regex;

        /**
         * @type {module:avi/network.SubnetListNetwork}
         * @protected
         */
        this.SubnetListNetwork_ = SubnetListNetwork;

        /**
         * For vcenter clouds, we would show placement_network dropdown and
         * subnets.
         * For others (linux, no_orch cloud), we will show just subnets. Set to undefined initially
         * to allow for one-time binding.
         * @type {Boolean}
         */
        this.showPlacementNetworks = undefined;

        /**
         * we are loading placement_networks of each vip to
         * get subnet options.
         *
         * Following key is to hold off rendering
         * of networks and subnets till we get everything loaded.
         *
         * Otherwise we would get tons of $digest errors on console.
         *
         * This happens on only on Initialization.
         * @type {Boolean}
         */
        this.subnetsLoaded = false;

        /**
         * Hash of network-uuid to subnet options.
         * @type {module:avi/vs.IPlacementNetworkToSubnetHash}
         * @protected
         */
        this.placementNetworkSubnetListHash_ = {};

        /**
         * Maximum number of placementNetworks that can be added to each VIP.
         * @type {number}
         * @protected
         */
        this.maxPlacementNetworks_ =
            schemaService.getFieldMaxElements('Vip', 'placement_networks');

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /* @override */
    $onInit() {
        /**
         * Need to show placement_networks dropdown only for vcenter clouds.
         * We will display just subnets incase of no_arch_cloud or linux cloud.
         */
        this.showPlacementNetworks = this.shouldShowPlacementNetworks();

        if (this.showPlacementNetworks) {
            this.loadPlacementNetworkAndSubnets_()
                .finally(() => {
                    this.subnetsLoaded = true;
                });
        }
    }

    /**
     * Loop through each vip and loads its placement_networks
     * to get subnet-list options.
     * @returns {Promise}
     * @protected
     */
    loadPlacementNetworkAndSubnets_() {
        const { vips: vipList } = this;

        const vipLoadPromises = vipList.reduce((loadPromisesList, vip) => {
            loadPromisesList.push(...this.loadNetworksAndSubnetsForVip_(vip));

            return loadPromisesList;
        }, []);

        return Promise.all(vipLoadPromises);
    }

    /**
     * Loads Networks and Subnets for a VIP.
     * @param {module:avi/vs.VipConfig} vip - VIP instance
     * @returns {Promises[]}
     * @protected
     */
    loadNetworksAndSubnetsForVip_(vip) {
        const { placementNetworks } = vip;
        const { id: cloudId } = this.cloud;

        // A VIP can have multiple placement_networks (max 10), so we load them all
        // and set their subnet-options.
        // TODO: Replace with collection load once AV-56522 is resolved
        const placementNetworkLoadPromises = placementNetworks
            .filter(({ network_ref: networkRef }) => networkRef)
            .map(placementNetwork => {
                const { network_ref: networkRef } = placementNetwork;

                const network = new this.SubnetListNetwork_({
                    id: networkRef.slug(),
                    cloudId,
                });

                return network.load()
                    .then(() => {
                        const subnets = network.getSubnets();

                        this.setSubnetOptionsForPlacementNetwork_(networkRef.slug(), subnets);
                    })
                    .finally(() => {
                        network.destroy();
                    });
            });

        return placementNetworkLoadPromises;
    }

    /**
     * Adds new entry in placement_networks list of VIP.
     * @param {module:avi/vs.VipConfig} vip - VIP instance
     */
    addPlacementNetwork(vip) {
        vip.addPlacementNetwork();
    }

    /**
     * Removes an entry from placement_networks list of a VIP.
     * @param {module:avi/vs.VipConfig} vip - VIP instance
     * @param {number} index - Index of the placementNetwork to be removed.
     */
    removePlacementNetwork(vip, index) {
        vip.removePlacementNetwork(index);
    }

    /**
     * Fires on placement_network collection-dropdown change.
     * Clears subnet options and repopulate them.
     * @param {module:avi/vs.VipConfig} vip - VIP instance
     * @param {module:avi/network.SubnetListNetwork} network
     *      - Selected PlacementNetwork config, used to get subnet options
     * @param {number} index - index of the placement_network which got changed
     */
    handlePlacementNetworkChange(vip, network, index) {
        vip.clearPlacementNetworkSubnet(index);

        const subnets = network.getSubnets();

        const { id: networkUuid } = network;

        this.setSubnetOptionsForPlacementNetwork_(networkUuid, subnets);
    }

    /**
     * Sets placementNetwork's subnet eAutoComplete options(ipv4 and ipv6).
     * @param {string} networkUuid
     * @param {Object[]} subnets
     * @protected
     */
    setSubnetOptionsForPlacementNetwork_(networkUuid, subnets = []) {
        const subnetDropdownOptionsInitialState = {
            ipv4: [],
            ipv6: [],
        };

        const subnetDropdownOptions =
            subnets.reduce(this.subnetDropdownOptionsReducer_, subnetDropdownOptionsInitialState);

        this.placementNetworkSubnetListHash_[networkUuid] = subnetDropdownOptions;
    }

    /**
     * Reducer method for subnet dropdownOptions.
     * @param {Object} options
     * @param {Object} subnet
     * @returns {Object}
     * @protected
     */
    subnetDropdownOptionsReducer_= (options, subnet) => {
        const { prefix } = subnet;

        const {
            ip_addr: {
                type: ipAddrType,
            },
        } = prefix;

        const {
            ipv4,
            ipv6,
        } = options;

        const subnetList = ipAddrType === 'V4' ? ipv4 : ipv6;

        const subnetString = this.getSubnetString_(prefix);

        // key should be `value` for eAutoComplete.
        subnetList.push({ value: subnetString });

        return options;
    };

    /**
     * Returns IPV4/IPV6 address options for subnet autocomplete field.
     * Looks up networkUuid in placementNetworkSubnetListHash_ and returns
     * requested type options.
     * @param {string} networkRef selected placement network ref.
     * @param {string} [type='ipv4'] ipv4/ipv6
     * @returns {string[]|undefined}
     */
    getSubnetOptions(networkRef, type = 'ipv4') {
        const networkUuid = networkRef.slug();

        const subnetList = this.placementNetworkSubnetListHash_[networkUuid];

        return subnetList && subnetList[type].length ? subnetList[type] : undefined;
    }

    /**
     * Returns true if Add placement network button can be displayed for a vip.
     * (max limit is get from schema)
     * @param {module:avi/vs.VipConfig} vip - VIP instance
     * @returns {boolean}
     */
    showAddPlacementNetworkButton(vip) {
        const { placementNetworks } = vip;

        const { length: placementNetworksCount } = placementNetworks;

        return placementNetworksCount < this.maxPlacementNetworks_;
    }

    /**
     * Returns VIP Label.
     * @param {module:avi/vs.VipConfig} vip - VIP instance
     * @returns {string}
     */
    getVipLabel(vip) {
        const {
            ip_address: ipAddress,
            ip6_address: ipv6Address,
        } = vip.getConfig();

        let vipLabel = '';

        if (ipAddress?.addr) {
            vipLabel = `${ipAddress.addr}`;
        }

        if (ipv6Address?.addr) {
            vipLabel += `${vipLabel ? ', ' : ''}${ipv6Address.addr}`;
        }

        return vipLabel;
    }

    /**
     * Need to show placement_networks dropdown only for vcenter clouds and NSX-T clouds of vlan
     * type. We will display just subnets incase of no_arch_cloud or linux cloud.
     * @returns {boolean}
     */
    shouldShowPlacementNetworks() {
        return this.cloud.isVtype(CLOUD_VCENTER) ||
            this.cloud.isVtype(CLOUD_NSXT) && this.cloud.getCloudConfig().isVlanTransportZone();
    }
}

VirtualserviceVipPlacementController.$inject = [
    'getSubnetString',
    'Regex',
    'schemaService',
    'SubnetListNetwork',
    'l10nService',
];

/**
 * @name virtualserviceVipPlacementComponent
 * @memberOf module:avi/vs
 * @property {module:avi/vs.VirtualserviceVipPlacementController} controller
 * @property {module:avi/vs.virtualserviceVipPlacementBindings} bindings
 * @description
 *     Handles inputs for vCenter VIP network placement in Virtual Service create/edit.
 *     User chooses a network from the bound SubnetListNetworkCollection, which sets fields
 *     on the SubnetworkCollection that allows the user to either select a subnet or
 *     manually enter one in.
 *  @author alextsg, chitra, Aravindh Nagarajan
 */
angular.module('avi/vs').component('virtualserviceVipPlacement', {
    /**
     * @mixin virtualserviceVipPlacementBindings
     * @memberof module:avi/vs
     * @property {module:avi/network.SubnetListNetworkCollection} networkCollection
     * @property {module:avi/vs.VipConfig[]} vips
     * @property {Cloud} cloud
     */
    bindings: {
        networkCollection: '<networks',
        vips: '<',
        cloud: '<',
    },
    controller: VirtualserviceVipPlacementController,
    templateUrl:
        'src/components/applications/virtualservice/virtualservice-vip-address/' +
        `${componentName}/${componentName}.component.html`,
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name AggSeries
 * @description
 *
 *     Aggregated series - basically just a one value in a first element of data array.
 */
angular.module('aviApp').factory('AggSeries', ['Series', function(Series) {
    return class AggSeries extends Series {
        constructor(args) {
            args.isAggregated = true;

            if (!args.aggregation) {
                args.aggregation = 'METRICS_DIMENSION_AGG_AVG';
                console.warn('aggregation not set on AggSeries creation');
            }

            super(args);
        }

        /** @override */
        process(rsp, anomalies, step, limit) {
            let gotUpdated = false;

            const series = this.findSeriesInResponse_(rsp);

            if (step !== this.step || limit !== this.limit) {
                this.emptyData_();
                this.step = step;
                this.limit = limit;
                gotUpdated = true;
            }

            if (series && series.data && series.data.length) {
                if (!this.hasData()) {
                    this.setHeader_(series.header);
                }

                this.values[0] = series.data[0].value;
                gotUpdated = true;
            }

            return gotUpdated;
        }

        /** @override */
        getValue() {
            return this.hasData() ? this.values[0] : NaN;
        }

        /** @override */
        findAnomaliesInResponse_() {}
    };
}]);

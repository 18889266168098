/**
 * @module WelcomeModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    Component,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { IEmailConfiguration, SMTPType } from 'generated-types';

import * as l10n from './welcome-email-settings.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *      Component to select SMTP Type in Welcome modal.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'welcome-email-settings',
    templateUrl: './welcome-email-settings.component.html',
})
export class WelcomeEmailSettingsComponent {
    /**
     * EmailConfig object.
     */
    @Input()
    public emailConfig: IEmailConfiguration;

    // SMTP Type values.
    public readonly SMTP_NONE = SMTPType.SMTP_NONE;
    public readonly SMTP_LOCAL_HOST = SMTPType.SMTP_LOCAL_HOST;
    public readonly SMTP_SERVER = SMTPType.SMTP_SERVER;
    public readonly SMTP_ANONYMOUS_SERVER = SMTPType.SMTP_ANONYMOUS_SERVER;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}

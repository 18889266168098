/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const componentName = 'l4-rule-protocol-match';

/**
 * @name l4RuleProtocolMatchComponent
 * @memberOf module:avi/policies/nat
 * @property {module:avi/policies/nat.l4RuleProtocolMatchComponentBindings} bindings
 * @desc Component for L4RuleProtocolMatch MessageItem.
 * @author Aravindh Nagarajan
 */
angular.module('avi/policies/nat').component('l4RuleProtocolMatch', {
    /**
     * @mixin l4RuleProtocolMatchComponentBindings
     * @memberOf module:avi/policies/nat
     * @property {module:avi/policies/nat.L4RuleProtocolMatchConfigItem} l4RuleProtocolMatch
     * @property {boolean} preventEdit
     */
    bindings: {
        l4RuleProtocolMatch: '<',
        preventEdit: '<',
    },
    templateUrl: 'src/components/forms/match-config/matches/' +
        `${componentName}/${componentName}.component.html`,
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './http-status-match.less';

class HTTPStatusMatchController {
    constructor($element, Regex) {
        this.Regex = Regex;
        $element.addClass('http-status-match');
    }
}

HTTPStatusMatchController.$inject = [
    '$element',
    'Regex',
];

/**
 * @ngdoc component
 * @requires httpStatusMatch
 * @param {HTTPStatusMatch} match - HTTPStatusMatch instance.
 * @param {string} hideMatchCriteria - If not empty, will hide "IS" and "IS NOT" selector.
 * @description
 *      Component for configuring HTTPStatusMatch instance.
 */
angular.module('aviApp').directive('httpStatusMatch', function() {
    return {
        restrict: 'E',
        controller: HTTPStatusMatchController,
        controllerAs: '$ctrl',
        scope: {
            match: '=',
            hideMatchCriteria: '@?',
        },
        templateUrl: 'src/components/common/http-status-match/http-status-match.html',
    };
});

/** @module GslbModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { LimitedListCollDataSource } from 'ajs/modules/data-model';
import { each } from 'underscore';
import { GSLBServiceFQDNCollection } from './gslb-service-fqdn.collection.factory';

interface IGslbServiceFqdnListRequestParamsResponse {
    offset_: number;
    limit_: number;
    results: IDomainInfo[];
}

interface IDomainInfo {
    domainName: string;
    siteName: string;
    gslbServiceName: string;
    vsId: string;
    clusterId: string;
}

/**
 * @description
 *
 *     getRequestParams actually builds the response from GSLB DNS VS sites and GSLBService domain
 *     names.
 *
 * @author Ram Pal
 */
export class GSLBServiceFQDNListCollDataSource extends LimitedListCollDataSource {
    public owner_: GSLBServiceFQDNCollection;

    /**
     * Making up the list of VS&FQDN combinations.
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public getRequestParams_(): IGslbServiceFqdnListRequestParamsResponse {
        const results: IDomainInfo[] = [];
        const { gslb, gslbService } = this.owner_;
        const domainNames = gslbService.getDomainNames();
        const gslbServiceName = gslbService.getName();
        const DNSVSSites = gslb.getDNSVSSites();

        each(DNSVSSites, ({ clusterId, name: siteName, dnsVSs }: Record<string, any>) => {
            dnsVSs.forEach((vsId: string) => {
                domainNames.forEach((domainName: string) => {
                    results.push({
                        domainName,
                        siteName,
                        gslbServiceName,
                        vsId,
                        clusterId,
                    });
                });
            });
        });

        // need offset&limit for compatibility with ListCollDataSource.processResponse_
        return {
            offset_: 0,
            limit_: 0,
            results,
        };
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public processResponse_(resp: any, requestParams: any): void {
        this.processConfigResponse_(resp, requestParams);
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './virtualserviceTrafficCaptureStatus.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  virtualserviceTrafficCaptureStatus
 * @description  Displays traffic capture status.
 */
function Controller(
    $filter,
    l10nService,
) {
    /**
     * Changes a debug_ip object into a string of IP addresses.
     * @return {string}
     */
    this.getFilterString = function(debug) {
        return $filter('ipObjectToString')(debug);
    };

    /**
    * Get keys from source bundles for template usage.
    */
    this.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);
}

Controller.$inject = [
    '$filter',
    'l10nService',
];

angular.module('aviApp').component('virtualserviceTrafficCaptureStatus', {
    bindings: {
        config: '<',
        progress: '<',
    },
    controller: Controller,
    templateUrl: 'src/components/operations/virtualservice-traffic-capture-status/' +
            'virtualservice-traffic-capture-status.html',
    transclude: {
        edit: 'a',
        switch: 'div',
    },
});

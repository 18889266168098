/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './custom-ipam.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class CustomIpamDnsPageController {
    constructor(
        CRUDGridConfig,
        CustomIpamDnsCollection,
        l10nService,
    ) {
        this.CRUDGridConfig = CRUDGridConfig;
        this.CustomIpamDnsCollection = CustomIpamDnsCollection;
        /**
        * Get keys from source bundles for template usage.
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
        this.l10nService_ = l10nService;
    }

    $onInit() {
        const { l10nService_: l10nService } = this;

        this.gridConfig = new this.CRUDGridConfig();
        this.gridConfig.collection = new this.CustomIpamDnsCollection();
        this.gridConfig.fields = [{
            name: 'data.config.name',
            sortBy: 'name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
        }, {
            name: 'data.config.script_uri',
            title: l10nService.getMessage(l10nKeys.columnTitleScript),
        }];

        const { objectName } = this.gridConfig.collection;

        this.gridConfig.id = `${objectName}-list-page`;
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

CustomIpamDnsPageController.$inject = [
    'CRUDGridConfig',
    'CustomIpamDnsCollection',
    'l10nService',
];

/**
 * @ngdoc component
 * @name customIpamDnsPage
 * @description
 *      Component for Custom IPAM/DNS Profile page.
 */
angular.module('aviApp').component('customIpamDnsPage', {
    controller: CustomIpamDnsPageController,
    template:
        `<div class="avi-main">
            <div class="panel templatesList">
                <collection-grid
                    config="$ctrl.gridConfig">
                </collection-grid>
            </div>
        </div>`,
});

/**
 * @module CoreModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    ApplicationProfileType,
    CloudType,
    LicenseTierType,
} from 'generated-types';

import { SystemInfoService } from '../system-info/system-info.service';

/**
 * @description Tracks what configurations are supported based on the user's license.
 * @author alextsg
 */
export class LicenseBasedFeaturesService {
    /**
     * Set of metrics which are not available on basic license.
     */
    private readonly basicLicenseMetricBlockList = new Set(['end_to_end']);

    constructor(private readonly systemInfoService: SystemInfoService) {}

    /**
     * Returns a map of supported cloud types.
     */
    public getSupportedCloudTypesMap(): Map<CloudType, boolean> {
        const supportedCloudTypesMap = new Map([
            [CloudType.CLOUD_NSXT, false],
            [CloudType.CLOUD_VCENTER, false],
            [CloudType.CLOUD_OPENSTACK, false],
            [CloudType.CLOUD_AWS, false],
            [CloudType.CLOUD_LINUXSERVER, false],
            [CloudType.CLOUD_AZURE, false],
            [CloudType.CLOUD_GCP, false],
            [CloudType.CLOUD_NONE, false],
        ]);

        const { defaultLicenseTier } = this.systemInfoService;

        switch (defaultLicenseTier) {
            case LicenseTierType.BASIC:
                supportedCloudTypesMap.set(CloudType.CLOUD_NONE, true);
                supportedCloudTypesMap.set(CloudType.CLOUD_NSXT, true);
                break;

            case LicenseTierType.ESSENTIALS:
                supportedCloudTypesMap.set(CloudType.CLOUD_NONE, true);
                supportedCloudTypesMap.set(CloudType.CLOUD_VCENTER, true);
                break;

            case LicenseTierType.ENTERPRISE:
                for (const cloudType of supportedCloudTypesMap.keys()) {
                    supportedCloudTypesMap.set(cloudType, true);
                }

                break;
            default:
                throw new Error('License not supported.');
        }

        return supportedCloudTypesMap;
    }

    /**
     * Returns a map of ApplicationProfile types and whether they are
     * allowed for the defaultLicenseTier.
     */
    public getSupportedAppProfileTypesMap(): Map<ApplicationProfileType, boolean> {
        const supportedAppProfileTypesMap = new Map([
            [ApplicationProfileType.APPLICATION_PROFILE_TYPE_DNS, false],
            [ApplicationProfileType.APPLICATION_PROFILE_TYPE_HTTP, false],
            [ApplicationProfileType.APPLICATION_PROFILE_TYPE_L4, false],
            [ApplicationProfileType.APPLICATION_PROFILE_TYPE_SIP, false],
            [ApplicationProfileType.APPLICATION_PROFILE_TYPE_SSL, false],
            [ApplicationProfileType.APPLICATION_PROFILE_TYPE_SYSLOG, false],
        ]);

        const { defaultLicenseTier } = this.systemInfoService;

        switch (defaultLicenseTier) {
            case LicenseTierType.BASIC:
                supportedAppProfileTypesMap
                    .set(ApplicationProfileType.APPLICATION_PROFILE_TYPE_HTTP, true);

                supportedAppProfileTypesMap
                    .set(ApplicationProfileType.APPLICATION_PROFILE_TYPE_L4, true);

                break;

            case LicenseTierType.ESSENTIALS:
                supportedAppProfileTypesMap
                    .set(ApplicationProfileType.APPLICATION_PROFILE_TYPE_L4, true);

                break;

            case LicenseTierType.ENTERPRISE:
                for (const appProfileType of supportedAppProfileTypesMap.keys()) {
                    supportedAppProfileTypesMap.set(appProfileType, true);
                }

                break;
            default:
                throw new Error('License not supported.');
        }

        return supportedAppProfileTypesMap;
    }

    /**
     * Returns true for all metrics on Enterprise license type
     * and for the available metrics on Basic license type
     */
    public isMetricAvailable(metricId: string): boolean {
        const { defaultLicenseTier } = this.systemInfoService;

        return defaultLicenseTier !== LicenseTierType.BASIC ||
            !this.basicLicenseMetricBlockList.has(metricId);
    }
}

LicenseBasedFeaturesService.$inject = [
    'systemInfoService',
];

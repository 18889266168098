/**
 * @module LicensingModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import './license-summary-card.component.less';

import {
    Component,
    Inject,
    Input,
} from '@angular/core';

import { LicenseTierType } from 'generated-types';
import { SchemaService } from 'ajs/modules/core';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './license-summary-card.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Card to display license summary data.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'license-summary-card',
    templateUrl: './license-summary-card.component.html',
})
export class LicenseSummaryCardComponent {
    @Input()
    public licenseTier: LicenseTierType;

    @Input()
    public usedServiceCores: number;

    @Input()
    public licensedServiceCores: number;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Getter for service core usage percentage number, rounded to one decimal place.
     */
    public get serviceCoreUsagePercentage(): number {
        return Math.round(this.usedServiceCores / this.licensedServiceCores * 1000) / 10;
    }

    /**
     * Getter for license tier as title: capitalize the enum value.
     */
    public get licenseTierTitle(): string {
        return this.schemaService.getEnumValue('LicenseTierType', this.licenseTier).label;
    }

    public constructor(
        @Inject(SchemaService) private schemaService: SchemaService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Only license of tier types other than ESSENTIALS have licensen service core denominators and
     * respective percentage numbers. Also 0 total service core will not yield such data.
     */
    public hasDenominatorAndPercentage(): boolean {
        if (this.licenseTier === LicenseTierType.ESSENTIALS) {
            return false;
        }

        return this.licensedServiceCores > 0;
    }
}

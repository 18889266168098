/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @constructor
 * @memberOf module:avi/component-kit
 * @mixes module:avi/component-kit.dropdownListComponentBindings
 */
class DropdownListComponentController {
    constructor($scope, $element, $compile, stringService) {
        this.$scope_ = $scope;
        this.$element_ = $element;
        this.$compile_ = $compile;
        this.stringService_ = stringService;
    }

    /** @override */
    $onInit() {
        const template = this.getTemplate_();

        this.$compile_(template)(this.$scope_)
            .appendTo(this.$element_);
    }

    /**
     * Generate list template.
     * @return {string}
     * @protected
     */
    getTemplate_() {
        const defaultOptionComponentName = 'dropdownListOption';

        let { optionComponentName } = this;

        optionComponentName = optionComponentName || defaultOptionComponentName;

        const optionComponentTagName = this.stringService_.camelCaseToDash(optionComponentName);

        const template =
            `<${optionComponentTagName}
                ng-repeat="option in $ctrl.options track by option.value"
                option="option"
                on-click="$ctrl.onSelect({ value })"
            ></${optionComponentTagName}>`;

        return template;
    }
}

DropdownListComponentController.$inject = [
    '$scope',
    '$element',
    '$compile',
    'stringService',
];

/**
 * @mixin dropdownListComponentBindings
 * @memberOf module:avi/component-kit
 * @property {module:avi/component-kit.DropdownListOption[]} options - Options to select on.
 * @property {Function} onSelect - Function to be called with value.
 * @property {string=} optionComponentName - The custom component name(in camel case) used for
 *     options. Default component will be used if this is not present.
 */

/**
 * @name DropdownListComponent
 * @memberOf module:avi/component-kit
 * @property {module:avi/component-kit.dropdownListComponentBindings} bindings
 * @property {module:avi/component-kit.DropdownListComponentController} controller
 * @description
 *
 *     Component for list of options primarily used by dropdown menu.
 *
 * @author Zhiqian Liu
 */
angular.module('avi/component-kit').component('dropdownList', {
    bindings: {
        options: '<',
        onSelect: '&',
        optionComponentName: '@?',
    },
    controller: DropdownListComponentController,
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  ErrorPageProfile
 * @description  ErrorPageProfile item.
 */
const ErrorPageProfile = Item => {
    class ErrorPageProfile extends Item {
        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            if (Array.isArray(config.error_pages)) {
                config.error_pages.forEach(page => {
                    page.redirect_ = !!page.error_redirect;
                });
            }
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            if (Array.isArray(config.error_pages)) {
                config.error_pages.forEach(page => {
                    if (page.redirect_) {
                        delete page.error_page_body_ref;
                    } else {
                        delete page.error_redirect;
                    }

                    delete page.redirect_;
                });
            }

            return config;
        }
    }

    angular.extend(ErrorPageProfile.prototype, {
        objectName: 'errorpageprofile',
        windowElement: 'error-page-profile-modal',
    });

    return ErrorPageProfile;
};

ErrorPageProfile.$inject = [
        'Item',
];

angular.module('aviApp').factory('ErrorPageProfile', ErrorPageProfile);

const moduleName = 'cloud-connector-user';
const componentName = 'gcp-credentials';
const prefix = `${moduleName}.${componentName}`;

export const serviceAccountKeyFileDataLabel = `${prefix}.serviceAccountKeyFileDataLabel`;
export const serviceAccountKeyFileDataTooltip = `${prefix}.serviceAccountKeyFileDataTooltip`;
export const serviceAccountKeyFileDataPlaceholder = `${prefix}.serviceAccountKeyFileDataPlaceholder`;

export const ENGLISH = {
    [serviceAccountKeyFileDataLabel]: 'Import or Paste Service Account Key',
    [serviceAccountKeyFileDataTooltip]: 'Copy-paste your Service Account Key File Data into the field below or load it from a file',
    [serviceAccountKeyFileDataPlaceholder]: 'Paste Service Account Key',
};

/**
 * @module HealthMonitorModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { L10nService } from '@vmw/ngx-vip';
import { IHealthMonitorHttp } from 'generated-types';
import { HealthMonitor } from 'ajs/modules/healthmonitor';

import template from './healthmonitor-authentication.component.html';
import * as l10n from './healthmonitor-authentication.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

// Authenticaton type NTLM
const AUTH_NTLM = 'AUTH_NTLM';

/**
 * @description
 *
 *   Healthmonitor http/https authentication component.
 *
 * @author Ashish Verma
 */
class HealthmonitorAuthenticationController {
    /**
     * Healthmonitor Item
     */
    public healthMonitor: HealthMonitor;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Delete authentication values when authentication type is cleared and reset
     * exact_http_request.
     */
    public handleAuthenticationTypeClear(): void {
        this.healthMonitor.clearHttpAuthentication();
        this.healthMonitor.resetExactHttpRequest();
    }

    /**
     * If authentication type is NTLM then reset exact_http_request.
     */
    public handleAuthenticationTypeChange(): void {
        const subConfig = this.healthMonitor.subConfig as IHealthMonitorHttp;

        if (subConfig.auth_type === AUTH_NTLM) {
            this.healthMonitor.resetExactHttpRequest();
        }
    }
}

HealthmonitorAuthenticationController.$inject = [
    'l10nService',
];

export const healthmonitorAuthenticationComponentOptions = {
    bindings: {
        healthMonitor: '<',
    },
    controller: HealthmonitorAuthenticationController,
    template,
};

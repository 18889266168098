/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { OPENSTACK_KEYSTONE_SERVICE_TOKEN } from 'ajs/modules/core';

const tenantFactory = (
    UpdatableItem,
    openstackKeystoneService,
) => {
    class Tenant extends UpdatableItem {
        /** @override **/
        beforeEdit() {
            this.backup = angular.copy(this.data.users);
        }

        /** @override **/
        isEditable() {
            // Enforce to disable editing remote users if keystone auth is enabled
            if (openstackKeystoneService.keystoneAuthEnabled) {
                return false;
            }

            return super.isEditable();
        }
    }

    Object.assign(Tenant.prototype, {
        objectName: 'tenant',
        windowElement: 'adm-tenant-create',
    });

    return Tenant;
};

tenantFactory.$inject = [
    'UpdatableItem',
    OPENSTACK_KEYSTONE_SERVICE_TOKEN,
];

/**
 * @ngdoc service
 * @name Tenant
 * @description
 * Tenant Item class.
 */
angular.module('aviApp').factory('Tenant', tenantFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'waf-policy-psm-group-list-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleFlagged = `${prefix}.columnTitleFlagged`;
export const columnTitleEvaluated = `${prefix}.columnTitleEvaluated`;
export const actionTitleEdit = `${prefix}.actionTitleEdit`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleFlagged]: 'Flagged',
    [columnTitleEvaluated]: 'Evaluated',
    [actionTitleEdit]: 'Edit',
};

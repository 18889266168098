/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './dns-app-profile-dns-zones.component.less';
import * as l10n from './dns-app-profile-dns-zones.l10n';

const componentName = 'dns-app-profile-dns-zones';
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @typedef DnsZone
 * @memberOf module:avi/profiles/application
 * @property {string} domain_name
 * @property {string|undefined} name_server
 * @property {string|undefined} admin_email
 */

/**
 * @constructor
 * @memberOf module:avi/profiles/application
 * @mixes module:avi/profiles/application.dnsAppProfileDnsZonesListComponentBindings
 */
class DnsAppProfileDnsZonesController {
    constructor(
        Regex,
        l10nService,
    ) {
        this.Regex = Regex;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Inserts a plain zone object to zones list.
     */
    addDnsZone() {
        this.dnsZonesList.push({});
    }

    /**
     * Removes a zone from dnsZonesList
     * @param {number} index Index of the zone to be deleted.
     */
    removeDnsZone(index) {
        this.dnsZonesList.splice(index, 1);
    }

    /**
     * Returns true when no. of zones has reached its max limit
     * @return {boolean}
     */
    isMaxLimitReached() {
        return this.dnsZonesList.length > 100;
    }
}

DnsAppProfileDnsZonesController.$inject = [
    'Regex',
    'l10nService',
];

/**
 * @name dnsAppProfileDnsZones
 * @memberOf module:avi/profiles/application
 * @property {module:avi/profiles/application.dnsAppProfileDnsZonesListComponentBindings} bindings
 * @property {module:avi/profiles/application.DnsAppProfileDnsZonesController} controller
 * @description
 *      Repeated DNS Zone configuration component.
 *      Used in DNS Application profile.
 * @author Aravindh Nagarajan
 */
angular.module('avi/profiles/application').component('dnsAppProfileDnsZones', {
    /**
     * @mixin dnsAppProfileDnsZonesListComponentBindings
     * @memberOf module:avi/profiles/application
     * @property {module:avi/profiles/application.DnsZone[]} dnsZonesList
     */
    bindings: {
        dnsZonesList: '<',
    },
    controller: DnsAppProfileDnsZonesController,
    templateUrl:
        'src/components/modals/templates/profiles/application-profile/' +
        `dns-app-profile-domain-names/${componentName}/${componentName}.component.html`,
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name  MetricData
 * @description
 *     An item of a MetricDataCollection, a collection created from a metrics API.
 * @typedef {Object} MetricData
 */
angular.module('aviApp').factory('MetricData', ['Item',
Item => {
    class MetricData extends Item {
        /** @override */
        getIdFromData_(data) {
            return data && data.id || '';
        }
    }

    return MetricData;
}]);

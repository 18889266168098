/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'ssl-tls-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleEnabled = `${prefix}.columnTitleEnabled`;
export const columnTitleCipher = `${prefix}.columnTitleCipher`;
export const columnTitleSecurityScore = `${prefix}.columnTitleSecurityScore`;
export const columnTitlePerformance = `${prefix}.columnTitlePerformance`;
export const columnTitleCompatibility = `${prefix}.columnTitleCompatibility`;
export const resetDefaultValuesHeaderText = `${prefix}.resetDefaultValuesHeaderText`;
export const resetDefaultValuesMessage = `${prefix}.resetDefaultValuesMessage`;
export const resetDefaultValuesConfirmationText = `${prefix}.resetDefaultValuesConfirmationText`;
export const resetDefaultValuesConfirmationButtonText = `${prefix}.resetDefaultValuesConfirmationButtonText`;
export const editSslTlsProfileHeader = `${prefix}.editSslTlsProfileHeader`;
export const newSslTlsProfileHeader = `${prefix}.newSslTlsProfileHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const typeLabel = `${prefix}.typeLabel`;
export const cipherLabel = `${prefix}.cipherLabel`;
export const listButtonLabel = `${prefix}.listButtonLabel`;
export const stringButtonLabel = `${prefix}.stringButtonLabel`;
export const sslRatingHeader = `${prefix}.sslRatingHeader`;
export const securityScoreLabel = `${prefix}.securityScoreLabel`;
export const performanceRatingLabel = `${prefix}.performanceRatingLabel`;
export const compatibilityRatingLabel = `${prefix}.compatibilityRatingLabel`;
export const invalidFormMessage = `${prefix}.invalidFormMessage`;
export const sendCloseNotifyCheckboxLabel = `${prefix}.sendCloseNotifyCheckboxLabel`;
export const preferClientCipherOrderingCheckboxLabel = `${prefix}.preferClientCipherOrderingCheckboxLabel`;
export const earlyDataCheckboxLabel = `${prefix}.earlyDataCheckboxLabel`;
export const sslSessionReuseCheckboxLabel = `${prefix}.sslSessionReuseCheckboxLabel`;
export const sslSessionExpirationInputLabel = `${prefix}.sslSessionExpirationInputLabel`;
export const ciphersLabel = `${prefix}.ciphersLabel`;
export const acceptedCiphersInputLabel = `${prefix}.acceptedCiphersInputLabel`;
export const ciphersuitesInputLabel = `${prefix}.ciphersuitesInputLabel`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;
export const rbacHeader = `${prefix}.rbacHeader`;

export const ENGLISH = {
    [columnTitleEnabled]: 'Enabled',
    [columnTitleCipher]: 'Cipher',
    [columnTitleSecurityScore]: 'Security Score',
    [columnTitlePerformance]: 'Performance',
    [columnTitleCompatibility]: 'Compatibility',
    [resetDefaultValuesHeaderText]: 'Reset to Default Values',
    [resetDefaultValuesMessage]: 'Changing cipher type will reset settings to default values.',
    [resetDefaultValuesConfirmationText]: 'Are you sure you want to continue?',
    [resetDefaultValuesConfirmationButtonText]: 'Yes, continue',
    [editSslTlsProfileHeader]: 'Edit SSL/TLS Profile: {0}',
    [newSslTlsProfileHeader]: 'New SSL/TLS Profile: {0}',
    [nameInputLabel]: 'SSL/TLS Name',
    [nameInputPlaceholder]: 'Name',
    [typeLabel]: 'Type',
    [cipherLabel]: 'Cipher',
    [listButtonLabel]: 'List',
    [stringButtonLabel]: 'String',
    [sslRatingHeader]: 'SSL Rating',
    [securityScoreLabel]: 'Security score: <span class="{0}">{1}</span>',
    [performanceRatingLabel]: 'Performance Rating: <span class="{0}">{1}</span>',
    [compatibilityRatingLabel]: 'Compatibility Rating: <span class="{0}">{1}</span>',
    [invalidFormMessage]: 'Form is invalid',
    [sendCloseNotifyCheckboxLabel]: 'Send "close notify" alert',
    [preferClientCipherOrderingCheckboxLabel]: 'Prefer client cipher ordering',
    [earlyDataCheckboxLabel]: 'Early Data',
    [sslSessionReuseCheckboxLabel]: 'Enable SSL Session Reuse',
    [sslSessionExpirationInputLabel]: 'SSL Session Expiration',
    [ciphersLabel]: 'Ciphers',
    [acceptedCiphersInputLabel]: 'Accepted Ciphers',
    [ciphersuitesInputLabel]: 'Ciphersuites (TLSv1.3)',
    [cancelButtonLabel]: 'Cancel',
    [saveButtonLabel]: 'Save',
    [rbacHeader]: 'Role-Based Access Control (RBAC)',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'hsm-group-modal';
const prefix = `${moduleName}.${componentName}`;

export const editHsmGroupHeader = `${prefix}.editHsmGroupHeader`;
export const newHsmGroupHeader = `${prefix}.newHsmGroupHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const typeInputPlaceholder = `${prefix}.typeInputPlaceholder`;
export const thalesRfsServerCheckboxLabel = `${prefix}.thalesRfsServerCheckboxLabel`;
export const rfsPortInputLabel = `${prefix}.rfsPortInputLabel`;
export const netHsmInputLabel = `${prefix}.netHsmInputLabel`;
export const netHsmIpAddressInputLabel = `${prefix}.netHsmIpAddressInputLabel`;
export const netHsmPortInputLabel = `${prefix}.netHsmPortInputLabel`;
export const electronicSerialNumberInputLabel = `${prefix}.electronicSerialNumberInputLabel`;
export const keyHashInputLabel = `${prefix}.keyHashInputLabel`;
export const priorityInputLabel = `${prefix}.priorityInputLabel`;
export const removeConfigurationButtonLabel = `${prefix}.removeConfigurationButtonLabel`;
export const addAdditionalHsmButtonLabel = `${prefix}.addAdditionalHsmButtonLabel`;
export const safenetLunaHeader = `${prefix}.safenetLunaHeader`;
export const safenetLunaIpAddressInputLabel = `${prefix}.safenetLunaIpAddressInputLabel`;
export const serialNumberInputLabel = `${prefix}.serialNumberInputLabel`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const serverCertificateInputLabel = `${prefix}.serverCertificateInputLabel`;
export const addAdditionalHsmPlusButtonLabel = `${prefix}.addAdditionalHsmPlusButtonLabel`;
export const enableHaCheckboxLabel = `${prefix}.enableHaCheckboxLabel`;
export const dedicatedInterfaceCheckboxLabel = `${prefix}.dedicatedInterfaceCheckboxLabel`;
export const clientIpAddressInputLabel = `${prefix}.clientIpAddressInputLabel`;
export const clientIpAddressInputPlaceholder = `${prefix}.clientIpAddressInputPlaceholder`;
export const clientIpAddressRemoveButtonLabel = `${prefix}.clientIpAddressRemoveButtonLabel`;
export const clientIpAddressAddButtonLabel = `${prefix}.clientIpAddressAddButtonLabel`;
export const awsCloudHsmHeader = `${prefix}.awsCloudHsmHeader`;
export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const hsmClusterCertificateButtonLabel = `${prefix}.hsmClusterCertificateButtonLabel`;
export const hsmIpAddressesInputLabel = `${prefix}.hsmIpAddressesInputLabel`;
export const hsmIpAddressesInputPlaceholder = `${prefix}.hsmIpAddressesInputPlaceholder`;
export const hsmIpLabel = `${prefix}.hsmIpLabel`;

export const ENGLISH = {
    [editHsmGroupHeader]: 'Edit HSM Group: {0}',
    [newHsmGroupHeader]: 'New HSM Group: {0}',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'HSM Name',
    [typeInputLabel]: 'Type',
    [typeInputPlaceholder]: 'HSM Type',
    [thalesRfsServerCheckboxLabel]: 'Thales RFS Server',
    [rfsPortInputLabel]: 'RFS Port',
    [netHsmInputLabel]: 'Thales netHSM',
    [netHsmIpAddressInputLabel]: 'netHSM IP Address',
    [netHsmPortInputLabel]: 'netHSM Port',
    [electronicSerialNumberInputLabel]: 'Electronic Serial Number',
    [keyHashInputLabel]: 'Key Hash',
    [priorityInputLabel]: 'Priority',
    [removeConfigurationButtonLabel]: 'Remove Configuration',
    [addAdditionalHsmButtonLabel]: 'Add Additional HSM',
    [safenetLunaHeader]: 'Safenet Luna',
    [safenetLunaIpAddressInputLabel]: 'HSM Address',
    [serialNumberInputLabel]: 'Serial Number',
    [passwordInputLabel]: 'Password',
    [serverCertificateInputLabel]: 'Server Certificate',
    [addAdditionalHsmPlusButtonLabel]: '+ Add Additional HSM',
    [enableHaCheckboxLabel]: 'Enable HA',
    [dedicatedInterfaceCheckboxLabel]: 'Dedicated Interface',
    [clientIpAddressInputLabel]: 'Client IP',
    [clientIpAddressInputPlaceholder]: 'Client IP Address',
    [clientIpAddressRemoveButtonLabel]: 'Remove Client IP',
    [clientIpAddressAddButtonLabel]: '+ Add Client IP',
    [awsCloudHsmHeader]: 'AWS CloudHSM',
    [usernameInputLabel]: 'Username',
    [hsmClusterCertificateButtonLabel]: 'HSM Cluster Certificate',
    [hsmIpAddressesInputLabel]: 'HSM IP Addresses',
    [hsmIpAddressesInputPlaceholder]: 'IP Address',
    [hsmIpLabel]: 'HSM IP',
};

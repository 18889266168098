/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './vs-access-policy-settings.component.less';

/**
 * @constructor
 * @memberOf module:avi/vs
 * @mixes module:avi/vs.vsAccessPolicySettingsBindings
 * @see {@link module:avi/vs.vsAccessPolicySettingsComponent vsAccessPolicySettingsComponent}
 */
class VsAccessPolicySettingsController {
    constructor(defaultValues) {
        this.defaultValues_ = defaultValues;
    }

    /** @override */
    $onInit() {
        const {
            samlSpConfig,
            ssoPolicyRef,
        } = this;

        /**
         * Model value for Access type radio buttons (saml/pingaccess/none).
         * UI Specific.
         * @type {string}
         */
        this.accessPolicyType = this.getAccessPolicyType_(samlSpConfig, ssoPolicyRef);
    }

    /**
     * Returns access policy type (SAML/PingAccess/None) of a VS.
     * @param {Object} samlSpConfig
     * @param {string} ssoPolicyRef
     * @returns {string} access policy type
     * @protected
     */
    getAccessPolicyType_(samlSpConfig, ssoPolicyRef) {
        if (!_.isEmpty(samlSpConfig)) {
            return 'saml';
        } else if (ssoPolicyRef) {
            return 'pingaccess';
        }

        return 'none';
    }

    /**
     * Fires when access type is changed.
     * Clears ssoPolicyRef and samlSpConfig fields.
     */
    clearAccessPolicySettings() {
        delete this.samlSpConfig;
        delete this.ssoPolicyRef;

        if (this.accessPolicyType === 'saml') {
            this.setDefaultSamlSettings_();
        }
    }

    /**
     * Fires when user clicks at SAML radio button.
     * Sets samlSpConfig default values to show in input fields.
     * @protected
     */
    setDefaultSamlSettings_() {
        this.samlSpConfig = {};

        const defaultSamlConfig =
            this.defaultValues_.getDefaultItemConfigByType('SAMLSPConfig');

        Object.assign(this.samlSpConfig, defaultSamlConfig);
    }
}

VsAccessPolicySettingsController.$inject = [
    'defaultValues',
];

/**
 * @name vsAccessPolicySettingsComponent
 * @memberOf module:avi/vs
 * @description
 *     Access policy settings (SAML & PingAccess) for virtual service.
 *     Only applicable for HTTP Virtual Services.
 * @param {module:avi/vs.vsAccessPolicySettingsBindings} bindings
 * @param {module:avi/vs.VsAccessPolicySettingsController} controller
 * @author Aravindh Nagarajan
 */
angular.module('avi/vs').component('vsAccessPolicySettings', {
    /**
     * @mixin vsAccessPolicySettingsBindings
     * @memberOf module:avi/vs
     * @property {Object} samlSpConfig VirtualService#data#config#saml_sp_config
     * @property {string} ssoPolicyRef VirtualService#data#config#sso_policy_ref
     */
    bindings: {
        samlSpConfig: '=',
        ssoPolicyRef: '=',
    },
    controller: VsAccessPolicySettingsController,
    templateUrl:
        'src/components/applications/virtualservice/vs-access-policy-settings/' +
        'vs-access-policy-settings.component.html',
});

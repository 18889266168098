/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'waf';
const componentName = 'waf-rule-group-modal';
const prefix = `${moduleName}.${componentName}`;

export const generalSectionHeader = `${prefix}.generalSectionHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const namePlaceholderLabel = `${prefix}.namePlaceholderLabel`;
export const enableGroupCheckboxLabel = `${prefix}.enableGroupCheckboxLabel`;
export const columnTitleSubnet = `${prefix}.columnTitleSubnet`;
export const columnTitlePath = `${prefix}.columnTitlePath`;
export const columnTitleMatchElement = `${prefix}.columnTitleMatchElement`;
export const actionTitleRemove = `${prefix}.actionTitleRemove`;
export const actionTitleEdit = `${prefix}.actionTitleEdit`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const exceptionsHeader = `${prefix}.exceptionsHeader`;

export const ENGLISH = {
    [generalSectionHeader]: 'General',
    [nameInputLabel]: 'Name',
    [namePlaceholderLabel]: 'Enter Name',
    [enableGroupCheckboxLabel]: 'Enable Group',
    [columnTitleSubnet]: 'Subnet',
    [columnTitlePath]: 'Path',
    [columnTitleMatchElement]: 'Match Element',
    [actionTitleRemove]: 'Remove',
    [actionTitleEdit]: 'Edit',
    [addButtonLabel]: 'Add',
    [exceptionsHeader]: 'Exceptions ({0})',
};

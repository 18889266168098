/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name  virtualserviceSecurityDdosFullList
 * @param {Object} gridConfig - Collection Grid config object for DDoS collections.
 * @param {string} title - Title of the modal.
 * @param {function} closeModal - Closes modal.
 * @description
 *     Displays the full list of DDoS Attacks/Client IPs/URLs/ASNs/Blocked IPs.
 */
angular.module('aviApp').component('virtualserviceSecurityDdosFullList', {
    bindings: {
        gridConfig: '<',
        title: '@',
        closeModal: '&',
    },
    templateUrl: 'src/components/modals/applications/virtualservice/' +
            'virtualservice-security-ddos-full-list/' +
            'virtualservice-security-ddos-full-list.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './RoleService.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').service('roleService', RoleService);
/**
 * @typedef {Object} RoleNode
 * @property {string} name
 * @property {string} permission
 * @property {string} type
 * @property {boolean} assorted
 * @property {RoleNode[]} children
 * @property {RoleNode} parentNode
 * @property {boolean} canWrite
 * @property {boolean} canRead
 * @property {string} hint
 */

/**
 * @typedef {Object} BasicRoleNode
 * @property {string} name
 * @property {string} permission
 * @property {BasicRoleNode[]} children
 */

/**
 * Role structure.
 * @const {Array<BasicRoleNode>}
 */
// TODO(logashoff): Generate this array from Schema object.
const roleCategories = [
    {
        name: l10nKeys.applicationLabel,
        permission: 'APPLICATION',
        children: [
            {
                name: l10nKeys.virtualServiceLabel,
                permission: 'PERMISSION_VIRTUALSERVICE',
            }, {
                name: l10nKeys.poolLabel,
                permission: 'PERMISSION_POOL',
            }, {
                name: l10nKeys.poolGroupLabel,
                permission: 'PERMISSION_POOLGROUP',
            }, {
                name: l10nKeys.httpPolicySetLabel,
                permission: 'PERMISSION_HTTPPOLICYSET',
            }, {
                name: l10nKeys.networkSecurityPolicyLabel,
                permission: 'PERMISSION_NETWORKSECURITYPOLICY',
            }, {
                name: l10nKeys.autoScaleLabel,
                permission: 'PERMISSION_AUTOSCALE',
            }, {
                name: l10nKeys.dnsPolicyLabel,
                permission: 'PERMISSION_DNSPOLICY',
            },
        ],
    }, {
        name: l10nKeys.profilesLabel,
        permission: 'PROFILE',
        children: [
            {
                name: l10nKeys.tcpUdpProfileLabel,
                permission: 'PERMISSION_NETWORKPROFILE',
            }, {
                name: l10nKeys.applicationProfileLabel,
                permission: 'PERMISSION_APPLICATIONPROFILE',
            }, {
                name: l10nKeys.persistenceProfileLabel,
                permission: 'PERMISSION_APPLICATIONPERSISTENCEPROFILE',
            }, {
                name: l10nKeys.healthMonitorLabel,
                permission: 'PERMISSION_HEALTHMONITOR',
            }, {
                name: l10nKeys.analyticsProfileLabel,
                permission: 'PERMISSION_ANALYTICSPROFILE',
            }, {
                name: l10nKeys.ipamDnsProfileLabel,
                permission: 'PERMISSION_IPAMDNSPROVIDERPROFILE',
            }, {
                name: l10nKeys.customIpamDnsProfileLabel,
                permission: 'PERMISSION_CUSTOMIPAMDNSPROFILE',
            }, {
                name: l10nKeys.trafficCloneLabel,
                permission: 'PERMISSION_TRAFFICCLONEPROFILE',
            }, {
                name: l10nKeys.icapProfileLabel,
                permission: 'PERMISSION_ICAP',
            },
        ],
    }, {
        name: l10nKeys.groupScriptLabel,
        permission: 'GROUPS',
        children: [
            {
                name: l10nKeys.ipAddressGroupLabel,
                permission: 'PERMISSION_IPADDRGROUP',
            }, {
                name: l10nKeys.stringGroupLabel,
                permission: 'PERMISSION_STRINGGROUP',
            }, {
                name: l10nKeys.dataScriptsLabel,
                permission: 'PERMISSION_VSDATASCRIPTSET',
            }, {
                name: l10nKeys.protocolParserScriptsLabel,
                permission: 'PERMISSION_PROTOCOLPARSER',
            },
        ],
    }, {
        name: l10nKeys.securityLabel,
        permission: 'SECURITY',
        children: [
            {
                name: l10nKeys.sslTlsProfileLabel,
                permission: 'PERMISSION_SSLPROFILE',
            }, {
                name: l10nKeys.authenticationProfileLabel,
                permission: 'PERMISSION_AUTHPROFILE',
            }, {
                name: l10nKeys.pingAccessAgentLabel,
                permission: 'PERMISSION_PINGACCESSAGENT',
            }, {
                name: l10nKeys.pkiProfileLabel,
                permission: 'PERMISSION_PKIPROFILE',
            }, {
                name: l10nKeys.sslTlsCertificatesLabel,
                permission: 'PERMISSION_SSLKEYANDCERTIFICATE',
            }, {
                name: l10nKeys.certificateManagementProfileLabel,
                permission: 'PERMISSION_CERTIFICATEMANAGEMENTPROFILE',
            }, {
                name: l10nKeys.hardwareSecurityModuleGroupLabel,
                permission: 'PERMISSION_HARDWARESECURITYMODULEGROUP',
            }, {
                name: l10nKeys.ssoPolicyLabel,
                permission: 'PERMISSION_SSOPOLICY',
            },
        ],
    }, {
        name: l10nKeys.policyLabel,
        permission: 'POLICIES',
        children: [
            {
                name: l10nKeys.natPolicyLabel,
                permission: 'PERMISSION_NATPOLICY',
            },
            {
                name: l10nKeys.l4PolicySetLabel,
                permission: 'PERMISSION_L4POLICYSET',
            },
        ],
    }, {
        name: 'WAF',
        permission: 'WAF',
        children: [
            {
                name: l10nKeys.wafProfileLabel,
                permission: 'PERMISSION_WAFPROFILE',
            }, {
                name: l10nKeys.wafPolicyLabel,
                permission: 'PERMISSION_WAFPOLICY',
            }, {
                name: l10nKeys.positiveSecurityLabel,
                permission: 'PERMISSION_WAFPOLICYPSMGROUP',
            },
        ],
    }, {
        name: l10nKeys.errorPageLabel,
        permission: 'ERRORPAGE',
        children: [
            {
                name: l10nKeys.errorPageProfileLabel,
                permission: 'PERMISSION_ERRORPAGEPROFILE',
            }, {
                name: l10nKeys.errorPageBodyLabel,
                permission: 'PERMISSION_ERRORPAGEBODY',
            },
        ],
    }, {
        name: l10nKeys.operationsLabel,
        permission: 'OPERATIONS',
        children: [
            {
                name: l10nKeys.alertConfigLabel,
                permission: 'PERMISSION_ALERTCONFIG',
            }, {
                name: l10nKeys.alertLabel,
                permission: 'PERMISSION_ALERT',
            }, {
                name: l10nKeys.alertActionLabel,
                permission: 'PERMISSION_ACTIONGROUPCONFIG',
            }, {
                name: l10nKeys.syslogLabel,
                permission: 'PERMISSION_ALERTSYSLOGCONFIG',
            }, {
                name: l10nKeys.emailLabel,
                permission: 'PERMISSION_ALERTEMAILCONFIG',
            }, {
                name: l10nKeys.snmpTrapsLabel,
                permission: 'PERMISSION_SNMPTRAPPROFILE',
            }, {
                name: l10nKeys.trafficCaptureLabel,
                permission: 'PERMISSION_TRAFFIC_CAPTURE',
            },
        ],
    }, {
        name: l10nKeys.infrastructureLabel,
        permission: 'INFRASTRUCTURE',
        children: [
            {
                name: l10nKeys.cloudLabel,
                permission: 'PERMISSION_CLOUD',
            }, {
                name: l10nKeys.serviceEngineLabel,
                permission: 'PERMISSION_SERVICEENGINE',
            }, {
                name: l10nKeys.serviceEngineGroupLabel,
                permission: 'PERMISSION_SERVICEENGINEGROUP',
            }, {
                name: l10nKeys.networkLabel,
                permission: 'PERMISSION_NETWORK',
            }, {
                name: l10nKeys.vrfContextLabel,
                permission: 'PERMISSION_VRFCONTEXT',
            }, {
                name: l10nKeys.userCredentialsLabel,
                permission: 'PERMISSION_USER_CREDENTIAL',
            },
        ],
    }, {
        name: l10nKeys.administrationLabel,
        permission: 'ADMINISTRATION',
        children: [
            {
                name: l10nKeys.systemSettingsLabel,
                permission: 'PERMISSION_SYSTEMCONFIGURATION',
            }, {
                name: l10nKeys.controllerLabel,
                permission: 'PERMISSION_CONTROLLER',
            }, {
                name: l10nKeys.rebootLabel,
                permission: 'PERMISSION_REBOOT',
            }, {
                name: l10nKeys.upgradeLabel,
                permission: 'PERMISSION_UPGRADE',
            }, {
                name: l10nKeys.troubleshootingLabel,
                permission: 'PERMISSION_TECHSUPPORT',
            }, {
                name: l10nKeys.internalLabel,
                permission: 'PERMISSION_INTERNAL',
            }, {
                name: l10nKeys.controllerSiteLabel,
                permission: 'PERMISSION_CONTROLLERSITE',
            }, {
                name: l10nKeys.softwareLabel,
                permission: 'PERMISSION_IMAGE',
            },
        ],
    }, {
        name: l10nKeys.accountsLabel,
        permission: 'ACCOUNT',
        children: [
            {
                name: l10nKeys.usersLabel,
                permission: 'PERMISSION_USER',
            }, {
                name: l10nKeys.rolesLabel,
                permission: 'PERMISSION_ROLE',
            }, {
                name: l10nKeys.tenantLabel,
                permission: 'PERMISSION_TENANT',
            },
        ],
    }, {
        name: 'GSLB',
        permission: 'GSLB',
        children: [
            {
                name: l10nKeys.gslbConfigurationLabel,
                permission: 'PERMISSION_GSLB',
            }, {
                name: l10nKeys.gslbServicesLabel,
                permission: 'PERMISSION_GSLBSERVICE',
            }, {
                name: l10nKeys.gslbGeoProfileLabel,
                permission: 'PERMISSION_GSLBGEODBPROFILE',
            },
        ],
    },
];

/**
 * Maps permission name to it's type in {@link roleCategories}.
 * @type {Object<string, RoleNode>}
 * @private
 */
const permissionNodeMap = {};

/**
 * Role service. Handles most interactions between, Role, RoleCollection and RoleController.
 * @constructor
 */
function RoleService(l10nService) {
    this.l10nKeys = l10nKeys;
    this.l10nService_ = l10nService;
    l10nService.registerSourceBundles(dictionary);
}

RoleService.$inject = ['l10nService'];

/**
 * @const {string}
 */
RoleService.prototype.NO_ACCESS = 'NO_ACCESS';

/**
 * @const {string}
 */
RoleService.prototype.READ_ACCESS = 'READ_ACCESS';

/**
 * @const {string}
 */
RoleService.prototype.WRITE_ACCESS = 'WRITE_ACCESS';

/**
 * Returns roles category structure.
 * @returns {Array<BasicRoleNode>}
 */
RoleService.prototype.getRoleCategories = function() {
    return roleCategories;
};

/**
 * Returns current permission node map which can be modified by multiple services.
 * @returns {Object<string, string>}
 */
RoleService.prototype.getCurrentPermissionNodeMap = function() {
    return permissionNodeMap;
};

/**
 * Processes {@link BasicRoleNode} array and returns array containing {@link RoleNode} items.
 * Modifies {@link permissionNodeMap} by mapping privilege type to created permission node.
 * @param {Array<BasicRoleNode>} source - Source array with role structure similar to
 *     {@link roleCategories}.
 * @param {Object<string, string>} privilegeTypeMap - Resource-type privilege hash map.
 * @param {RoleNode} [parentNode] - Optional parent node.
 * @return {Array<RoleNode>} Data for Roles UI table.
 */
RoleService.prototype.processRoles = function(source, privilegeTypeMap, parentNode) {
    const target = [];
    const { l10nService_: l10nService } = this;

    for (let i = 0; i < source.length; i++) {
        const category = source[i];
        const privilegeNode = {
            name: l10nService.getMessage(category.name),
            permission: category.permission,
            type: privilegeTypeMap[category.permission] || this.NO_ACCESS,
            assorted: false,
            children: [],
            parentNode,
            canWrite: true,
            canRead: true,
            hint: '',
        };

        permissionNodeMap[category.permission] = privilegeNode;
        target.push(privilegeNode);

        if (category.children && category.children.length) {
            privilegeNode.children = this.processRoles(
                category.children, privilegeTypeMap, privilegeNode,
            );
            this.setRoleNodeType(privilegeNode);
        }
    }

    return target;
};

/**
 * Sets specified RoleNode's type based on its children properties.
 * @param {RoleNode} roleNode
 */
RoleService.prototype.setRoleNodeType = function(roleNode) {
    if (roleNode && roleNode.children || !roleNode.children.length) {
        let allSelected = true;
        let hasRead = false;
        let hasWrite = false;
        let hasNoAccess = false;
        let [{ type }] = roleNode.children;

        for (let i = 0; i < roleNode.children.length; i++) {
            const child = roleNode.children[i];

            if (!child.type || child.type !== type) {
                allSelected = false;
            }

            if (!hasNoAccess && child.type === this.NO_ACCESS) {
                hasNoAccess = true;
            }

            if (!hasRead && child.type === this.READ_ACCESS) {
                hasRead = true;
            }

            if (!hasWrite && child.type === this.WRITE_ACCESS) {
                hasWrite = true;
            }

            type = child.type;
        }

        roleNode.assorted = this.isAssorted(hasRead, hasWrite, hasNoAccess);

        if (allSelected) {
            roleNode.type = type;
        } else {
            roleNode.type = '';
        }
    }
};

/**
 * Checks if passed arguments are assorted i.e. more than 1 is of the same value.
 * @param {boolean} hasRead
 * @param {boolean} hasWrite
 * @param {boolean} hasNoAccess
 * @returns {boolean} True if arguments are assorted.
 */
RoleService.prototype.isAssorted = function(hasRead, hasWrite, hasNoAccess) {
    return hasRead && hasWrite || hasRead && hasNoAccess || hasWrite && hasNoAccess;
};

/**
 * Maps privilege resource to its type.
 * @param {Array<{permission: string, type: string}>} data
 * @returns {Object<string, string>}
 */
RoleService.prototype.createPrivilegeHashMap = function(data) {
    const hashMap = {};

    if (!data || !data.length) {
        return hashMap;
    }

    for (let i = 0; i < data.length; i++) {
        const item = data[i];

        hashMap[item.resource] = item.type;
    }

    return hashMap;
};

/**
 * Converts role nodes back to server defined privilege objects.
 * @param {Array<RoleNode>} roles
 * @returns {Object.<string, Object>}
 */
RoleService.prototype.flattenRoles = function(roles) {
    const privileges = {};

    for (let i = 0; i < roles.length; i++) {
        const parent = roles[i];
        const { children } = parent;

        children.forEach(child => {
            const {
                permission: resource,
                type,
            } = child;

            if (resource && type) {
                privileges[resource] = {
                    resource,
                    type,
                };
            }
        });
    }

    return privileges;
};

/**
 * Returns roles row transform function for Roles table assuming each parent node in
 * {@link roleCategories} corresponds to each Role table column.
 * @param {number} columnIndex - Column index in Roles table. Matches each parent node in
 *     {@link roleCategories}.
 * @returns {Function} Row transform function.
 */
RoleService.prototype.getRowTransformFunctionBasedOnColumnIndex = function(columnIndex) {
    const self = this;
    const { l10nService_: l10nService } = this;

    return function(row) {
        const privileges = row.getPrivileges();
        const { children } = privileges[columnIndex];

        if (!children) {
            return '';
        }

        let hasRead = false;
        let hasWrite = false;
        let hasNoAccess = false;

        for (let i = 0; i < children.length; i++) {
            const child = children[i];

            if (!hasRead && child.type === self.READ_ACCESS) {
                hasRead = true;
            }

            if (!hasWrite && child.type === self.WRITE_ACCESS) {
                hasWrite = true;
            }

            if (!hasNoAccess && child.type === self.NO_ACCESS) {
                hasNoAccess = true;
            }

            if (hasRead && hasWrite && hasNoAccess) {
                break;
            }
        }

        if (self.isAssorted(hasRead, hasWrite, hasNoAccess)) {
            return l10nService.getMessage(l10nKeys.assortedLabel);
        } else if (hasNoAccess) {
            return l10nService.getMessage(l10nKeys.noAccessLabel);
        } else if (hasRead) {
            return l10nService.getMessage(l10nKeys.readLabel);
        } else if (hasWrite) {
            return l10nService.getMessage(l10nKeys.writeLabel);
        }

        return '';
    };
};

/**
 * @module ScriptModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { copy } from 'angular';

import {
    pluck,
    unique,
} from 'underscore';

import {
    IVSDataScript,
    IVSDataScriptSet,
    VSDataScriptEvent,
} from 'generated-types';

import { Item } from 'ajs/modules/data-model';
import { StringService } from 'ajs/modules/core';

type TDataScriptTypesHash = Record<VSDataScriptEvent, IVSDataScript>;

const dataScriptItemProperties = {
    objectName: 'vsdatascriptset',
    windowElement: 'datascriptset-modal',
};

/**
 * Common prefix for all Datascript event names.
 */
const VSDataScriptEventPrefix = 'VS_DATASCRIPT_EVT_';

// Supported Datascript events
const l4EventTypes = [
    VSDataScriptEvent.VS_DATASCRIPT_EVT_L4_REQUEST,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_L4_RESPONSE,
];

const dnsEventTypes = [
    VSDataScriptEvent.VS_DATASCRIPT_EVT_DNS_REQ,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_DNS_RESP,
];

const sslEventTypes = [
    VSDataScriptEvent.VS_DATASCRIPT_EVT_SSL_HANDSHAKE_DONE,
];

const httpEventTypes = [
    VSDataScriptEvent.VS_DATASCRIPT_EVT_HTTP_AUTH,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_HTTP_POST_AUTH,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_HTTP_REQ,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_HTTP_REQ_DATA,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_HTTP_LB_DONE,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_HTTP_RESP,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_HTTP_RESP_DATA,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_HTTP_RESP_FAILED,
];

/**
 * @description
 *
 *   VS DatascriptSet item.
 *
 * @author Aravindh Nagarajan
 */
export class DataScriptSet extends Item<IVSDataScriptSet> {
    public static readonly l4EventTypes = l4EventTypes;
    public static readonly dnsEventTypes = dnsEventTypes;
    public static readonly sslEventTypes = sslEventTypes;
    public static readonly httpEventTypes = httpEventTypes;

    public static readonly supportedEventTypes = DataScriptSet.l4EventTypes
        .concat(
            DataScriptSet.sslEventTypes,
            DataScriptSet.httpEventTypes,
            DataScriptSet.dnsEventTypes,
        );

    constructor(args = {}) {
        super({
            ...args,
            ...dataScriptItemProperties,
        });
    }

    /**
     * Returns human readable string out of VSDataScriptEvent enum value.
     */
    public static getDSEventLabel(eventName: VSDataScriptEvent): string {
        const stringService: StringService = this.getAjsDependency_('stringService');

        return stringService.enumeration(eventName, VSDataScriptEventPrefix);
    }

    /**
     * Returns basic configuration of VSDataScript.
     */
    private static getDefaultDSConfig(eventType: VSDataScriptEvent): IVSDataScript {
        return {
            evt: eventType,
            script: '',
        };
    }

    /** @override */
    public beforeEdit(): void {
        this.transformAfterConfigLoad(this.getConfig());

        const typesHash = {} as unknown as TDataScriptTypesHash;
        const config = this.getConfig();
        const { datascript: list, rate_limiters: rateLimiters } = config;
        const dataScriptList: IVSDataScript[] = [];

        if (!rateLimiters) {
            config.rate_limiters = [];
        }

        list.forEach(({ evt }, index) => typesHash[evt] = list[index]);

        DataScriptSet.supportedEventTypes.forEach((eventType: VSDataScriptEvent) => {
            const eventConfig = typesHash[eventType];

            if (!eventConfig) {
                dataScriptList.push(DataScriptSet.getDefaultDSConfig(eventType));
            } else {
                dataScriptList.push(eventConfig);
            }
        });

        this.getConfig().datascript = dataScriptList;
    }

    /** @override */
    public transformAfterLoad(): void {
        this.transformAfterConfigLoad(this.getConfig());
    }

    /** @override */
    public dataToSave(): IVSDataScriptSet {
        const config = copy(this.getConfig());

        const {
            datascript: datascriptList,
            rate_limiters: rateLimiters,
        } = config;

        if (rateLimiters && !rateLimiters.length) {
            delete config.rate_limiters;
        }

        config.datascript = datascriptList
            .filter((datascript: IVSDataScript) => {
                const { script } = datascript;

                if (script) {
                    datascript.script = script
                        .replace(/[\u2018\u2019]/g, '\'')
                        .replace(/[\u201C\u201D]/g, '"');

                    return true;
                }

                return false;
            });

        return config;
    }

    /**
     * Checks whether current config can be saved.
     */
    public hasValidConfig(): boolean {
        const { datascript: datascriptList } = this.getConfig();

        return datascriptList.some(({ script }) => !!script);
    }

    /**
     * Returns a list of event types used by this set as list of enums.
     */
    public getListOfEventTypes(): VSDataScriptEvent[] {
        const { datascript: list } = this.getConfig();
        const types = pluck(list, 'evt');

        return unique(types);
    }

    /**
     * Modifies Item's configuration to be consistent.
     */
    private transformAfterConfigLoad(config: IVSDataScriptSet): IVSDataScriptSet {
        if (!('datascript' in config)) {
            config.datascript = [];
        }

        return config;
    }
}

DataScriptSet.ajsDependencies = [
    'stringService',
];

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as seGroupAutoscaleSettingsTemplate from
    './se-group-autoscale-settings.component.partial.html';
import './se-group-autoscale-settings.less';

/**
 * @constructor
 * @memberOf module:avi/serviceengine
 * @mixes module:avi/serviceengine.seGroupAutoscaleSettingsBindings
 * @see {@link module:avi/serviceengine.seGroupAutoscaleSettingsComponent}
 */
class SeGroupAutoscaleSettingsController {
    constructor(schemaService, dropDownUtils) {
        this.minCpuUsagePercentRange =
            schemaService.getFieldRangeAsTuple('ServiceEngineGroup', 'min_cpu_usage');
        this.maxCpuUsagePercentRange =
            schemaService.getFieldRangeAsTuple('ServiceEngineGroup', 'max_cpu_usage');

        this.autoRebalanceCriterionOptions = dropDownUtils
            .getEnumDropdownOptions('AutoRebalanceCriterion');
    }

    /**
     * Handler for Auto-rebalance criteria dropdown's change event. It automatically
     * prompts the input for Auto Rebalance Capacity Per SE For each selected criteria except
     * for CPU option.
     */
    handleAutoRebalanceCriteriaChange() {
        this.editable.getConfig().auto_rebalance_capacity_per_se = [];
    }

    /**
     * Returns selected Auto Rebalance Criteria label
     * @returns {string}
     */
    getAutoRebalanceCriteriaLabel() {
        const { auto_rebalance_criteria: autoRebalanceCriteria } = this.editable.getConfig();

        return autoRebalanceCriteria[0].enumeration('SE_AUTO_REBALANCE_');
    }

    /**
     * Handler for auto-rebalance checkbox toggle.
     * The previously selected values for auto-rebalance criteria
     * are reset when the checkbox is unchecked.
     */
    handleAutoRebalanceCheckboxToggle() {
        const { auto_rebalance: autoRebalance } = this.editable.getConfig();

        if (!autoRebalance) {
            this.editable.resetAutoRebalanceCriterion();
        }
    }

    /**
     * Return true if auto scale subnet count is greater than zero
     * @returns {boolean}
     */
    autoscaleSubnetRequired() {
        return this.editable.getConfig().vip_asg.configuration.zones.length > 0;
    }

    /**
     * Removes zone from VipAutoscaleZones at specified index.
     * @param {number} index
     */
    removeVipAutoscaleZone(index) {
        const { vip_asg: vipAsg } = this.editable.getConfig();

        vipAsg.configuration.zones.splice(index, 1);
    }

    /**
     * Adds empty zone to VipAutoscaleZones.
     */
    addVipAutoscaleZone() {
        const { vip_asg: vipAsg } = this.editable.getConfig();

        vipAsg.configuration.zones.push({
            subnet_uuid: '',
        });
    }
}

SeGroupAutoscaleSettingsController.$inject = [
    'schemaService',
    'dropDownUtils',
];

/**
 * @mixin seGroupAutoscaleSettingsBindings
 * @memberOf module:avi/serviceengine
 * @see {@link module:avi/serviceengine.seGroupAutoscaleSettingsComponent}
 * @property {SEGroup} editable - SEGroup instance
 * @property {SubnetListNetworkCollection} subnetListNetworkCollection - subnetListNetworkCollection
 *  instance
 */
const seGroupAutoscaleSettingsBindings = {
    editable: '<',
    subnetListNetworkCollection: '<',
};

/**
 * @name seGroupAutoscaleSettingsComponent
 * @ngdoc component
 * @memberOf module:avi/serviceengine
 * @property {module:avi/serviceengine.seGroupAutoscaleSettingsBindings} bindings
 * @property {module:avi/serviceengine.SeGroupAutoscaleSettingsController} controller
 * @description Component for selecting licensing info for SEGroup instance.
 * @author Ashish Verma
 */
angular.module('avi/serviceengine').component('seGroupAutoscaleSettings', {
    bindings: seGroupAutoscaleSettingsBindings,
    controller: SeGroupAutoscaleSettingsController,
    template: seGroupAutoscaleSettingsTemplate,
});

/**
 * @module CloudModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { ITier1LogicalRouterInfo } from 'generated-types';
import { IMessageItemData, MessageItem } from 'ajs/modules/data-model';

interface ITier1LogicalRouterInfoData extends IMessageItemData {
    config: ITier1LogicalRouterInfo;
}

export class Tier1LogicalRouterInfoConfigItem extends MessageItem {
    public data: ITier1LogicalRouterInfoData;

    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'Tier1LogicalRouterInfo',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public isValid(): boolean {
        return this.canFlatten();
    }

    /** @override */
    public canFlatten(): boolean {
        return Boolean(this.config.tier1_lr_id);
    }

    /**
     * Clears the configured Segment ID.
     */
    public clearSegmentID(): void {
        this.config.segment_id = undefined;
    }

    /**
     * Clears both configured Tier1LR ID and Segment ID.
     */
    public clearFields(): void {
        this.clearTier1LRID();
        this.clearSegmentID();
    }

    /**
     * Clears the configured Tier1LR ID.
     */
    private clearTier1LRID(): void {
        this.config.tier1_lr_id = undefined;
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').service('DisplayValue', ['Convert', 'GraphSync',
function(Convert, GraphSync) {
    this.createCardValueUnit = function(series, displayValue) {
        if (series && series.hasData()) {
            if (GraphSync.mouseOnGraph) {
                const val = series.getDataPoint(GraphSync.mouseOnGraph);

                if (val) {
                    return Convert.getVal(val.value, series.getUnits());
                } else {
                    return {
                        value: '',
                    };
                }
            } else if (displayValue === 'current') {
                return Convert.getVal(series.getValue('current'), series.getUnits());
            } else if (displayValue === 'sum') {
                if (_.isNaN(Number(series.getValue('sum')))) {
                    return {
                        value: 'NA',
                    };
                } else {
                    const cardValUnit = Convert.getVal(series.getValue('sum'), series.getUnits());

                    cardValUnit.unit = Convert.toSum(cardValUnit.unit);

                    //Convert Xb from bits to Bytes
                    //TODO: move this into convert
                    if (cardValUnit.unit && cardValUnit.unit.length === 2 &&
                        cardValUnit.unit[1] === 'b') {
                        cardValUnit.unit = `${cardValUnit.unit[0]}bytes`;
                        cardValUnit.value /= 8;
                    }

                    return cardValUnit;
                }
            } else {
                return Convert.getVal(series.getValue(displayValue), series.getUnits());
            }
        } else if (series && series.isAggregated()) {
            if (GraphSync.mouseOnGraph) {
                return {
                    value: 'N/A',
                };
            } else {
                return {
                    value: '',
                };
            }
        } else {
            return {
                value: '',
            };
        }
    };

    return this;
}]);

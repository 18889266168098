/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  IpamDnsAwsProfileConfig
 * @description  IpamDnsAwsProfileConfig ConfigItem class.
 */
const configFactory = (ConfigItem, defaultValues, $q, secretStubStr) => {
    return class IpamDnsAwsProfileConfig extends ConfigItem {
        constructor(args) {
            const
                extendedArgs = { type: 'IpamDnsAwsProfile' },
                defaultAWSConfig =
                    defaultValues.getDefaultItemConfig('ipamdnsproviderprofile.aws_profile');

            if (defaultAWSConfig) {
                extendedArgs.defaultConfig = defaultAWSConfig;
            }

            angular.extend(extendedArgs, args);

            super(extendedArgs);
        }

        /**
         * Called when user toggles using IAM roles in AWS IPAM Profile configuration.
         */
        clearAuthenticationFields() {
            const config = this.getConfig();

            config.access_key_id = undefined;
            config.secret_access_key = undefined;
        }

        /**
         * Called when user changes VPC. Removes all usable networks from AWS profile.
         */
        clearUsableNetworks() {
            const { zones } = this.getConfig();

            if (Array.isArray(zones)) {
                zones.length = 0;
            }
        }

        /**
         * Called when user changes VPC. Removes all usable domains from AWS profile.
         */
        clearUsableDomains() {
            const { usable_domains: usableDomains } = this.getConfig();

            if (Array.isArray(usableDomains)) {
                usableDomains.length = 0;
            }
        }

        /**
         * Adds empty usable_network_refs entry to AWS profiles.
         */
        addUsableNetwork() {
            this.getConfig().zones.push({
                availability_zone: undefined,
                usable_network_uuids: [],
            });
        }

        /**
         * Removes Network ref from AWS network list.
         * @param {number=} index
         */
        removeUsableNetwork(index = 0) {
            this.getConfig().zones.splice(index, 1);
        }

        /**
         * Adds empty usable domain entry to AWS profiles.
         */
        addUsableDomain() {
            this.getConfig().usable_domains.push(undefined);
        }

        /**
         * Removes usable domain from AWS domain list.
         * @param {number=} index
         */
        removeUsableDomain(index = 0) {
            this.getConfig().usable_domains.splice(index, 1);
        }

        /**
         * Gets a list of AWS regions.
         * @return {ng.$q.promise}
         */
        getRegions() {
            this.busy = true;
            this.errors = null;

            return this.request('GET', '/api/aws-get-regions')
                .then(({ data }) => data.regions)
                .catch(rsp => this.errors = rsp.data)
                .finally(() => this.busy = false);
        }

        /**
         * Returns params used for AWS requests. If sensitive fields are found, includes the
         * parentId (IPAM profile uuid).
         * @param {Object} proxyConfig - Proxy configuration, alternative params used for AWS
         *     requests.
         * @return {Object}
         */
        _getParams(proxyConfig) {
            const {
                access_key_id: accessKeyId,
                secret_access_key: secretAccessKey,
                vpc_id: vpcId,
                region,
                use_iam_roles: useIamRoles,
                iam_assume_role: iamAssumeRole,
            } = this.getConfig();

            const params = {
                username: accessKeyId,
                password: secretAccessKey,
                vpc: vpcId,
                region,
                use_iam_roles: useIamRoles,
                iam_assume_role: iamAssumeRole,
            };

            if (!_.isEmpty(proxyConfig)) {
                angular.extend(params, {
                    proxy_host: proxyConfig.host,
                    proxy_port: proxyConfig.port,
                    proxy_user: proxyConfig.username,
                    proxy_pass: proxyConfig.password,
                });
            }

            if (this._parentId && (params.password === secretStubStr ||
                angular.isObject(proxyConfig) && proxyConfig.password === secretStubStr)) {
                params['ipamdnsprovider_uuid'] = this._parentId;
                delete params.username;
                delete params.password;
                delete params.proxy_pass;
            }

            return params;
        }

        /**
         * Returns lists of AWS networks and VPCs.
         * @param {string} list - Specifies which list to request. If undefined, get both.
         * @param {Object} proxyConfig - Proxy configuration, alternative params used for AWS
         *     requests.
         * @return {ng.$q.promise}
         */
        getLists(list, proxyConfig) {
            const promises = [this.getVpcs(proxyConfig)];

            switch (list) {
                case 'zones':
                    promises.push(this.getAvailabilityZones(proxyConfig));
                    break;
                case 'domains':
                    promises.push(this.getDomains(proxyConfig));
                    break;
            }

            return $q.all(promises);
        }

        /**
         * Makes request for the list of availability zones to be used for usable networks.
         * @param {Object} proxyConfig - Proxy configuration, alternative params used for AWS
         *     request.
         * @return {ng.$q.promise}
         */
        getAvailabilityZones(proxyConfig) {
            const params = this._getParams(proxyConfig);

            this.errors = null;

            return this.request('POST', '/api/aws-get-networks', params)
                .then(({ data }) => data.networks)
                .catch(({ data }) => {
                    this.errors = data;

                    return $q.reject();
                });
        }

        /**
         * Makes request for the domains list without dealing with this.busy or this.errors, so
         * that they can be managed by the caller.
         * @param {Object} proxyConfig - Proxy configuration, alternative params used for AWS
         *     request.
         * @return {ng.$q.promise}
         */
        getDomains(proxyConfig) {
            this.errors = null;

            const params = this._getParams(proxyConfig);
            const { ipamdnsprovider_uuid: uuid } = params;

            if (uuid) {
                const api = '/api/ipamdnsproviderprofiledomainlist';

                return this.request('GET', `${api}?ipamdnsprovider_uuid=${uuid}`)
                    .then(({ data }) => data.domains || [])
                    .catch(({ data }) => {
                        this.errors = data;

                        return $q.reject(data);
                    });
            }

            return $q.when([]);
        }

        /**
         * Gets list of AWS VPCs.
         * @param {Object} proxyConfig - Proxy configuration, alternative params used for AWS
         *     request.
         * @return {ng.$q.promise}
         */
        getVpcs(proxyConfig) {
            const params = this._getParams(proxyConfig);

            this.errors = null;

            return this.request('POST', '/api/aws-verify-credentials', params)
                .then(({ data }) => data.vpcs)
                .catch(({ data }) => {
                    this.errors = data;

                    return $q.reject();
                });
        }

        /**
         * Requests AWS AssumeRole list.
         * @param {Object} proxyConfig - Proxy configuration, alternative params used for AWS
         *     requests.
         * @returns {ng.$q.promise}
         */
        getIamAssumeRoles(proxyConfig) {
            const params = this._getParams(proxyConfig);

            this.errors = null;

            return this.request('POST', '/api/aws-get-assume-roles', params)
                .then(({ data }) => data.assume_roles)
                .catch(({ data }) => this.errors = data);
        }

        /**
         * Sets iam_assume_role in aws_profile to undefined.
         */
        removeIamAssumeRole() {
            const config = this.getConfig();

            config.iam_assume_role = undefined;
        }

        /**
         * @override
         */
        beforeEdit() {
            const config = this.getConfig();

            config.zones = config.zones || [];
            config.usable_domains = config.usable_domains || [];
        }

        /**
         * @override
         */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            if (config.use_iam_roles) {
                delete config.access_key_id;
                delete config.secret_access_key;
            }

            if (_.isEmpty(config.usable_domains)) {
                delete config.usable_domains;
            }

            if (_.isEmpty(config.vpc_id)) {
                delete config.vpc_id;
            }

            if (_.isEmpty(config.usable_network_uuids)) {
                delete config.usable_network_uuids;
            }

            if (_.isEmpty(config.zones)) {
                delete config.zones;
            }

            return config;
        }
    };
};

configFactory.$inject = [
        'ConfigItem',
        'defaultValues',
        '$q',
        'secretStubStr',
];

angular.module('aviApp').factory('IpamDnsAwsProfileConfig', configFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').factory('VSTrafficCaptureConfig', [
'$http', 'RangeParser', 'Base', 'defaultValues', '$q', '$filter',
function($http, RangeParser, Base, defaultValues, $q, $filter) {
    const VSTrafficCaptureConfig = function() {
        this._base = new Base();

        this.config = defaultValues.getDefaultItemConfigByType('debugvirtualservice');
        this.allOrChooseIP = 'all';
        this.numberOrDuration = 'number';
        this.busy = false;
        this.errors = null;
    };

    /**
     * Gets default capture_params from defaultValues.
     * @return {Object} - Default capture_params.
     */
    VSTrafficCaptureConfig.prototype.getDefaultCaptureParams = function() {
        return defaultValues.getDefaultItemConfig('debugvirtualservice.capture_params');
    };

    /**
     * Prepares data to be saved to begin traffic capture.
     * @return {Object} - Config object to be payload.
     */
    VSTrafficCaptureConfig.prototype.dataToSave = function() {
        const config = angular.copy(this.config);

        if (this.allOrChooseIP === 'all') {
            config.debug_ip = undefined;
        } else {
            config.debug_ip = this.parseIPList(config.debug_ip);
        }

        if (this.numberOrDuration === 'duration') {
            config.capture_params.num_pkts = undefined;
        } else if (this.numberOrDuration === 'number') {
            config.capture_params.duration = undefined;
        }

        config.capture = true;

        return config;
    };

    /**
     * Makes PUT request to begin traffic capture.
     * @return {ng.$q.promise}
     */
    VSTrafficCaptureConfig.prototype.save = function() {
        const
            config = this.dataToSave(),
            uuid = config.uuid.slug(),
            self = this;

        this.busy = true;
        this.errors = null;

        return this._base.request('PUT', `/api/debugvirtualservice/${uuid}`, config)
            .catch(function(rsp) {
                self.errors = rsp.data;

                return $q.reject();
            }).finally(function() {
                self.busy = false;
            });
    };

    /**
     * Parses the filter string to create arrays for IP addresses, ranges, and subnet masks.
     * @param  {string} filter - String of IP addresses, ranges, and subnet masks joined by commas.
     * @return {ipDebug}
     */
    VSTrafficCaptureConfig.prototype.parseIPList = function(filter) {
        const
            debug = {},
            data = filter.split(',');

        data.forEach(function(addr) {
            const rangeOrIp = RangeParser.ipRange2Json(addr);

            if (!rangeOrIp) {
                return;
            }

            if (rangeOrIp.begin) {
                debug.ranges = debug.ranges || [];
                debug.ranges.push(rangeOrIp);
            } else if (rangeOrIp.mask) {
                debug.prefixes = debug.prefixes || [];
                debug.prefixes.push(rangeOrIp);
            } else {
                debug.addrs = debug.addrs || [];
                debug.addrs.push(rangeOrIp);
            }
        });

        return debug;
    };

    /**
     * Loads previous configuration of VS.
     * @return {ng.$q.promise}
     */
    VSTrafficCaptureConfig.prototype.loadConfig = function() {
        const
            vsName = this.config.uuid.name(),
            self = this;

        this.busy = true;
        this.errors = null;

        return this._base.request('GET', `/api/debugvirtualservice?include_name&name=${vsName}`)
            .then(function(rsp) {
                self.setLoadedConfig(rsp.data.results[0]);
            }).catch(function(rsp) {
                self.errors = rsp.data;

                return $q.reject(rsp.data);
            }).finally(function() {
                self.busy = false;
            });
    };

    /**
     * Sets config object based on loaded configuration of VS.
     */
    VSTrafficCaptureConfig.prototype.setLoadedConfig = function(vsConfig) {
        if (_.isUndefined(vsConfig)) {
            return;
        }

        if (vsConfig.debug_ip) {
            this.config.debug_ip = this.getFilter(vsConfig.debug_ip);
            this.allOrChooseIP = 'choose';
        } else {
            this.allOrChooseIP = 'all';
        }

        if (vsConfig.capture_params) {
            this.config.capture_params = angular.copy(vsConfig.capture_params);

            if (this.config.capture_params.duration) {
                this.numberOrDuration = 'duration';
            } else {
                this.numberOrDuration = 'number';
            }
        }

        vsConfig.uuid += `#${vsConfig.name}`;

        this.config = angular.merge(this.config, vsConfig);
    };

    /**
     * Creates a single string of IP addresses based on debug IP object.
     * @param  {Object} - Object containing IP addresses, ranges, and subnets.
     * @return {string} String of IP addresses, ranges, and subnet masks joined by commas.
     */
    VSTrafficCaptureConfig.prototype.getFilter = function(debug) {
        return $filter('ipObjectToString')(debug);
    };

    /**
     * Cancels all ongoing requests.
     */
    VSTrafficCaptureConfig.prototype.cancelRequests = function() {
        this._base.cancelRequests();
    };

    return VSTrafficCaptureConfig;
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name PersistenceProfileCollection
 */
angular.module('aviApp').factory('PersistenceProfileCollection', [
'Collection', 'PersistenceProfile',
function(Collection, PersistenceProfile) {
    class PersistenceProfileCollection extends Collection {}

    angular.extend(PersistenceProfileCollection.prototype, {
        objectName_: 'applicationpersistenceprofile',
        itemClass_: PersistenceProfile,
        windowElement_: 'persistence-profile-modal',
    });

    return PersistenceProfileCollection;
}]);

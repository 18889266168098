/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './user-profile.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  userProfileCreate
 * @param {UserProfile} editable
 * @param {Function} closeModal
 * @description
 *     Component used to create and edit a UserProfile item.
 */
class UserProfileCreateController {
    constructor(Regex, l10nService) {
        this.Regex = Regex;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }
}

UserProfileCreateController.$inject = [
        'Regex',
        'l10nService',
];

angular.module('aviApp').component('userProfileCreate', {
    bindings: {
        editable: '=',
        closeModal: '&',
    },
    controller: UserProfileCreateController,
    templateUrl:
        'src/components/modals/administration/accounts/user-profile/user-profile-create.html',
});

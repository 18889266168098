/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const prefix = 'waf';

export const wafExcludeListEntryBreadcrumbTitle = `${prefix}.wafExcludeListEntryBreadcrumbTitle`;
export const addExceptionModalHeader = `${prefix}.addExceptionModalHeader`;
export const editExceptionModalHeader = `${prefix}.editExceptionModalHeader`;
export const createPreCrsGroupModalHeader = `${prefix}.createPreCrsGroupModalHeader`;
export const editPreCrsGroupModalHeader = `${prefix}.editPreCrsGroupModalHeader`;
export const createPostCrsGroupModalHeader = `${prefix}.createPostCrsGroupModalHeader`;
export const editPostCrsGroupModalHeader = `${prefix}.editPostCrsGroupModalHeader`;
export const editCrsGroupModalHeader = `${prefix}.editCrsGroupModalHeader`;
export const createPreCrsRuleModalHeader = `${prefix}.createPreCrsRuleModalHeader`;
export const editPreCrsRuleModalHeader = `${prefix}.editPreCrsRuleModalHeader`;
export const createPostCrsRuleModalHeader = `${prefix}.createPostCrsRuleModalHeader`;
export const editPostCrsRuleModalHeader = `${prefix}.editPostCrsRuleModalHeader`;
export const editCrsRuleModalHeader = `${prefix}.editCrsRuleModalHeader`;
export const wafGroupBreadcrumbTitle = `${prefix}.wafGroupBreadcrumbTitle`;
export const wafRuleBreadcrumbTitle = `${prefix}.wafRuleBreadcrumbTitle`;

export const ENGLISH = {
    [addExceptionModalHeader]: 'Add Exception',
    [editExceptionModalHeader]: 'Edit Exception',
    [wafExcludeListEntryBreadcrumbTitle]: 'Exception',
    [createPreCrsGroupModalHeader]: 'Create Pre-CRS Group: {0}',
    [editPreCrsGroupModalHeader]: 'Edit Pre-CRS Group: {0}',
    [createPostCrsGroupModalHeader]: 'Create Post-CRS Group: {0}',
    [editPostCrsGroupModalHeader]: 'Edit Post-CRS Group: {0}',
    [editCrsGroupModalHeader]: 'Edit CRS Group: {0}',
    [createPreCrsRuleModalHeader]: 'Create Pre-CRS Rule: {0}',
    [editPreCrsRuleModalHeader]: 'Edit Pre-CRS Rule: {0}',
    [createPostCrsRuleModalHeader]: 'Create Post-CRS Rule: {0}',
    [editPostCrsRuleModalHeader]: 'Edit Post-CRS Rule: {0}',
    [editCrsRuleModalHeader]: 'Edit CRS Rule: {0}',
    [wafGroupBreadcrumbTitle]: 'CRS Group',
    [wafRuleBreadcrumbTitle]: 'CRS Rule',
};

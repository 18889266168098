/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './service-engine-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name serviceEngineList
 * @description Component for Service Engine page.
 */
class ServiceEngineListController {
    constructor(
            CRUDGridConfig,
            $templateCache,
            ServiceEngineCollection,
            infraCloudState,
            getGridMetricFieldConfig,
            $window,
            l10nService,
    ) {
        this._CRUDGridConfig = CRUDGridConfig;
        this._$templateCache = $templateCache;
        this._ServiceEngineCollection = ServiceEngineCollection;
        this._infraCloudState = infraCloudState;
        this._getGridMetricFieldConfig = getGridMetricFieldConfig;
        this.$window_ = $window;
        this.l10nKeys = l10nKeys;
        this.l10nService = l10nService;

        l10nService.registerSourceBundles(dictionary);

        const rebootAction = {
            title: l10nService.getMessage(l10nKeys.rebootBtnLabel),
            className: 'icon-refresh',
            do(rows) {
                const cfm = $window.confirm(l10nService.getMessage(l10nKeys.rebootConfirmMessage));

                if (cfm) {
                    rows.forEach(se => se.reboot());
                }

                return cfm;
            },
        };

        const multipleActions = [
                {
                    title: l10nService.getMessage(l10nKeys.enableBtnLabel),
                    className: 'sel-enable-se icon-check',
                    disabled(rows) {
                        return !_.some(rows, se => {
                            return se.isEditable() && !se.isEnabled();
                        });
                    },
                    do(rows) {
                        _.each(rows, se => {
                            if (se.isEditable() && !se.isEnabled()) {
                                se.setEnableState(0);
                            }
                        });

                        return true;
                    },
                }, {
                    title: l10nService.getMessage(l10nKeys.disableBtnLabel),
                    className: 'sel-disable-se icon-minus',
                    disabled(rows) {
                        return !_.some(rows, se => {
                            return se.isEditable() &&
                                (se.isEnabled() || se.isDisabledForPlacement());
                        });
                    },
                    do(rows) {
                        _.each(rows, se => {
                            if (se.isEditable() &&
                                (se.isEnabled() || se.isDisabledForPlacement())
                            ) {
                                se.setEnableState(2);
                            }
                        });

                        return true;
                    },
                }, {
                    title: l10nService.getMessage(l10nKeys.disabelForVsPlacementBtnLabel),
                    className: 'sel-disable-se icon-minus',
                    disabled(rows) {
                        return !_.some(rows, se => {
                            return se.isEditable() &&
                                (se.isEnabled() || !se.isDisabledForPlacement());
                        });
                    },
                    do(rows) {
                        _.each(rows, se => {
                            if (se.isEditable() &&
                                (se.isEnabled() || !se.isDisabledForPlacement())
                            ) {
                                se.setEnableState(1);
                            }
                        });

                        return true;
                    },
                },
                rebootAction,
        ];

        this.multipleActions_ = {
            openShiftK8S: rebootAction,
            default: multipleActions,
        };
    }

    $onInit() {
        const cloudRef = this._infraCloudState.getCloudRef();
        const slug = cloudRef && cloudRef.slug();

        this.gridConfig = new this._CRUDGridConfig();

        this.gridConfig.props = { l10nKeys };

        this.setMultipleActions_();

        this.gridConfig.collection = new this._ServiceEngineCollection({
            params: {
                'cloud_ref.uuid': slug,
            },
        });

        const { objectName } = this.gridConfig.collection;

        this.gridConfig.id = `${objectName}-list-page`;

        const gridMetricFields = [
                'se_stats.max_num_vs',
                'se_if.avg_bandwidth',
                'se_stats.avg_cpu_usage',
                'se_stats.avg_mem_usage',
                'se_stats.avg_disk1_usage',
                'se_stats.avg_connections',
                'se_stats.pct_connections_dropped',
                'se_stats.avg_packet_buffer_usage',
                'se_stats.avg_persistent_table_usage',
                'se_stats.avg_ssl_session_cache_usage',
                'se_stats.pct_syn_cache_usage',
                'se_if.avg_rx_pkts',
                'se_if.avg_rx_bytes',
                'se_stats.avg_rx_pkts_dropped',
                'se_stats.avg_rx_bytes_dropped',
                'se_if.avg_tx_bytes',
                'se_if.avg_tx_pkts',
                'se_stats.avg_connection_mem_usage',
                'se_stats.avg_dynamic_mem_usage',
        ];

        this.gridConfig.fields = [{
            name: 'name',
            title: this.l10nService.getMessage(l10nKeys.columnTitleName),
            template: '<a class="sel-se-name" ui-sref="^.^.cloud.serviceengine-detail.' +
                    'summary({seId: \'{{row.id}}\', cloudId: \'' +
                    '{{row.data.config.cloud_ref.slug()}}\'})">' +
                    '{{row.getName()}}</a>',
            sortBy: 'name',
            visibility: 'm',
        }, {
            require: 'health, runtime',
            name: 'health',
            title: this.l10nService.getMessage(l10nKeys.columnTitleHealth),
            template:
                    '<avi-healthscore item="row" stop-async-on-hide="true"></avi-healthscore>',
            sortBy: 'health_score',
            visibility: 'm',
        }, {
            name: 'enable_state',
            title: this.l10nService.getMessage(l10nKeys.columnTitleEnableState),
            template: '{{row.getConfig().enable_state.enumeration("SE_STATE_")}}',
            sortBy: 'enable_state',
        }, {
            require: 'runtime',
            name: 'status',
            title: this.l10nService.getMessage(l10nKeys.columnTitleStatus),
            template: '{{row.getRuntimeData().oper_status.state | unitStateLabel}}',
            visibility: 'd',
        }, {
            require: 'runtime',
            name: 'uptime',
            title: this.l10nService.getMessage(l10nKeys.columnTitleUptime),
            template: '{{row.getRuntimeData().oper_status.last_changed_time.secs | uptime}}',
        }, {
            name: 'segroup',
            title: this.l10nService.getMessage(l10nKeys.columnTitleSeGroup),
            template: '{{ row.getConfig().se_group_ref | name }}',
            sortBy: 'se_group_ref__name',
        }, {
            name: 'mgmt-ip',
            title: this.l10nService.getMessage(l10nKeys.columnTitleManagementIp),
            template: '{{ row.getConfig().mgmt_ip_address.addr }}',
        }, {
            name: 'num_vs',
            title: this.l10nService.getMessage(l10nKeys.columnTitleVirtualServices),
            template: '{{ row.getConfig().vs_per_se_refs.length }}',
        }, {
            name: 'availability_zone',
            title: this.l10nService.getMessage(l10nKeys.columnTitleAvailabilityZone),
            transform: row => {
                return row.getConfig().availability_zone ? row.getConfig().availability_zone :
                    this.l10nService.getMessage(l10nKeys.naLabel);
            },
        }, {
            name: 'host_ref',
            title: this.l10nService.getMessage(l10nKeys.columnTitleHost),
            transform: row => {
                return row.getConfig().host_ref.name() ? row.getConfig().host_ref.name() :
                    this.l10nService.getMessage(l10nKeys.naLabel);
            },
        }, {
            require: 'alert',
            name: 'alerts',
            title: this.l10nService.getMessage(l10nKeys.columnTitleAlerts),
            template: '<item-alert-bell item="row"></item-alert-bell>',
        }, {
            name: 'version',
            title: this.l10nService.getMessage(l10nKeys.columnTitleVersion),
            template: '{{ row.getRuntimeData().version }}',
        }];

        const { fields } = this.gridConfig;

        //let's add metric series fields
        fields.push(...gridMetricFields.map(this._getGridMetricFieldConfig));

        this.gridConfig.singleactions.push({
            title: this.l10nService.getMessage(l10nKeys.deleteBtnLabel),
            class: 'icon-trash',
            hidden(row) {
                return !row.isDroppable();
            },
            do: row => this.deleteServiceEngine(row),
        });

        //disable when remove & enable/disable SE are both unavailable
        this.gridConfig.checkboxDisable = row => !(row.isDroppable() || row.isEditable());

        this.gridConfig.expandedContainerTemplate = require(
            './service-engine-list-expander.partial.html',
        );

        this.gridConfig.expanderDisabled =
                row => !row.getConfig() || !('virtualservice_refs' in row.getConfig());

        this.gridConfig.layout = {
            includeTimeframeSelector: true,
            includeMetricsValueSelector: true,
        };

        this.handleCloudChange = this.handleCloudChange.bind(this);
        this._infraCloudState.on('cloudChange', this.handleCloudChange);
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
        this._infraCloudState.unbind('cloudChange', this.handleCloudChange);
    }

    /**
     * Triggers delete call for a SE. incase of failed deletion,
     * Gives an option to try force delete.
     * @param {module:avi/serviceengine.ServiceEngine} serviceEngine
     */
    deleteServiceEngine(serviceEngine) {
        const { collection } = this.gridConfig;

        collection.dropItems(serviceEngine, false, false, null, false)
            .catch(({ data }) => {
                const { error } = data;

                if (error) {
                    if (this.$window_.confirm(
                        this.l10nService.getMessage(l10nKeys.tryForceDeleteConfirmMessage,
                            [error]),
                    )) {
                        collection.dropItems(serviceEngine, false, true);
                    }
                }
            });
    }

    /**
     * Handler for the 'cloudChange' event. Sets the Service Engine collection based on the
     * cloud.
     */
    handleCloudChange() {
        const cloudRef = this._infraCloudState.getCloudRef();
        const slug = cloudRef && cloudRef.slug();
        const { collection } = this.gridConfig;

        this.setMultipleActions_();

        collection.setParams({
            'cloud_ref.uuid': slug,
        });

        collection.setDefaultItemConfigProps({
            cloud_ref: slug,
        });

        collection.load();
    }

    /**
     * Sets the multiple action options based on the cloud type selected.
     * For cloud_type "CLOUD_OSHIFT_K8S", display only Delete, Reboot options
     * For other clouds display Delete, Disable, Enable, Disable vs placement and
     * Reboot action buttons.
     * @protected
     */
    setMultipleActions_() {
        const { multipleactions } = this._CRUDGridConfig();

        multipleactions.push(...this.multipleActions_.default);

        this.gridConfig.multipleactions = multipleactions;
    }
}

ServiceEngineListController.$inject = [
    'CRUDGridConfig',
    '$templateCache',
    'ServiceEngineCollection',
    'infraCloudState',
    'getGridMetricFieldConfig',
    '$window',
    'l10nService',
];

angular.module('aviApp').component('serviceEngineList', {
    controller: ServiceEngineListController,
    template:
        `<div class="infrastructure-detail">
            <collection-grid
                config="$ctrl.gridConfig"
                class="sel-service-engine-list service-engine-list"
            ></collection-grid>
        </div>`,
});

const moduleName = 'avi/accounts';
const componentName = 'user-modal-tenant-settings';
const prefix = `${moduleName}.${componentName}`;

export const tenantSettingsHeader = `${prefix}.tenantSettingsHeader`;
export const individualTenantsTableHeader = `${prefix}.individualTenantsTableHeader`;
export const selectTenantLabel = `${prefix}.selectTenantLabel`;
export const selectTenantPlaceholder = `${prefix}.selectTenantPlaceholder`;
export const selectRoleLabel = `${prefix}.selectRoleLabel`;
export const selectRolePlaceholder = `${prefix}.selectRolePlaceholder`;
export const addTenantLabel = `${prefix}.addTenantLabel`;
export const noTenantAddedLabel = `${prefix}.noTenantAddedLabel`;
export const defaultTenantLabel = `${prefix}.defaultTenantLabel`;

export const ENGLISH = {
    [tenantSettingsHeader]: 'Tenant & Role',
    [individualTenantsTableHeader]: 'Individual Tenants and Role',
    [selectTenantLabel]: 'Tenant',
    [selectTenantPlaceholder]: 'Select Tenant',
    [selectRoleLabel]: 'Role',
    [selectRolePlaceholder]: 'Select Role',
    [addTenantLabel]: 'Add Tenant',
    [noTenantAddedLabel]: 'No tenants have been added.',
    [defaultTenantLabel]: 'Default Tenant',
};

/** @module WafModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { ObjectTypeItem } from 'ajs/modules/data-model';

export const WAF_CRS_ITEM_TOKEN = 'WafCrs';

/**
 * @description WafCrs ObjectTypeItem
 * @author alextsg
 */
export class WafCrs extends ObjectTypeItem {
    public static ajsDependencies = [
        'Auth',
    ];

    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'wafcrs',
            objectType: 'WafCRS',
        };

        super(extendedArgs);
    }

    /** @override */
    public isDroppable(): boolean {
        const Auth = this.getAjsDependency_('Auth');

        return Auth.isAllowed('wafpolicy', 'w');
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'analytics-http-resp-codes';
const prefix = `${moduleName}.${componentName}`;

export const excludeHttpStatusCodesFromErrorClassificationInputLabel = `${prefix}.excludeHttpStatusCodesFromErrorClassificationInputLabel`;

export const ENGLISH = {
    [excludeHttpStatusCodesFromErrorClassificationInputLabel]: 'Exclude HTTP Status codes from Error Classification',
};

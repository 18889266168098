const moduleName = 'ajs-legacy';
const componentName = 'control-scripts-controller';
const prefix = `${moduleName}.${componentName}`;

export const editControlScriptModalHeader = `${prefix}.editControlScriptModalHeader`;
export const newControlScriptModalHeader = `${prefix}.newControlScriptModalHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const controlScriptInputLabel = `${prefix}.controlScriptInputLabel`;
export const controlScriptInputPlaceholder = `${prefix}.controlScriptInputPlaceholder`;
export const controlScriptInputHelpText = `${prefix}.controlScriptInputHelpText`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;

export const ENGLISH = {
    [editControlScriptModalHeader]: 'Edit ControlScript: {0}',
    [newControlScriptModalHeader]: 'New ControlScript: {0}',
    [nameInputLabel]: 'Name',
    [controlScriptInputLabel]: 'ControlScript',
    [controlScriptInputPlaceholder]: 'User Defined Alert Action Script. Please refer to kb.avinetworks.com for more information.',
    [controlScriptInputHelpText]: 'Enter your ControlScript Here',
    [cancelButtonLabel]: 'Cancel',
    [saveButtonLabel]: 'Save',
};

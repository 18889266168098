const moduleName = 'ajs-legacy';
const componentName = 'authentication-controller';
const prefix = `${moduleName}.${componentName}`;

export const columntTitleAuthorization = `${prefix}.columntTitleAuthorization`;
export const columntTitleAssignment = `${prefix}.columntTitleAssignment`;
export const actionBtnEdit = `${prefix}.actionBtnEdit`;
export const actionBtnDelete = `${prefix}.actionBtnDelete`;
export const memberOfPrefixLabel = `${prefix}.memberOfPrefixLabel`;
export const notMemberOfPrefixLabel = `${prefix}.notMemberOfPrefixLabel`;
export const localAuthType = `${prefix}.localAuthType`;
export const remoteAuthType = `${prefix}.remoteAuthType`;
export const authenticationAuthorizationTypeLabel = `${prefix}.authenticationAuthorizationTypeLabel`;
export const profileLabel = `${prefix}.profileLabel`;
export const tenantAndRoleMappingLabel = `${prefix}.tenantAndRoleMappingLabel`;
export const newMappingButtonLabel = `${prefix}.newMappingButtonLabel`;
export const superUserHeader = `${prefix}.superUserHeader`;
export const tenantHeader = `${prefix}.tenantHeader`;
export const emptyDataLabel = `${prefix}.emptyDataLabel`;
export const roleHeader = `${prefix}.roleHeader`;
export const groupHeader = `${prefix}.groupHeader`;
export const anyDataLabel = `${prefix}.anyDataLabel`;
export const attributeHeader = `${prefix}.attributeHeader`;

export const ENGLISH = {
    [columntTitleAuthorization]: 'Authorization',
    [columntTitleAssignment]: 'Assignment',
    [actionBtnEdit]: 'Edit',
    [actionBtnDelete]: 'Delete',
    [memberOfPrefixLabel]: 'Member of ',
    [notMemberOfPrefixLabel]: 'Not a member of ',
    [localAuthType]: 'Local',
    [remoteAuthType]: 'Remote',
    [authenticationAuthorizationTypeLabel]: 'Authentication/Authorization: <strong>{0}</strong>',
    [profileLabel]: 'Profile: <strong>{0}</strong>',
    [tenantAndRoleMappingLabel]: 'Tenant and Role Mapping',
    [newMappingButtonLabel]: 'New Mapping',
    [superUserHeader]: 'Super User',
    [tenantHeader]: 'Tenant',
    [emptyDataLabel]: 'None',
    [roleHeader]: 'Role',
    [groupHeader]: 'Group',
    [anyDataLabel]: 'Any',
    [attributeHeader]: 'Attribute',
};

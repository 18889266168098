/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './hsm-group-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name hsmGroupModal
 * @description
 *
 *     Modal component of HSMGroup.
 */
class HsmGroupModal {
    constructor(
        Regex,
        schemaService,
        l10nService,
    ) {
        this.Regex = Regex;
        this.clusterCertDescription = schemaService
            .getFieldDescription('HSMAwsCloudHsm', 'cluster_cert');

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this.handleTypeChange(true);
    }

    /**
     * Calls hsmGroup.onTypeChange and loads SAFENET LUNA nodes when applicable.
     * @param {boolean?} initial
     * @public
     */
    handleTypeChange(initial) {
        const { editable: hsmGroup } = this;

        if (!hsmGroup.id && hsmGroup.getType() === 'SAFENET_LUNA') {
            hsmGroup.populateSLunaNodesList();
        }

        if (!initial) {
            hsmGroup.onTypeChange();
        }
    }
}

HsmGroupModal.$inject = [
    'Regex',
    'schemaService',
    'l10nService',
];

angular.module('aviApp').component('hsmGroupModal', {
    controller: HsmGroupModal,
    bindings: {
        editable: '=',
        closeModal: '&',
    },
    templateUrl: 'src/components/modals/hsm-group-modal/hsm-group-modal.html',
});

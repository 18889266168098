/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'dns-app-profile-dns-zones';
const prefix = `${moduleName}.${componentName}`;

export const domainNameInputLabel = `${prefix}.domainNameInputLabel`;
export const nameServerInputLabel = `${prefix}.nameServerInputLabel`;
export const adminEmailInputLabel = `${prefix}.adminEmailInputLabel`;
export const adminEmailInputPlaceholder = `${prefix}.adminEmailInputPlaceholder`;
export const addDomainButtonLabel = `${prefix}.addDomainButtonLabel`;
export const noAddedDomainsMessage = `${prefix}.noAddedDomainsMessage`;

export const ENGLISH = {
    [domainNameInputLabel]: 'Domain Name',
    [nameServerInputLabel]: 'Name Server',
    [adminEmailInputLabel]: 'Admin Email',
    [adminEmailInputPlaceholder]: 'hostmaster',
    [addDomainButtonLabel]: 'Add Domain',
    [noAddedDomainsMessage]: 'No domains have been added.',
};

const moduleName = 'shared';
const componentName = 'full-modal-config-confirmation';
const prefix = `${moduleName}.${componentName}`;

export const confirmationMessage = `${prefix}.confirmationMessage`;
export const cancelBtnLabel = `${prefix}.cancelBtnLabel`;
export const discardChangesBtnLabel = `${prefix}.discardChangesBtnLabel`;

export const ENGLISH = {
    [confirmationMessage]: 'Recent changes have been made. Are you sure you want to discard?',
    [cancelBtnLabel]: 'Cancel',
    [discardChangesBtnLabel]: 'Yes, discard changes',
};

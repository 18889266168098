/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * Module for AVI Navigation related components.
 * Includes Header, Category-navbar, tenant-selector,
 * Controllersite-selector, user-menu and user-card components.
 * @module avi/navigation
 */

import './avi-header.less';

/**
 * @constructor
 * @memberOf module:avi/navigation
 * @see {@link module:avi/navigation.aviHeaderComponent aviHeaderComponent}
 */
class AviHeaderController {
    constructor(Auth) {
        /**
         * @type {module:avi/core.Auth}
         * @protected
         */
        this.auth_ = Auth;

        /**
         * Set to true if user has access to controllersites.
         * @type {boolean}
         */
        this.showControllerSiteSelector = false;
    }

    /** @override */
    $onInit() {
        this.showControllerSiteSelector = this.auth_.isAllowed('controllersite');
    }
}

AviHeaderController.$inject = [
    'Auth',
];

/**
 * @name aviHeaderComponent
 * @memberOf module:avi/navigation
 * @description Component for the main application header.
 * @property {module:avi/navigation.AviHeaderController} controller
 * @author alextsg, Aravindh Nagarajan
 */
angular.module('avi/navigation').component('aviHeader', {
    controller: AviHeaderController,
    templateUrl: 'src/components/avi-header/avi-header.html',
});

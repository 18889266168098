/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './sensitive-log-profile.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name sensitiveLogProfile
 * @description
 *     Component for displaying the Sensitive Log Profile under Analytics Profile.
 * @param {sensitiveLogProfileConfig} profile - Sensitive Log Profile ConfigItem.
 */
class SensitiveLogProfileController {
    constructor(
        PolicyGridConfig,
        $templateCache,
        AviConfirmService,
        l10nService,
    ) {
        this.PolicyGridConfig_ = PolicyGridConfig;
        this.$templateCache_ = $templateCache;
        this.AviConfirmService_ = AviConfirmService;

        /**
        * Get keys from source bundles for template usage
        */
        this.l10nKeys = l10nKeys;
        this.l10nService_ = l10nService;
        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        const { l10nService_: l10nService } = this;

        this.sensitiveLogProfileGridConfig = new this.PolicyGridConfig_({
            collection: this.profile,
            getRowId: row => row.getIndex(),
            fields: [{
                title: l10nService.getMessage(l10nKeys.indexColumnTitle),
                name: 'index',
                template: '{{ row.getIndex() }}',
            }, {
                title: l10nService.getMessage(l10nKeys.enableColumnTitle),
                name: 'enable',
                template: '<checkbox ng-model="row.getConfig().enabled"></checkbox>',
            }, {
                title: l10nService.getMessage(l10nKeys.nameColumnTitle),
                name: 'name',
                template: '<span class="hide-text-overflow">{{ row.getName() }}</span>',
                allowResize: true,
            }, {
                title: l10nService.getMessage(l10nKeys.matchingRuleColumnTitle),
                name: 'match',
                template:
                        `<policy-grid-match-column
                            rule="row">
                        </policy-grid-match-column>`,
                allowResize: true,
            }, {
                title: l10nService.getMessage(l10nKeys.actionColumnTitle),
                name: 'action',
                template:
                        `<policy-grid-action-column
                            rule="row">
                        </policy-grid-action-column>`,
            }],
            controls: {
                create: {
                    title: l10nService.getMessage(l10nKeys.createRuleControlTitle),
                    do: () => this.profile.createRule(),
                },
            },
            actions: {
                createAt: ({ index }, position) => this.profile.createRule({
                    index,
                    position,
                }),
                search: (row, searchTerm) => row.searchBy(searchTerm),
            },
            singleactions: [{
                title: l10nService.getMessage(l10nKeys.deleteActionTitle),
                class: 'icon icon-trash',
                do: rule => this.profile.delete(rule),
            }, {
                title: l10nService.getMessage(l10nKeys.menuActionTitle),
                template: require(
                    '../../../../../components/applications/virtualservice/policy/' +
                        'policy-grid/policy-grid-menu.tooltip.partial.html',
                ),
                edit: rule => this.profile.editRule(rule),
                move: rule => {
                    const { rules } = this.profile;

                    this.AviConfirmService_
                        .prompt('policy-grid-prompt-index', {
                            rule,
                            rules,
                        })
                        .then(data => this.profile.moveRule(rule, data));
                },
                duplicate: rule => {
                    this.AviConfirmService_
                        .prompt('policy-grid-prompt-index', { rules: this.profile.rules })
                        .then(data => this.profile.duplicateRule(rule, data));
                },
            }],
            expandTemplate:
                    `<policy-grid-expander
                        row="row"
                        config="config">
                    </policy-grid-expander>`,
        });
    }
}

SensitiveLogProfileController.$inject = [
        'PolicyGridConfig',
        '$templateCache',
        'AviConfirmService',
        'l10nService',
];

angular.module('aviApp').component('sensitiveLogProfile', {
    bindings: {
        profile: '<',
    },
    controller: SensitiveLogProfileController,
    templateUrl: 'src/components/templates/profiles/analytics/sensitive-log-profile/' +
            'sensitive-log-profile.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function poolServerFromHSScatterPlotMetricInventoryCollDataSourceFactory(
    InventoryCollDataSource,
    Server,
) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.InventoryCollDataSource
     * @author Alex Malitsky, Ashish Verma
     * @desc
     *
     *     DataSource to get Pool Servers inventory date from {@link PoolServersHsScatterMetric}.
     *     NoAPI calls, has ability to filter all servers with a white list of allowed ones.
     */
    class PoolServerFromHSScatterPlotMetricInventoryCollDataSource extends InventoryCollDataSource {
        constructor(args) {
            super(args);
            /**
             * Hash of Server ids allowed to be present in a inventory list.
             * Keys are server ids, value is always `true`.
             * @type {Object.<string, boolean>}
             * @protected
             */
            this.serverWhiteListHash_ = {};
        }

        /**
         * When working with HS ScatterPlot as a source of server inventory list we need ability to
         * filter all servers by brush selection on the chart. This method sets a list of Servers
         * which can be present in list. Called by corresponding Collection instance.
         * @param {Array.<Server#id>=} serverIds
         * @public
         */
        setServerWhiteList(serverIds) {
            if (Array.isArray(serverIds)) {
                this.serverWhiteListHash_ = _.reduce(serverIds, function(hash, serverId) {
                    hash[serverId] = true;

                    return hash;
                }, {});
            } else {
                this.serverWhiteListHash_ = {};
            }
        }

        /**
         * Filters an inventory list of all servers provided by {@link PoolServersHsScatterMetric}
         * with a white list which is supposed to be set through brush selection on the
         * corresponding {@link scatterPlot | Pool Servers HealthScore ScatterPlot chart}.
         * @protected
         */
        getFilteredServerInventoryList_() {
            const allServers = this.owner_.HSScatterPlotMetric.getServersInventoryList();

            return _.filter(allServers, function(serverData) {
                return Server.getServerUuid(serverData.config) in this.serverWhiteListHash_;
            }, this);
        }

        /**
         * Request actually provides all results with expected format since we are using
         * {@link PoolServersHsScatterMetric} as a data source and no API call is needed.
         * @override
         */
        getRequestParams_() {
            const
                poolId = this.owner_.id,
                results = this.getFilteredServerInventoryList_();

            results.forEach(function(serverData) {
                serverData.config.poolId = poolId;
                serverData.config.uuid = Server.getServerUuid(serverData.config);
            });

            return {
                data: {
                    results,
                    count: results.length,
                },
            };
        }
    }

    const defaultParams = {};

    Object.assign(PoolServerFromHSScatterPlotMetricInventoryCollDataSource.prototype, {
        hasSearch: false,
        hasSorting: false,
        hasPagination: false,
        defaultParams_: defaultParams,
    });

    return PoolServerFromHSScatterPlotMetricInventoryCollDataSource;
}

poolServerFromHSScatterPlotMetricInventoryCollDataSourceFactory.$inject = [
    'ListCollDataSource',
    'Server',
];

angular.module('avi/app').factory(
    'PoolServerFromHSScatterPlotMetricInventoryCollDataSource',
    poolServerFromHSScatterPlotMetricInventoryCollDataSourceFactory,
);

/**
 * @module SystemModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    IEmailConfiguration,
    SMTPType,
} from 'generated-types';
import {
    Component,
    Type,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { EmailSmtpModalComponent } from 'ng/modules/system';
import { IMessageItemData, MessageItem } from 'ajs/modules/data-model';
import { withFullModalMixin } from 'ajs/utils/mixins';
import * as l10n from '../system.l10n';

interface IEmailConfigurationData extends IMessageItemData {
    config: IEmailConfiguration;
}

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export const EMAIL_CONFIGURATION_CONFIG_ITEM_TOKEN = 'EmailConfigurationConfigItem';

export class EmailConfigurationConfigItem extends withFullModalMixin(MessageItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    public data: IEmailConfigurationData;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    protected readonly objectType = 'EmailConfiguration';

    protected readonly windowElement = EmailSmtpModalComponent as Type<Component>;

    private l10nService: L10nService;

    constructor(args = {}) {
        super(args);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.emailConfigurationModalBreadcrumbTitle);
    }

    /**
     * Returns the configured SMTP type.
     */
    public get smtpType(): SMTPType {
        return this.config.smtp_type;
    }

    /**
     * Returns true if the from_email field is defined.
     */
    public hasFromEmail(): boolean {
        return (this.smtpType === SMTPType.SMTP_LOCAL_HOST ||
            this.smtpType === SMTPType.SMTP_ANONYMOUS_SERVER) && Boolean(this.config.from_email);
    }
}

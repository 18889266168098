/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './pool-group-deployment-policy-modal.l10n';
import './pool-group-deployment-policy-modal.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  poolGroupDeploymentPolicyModal
 * @description
 *     Component for Pool Group Deployment Policy modal.
 * @param  {PoolGroupDeploymentPolicy} editable
 * @param  {Function} closeModal - Closes modal.
 */
class PoolGroupDeploymentPolicyModalController {
    constructor(
        Regex,
        AlertMetricsCollection,
        schemaService,
        l10nService,
    ) {
        this.Regex = Regex;
        this.rulesConfig = {
            fields: [{
                name: 'metric_id',
                title: 'Metric ID',
                required: true,
            }, {
                name: 'operator',
                title: 'Operator',
            }, {
                name: 'threshold',
                title: 'Threshold',
            }],
        };
        this.metricsCollection = new AlertMetricsCollection();
        this.comparisonOperatorEnumValues = schemaService.getEnumValues('ComparisonOperator');

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    addRuleHandler() {
        this.editable.addRule();
    }

    removeRuleHandler(index) {
        this.editable.removeRule(index);
    }

    /**
     * Handles Metric ID dropdown change.
     * @param {Object} rule
     */
    metricChangeHandler(rule) {
        if (rule && typeof rule.metric_id === 'string') {
            rule.metric_id = rule.metric_id.slug();
        }
    }
}

PoolGroupDeploymentPolicyModalController.$inject = [
        'Regex',
        'AlertMetricsCollection',
        'schemaService',
        'l10nService',
];

angular.module('aviApp').component('poolGroupDeploymentPolicyModal', {
    controller: PoolGroupDeploymentPolicyModalController,
    bindings: {
        editable: '<',
        closeModal: '&',
    },
    templateUrl: 'src/components/templates/autoscale/pool-group-deployment/' +
            'pool-group-deployment-policy-modal/pool-group-deployment-policy-modal.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'virtualservice-vip-address';
const prefix = `${moduleName}.${componentName}`;

export const switchToBasicButtonLabel = `${prefix}.switchToBasicButtonLabel`;
export const switchToAdvancedButtonLabel = `${prefix}.switchToAdvancedButtonLabel`;
export const vipAddressSharedAcrossVirtualServicesMessage = `${prefix}.vipAddressSharedAcrossVirtualServicesMessage`;
export const sharedWithVirtualServicesLabel = `${prefix}.sharedWithVirtualServicesLabel`;
export const vipIpAddressInputLabel = `${prefix}.vipIpAddressInputLabel`;
export const enableOrDisableVipAddressButtonLabel = `${prefix}.enableOrDisableVipAddressButtonLabel`;
export const removeDomainNameButtonLabel = `${prefix}.removeDomainNameButtonLabel`;
export const addDomainNameButtonLabel = `${prefix}.addDomainNameButtonLabel`;
export const tier1LogicalRouterInputLabel = `${prefix}.tier1LogicalRouterInputLabel`;
export const tier1LogicalRouterInputPlaceholder = `${prefix}.tier1LogicalRouterInputPlaceholder`;

export const ENGLISH = {
    [switchToBasicButtonLabel]: 'Switch to Basic',
    [switchToAdvancedButtonLabel]: 'Switch to Advanced',
    [vipAddressSharedAcrossVirtualServicesMessage]: 'VIP Address is shared across these Virtual Services.',
    [sharedWithVirtualServicesLabel]: 'Shared with Virtual Services:',
    [vipIpAddressInputLabel]: 'VIP Address',
    [enableOrDisableVipAddressButtonLabel]: 'Click to {0} this VIP Address',
    [removeDomainNameButtonLabel]: 'Remove Domain Name',
    [addDomainNameButtonLabel]: '+ Add Domain Name',
    [tier1LogicalRouterInputLabel]: 'Tier1 Logical Router',
    [tier1LogicalRouterInputPlaceholder]: 'Select Tier1 Logical Router',
};

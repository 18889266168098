/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    isNull,
    isUndefined,
} from 'underscore';

/**
 * Given a value, check for null, empty array, and empty string values and return undefined instead.
 */
export const normalizeValue = (value: any): any | undefined => {
    if (!isUndefined(value)) {
        if (isNull(value) || Array.isArray(value) && !value.length || value === '') {
            return undefined;
        }
    }

    return value;
};

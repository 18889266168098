/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { isUndefined } from 'underscore';
import { IRoleFilterMatchLabel } from 'generated-types';

/**
 * @description The counterpart of RbacLabelGrid component used in Ajs.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'rbac-label-grid-ajs-wrapper',
    templateUrl: './rbac-label-grid-ajs-wrapper.component.html',
})
export class RbacLabelGridAjsWrapperComponent {
    /**
     * List of RBAC label key-value objects.
     */
    @Input()
    public rbacLabelList: IRoleFilterMatchLabel[];

    /**
     * Event emitter to propagate RBAC list changes to the parent.
     */
    @Output()
    public rbacLabelListChange = new EventEmitter<IRoleFilterMatchLabel[]>();

    public ngOnInit(): void {
        // force the data model to handle list creation
        if (isUndefined(this.rbacLabelList)) {
            throw new Error('The list must be defined to use RBAC label grid!');
        }
    }

    /**
     * Getter for RBAC label list.
     */
    public get list(): IRoleFilterMatchLabel[] {
        return this.rbacLabelList;
    }

    /**
     * Setter for RBAC label list. In the template whenever the bound Angular [(ngModel)] list gets
     * updated from UI, this setter gets called and it in turn calls the event emitter propagating
     * the update to the RBAC label list passing from an AngularJS parent.
     */
    public set list(newList: IRoleFilterMatchLabel[]) {
        this.rbacLabelListChange.emit(newList);
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function certificateManagementFactory(Item, defaultValues, stringService) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Item
     * @author Ram Pal
     * @desc CertificateManagement item
     */
    class CertificateManagement extends Item {
        /**
         * Used to populate sensitiveHash so we know which params had a previously
         * configured sensitive value, so that we can show a '********' placeholder.
         */
        setSensitiveHash() {
            const data = this.data.config;
            const self = this;

            data.script_params.forEach(function(params, index) {
                if (params.is_sensitive) {
                    self.sensitiveHash[index] = true;
                }
            });
        }

        /**
         * @override
         */
        beforeEdit() {
            const data = this.data.config;

            if (data.script_params && data.script_params.length) {
                this.enableScriptParams = true;
                this.setSensitiveHash();
            } else {
                data.script_params = [defaultValues.getDefaultItemConfigByType('customparams')];
            }
        }

        /**
         * @override
         */
        dataToSave() {
            const data = angular.copy(this.data.config);

            if (!this.enableScriptParams) {
                data.script_params = undefined;
            }

            return data;
        }

        /**
         * used to find if item has script params.
         * @return {boolean}
         */
        get hasScriptParams() {
            return !!this.scriptParams?.length;
        }

        /**
         * used to get name of control script.
         * @return {string}
         */
        get controlScriptName() {
            return stringService.name(this.getConfig().run_script_ref);
        }

        /**
         * used to get script params.
         * @return {CustomParams|undefined}
         */
        get scriptParams() {
            return this.getConfig().script_params;
        }
    }

    Object.assign(CertificateManagement.prototype, {
        objectName: 'certificatemanagementprofile',
        windowElement: 'prof-certificate-management-create',
        sensitiveHash: {},
    });

    return CertificateManagement;
}

certificateManagementFactory.$inject = [
    'Item',
    'defaultValues',
    'stringService',
];

angular.module('avi/app')
    .factory('CertificateManagement', certificateManagementFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './sip-profile.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class SipProfileController {
    constructor(Regex, l10nService) {
        this.Regex = Regex;

        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }
}

SipProfileController.$inject = [
    'Regex',
    'l10nService',
];

/**
 * @ngdoc component
 * @name sipProfile
 * @description
 *    Responsible for rendering a html with two fields: sip_log_depth
 *    and exclude_error_status_codes, input fields validation
 * @param {AnalyticsProfile.config} config
 * @param {Function} handleSipErrCodes - called on blur of exclude_sip_error_codes
 *
 */

angular.module('aviApp').component('sipProfile', {
    bindings: {
        config: '<',
    },
    controller: SipProfileController,
    templateUrl: 'src/components/forms/sip-profile/sip-profile.html',
});

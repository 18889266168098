const moduleName = 'ajs-legacy';
const componentName = 'persistence-profile-list-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const actionBtnEdit = `${prefix}.actionBtnEdit`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleType]: 'Type',
    [actionBtnEdit]: 'Edit',
};

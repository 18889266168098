/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function alertActionCollectionFactory(Collection, AlertAction) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Collection
     * @author Ram Pal
     * @desc Collection of {@link module:avi/app.AlertAction AlertActions}.
     */
    class AlertActionCollection extends Collection {}

    const allDataSources = {
        list: {
            source: 'ListCollDataSource',
            transformer: 'ListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    Object.assign(AlertActionCollection.prototype, {
        objectName_: 'actiongroupconfig',
        itemClass_: AlertAction,
        windowElement_: 'adm-alertaction-create',
        defaultDataSources_: 'list',
        allDataSources_: allDataSources,
    });

    return AlertActionCollection;
}

alertActionCollectionFactory.$inject = [
    'Collection',
    'AlertAction',
];

angular.module('avi/app')
    .factory('AlertActionCollection', alertActionCollectionFactory);

/**
 * Module containing network related components.
 * @preferred
 * @module NetworkModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { VIPModule } from '@vmw/ngx-vip';

import {
    ConfiguredNetworkSubnetExpanderComponent,
    NetworkListExpanderComponent,
} from '.';

const networkComponents = [
    NetworkListExpanderComponent,
    ConfiguredNetworkSubnetExpanderComponent,
];

@NgModule({
    declarations: [
        ...networkComponents,
    ],
    imports: [
        FormsModule,
        CommonModule,
        VIPModule,
    ],
    exports: [
        ...networkComponents,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class NetworkModule { }

/**
 * @module LicensingModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import './license-tier-switch-modal.component.less';

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { LicenseTierType } from 'generated-types';
import {
    AviConfirmService,
    IAviAjsConfirmBindings,
    SchemaService,
} from 'ajs/modules/core';
import { SystemConfig } from 'ajs/modules/system';
import { LicensingService } from 'ajs/modules/licensing';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './license-tier-switch-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface ILicenseTierUsageData {
    total: number;
    used: number;
}

interface ILicenseTiersUsageData {
    [LicenseTierType.ENTERPRISE]: ILicenseTierUsageData;
    [LicenseTierType.BASIC]: ILicenseTierUsageData;
    [LicenseTierType.ESSENTIALS]?: ILicenseTierUsageData;
}
enum AlertType {
    NONE = 'none',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'danger',
}

interface IAlertInfo {
    status: AlertType;
    message: string;
}

/**
 * @description
 *
 *     License tier switch modal.
 *
 * @author Zhiqian Liu
 */
@Component({
    selector: 'license-tier-switch-modal',
    templateUrl: './license-tier-switch-modal.component.html',
})
export class LicenseTierSwitchModalComponent implements OnInit {
    /**
     * SystemConfig editable object to change licensing tier on.
     */
    @Input()
    public editable: SystemConfig;

    /**
     * Usage data of license tiers, containing used and total service cores of each tier type.
     */
    @Input()
    public licenseTiersUsageData: ILicenseTiersUsageData;

    /**
     * Fire when save succeeds.
     */
    @Output()
    public onSaveSuccess = new EventEmitter<void>();

    /**
     * List of all LicenseTierType enum values.
     */
    public licensetTierTypeList = [
        LicenseTierType.ENTERPRISE,
        LicenseTierType.BASIC,
        LicenseTierType.ESSENTIALS,
    ];

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Getter for the selected license tier.
     */
    public get selectedTier(): LicenseTierType {
        return this.editable.defaultLicenseTier;
    }

    /**
     * Hold component busy state.
     */
    private busy = false;

    /**
     * Currently in-use license tier type.
     */
    private tierInUse: LicenseTierType;

    constructor(
        @Inject(AviConfirmService) private aviConfirmService: AviConfirmService,
        @Inject(LicensingService) private licensingService: LicensingService,
        @Inject(SchemaService) private schemaService: SchemaService,
        private l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.tierInUse =
            this.editable.getConfig().default_license_tier as LicenseTierType;
    }

    /**
     * Check busy state, decided by editable busy state and component busy state.
     */
    public isBusy(): boolean {
        return this.editable.isBusy() || this.busy;
    }

    /**
     * Called when the user clicks the "save" button.
     * 1. When clicked if the switch is from ENTERPRISE to any other tiers, a confirmation dialog
     *    will be brought up before trigerring saving procedure.
     * 2. When clicked if the switch is from other tiers the ENTERPRISE, the saving procedure will
     *    be automatically triggered without confirmations.
     */
    public handleSubmit(): void {
        const { l10nService, l10nKeys } = this;

        if (this.tierInUse === LicenseTierType.ENTERPRISE &&
            this.selectedTier !== LicenseTierType.ENTERPRISE
        ) {
            const { label } = this.schemaService.getEnumValue('LicenseTierType', this.selectedTier);
            const aviConfirmComponentBindings: IAviAjsConfirmBindings = {
                headerText: l10nService.getMessage(l10nKeys.switchLicenseHeader, [label]),
                message: l10nService.getMessage(l10nKeys.disableEnterpriseFeaturesMessage),
                isWarning: true,
                confirmationText: l10nService.getMessage(l10nKeys.isContinueMessage),
                confirmButtonText: l10nService.getMessage(l10nKeys.yesContinueBtnLabel),
            };

            this.aviConfirmService.confirm(aviConfirmComponentBindings)
                .then(this.confirmToSave);
        } else {
            this.confirmToSave();
        }
    }

    /**
     * Called when the user wants to cancel or to close the modal.
     */
    public dismiss(): void {
        this.editable.closeLicenseTierModal();
    }

    /**
     * trackBy function for tier selection cards.
     */
    public trackByTierType(index: number, tierType: LicenseTierType): LicenseTierType {
        return tierType;
    }

    /**
     * Get usage fraction string by tier type.
     */
    public getTierUsageFraction(tierType: LicenseTierType): string {
        const isInUse = this.isTierInUse(tierType);
        const { used, total } = this.licenseTiersUsageData[tierType];

        if (isInUse) {
            // only show used cores for the Essentials type
            if (tierType === LicenseTierType.ESSENTIALS) {
                return `${used}`;
            }

            // show the usage fraction for an active type
            return `${used}/${total}`;
        }

        // only show the total number of cores for an in-active type
        return `${total}`;
    }

    /**
     * By tier type, get the status value for the selection card cds alert directive.
     */
    public getTierSwitchAlertStatus(tierType: LicenseTierType): AlertType {
        return this.getTierSwitchAlertInfo(tierType).status;
    }

    /**
     * Get the alert message for a tier type select block.
     */
    public getTierSwitchAlertMsg(tierType: LicenseTierType): string {
        return this.getTierSwitchAlertInfo(tierType).message;
    }

    /**
     * Show the alert message only when the alert status is not NONE.
     */
    public showTierSwitchAlert(tierType: LicenseTierType): boolean {
        return this.getTierSwitchAlertStatus(tierType) !== AlertType.NONE;
    }

    /**
     * Decide if switching to a tier is allowed.
     */
    public isTierAvailable(tierType: LicenseTierType): boolean {
        // switching to Essentials is always available because it doesn't consume any service core
        if (tierType === LicenseTierType.ESSENTIALS) {
            return true;
        }

        const { used: currentlyUsedCores } = this.licenseTiersUsageData[this.tierInUse];
        const { total: allowedServiceCores } = this.licenseTiersUsageData[tierType];

        // tier won't be available if it doesn't have enough service cores to hold the number of
        // current ones in use
        if (currentlyUsedCores > allowedServiceCores) {
            return false;
        }

        return true;
    }

    /**
     * Set selected tier to the respect value when the selection card is clicked.
     */
    public selectTier(tierType: LicenseTierType): void {
        this.editable.defaultLicenseTier = tierType;
    }

    /**
     * Decide whether a tier is selected.
     */
    public isSelected(tierType: LicenseTierType): boolean {
        return tierType === this.selectedTier;
    }

    /**
     * Trigger saving for license tier type.
     */
    private confirmToSave = (): void => {
        this.editable.saveDefaultLicenseTierType()
            .then(() => {
                this.onSaveSuccess.emit();
                this.dismiss();
            });
    };

    /**
     * Decide if a tier type is the current one in use.
     */
    private isTierInUse(tierType: LicenseTierType): boolean {
        return tierType === this.tierInUse;
    }

    /**
     * Generate a hash containing alert status and alert message for a particular tier type.
     */
    private getTierSwitchAlertInfo(tierType: LicenseTierType): IAlertInfo {
        const { label: tierLabel } = this.schemaService.getEnumValue(
            'LicenseTierType',
            tierType,
        );

        const { l10nService, l10nKeys } = this;

        // alert info for use when no alert should be shown
        const noneStatusAlertInfo = {
            status: AlertType.NONE,
            message: '',
        };

        // no alert for a tier in use
        if (this.isTierInUse(tierType)) {
            return noneStatusAlertInfo;
        }

        // error alert when not available for selection
        if (!this.isTierAvailable(tierType)) {
            return {
                status: AlertType.ERROR,
                message: l10nService.getMessage(l10nKeys.insufficientLicensesMessage, [tierLabel]),
            };
        }

        // show info for Enterprise if other tier type is in use
        // (switching from any other tier to Enterprise)
        if (tierType === LicenseTierType.ENTERPRISE) {
            return {
                status: AlertType.INFO,
                message: l10nService.getMessage(l10nKeys.unlockMoreFeaturesMessage, [tierLabel]),
            };
        }

        // show warning if:
        // 1. Enterprise is in use (switching from Enterprise to other tiers)
        // 2. for Essentials if Basic is in use (swithing from Basic to Essentials)
        if (this.isTierInUse(LicenseTierType.ENTERPRISE) ||
            this.isTierInUse(LicenseTierType.BASIC) && tierType === LicenseTierType.ESSENTIALS
        ) {
            const { label: inUseTierLabel } = this.schemaService.getEnumValue(
                'LicenseTierType',
                this.tierInUse,
            );

            return {
                status: AlertType.WARNING,
                message: l10nService.getMessage(l10nKeys.someFeaturesOnlyAvailableMessage,
                    [inUseTierLabel, tierLabel]),
            };
        }

        return noneStatusAlertInfo;
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'nat-policy-list';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleRulesCount = `${prefix}.columnTitleRulesCount`;
export const descriptonDataLabel = `${prefix}.descriptonDataLabel`;
export const emptyObjectDataLabel = `${prefix}.emptyObjectDataLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleRulesCount]: '# Rules',
    [descriptonDataLabel]: 'Description',
    [emptyObjectDataLabel]: 'N/A',
};

/**
 * Module for NAT Policy related items and components
 * @module NatModule
 * @preferred
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';
import { isNaN, isUndefined } from 'underscore';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { ObjectTypeItem } from 'ajs/modules/data-model';

// Importing declarations
import { NatRuleConfigItem } from '../config-items/nat-policy';

interface INatPolicyConfig {
    rules: RepeatedMessageItem<NatRuleConfigItem>;
}

export class NatPolicy extends ObjectTypeItem {
    // overriding getConfig()'s return type
    public getConfig: () => INatPolicyConfig;

    constructor(args: {}) {
        const extendedArgs = {
            ...args,
            objectName: 'natpolicy',
            objectType: 'NatPolicy',
            permissionName: 'PERMISSION_NATPOLICY',
            windowElement: 'nat-policy-modal',
        };

        super(extendedArgs);
    }

    /**
     * Returns a new NatRule ConfigItem instance.
     */
    public createNewRule(config: {} = null): MessageItem {
        return this.createChildByField_('rules', config, true) as MessageItem;
    }

    /**
     * Adds a rule to the list of rules. If the rule has an index, it means that an existing
     * rule was modified so the existing rule should be replaced. If not, add the new rule to
     * the end of the list.
     */
    public addRule(rule: NatRuleConfigItem): void {
        const { rules } = this.getConfig();
        const indexField = rule.getIndex();

        // New rule doesn't have an index.
        if (isUndefined(indexField)) {
            const maxIndex = rules.getMaxIndex();
            const newIndex = isNaN(maxIndex) ? 0 : maxIndex + 1;

            rule.setIndex(newIndex);
            rules.add(rule);
        } else {
            const arrayIndex = rules.getArrayIndexWithIndexField(indexField as any as number);

            rules.config[arrayIndex] = rule;
        }
    }

    /**
     * Getter for the number of rules in policy.
     */
    public get rulesCount(): number {
        return this.getConfig().rules.count;
    }
}

initAjsDependency(
    angular.module('avi/policies/nat'),
    'factory',
    'NatPolicy',
    NatPolicy,
);

/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */
import { Pipe, PipeTransform } from '@angular/core';
import { backendErrorMsg } from '../../../js/filters/backend-error-msg.filter';

@Pipe({ name: 'backendErrorMsg' })
export class BackendErrorMsgPipe implements PipeTransform {
    /**
     * Pipe that formats backend error messages into a string.
     */
    public transform(...args: Array<string | object>): string {
        return backendErrorMsg(...args);
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'expander-with-actions';
const componentName = 'file-upload';
const prefix = `${moduleName}.${componentName}`;

export const selectFileInputLabel = `${prefix}.selectFileInputLabel`;
export const maxSizeExceededAlertMessage = `${prefix}.maxSizeExceededAlertMessage`;

export const ENGLISH = {
    [selectFileInputLabel]: 'Select a file',
    [maxSizeExceededAlertMessage]: 'File size should be under {0} MB',
};

const moduleName = 'ajs-legacy';
const componentName = 'service-engine-configuration-controller';
const prefix = `${moduleName}.${componentName}`;

export const vlanInterfacesHeader = `${prefix}.vlanInterfacesHeader`;
export const editServiceEngineHeader = `${prefix}.editServiceEngineHeader`;
export const serviceEngineGroupHeader = `${prefix}.serviceEngineGroupHeader`;
export const seGroupInputLabel = `${prefix}.seGroupInputLabel`;
export const managementNetworkHeader = `${prefix}.managementNetworkHeader`;
export const dataNetworksHeader = `${prefix}.dataNetworksHeader`;
export const networksHeader = `${prefix}.networksHeader`;
export const createVlanInterfaceBtnLabel = `${prefix}.createVlanInterfaceBtnLabel`;
export const cancelBtnLabel = `${prefix}.cancelBtnLabel`;
export const saveBtnLabel = `${prefix}.saveBtnLabel`;
export const columnTitleInterface = `${prefix}.columnTitleInterface`;
export const columnTitleNetworkName = `${prefix}.columnTitleNetworkName`;
export const columnTitleMacAddress = `${prefix}.columnTitleMacAddress`;
export const columnTitleIPAddress = `${prefix}.columnTitleIPAddress`;
export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleParentInterface = `${prefix}.columnTitleParentInterface`;
export const editBtnLabel = `${prefix}.editBtnLabel`;
export const deleteBtnLabel = `${prefix}.deleteBtnLabel`;
export const staticIpv6InputLabel = `${prefix}.staticIpv6InputLabel`;
export const staticIpv6InputPlaceholder = `${prefix}.staticIpv6InputPlaceholder`;
export const staticIpv4InputLabel = `${prefix}.staticIpv4InputLabel`;
export const staticIpv4InputPlaceholder = `${prefix}.staticIpv4InputPlaceholder`;

export const ENGLISH = {
    [vlanInterfacesHeader]: 'VLAN Interfaces',
    [editServiceEngineHeader]: 'Edit Service Engine: <strong>{0}</strong>',
    [serviceEngineGroupHeader]: 'Service Engine Group',
    [seGroupInputLabel]: 'SE Group',
    [managementNetworkHeader]: 'Management Network',
    [dataNetworksHeader]: 'Data Networks',
    [networksHeader]: 'Networks',
    [createVlanInterfaceBtnLabel]: 'Create VLAN Interface',
    [cancelBtnLabel]: 'Cancel',
    [saveBtnLabel]: 'Save',
    [columnTitleInterface]: 'Interface',
    [columnTitleNetworkName]: 'Network Name',
    [columnTitleMacAddress]: 'Mac Address',
    [columnTitleIPAddress]: 'IP Address',
    [columnTitleName]: 'Name',
    [columnTitleParentInterface]: 'Parent Interface',
    [editBtnLabel]: 'Edit',
    [deleteBtnLabel]: 'Delete',
    [staticIpv6InputLabel]: 'STATIC IPv6:',
    [staticIpv6InputPlaceholder]: 'Comma-separated IPv6 Prefixes',
    [staticIpv4InputLabel]: 'STATIC IPv4:',
    [staticIpv4InputPlaceholder]: 'Comma-separated IPv4 Prefixes',
};

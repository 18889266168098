/**
 * @module NatModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import { IMessageItemData, MessageItem } from 'ajs/modules/data-model';
import { NatMatchTargetConfigItem } from './nat-match-target.config-item.factory';
import { NatPolicyActionConfigItem } from './nat-policy-action.config-item.factory';

interface INatRuleConfig {
    match: NatMatchTargetConfigItem;
    action: NatPolicyActionConfigItem;
    enable: boolean;
}

interface INatRuleData extends IMessageItemData {
    config: INatRuleConfig;
}

/**
 * @desc NatRule MessageItem class.
 * @author Aravindh Nagarajan
 */
class NatRuleConfigItem extends MessageItem {
    public data: INatRuleData;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'NatRule',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return ['match', 'action'];
    }

    /**
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): INatRuleConfig {
        const type = this.objectType.toLowerCase();
        const defaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};
        const { match, ...defaults } = defaultConfig;

        return {
            ...defaults,
            enable: true,
            match: null,
        };
    }

    /**
     * Getter for the number of matches in NAT rule.
     */
    public get matchCount(): number {
        return this.config.match ? this.config.match.matchCount : 0;
    }

    /**
     * Getter for enable value of rule.
     */
    public get enable(): boolean {
        return this.config.enable;
    }

    /**
     * Getter for match configItem of a rule.
     */
    public get match(): NatMatchTargetConfigItem {
        return this.config.match || null;
    }

    /**
     * Getter for action configItem of a rule.
     */
    public get action(): NatPolicyActionConfigItem {
        return this.config.action || null;
    }
}

NatRuleConfigItem.ajsDependencies = [
    'defaultValues',
];

initAjsDependency(
    angular.module('avi/policies/nat'),
    'factory',
    'NatRuleConfigItem',
    NatRuleConfigItem,
);

export { NatRuleConfigItem };

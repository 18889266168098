/**
 * @module PoliciesModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { IMessageItemData, MatchExtendableConfigItem } from 'ajs/modules/data-model';
import {
    IpAddrMatchConfigItem,
} from 'ajs/js/services/config-items/matches/ip-addr-match.config-item.factory';

interface IMatchTargetConfigItemData extends IMessageItemData {
    config: {
        client_ip?: IpAddrMatchConfigItem;
    };
}

export class MatchTargetConfigItem extends MatchExtendableConfigItem {
    public data: IMatchTargetConfigItemData;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'MatchTarget',
            ...args,
        };

        super(extendedArgs);
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './error-page-profile-modal.l10n';
import './error-page-profile-modal.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class ErrorPageProfileModalController {
    static createPageConfig(index = 0, enable = true, bodyRef = '', redirect = '') {
        return {
            index,
            enable,
            match: {
                match_criteria: 'IS_IN',
                status_codes: [],
                ranges: [],
            },
            error_page_body_ref: bodyRef,
            error_redirect: redirect,
            redirect_: !!redirect,
        };
    }

    constructor(
        $window,
        Regex,
        ErrorPageBodyCollection,
        l10nService,
    ) {
        this.$window_ = $window;
        this.Regex = Regex;
        this.bodyCollection = new ErrorPageBodyCollection();
        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    addPage() {
        const config = this.editable.getConfig();

        if (!Array.isArray(config.error_pages)) {
            config.error_pages = [];
        }

        config.error_pages.push(
            ErrorPageProfileModalController.createPageConfig(config.error_pages.length),
        );
    }

    removePage(index = 0) {
        if (this.$window_.confirm('Remove page?')) {
            const config = this.editable.getConfig();

            config.error_pages.splice(index, 1);
        }
    }
}

ErrorPageProfileModalController.$inject = [
    '$window',
    'Regex',
    'ErrorPageBodyCollection',
    'l10nService',
];

/**
 * @ngdoc component
 * @name errorPageProfileModal
 * @param {ErrorPageProfile} editable
 * @description
 *     Error Page Profile config modal.
 */
angular.module('aviApp').component('errorPageProfileModal', {
    controller: ErrorPageProfileModalController,
    bindings: {
        editable: '<',
    },
    templateUrl: 'src/components/modals/error-page-profile-modal/error-page-profile-modal.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc Controller
 * @name SystemConfigurationOsInstallPluginController
 */
angular.module('aviApp').controller('SystemConfigurationOsInstallPluginController', [
'$scope', '$http', 'Regex', 'AviModal',
function($scope, $http, Regex, AviModal) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.Regex = Regex;

    $scope.init = function() {
        $scope.busy = false;
        $scope.error = null;

        $scope.instplug = {
            op_type: 'POT_POST',
            prov_name: 'Avi_ADC',
            uuid: $scope.cloudId,
        };
    };

    $scope.install = function() {
        $scope.busy = true;
        $scope.error = null;

        $http.post('/api/os_lbprov_plugin', $scope.instplug)
            .then(function() {
                $scope.busy = false;
                $scope.closeModal();
            }).catch(function(rsp) {
                $scope.busy = false;
                $scope.error = rsp.result;
            });
    };

    $scope.closeModal = function() {
        if (!$scope.busy) {
            AviModal.destroy('adm-sysconf-os-install-plugin');
        }
    };
}]);

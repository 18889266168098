/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc directive
 * @name parseIp
 * @restrict A
 * @description Parses and formats input IP address string. IP address string gets parsed to
 * object with addr and type properties, and IP object is formatted into IP address string.
 */
angular.module('aviApp').directive('parseIp', ['RangeParser', function(RangeParser) {
    function link(scope, elm, attr, ngModel) {
        ngModel.$parsers.push(parser(attr['parseIp']));
        ngModel.$formatters.push(formatIpAddr);
    }

    /**
     * Parses hostname or IP address string into an IpAddr object with addr and type properties.
     * @param  {string?} type - Optional 'dns' attribute.
     * @return {Function} Parser function that returns null or an IpAddr object
     * {addr: 'foo.bar', type: 'DNS' or 'V4'}.
     */
    function parser(type) {
        const fallback = angular.isString(type) && type.toLowerCase() === 'dns' ?
            val => ({
                addr: val,
                type: 'DNS',
            }) : () => '';

        return val => {
            const ip = RangeParser.ipRange2Json(val);

            return ip === null ? fallback(val) : ip;
        };
    }

    /**
     * Formats IP object into IP address string.
     * @param  {Object} val - IP object {addr:'1.2.3.4', type:'V4'}.
     * @return {string} IP address string.
     */
    function formatIpAddr(val) {
        return val ? val.addr : '';
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        link,
    };
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'cportal';
const componentName = 'case-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const caseModalTitle = `${prefix}.caseModalTitle`;
export const statusLabel = `${prefix}.statusLabel`;
export const subjectLabel = `${prefix}.subjectLabel`;
export const contactLabel = `${prefix}.contactLabel`;
export const typeLabel = `${prefix}.typeLabel`;
export const severityLabel = `${prefix}.severityLabel`;
export const versionLabel = `${prefix}.versionLabel`;
export const ecosystemLabel = `${prefix}.ecosystemLabel`;
export const userCasesLabel = `${prefix}.userCasesLabel`;
export const businessJustificationLabel = `${prefix}.businessJustificationLabel`;
export const currentSolutionLabel = `${prefix}.currentSolutionLabel`;
export const descriptionLabel = `${prefix}.descriptionLabel`;
export const customTagLabel = `${prefix}.customTagLabel`;
export const attachmentsLabel = `${prefix}.attachmentsLabel`;
export const activityLabel = `${prefix}.activityLabel`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit',
    [modalHeaderNew]: 'New',
    [caseModalTitle]: 'Case',
    [statusLabel]: 'Status',
    [subjectLabel]: 'Subject',
    [contactLabel]: 'Contact',
    [typeLabel]: 'Type',
    [severityLabel]: 'Severity',
    [versionLabel]: 'Version',
    [ecosystemLabel]: 'Ecosystem',
    [userCasesLabel]: 'Use Cases',
    [businessJustificationLabel]: 'Business Justification',
    [currentSolutionLabel]: 'Current Solution',
    [descriptionLabel]: 'Description',
    [customTagLabel]: 'Custom Tag',
    [attachmentsLabel]: 'Attachments',
    [activityLabel]: 'Activity',
};

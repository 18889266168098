/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function tenantUsersDataTransformerFactory(RevisedDataTransformer) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.RevisedDataTransformer
     * @author Ashish Verma
     * @desc RevisedDataTransformer of {@link module:avi/app.TenantUsers TenantUsers}.
     */
    class TenantUsersDataTransformer extends RevisedDataTransformer {
        /** @override */
        processResponse(resp) {
            const
                allTenantsKey = '__any_tenant|', //should be in sync with TenantUsersCollDataSource
                resultsHash = {};

            let results = [];

            if ('results' in resp.data && Array.isArray(resp.data.results)) {
                _.each(resp.data.results, function(user) {
                    if (Array.isArray(user.access)) {
                        user.access.forEach(function(access) {
                            const tenantId =
                                access.all_tenants ? allTenantsKey : access.tenant_ref.slug();

                            if (!(tenantId in resultsHash)) {
                                resultsHash[tenantId] = {
                                    tenantId,
                                    users: [],
                                };
                            }

                            resultsHash[tenantId].users.push({
                                user_ref: user.url,
                                role_ref: access.role_ref,
                            });
                        });
                    }
                });

                //for conformity translates hash into array
                results = _.values(resultsHash);
            }

            resp.data.results = results;
            resp.data.count = results.length;

            return resp;
        }
    }

    return TenantUsersDataTransformer;
}

tenantUsersDataTransformerFactory.$inject = [
    'RevisedDataTransformer',
];

angular.module('avi/app').factory('TenantUsersDataTransformer', tenantUsersDataTransformerFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').directive('sideColumnCollapseButton', [
'$rootScope', 'myAccount',
function($rootScope, myAccount) {
    function sideColumnCollapseButtonLink(scope, element) {
        element
            .addClass('button-side-column')
            .on('click', () => myAccount.toggleSidebar());

        scope.$watch(
            () => myAccount.uiProperty.sideRailOpen,
            newValue => element.toggleClass('collapsed', !newValue),
        );
    }

    return {
        scope: false,
        link: sideColumnCollapseButtonLink,
        template: '<i class="icon icon-cancel-1"></i><i class="icon icon-chevron-left"></i>',
    };
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @typedef {Object} OCSPResponseInfo
 * @memberOf module:aviApp
 * @property {string} ocsp_response
 * @property {string} cert_status
 * @property {string} this_update
 * @property {string} next_update
 * @property {string} revocation_time
 * @property {string} revocation_reason
 * @property {string} ocsp_resp_from_responder_url
 */

// TODO: update with import from generated-types, AV-97983
const OCSP_CERTSTATUS_REVOKED = 'OCSP_CERTSTATUS_REVOKED';

/**
 * @ngdoc factory
 * @name  Certificate
 * @description  Certificate item.
 */
const CertificateFactory = function(Item, CertificateManagement, $q) {
    class Certificate extends Item {
        /**
         * Returns true if the type of the Certificate is CSR.
         * @return {boolean}
         */
        isCertificateSigningRequest() {
            const { certificate } = this.getConfig();

            return !angular.isUndefined(certificate.certificate_signing_request);
        }

        /**
         * Called when user selects between Self-signed, CSR, and Import types. Sets properties
         * on data.config.
         * @param  {string} type - 'self-signed', 'ca-signed', or 'import'.
         */
        changeType(type) {
            const config = this.getConfig();
            const { certificate } = config;

            certificate.self_signed = type === 'self-signed';

            if (type === 'import') {
                certificate.subject = undefined;
                certificate.self_signed = undefined;
            } else {
                certificate.certificate = undefined;
                config.key = undefined;

                if (type === 'ca-signed') {
                    certificate.days_until_expire = undefined;
                }
            }
        }

        /**
         * Get missing cert issuer name. If no cert is missing return empty string.
         * @returns {string}
         */
        getMissingCertIssuerName() {
            const { ca_certs: caCerts, certificate } = this.getConfig();
            let certName = '';

            if (caCerts && !certificate.self_signed) {
                const cert = caCerts.find(caCert => !caCert.ca_ref);

                certName = cert ? cert.name : '';
            }

            return certName;
        }

        /**
         * Returns ocsp_config object, if it exists.
         * @return {IOCSPConfig|null}
         */
        get ocspConfig() {
            const {
                ocsp_config: ocspConfig,
                enable_ocsp_stapling: enableOcspStapling,
            } = this.getConfig();

            if (enableOcspStapling && !ocspConfig) {
                throw new Error('config.ocsp_config does not exist');
            }

            return ocspConfig || null;
        }

        /**
         * Returns expiry_status property for the certificate.
         * @return {string|undefined}
         */
        get expiryStatus() {
            return this.getConfig().certificate.expiry_status;
        }

        /**
         * Returns ocsp_error_status property for the certificate.
         * @return {string|undefined}
         */
        get ocspErrorStatus() {
            return this.getConfig().ocsp_error_status;
        }

        /**
         * Returns enable_ocsp_stapling property for the certificate.
         * @return {boolean|undefined}
         */
        get enableOcspStapling() {
            return this.getConfig().enable_ocsp_stapling;
        }

        /**
         * Sends certificate validate request
         * @returns {ng.$q.promise}
         */
        validateCertificate() {
            const
                config = this.getConfig(),
                { certificate } = config;

            this.busy = true;
            this.errors = null;

            return this.request('POST', '/api/sslkeyandcertificate/validate', {
                certificate: certificate ? certificate.certificate : undefined,
                certificate_base64: config.certificate_base64,
                key: config.key,
                key_base64: config.key_base64,
                key_passphrase: config.key_passphrase,
            })
                .then(({ data }) => {
                    this._setValidatedCertificate(data.certificate);

                    if (config.type === 'SSL_CERTIFICATE_TYPE_CA') {
                        this._setValidatedKey(data.certificate);
                    } else {
                        this._setValidatedKey(data);
                    }
                })
                .catch(rsp => {
                    this.errors = rsp.data;

                    return $q.reject(rsp);
                })
                .finally(() => this.busy = false);
        }

        /**
         * Called after validating a certificate and replaces previous certificate values with
         * validated values.
         * @param {Object} certificate - Validated certificate object.
         */
        _setValidatedCertificate(certificate) {
            const config = this.getConfig();

            config.certificate_base64 = false;
            config.certificate = {
                certificate: certificate.certificate,
                subject: certificate.subject,
                key_params: certificate.key_params,
                not_after: certificate.not_after,
            };
        }

        /**
         * Called after validating a certificate and replaces previous key values with validated
         * values.
         * @param {Array} data
         */
        _setValidatedKey(data) {
            const config = this.getConfig();

            config.key_base64 = false;
            config.key = data.key;
            config.key_params = data.key_params;
        }

        /**
         * @override
         */
        isEditable() {
            const config = this.getConfig();

            return config.type !== 'SSL_CERTIFICATE_TYPE_CA' &&
                config.certificate && config.certificate.self_signed === false &&
                super.isEditable();
        }

        /**
         * Sets config.certificate.certificate, config.key, and config.key_passphrase to
         * undefined, but leaves config.certificate.subject as is. This allows showing the user
         * the previous certificate and lets him import a new certificate and key.
         * If config.certificate is set to undefined, the previous certificate data is lost.
         * If nothing is set to undefined, then the user could possibly replace the old key with
         * a new key without replacing the old certificate, causing an error.
         * @override
         */
        beforeEdit() {
            const config = this.getConfig();

            const { certificate } = config;

            if (!angular.isUndefined(certificate)) {
                if (!certificate.certificate) {
                    config.certificate_base64 = true;
                    config.key_base64 = true;
                }

                if (!('certificate_signing_request' in certificate)) {
                    certificate.subject = undefined;
                    certificate.not_after = undefined;
                }
            }

            const { ocspConfig } = this;

            if (ocspConfig && !ocspConfig.responder_url_lists) {
                ocspConfig.responder_url_lists = [];
            }
        }

        /**
         * @override
         */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            if (config.certificate) {
                delete config.certificate.key_params;
            }

            const { key_params: keyParams } = config;

            if (keyParams) {
                const { algorithm } = keyParams;

                switch (algorithm) {
                    case 'SSL_KEY_ALGORITHM_RSA':
                        delete keyParams.ec_params;
                        break;
                    case 'SSL_KEY_ALGORITHM_EC':
                        delete keyParams.rsa_params;
                        break;
                }
            }

            return config;
        }

        /**
         * If a Certificate Management Profile is selected, load it to check for dynamic_params.
         */
        loadCertificateManagementProfile() {
            const config = this.getConfig();

            config.dynamic_params = undefined;

            if (!config.certificate_management_profile_ref) {
                return;
            }

            const certManagement = new CertificateManagement({
                id: config.certificate_management_profile_ref.slug(),
            });

            this.busy = true;

            certManagement.load()
                .then(rsp => {
                    const scriptParams = certManagement.getConfig().script_params;

                    if (!angular.isArray(scriptParams)) {
                        return;
                    }

                    config.dynamic_params = scriptParams.filter(param => param.is_dynamic);
                })
                .finally(() => this.busy = false);
        }

        /**
         * @return {module:aviApp.OCSPResponseInfo|null}
         */
        get ocspResponseInfo() {
            return this.getConfig().ocsp_response_info || null;
        }

        /**
         * To check if Ocsp is applicable to a certficate.
         * @return {boolean}
         */
        get isOcspApplicable() {
            return !!this.getConfig().ocsp_config;
        }

        /**
         * To check if certificate OCSP status is revoked.
         * @return {boolean}
         */
        get isRevoked() {
            if (!this.isOcspApplicable) {
                throw new Error('OCSP not applicable for this certificate');
            }

            return this.ocspResponseInfo?.cert_status === OCSP_CERTSTATUS_REVOKED;
        }
    }

    angular.extend(Certificate.prototype, {
        objectName: 'sslkeyandcertificate',
        windowElement: 'ssl-certificate-create-application-system',
        restrictEditOnEssentialLicense_: false,
        params: {
            include_internal: true,
            include_name: true,
        },
    });

    return Certificate;
};

CertificateFactory.$inject = [
    'Item',
    'CertificateManagement',
    '$q',
];

angular.module('aviApp').factory('Certificate', CertificateFactory);

const moduleName = 'waf';
const componentName = 'waf-policy-allowlist-rule-expander';
const prefix = `${moduleName}.${componentName}`;

export const matchCellHeader = `${prefix}.matchCellHeader`;
export const actionCellHeader = `${prefix}.actionCellHeader`;
export const actionCellLabel = `${prefix}.actionCellLabel`;

export const ENGLISH = {
    [matchCellHeader]: 'MATCH ({0})',
    [actionCellHeader]: 'ACTION',
    [actionCellLabel]: 'Action',
};

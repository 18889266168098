/**
 * @module NatModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { IMessageItemData, MessageItem } from 'ajs/modules/data-model';
import { NatAddrInfoConfigItem } from './nat-addr-info.config-item.factory';

interface INatPolicyActionConfig {
    nat_info: RepeatedMessageItem<NatAddrInfoConfigItem>;
    type: string;
}

interface INatPolicyActionData extends IMessageItemData {
    config: INatPolicyActionConfig;
}

/**
 * @desc NatPolicyAction MessageItem class.
 * @author Aravindh Nagarajan
 */
class NatPolicyActionConfigItem extends MessageItem {
    public data: INatPolicyActionData;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'NatPolicyAction',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter for nat_info.
     */
    public get natInfo(): RepeatedMessageItem<NatAddrInfoConfigItem> {
        return this.config.nat_info;
    }

    /**
     * Getter for action type
     */
    public get type(): string {
        return this.config.type;
    }
}

initAjsDependency(
    angular.module('avi/policies/nat'),
    'factory',
    'NatPolicyActionConfigItem',
    NatPolicyActionConfigItem,
);

export { NatPolicyActionConfigItem };

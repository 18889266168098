/** @module WafModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    Input,
} from '@angular/core';
import {
    MatchCase,
    StringOperation,
} from 'generated-types';
import { WafExcludeListEntryConfigItem, WafExclusionTypeConfigItem } from 'ajs/modules/waf';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './waf-exclude-list-entry.l10n';
import './waf-exclude-list-entry.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Displays an entry of the WAF Exclude List.
 * @author alextsg
 */
@Component({
    selector: 'waf-exclude-list-entry',
    templateUrl: './waf-exclude-list-entry.component.html',
})
export class WafExcludeListEntryComponent {
    /**
     * WafExcludeListEntryConfigItem instance.
     */
    @Input()
    public wafExcludeListEntry: WafExcludeListEntryConfigItem;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Displays settings for the Path, if it's Case Sensitive and/or Regex Match.
     */
    public get pathLabelSubtext(): string {
        return this.getExclusionTypeLabel(this.wafExcludeListEntry.config.uri_match_criteria);
    }

    /**
     * Displays settings for the Match Element, if it's Case Sensitive and/or Regex Match.
     */
    public get matchElementLabelSubtext(): string {
        return this.getExclusionTypeLabel(this.wafExcludeListEntry.config.match_element_criteria);
    }

    /**
     * Returns a string representation of Case Sensitive/Insensitive and Regex/Equal match settings
     * on either the Path or Match Element.
     */
    private getExclusionTypeLabel(exclusionType: WafExclusionTypeConfigItem): string {
        const output = [];
        const { config } = exclusionType;
        const { match_case: matchCase, match_op: matchOP } = config;

        if (matchOP === StringOperation.EQUALS) {
            output.push(this.l10nService.getMessage(l10nKeys.exactMatchLabel));
        } else if (matchOP === StringOperation.REGEX_MATCH) {
            output.push(this.l10nService.getMessage(l10nKeys.regexMatchLabel));
        }

        if (matchCase === MatchCase.SENSITIVE) {
            output.push(this.l10nService.getMessage(l10nKeys.caseSensitiveLabel));
        } else {
            output.push(this.l10nService.getMessage(l10nKeys.caseInsensitiveLabel));
        }

        return output.join(', ');
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { CLOUD_NSXT } from 'ajs/js/services/items/Cloud';
import * as l10n from './SEGroupCreateController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('avi/serviceengine').controller('SEGroupCreateController', [
'$scope',
'$http',
'$filter',
'Regex',
'schemaService',
'Collection',
'Cloud',
'dropDownUtils',
'HSMGroupCollection',
'SubnetListNetworkCollection',
'l10nService',
function(
    $scope,
    $http,
    $filter,
    Regex,
    schemaService,
    Collection,
    Cloud,
    dropDownUtils,
    HSMGroupCollection,
    SubnetListNetworkCollection,
    l10nService,
) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    const CLOUD_AZURE = 'CLOUD_AZURE';

    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    $scope.ui = {
        max_vs_per_se_upperBound: 1000,
        cloudIsSet: false,
        useAutoScaleFipSubnet: false,
        vcenterServerRefs: undefined,
    };

    $scope.tab = {};
    $scope.Regex = Regex;

    $scope.networkCollection = new SubnetListNetworkCollection({
        params: {
            discovered_only: true,
        },
    });

    $scope.HSMGroupCollection = new HSMGroupCollection();
    $scope.bufferSeFieldDescription =
        `${schemaService.getFieldDescription('ServiceEngineGroup', 'buffer_se')}.
        This integer is the 'M' in 'N + M.'
        Default value depends on which elastic HA option is selected.`;

    $scope.hmOnStandbyDescription =
        `${schemaService.getFieldDescription('ServiceEngineGroup', 'hm_on_standby')}` +
        ' Note: By definition, no client traffic is directed to the standby SE of a given VS.' +
        ' Consequently, this option is meaningful only for active monitors that may have been' +
        ' specified. They are strongly recommended for any VS placed on ' +
        'a legacy HA SE group.';

    /**
     * Absolute minimal valid value of disk_per_se field. Static, in GB.
     * @type {number}
     * @inner
     */
    const diskPerSeAbsMin = 15;

    /**
     * Minimal valid disk_per_se value in GB. Updated interactively based on memory_per_se value.
     * Can't be lower than diskPerSeAbsMin.
     * @type {number}
     */
    $scope.diskPerSeMin = diskPerSeAbsMin;

    $scope.diskPerSeDescription =
        'Amount of disk space for each of the Service Engine virtual machines. ' +
        'Disk space should be at least 2 x memory per Service Engine + 5GB and no less ' +
        `than ${diskPerSeAbsMin}GB.`;

    $scope.subnetListNetworkCollection = new SubnetListNetworkCollection({
        params: {
            auto_allocate_only: true,
        },
    });

    $scope.autoScaleFipChange = function() {
        $scope.subnetListNetworkCollection.setParams({
            fip_capable: $scope.ui.useAutoScaleFipSubnet || undefined,
        });
    };

    /**
     * This function is called when modal window opened
     */
    $scope.init = function() {
        // First tab should be initially selected
        $scope.tab.number = 0;
        $scope.ui.cloudIsSet = false;
        $scope.hostGeoProfile = $scope.editable.getConfig().extra_shared_config_memory > 0;

        $scope.vcenterDatastoreModeEnumValues = schemaService.getEnumValues('VcenterDatastoreMode');
        setCloud();
    };

    /**
     * Called after $scope.Cloud has loaded.
     */
    function afterCloudInit() {
        const { Cloud: { id: cloudId } } = $scope;

        [
            $scope.networkCollection,
            $scope.subnetListNetworkCollection,
        ]
            .forEach(collection => collection.setParams({ cloud_uuid: cloudId }));

        const seConfig = $scope.editable.getConfig();
        const cloudConfig = $scope.Cloud.getConfig();
        const vtype = $scope.Cloud.getVtype();

        //TODO need to update backend with filtering by cloudRef an enable this API call @am
        switch (vtype) {
            case 'CLOUD_VCENTER':
                $scope.editable.getDatastores(cloudId)
                    .then(datastores => {
                        $scope.datastoreOptions =
                            datastores.map(({ name }) => dropDownUtils.createOption(name));
                    });
                break;
            case 'CLOUD_OPENSTACK':
            case 'CLOUD_AWS':
            case CLOUD_AZURE:
                $http.get(`/api/cloud/${cloudId}/availability-zones`)
                    .then(({ data: { zones = [] } }) => {
                        $scope.availableZoneOptions =
                            zones.map(({ name }) => dropDownUtils.createOption(name));
                    });
                break;
            case CLOUD_NSXT: {
                $scope.Cloud.getNsxtVCenterServerRefs().then(refs => {
                    $scope.ui.vcenterServerRefs = refs;
                });

                break;
            }
        }

        if (vtype !== 'CLOUD_AWS') {
            delete seConfig.vip_asg;
        } else if (!seConfig.uuid) {
            $scope.addVipAutoscaleZone();
        }

        $scope.isVCenterCloud = vtype === 'CLOUD_VCENTER';
        $scope.isVCenterReadCloud = $scope.isVCenterCloud &&
            cloudConfig.vcenter_configuration.privilege === 'READ_ACCESS';
        $scope.isCloudNone = vtype === 'CLOUD_NONE';
        $scope.isLinuxCloud = vtype === 'CLOUD_LINUXSERVER';
        $scope.isAwsCloud = vtype === 'CLOUD_AWS';
        $scope.isOpenstackCloud = vtype === 'CLOUD_OPENSTACK';
        $scope.isAzureCloud = vtype === CLOUD_AZURE;

        const {
            isVCenterCloud,
            isVCenterReadCloud,
            isCloudNone,
            isLinuxCloud,
            isAwsCloud,
            isOpenstackCloud,
            isAzureCloud,
        } = $scope;

        $scope.hasMemoryPerSE = !isVCenterReadCloud && !isCloudNone && !isLinuxCloud &&
            !isAwsCloud && !isOpenstackCloud && !isAzureCloud;
        $scope.hasCPUPerSE = $scope.hasMemoryPerSE;
        $scope.hasDiskPerSE = $scope.hasMemoryPerSE;
        $scope.hasSENamePrefix = !isVCenterReadCloud && !isCloudNone && !isLinuxCloud;
        $scope.hasDeleteSE = $scope.hasSENamePrefix;
        $scope.hasMaxNumberOfSE = !isVCenterReadCloud && !isLinuxCloud;
        $scope.hasIPRouting = isCloudNone || isLinuxCloud ||
            isVCenterCloud && !isVCenterReadCloud;

        switch (vtype) {
            case 'CLOUD_OPENSTACK':
            case 'CLOUD_AWS':
                $scope.ui.cpuMemOption = 'flavors';
                $scope.editable.getSecurityGroups()
                    .then(securityGroups => $scope.ui.securityGroups = securityGroups);
                break;
        }

        if (!seConfig.license_tier) {
            seConfig.license_tier = cloudConfig.license_tier;
        }

        if (!seConfig.license_type) {
            seConfig.license_type = cloudConfig.license_type;
        }

        if (vtype === 'CLOUD_AWS') {
            /**
            * Used only as the controller layer ng-model for 'Avi Managed Security Groups' checkbox,
            * indicating its mutually negative relation with real data model value.
            */
            $scope.enableAviSecurityGroups = !$scope.editable
                .getConfig()['disable_avi_securitygroups'];

            // label text re-phrased for 'Avi Managed Security Groups' checkbox
            $scope.aviSgLabel = 'If enabled Avi will create and manage security groups along with' +
                'custom sg provided by user. If disabled user creates and manages new security ' +
                'groups. Avi will only make use of custom security groups provided by user. This ' +
                'option is only supported for AWS cloud type.';
        }

        $scope.nonCachingMemoryPercentage = $scope.getNonCachingMemoryPercentage();

        $scope.updateDiskPerSeMin();
    }

    /**
     * Called when 'Avi Managed Security Groups' is checked/unchecked. Supported only under AWS.
     */
    $scope.flipManageSecurityGroups = function() {
        $scope.editable.getConfig()['disable_avi_securitygroups'] = !$scope.editable
            .getConfig()['disable_avi_securitygroups'];
    };

    /**
     * Event handler for memory_per_se field change.
     * SE VM needs at least 15GBs of disk space and there is a formula based on memory_per_se.
     * @see $scope.diskPerSeDescription
     */
    $scope.updateDiskPerSeMin = function() {
        const { memory_per_se: memoryPerSe = 1024 } = $scope.editable.getConfig();
        const diskPerSeMin = 2 * memoryPerSe / 1024 + 5;

        $scope.diskPerSeMin = Math.max(diskPerSeMin, diskPerSeAbsMin);
    };

    const setCloud = () => {
        //always have cloudId as we open it from the Cloud tab
        $scope.Cloud = new Cloud({ id: $scope.editable.getCloudRef().slug() });

        $scope.editable.errors = null;

        $scope.Cloud.load()
            .then(function() {
                afterCloudInit();
                $scope.ui.cloudIsSet = true;
            }).catch(function(rsp) {
                $scope.editable.errors = rsp.data;
            });
    };

    /**
     * Called when HA Mode has been changed, either by clicking Cluster/Legacy HA buttons or
     * Active/N+M radio buttons.
     * @param  {string=} haMode - 'cluster' or 'legacy' when button is clicked. Undefined if radio
     *     is selected.
     */
    $scope.haModeChanged = function(haMode) {
        const config = $scope.editable.getConfig();

        if (haMode === 'cluster') {
            config.ha_mode = 'HA_MODE_SHARED';
        } else if (haMode === 'legacy') {
            config.ha_mode = 'HA_MODE_LEGACY_ACTIVE_STANDBY';
        }

        switch (config.ha_mode) {
            case 'HA_MODE_SHARED_PAIR':
                config.min_scaleout_per_vs = 2;
                config.buffer_se = 0;
                config.algo = 'PLACEMENT_ALGO_DISTRIBUTED';
                config.max_se = 10;
                break;
            case 'HA_MODE_SHARED':
                config.min_scaleout_per_vs = 1;
                config.buffer_se = 1;
                config.algo = 'PLACEMENT_ALGO_PACKED';
                config.max_se = 10;
                break;
            case 'HA_MODE_LEGACY_ACTIVE_STANDBY':
                config.min_scaleout_per_vs = 1;
                config.buffer_se = 0;
                config.algo = 'PLACEMENT_ALGO_PACKED';
                config.max_se = 2;
                config.auto_rebalance = false;
                break;
        }

        config.max_vs_per_se = 10;
    };

    /**
     * Handler for Distribute Load checkbox ng-change.
     */
    $scope.handleDistributeLoadChange = function() {
        const config = $scope.editable.getConfig();

        config.auto_redistribute_active_standby_load = false;
    };

    /**
     * Adds empty zone to VipAutoscaleZones.
     */
    $scope.addVipAutoscaleZone = function() {
        const config = $scope.editable.getConfig();

        config.vip_asg.configuration.zones.push({
            subnet_uuid: '',
        });
    };

    /**
     * Determines if match subnets options should be shown.
     * @returns {boolean}
     */
    $scope.showMatchSubnets = function() {
        const { Cloud } = $scope;
        const correctCloud = Cloud.getVtype() === 'CLOUD_LINUXSERVER';

        if (!correctCloud) {
            return false;
        }

        const { ipam_provider_ref: ipamRef } = Cloud.getConfig();

        return ipamRef && ipamRef.name() === 'gcpipam';
    };

    /**
     * Adds blank entry to match subnets list.
     */
    $scope.addMatchSubnet = function() {
        const config = $scope.editable.getConfig();

        config.service_ip_subnets.push(undefined);
    };

    /**
     * Removes current item at index from match subnets list.
     * @param {number} index
     */
    $scope.removeMatchSubnet = function(index) {
        const { service_ip_subnets: subnets } = this.editable.getConfig();

        subnets.splice(index, 1);
    };

    /**
     * Return true if any of the sub-sections in SE capacity and limit settings is presented.
     * @param {number} index
     * @return {boolean}
     */
    $scope.seCapacityAndLimitSettingsAvailable = function() {
        return $scope.hasMaxNumberOfSE || $scope.hasMemoryPerSE ||
            $scope.hasCPUPerSE || $scope.hasDiskPerSE;
    };

    /**
     * Return the calculated percentage of non-caching memory based on caching part.
     * @return {number}
     */
    $scope.getNonCachingMemoryPercentage = function() {
        const seConfig = $scope.editable.getConfig();

        return 100 - (seConfig['app_cache_percent'] || 0);
    };

    /**
     * Adjust memory allocation percentage on change.
     * Keep original connect/buffer ratio for non-caching memory.
     */
    $scope.onAppCachePercentChange = function() {
        const
            seConfig = $scope.editable.getConfig(),
            oldNonCachePercentage = $scope.nonCachingMemoryPercentage,
            newNonCachePercentage = $scope.getNonCachingMemoryPercentage(),
            { connection_memory_percentage: oldConnectPercentage } = seConfig;

        let newConnectPercentage = newNonCachePercentage * (oldConnectPercentage /
                oldNonCachePercentage);

        newConnectPercentage = Math.max(newConnectPercentage, 10);
        seConfig['connection_memory_percentage'] = newConnectPercentage;
        $scope.nonCachingMemoryPercentage = newNonCachePercentage;
    };

    /**
     * If true, shows the NSX-T placement configuration section.
     */
    $scope.showNsxtPlacementScopeConfig = () => {
        return $scope.ui.vcenterServerRefs?.length && $scope.Cloud.getVtype() === CLOUD_NSXT;
    };

    $scope.$on('$destroy', () => {
        const colls = [//array of collections working with respect to the cloud
            $scope.networkCollection,
            $scope.subnetListNetworkCollection,
        ];

        colls.forEach(collection => collection.destroy());

        $scope.Cloud.destroy();
    });
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name ServerHealthMonitorCollection
 * @description Health monitors {@link Collection} for the {@link Pool Pool's} {@link Server
 * Servers}.
 */
angular.module('aviApp').factory('ServerHealthMonitorCollection', [
    'Collection', 'ServerHealthMonitor',
    function(Collection, ServerHealthMonitor) {
        const dataSources = {
            list: {
                source: 'ServerHealthScoreMonitorListCollDataSource',
                transformer: 'ServerHealthScoreMonitorListDataTransformer',
                transport: 'ServerHealthScoreMonitorListDataTransport',
                fields: ['config'],
            },
        };

        class ServerHealthMonitorCollection extends Collection {
            constructor(args = {}) {
                if (angular.isUndefined(args.poolId) ||
                    angular.isUndefined(args.serverIp) ||
                    angular.isUndefined(args.serverPort)) {
                    throw new Error('Can\'t work with ServerHealthMonitorCollection without' +
                        ' poolId, server ip or port.');
                }

                super(args);

                /**
                 * Pool id of a Pool this Server belongs to
                 * @type {string}
                 */
                this.poolId = args.poolId;

                /**
                 * Server IP address
                 * @type {string}
                 */
                this.serverIp = args.serverIp;

                /**
                 * Server port
                 * @type {string}
                 */
                this.serverPort = `${args.serverPort}`;
            }

            /** @override */
            isCreatable() {
                return false;
            }
        }

        angular.extend(ServerHealthMonitorCollection.prototype, {
            objectName_: 'server-health-monitors',
            itemClass_: ServerHealthMonitor,
            allDataSources_: dataSources,
            defaultDataSources_: 'list',
            isStatic_: false,
        });

        return ServerHealthMonitorCollection;
    }]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'pool-group-deployment-policy-modal';
const prefix = `${moduleName}.${componentName}`;

export const editDeploymentPolicyHeader = `${prefix}.editDeploymentPolicyHeader`;
export const newDeploymentPolicyHeader = `${prefix}.newDeploymentPolicyHeader`;
export const generalSectionHeader = `${prefix}.generalSectionHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const trafficRatioInputLabel = `${prefix}.trafficRatioInputLabel`;
export const trafficRatioInputPlaceholder = `${prefix}.trafficRatioInputPlaceholder`;
export const evaluationDurationInputLabel = `${prefix}.evaluationDurationInputLabel`;
export const trafficRatioRampupInputLabel = `${prefix}.trafficRatioRampupInputLabel`;
export const trafficRatioRampupInputPlaceholder = `${prefix}.trafficRatioRampupInputPlaceholder`;
export const rulesSectionHeader = `${prefix}.rulesSectionHeader`;
export const emptyRuleMetricPlaceholder = `${prefix}.emptyRuleMetricPlaceholder`;
export const removeRuleButtonLabel = `${prefix}.removeRuleButtonLabel`;
export const addRuleButtonLabel = `${prefix}.addRuleButtonLabel`;

export const ENGLISH = {
    [editDeploymentPolicyHeader]: 'Edit Deployment Policy: {0}',
    [newDeploymentPolicyHeader]: 'New Deployment Policy: {0}',
    [generalSectionHeader]: 'General',
    [nameInputLabel]: 'Name',
    [trafficRatioInputLabel]: 'Traffic Ratio',
    [trafficRatioInputPlaceholder]: 'Target Traffic Ratio',
    [evaluationDurationInputLabel]: 'Evaluation Duration',
    [trafficRatioRampupInputLabel]: 'Test Traffic Ratio Rampup',
    [trafficRatioRampupInputPlaceholder]: 'Ramup Ratio',
    [rulesSectionHeader]: 'Rules',
    [emptyRuleMetricPlaceholder]: 'None',
    [removeRuleButtonLabel]: 'Remove Rule',
    [addRuleButtonLabel]: '+ Add Rule',
};

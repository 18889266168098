/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name  ControllerFaultCollDataSource
 * @description
 *     DataSource for ControllerFaultCollection. Extends LimitedListCollDataSource as it does not
 *     support pagination or search, and sets the update interval to 3 minutes.
 */
angular.module('aviApp').factory('ControllerFaultCollDataSource', [
'LimitedListCollDataSource', LimitedListCollDataSource => {
    return class ControllerFaultCollDataSource extends LimitedListCollDataSource {
        constructor(args) {
            super(args);
            this.updateInterval_ = 600;
            this.protectedUpdateInterval_ = true;
        }
    };
}]);

const moduleName = 'ajs-legacy';
const componentName = 'category-navbar';
const prefix = `${moduleName}.${componentName}`;

export const dashboard = `${prefix}.applications-dashboard`;
export const gslbservices = `${prefix}.applications-gslbservices`;
export const poolgroups = `${prefix}.applications-poolgroups`;
export const pools = `${prefix}.applications-pools`;
export const virtualservices = `${prefix}.applications-virtualservices`;
export const accountsLabel = `${prefix}.accountsLabel`;
export const settingsLabel = `${prefix}.settingsLabel`;
export const controllerLabel = `${prefix}.controllerLabel`;
export const systemLabel = `${prefix}.systemLabel`;
export const userCredentialsLabel = `${prefix}.userCredentialsLabel`;
export const supportLabel = `${prefix}.supportLabel`;
export const dashboardLabel = `${prefix}.dashboardLabel`;
export const cloudsLabel = `${prefix}.cloudsLabel`;
export const serviceEngineLabel = `${prefix}.serviceEngineLabel`;
export const serviceEngineGroupLabel = `${prefix}.serviceEngineGroupLabel`;
export const networksLabel = `${prefix}.networksLabel`;
export const routingLabel = `${prefix}.routingLabel`;
export const alertsLabel = `${prefix}.alertsLabel`;
export const eventsLabel = `${prefix}.eventsLabel`;
export const notificationsLabel = `${prefix}.notificationsLabel`;
export const trafficCaptureLabel = `${prefix}.trafficCaptureLabel`;
export const profilesLabel = `${prefix}.profilesLabel`;
export const policiesLabel = `${prefix}.policiesLabel`;
export const groupsLabel = `${prefix}.groupsLabel`;
export const securityLabel = `${prefix}.securityLabel`;
export const scriptsLabel = `${prefix}.scriptsLabel`;
export const autoScaleLabel = `${prefix}.autoScaleLabel`;
export const errorPageLabel = `${prefix}.errorPageLabel`;

export const ENGLISH = {
    [dashboard]: 'Dashboard',
    [gslbservices]: 'GSLB Services',
    [poolgroups]: 'Pool Groups',
    [pools]: 'Pools',
    [virtualservices]: 'Virtual Services',
    [accountsLabel]: 'Accounts',
    [settingsLabel]: 'Settings',
    [controllerLabel]: 'Controller',
    [systemLabel]: 'System',
    [userCredentialsLabel]: 'User Credentials',
    [supportLabel]: 'Support',
    [dashboardLabel]: 'Dashboard',
    [cloudsLabel]: 'Clouds',
    [serviceEngineLabel]: 'Service Engine',
    [serviceEngineGroupLabel]: 'Service Engine Group',
    [networksLabel]: 'Networks',
    [routingLabel]: 'Routing',
    [alertsLabel]: 'Alerts',
    [eventsLabel]: 'Events',
    [notificationsLabel]: 'Notifications',
    [trafficCaptureLabel]: 'Traffic Capture',
    [profilesLabel]: 'Profiles',
    [policiesLabel]: 'Policies',
    [groupsLabel]: 'Groups',
    [securityLabel]: 'Security',
    [scriptsLabel]: 'Scripts',
    [autoScaleLabel]: 'AutoScale',
    [errorPageLabel]: 'Error Page',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './radio-button.less';

const componentName = 'radio-button';

/**
 * Trims passed string or if not string, returns the value passed.
 * @param {*} value
 * @return {*}
 * @inner
 */
function trim(value) {
    return angular.isString(value) ? value.trim() : value;
}

/** @alias radioButton **/
class RadioButtonController {
    constructor(
            $element,
            $attrs,
    ) {
        this.$element = $element;
        this.$attrs = $attrs;
    }

    /** @override */
    $onInit() {
        const {
            ngModelCtrl,
            $element,
            $attrs,
        } = this;

        $element.attr({
            role: 'radio',
            tabindex: '0',
        });

        // make the name unique, if not defined
        if (angular.isUndefined($attrs.name)) {
            $element.attr('name', _.random(0, 999999));
        }

        $element
            .on('click', this.onClick_.bind(this))
            .on('keydown', this.onKeyDown_.bind(this));

        ngModelCtrl.$render = () => {
            let { value } = $attrs;

            if (this.doTrim_()) {
                value = trim(value);
            }

            $element.attr('checked', value === ngModelCtrl.$viewValue);
        };

        $attrs.$observe('value', ngModelCtrl.$render);

        $element.addClass(componentName);
    }

    /**
     * Sets checked attribute on valid click or keydown event.
     * @param {MouseEvent|KeyboardEvent} e
     * @protected
     */
    onChange_(e) {
        const { $element } = this;

        if (!$element.attr('checked')) {
            const {
                ngModelCtrl,
                $attrs,
            } = this;

            $element.attr('checked', true);

            let { value } = $attrs;

            if (this.doTrim_()) {
                value = trim(value);
            }

            ngModelCtrl.$setViewValue(value, e && e.type);
        }
    }

    /**
     * Calls onChange_ on mouse click if input is enabled.
     * @param {MouseEvent} e
     * @protected
     */
    onClick_(e) {
        if (this.isDisabled_()) {
            return;
        }

        this.onChange_(e);
    }

    /**
     * Calls onChange_ on keydown event if input is enabled.
     * @param {KeyboardEvent} e
     * @protected
     */
    onKeyDown_(e) {
        if (this.isDisabled_()) {
            return;
        }

        switch (e.key) {
            case 'Enter': // enter
            case ' ': // space
                this.onChange_(e);
                e.stopPropagation();
                e.preventDefault();
                break;
        }
    }

    /**
     * Returns true if component is marked as disabled by ngDisabled directive.
     * @return {boolean}
     * @protected
     */
    isDisabled_() {
        return !!this.$element.attr('disabled');
    }

    /**
     * Returns true if ngTrim behavior is requested by ngTrim attribute.
     * @return {boolean}
     * @protected
     */
    doTrim_() {
        const { $attrs } = this;

        return !$attrs.ngTrim || trim($attrs.ngTrim) !== 'false';
    }
}

RadioButtonController.$inject = [
    '$element',
    '$attrs',
];

/**
 * @ngdoc component
 * @name radioButton
 * @author Alex Malitsky
 * @see angularJs input[radio]
 * @desc
 *
 *     Radio button form input element.
 */
//TODO create label component to provide regular label onClick behavior
angular.module('avi/component-kit').component('radioButton', {
    require: {
        ngModelCtrl: 'ngModel',
    },
    // Input tag is added to capture container/parent's click event. For example when this
    // component is wrapped under label we want to handle click event of label as well.
    template: '<input>',
    controller: RadioButtonController,
});

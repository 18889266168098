/**
 * Module containing system configuration components.
 * @preferred
 * @module SystemModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
    ClrTextareaModule,
} from '@clr/angular';
import { SharedModule } from 'ng/shared/shared.module';
import { EmailSmtpModalComponent } from '.';

const systemComponents = [
    EmailSmtpModalComponent,
];

@NgModule({
    declarations: [
        ...systemComponents,
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        ClrCheckboxModule,
        ClrInputModule,
        ClrTextareaModule,
        ClrFormsModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SystemModule {}

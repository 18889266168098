/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './authentication-rule-expander-content.component.less';

const componentName = 'authentication-rule-expander-content';

/**
 * @constructor
 * @memberOf module:avi/policies/sso
 * @mixes module:avi/policies/sso.authenticationRuleExpanderContentComponentBindings
 * @see {@link module:avi/policies/sso.authenticationRuleExpanderContentComponent}
 */
class AuthenticationRuleExpanderContentController {
    constructor(schemaService) {
        /**
         * @type {module:avi/policies/sso.AuthenticationMatchConfigItem|null}
         * @protected
         */
        this.match_ = null;

        /**
         * Array containing supported match fields except 'client_ip'
         * @type {string[]}
         */
        this.matchFields = [];

        /**
         * @type {EnumValue[]}
         * @protected
         */
        this.actionEnums_ = schemaService.getEnumValues('AuthenticationActionEnum');

        /**
         * Selected Authentication action enumvalue
         * @type {EnumValue|null}
         */
        this.authAction = null;
    }

    /** @override */
    $onInit() {
        this.updateDisplayData_();
    }

    /** @override */
    $onChanges(changes) {
        const isFirstChange = _.sample(changes).isFirstChange();

        if (!isFirstChange) {
            this.updateDisplayData_();
        }
    }

    /**
     * Updated UI Fields - matchFields and action values.
     */
    updateDisplayData_() {
        this.match_ = this.rule.config.match;

        // Pick only the fields that are configured in rule
        this.matchFields = this.match_ ?
            this.match_.fields.filter(field => this.hasMatch(field)) : [];

        this.authAction = _.findWhere(this.actionEnums_, { value: this.rule.actionType });
    }

    /**
     * Method to filter out unused matches.
     * @returns {boolean} True, if Rule is configured with the match field.
     */
    hasMatch(field) {
        return this.match_.hasMatchByField(field);
    }

    /**
     * Returns value for a match field.
     * @param {string} field Any one of the supportedMatches (path/client_ip/host_hdr)
     * @returns {string}
     */
    getMatchValue(field) {
        const {
            config: matchConfig,
        } = this.match_;

        return field === 'client_ip' ?
            matchConfig.client_ip.flatDataToString() :
            matchConfig[field];
    }

    /**
     * Returns label for a match field.
     * @param {string} field Any one of the supportedMatches (path/client_ip/host_hdr)
     * @returns {string}
     */
    getMatchLabel(field) {
        return this.match_.getMatchLabelByField(field);
    }
}

AuthenticationRuleExpanderContentController.$inject = [
    'schemaService',
];

/**
 * @name authenticationRuleExpanderContentComponent
 * @memberOf module:avi/policies/sso
 * @property {module:avi/policies/sso.AuthenticationRuleExpanderContentController} controller
 * @property {module:avi/policies/sso.authenticationRuleExpanderContentComponentBindings} bindings
 * @description
 *      Authentication Rule Expander Component.
 * @author Aravindh Nagarajan
 */
angular.module('avi/policies/sso').component('authenticationRuleExpanderContent', {
    /**
     * @mixin authenticationRuleExpanderContentComponentBindings
     * @memberOf module:avi/policies/sso
     * @property {module:avi/policies/sso.AuthenticationRuleConfigItem} rule
     */
    bindings: {
        rule: '<',
    },
    controller: AuthenticationRuleExpanderContentController,
    templateUrl: 'src/components/modals/sso-policy-modal/authentication-policy/' +
        'authentication-rules-list/authentication-rule/' +
        `${componentName}/${componentName}.component.html`,
});

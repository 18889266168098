/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name PoolGroupDeploymentPolicy
 * @description
 *     Pool Group Deployment Policy collection item.
 */
angular.module('aviApp').factory('PoolGroupDeploymentPolicy', ['Item', function(Item) {
    class PoolGroupDeploymentPolicy extends Item {
        /**
         * Returns the list of rules in pool group deployment policy.
         * @returns {Object[]}
         * @public
         */
        getRules() {
            return this.getConfig().rules;
        }

        /**
         * Adds a rule to pool group deployment policy with default configs.
         * @public
         */
        addRule() {
            const rules = this.getRules();

            rules.push(angular.copy(PoolGroupDeploymentPolicy.defaultRuleConfig));
        }

        /**
         * Removes a rule from pool group policy deployment by index.
         * @param {number} index
         * @public
         */
        removeRule(index) {
            const rules = this.getRules();

            rules.splice(index, 1);
        }

        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            if (!('rules' in config)) {
                config['rules'] = [];
            }
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.data.config);

            if (!config.rules.length) {
                config['rules'] = undefined;
            }

            return config;
        }
    }

    PoolGroupDeploymentPolicy.defaultRuleConfig = {
        metric_id: '',
        operator: 'CO_LE',
        threshold: '',
    };

    angular.extend(PoolGroupDeploymentPolicy.prototype, {
        objectName: 'poolgroupdeploymentpolicy',
        windowElement_: 'pool-group-deployment-policy-modal',
    });

    return PoolGroupDeploymentPolicy;
}]);

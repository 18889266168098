/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { WafPolicyAllowlistRuleConfigItem } from 'ajs/modules/waf';
import './waf-policy-allowlist-rules-list.less';

/**
 * @desc Component for displaying a WAF Policy Allowlist rule in the WAF Policy modal.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-allowlist-rules-list',
    templateUrl: './waf-policy-allowlist-rules-list.component.html',
})
export class WafPolicyAllowlistRulesListComponent {
    @Input() public rules: RepeatedMessageItem<WafPolicyAllowlistRuleConfigItem>;

    @Output() public onEdit = new EventEmitter();

    /**
     * Handler for editing a allowlist rule.
     */
    public handleEdit(rule: WafPolicyAllowlistRuleConfigItem): void {
        this.onEdit.emit(rule);
    }

    /**
     * Handler for deleting a allowlist rule.
     * @param index - Index of the allowlist rule to remove.
     */
    public handleDeleteRule(index: number): void {
        this.rules.remove(index);
    }

    /**
     * Handler for the drag-and-drop event.
     */
    public handleDrop(dragEvent: CdkDragDrop<void>): void {
        const { previousIndex, currentIndex } = dragEvent;
        const rule = this.rules.at(previousIndex);

        this.rules.moveItem(rule, currentIndex);
    }
}

/**
 * Module containing notification components
 * @preferred
 * @module NotificationModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { VIPModule } from '@vmw/ngx-vip';
import {
    AviNotificationComponent,
    AviNotificationsPortalComponent,
    CloudStatusComponent,
    CloudTokenComponent,
    ControllerFaultsComponent,
    MessageOfTheDayComponent,
    UtcMismatchNotificationComponent,
    VSFaultsComponent,
} from '.';

const notificationComponents = [
    AviNotificationComponent,
    AviNotificationsPortalComponent,
    CloudStatusComponent,
    CloudTokenComponent,
    ControllerFaultsComponent,
    MessageOfTheDayComponent,
    UtcMismatchNotificationComponent,
    VSFaultsComponent,
];

@NgModule({
    declarations: [
        ...notificationComponents,
    ],
    imports: [
        CommonModule,
        VIPModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class NotificationModule { }

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function alertDataTransformerFactory(DataTransformer, itemAlertDataTransform) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.DataTransformer
     * @author Ashish Verma
     * @desc dataTransformer of {@link module:avi/app.AlertDataTransformer
     *       AlertDataTransformer}.
     */
    class AlertDataTransformer extends DataTransformer {
        /**
         * Model is executing this function to ask the field to participate in a call group.
         * @param data - model data hash, used to figure out the earliest start time for the call
         *     and also may do other preparations.
         * @param groupCall - the object that the fields put their parameters in to participate
         *     in the request.
         */
        beforeCall(data, groupCall) {
            if (groupCall.inventory) {
                groupCall.inventory.include.alert = true;
            }

            return null;
        }

        /**
         * Model is executing this function when the call already happened and the purpose of
         * the call is to share the response data between the fields each field is taking
         * whatever data it needs to generate the right value.
         * @param data - model data hash, here used to update fields value
         * @param groupCall - the group call object, at this point it contains server
         *     response that the field want to access to generate value.
         */
        afterCall(data, groupCall) {
            // Just place the result into the data
            if (groupCall.inventory && groupCall.inventory.response) {
                data.alert = groupCall.inventory.response.alert;

                if (data.alert) {
                    itemAlertDataTransform(data.alert);
                }
            }
        }
    }

    AlertDataTransformer.prototype.name = 'alert';

    return AlertDataTransformer;
}

alertDataTransformerFactory.$inject = [
    'DataTransformer',
    'itemAlertDataTransform',
];

angular.module('avi/app').factory('AlertDataTransformer', alertDataTransformerFactory);

/**
 * @module IPReputationDBModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { IHttpResponse } from 'angular';
import { Component, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { L10nService } from '@vmw/ngx-vip';

import { StringService } from 'ajs/modules/core';
import { IPReputationDB } from 'ajs/modules/ip-reputation-db';
import * as l10n from './ip-reputation-search-record-card.l10n';
import './ip-reputation-search-record-card.component.less';
import {
    IIPReputationSearchApiResult,
    IPReputationSearchRecordCardService,
} from './ip-reputation-search-record-card.service';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for a card to search records
 * @author Nisar Nadaf
 */
@Component({
    selector: 'ip-reputation-search-record-card',
    templateUrl: './ip-reputation-search-record-card.component.html',
    providers: [
        IPReputationSearchRecordCardService,
    ],
})
export class IPReputationSearchRecordCardComponent {
    /**
     * IP Reputation DB item.
     */
    @Input()
    public ipReputationDB: IPReputationDB;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * If true, shows a spinner under the options.
     */
    public busy = false;

    /**
     * Errors to be displayed if an error occurs after search.
     */
    public errors: HttpErrorResponse;

    /**
     * Store search input field value
     */
    public searchValue: string;

    /**
     * Contains result for searched ip address.
     */
    public searchResult: IIPReputationSearchApiResult;

    /**
     * Stores searched value.
     */
    public searchedIPAddress: string;

    constructor(
        l10nService: L10nService,
        private readonly ipReputationSearchRecordCardService : IPReputationSearchRecordCardService,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Search IP address in IP reputation DB
     */
    public search(input: string): void {
        const { id } = this.ipReputationDB;

        // If user cleared input and press enter then clear the result
        if (!input) {
            this.clearInput();

            return;
        }

        this.busy = true;
        this.errors = undefined;
        this.searchResult = undefined;
        this.searchedIPAddress = input;
        this.ipReputationSearchRecordCardService.search(input, id)
            .then(({ data }: IHttpResponse<IIPReputationSearchApiResult>) => {
                this.searchResult = data;
            }).catch(({ data }) => {
                this.errors = data;
            }).finally(() => this.busy = false);
    }

    /**
     * Gets description from enum prop to display threat type.
     */
    public getIPReputationType(threatType: string): string {
        return this.stringService.enumeration(
            threatType,
            'IP_REPUTATION_TYPE_',
        );
    }

    /**
     * Clears the search field
     */
    public clearInput(): void {
        this.searchValue = undefined;
    }

    /**
     * Callback to ngFor-track by.
     */
    public trackbyIndex(index: number): number {
        return index;
    }
}

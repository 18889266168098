/**
 * @module VSModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { L10nService } from '@vmw/ngx-vip';
import { IVHMatch, StringOperation } from 'generated-types';
import * as Regex from 'ng/utils/regex.utils';
import template from './vs-enhanced-vh-child-hosts.component.html';
import './vs-enhanced-vh-child-hosts.component.less';
import * as l10n from './vs-enhanced-vh-child-hosts.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TRegex = typeof Regex;

class VSEnhancedVHChildHostsController {
    /**
     * VirtualService item. TODO: change "any" to the VirtualService class once it's been converted
     * to TS.
     */
    public vs: any;

    /**
     * List of string operations for the PathMatch that are not supported in configuring a VH
     * Enhanced child VS.
     */
    public readonly unsupportedPathMatchStringOperations = [
        StringOperation.CONTAINS,
        StringOperation.DOES_NOT_CONTAIN,
        StringOperation.ENDS_WITH,
        StringOperation.DOES_NOT_END_WITH,
    ];

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        public regex: TRegex,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Adds a VH Match, which contains a host and a PathMatch.
     */
    public addDomain(): void {
        this.vs.addEnhancedVHMatch();
    }

    /**
     * Removes a VH Match.
     */
    public removeDomain(matchIndex: number): void {
        this.vs.removeEnhancedVHMatch(matchIndex);
    }

    /**
     * Called to add a PathMatch to a VH Match.
     */
    public addPathMatch(matchIndex: number): void {
        this.vs.addEnhancedVHPathMatch(matchIndex);
    }

    /**
     * Called to remove a PathMatch from a VH Match.
     */
    public removePathMatch(matchIndex: number, pathMatchIndex: number): void {
        this.vs.removeEnhancedVHPathMatch(matchIndex, pathMatchIndex);
    }

    /**
     * Returns the list of VH Match.
     */
    public get vhMatches(): IVHMatch[] {
        return this.vs.getConfig().vh_matches;
    }
}

VSEnhancedVHChildHostsController.$inject = [
    'Regex',
    'l10nService',
];

/**
 * @description Component for configuring VH Enhanced Child VS settings. Contains a repeated list of
 *     host and path match pairs.
 * @author alextsg
 */
export const vsEnhancedVHChildHostsOptions = {
    bindings: {
        vs: '<',
    },
    controller: VSEnhancedVHChildHostsController,
    template,
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './protocol-parser-scripts-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const componentName = 'protocol-parser-scripts-list';

class ProtocolParserScriptsListController {
    constructor(
        $element,
        ProtocolParserCollection,
        CRUDGridConfig,
        l10nService,
    ) {
        this.$element_ = $element;
        this.ProtocolParserCollection_ = ProtocolParserCollection;
        this.CRUDGridConfig_ = CRUDGridConfig;

        /**
        * Get keys from source bundles for template usage.
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
        this.l10nService_ = l10nService;
    }

    $onInit() {
        this.$element_.addClass(componentName);

        const { l10nService_: l10nService } = this;

        const gridConfigOptions = {
            collection: new this.ProtocolParserCollection_(),
            fields: [{
                name: 'name',
                title: l10nService.getMessage(l10nKeys.columnTitleName),
                template: '{{row.getName()}}',
                sortBy: 'name',
            }],
            permission: 'PERMISSION_PROTOCOLPARSER',
        };

        const { objectName } = gridConfigOptions.collection;

        gridConfigOptions.id = `${objectName}-list-page`;

        this.gridConfig = new this.CRUDGridConfig_(gridConfigOptions);
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

ProtocolParserScriptsListController.$inject = [
    '$element',
    'ProtocolParserCollection',
    'CRUDGridConfig',
    'l10nService',
];

/**
 * @ngdoc component
 * @name ProtocolParserScriptsList
 * @author Aravindh Nagarajan
 * @description Protocol Parser Scripts list page with options to create/delete/edit.
 */
angular.module('aviApp').component('protocolParserScriptsList', {
    controller: ProtocolParserScriptsListController,
    template:
        `<div class="templatesList">
            <collection-grid config="$ctrl.gridConfig"></collection-grid>
        </div>`,
});

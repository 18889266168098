/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name PKIProfileCollection
 */
angular.module('aviApp').factory('PKIProfileCollection', [
'Collection', 'PKIProfile',
function(Collection, PKIProfile) {
    class PKIProfileCollection extends Collection {}

    angular.extend(PKIProfileCollection.prototype, {
        objectName_: 'pkiprofile',
        itemClass_: PKIProfile,
        windowElement_: 'prof-pki-profile-create',
    });

    return PKIProfileCollection;
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import angular from 'angular';

import { each } from 'underscore';

import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import { Item } from 'ajs/modules/data-model';

/**
 * @constructor
 * @memberOf module:avi/app
 * @alias PersistenceProfile
 * @description PersistenceProfile Item.
 * @author Alex Malitsky, Aravindh Nagarajan
 */
class PersistenceProfile extends Item {
    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'applicationpersistenceprofile',
            windowElement: 'persistence-profile-modal',
        };

        super(extendedArgs);
    }

    /**
     * Returns profile type as enum value.
     * @returns {string}
     */
    getType() {
        return this.getConfig().persistence_type;
    }

    /** @override */
    dataToSave() {
        const config = angular.copy(this.getConfig());
        const actualType = this.getType();
        const actualPropName = PersistenceProfile.typeToConfigPropNameHash[actualType];

        each(PersistenceProfile.allConfigPropNames, propName => {
            if (propName !== actualPropName) {
                delete config[propName];
            }
        });

        this.setIsFederatedFlag_();

        return config;
    }

    /**
     * Event handler for type change event. Used by edit modal.
     */
    onTypeChange() {
        const config = this.getConfig();

        PersistenceProfile.allConfigPropNames
            .forEach(propName => delete config[propName]);

        const
            defaultConfig = this.getDefaultConfig_(),
            type = this.getType();

        const propName = PersistenceProfile.typeToConfigPropNameHash[type];

        config[propName] = defaultConfig[propName];

        this.setIsFederatedFlag_();
    }

    /**
     * Resets 'is_federated' field to false for all types but GSLB.
     * @private
     */
    setIsFederatedFlag_() {
        this.getConfig().is_federated = this.getType() === 'PERSISTENCE_TYPE_GSLB_SITE';
    }
}

/**
 *
 * @type {{string: string}}
 * @static
 */
PersistenceProfile.typeToConfigPropNameHash = {
    PERSISTENCE_TYPE_CLIENT_IP_ADDRESS: 'ip_persistence_profile',
    PERSISTENCE_TYPE_CUSTOM_HTTP_HEADER: 'hdr_persistence_profile',
    PERSISTENCE_TYPE_APP_COOKIE: 'app_cookie_persistence_profile',
    PERSISTENCE_TYPE_HTTP_COOKIE: 'http_cookie_persistence_profile',
    PERSISTENCE_TYPE_GSLB_SITE: 'http_cookie_persistence_profile',
};

/**
 * @type {string[]}
 * @static
 */
PersistenceProfile.allConfigPropNames = _.values(PersistenceProfile.typeToConfigPropNameHash);

initAjsDependency(
    angular.module('avi/app'),
    'factory',
    'PersistenceProfile',
    PersistenceProfile,
);

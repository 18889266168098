/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { createDropdownOption } from 'ng/utils/dropdown.utils';

/**
 * @typedef {Object} poolMemberVsHash
 * @property {GSLBVSCollection} GSLBVSCollection
 * @property {Object.<string, string>} names - vsip as keys  and VS name as value
 * @property {Object.<string, IpAddr.addr[]>} ips - vsip as keys and ips array as value
 **/

/**
 * @ngdoc directive
 * @name gslbPoolMemberForm
 * @author Alex Malitsky
 * @restrict E
 *
 * @param {GslbPoolConfig} config - Config of GslbPool we are working on.
 * @param {GSLBService} gslbService - GslbService Item, not updated directly by this
 *     directive. But some of it's methods are used.
 * @param {string=} basicEditMode - This directive is working with one GslbPool members, but when
 *     mode is `active-standby` later we want to create a new group for each member, meaning
 *     that here we have to use an extra input for {@link GslbPoolMember#priority_}.
 *
 * @description
 *
 *     Each GslbPool has a list of members. We use this directive to manage this
 *     list and to set up gslbPool member's properties. Uses GSLB configuration.
 */
angular.module('avi/gslb').directive('gslbPoolMemberForm', [
'GSLBVSCollection', 'Regex',
function(GSLBVSCollection, Regex) {
    function link(scope) {
        scope.Regex = Regex;

        /**
         * Each GslbSite of GSLB configuration has
         * it's own collection of VirtualServices (which differ by `headers_` param), this hash
         * keeps them all. We need have GSLB configuration loaded to get the list of configured
         * sites.
         * @type {{string: GSLBVSCollection}}
         * @public
         */
        scope.vsCollections = {};

        const { vsCollections } = scope;

        scope.gslbService.getPoolMemberVsData()
            .then(setVSVipOptionsAndVSUuid)
            .then(() => {
                setTimeout(() => scope.gslbService.setPristine());
            });

        /**
         * Set vsVipIPOptionsMap and vs_uuid from config
         * @param {poolMemberVsHash} poolMemberVsHash
         */
        function setVSVipOptionsAndVSUuid(poolMemberVsHash) {
            const { members } = scope.config;

            members.forEach(member => {
                const { cluster_uuid: clusterId } = member;

                const vsId = member?.vs_uuid?.slug();
                let clusterData;

                if (clusterId && vsId && (clusterData = poolMemberVsHash[clusterId])) {
                    const clusterName = member.vs_uuid.split('#');

                    if (vsId in clusterData.names && clusterName.length <= 1) {
                        member.vs_uuid += `#${clusterData.names[vsId]}`;
                    }

                    if (vsId in clusterData.ips) {
                        const ips = clusterData.ips[vsId];

                        addMemberVSVipIPOptionsToMap(ips, member);
                    }
                }
            });
        }

        /**
         * Array of strings where cluster_uuid is concatenated with hash
         * symbol and it's name. Will be populated once GSLB config is loaded.
         * @type {Array<string>}
         * @public
         */
        //gslb is supposed to be loaded
        scope.sites = scope.gslbService.getGSLB().getSites();

        const { sites } = scope;

        const nonAviSites = scope.gslbService.getGSLB().getNonAviSites();

        scope.nonAviSitesOptions = nonAviSites.map(nonAviSite => createDropdownOption(
            nonAviSite.slug(),
            nonAviSite.name(),
        ));

        const isCreatable = () => false;

        sites.forEach(siteRef => {
            const gslbSiteId = siteRef.slug();

            vsCollections[gslbSiteId] = new GSLBVSCollection({
                gslbSiteId,
                params: {
                    fields: ['vsvip_ref', 'vh_parent_vs_ref', 'type'].join(),
                    join: 'vsvip_ref',
                },
            });

            vsCollections[gslbSiteId].isCreatable = isCreatable;
        });

        /**
         * WeakMap to store vsIP dropdown option per member
         * @inner
         */
        const vsVipIPOptionsMap = new WeakMap();

        /**
         * create Vs VIPOptions for given members
         * @param {string[]} ips
         * @param {GslbPoolMemberConfig} member
         * @private
         */
        function addMemberVSVipIPOptionsToMap(ips, member) {
            const memberIpOptions = ips.map(ip => createDropdownOption(ip));

            vsVipIPOptionsMap.set(member, memberIpOptions);
        }

        /**
         * get VsVIP options for dropdown
         * @param {GslbPoolMemberConfig} member
         * @returns {DropDownOption[]}
         * @public
         */
        scope.getVsVipIPOptions = function(member) {
            return vsVipIPOptionsMap.get(member) || [];
        };

        /**
         * Event handler for layout switch between direct IP address input versus cluster_uuid &
         * vs_uuid selectors.
         * @param {GslbPoolMemberConfig} member
         * @public
         */
        scope.isSetByIpChange = function(member) {
            if (member.isSetByIp) {
                delete member['cluster_uuid'];
                delete member['vs_uuid'];
                vsVipIPOptionsMap.delete(member);
            } else {
                delete member['fqdn'];
                member['ip'].addr = '';
            }
        };

        /**
         * Event handler for the GslbSite dropdown select or clear events.
         * @param {GslbPoolMemberConfig} member
         * @public
         */
        scope.onSiteSelect = function(member) {
            member['vs_uuid'] = undefined;
            member['ip'].addr = '';
            vsVipIPOptionsMap.delete(member);
        };

        /**
         * Event handler for the VS dropdown select event. We need to set the
         * GslbPoolMemberConfig#ip taking it from the selected VS configuration.
         * @param {GslbPoolMemberConfig} member
         * @public
         */
        scope.onSiteVSSelect = function(member) {
            vsVipIPOptionsMap.set(member, []);
            member['ip'].addr = '';

            const {
                vs_uuid: vsId,
                cluster_uuid: clusterId,
            } = member;

            if (!angular.isUndefined(vsId)) {
                const vs = vsCollections[clusterId].getItemById(vsId.slug());

                //In case vs is a child:
                if (vs.isVHChild()) {
                    const headerParam = { headers_: { 'X-Avi-Internal-GSLB': clusterId } };

                    vs.addParams(headerParam);

                    vs.getVHParentIPs('allIPs')
                        .then(parentIPs => assignMemberIPs(parentIPs, member))
                        .catch(console.error);
                } else {
                    assignMemberIPs(vs.getIPAddresses('allIPs'), member);
                }
            }
        };

        /**
         * Handles assigning of IPs for given vs.
         * @param {IpAddr.addr} ips - list of ips
         * @param {GslbPoolMemberConfig} member
         * @private
         */
        function assignMemberIPs(ips, member) {
            if (ips.length) {
                [member['ip'].addr] = ips;

                addMemberVSVipIPOptionsToMap(ips, member);
            }
        }

        /**
         * `Add Member` button click event handler.
         */
        scope.addMember = function() {
            //for a-s we put each members into a separate group meaning no more than 20 can be added
            if (scope.basicEditMode !== 'active-standby' || scope.config['members'].length < 99) {
                scope.config['members'].push(scope.gslbService.getDefaultPoolMemberConfig());
            }
        };

        scope.$on('$destroy', () => {
            _.each(vsCollections, collection => collection.destroy());
        });
    }

    return {
        restrict: 'E',
        scope: {
            config: '=',
            gslbService: '<',
            basicEditMode: '<',
        },
        link,
        templateUrl: 'src/views/partials/application/gslb-pool-member-form.html',
    };
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './dns-app-profile-domain-names.component.less';
import * as l10n from './dns-app-profile-domain-names.l10n';

const componentName = 'dns-app-profile-domain-names';
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @constructor
 * @memberOf module:avi/profiles/application
 * @mixes module:avi/profiles/application.dnsAppProfileDomainNamesComponentBindings
 */
class DnsAppProfileDomainNamesController {
    constructor(Regex, l10nService) {
        this.Regex = Regex;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }
}

DnsAppProfileDomainNamesController.$inject = [
    'Regex',
    'l10nService',
];

/**
 * @name dnsAppProfileDomainNamesComponent
 * @memberOf module:avi/profiles/application
 * @property {module:avi/profiles/application.dnsAppProfileDomainNamesComponentBindings} bindings
 * @property {module:avi/profiles/application.DnsAppProfileDomainNamesController} controller
 * @description
 *      Domains/Sub-domain names component.
 *      Used in DNS Application profile.
 * @author Aravindh Nagarajan
 */
angular.module('avi/profiles/application').component('dnsAppProfileDomainNames', {
    /**
     * @mixin dnsAppProfileDomainNamesComponentBindings
     * @memberOf module:avi/profiles/application
     * @property {Object} dnsApplicationProfile
     */
    bindings: {
        dnsApplicationProfile: '<',
    },
    controller: DnsAppProfileDomainNamesController,
    templateUrl:
        'src/components/modals/templates/profiles/application-profile/' +
        `${componentName}/${componentName}.component.html`,
});

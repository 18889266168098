const moduleName = 'ajs-legacy';
const componentName = 'protocol-parser-modal';
const prefix = `${moduleName}.${componentName}`;

export const editProtocolParserScriptModalHeader = `${prefix}.editProtocolParserScriptModalHeader`;
export const newProtocolParserScriptModalHeader = `${prefix}.newProtocolParserScriptModalHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const parserCodeInputLabel = `${prefix}.parserCodeInputLabel`;

export const ENGLISH = {
    [editProtocolParserScriptModalHeader]: 'Edit ProtocolParserScript: {0}',
    [newProtocolParserScriptModalHeader]: 'New ProtocolParserScript: {0}',
    [nameInputLabel]: 'Name',
    [descriptionInputLabel]: 'Description',
    [parserCodeInputLabel]: 'Parser code',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function applicationProfileCollectionFactory(
    Collection,
    ApplicationProfile,
) {
    /**
     * @constructor
     * @memberOf module:avi/profiles/application
     * @extends module:avi/dataModel.Collection
     */
    class ApplicationProfileCollection extends Collection {}

    Object.assign(ApplicationProfileCollection.prototype, {
        objectName_: 'applicationprofile',
        itemClass_: ApplicationProfile,
        windowElement_: 'application-profile-modal',
    });

    ApplicationProfileCollection.prototype.serverDefaultsOverride_ = {
        type: 'APPLICATION_PROFILE_TYPE_HTTP',
    };

    return ApplicationProfileCollection;
}

applicationProfileCollectionFactory.$inject = [
    'Collection',
    'ApplicationProfile',
];

angular.module('avi/profiles/application')
    .factory('ApplicationProfileCollection', applicationProfileCollectionFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'security';
const componentName = 'ssl-tls-certificate-ocsp';
const prefix = `${moduleName}.${componentName}`;

export const ocspStaplingCheckboxLabel = `${prefix}.ocspStaplingCheckboxLabel`;
export const ocspResponderUrlFromCertificatesListLabel = `${prefix}.ocspResponderUrlFromCertificatesListLabel`;
export const frequencyIntervalInputLabel = `${prefix}.frequencyIntervalInputLabel`;
export const responseTimeoutInputLabel = `${prefix}.responseTimeoutInputLabel`;
export const failJobIntervalInputLabel = `${prefix}.failJobIntervalInputLabel`;
export const maxTriesInputLabel = `${prefix}.maxTriesInputLabel`;
export const ocspResponderUrlListLabel = `${prefix}.ocspResponderUrlListLabel`;
export const addOcspResponderUrlButtonLabel = `${prefix}.addOcspResponderUrlButtonLabel`;
export const responderUrlActionInputLabel = `${prefix}.responderUrlActionInputLabel`;

export const ENGLISH = {
    [ocspStaplingCheckboxLabel]: 'Enable OCSP Stapling',
    [ocspResponderUrlFromCertificatesListLabel]: 'OCSP Responder URL from Certificates List',
    [frequencyIntervalInputLabel]: 'Frequency Interval',
    [responseTimeoutInputLabel]: 'Response Timeout',
    [failJobIntervalInputLabel]: 'Fail Job Interval',
    [maxTriesInputLabel]: 'Max Tries',
    [ocspResponderUrlListLabel]: 'OCSP Responder URL List',
    [addOcspResponderUrlButtonLabel]: 'Add OCSP Responder URL',
    [responderUrlActionInputLabel]: 'Responder URL Action',
};

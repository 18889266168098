/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'error-page-profile';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleErrorPages = `${prefix}.columnTitleErrorPages`;
export const totalPagesLabel = `${prefix}.totalPagesLabel`;
export const statusCodesLabel = `${prefix}.statusCodesLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleErrorPages]: 'Error Pages',
    [totalPagesLabel]: 'Total Pages: {0}',
    [statusCodesLabel]: 'Status Codes: {0}',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'ssl-certificate-export';
const prefix = `${moduleName}.${componentName}`;

export const exportCertificateHeader = `${prefix}.exportCertificateHeader`;
export const configurationHeader = `${prefix}.configurationHeader`;
export const sslKeyInputLabel = `${prefix}.sslKeyInputLabel`;
export const copyToClipboardButtonLabel = `${prefix}.copyToClipboardButtonLabel`;
export const sslCertificateInputLabel = `${prefix}.sslCertificateInputLabel`;
export const doneButtonLabel = `${prefix}.doneButtonLabel`;

export const ENGLISH = {
    [exportCertificateHeader]: 'Export Certificate: {0}',
    [configurationHeader]: 'Configuration',
    [sslKeyInputLabel]: 'Key',
    [copyToClipboardButtonLabel]: 'Copy to clipboard',
    [sslCertificateInputLabel]: 'Certificate',
    [doneButtonLabel]: 'Done',
};

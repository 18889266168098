/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import * as patterns from 'ng/utils/regex.utils';

/**
 * Validates a value against a regex pattern.
 */
export function regexPatternValidator(regexPatternName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!(regexPatternName in patterns)) {
            throw new Error(`regexPatternName ${regexPatternName} not found.`);
        }

        const { value } = control;
        const valid = patterns[regexPatternName].test(value);

        return valid ? null : { regexPattern: { value } };
    };
}

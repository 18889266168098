/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { IWafPSMMatchElement } from 'generated-types';
import { IMessageItemData, MessageItem } from 'ajs/modules/data-model';

interface IWafPSMMatchElementData extends IMessageItemData {
    config: IWafPSMMatchElement;
}

export class WafPSMMatchElementConfigItem extends MessageItem {
    public data: IWafPSMMatchElementData;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'WafPSMMatchElement',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns a list of values that should be searchable.
     */
    public get searchableValues(): string[] {
        const { name, sub_element: subElement } = this.config;

        return [
            name,
            subElement,
        ];
    }

    /** @override */
    public canFlatten(): boolean {
        return Boolean(this.config.name);
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'cloud';
const componentName = 'vcenter-server-edit-credentials-dialog';
const prefix = `${moduleName}.${componentName}`;

export const setVcenterServerCredentialsHeader = `${prefix}.setVcenterServerCredentialsHeader`;
export const vCenterAddressLabel = `${prefix}.vCenterAddressLabel`;
export const vCenterCredentialsSelectLabel = `${prefix}.vCenterCredentialsSelectLabel`;
export const vCenterCredentialsSelectPlaceholder = `${prefix}.vCenterCredentialsSelectPlaceholder`;

export const ENGLISH = {
    [setVcenterServerCredentialsHeader]: 'Set vCenter Server Credentials',
    [vCenterAddressLabel]: 'vCenter Address',
    [vCenterCredentialsSelectLabel]: 'vCenter Credentials',
    [vCenterCredentialsSelectPlaceholder]: 'Select vCenter Credentials',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const wafPolicyAllowlistFactory = MessageItem => {
    /**
     * @alias module:config-item/WafPolicyAllowlistConfigItem
     * @private
     */
    class WafPolicyAllowlistConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'WafPolicyAllowlist',
                ...args,
            };

            super(extendedArgs);
        }

        /**
         * @override
         */
        canFlatten() {
            return !this.config.rules.isEmpty();
        }

        /**
         * Returns the number of rules in the config.
         * @returns {number}
         */
        getRulesCount() {
            return this.config.rules.count;
        }

        /**
         * Returns a new WafPolicyAllowlistRule ConfigItem instance.
         * @param {Object} [config=null] - Config object.
         * @return {WafPolicyAllowlistRule}
         */
        createNewRule(config = null) {
            return this.createChildByField_('rules', config, true);
        }

        /**
         * Adds a rule to the list of rules. If the rule has an index, it means that an existing
         * rule was modified so the existing rule should be replaced. If not, add the new rule to
         * the end of the list.
         * @param {WafPolicyAllowlistRule} rule - Rule to add.
         */
        addRule(rule) {
            const { rules } = this.config;
            const indexField = rule.getIndex();

            // New rule doesn't have an index.
            if (_.isUndefined(indexField)) {
                const maxIndex = rules.getMaxIndex();
                const newIndex = _.isNaN(+maxIndex) ? 0 : maxIndex + 1;

                rule.setIndex(newIndex);
                rules.add(rule);
            } else {
                const arrayIndex = rules.getArrayIndexWithIndexField(indexField);

                rules.config[arrayIndex] = rule;
            }
        }
    }

    return WafPolicyAllowlistConfigItem;
};

wafPolicyAllowlistFactory.$inject = [
    'MessageItem',
];

/**
 * @ngdoc factory
 * @name  WafPolicyAllowlistConfigItem
 * @description  WafPolicyAllowlist ConfigItem class.
 * @module config-item/WafPolicyAllowlistConfigItem
 * @author alextsg
 */
angular
    .module('avi/waf')
    .factory('WafPolicyAllowlistConfigItem', wafPolicyAllowlistFactory);

/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { Component, Input } from '@angular/core';
import './avi-label-with-tooltip.less';

@Component({
    selector: '[aviLabelWithTooltip]',
    templateUrl: './avi-label-with-tooltip.component.html',
})
export class AviLabelWithTooltipComponent {
    /**
     * @param tooltipText - Custom text unrelated to Schema to be shown.
     */
    @Input() public tooltipText = '';

    /**
     * @param objectType - The Schema object type that the field belongs to.
     */
    @Input() public objectType = '';

    /**
     * @param fieldName - The Schema field that contains the description text.
     */
    @Input() public fieldName = '';

    /**
     * @param hideRange - If true, hides the range text.
     */
    @Input() public hideRange = false;

    /**
     * @param enumType - The Schema enum type that the enum value belongs to.
     */
    @Input() public enumType = '';

    /**
     * @param enumValue - The Schema enum value that maps to contains the description text.
     */
    @Input() public enumValue = '';

    /**
     * @param for - Label "for" attribute.
     */
    @Input() public for = '';

    /**
     * @param required - If the required binding exists or is equal to true, set this.required to
     *     true.
     */
    @Input('required') private set setRequired(required: boolean | '') {
        this.required = required === '' || required;
    }

    public required = false;
}

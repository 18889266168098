/** @module GslbModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { IMessageItemData, Item } from 'ajs/modules/data-model';
import { StringService } from 'ajs/modules/core';
import { IHttpResponse, IPromise } from 'angular';
import {
    GslbSiteCfgSyncState,
    IGslb,
    IGslbSite,
    SiteMemberType,
} from 'generated-types';
import { GSLBSiteCollection } from './gslb-site.collection.factory';

interface IGslbSiteConfig extends IGslbSite {
    isLeader?: boolean;
    isNonAviSite: boolean;
}

interface IGslbSiteData extends IMessageItemData {
    config: IGslbSite;
}

interface IGslbReplicationStatus {
    syncState: GslbSiteCfgSyncState;
    isReplicationPullEnabled: boolean;
}

/**
 * @description
 *     GSLBSite is the nested structure of GSLB config, hence there is no direct API. These
 *     items are created by collection which is supposed to have a loaded GSLB Item.
 *
 * @author Alex Malitsky, Akul Aggarwal, Ram Pal
 */
export class GSLBSite extends Item {
    public getConfig: () => IGslbSiteConfig;
    public collection: GSLBSiteCollection;

    /**
     * Override runtime data type.
     * @override
     */
    public getRuntimeData: () => any;

    /**
     * Returns true if member is `active`, false otherwise.
     */
    public isActiveMember() : boolean {
        const config = this.getConfig();

        if (config) {
            return config.member_type === SiteMemberType.GSLB_ACTIVE_MEMBER;
        }
    }

    /**
     * Returns boolean flag whether this GslbSite is the GSLB leader.
     */
    public isLeader(): boolean {
        return !!this.getConfig()?.isLeader;
    }

    /** @override */
    public isEditable(): boolean {
        const gslb = this.collection && this.collection.gslb;

        return gslb ? gslb.isEditable() : false;
    }

    /** @override */
    public isDroppable(): boolean {
        const gslb = this.collection && this.collection.gslb;

        return gslb ? gslb.isEditable() && !this.isLeader() : false;
    }

    /**
     * Returns GslbSite enabled/disabled state.
     */
    public isEnabled(): boolean {
        return this.getConfig().enabled;
    }

    /** @override */
    public drop(): IPromise<IHttpResponse<IGslb>> {
        const { collection } = this;

        if (collection) {
            return collection.dropSites(this.id);
        } else {
            return Promise.reject(new Error('Can\'t drop when no GSLB item is present'));
        }
    }

    /** @override */
    public edit(): IPromise<void> {
        const gslb = this.collection && this.collection.gslb;

        if (gslb) {
            const methodName = this.isNonAviSite() ? 'editNonAviSite' : 'editAviSite';

            return gslb[methodName](this.id);
        } else {
            return Promise.reject(new Error('Can\'t edit when no GSLB item is present'));
        }
    }

    /**
     * Calls checkpoint replication api to bring active site to 'active' point.
     */
    public kickoffReplication(): IPromise<IHttpResponse<void>> {
        const url = '/api/gslbsiteops/replicationpull/';
        const payload = {
            site_id: this.getIdFromData(),
        };

        return this.request(
            'post',
            url,
            payload,
        ).catch((errorObject: any) => {
            const { data: error } = errorObject;

            console.error(error);

            return Promise.reject(error);
        });
    }

    /**
     * Returns needed replicationStatus info for use in UI.
     */
    public get replicationStatus(): IGslbReplicationStatus {
        return this.getRuntimeData()?.replicationStatus;
    }

    /**
     * Returns human readable syncState for active GSLB site.
     */
    public get replicationSyncState(): string {
        const { syncState } = this.replicationStatus;
        const stringService: StringService = this.getAjsDependency_('stringService');

        return stringService.enumeration(syncState, 'GSLB_SITE_CFG_');
    }

    /**
     * Returns replication_pull_enabled from runtime data for active GSLB site.
     */
    public get isReplicationPullEnabled(): boolean {
        return this.replicationStatus.isReplicationPullEnabled;
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    protected getIdFromData_(data: IGslbSiteData): string {
        return data && data.config && data.config.cluster_uuid || '';
    }

    /**
     * Returns true if member is a non-Avi site, false otherwise.
     */
    private isNonAviSite(): boolean {
        return !!this.getConfig().isNonAviSite;
    }
}

Object.assign(GSLBSite.prototype, {
    objectName: 'gslbsite',
});

GSLBSite.ajsDependencies = [
    'stringService',
];

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name SensitiveFieldRuleStringMatchConfig
 * @description Match class used in Sensitive Field Rule.
 */
const sensitiveFieldRuleStringMatchConfig = StringMatchConfig => {
    class SensitiveFieldRuleStringMatchConfig extends StringMatchConfig {}

    angular.extend(StringMatchConfig.prototype, {
        type: 'StringMatch',
        name: 'String',
        defaultPath: 'sensitivefieldrule.match',
    });

    return SensitiveFieldRuleStringMatchConfig;
};

sensitiveFieldRuleStringMatchConfig.$inject = [
        'StringMatchConfig',
];

angular.module('aviApp').factory(
    'SensitiveFieldRuleStringMatchConfig', sensitiveFieldRuleStringMatchConfig,
);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc controller
 * @name LaunchConfigListController
 * @description Controller for AutoScale Launch Config page.
 **/
angular.module('aviApp').controller('LaunchConfigListController', [
'$scope', 'CRUDGridConfig', 'AutoScaleLaunchConfigCollection', 'Regex',
function($scope, CRUDGridConfig, AutoScaleLaunchConfigCollection, Regex) {
    this.Regex = Regex;

    this.collection = new AutoScaleLaunchConfigCollection();

    this.gridConfig = new CRUDGridConfig();

    this.gridConfig.collection = this.collection;

    const { objectName } = this.gridConfig.collection;

    this.gridConfig.id = `${objectName}-list-page`;

    this.gridConfig.fields = [{
        name: 'data.config.name',
        title: 'Name',
        sortBy: 'name',
    }];

    this.gridConfig.layout = {
        hideEditColumns: true,
    };
}]);

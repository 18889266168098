/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'sso-policy-modal';
const prefix = `${moduleName}.${componentName}`;

export const editSsoPolicyHeader = `${prefix}.editSsoPolicyHeader`;
export const newSsoPolicyHeader = `${prefix}.newSsoPolicyHeader`;
export const generalSectionHeader = `${prefix}.generalSectionHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const typeButtonsLabel = `${prefix}.typeButtonsLabel`;
export const authenticationPolicyHeader = `${prefix}.authenticationPolicyHeader`;
export const ruleCountText = `${prefix}.ruleCountText`;

export const ENGLISH = {
    [editSsoPolicyHeader]: 'Edit SSO Policy: {0}',
    [newSsoPolicyHeader]: 'New SSO Policy: {0}',
    [generalSectionHeader]: 'General',
    [nameInputLabel]: 'Name',
    [typeButtonsLabel]: 'Type',
    [authenticationPolicyHeader]: 'Authentication Policy',
    [ruleCountText]: '{0, plural, one{# Rule} other{# Rules}}',
};

const moduleName = 'licensing';
const componentName = 'license-tier-switch-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitle = `${prefix}.modalTitle`;
export const submitButtonLabel = `${prefix}.submitButtonLabel`;
export const switchLicenseHeader = `${prefix}.switchLicenseHeader`;
export const disableEnterpriseFeaturesMessage = `${prefix}.disableEnterpriseFeaturesMessage`;
export const isContinueMessage = `${prefix}.isContinueMessage`;
export const yesContinueBtnLabel = `${prefix}.yesContinueBtnLabel`;
export const insufficientLicensesMessage = `${prefix}.insufficientLicensesMessage`;
export const unlockMoreFeaturesMessage = `${prefix}.unlockMoreFeaturesMessage`;
export const someFeaturesOnlyAvailableMessage = `${prefix}.someFeaturesOnlyAvailableMessage`;

export const ENGLISH = {
    [modalTitle]: 'Licensing',
    [submitButtonLabel]: 'Save',
    [switchLicenseHeader]: 'Switching to {0} License',
    [disableEnterpriseFeaturesMessage]: 'Enterprise features will be disabled.',
    [isContinueMessage]: 'Are you sure you want to continue?',
    [yesContinueBtnLabel]: 'Yes, continue',
    [insufficientLicensesMessage]: 'Insufficient Service Core Licenses to switch to {0} License',
    [unlockMoreFeaturesMessage]: 'Unlock more features and possibilities with an {0} License',
    [someFeaturesOnlyAvailableMessage]: 'Some features are only available in {0}, switching to {1} will disable these features',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import './fqdn.component.less';

/**
 * @ngdoc component
 * @name  fqdn
 * @description
 *     If the Cloud contains a dns_provider_ref, the user can configure the dns_info property of the
 *     VirtualService. Adds fqdn to the DnsInfo object.
 * @param {Cloud} cloud
 * @param {VirtualService} vs
 */
class FqdnController {
    constructor(IPAMProfile, fqdnJoin, fqdnSplit, Regex) {
        this._IPAMProfile = IPAMProfile;
        this._fqdnJoin = fqdnJoin;
        this._fqdnSplit = fqdnSplit;

        /**
         * @type {Regex}
         */
        this.regex = Regex;
    }

    $onInit() {
        this.inputModel = undefined;
        this.dropdownModel = undefined;
        this.domains = [];

        const type = this.cloud.getVtype();

        if (!this.cloud.hasDnsProviderProfile() &&
            !(type === 'CLOUD_AWS' || type === 'CLOUD_AZURE')) {
            // eslint-disable-next-line
                console.error(
                'Trying to edit dns_info of a Virtual Service in a cloud without a supported ' +
                    'cloud configuration.',
            );

            return;
        }

        this._IPAMProfile.getIpamDomains(this.cloud.getRef())
            .then(domains => {
                this.domains = domains;

                const { fqdn } = this.dnsInfo.getConfig();

                /**
                 * If fqdn value exists, split it into the hostname and domain name. Otherwise,
                 * pre-populate the domain name with the first entry in the domains list.
                 */
                if (!angular.isUndefined(fqdn)) {
                    [this.inputModel, this.dropdownModel] = this._fqdnSplit(fqdn, this.domains);
                } else {
                    [this.dropdownModel] = this.domains;

                    if (!angular.isUndefined(this.inputModel)) {
                        this.setValues();
                    }
                }
            });
    }

    /**
     * Track changes to inputAutocomplete, which populates the inputModel until it or the
     * dropdownModel has been modified.
     */
    $onChanges({ inputAutocomplete }) {
        if (!this.vs.getRef() && inputAutocomplete && !inputAutocomplete.isFirstChange()) {
            if (this.inputModel === inputAutocomplete.previousValue) {
                this.inputModel = inputAutocomplete.currentValue;
                this.setValues();
            }
        }
    }

    /**
     * Called when inputs have been changed. Sets the config.dnsInfo.fqdn field.
     */
    setValues() {
        if (angular.isUndefined(this.dropdownModel) || this.dropdownModel === '') {
            this.setFqdn(undefined);
        } else {
            this.setFqdn(this._fqdnJoin(this.inputModel, this.dropdownModel));
        }
    }

    /**
     * Sets the fqdn property in the dnsInfo object.
     * @param {string} fqdn - FQDN hostname string.
     */
    setFqdn(fqdn) {
        this.dnsInfo.setFqdn(fqdn);
    }
}

FqdnController.$inject = [
    'IPAMProfile',
    'fqdnJoin',
    'fqdnSplit',
    'Regex',
];

angular.module('aviApp').component('fqdn', {
    bindings: {
        cloud: '<',
        vs: '<',
        dnsInfo: '<',
        inputAutocomplete: '<',
    },
    controller: FqdnController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-vip-address/' +
        'fqdn/fqdn.html',
});

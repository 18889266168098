/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/** @alias VRFContextCollDataSource */
function vrfContextCollDataSourceFactory(ListCollDataSource) {
    class VRFContextCollDataSource extends ListCollDataSource {}

    VRFContextCollDataSource.prototype.defaultParams_ = {
        exclude: 'name.in',
        'name.in': 'management',
        includeName_: true,
    };

    return VRFContextCollDataSource;
}

vrfContextCollDataSourceFactory.$inject = [
        'ListCollDataSource',
];

/**
 * @ngdoc service
 * @name VRFContextCollDataSource
 * @author Alex Malitsky
 * @desc
 *     {@link ListCollDataSource} with default parameters set.
 */
angular.module('aviApp')
    .factory('VRFContextCollDataSource', vrfContextCollDataSourceFactory);

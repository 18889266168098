/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import * as l10n from './waf-profile-modal.l10n';
import './waf-profile-modal.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name wafProfileModal
 * @param {WafProfile} editable
 * @description Modal component for Waf Profile
 */
class WafProfileModalController {
    constructor(
        Regex,
        schemaService,
        l10nService,
    ) {
        this.Regex = Regex;
        this.pbWafConfigFields = schemaService.getMessageFields('WafConfig');

        const wafDataFileTypes = schemaService.getEnumValues('WafDataFileType');

        /**
         * file Type dropdown options.
         * @type {DropDownOption[]}
         */
        this.fileTypeDropdownOptions = createOptionsFromEnumProps(wafDataFileTypes);

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Handler for Add File button.
     * @public
     */
    handleAddFile() {
        this.editable.addFile();
    }

    /**
     * Handler for Remove File trash button.
     * @param {number} index - Index of the file to remove.
     * @public
     */
    handleRemoveFile(index) {
        this.editable.removeFile(index);
    }
}

WafProfileModalController.$inject = [
    'Regex',
    'schemaService',
    'l10nService',
];

angular.module('aviApp').component('wafProfileModal', {
    controller: WafProfileModalController,
    bindings: {
        editable: '<',
    },
    templateUrl: 'src/components/modals/waf-profile-modal/waf-profile-modal.html',
});

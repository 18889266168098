const moduleName = 'ajs-legacy';
const componentName = 'custom-ipam';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleScript = `${prefix}.columnTitleScript`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleScript]: 'Script',
};

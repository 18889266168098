/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 *  @typedef {Object} DataTransportRequestParams
 *  @property {string} url - Full API url string.
 *  @property {Object} payload - Payload for PUT and POST requests.
 *  @property {Object} headers - Hash of header names and values.
 */
function dataTransportFactory($q, IdentityDataTransport) {
    /**
     * @constructor
     * @memberof module:avi/dataModel
     * @extends module:avi/dataModel.IdentityDataTransport
     * @author Alex Malitsky, Ashish Verma
     * @desc
     *
     *     Data transport is the lowest level class to work with API network calls. It translates
     *     request parameters object into URL, headers and payload, executes an actual network
     *     call and returns the result (as a promise) without any transformation
     *     (in 99.9% of cases).
     *
     *     This layer has no clue of it's user (i.e. Collection), data transitions to be done
     *     with payload or data received, or furthermore - about continuous polling.
     *
     *     Basically this is a tiny wrapper over {@link Base#request} method specialized on
     *     particular API. Easiest example to explain is any list API where you have to make up
     *     a URL string of parameter-keys-equal-sign-values concatenated by ampersand. Know what?
     *     You don't need to create such strings in you code anymore - just pass a hash of
     *     parameters to {@link ListDataTransport#load} and they will be concatenated for you in
     *     an appropriate manner.
     *
     *     In general every API type significantly different from existing ones in terms of
     *     request URL, headers, payload or HTTP type should have it's own dataTransport.
     *
     *     Only one main method {@link DataTransport#load} is exposed. It takes the request params
     *     object of unified (within all users of this API) structure and returns back the received
     *     response. Exact request params structure must be defined by this Class's children.
     *
     *     It is very abstract, please keep it simple. All usage-specific transformation of
     *     request payload or received data should be done by {@link DataTransformer |
     *     DataTransformers} or {@link CollDataSource | DataSources} or any other custom code.
     *
     *     It does support multiple network calls of the same type but such approach is
     *     strongly discouraged. Please use one instance of {@link DataTransformer}
     *     for each channel of data flow (i.e. - {@link CollDataSource}).
     *
     *     It also supports data transformations over received response but this is a fallback
     *     option only for very generic cases when 100% of this API users always need the same
     *     transformation. I.e. backend for some API always returns UNIX timestamps with
     *     milliseconds (12 digits) while UI uses only 10 (seconds only). For such case it makes
     *     sense to go through the response here and provide a guarantee of unified data format.
     *
     */
    class DataTransport extends IdentityDataTransport {
        constructor(args) {
            super(args);

            if (!args || typeof args !== 'object') {
                args = {};
            }

            this.httpMethod_ = args.httpMethod || 'get';
            this.apiUrlPrefix_ = args.apiUrlPrefix || '/api/';
            this.includeName = !!args.includeName || false;
        }

        /**
         * Makes actual API call by transforming request params into URL and payload and returns
         * result as a promise.
         * @param {*} params - Unified within transport request parameters object.
         * @param {string=} requestId - Optional request id to stop pending one (if any) with the
         *     same id before making a next call.
         * @returns {ng.$q.promise}
         * @public
         */
        load(params, requestId) {
            const request = this.getRequestObject_(params);
            let loadPromise;

            requestId = requestId && typeof requestId === 'string' ? requestId : 'default';

            this.cancelRequests(requestId);

            if (Array.isArray(request)) { //DISCOURAGED, multiple transports in 99% suit MUCH better
                loadPromise = $q.all(_.map(request, singleRequest => {
                    if (singleRequest.requestId) {
                        this.cancelRequests(singleRequest.requestId);
                    }

                    if ('httpMethod' in singleRequest) {
                        singleRequest.method = singleRequest.httpMethod;

                        delete singleRequest.httpMethod;
                    } else {
                        singleRequest.method = this.httpMethod_;
                    }

                    const requestGroupId = singleRequest.requestId || requestId;
                    const requestConfig =
                        DataTransport.getRequestConfig_(singleRequest, requestGroupId);

                    return this.request(requestConfig);
                }));
            } else { //normal and advised case
                request.method = this.httpMethod_;

                const requestConfig = DataTransport.getRequestConfig_(request, requestId);

                loadPromise = this.request(requestConfig);
            }

            return loadPromise.then(this.processResponse_.bind(this));
        }

        /**
         * Returns request config.
         * @param {Object} request
         * @param {string?} [requestId='']
         * @returns {module:avi/dataModel.BaseRequestConfig}
         * @protected
         */
        static getRequestConfig_(request, requestId = '') {
            return {
                ...request,
                group: requestId,
            };
        }

        /**
         * Translates request params into one or few API call request objects having everything
         * (url&payload) to perform an actual network call.
         * @param {*} params - Unified within transport request parameters object.
         * @returns {DataTransportRequestParams|DataTransportRequestParams[]} If array has been
         * returned DataTransport will make a separate call for each of them.
         * @protected
         */
        getRequestObject_(params) {
            const
                requestData = {
                    url: this.getRequestUrl_(params),
                    data: this.getRequestPayload_(params),
                    headers: this.getRequestHeaders_(params),
                    params: this.getQueryParams_(params),
                },
                isArrayHash = {};//each data type has false or array length

            let res,
                multipleRequests = false;

            //need to figure out if we are in multi-thread mode
            _.each(requestData, (data, key) => {
                const falseOrArrLength = Array.isArray(data) && data.length;

                isArrayHash[key] = falseOrArrLength;

                if (falseOrArrLength !== false) {
                    multipleRequests = true;
                }
            });

            if (multipleRequests) {
                let multiReqLength;

                const paramsMismatch = _.any(isArrayHash, arrLength => {
                    if (arrLength !== false) { //ignore single values
                        if (angular.isUndefined(multiReqLength)) { //capture the first length
                            multiReqLength = arrLength;
                        //other lengths should be equal or single
                        } else {
                            return arrLength !== multiReqLength;
                        }
                    }
                });

                if (!paramsMismatch) {
                    res = [];

                    for (let i = 0; i < multiReqLength; i++) {
                        const request = _.reduce(requestData, (acc, data, key) => {
                            acc[key] = isArrayHash[key] === false ? data : data[i];

                            return acc;
                        }, {});

                        res.push(request);
                    }
                } else {
                    const errMsg = `Inconsistent behaviour of getRequestUrl_, getRequestPayload_
                        and getRequestHeadersPayload_ - all are supposed to return one
                        undefined/object/string or arrays of those with the equal length`;

                    console.error(errMsg, requestData);
                    throw new Error(errMsg);
                }
            } else {
                res = requestData;
            }

            return res;
        }

        /**
         * Translates request params into one or few URL string to perform an actual API call(s).
         * @param {*=} params - Unified within transport request parameters object.
         * @returns {string}
         * @protected
         * @abstract
         */
        getRequestUrl_(params) {
            return this.apiUrlPrefix_;
        }

        /**
         * Generates QueryParam object for Request. Special values will be excluded.
         * @param {ListDataTransportRequestParams} params
         * @returns {ListDataTransportRequestParams}
         * @protected
         * @abstract
         */
        getQueryParams_(params) {
            return params;
        }

        /**
         * Translates request params into one or few PUT or POST payload objects.
         * @param {*} params - Unified within transport request parameters object.
         * @returns {Object}
         * @protected
         * @abstract
         */
        getRequestPayload_(params) {}

        /**
         * Translates request params into a headers hash.
         * @param {*} params
         * @returns {Object|undefined} - Hash of header names and text values.
         * @protected
         * @abstract
         */
        getRequestHeaders_(params) {}
    }

    return DataTransport;
}

dataTransportFactory.$inject = [
    '$q',
    'IdentityDataTransport',
];

angular.module('avi/dataModel').factory('DataTransport', dataTransportFactory);

const moduleName = 'ajs-legacy';
const componentName = 'license-upload-panel';
const prefix = `${moduleName}.${componentName}`;

export const haveLicenseKeyLabel = `${prefix}.haveLicenseKeyLabel`;
export const applyKeyBtnLabel = `${prefix}.applyKeyBtnLabel`;
export const orLabel = `${prefix}.orLabel`;
export const haveLicenseFileLabel = `${prefix}.haveLicenseFileLabel`;
export const uploadFromComputerBtnLabel = `${prefix}.uploadFromComputerBtnLabel`;

export const ENGLISH = {
    [haveLicenseKeyLabel]: 'Have a License key?',
    [applyKeyBtnLabel]: 'Apply Key',
    [orLabel]: 'OR',
    [haveLicenseFileLabel]: 'Have a License File?',
    [uploadFromComputerBtnLabel]: 'Upload from Computer',
};

/**
 * @module NotificationModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './cloud-token.l10n';
import './cloud-token.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Displays the cloud generated token.
 * @author alextsg
 */
@Component({
    selector: 'cloud-token',
    templateUrl: './cloud-token.component.html',
})
export class CloudTokenComponent {
    /**
     * The generated token.
     */
    @Input() public token: string;

    /**
     * Tenant name.
     */
    @Input() public tenantName: string;

    /**
     * Cloud name.
     */
    @Input() public cloudName: string;

    /**
     * Cluster UUID.
     */
    @Input() public clusterUuid?: string;

    /**
     * Called to close the notification.
     */
    @Output() public onClose = new EventEmitter();

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    public constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Close handler for closing the notification.
     */
    public handleClose(): void {
        this.onClose.emit();
    }
}

/** @module VsModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { L10nService } from '@vmw/ngx-vip';
import { VHType, VirtualServiceType } from 'generated-types';
import template from './vs-list-virtual-hosting-cell.component.html';
import * as l10n from './vs-list-virtual-hosting-cell.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description  Displays virtual hosting details in the Virtual Service grid.
 * @author Rachit Aggarwal
 */
class VsListVirtualHostingCellController {
    /**
     * * Holds value of VS type.
     */
    public vsType: VirtualServiceType;

    /**
     * Holds value of Virtual Hosting type.
     */
    public vhType: VHType;

    /**
     * Get keys from source bundles for template usage.
     */
    private readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns label for vs type.
     */
    public getVSTypeLabel(type: VirtualServiceType): string {
        switch (type) {
            case VirtualServiceType.VS_TYPE_VH_PARENT: {
                return this.l10nKeys.parentLabel;
            }

            case VirtualServiceType.VS_TYPE_VH_CHILD: {
                return this.l10nKeys.childLabel;
            }

            default:
                return '';
        }
    }

    /**
     * Returns label for vh type.
     */
    public getVHTypeLabel(type: VHType): string {
        switch (type) {
            case VHType.VS_TYPE_VH_ENHANCED: {
                return this.l10nKeys.enhancedVHLabel;
            }

            case VHType.VS_TYPE_VH_SNI: {
                return this.l10nKeys.sniVHLabel;
            }

            default:
                return '';
        }
    }
}

VsListVirtualHostingCellController.$inject = [
    'l10nService',
];

export const vsListVirtualHostingCellOptions = {
    bindings: {
        vsType: '<',
        vhType: '<',
    },
    controller: VsListVirtualHostingCellController,
    template,
};

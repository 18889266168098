const moduleName = 'ajs-legacy';
const componentName = 'segroup-license-selector';
const prefix = `${moduleName}.${componentName}`;

export const licenseSettings = `${prefix}.licenseSettings`;
export const enablePerappSeModeCheckboxLabel = `${prefix}.enablePerappSeModeCheckboxLabel`;
export const seBandwidthTypeInputLabel = `${prefix}.seBandwidthTypeInputLabel`;
export const virtualServicePerSeInputLabel = `${prefix}.virtualServicePerSeInputLabel`;
export const setNumberInputPlaceholder = `${prefix}.setNumberInputPlaceholder`;
export const maximumInnerText = `${prefix}.maximumInnerText`;
export const numberSeDataPathsInputLabel = `${prefix}.numberSeDataPathsInputLabel`;
export const useHyperThreadingCheckboxLabel = `${prefix}.useHyperThreadingCheckboxLabel`;
export const instanceFlavorInputLabel = `${prefix}.instanceFlavorInputLabel`;
export const azurePricingInfoInputLabel = `${prefix}.azurePricingInfoInputLabel`;
export const autoSelectInputPlaceholder = `${prefix}.autoSelectInputPlaceholder`;
export const showAllFlavorsCheckboxLabel = `${prefix}.showAllFlavorsCheckboxLabel`;

export const ENGLISH = {
    [licenseSettings]: 'License',
    [enablePerappSeModeCheckboxLabel]: 'Enable Per-app SE Mode',
    [seBandwidthTypeInputLabel]: 'SE Bandwidth Type',
    [virtualServicePerSeInputLabel]: 'Virtual Service per SE',
    [setNumberInputPlaceholder]: 'Set number',
    [maximumInnerText]: 'Maximum',
    [numberSeDataPathsInputLabel]: 'Number of SE Data Paths',
    [useHyperThreadingCheckboxLabel]: 'Use Hyperthreading',
    [instanceFlavorInputLabel]: 'Instance Flavor',
    [azurePricingInfoInputLabel]: 'Azure Pricing Info',
    [autoSelectInputPlaceholder]: 'Auto select (default)',
    [showAllFlavorsCheckboxLabel]: 'Show all flavors',
};

/** @module LogsModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { L10nService } from '@vmw/ngx-vip';
import { IDSRequestLogRow } from 'ajs/modules/logs';
import * as l10n from './out-of-band-log-list-expander.l10n';
import template from './out-of-band-log-list-expander.component.html';
import './out-of-band-log-list-expander.component.less';

/**
 * Token for outOfBandLogListExpander component.
 */
export const OUT_OF_BAND_EXPANDER_COMPONENT_TOKEN = 'outOfBandLogListExpander';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const newLineSplitter = '\\r\\n';

/**
 * @author Satish Pednekar
 * @desc Component for OutOfBand logs present in VS for L7.
 */
class OutOfBandLogListExpanderComponent {
    public readonly l10nKeys = l10nKeys;

    /**
     * Single DSRequestLog row entry which will be displayed in expandable panel.
     */
    public readonly outOfBandLog: IDSRequestLogRow;

    /**
     * splitHeadersSent holds transformed DSRequestLog header fields which
     * are displayed in headers sent section.
     */
    public splitHeadersSent: string[];

    /**
     * splitHeadersReceived holds transformed DSRequestLog header fields which
     * are displayed in headers received section.
     */
    public splitHeadersReceived: string[];

    /**
     * Parent function passed in to handle filter by new prop.
     */
    public updateSearch: (args: Record<string, any>) => void;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Splits input header string by delimiter defined by newLineSplitter.
     * Used for formatting raw header string which is then displayed in 'Headers Sent to Server'/
     * 'Headers Received from Server' sections in expandable panel.
     */
    private static splitHeader(header: string): string[] {
        if (header) {
            return JSON.stringify(header).slice(1, -2).split(newLineSplitter);
        }

        return [];
    }

    /** @override */
    public $onInit(): void {
        this.splitServerHeaders();
    }

    /**
     * Calls parent function to handle user click to filter by clicked field.
     */
    public onUpdateSearch(str: string): void {
        this.updateSearch({ str });
    }

    /**
     * Split server sent & received headers.
     */
    private splitServerHeaders(): void {
        const { outOfBandLog } = this;

        const {
            headers_sent_to_server: headersSent,
            headers_received_from_server: headersReceived,
        } = outOfBandLog;

        this.splitHeadersSent =
            OutOfBandLogListExpanderComponent.splitHeader(headersSent.display);
        this.splitHeadersReceived =
            OutOfBandLogListExpanderComponent.splitHeader(headersReceived.display);
    }
}

OutOfBandLogListExpanderComponent.$inject = [
    'l10nService',
];

export const outOfBandLogListExpanderComponentOptions = {
    bindings: {
        outOfBandLog: '<',
        updateSearch: '&',
    },
    controller: OutOfBandLogListExpanderComponent,
    template,
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'newline-converted-textarea';
const prefix = `${moduleName}.${componentName}`;

export const userInputHeader = `${prefix}.userInputHeader`;
export const enterStringInputPlaceholder = `${prefix}.enterStringInputPlaceholder`;
export const convertedValuePreviewHeader = `${prefix}.convertedValuePreviewHeader`;
export const wordwrapBtnLabel = `${prefix}.wordwrapBtnLabel`;

export const ENGLISH = {
    [userInputHeader]: 'USER INPUT',
    [enterStringInputPlaceholder]: 'Enter string',
    [convertedValuePreviewHeader]: 'CONVERTED VALUE PREVIEW',
    [wordwrapBtnLabel]: 'Wordwrap',
};

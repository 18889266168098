/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name  geoProfilePage
 * @description  Page for Geo Profiles containing a Collection Grid of Geo Profiles.
 */
class GeoProfilePageController {
    constructor(GeoProfileCollection, CRUDGridConfig) {
        this._collection = new GeoProfileCollection();

        const entriesTemplate =
                `<span ng-repeat="entry in row.data.config.entries track by entry.file.filename">
                    {{entry.file.filename + '(' + entry.priority + ')'}}{{$last ? '' : ', '}}
                </span>`;

        const gridConfig = {
            collection: this._collection,
            id: 'geo-profile-list-page',
            fields: [{
                name: 'data.config.name',
                sortBy: 'name',
                title: 'Name',
            }, {
                name: 'entries',
                title: 'Entries (priority)',
                template: entriesTemplate,
            }],
        };

        this.gridConfig = CRUDGridConfig(gridConfig);
    }
}

GeoProfilePageController.$inject = [
        'GeoProfileCollection',
        'CRUDGridConfig',
];

angular.module('aviApp').component('geoProfilePage', {
    controller: GeoProfilePageController,
    templateUrl: 'src/components/pages/infrastructure/gslb/geo-profile-page/' +
            'geo-profile-page.html',
});

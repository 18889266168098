/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name SupplMetricData
 * @author Alex Malitsky
 * @description
 *
 *    For some Metric instances we want to fetch a supplementary data such as events, alerts and
 *    anomalies which had happened during the time frame metric represents.
 *
 *    SupplMetricData is meant to load such data and keep it in sync with Metric instance. In sync
 *    meaning step, limit, item type, item id and list of regular series Metric has.
 *
 *    All supplementary data is present in a form of Series since it is nothing but set of data
 *    points for the same time frame as Metric instance represents.
 *
 *    When those Series get updates SupplMetricData catches those events and propagates them by
 *    triggering on instance and it's owner: Metric.
 *
 */
angular.module('avi/metrics').factory('SupplMetricData', [
'$q', 'UpdatableBase', 'Metric', 'Series', 'SupplMetricDataEventsDataSource',
function($q, UpdatableBase, Metric, Series, SupplMetricDataEventsDataSource) {
    const allDataSources = {
            config_events_ds: {
                source: 'SupplMetricDataEventsDataSource',
                transformer: 'AggEventSeriesDataTransformer',
                transport: 'ListDataTransport',
                fields: [
                    SupplMetricDataEventsDataSource.eventTypes['config_events_ds'].fieldName,
                ],
            },
            non_config_events_ds: {
                source: 'SupplMetricDataEventsDataSource',
                transformer: 'AggEventSeriesDataTransformer',
                transport: 'ListDataTransport',
                fields: [
                    SupplMetricDataEventsDataSource.eventTypes['non_config_events_ds'].fieldName,
                ],
            },
        },
        defaultDataSources = Object.keys(allDataSources);

    class SupplMetricData extends UpdatableBase {
        constructor(args = {}) {
            angular.extend(args, {
                isStatic: false,
                allDataSources,
                defaultDataSources,
            });

            super(args);

            if (!args.metric) {
                console.error('Can\'t create SupplMetricData wo Metric instance reference');
            } else {
                this.metric_ = args.metric;
            }

            [
                'load',
                'flushAndLoad',
                'onEventsSeriesUpdate_',
                'destroy',
            ].forEach(methodName => this[methodName] = this[methodName].bind(this));

            const { metric_: metric } = this;

            metric.one('beforeDestroy', this.destroy);

            /**
             * Wrapping load here to avoid passing unnecessary arguments to load method.
             * @private
             */
            this.onMetricUpdate_ = () => this.load();

            [
                Metric.SERIES_LIST_UPDATE_EVENT,
                Metric.PARAMS_UPDATE_EVENT,
            ].forEach(eventName => metric.on(eventName, this.onMetricUpdate_));

            metric.on(Metric.ITEM_UPDATE_EVENT, this.flushAndLoad);

            const seriesUpdateEventListeners = {};

            seriesUpdateEventListeners[Series.VALUES_UPDATE_EVENT] = this.onEventsSeriesUpdate_;

            this.aggConfigEventSeries = new Series({
                id: 'agg_config_events',
                seriesId: 'agg_config_events',
                bind: seriesUpdateEventListeners,
            });

            this.aggNonConfigEventSeries = new Series({
                id: 'agg_non_config_events',
                seriesId: 'agg_non_config_events',
                bind: seriesUpdateEventListeners,
            });

            this.isActive_ = this.loadOnCreate_;

            this.load();
        }

        /**
         * To be used by DataSource only to get Metric's step and limit params.
         * @returns {Object} - Step and limit only.
         */
        getMetricParams() {
            return angular.copy(this.metric_.params);
        }

        /**
         * To be used by DataSource only to get Metric's item id.
         * @returns {Item.id}
         */
        getMetricItemId() {
            return this.metric_.itemId;
        }

        /**
         * Convenience method to get all the series owned by this instance in a list.
         * @returns {Series[]}
         * @private
         */
        getAllSeries_() {
            return [
                this.aggConfigEventSeries,
                this.aggNonConfigEventSeries,
            ];
        }

        /**
         * Removes all series data we have and starts polling right after (if instance is in
         * active state).
         * @returns {ng.$q.promise}
         * @public
         */
        flushAndLoad() {
            this.emptyData();

            return this.load();
        }

        /** @override */
        emptyData() {
            this.getAllSeries_().forEach(series => series.emptyData());
            super.emptyData();
        }

        /**
         * Switches instance into active state and starts polling data.
         * @returns {ng.$q.promise}
         * @public
         */
        activate() {
            this.isActive_ = true;

            return this.load();
        }

        /**
         * Deactivates instance and stops data polling.
         * @public
         * @override
         */
        stopUpdates() {
            this.isActive_ = false;
            super.stopUpdates();
        }

        /**
         * Returns an active flag value.
         * @returns {boolean}
         * @public
         */
        isActive() {
            return this.isActive_;
        }

        /**
         * Event listener for the nested Series update. Triggers corresponding events on
         * instance and Metric it belongs to.
         * @param {Series} series
         * @protected
         */
        onEventsSeriesUpdate_(series) {
            const { metric_: metric } = this;

            let eventType;

            if (series.id === 'agg_config_events') {
                eventType = SupplMetricData.CONFIG_EVENTS_UPDATE_EVENT;
            } else {
                eventType = SupplMetricData.NON_CONFIG_EVENTS_UPDATE_EVENT;
            }

            this.trigger(eventType, this, series);
            metric.trigger(eventType, metric, series);
        }

        /** @override */
        destroy() {
            const gotDestroyed = super.destroy();

            if (gotDestroyed) {
                const { metric_: metric } = this;

                [
                    Metric.SERIES_LIST_UPDATE_EVENT,
                    Metric.PARAMS_UPDATE_EVENT,
                ].forEach(eventName => metric.unbind(eventName, this.onMetricUpdate_));

                metric.unbind(Metric.ITEM_UPDATE_EVENT, this.flushAndLoad);
                this.getAllSeries_().forEach(series => series.destroy());
            }

            return gotDestroyed;
        }
    }

    SupplMetricData.CONFIG_EVENTS_UPDATE_EVENT = 'configEventsUpdate';
    SupplMetricData.NON_CONFIG_EVENTS_UPDATE_EVENT = 'nonConfigEventsUpdate';

    /* Coming soon:
    SupplMetricData.ALERTS_UPDATE_EVENT = 'alertsUpdate';
    SupplMetricData.ANOMALIES_UPDATE_EVENT = 'anomaliesUpdate';
    SupplMetricData.ANOMALIES_LIST_UPDATE_EVENT = 'anomaliesListUpdate'; */

    return SupplMetricData;
}]);

const moduleName = 'ajs-legacy';
const componentName = 'analytics-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const gatherAnalyticsInputLabel = `${prefix}.gatherAnalyticsInputLabel`;
export const filedNameInputPlaceholder = `${prefix}.filedNameInputPlaceholder`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const httpAnalyticsHeader = `${prefix}.httpAnalyticsHeader`;
export const apdexResponseThresholdHeader = `${prefix}.apdexResponseThresholdHeader`;
export const satisfactoryLatencyThresholdInputLabel = `${prefix}.satisfactoryLatencyThresholdInputLabel`;
export const msInnerText = `${prefix}.msInnerText`;
export const toleratedLatencyFactorInputLabel = `${prefix}.toleratedLatencyFactorInputLabel`;
export const multiplierPlaceholder = `${prefix}.multiplierPlaceholder`;
export const apdexClientPageloadHeader = `${prefix}.apdexClientPageloadHeader`;
export const apdexServerResponseHeader = `${prefix}.apdexServerResponseHeader`;
export const networkAnalyticsHeader = `${prefix}.networkAnalyticsHeader`;
export const clientConnectionApdexLossyConnectionThresholdHeader = `${prefix}.clientConnectionApdexLossyConnectionThresholdHeader`;
export const connLossyTotalRetransmitThersholdInputLabel = `${prefix}.connLossyTotalRetransmitThersholdInputLabel`;
export const connServerLossyTotalRetransmitThersholdInputLabel = `${prefix}.connServerLossyTotalRetransmitThersholdInputLabel`;
export const connLossyTimeoutRetransmitThresholdInputLabel = `${prefix}.connLossyTimeoutRetransmitThresholdInputLabel`;
export const connServerLossyTimeoutRetransmitThresholdInputLabel = `${prefix}.connServerLossyTimeoutRetransmitThresholdInputLabel`;
export const connLossyOutOfOrderThresholdInputLabel = `${prefix}.connLossyOutOfOrderThresholdInputLabel`;
export const connServerLossyOutOfOrderThresholdInputLabel = `${prefix}.connServerLossyOutOfOrderThresholdInputLabel`;
export const connLossyZeroWindowSizeEventThresholdInputLabel = `${prefix}.connLossyZeroWindowSizeEventThresholdInputLabel`;
export const connServerLossyZeroWindowSizeEventThresholdInputLabel = `${prefix}.connServerLossyZeroWindowSizeEventThresholdInputLabel`;
export const serverConnectionApdexLossyConnectionThresholdHeader = `${prefix}.serverConnectionApdexLossyConnectionThresholdHeader`;
export const excludeNetworkErrorsHeader = `${prefix}.excludeNetworkErrorsHeader`;
export const excludeTcpResetAsErrorInputLabel = `${prefix}.excludeTcpResetAsErrorInputLabel`;
export const excludePersistenceChangeAsErrorInputLabel = `${prefix}.excludePersistenceChangeAsErrorInputLabel`;
export const excludeClientCloseBeforeRequestAsErrorInputLabel = `${prefix}.excludeClientCloseBeforeRequestAsErrorInputLabel`;
export const excludeServerTcpResetAsErrorInputLabel = `${prefix}.excludeServerTcpResetAsErrorInputLabel`;
export const excludeDnsErrorsHeader = `${prefix}.excludeDnsErrorsHeader`;
export const excludeInvalidDnsQueryAsErrorInputLabel = `${prefix}.excludeInvalidDnsQueryAsErrorInputLabel`;
export const excludeInvalidDnsDomainAsErrorInputLabel = `${prefix}.excludeInvalidDnsDomainAsErrorInputLabel`;
export const excludeNoDnsRecordAsErrorInputLabel = `${prefix}.excludeNoDnsRecordAsErrorInputLabel`;
export const excludeGslbDownAsErrorInputLabel = `${prefix}.excludeGslbDownAsErrorInputLabel`;
export const excludeNoValidGslbMemberAsErrorInputLabel = `${prefix}.excludeNoValidGslbMemberAsErrorInputLabel`;
export const excludeUnsupportedDnsQueryAsErrorInputLabel = `${prefix}.excludeUnsupportedDnsQueryAsErrorInputLabel`;
export const excludeServerDnsErrorAsErrorInputLabel = `${prefix}.excludeServerDnsErrorAsErrorInputLabel`;
export const excludeDnsPolicyDropAsSignificantInputLabel = `${prefix}.excludeDnsPolicyDropAsSignificantInputLabel`;
export const sslOcspResponseHeader = `${prefix}.sslOcspResponseHeader`;
export const excludeStaleOcspResponsesAsErrorInputLabel = `${prefix}.excludeStaleOcspResponsesAsErrorInputLabel`;
export const excludeRevokedOcspResponsesAsErrorInputLabel = `${prefix}.excludeRevokedOcspResponsesAsErrorInputLabel`;
export const excludeIssuerRevokedOcspResponsesAsErrorInputLabel = `${prefix}.excludeIssuerRevokedOcspResponsesAsErrorInputLabel`;
export const excludeUnavailableOcspResponsesAsErrorInputLabel = `${prefix}.excludeUnavailableOcspResponsesAsErrorInputLabel`;
export const clientLogProcessingHeader = `${prefix}.clientLogProcessingHeader`;
export const enableSignificantLogCollectionInputLabel = `${prefix}.enableSignificantLogCollectionInputLabel`;
export const significantLogProcessingInputLabel = `${prefix}.significantLogProcessingInputLabel`;
export const filteredLogProcessingInputLabel = `${prefix}.filteredLogProcessingInputLabel`;
export const nonSignificantLogProcessingInputLabel = `${prefix}.nonSignificantLogProcessingInputLabel`;
export const clientLogStreamingHeader = `${prefix}.clientLogStreamingHeader`;
export const streamLogsToExternalServerInputLabel = `${prefix}.streamLogsToExternalServerInputLabel`;
export const externalServerPortInputLabel = `${prefix}.externalServerPortInputLabel`;
export const logTypesToSendInputLabel = `${prefix}.logTypesToSendInputLabel`;
export const maxLogsPerSecondInputLabel = `${prefix}.maxLogsPerSecondInputLabel`;
export const perSecInnerText = `${prefix}.perSecInnerText`;
export const protocolInputLabel = `${prefix}.protocolInputLabel`;
export const significantLogSeverityInputLabel = `${prefix}.significantLogSeverityInputLabel`;
export const nonSignificantLogSeverityInputLabel = `${prefix}.nonSignificantLogSeverityInputLabel`;
export const filteredLogSeverityInputLabel = `${prefix}.filteredLogSeverityInputLabel`;
export const hostnameInputLabel = `${prefix}.hostnameInputLabel`;
export const facilityInputLabel = `${prefix}.facilityInputLabel`;
export const externalServerInputLabel = `${prefix}.externalServerInputLabel`;
export const externalServerPortExternalServerPortInputLabel = `${prefix}.externalServerPortExternalServerPortInputLabel`;
export const removeServerButtonLabel = `${prefix}.removeServerButtonLabel`;
export const addServerButtonLabel = `${prefix}.addServerButtonLabel`;
export const sensitiveLogProfileHeader = `${prefix}.sensitiveLogProfileHeader`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;
export const healthScoreAnalyticsHeader = `${prefix}.healthScoreAnalyticsHeader`;
export const healthScorePerformanceBoostInputLabel = `${prefix}.healthScorePerformanceBoostInputLabel`;
export const healthScoreMaxAnomalyPenaltyInputLabel = `${prefix}.healthScoreMaxAnomalyPenaltyInputLabel`;
export const healthScoreMaxResourcesPenaltyInputLabel = `${prefix}.healthScoreMaxResourcesPenaltyInputLabel`;
export const healthScoreMaxSecurityPenaltyInputLabel = `${prefix}.healthScoreMaxSecurityPenaltyInputLabel`;
export const healthScoreSecurityChainInvalidityPenaltyInputLabel = `${prefix}.healthScoreSecurityChainInvalidityPenaltyInputLabel`;
export const healthScoreSecurityHstsPenaltyInputLabel = `${prefix}.healthScoreSecurityHstsPenaltyInputLabel`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit Analytics Profile: {0}',
    [modalHeaderNew]: 'New Analytics Profile: {0}',
    [nameInputLabel]: 'Name',
    [gatherAnalyticsInputLabel]: 'Gather Analytics for',
    [filedNameInputPlaceholder]: 'Select',
    [descriptionInputLabel]: 'Description',
    [httpAnalyticsHeader]: 'HTTP Analytics',
    [apdexResponseThresholdHeader]: 'Client Response Apdex',
    [satisfactoryLatencyThresholdInputLabel]: 'Satisfactory Latency Threshold',
    [msInnerText]: 'ms',
    [toleratedLatencyFactorInputLabel]: 'Tolerated Latency Factor',
    [multiplierPlaceholder]: 'Multiplier',
    [apdexClientPageloadHeader]: 'Client PageLoad Apdex',
    [apdexServerResponseHeader]: 'Server Response Apdex',
    [networkAnalyticsHeader]: 'Network Analytics',
    [clientConnectionApdexLossyConnectionThresholdHeader]: 'Client Connection Apdex - Lossy Connection Threshold',
    [connLossyTotalRetransmitThersholdInputLabel]: 'TCP Retransmit Threshold',
    [connServerLossyTotalRetransmitThersholdInputLabel]: 'TCP Retransmit Threshold',
    [connLossyTimeoutRetransmitThresholdInputLabel]: 'TCP Timeout Threshold',
    [connServerLossyTimeoutRetransmitThresholdInputLabel]: 'TCP Timeout Threshold',
    [connLossyOutOfOrderThresholdInputLabel]: 'TCP Out-Of-Order Threshold',
    [connServerLossyOutOfOrderThresholdInputLabel]: 'TCP Out-Of-Order Threshold',
    [connLossyZeroWindowSizeEventThresholdInputLabel]: 'TCP Zero Window Threshold',
    [connServerLossyZeroWindowSizeEventThresholdInputLabel]: 'TCP Zero Window Threshold',
    [serverConnectionApdexLossyConnectionThresholdHeader]: 'Server Connection Apdex - Lossy Connection Threshold',
    [excludeNetworkErrorsHeader]: 'Exclude Network Errors',
    [excludeTcpResetAsErrorInputLabel]: 'Client Connection RST',
    [excludePersistenceChangeAsErrorInputLabel]: 'Client Connection Persistence Changed',
    [excludeClientCloseBeforeRequestAsErrorInputLabel]: 'Client Connection Closed before HTTP Request',
    [excludeServerTcpResetAsErrorInputLabel]: 'Server Connection RST',
    [excludeDnsErrorsHeader]: 'Exclude DNS Errors',
    [excludeInvalidDnsQueryAsErrorInputLabel]: 'Invalid DNS Query',
    [excludeInvalidDnsDomainAsErrorInputLabel]: 'Invalid DNS Domain',
    [excludeNoDnsRecordAsErrorInputLabel]: 'No DNS Record',
    [excludeGslbDownAsErrorInputLabel]: 'GSLB Service Down',
    [excludeNoValidGslbMemberAsErrorInputLabel]: 'No Valid GSLB Service Member',
    [excludeUnsupportedDnsQueryAsErrorInputLabel]: 'Unsupported DNS Query',
    [excludeServerDnsErrorAsErrorInputLabel]: 'Server DNS Error',
    [excludeDnsPolicyDropAsSignificantInputLabel]: 'DNS Policy Drop as Significant',
    [sslOcspResponseHeader]: 'SSL OCSP Response',
    [excludeStaleOcspResponsesAsErrorInputLabel]: 'Exclude Stale as Error',
    [excludeRevokedOcspResponsesAsErrorInputLabel]: 'Exclude Revoked as Error',
    [excludeIssuerRevokedOcspResponsesAsErrorInputLabel]: 'Exclude Issuer Revoked as Error',
    [excludeUnavailableOcspResponsesAsErrorInputLabel]: 'Exclude Unavailable as Error',
    [clientLogProcessingHeader]: 'Client Log Processing',
    [enableSignificantLogCollectionInputLabel]: 'Enable Significant Logs',
    [significantLogProcessingInputLabel]: 'Significant Log Processing Type',
    [filteredLogProcessingInputLabel]: 'Filtered Log Processing Type',
    [nonSignificantLogProcessingInputLabel]: 'Non-significant Log Processing Type',
    [clientLogStreamingHeader]: 'Client Log Streaming',
    [streamLogsToExternalServerInputLabel]: 'Stream Logs to an External Server',
    [externalServerPortInputLabel]: 'Default Port',
    [logTypesToSendInputLabel]: 'Types of Logs to Stream',
    [maxLogsPerSecondInputLabel]: 'Max Logs',
    [perSecInnerText]: '/sec',
    [protocolInputLabel]: 'Log Streaming Protocol',
    [significantLogSeverityInputLabel]: 'Log Severity',
    [nonSignificantLogSeverityInputLabel]: 'Non-Significant Log Severity',
    [filteredLogSeverityInputLabel]: 'Filtered Log Severity',
    [hostnameInputLabel]: 'Hostname',
    [facilityInputLabel]: 'Facility',
    [externalServerInputLabel]: 'Server',
    [externalServerPortExternalServerPortInputLabel]: 'Port',
    [removeServerButtonLabel]: 'Remove Server',
    [addServerButtonLabel]: '+ Add Server',
    [sensitiveLogProfileHeader]: 'Sensitive Log Profile',
    [cancelButtonLabel]: 'Cancel',
    [saveButtonLabel]: 'Save',
    [healthScoreAnalyticsHeader]: 'Health Score Analytics',
    [healthScorePerformanceBoostInputLabel]: 'Performance Boost',
    [healthScoreMaxAnomalyPenaltyInputLabel]: 'Anomaly Penalties',
    [healthScoreMaxResourcesPenaltyInputLabel]: 'Resource Penalties',
    [healthScoreMaxSecurityPenaltyInputLabel]: 'Security Penalties',
    [healthScoreSecurityChainInvalidityPenaltyInputLabel]: 'Penalty For Invalid Chain',
    [healthScoreSecurityHstsPenaltyInputLabel]: 'Penalty For No HSTS',
};

/**
 * Module for Templates>Security.
 * @preferred
 * @module SecurityModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';
import {
    authProfileCreateController,
    certificateExpanderOptions,
    certificateManagementCreateController,
    certificateManagementExpanderOptions,
    pkiProfileCreateController,
    sslTlsCertificateOcspComponentOptions,
} from '.';

const securityModule = angular.module('avi/security');

const components = [{
    name: 'sslTlsCertificateOcsp',
    options: sslTlsCertificateOcspComponentOptions,
}, {
    name: 'certificateExpander',
    options: certificateExpanderOptions,
}, {
    name: 'certificateManagementExpander',
    options: certificateManagementExpanderOptions,
}];

components.forEach(({ name, options }) => securityModule.component(name, options));

const controllers = [
    {
        name: 'AuthProfileCreateController',
        controller: authProfileCreateController,
    },
    {
        name: 'CertificateManagementCreateController',
        controller: certificateManagementCreateController,
    },
    {
        name: 'PKIProfileCreateController',
        controller: pkiProfileCreateController,
    },
];

controllers.forEach(({ name, controller }) => securityModule.controller(name, controller));

const moduleName = 'ajs-legacy';
const componentName = 'cluster-edit';
const prefix = `${moduleName}.${componentName}`;

export const editControllerConfigurationHeader = `${prefix}.editControllerConfigurationHeader`;
export const clusterInformationHeader = `${prefix}.clusterInformationHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const ipAddressInputLabel = `${prefix}.ipAddressInputLabel`;
export const ipAddressInputPlaceholder = `${prefix}.ipAddressInputPlaceholder`;
export const clusterNodesHeader = `${prefix}.clusterNodesHeader`;
export const hostIpAddressPlaceholder = `${prefix}.hostIpAddressPlaceholder`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const publicIpInputLabel = `${prefix}.publicIpInputLabel`;
export const saveBtnLabel = `${prefix}.saveBtnLabel`;

export const ENGLISH = {
    [editControllerConfigurationHeader]: 'Edit Controller Configuration',
    [clusterInformationHeader]: 'Cluster Information',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'name',
    [ipAddressInputLabel]: 'Controller Cluster IP',
    [ipAddressInputPlaceholder]: 'IP Address',
    [clusterNodesHeader]: 'Cluster Nodes',
    [hostIpAddressPlaceholder]: 'Host IP Address',
    [passwordInputLabel]: 'Password',
    [publicIpInputLabel]: 'Public IP',
    [saveBtnLabel]: 'Save',
};

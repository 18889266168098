/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').controller('HSMGroupListController', [
'$scope', 'HSMGroupCollection', 'CRUDGridConfig', 'AviModal',
function($scope, HSMGroupCollection, CRUDGridConfig, AviModal) {
    this.collection = new HSMGroupCollection();

    this.gridConfig = new CRUDGridConfig();
    this.gridConfig.collection = this.collection;

    const { objectName } = this.gridConfig.collection;

    this.gridConfig.id = `${objectName}-list-page`;

    this.gridConfig.fields = [{
        name: 'data.config.name',
        title: 'Name',
        sortBy: 'name',
    }, {
        name: 'type',
        title: 'Type',
        template: '{{row.data.config.hsm.type | enum: "HSM_TYPE_" }}',
    }];

    this.gridConfig.singleactions = [{
        title: 'Edit',
        class: 'icon-pencil-4',
        hidden: row => !row.isEditable() && !row.isClonable(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }, {
        title: 'Open window for client certificate download',
        class: 'icon-lostlog',
        do: ({ data }) => {
            AviModal.open('prof-hsmgroup-download-cert', {
                nodes: data.config.hsm.sluna.node_info,
                name: data.config.name,
            });
        },
        hidden(row) {
            return row.data.config.hsm.type !== 'HSM_TYPE_SAFENET_LUNA';
        },
    }];
}]);

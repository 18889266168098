/**
 * Module containing components, pipes and services used in
 * NSX ALB Header.
 * @module HeaderModule
 * @preferred
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */
import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { SharedModule } from 'ng/shared/shared.module';

import {
    MainMenuComponent,
    MainMenuListComponent,
    StateButtonComponent,
    StateGroupPipe,
    StateNamePipe,
} from '.';

const headerComponents = [
    MainMenuComponent,
    MainMenuListComponent,
    StateButtonComponent,
];

const headerPipes = [
    StateGroupPipe,
    StateNamePipe,
];

@NgModule({
    declarations: [
        ...headerComponents,
        ...headerPipes,
    ],
    exports: [
        MainMenuComponent,
        StateButtonComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        UIRouterModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class HeaderModule {}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').factory('PoolServerInventoryDataTransformer', [
'InventoryDataTransformer', 'Server',
function(InventoryDataTransformer, Server) {
    return class PoolServerInventoryDataTransformer extends InventoryDataTransformer {
        processResponse(resp, request) {
            resp = this.responseListOffsetControl_(resp, request);

            if ('results' in resp.data && Array.isArray(resp.data.results)) {
                resp.data.results.forEach(({ config }) =>
                    config.uuid = Server.getServerUuid(config));
            }

            return resp;
        }
    };
}]);

/**
 * @module NotificationModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './message-of-the-day.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Displays the system Message Of The Day.
 * @author alextsg
 */
@Component({
    selector: 'message-of-the-day',
    templateUrl: './message-of-the-day.component.html',
})
export class MessageOfTheDayComponent {
    @Input() public message = '';
    @Output() public onClose = new EventEmitter();

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Close handler for closing the notification.
     */
    public handleClose(): void {
        this.onClose.emit();
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './UserProfileController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name  UserProfileController
 * @description Controller for User Profile List page.
 */
class UserProfileController {
    constructor(
        CRUDGridConfig,
        UserProfileCollection,
        l10nService,
    ) {
        this.collection = new UserProfileCollection();
        this.CRUDGridConfig_ = CRUDGridConfig;

        /**
        * Get keys from source bundles for template usage
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
        this.l10nService_ = l10nService;
    }

    $onInit() {
        const { l10nService_: l10nService } = this;

        const config = {
            collection: this.collection,
            fields: [{
                name: 'data.config.name',
                title: l10nService.getMessage(l10nKeys.columnTitleName),
            }],
        };

        const { objectName } = config.collection;

        config.id = `${objectName}-list-page`;

        this.gridConfig = this.CRUDGridConfig_(config);
    }

    $onDestroy() {
        this.collection.destroy();
    }
}

UserProfileController.$inject = [
        'CRUDGridConfig',
        'UserProfileCollection',
        'l10nService',
];

angular.module('aviApp').controller('UserProfileController', UserProfileController);

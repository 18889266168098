/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { analyticsProfileAnalyticsTypeLabelHash }
    from '../../constants/analytics-enabled-keys.constant';

/**
 * @typedef {Object} AnalyticsProfile - AnalyticsProfile item.
 */
angular.module('aviApp').factory('AnalyticsProfile', [
'Item', 'SensitiveLogProfileConfig',
function(Item, SensitiveLogProfileConfig) {
    class AnalyticsProfile extends Item {
        constructor(args) {
            super(args);

            /** @type {boolean} */
            this.useExternalLogs = false;
        }

        /**
         * Get log streaming external server config object.
         * @return {Object}
         */
        static getDefaultExternalLogServerConfig() {
            return {};
        }

        /**
         * Returns list of server objects form the comma separated string.
         * @param {Object[]} acc - Accumulator external serverarray value.
         * @param {string} externalServerWithPort - string value containing server and
         *      port value saperated by ':'.
         * @return {Object[]}
         */
        static externalLogServerParserReducer(acc, externalServerWithPort) {
            const externalServerObject = AnalyticsProfile
                .getDefaultExternalLogServerConfig();
            const [server, port] = externalServerWithPort.split(':');

            if (port) {
                externalServerObject.port = Number(port);
            }

            externalServerObject.server = server;
            acc.push(externalServerObject);

            return acc;
        }

        /**
         * Returns a list of external log servers in string form.
         * @param {string[]} acc - Accumulator external serverarray value.
         * @param {Object} externalServer - Object having server and port values.
         * @return {string[]}
         */
        static externalLogServerFormatterReducer(acc, { server, port }) {
            let serverStr = '';
            const portValue = Number(port);

            if (!_.isNaN(portValue)) {
                serverStr = `${server}:${portValue}`;
            } else {
                serverStr = server;
            }

            acc.push(serverStr);

            return acc;
        }

        /** @override */
        beforeEdit() {
            const
                config = this.getConfig(),
                { client_log_streaming_config: strConfig } = config;

            if (strConfig && strConfig['external_server']) {
                this.useExternalLogs = true;
            } else {
                this.useExternalLogs = false;

                const { client_log_streaming_config: defStrConfig } = this.getDefaultConfig_();

                config['client_log_streaming_config'] = defStrConfig;
            }

            const { client_log_streaming_config: clientLogStreamingConfig } = config;

            clientLogStreamingConfig['externalServerArray'] = [];

            if ('external_server' in clientLogStreamingConfig) {
                const externalServerWithPortArray = clientLogStreamingConfig['external_server']
                    .split(',');

                clientLogStreamingConfig['externalServerArray'] = externalServerWithPortArray
                    .reduce(AnalyticsProfile.externalLogServerParserReducer, []);
            } else {
                clientLogStreamingConfig['externalServerArray']
                    .push(AnalyticsProfile.getDefaultExternalLogServerConfig());
            }

            const { sensitive_log_profile: sensLogProf } = config;

            if (sensLogProf && !(sensLogProf instanceof SensitiveLogProfileConfig)) {
                config['sensitive_log_profile'] = new SensitiveLogProfileConfig({
                    data: { config: sensLogProf },
                });
            } else {
                config['sensitive_log_profile'] = new SensitiveLogProfileConfig();
            }

            config.analyticsEnabledFor = [];

            const analyticsPropertyList = Object.keys(analyticsProfileAnalyticsTypeLabelHash);

            config.analyticsEnabledFor = analyticsPropertyList.filter(key => config[key]);
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig()),
                { client_log_streaming_config: strConfig } = config;

            if (strConfig.externalServerArray && strConfig.externalServerArray.length > 0) {
                const serverArray = strConfig.externalServerArray
                    .reduce(AnalyticsProfile.externalLogServerFormatterReducer, []);

                strConfig['external_server'] = serverArray.join(',');
            }

            delete strConfig['externalServerArray'];

            if (!this.useExternalLogs) {
                delete config['client_log_streaming_config'];
            }

            const { sensitive_log_profile: sensLogProf } = config;

            if (sensLogProf && sensLogProf instanceof SensitiveLogProfileConfig) {
                const dataToSave = sensLogProf.dataToSave();

                if (dataToSave) {
                    config['sensitive_log_profile'] = dataToSave;
                } else {
                    delete config['sensitive_log_profile'];
                }
            }

            const { analyticsEnabledFor } = config;
            const analyticsPropertyList = Object.keys(analyticsProfileAnalyticsTypeLabelHash);

            if (analyticsEnabledFor && analyticsEnabledFor.length) {
                analyticsPropertyList.forEach(key => {
                    config[key] = analyticsEnabledFor.includes(key);
                });
            } else {
                analyticsPropertyList.forEach(value => config[value] = false);
            }

            delete config.analyticsEnabledFor;

            return config;
        }

        /**
         * Sets "exclude_http_error_codes", "ranges", and "resp_code_block" properties on config.
         * @param {Object} respCodes - Object containing response code properties to be set on
         *     config.
         */
        handleRespCodesUpdate(respCodes) {
            angular.extend(this.getConfig(), respCodes);
        }

        /**
         * Removes element from ExternalServer array by index.
         * @param {number} index
         */
        removeExternalLogServer(index) {
            const config = this.getConfig();
            const { client_log_streaming_config: strConfig } = config;

            strConfig.externalServerArray.splice(index, 1);
        }

        /**
         * Adds new value to ExternalServer array.
         */
        addExternalLogServer() {
            const config = this.getConfig();
            const { client_log_streaming_config: strConfig } = config;

            strConfig.externalServerArray.push(
                AnalyticsProfile.getDefaultExternalLogServerConfig(),
            );
        }
    }

    Object.assign(AnalyticsProfile.prototype, {
        objectName: 'analyticsprofile',
        windowElement: 'analytics-profile-modal',
    });

    return AnalyticsProfile;
}]);

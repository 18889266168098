/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name LogRecordCollection
 * @description
 *
 *     Log records collection.
 *
 *     Differs from regular collections by filter query param  - can be multiple and values
 *     look like "eq(fieldName, fieldValue)".
 *
 *     Also filter values can be set through setFilter rather than setParam.
 */
angular.module('aviApp').factory('LogRecordCollection', [
'Collection', 'LogRecord',
function(Collecton, LogRecord) {
    class LogRecordCollection extends Collecton {
        constructor(args = {}) {
            super(args);

            this.vsId = args.vsId;

            /**
             * Need this to render data transfer bar chart.
             * @type {Object}
             * @property {number} maxDuration - max data transfer duration
             * @property {Moment} minTimestamp
             * @property {Moment} maxTimestamp
             */
            this.timeline = {};
        }

        /**
         * Set's user defined filters on collection.
         * @param args
         * @public
         * @see {@link LogRecordCollDataSource.setFilters}
         */
        setFilter(...args) {
            const dataSource = this.getDataSourceByFieldName('config');

            dataSource.setFilters(...args);
        }

        /** @override */
        emptyData(...args) {
            super.emptyData(...args);
            this.timeline = {};
        }
    }

    angular.extend(LogRecordCollection.prototype, {
        objectName_: angular.copy(LogRecord.prototype.objectName),
        itemClass_: LogRecord,
        sortBy: '-report_timestamp',
        defaultDataSources_: 'list',
        allDataSources_: {
            list: {
                source: 'LogRecordCollDataSource',
                transformer: 'LogRecordListDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        },
    });

    return LogRecordCollection;
}]);

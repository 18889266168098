/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './IpAddrGroupCreateController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('IpAddrGroupCreateController', [
'$scope',
'Regex',
'Base',
'CloudCollection',
'l10nService',
function(
    $scope,
    Regex,
    Base,
    CloudCollection,
    l10nService,
) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.Regex = Regex;
    $scope.cloudCollection = new CloudCollection({ isStatic: true });

    /**
    * Get keys from source bundles for template usage.
    */
    $scope.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    const base = new Base();

    /**
     * Configures CollectionGrid directive, works in conjunction with Collection instance declared
     * above.
     */
    $scope.serversGrid = {
        id: 'ip-addr-group-servers-list',
        fields: [{
            name: 'ip_addr',
            title: l10nService.getMessage(l10nKeys.ipAddressColumnLabel),
            template: '{{row.addr}}',
        }],
        rowId: 'addr',
        searchFields: ['addr'],
        multipleactions: [{
            title: l10nService.getMessage(l10nKeys.removeButtonLabel),
            do(servers) {
                $scope.editable.data.config.servers =
                    _.filter($scope.editable.data.config.servers, function(row) {
                        return !_.find(servers, function(server) {
                            return row == server;
                        });
                    });

                return true;
            },
        }],
    };

    $scope.init = function() {
        let apicEpgName;

        $scope.ui = {
            newAddr: {},
            select: 'ip',
            busy: false,
            disableGrid: false,
            countryCodes: undefined,
            apicepgs: undefined,
        };

        if ($scope.editable.data.config.country_codes) {
            $scope.ui.select = 'country_code';
        } else if ($scope.editable.data.config.apic_epg_name) {
            $scope.ui.select = 'epg';
            $scope.ui.disableGrid = true;
            apicEpgName = $scope.editable.data.config.apic_epg_name.split(':');

            if (apicEpgName.length === 3) {
                $scope.editable.data.config.apic_epg_name =
                    `${apicEpgName[1]}:${apicEpgName[2]}`;
            }
        }

        base.request('GET', '/api/geoipdb/').then(function(rsp) {
            if (rsp && rsp.data) {
                $scope.ui.countryCodes = _.sortBy(rsp.data.country, 'name');
            }
        });

        base.request('GET', '/api/apicepgs/all').then(function(rsp) {
            if (rsp && rsp.data) {
                $scope.ui.apicepgs = rsp.data;
            }
        });

        $scope.cloudCollection.load().then(function() {
            $scope.ui.apic_mode = _.any($scope.cloudCollection.items, function(cloud) {
                return cloud.data.config.apic_mode === true;
            });
        });
    };

    $scope.importFile = function() {
        let containsValidIPs = false;

        $scope.editable.errors = '';

        _.each($scope.ui.newAddr.file.split('\n'), function(addr) {
            addr = addr.trim();

            if (addr && addr.match(Regex.anyIPIPSubnetIPv4RangeList)) {
                $scope.addAddr(addr);
                containsValidIPs = true;
            }
        });

        if (!containsValidIPs) {
            $scope.editable.errors =
                l10nService.getMessage(l10nKeys.fileHasNoValidIpAddressesErrorMessage);
        }

        $scope.ui.newAddr.file = '';
    };

    $scope.addAddr = function(addr) {
        $scope.ui.disableGrid = false;
        $scope.editable.errors = '';

        if (addr == null || _.isUndefined(addr) || addr === '') {
            return;
        }

        addr = addr.trim();

        if (!$scope.editable.data.config.servers) {
            $scope.editable.data.config.servers = [];
        }

        if (duplicateAddress(addr)) {
            $scope.editable.errors = l10nService.getMessage(l10nKeys.duplicateAddressErrorMessage);
        } else {
            $scope.editable.data.config.servers.push({
                addr,
            });
        }

        $scope.ui.newAddr = {};
    };

    $scope.selectEPG = function() {
        $scope.editable.data.config.servers = [];
        $scope.editable.data.config.tenant = $scope.Auth.getTenantName();
        $scope.ui.busy = true;
        base.request(
            'GET',
            `/api/apicepgeps/?epg_name=${$scope.editable.getConfig().apic_epg_name}`,
        )
            .then(function(rsp) {
                const serverList = rsp.data;

                _.each(serverList, function(server) {
                    $scope.editable.data.config.servers.push({
                        addr: server.ep_ip.addr,
                    });
                });
                $scope.ui.disableGrid = true;
                $scope.ui.busy = false;
            }).catch(function(errors) {
                $scope.editable.errors = errors.data;
                $scope.ui.busy = false;
            });
    };

    $scope.clearOptions = function() {
        $scope.ui.disableGrid = false;
        $scope.ui.newAddr.file = '';
        $scope.ui.newAddr = {};

        if ($scope.editable.data.config.country_codes) {
            delete $scope.editable.data.config.country_codes;
        }

        if ($scope.editable.data.config.apic_epg_name) {
            delete $scope.editable.data.config.apic_epg_name;
        }

        $scope.editable.data.config.servers = [];
    };

    /**
     * Returns true if address already exists in the list of server addresses.
     * @param  {string} address - IP address, range, or mask.
     * @return {boolean}
     */
    function duplicateAddress(address) {
        return _.some($scope.editable.data.config.servers, function(server) {
            return server.addr === address;
        });
    }

    $scope.$on('$destroy', function() {
        $scope.cloudCollection.destroy();
        base.cancelRequests();
    });
}]);

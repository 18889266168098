/**
 * Module containing scripts related components.
 * @preferred
 * @module ScriptsModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    rateLimitersComponentOptions,
} from './components';
import {
    ControlScript,
    ControlScriptCollection,
    DataScriptSet,
    DataScriptSetCollection,
} from '.';

const scriptsModule = angular.module('avi/scripts');

const components = [
    {
        name: 'rateLimiters',
        options: rateLimitersComponentOptions,
    },
];

const factories = [{
    dependency: ControlScript,
    name: 'ControlScript',
}, {
    dependency: ControlScriptCollection,
    name: 'ControlScriptCollection',
}, {
    dependency: DataScriptSet,
    name: 'DataScriptSet',
}, {
    dependency: DataScriptSetCollection,
    name: 'DataScriptSetCollection',
}];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(
        scriptsModule,
        'factory',
        name,
        dependency,
    );
});

components.forEach(({ name, options }) => scriptsModule.component(name, options));

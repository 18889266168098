/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc constant
 * @type function
 * @description
 *
 *     Joins subdomain and domain with no separator. Domain is supposed to start with dot.
 */
angular.module('aviApp').constant('fqdnJoin',
    function(subdomain = '', domain = '') {
        return `${subdomain}${domain}`;
    });

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'ping-access-agent-modal';
const prefix = `${moduleName}.${componentName}`;

export const newPingAccessAgentHeader = `${prefix}.newPingAccessAgentHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const pingAccessAgentPropertiesLabel = `${prefix}.pingAccessAgentPropertiesLabel`;

export const ENGLISH = {
    [newPingAccessAgentHeader]: 'New PingAccess Agent',
    [nameInputLabel]: 'Name',
    [pingAccessAgentPropertiesLabel]: 'PingAccess Agent Properties',
};

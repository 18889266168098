/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import classnames from 'classnames';
import { ConnectedPosition } from '@angular/cdk/overlay';
import {
    BOTTOM_CONNECTED_POSITION,
    BOTTOM_LEFT_CONNECTED_POSITION,
    BOTTOM_RIGHT_CONNECTED_POSITION,
    TooltipPosition,
    TOP_CONNECTED_POSITION,
    TOP_LEFT_CONNECTED_POSITION,
    TOP_RIGHT_CONNECTED_POSITION,
} from '../../directives/avi-tooltip';
import './avi-tooltip-icon.less';

const positionMap = new Map([
    [BOTTOM_CONNECTED_POSITION, TooltipPosition.BOTTOM_POSITION],
    [BOTTOM_LEFT_CONNECTED_POSITION, TooltipPosition.BOTTOM_LEFT_POSITION],
    [BOTTOM_RIGHT_CONNECTED_POSITION, TooltipPosition.BOTTOM_RIGHT_POSITION],
    [TOP_CONNECTED_POSITION, TooltipPosition.TOP_POSITION],
    [TOP_LEFT_CONNECTED_POSITION, TooltipPosition.TOP_LEFT_POSITION],
    [TOP_RIGHT_CONNECTED_POSITION, TooltipPosition.TOP_RIGHT_POSITION],
]);

/**
 * @description Component for an tooltip icon that shows transcluded content on hover.
 * @author alextsg
 */
@Component({
    selector: 'avi-tooltip-icon',
    templateUrl: './avi-tooltip-icon.component.html',
})
export class AviTooltipIconComponent {
    /**
     * Classname for the Clarity icon.
     */
    @Input()
    public shape = 'info-circle';

    /**
     * Size of the icon.
     */
    @Input()
    public size = '20';

    /**
     * Delay for the tooltip content to appear.
     */
    @Input()
    public delay = 250;

    /**
     * True to show the tooltip content when the icon is clicked.
     */
    @Input()
    public showOnClick = false;

    /**
     * List of positions to prioritize for rendering the tooltip content.
     */
    @Input()
    public positionsPriority?: ConnectedPosition[];

    /**
     * Classes to be set on the cds-icon element.
     */
    @Input()
    public iconClassName: string;

    /**
     * Color of the icon will be displayed from status attribute.
     */
    @Input()
    public status = '';

    /**
     * If true, icon will be displayed as solid.
     */
    @Input('solid')
    private set setSolid(solid: boolean | '') {
        this.solid = solid === '' || Boolean(solid);
    }

    /**
     * Called when the tooltip render position has changed.
     */
    @Output()
    public onPositionChange = new EventEmitter();

    /**
     * If true, icon will be displayed solid.
     */
    public solid = false;

    /**
     * Handler for the position change of the tooltip content. Used to reposition the caret.
     */
    public handlePositionChange(connectedPosition: ConnectedPosition): void {
        this.onPositionChange.emit(positionMap.get(connectedPosition));
    }

    /**
     * Returns the classes to be set on the cds-icon element.
     */
    public getIconClassNames(): string {
        return classnames('avi-tooltip-icon__icon', this.iconClassName);
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './alertConfigGridExpander.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  alertConfigGridExpander
 * @description  Displays Alert Config rule expander info.
 */
function Controller(l10nService) {
    /**
    * Get keys from source bundles for template usage.
    */
    this.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    this.$onInit = function() {
        const self = this;

        this.alertRule = [this.config.alert_rule];

        this.gridConfig = {
            id: 'alertconfig-list',
            fields: [{
                title: l10nService.getMessage(l10nKeys.columnTitleType),
                name: 'type',
                transform() {
                    return self.config.source.enumeration();
                },
            }],
            rowId(row) {
                return self.config.uuid;
            },
            layout: {
                hideDisplaying: true,
                hideSearch: true,
                hideEditColumns: true,
            },
        };

        if (Array.isArray(this.config.alert_rule.sys_event_rule)) {
            this.gridConfig.fields.push({
                title: l10nService.getMessage(l10nKeys.columnTitleEvents),
                name: 'event',
                transform(row) {
                    return self.getRuleString(row.sys_event_rule);
                },
            });
        } else if (Array.isArray(this.config.alert_rule.metrics_rule)) {
            this.gridConfig.fields.push({
                title: l10nService.getMessage(l10nKeys.columnTitleMetrics),
                name: 'metric',
                transform(row) {
                    return self.getRuleString(row.metrics_rule);
                },
            });
        }
    };

    /**
     * Returns a string to be displayed in the Event(s) or Metric(s) column. Combines the
     * operator and not_cond booleans.
     * @param  {Object[]} rules - Array of events or metrics.
     * @return {string}
     */
    this.getRuleString = function(rules) {
        const operator = this.config.alert_rule.operator.enumeration('OPERATOR_').toLowerCase();

        return rules.map(function(rule) {
            const id = rule.event_id || rule.metric_id;

            return (rule.not_cond ? 'not ' : '') + id.enumeration();
        }).join(`, ${operator} `);
    };
}

Controller.$inject = [
    'l10nService',
];

angular.module('aviApp').component('alertConfigGridExpander', {
    bindings: {
        config: '<',
    },
    controller: Controller,
    template: '<grid config="$ctrl.gridConfig" rows="$ctrl.alertRule"></grid>',
});

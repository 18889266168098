/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './StringGroupCreateController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name StringGroupCreateController
 */
angular.module('aviApp').controller('StringGroupCreateController', [
'$scope',
'$window',
'aviAlertService',
'StringGroup',
'AviConfirmService',
'l10nService',
function(
    $scope,
    $window,
    aviAlertService,
    StringGroup,
    AviConfirmService,
    l10nService,
) {
    /**
    * Get keys from source bundles for template usage.
    */
    $scope.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    const gridConfig = {
        fields: [{
            title: l10nService.getMessage(l10nKeys.stringColumnLabel),
            name: 'key',
        }],
        rowId: StringGroup.generateStringObjId,
        searchFields: ['key'],
        multipleactions: [{
            title: l10nService.getMessage(l10nKeys.removeButtonLabel),
            do: rows => {
                $scope.editable.removeStrObjs(rows);
            },
        }],
    };

    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.stringGrid = angular.copy(gridConfig);
    $scope.stringGrid.id = 'string-group-create-sgtype-list';
    $scope.mapGrid = angular.copy(gridConfig);
    $scope.mapGrid.id = 'string-group-create-nonsgtype-list';

    $scope.mapGrid.fields[0].title = l10nService.getMessage(l10nKeys.keyColumnLabel);

    $scope.mapGrid.fields.push({
        title: l10nService.getMessage(l10nKeys.valueColumnLabel),
        name: 'value',
    });

    $scope.mapGrid.searchFields.push('value');

    $scope.newString = {};

    $scope.init = () => {
        $scope.mapView = $scope.editable.getConfig().type;
    };

    /**
     * File upload event handler. Adds strings to the list.
     * @public
     */
    $scope.importFile = function() {
        let duplicateVals = false;

        const { type } = $scope.editable.getConfig();

        $scope.newString.file.split('\n').forEach(str => {
            let key = str.trim(),
                value,
                firstCommaPos;

            if (!str) {
                return;
            }

            if (type !== 'SG_TYPE_STRING') {
                firstCommaPos = key.indexOf(',');

                if (firstCommaPos !== -1) {
                    value = key.slice(firstCommaPos + 1).trim();
                    key = key.slice(0, firstCommaPos).trim();
                }
            }

            const strObj = {
                key,
                value,
            };
            // Force adds strObj to list
            const strObjIsUnique = $scope.editable.addStringObj(strObj, true);

            if (!strObjIsUnique) {
                duplicateVals = true;
            }
        });

        if (duplicateVals) {
            aviAlertService.throw(l10nService.getMessage(l10nKeys.duplicateValuesAlert));
        }
    };

    /**
     * Adds kv object to group.
     * @param {Object} kvObj - kv trying to be added
     * @public
     */
    $scope.addString = function(kvObj) {
        kvObj = kvObj || $scope.newString;

        const strObjAdded = $scope.editable.addStringObj(kvObj);

        if (strObjAdded === false) {
            AviConfirmService.confirm(l10nService.getMessage(l10nKeys.replaceDuplicateConfirmation))
                .then(() => {
                    $scope.editable.addStringObj(kvObj, true);
                    $scope.newString = {};
                });
        } else {
            $scope.newString = {};
        }
    };

    /**
     * Since setting map property is async (because of confirmation dialog) we have to
     * introduce a separate `ui` value just for checkbox and update actual one when user has
     * confirmed or cancelled this change.
     */
    $scope.onViewTypeChange = function() {
        const config = $scope.editable.getConfig();

        //mapView already has a new, 'changed' value
        if (!config.kv.length ||
            $window.confirm(l10nService.getMessage(l10nKeys.discardedCurrentValuesConfirmation))
        ) {
            config.type = $scope.mapView;
            $scope.newString.value = undefined;
            $scope.editable.discardAllStrings();
        } else {
            //restore old value
            $scope.mapView = config.type;
        }
    };
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function anomalyListCollDataSourceFactory(ListCollDataSource, Timeframe) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.ListCollDataSource
     * @author Ashish Verma
     * @desc ListCollDataSource of {@link module:avi/app.AnomalyListCollDataSource
     *      AnomalyListCollDataSource}.
     */
    class AnomalyListCollDataSource extends ListCollDataSource {
        /**
         * @override
         */
        getRequestParams_() {
            const params = super.getRequestParams_();

            params['step'] = Timeframe.selected().step;

            return params;
        }
    }

    Object.assign(AnomalyListCollDataSource.prototype, {
        hasSearch: false,
        hasSorting: false,
        hasPagination: false,
        hasTotalNumberOfItems: true,
    });

    return AnomalyListCollDataSource;
}

anomalyListCollDataSourceFactory.$inject = [
    'ListCollDataSource',
    'Timeframe',
];

angular.module('avi/app').factory(
    'AnomalyListCollDataSource',
    anomalyListCollDataSourceFactory,
);

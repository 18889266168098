/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { SECRET_STUB_STR } from 'ng/shared';
import * as patterns from 'ng/utils/regex.utils';

angular.module('aviApp')

    /**
     * @ngdoc constant
     * @name appDefaultState
     * @description
     *     Application default route state name.
     */
    .constant('appDefaultState', 'authenticated.application.dashboard')

    /**
     * @ngdoc constant
     * @name noValueLogLabel
     * @description
     *
     *     For some log properties we want sometimes have no data and want to show some kind of
     *     text placeholder.
     *
     */
    .constant('noValueLogLabel', '<NO-DATA>')

    .constant('HTTP_HEADERS', [
        'Accept', 'Accept-Charset', 'Accept-Encoding', 'Accept-Language', 'Accept-Ranges', 'Age',
        'Allow', 'Alternates', 'Authorization', 'Cache-Control', 'Connection', 'Content-Base',
        'Content-Disposition', 'Content-Disposition', 'Content-Encoding', 'Content-Language',
        'Content-Length', 'Content-Location', 'Content-MD5', 'Content-Range', 'Content-Type',
        'Content-Version', 'Date', 'Derived-From', 'ETag', 'Expect', 'Expires', 'From', 'Host',
        'If-Match', 'If-Modified-Since', 'If-None-Match', 'If-Range', 'If-Unmodified-Since',
        'Last-Modified', 'Link', 'Location', 'Max-Forwards', 'MIME-Version', 'Pragma',
        'Proxy-Authenticate', 'Proxy-Authorization', 'Public', 'Range', 'Referer',
        'Retry-After', 'Server', 'Title', 'TE', 'Trailer', 'Transfer-Encoding', 'Upgrade', 'URI',
        'User-Agent', 'Vary', 'Via', 'Warning', 'WWW-Authenticate',
    ])

    /**
     * @ngdoc constant
     * @name poolServersLimit
     * @description
     *
     *     There is an API limit for a number of Servers in a Pool.
     *
     */
    .constant('poolServersLimit', 5000)

    /**
     * @ngdoc constant
     * @name httpHeaderNameDuplicatePostfix
     * @description
     *
     *     Postfix to make repeated header names unique for the sake of comparison and presenting
     *     diff. Used characters are not allowed to be used as a header name in accordance with
     *     HTTP 1.1.
     */
    .constant('httpHeaderNameDuplicatePostfix', ':://')

    /**
     * @ngdoc constant
     * @name secretStubStr
     * @description
     *
     *     Backend returns special string instead of actual sensitive information to show that
     *     it is set.
     */
    .constant('secretStubStr', SECRET_STUB_STR)

    /**
     * @ngdoc constant
     * @name responseErrorInterceptorExceptions
     * @description
     *
     *     Hash of URLs with array of HTTP error response codes to ignore in $http interceptor.
     */
    .constant('responseErrorInterceptorExceptions', {
        '/api/mesos-verify-login': [401],
    })

    /**
     * @ngdoc constant
     * @name itemObjectTypeToClassName
     * @description
     *
     *     Hash of Item constructor factory names by the objectName (basically api objectType).
     */
    .constant('itemObjectTypeToClassName', {
        networkprofile: 'NetworkProfile',
        applicationprofile: 'ApplicationProfile',
    })

    /**
     * @ngdoc constant
     * @name backEndDateStringFormat
     * @description
     *
     *     String value to be passed into moment().format method to get timestamp in a string
     *     form it comes from analytics APIs.
     */
    .constant('backendDateStringFormat', 'YYYY-MM-DDTHH:mm:ssZ')

    /**
     * @ngdoc constant
     * @name Regex
     * @description Dictionary of various regular expressions used by app.
     */
    .constant('Regex', patterns)

    /**
     * @ngdoc constant
     * @name licenseTierComparisonDocSrc
     * @description Path of license tier comparison datasheet.
     */
    .constant('licenseTierComparisonDocSrc', 'src/assets/docs/license-tiers-comparison.pdf');

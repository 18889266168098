/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').factory('ControllerFaultDataTransformer', [
'RevisedDataTransformer', RevisedDataTransformer => {
    return class ControllerFaultDataTransformer extends RevisedDataTransformer {
        /**
         * API is returning a hash of faults instead of the list.
         * @override
         **/
        processResponse(rsp) {
            let results = [];

            if ('results' in rsp.data) {
                results = _.map(rsp.data.results, (row, type) => {
                    const config = {
                        type,
                        faults: row,
                    };

                    return { config };
                });
            }

            rsp.data.results = results;
            rsp.data.count = results.length;

            return rsp;
        }
    };
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name MetricsDimensionDataTransformer
 * @description
 *
 *     Makes a row for every series received, wrapping series in Series class and adding config.id.
 */
angular.module('avi/vs/security').factory('MetricsDimensionDataTransformer', [
'RevisedDataTransformer', 'Series', 'AggSeries',
function(RevisedDataTransformer, Series, AggSeries) {
    return class MetricsDimensionDataTransformer extends RevisedDataTransformer {
        /** @override */
        processResponse(resp, req) {
            const
                { series } = resp.data,
                results = [];

            if (series && series.length) {
                results.push(
                    ...series.map(
                        series => this.constructor.seriesTransform_(series, req),
                    ),
                );
            }

            resp.data.count = results.length;//no good as we won't know actual count
            resp.data.results = results;

            return resp;
        }

        /**
         * Wraps SeriesData objects into Series class and returns it along with series/row/item id.
         * @param {SeriesData} seriesData
         * @param {Object} req
         * @returns {Object}
         * @protected
         * @static
         */
        static seriesTransform_(seriesData, req) {
            const
                {
                    step,
                    limit,
                    dimension_aggregation: dimensionAgg,
                    dimensions,
                } = req,
                { header, data: values } = seriesData,
                dimensionData = header['dimension_data'] && header['dimension_data'][0],
                dimensionId = dimensionData && dimensionData['dimension_id'] || '',
                dimensionsList = dimensions ? dimensions.split(',') : [];

            const
                [dataPoint] = values,
                isAggregated = dataPoint && !('timestamp' in dataPoint),
                SeriesConstructor = isAggregated ? AggSeries : Series;

            const series = new SeriesConstructor({
                itemId: header['entity_uuid'],
                seriesId: header['name'],
                dimensionId,
                title: dimensionId,
                aggregation: dimensionAgg,
                isAggregated,
                dimensions: dimensionsList,
                data: {
                    seriesData,
                    step,
                    limit,
                },
            });

            return {
                config: {
                    id: dimensionId,
                },
                series,
            };
        }
    };
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function arrayOfObjectsCollectionFactory(Collection) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Collection
     * @author Alex Malitsky, Ram Pal
     * @desc
     *      This collection is for making a collection call when the response is an array of
     *      objects. propertyToDisplay is the key in the object that should be used for the
     *      values/options in CollectionDropdown. Apic epgs and apicgraphinstances only.
     */
    class ArrayOfObjectsCollection extends Collection {
        constructor(oArgs) {
            if (!oArgs.propertyToDisplay) {
                throw new Error(
                    'Can\'t create ArrayOfObjectsCollection without `propertyToDisplay`',
                );
            }

            super(oArgs);
            this.propertyToDisplay = oArgs.propertyToDisplay;
        }
    }

    ArrayOfObjectsCollection.prototype.allDataSources_ = {
        list: {
            source: 'ListCollDataSource',
            transformer: 'ArrayOfObjectsListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    return ArrayOfObjectsCollection;
}

arrayOfObjectsCollectionFactory.$inject = [
    'Collection',
];

angular.module('avi/app')
    .factory('ArrayOfObjectsCollection', arrayOfObjectsCollectionFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name PKIProfile
 */
angular.module('aviApp').factory('PKIProfile', ['Item',
function(Item) {
    class PKIProfile extends Item {
        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            if (!('ca_certs' in config)) {
                config['ca_certs'] = [];
            }

            config['ca_certs'].forEach(
                (cert, index) => cert.index = index,
            );

            if (!('crls' in config)) {
                config['crls'] = [];
            }

            config['crls'].forEach(
                (crl, index) => crl.index = index,
            );
        }

        resolveCA(content) {
            return this.request(
                'post',
                '/api/printssl',
                { body: btoa(content) },
                null,
                'resolveca',
            ).then(rsp => rsp.data);
        }

        resolveCRL(content) {
            return this.request(
                'post',
                '/api/printssl', {
                    body: btoa(content),
                    type: 'crl',
                },
                null,
                'resolvecrl',
            ).then(rsp => rsp.data);
        }

        resolveUrl(url) {
            return this.request(
                'post',
                '/api/printssl', {
                    url,
                    type: 'crl',
                },
                null,
                'resolvecrl',
            ).then(rsp => rsp.data);
        }
    }

    angular.extend(PKIProfile.prototype, {
        objectName: 'pkiprofile',
        windowElement: 'prof-pki-profile-create',
    });

    return PKIProfile;
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../waf-log-entry.less';
import '../waf-logs-list.less';

/**
 * @constructor
 * @memberOf module:avi/logs
 * @mixes module:avi/logs.psmLogsListBindings
 * @see {@link  module:avi/logs.psmLogsListComponent}
 */
class PsmLogsListController {
    constructor() {
        /**
         * Expanded state. Used for expanding/collapsing all.
         */
        this.expanded = false;
    }

    /**
     * Click handler for expanding/collapsing all.
     */
    toggleExpand() {
        this.expanded = !this.expanded;
    }
}

/**
 * @name psmLogsListComponent
 * @memberOf module:avi/logs
 * @property {module:avi/logs.psmLogsListBindings} bindings
 * @description Component for a list of PSM Logs.
 * @author alextg
 */
angular.module('avi/logs').component('psmLogsList', {
    /**
     * @mixin psmLogsListBindings
     * @memberOf module:avi/logs
     * @property {Object[]} logs - Array of PSM Log entries.
     */
    bindings: {
        logs: '<',
    },
    controller: PsmLogsListController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-logs/waf-logs/' +
        'psm-logs-list/psm-logs-list.component.html',
});

/**
 * @module CloudModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { INsxtTier1SegmentAutomaticMode } from 'generated-types';
import { IMessageItemData, MessageItem } from 'ajs/modules/data-model';

interface INsxtTier1SegmentAutomaticModeData extends IMessageItemData {
    config: INsxtTier1SegmentAutomaticMode;
}

export class NsxtTier1SegmentAutomaticModeConfigItem extends MessageItem {
    public data: INsxtTier1SegmentAutomaticModeData;

    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'NsxtTier1SegmentAutomaticMode',
            ...args,
        };

        super(extendedArgs);
    }
}

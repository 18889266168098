const moduleName = 'ajs-legacy';
const componentName = 'crash-reports-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleFilename = `${prefix}.columnTitleFilename`;
export const downloadBtnLabel = `${prefix}.downloadBtnLabel`;
export const deleteBtnLabel = `${prefix}.deleteBtnLabel`;
export const coreFilesHeader = `${prefix}.coreFilesHeader`;

export const ENGLISH = {
    [columnTitleFilename]: 'Filename',
    [downloadBtnLabel]: 'Download',
    [deleteBtnLabel]: 'Delete',
    [coreFilesHeader]: 'Core Files',
};

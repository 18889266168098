/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name transitionProgressBar
 * @author Ram Pal
 * @description
 *
 *     Component shows horizontal progress bar during routing transitions.
 *
 **/
class TransitionProgressBarController {
    constructor($transitions, $timeout) {
        this.$transitions_ = $transitions;
        this.$timeout_ = $timeout;

        /**
         * Default value of percentage.
         * @type {number}
         */
        this.percentage = 0;

        /**
         * By default transition progress bar will be hidden.
         * @type {boolean}
         */
        this.showProgressbar = false;

        /**
         * Default value of property transitionFinished.
         * @type {boolean}
         */
        this.transitionFinished = false;

        /**
         * Initially transitionId_ will be set as 0.
         * @type {number}
         * @protected
         */
        this.transitionId_ = 0;

        /**
         * Default value of start time before transition.
         * @type {Date|null}
         * @protected
         */
        this.startTime_ = null;

        /**
         * Default value of fast transition duration.
         * @type {number}
         * @protected
         */
        this.fastTransitionDuration_ = 200;

        /**
         * Max duration before calling reset progress bar in transition finish and
         * it should match the transition duration set in css.
         * @type {number}
         * @protected
         */
        this.resetDuration_ = 500;
    }

    /**
     * Register transitions events.
     * @override
     */
    $onInit() {
        this.unregisterTransitionStart_ = this.$transitions_.onStart({},
            this.transitionStartHandler_.bind(this));
        this.unregisterTransitionFinish_ = this.$transitions_.onFinish({},
            this.transitionFinishHandler_.bind(this));
    }

    /**
     * Handler is called when transition starts.
     * @protected
     */
    transitionStartHandler_() {
        this.startTime_ = new Date();
        this.transitionId_++;

        const { transitionId_ } = this;

        this.percentage = 0;

        // Till transitionFinished is set as true, transition-duration in css will remain as 4s.
        this.transitionFinished = false;
        this.showProgressbar = true;

        // Timeout is used here to avoid percentage increase for fast transition
        this.startTimeout_ = this.$timeout_(() => {
            if (this.transitionId_ !== transitionId_) {
                return;
            }

            this.percentage = 90;
        }, this.fastTransitionDuration_);
    }

    /**
     * Handler is called when transition finishes.
     * @protected
     */
    transitionFinishHandler_() {
        if (this.isFastTransition_()) {
            this.$timeout_.cancel(this.startTimeout_);
            this.resetProgressBar_();

            return;
        }

        const { transitionId_ } = this;

        // When transitionFinished is set as true, transition-duration in css
        // will be set to 500ms from 4s.
        this.transitionFinished = true;

        // Timeout is used here so that css transition-duration should apply before this.
        this.$timeout_(() => {
            if (this.transitionId_ !== transitionId_) {
                return;
            }

            this.percentage = 100;

            // This timeout will make sure that percentage is set to 100 before reset gets called.
            this.$timeout_(() => {
                if (this.transitionId_ !== transitionId_) {
                    return;
                }

                this.resetProgressBar_();
            }, this.resetDuration_);
        });
    }

    /**
     * Checks if transition is shorter than this.fastTransitionDuration_.
     * @protected
     */
    isFastTransition_() {
        const transDelay = new Date() - this.startTime_;

        return transDelay < this.fastTransitionDuration_;
    }

    /**
     * Reset progress bar values.
     * @protected
     */
    resetProgressBar_() {
        this.percentage = 0;
        this.showProgressbar = false;
        this.transitionFinished = false;
        this.startTime_ = null;
    }

    /**
     * Transitions events gets unregistered.
     * @override
     */
    $onDestroy() {
        this.unregisterTransitionStart_();
        this.unregisterTransitionFinish_();
    }
}

TransitionProgressBarController.$inject = [
    '$transitions',
    '$timeout',
];

angular.module('aviApp').component('transitionProgressBar', {
    controller: TransitionProgressBarController,
    template:
        `<progress-bar
            type="transition"
            ng-if="$ctrl.showProgressbar"
            transition-finished="$ctrl.transitionFinished"
            percentage="$ctrl.percentage" />`,
});

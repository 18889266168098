/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './nat-addr-info-select.component.less';

const componentName = 'nat-addr-info-select';

/**
 * @constructor
 * @memberOf module:avi/policies/nat
 * @mixes module:avi/policies/nat.natAddrInfoSelectComponentBindings
 * @see {@link module:avi/policies/nat.natAddrInfoSelectComponent natAddrInfoSelectComponent}
 */
class NatAddrInfoSelectController {
    /**
     * Adds a new Instance of NatAddrInfo messageItem to natPolicyAction.natInfo
     */
    addNatIPAddrInfo() {
        this.natPolicyAction.natInfo.add();
    }

    /**
     * Removes passed index from natPolicyAction.natInfo repeatedMessageItem
     */
    deleteNatIPAddrInfo(index) {
        this.natPolicyAction.natInfo.remove(index);
    }
}

NatAddrInfoSelectController.$inject = [
    'schemaService',
    'Regex',
];

/**
 * @name natAddrInfoSelectComponent
 * @memberOf module:avi/policies/nat
 * @property {module:avi/policies/nat.NatAddrInfoSelectController} controller
 * @property {module:avi/policies/nat.natAddrInfoSelectComponentBindings} bindings
 * @descriptiion
 *      Component for IP Addr/IP Addr Range selection in NAT Rule modal.
 * @author Aravindh Nagarajan
 */
angular.module('avi/policies/nat').component('natAddrInfoSelect', {
    /**
     * @mixin natAddrInfoSelectComponentBindings
     * @memberOf module:avi/policies/nat
     * @property {module:avi/policies/nat.NatPolicyActionConfigItem} natPolicyAction
     */
    bindings: {
        natPolicyAction: '<',
    },
    controller: NatAddrInfoSelectController,
    templateUrl:
        `src/components/modals/nat-rule-modal/${componentName}/${componentName}.component.html`,
});

const moduleName = 'cloud';
const componentName = 'cloud-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const nameLabel = `${prefix}.nameLabel`;
export const typeLabel = `${prefix}.typeLabel`;
export const dhcpCheckboxLabel = `${prefix}.dhcpCheckboxLabel`;
export const objNamePrefixLabel = `${prefix}.objNamePrefixLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Cloud: {0}',
    [modalTitleNew]: 'New Cloud: {0}',
    [sectionTitleGeneral]: 'General',
    [nameLabel]: 'Name',
    [typeLabel]: 'Type',
    [dhcpCheckboxLabel]: 'DHCP',
    [objNamePrefixLabel]: 'Object Name Prefix',
};

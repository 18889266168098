/** @module LogsModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    IGridConfig,
    IGridRow,
} from 'ajs/components/common/grid/grid-data-model';
import {
    IIcapLog,
    IIcapRequestLog,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { IIcapLogViolation } from 'ajs/modules/logs';
import * as l10n from './icap-logs.l10n';
import template from './icap-logs.component.html';
import './icap-logs.component.less';

/** Formatted object containing values needed for Application>VS>Logs (expand twice). */
export interface IIcapLogDisplayRow extends IGridRow {
    action: {
        display: string;
        val: IIcapRequestLog['action'];
    };
    reason: {
        display: string;
        val: IIcapRequestLog['reason'];
    };
    threat_id: {
        display: string;
        val: IIcapRequestLog['threat_id'];
    };
    pool_name: {
        display: string;
        val: IIcapRequestLog['pool_name'];
    };
    icap_server_ip: {
        display: string;
        val: IIcapRequestLog['icap_server_ip'];
    };
    icap_headers_sent_to_server: {
        display: string;
        val: IIcapRequestLog['icap_headers_sent_to_server'];
    };
    icap_headers_received_from_server: {
        display: string;
        val: IIcapRequestLog['icap_headers_received_from_server'];
    };
    icap_server_port: {
        display: string;
        val: IIcapRequestLog['icap_server_port'];
    };
    violations: IIcapLogViolation[];
    latency: {
        display: string;
        val: IIcapRequestLog['latency'];
    };
    http_method: {
        display: string;
        val: IIcapRequestLog['http_method'];
    };
    complete_body_sent: {
        display: string;
        val: IIcapRequestLog['complete_body_sent'];
    };
    icap_absolute_uri: {
        display: string;
        val: IIcapRequestLog['icap_absolute_uri'];
    };
    icap_method: {
        display: string;
        val: IIcapRequestLog['icap_method'];
    };
    http_response_code: {
        display: string;
        val: IIcapRequestLog['http_response_code'];
    };
    modified_content_length: {
        display: string;
        val: IIcapRequestLog['modified_content_length'];
    };
    icap_response_code: {
        display: string;
        val: IIcapRequestLog['icap_response_code'];
    };
}

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @author akulaggarwal
 * @desc Component when ICAP logs present in VS for L7.
 */
class IcapLogsComponentController {
    public gridConfig: IGridConfig;
    public gridRows: IIcapLogDisplayRow[];
    public readonly l10nKeys = l10nKeys;

    /**
     * Data to be shown in ICAP logs table.
     * Note: here top level action represents most severe action out of entire list,
     *       not to be confused with action for each individual icap log entry
     */
    public readonly icapData: {
        action: IIcapLog['action'],
        logs: IIcapLogDisplayRow[],
    };

    /**
     * Parent function passed in to handle filter by new prop.
     */
    public updateSearch: (str: string) => void;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    public $onInit(): void {
        this.gridRows = this.icapData.logs;
        this.gridConfig = this.createIcapLogsGrid();
    }

    /** Creates and returns grid config. */
    private createIcapLogsGrid(): IGridConfig {
        return {
            expandedContainerTemplate:
                `<icap-log-list-expander
                     icap-log="::row"
                     update-search="::config.props.updateSearch"
                 ></icap-log-list-expander>`,
            props: {
                updateSearch: this.updateSearch,
            },
            fields: [{
                name: 'action',
                title: this.l10nService.getMessage(l10nKeys.gridColumnHeaderActionLabel),
                template: `<div
                               class="icap-logs__clickable-filter"
                               log-filter-click
                               key="icap_log.request_logs.action"
                               display-value="{{ ::row.action.display }}"
                               value="::row.action.val"
                               on-update="::config.props.updateSearch(str)"
                           ></div>`,
            }, {
                name: 'reason',
                title: this.l10nService.getMessage(l10nKeys.gridColumnHeaderReasonLabel),
                template: `<div
                               class="icap-logs__clickable-filter"
                               log-filter-click
                               key="icap_log.request_logs.reason"
                               display-value="{{ ::row.reason.display }}"
                               value="::row.reason.val"
                               on-update="::config.props.updateSearch(str)"
                           ></div>`,
            }, {
                name: 'violations',
                title: this.l10nService.getMessage(l10nKeys.gridColumnHeaderViolationsLabel),
                template: `<cds-icon
                               shape="exclamation-triangle"
                               size="22"
                               status="warning"
                               class="icap-logs__warning-icon"
                               ng-if="::row.violations.length"
                           ></cds-icon>
                           <span ng-if="::row.violations.length">{{ row.violations.length }}</span>
                           <div ng-if="::!row.violations.length">-</div>`,
            }],
            id: 'icap-logs',
            layout: {
                hideDisplaying: true, // TODO: Consider removing when multiple rows possible
                hideSearch: true,
            },
        };
    }
}

IcapLogsComponentController.$inject = [
    'l10nService',
];

export const icapLogsComponentOptions = {
    bindings: {
        icapData: '<',
        updateSearch: '<',
    },
    controller: IcapLogsComponentController,
    template,
};

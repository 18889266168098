/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name PoolGroupDeploymentPolicyCollection
 * @description
 *     Pool Group Deployment Policy collection.
 */
angular.module('aviApp').factory('PoolGroupDeploymentPolicyCollection', [
'Collection', 'PoolGroupDeploymentPolicy',
function(Collection, PoolGroupDeploymentPolicy) {
    class PoolGroupDeploymentPolicyCollection extends Collection {}

    angular.extend(PoolGroupDeploymentPolicyCollection.prototype, {
        objectName_: 'poolgroupdeploymentpolicy',
        windowElement_: 'pool-group-deployment-policy-modal',
        itemClass_: PoolGroupDeploymentPolicy,
    });

    return PoolGroupDeploymentPolicyCollection;
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './DataScriptSetListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name DataScriptSetListController
 * @description
 *
 *     List view for DataScriptSets.
 */
angular.module('aviApp').controller('DataScriptSetListController', [
'$scope',
'CRUDGridConfig',
'DataScriptSetCollection',
'DataScriptSet',
'l10nService',
function(
    $scope,
    CRUDGridConfig,
    DataScriptSetCollection,
    DataScriptSet,
    l10nService,
) {
    /**
    * Get keys from source bundles for template usage.
    */
    $scope.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    const collection = new DataScriptSetCollection();

    const { objectName } = collection;

    $scope.gridConfig = new CRUDGridConfig({
        id: `${objectName}-list-page`,
        collection,
        fields: [{
            name: 'data.config.name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            sortBy: 'name',
        }, {
            name: 'eventTypes',
            title: l10nService.getMessage(l10nKeys.columnTitleEventTypes),
            transform: row => {
                const eventTypes = row.getListOfEventTypes().sort();

                const eventTypeLabels = eventTypes
                    .map(type => DataScriptSet.getDSEventLabel(type));

                return eventTypeLabels.join(', ') || 'N/A';
            },
        }],
    });

    $scope.$on('$destroy', () => {
        collection.destroy();
    });
}]);

/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { L10nService } from '@vmw/ngx-vip';
import { WafPolicy } from '../../factories';
import template from './waf-learning-data-modal.component.html';
import './waf-learning-data-modal.component.less';

import * as l10n from './waf-learning-data-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class WafLearningDataModalController {
    /**
     * WafPolicy Item.
     */
    public wafPolicy: WafPolicy;

    /**
     * UUID of the VS referencing the WAFPolicy.
     */
    public vsId: string;

    /**
     * Learning data to be displayed using objectToHtmlList service, in the form of JSON data.
     */
    public learningDataHtmlList: string;

    /**
     * Errors from HTTP request to be displayed.
     */
    public errors: any;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private objectToHtmlList: any,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public async $onInit(): Promise<void> {
        try {
            const response = await this.wafPolicy.fetchLearningData(this.vsId);

            this.learningDataHtmlList = this.objectToHtmlList(response.data);
        } catch (errors) {
            this.errors = errors.data;
        }
    }
}

WafLearningDataModalController.$inject = [
    'objectToHtmlList',
    'l10nService',
];

/**
 * @description Modal component displaying WAF learning data.
 * @author alextsg
 */
export const wafLearningDataModalOptions = {
    bindings: {
        vsId: '@',
        wafPolicy: '<',
        closeModal: '&',
    },
    controller: WafLearningDataModalController,
    template,
};

/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

/**
 * Returns validatorFunction for AviRepeatedStringsComponent.
 *
 * @author Aravindh Nagarajan
 */
export function aviRepeatedStringsRangeValidator(range: [number, number]): ValidatorFn {
    /**
     * Returns null if it's a valid value - i.e. value is within range.
     * Returns validationError otherwise.
     */
    return (control: AbstractControl): ValidationErrors | null => {
        const [min = 0, max = Infinity] = range;
        const { value } = control;
        const { length: valueCount } = value;

        const errorObject = {
            aviRepeatedStringsValidation: valueCount,
        };

        if (valueCount < min || valueCount > max) {
            return errorObject;
        }

        return null;
    };
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function clipboardService(TooltipLight) {
    /**
     * Copies message to clipboard.
     * @param {string} message - message used as the source of text to be copied
     * @inner
     */
    function copy_(message) {
        const textarea = document.createElement('textarea');
        const { body } = document;

        textarea.value = message;
        textarea.style.opacity = 0;
        body.appendChild(textarea);

        const selection = document.getSelection();
        const priorSelection = selection.rangeCount > 0 ?
            selection.getRangeAt(0) : false;

        textarea.select();
        document.execCommand('copy');
        body.removeChild(textarea);

        if (priorSelection) {
            selection.removeAllRanges();
            selection.addRange(priorSelection);
        }
    }

    /**
     * Shows tooltip on the provided target.
     * @param {HTMLElement} target - target is used to show tooltip on it.
     * @inner
     */
    function showTooltip(target) {
        const tooltip = TooltipLight.create(
            target,
            'Copied to clipboard!',
        );

        setTimeout(() => {
            tooltip.fadeOut();
            setTimeout(() => tooltip.remove());
        }, 2000);
    }

    /**
     * Copies message to clipboard and shows tooltip.
     * @param {string} message - message used as the source of text to be copied.
     * @param {HTMLElement} target - target on which tooltip is displayed.
     * @public
     */
    function copy(message, target) {
        copy_(message);
        showTooltip(target);
    }

    return { copy };
}

/**
 * @ngdoc factory
 * @name clipboardService
 * @description
 *     Clipboard service provides various functionality related to clipboard.
 *     Currently it provides copy to clipboard functionality and shows tooltip confirmation.
 */
angular.module('avi/component-kit').factory('clipboardService', [
    'TooltipLight',
    clipboardService,
]);

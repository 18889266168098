/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'welcome';
const componentName = 'welcome-email-smtp-server-settings';
const prefix = `${moduleName}.${componentName}`;

export const userNameLabel = `${prefix}.userNameLabel`;
export const passwordLabel = `${prefix}.passwordLabel`;
export const fromAddressLabel = `${prefix}.fromAddressLabel`;
export const smtpServerLabel = `${prefix}.smtpServerLabel`;
export const portLabel = `${prefix}.portLabel`;
export const disableTlsLabel = `${prefix}.disableTlsLabel`;

export const ENGLISH = {
    [userNameLabel]: 'Username',
    [passwordLabel]: 'Password',
    [fromAddressLabel]: 'From Address',
    [smtpServerLabel]: 'SMTP Server',
    [portLabel]: 'Port',
    [disableTlsLabel]: 'Do not use TLS',
};

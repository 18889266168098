/**
 * @module Angular-Core
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { isUndefined } from 'underscore';
import { IAviDropdownOption } from 'ng/shared/components';
import { IEnumValue } from 'ajs/modules/core';

/**
 * Returns a dropdown option used in the AviDropdownComponent.
 */
export function createDropdownOption(
    value: IAviDropdownOption['value'],
    label?: string,
    tooltip?: string,
): IAviDropdownOption {
    if (isUndefined(value)) {
        throw new Error('Dropdown option must have a value');
    }

    const parsedLabel = isUndefined(label) ? value.toString() : label;
    const parsedTooltip = isUndefined(tooltip) ? parsedLabel : tooltip;

    return {
        value,
        label: parsedLabel,
        tooltip: parsedTooltip,
    };
}

/**
 * Returns dropdown options given a list of EnumValues.
 */
export function createOptionsFromEnumProps(values: IEnumValue[] = []): IAviDropdownOption[] {
    return values.map(({ value, label, description }) => {
        return createDropdownOption(value, label, description);
    });
}

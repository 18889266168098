/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { CREDENTIALS_TYPE } from './cloud-connector-user.types';

const labelHash = {
    [CREDENTIALS_TYPE.SSH]: 'SSH',
    [CREDENTIALS_TYPE.AZURE]: 'Azure',
    [CREDENTIALS_TYPE.GCP]: 'GCP',
    [CREDENTIALS_TYPE.NSXT]: 'NSX-T',
    [CREDENTIALS_TYPE.VCENTER]: 'vCenter',
};

/**
 * Returns the human-readable label for the credentials type.
 */
export function getTypeLabel(type: CREDENTIALS_TYPE): string {
    return labelHash[type];
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc constant
 * @type Function
 * @name gslbLocationAfterLoad
 * @param {Object} - GslbGeoLocation protobuf.
 * @returns {Object}
 * @description
 *
 *     Makes necessary modifications on the passed GslbGeoLocation object and returns it back.
 */
angular.module('aviApp').constant('gslbLocationAfterLoad', function(config) {
    if (!config || !config.location) {
        return config;
    }

    const { location } = config;

    if ('longitude' in location) {
        location.longitude = Math.toFixedN(location.longitude, 4);
    }

    if ('latitude' in location) {
        location.latitude = Math.toFixedN(location.latitude, 4);
    }

    return config;
});

const moduleName = 'ajs-legacy';
const componentName = 'traffic-clone-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const preserveClientIpInputLabel = `${prefix}.preserveClientIpInputLabel`;
export const subnetInputLabel = `${prefix}.subnetInputLabel`;
export const ipAddressInputLabel = `${prefix}.ipAddressInputLabel`;
export const ipAddressInputPlaceholder = `${prefix}.ipAddressInputPlaceholder`;
export const macAddressInputLabel = `${prefix}.macAddressInputLabel`;
export const macAddressInputPlaceholder = `${prefix}.macAddressInputPlaceholder`;
export const removeCloneServerButtonLabel = `${prefix}.removeCloneServerButtonLabel`;
export const addCloneServerButtonLabel = `${prefix}.addCloneServerButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit Traffic Clone Profile: {0}',
    [modalHeaderNew]: 'New Traffic Clone Profile: {0}',
    [nameInputLabel]: 'Name',
    [preserveClientIpInputLabel]: 'Preserve Client IP',
    [subnetInputLabel]: 'Subnet',
    [ipAddressInputLabel]: 'Server Address',
    [ipAddressInputPlaceholder]: 'IP Address',
    [macAddressInputLabel]: 'Server MAC Address',
    [macAddressInputPlaceholder]: 'MAC Address',
    [removeCloneServerButtonLabel]: 'Remove Clone Server',
    [addCloneServerButtonLabel]: 'Add Clone Server',
    [saveButtonLabel]: 'Save',
};

const moduleName = 'ajs-legacy';
const componentName = 'time-frame';
const prefix = `${moduleName}.${componentName}`;

export const past30minutesOptionLabel = `${prefix}.past30minutesOptionLabel`;
export const past6HoursOptionLabel = `${prefix}.past6HoursOptionLabel`;
export const pastDayOptionLabel = `${prefix}.pastDayOptionLabel`;
export const pastWeekOptionLabel = `${prefix}.pastWeekOptionLabel`;
export const pastMonthOptionLabel = `${prefix}.pastMonthOptionLabel`;
export const pastQuarterOptionLabel = `${prefix}.pastQuarterOptionLabel`;
export const pastYearOptionLabel = `${prefix}.pastYearOptionLabel`;

export const ENGLISH = {
    [past30minutesOptionLabel]: 'Past 30 Minutes',
    [past6HoursOptionLabel]: 'Past 6 Hours',
    [pastDayOptionLabel]: 'Past Day',
    [pastWeekOptionLabel]: 'Past Week',
    [pastMonthOptionLabel]: 'Past Month',
    [pastQuarterOptionLabel]: 'Past Quarter',
    [pastYearOptionLabel]: 'Past Year',
};

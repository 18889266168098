/**
 * Angular Build, Upgrade/downgrade stuff
 * @module Angular-Core
 * @preferred
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { Injector } from '@angular/core';
import { IPAMProfileCollection } from 'ajs/modules/ipam';
import {
    Auth,
    AviConfirmService,
    AviModalService,
    DefaultValues,
    HttpWrapper,
    InitialDataService,
    MyAccount,
    SchemaService,
    StateManager,
    StringService,
    Upload,
} from 'ajs/modules/core';
import { VCenterServerCollection } from 'ajs/modules/cloud';
import { PoolGroupCollection } from 'ajs/js/services/collections/PoolGroupCollection';
import {
    WafApplicationSignatureProvider,
    WafApplicationSignatureProviderCollection,
    WafPolicyPsmGroupCollection,
    WafPolicyPsmGroupMetric,
} from 'ajs/modules/waf';
import { LicensingService } from 'ajs/modules/licensing';
import {
    BackupConfiguration,
    BackupConfigurationCollection,
    SystemConfig,
} from 'ajs/modules/system';
import { Cloud } from 'ajs/js/services/items/Cloud';
import { MacroStackFactory } from 'ajs/modules/data-model';
import { CportalService } from 'ajs/modules/cportal/services';
import { FaultService } from 'ajs/modules/notifications';

const $injector = '$injector';

/**
 * Used to upgrade providers from AngularJS to be used in Angular.
 * @param serviceName Service to be injected from AngularJS.
 */
export function matchTargetConfigItemFactory(injector: Injector): any {
    return injector.get('MatchTargetConfigItem');
}

export const matchTargetConfigItemProvider = {
    deps: [$injector],
    provide: 'MatchTargetConfigItem',
    useFactory: matchTargetConfigItemFactory,
};

export const schemaServiceProvider = {
    deps: [$injector],
    provide: SchemaService,
    useFactory(injector: Injector): SchemaService {
        return injector.get('schemaService');
    },
};

export const aviModalProvider = {
    deps: [$injector],
    provide: AviModalService,
    useFactory(injector: Injector): AviModalService {
        return injector.get('AviModal');
    },
};

export const aviConfirmServiceProvider = {
    deps: [$injector],
    provide: AviConfirmService,
    useFactory(injector: Injector): AviConfirmService {
        return injector.get('AviConfirmService');
    },
};

export const wafPolicyPsmGroupCollectionProvider = {
    deps: [$injector],
    provide: WafPolicyPsmGroupCollection,
    useFactory(injector: Injector): typeof WafPolicyPsmGroupCollection {
        return injector.get('WafPolicyPsmGroupCollection');
    },
};

export const wafApplicationSignatureProviderCollectionProvider = {
    deps: [$injector],
    provide: WafApplicationSignatureProviderCollection,
    useFactory(injector: Injector): typeof WafApplicationSignatureProviderCollection {
        return injector.get('WafApplicationSignatureProviderCollection');
    },
};

export const wafApplicationSignatureProviderProvider = {
    deps: [$injector],
    provide: WafApplicationSignatureProvider,
    useFactory(injector: Injector): typeof WafApplicationSignatureProvider {
        return injector.get('WafApplicationSignatureProvider');
    },
};

export const wafPolicyPsmGroupMetricProvider = {
    deps: [$injector],
    provide: WafPolicyPsmGroupMetric,
    useFactory(injector: Injector): typeof WafPolicyPsmGroupMetric {
        return injector.get('WafPolicyPsmGroupMetric');
    },
};

export function timeframeFactory(injector: Injector): any {
    return injector.get('Timeframe');
}

export const timeframeProvider = {
    deps: [$injector],
    provide: 'Timeframe',
    useFactory: timeframeFactory,
};

export function appStateTreeFactory(injector: Injector): any {
    return injector.get('appStateTree');
}

export const appStateTreeProvider = {
    deps: [$injector],
    provide: 'appStateTree',
    useFactory: appStateTreeFactory,
};

export function $stateFactory(injector: Injector): any {
    return injector.get('$state');
}

export const $stateProvider = {
    deps: [$injector],
    provide: '$state',
    useFactory: $stateFactory,
};

export function upgradeNodeCollectionFactory(injector: Injector): any {
    return injector.get('UpgradeNodeCollection');
}

export const UpgradeNodeCollectionProvider = {
    deps: [$injector],
    provide: 'UpgradeNodeCollection',
    useFactory: upgradeNodeCollectionFactory,
};

export function upgradeServiceFactory(injector: Injector): any {
    return injector.get('upgradeService');
}

export const UpgradeServiceProvider = {
    deps: [$injector],
    provide: 'upgradeService',
    useFactory: upgradeServiceFactory,
};

export function licensingServiceFactory(injector: Injector): LicensingService {
    return injector.get('licensingService');
}

export const licensingServiceProvider = {
    deps: [$injector],
    provide: LicensingService,
    useFactory: licensingServiceFactory,
};

export const cloudConnectorUserCollectionProvider = {
    deps: [$injector],
    provide: 'CloudConnectorUserCollection',
    useFactory(injector: Injector): any {
        return injector.get('CloudConnectorUserCollection');
    },
};

export const cloudCollectionProvider = {
    deps: [$injector],
    provide: 'CloudCollection',
    useFactory(injector: Injector): any {
        return injector.get('CloudCollection');
    },
};

export const myAccountProvider = {
    deps: [$injector],
    provide: MyAccount,
    useFactory(injector: Injector): MyAccount {
        return injector.get('myAccount');
    },
};

export const ipamProfileCollectionProvider = {
    deps: [$injector],
    provide: IPAMProfileCollection,
    useFactory(injector: Injector): typeof IPAMProfileCollection {
        return injector.get('IPAMProfileCollection');
    },
};

export const vCenterServerCollectionProvider = {
    deps: [$injector],
    provide: VCenterServerCollection,
    useFactory(injector: Injector): typeof VCenterServerCollection {
        return injector.get('VCenterServerCollection');
    },
};

export const authServiceProvider = {
    deps: [$injector],
    provide: Auth,
    useFactory(injector: Injector): Auth {
        return injector.get('Auth');
    },
};

export const poolGroupCollectionProvider = {
    deps: [$injector],
    provide: PoolGroupCollection,
    useFactory(injector: Injector): typeof PoolGroupCollection {
        return injector.get('PoolGroupCollection');
    },
};

export const stringServiceProvider = {
    deps: [$injector],
    provide: StringService,
    useFactory(injector: Injector): StringService {
        return injector.get('stringService');
    },
};

function initialDataService(injector: Injector): InitialDataService {
    return injector.get('initialDataService');
}

export const initialDataServiceProvider = {
    deps: [$injector],
    provide: InitialDataService,
    useFactory: initialDataService,
};

export const stateManagerServiceProvider = {
    deps: ['$injector'],
    provide: StateManager,
    useFactory($injector: Injector): StateManager {
        return $injector.get('stateManagerService');
    },
};

export const uploadProvider = {
    deps: ['$injector'],
    provide: Upload,
    useFactory(i: Injector): typeof Upload {
        return i.get('Upload');
    },
};

export const macroStackProvider = {
    deps: [$injector],
    provide: MacroStackFactory,
    useFactory(injector: Injector): typeof MacroStackFactory {
        return injector.get('MacroStackFactory');
    },
};

export const systemConfigProvider = {
    deps: [$injector],
    provide: SystemConfig,
    useFactory(injector: Injector): typeof SystemConfig {
        return injector.get('SystemConfig');
    },
};

export const backupConfigItemProvider = {
    deps: [$injector],
    provide: BackupConfiguration,
    useFactory(injector: Injector): typeof BackupConfiguration {
        return injector.get('BackupConfiguration');
    },
};

export const backupConfigCollectionProvider = {
    deps: [$injector],
    provide: BackupConfigurationCollection,
    useFactory(injector: Injector): typeof BackupConfigurationCollection {
        return injector.get('BackupConfigurationCollection');
    },
};

export const faultServiceProvider = {
    deps: [$injector],
    provide: FaultService,
    useFactory(injector: Injector): typeof FaultService {
        return injector.get('FaultService');
    },
};

export const defaultValuesProvider = {
    deps: [$injector],
    provide: DefaultValues,
    useFactory(injector: Injector): typeof DefaultValues {
        return injector.get('defaultValues');
    },
};

export const cloudItemProvider = {
    deps: [$injector],
    provide: Cloud,
    useFactory(injector: Injector): typeof Cloud {
        return injector.get('Cloud');
    },
};

export const systemInfoServiceProvider = {
    deps: [$injector],
    provide: 'systemInfoService',
    useFactory(i: Injector): any {
        return i.get('systemInfoService');
    },
};

export const cportalServiceProvider = {
    deps: [$injector],
    provide: CportalService,
    useFactory(i: Injector): CportalService {
        return i.get('CportalService');
    },
};

export const HttpWrapperProvider = {
    deps: [$injector],
    provide: HttpWrapper,
    useFactory(injector: Injector): typeof HttpWrapper {
        return injector.get('HttpWrapper');
    },
};


/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../../../less/pages/administration/crash-reports.less';
import * as l10n from './CrashReportsController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @alias module:controller/CrashReportsController
 * @private
 */
class CrashReportsController {
    /**
     * Returns Core file name based on its file path.
     * @param {string} url - File path to Core file.
     * @returns {string}
     */
    static getNameFromUrl(url) {
        return angular.isString(url) ? url.slice(url.lastIndexOf('/') + 1) : '';
    }

    constructor(
        $http,
        $window,
        $q,
        l10nService,
    ) {
        this.$http = $http;
        this.$window = $window;
        this.rows = [];

        /**
        * Get keys from source bundles for template usage.
        */
        this.l10nKeys = l10nKeys;
        this.l10nService_ = l10nService;
        l10nService.registerSourceBundles(dictionary);

        const self = this;

        this.gridConfig = {
            id: 'crash-reports-list',
            rowId: 'url',
            searchFields: ['url'],
            expandedContainerTemplate: '<pre class="stack-trace">{{::row.stack_trace}}</pre>',
            expanderDisabled(row) {
                const { stack_trace: trace } = row;

                return angular.isUndefined(trace) || trace === '';
            },
            fields: [{
                name: 'name',
                title: this.l10nService_.getMessage(l10nKeys.columnTitleFilename),
                transform(row) {
                    return CrashReportsController.getNameFromUrl(row.url);
                },
            }],
            singleactions: [{
                title: this.l10nService_.getMessage(l10nKeys.downloadBtnLabel),
                class: 'icon-download-1',
                do(row) {
                    $window.open(row.url, '_blank');
                },
            }],
            multipleactions: [{
                title: this.l10nService_.getMessage(l10nKeys.deleteBtnLabel),
                do(rows) {
                    const queue = rows.map(row => {
                        const fileName = CrashReportsController.getNameFromUrl(row.url);
                        const api = `/api/fileservice?uri=controller://archive/${fileName}`;

                        return $http.delete(api);
                    });

                    $q.all(queue).finally(() => self.getList());

                    return true;
                },
            }],
        };
        this.getList();
    }

    /**
     * Requests Core file list.
     */
    getList() {
        this.$http.get('/api/fileservice?uri=controller://archive')
            .then(data => this.dataHandler(data));
    }

    /**
     * Handles Core file list.
     * @param {Object} response - Angular $http response object.
     */
    dataHandler(response) {
        this.rows = response.data.results;
    }
}

CrashReportsController.$inject = [
    '$http',
    '$window',
    '$q',
    'l10nService',
];

/**
 * @ngdoc controller
 * @name CrashReportsController
 * @module controller/CrashReportsController
 * @description
 *
 *     Controls Crash Report (i.e. Cores) list page in Administration>System.
 *
 */
angular.module('aviApp').controller('CrashReportsController', CrashReportsController);

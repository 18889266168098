/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './authentication-rules-list.component.less';

const componentName = 'authentication-rules-list';

/**
 * @constructor
 * @memberOf module:avi/policies/sso
 * @mixes module:avi/policies/sso.authenticationRulesListComponentBindings
 * @see {@link module:avi/policies/sso.authenticationRulesListComponent}
 */
class AuthenticationRulesListController {
    /**
     * Click handler for editing an authentication rule.
     * @param {module:avi/policies/sso.AuthenticationRuleConfigItem} rule
     */
    editRule(rule) {
        this.onEdit({ rule });
    }

    /**
     * Click handler for deleting an authentication rule.
     * @param {number} rule
     */
    deleteRule(index) {
        this.rules.remove(index);
    }
}

/**
 * @name authenticationRulesListComponent
 * @memberOf module:avi/policies/sso
 * @property {module:avi/policies/sso.AuthenticationRulesListController} controller
 * @property {module:avi/policies/sso.authenticationRulesListComponentBindings} bindings
 * @description
 *      Authentication Rules List Component.
 * @author Aravindh Nagarajan
 */
angular.module('avi/policies/sso').component('authenticationRulesList', {
    /**
     * @mixin authenticationRulesListComponentBindings
     * @memberOf module:avi/policies/sso
     * @property {module:avi/policies/sso.AuthenticationRuleConfigItem[]} rules
     * @property {Function} onEdit - method fires on editing a rule.
     * @property {Function} onDelete - method fires on deleting a rule.
     */
    bindings: {
        rules: '<',
        onEdit: '&',
        onDelete: '&',
    },
    controller: AuthenticationRulesListController,
    templateUrl:
        'src/components/modals/sso-policy-modal/authentication-policy/' +
        `${componentName}/${componentName}.component.html`,
});

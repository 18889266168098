/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name AsyncFactory
 */
angular.module('aviApp').factory('AsyncFactory', ['$q', '$interval', function($q, $interval) {
    /**
     * Allows for easy setup of polling with constructor then
     *   call start and stop to control the polling
     * @param {Function} pollingFunction Must return a promise when the
     *   operation is complete. Intervals will be skipped until this promise
     *   is resolved / rejected
     * @param {Object} timeoutConfig maxSkipped: number of times polling can
     *   not happen because pollingFunction has not resolved/rejected its
     *   promise timeoutCallback: callback that is called when maxSkipped is
     *   reached, use this to cancel your unresponsive async calls so
     *   polling can try again
     */
    const AsyncFactory = function(pollingFunction, timeoutConfig) {
        if (typeof pollingFunction !== 'function') {
            throw new Error('pollingFunction must be a function');
        }

        this.pollingFunction = pollingFunction;
        this.skipped = 0;
        timeoutConfig = timeoutConfig || {};
        this.maxSkipped = timeoutConfig.maxSkipped;
        this.timeoutCallback = timeoutConfig.callback;

        if (this.maxSkipped && !this.timeoutCallback || !this.maxSkipped && this.timeoutCallback) {
            throw new
            Error('Must define both timeoutCallback and maxSkipped, not one or the other');
        }

        if (this.timeoutCallback && typeof this.timeoutCallback !== 'function') {
            throw new Error('timeoutCallback must be a function');
        }

        this.outstanding = false;
        this.intervalId = null;
    };

    /**
     * Private function to check if given object looks like a promise
     * @param  {object} promise
     * @return Throws if not promise like, otherwise just exits
     */
    function promiseLike(promise) {
        if (!(promise && promise.finally && typeof promise.finally === 'function')) {
            throw new Error('Function must return a promise');
        }
    }

    /**
     * Private function that checks if the previous pollingFunction has
     *   completed before calling it again. If maxSkipped has been reached,
     *   timeoutCallback is called
     * @param   self    reference to the AsyncFactory, done to keep this
     *   function private
     * @param {null|number=} intervalId - ng.$interval.$$intervalId or null when there is no
     * intervalId set.
     */
    function repeated(self, intervalId) {
        intervalId = intervalId || self.intervalId && self.intervalId.$$intervalId;

        if (self.outstanding) {
            if (self.maxSkipped && self.maxSkipped <= self.skipped) {
                // Call callback passing empty object as context (instead of this object)
                self.timeoutCallback.apply(undefined, []);
            } else {
                self.skipped++;
            }

            // Not going to make another request until outstanding is false
            // It is the timeoutCallback's job to cancel the pending requests
            return;
        }

        // Call callback passing empty object as context (instead of this object)
        let promise = self.pollingFunction.apply(undefined, []);

        if (!promise || !promise.length) {
            promise = [promise];
        }

        // Check each object is a promise
        _.each(promise, function(p) {
            promiseLike(p);
        });

        $q.all(promise).finally(function() {
            //if we've already started a new $interval it doesn't make sense to update
            //parameters by rejected or resolved promise of the previous $interval polling function
            if (!self.intervalId || intervalId === self.intervalId.$$intervalId) {
                self.skipped = 0;
                self.outstanding = false;
            }
        });

        self.outstanding = true;
    }

    /**
     * Start the polling, first pollingFunction is called immediately
     * @param  {number} ms  Time between intervals in milliseconds, 0 makes request only once
     * @return {AsyncFactory}  Returns a reference to itself for chaining
     */
    AsyncFactory.prototype.start = function(ms) {
        if (!(typeof ms === 'number' && ms % 1 === 0)) {
            throw new Error('start(ms) - ms must be an integer');
        }

        if (this.intervalId) {
            throw new Error('Must call stop before starting again');
        }

        if (ms !== 0) {
            this.intervalId = $interval(function() {
                repeated(this);
            }.bind(this), ms);
        }

        repeated(this, this.intervalId && this.intervalId.$$intervalId);

        return this;
    };

    /**
     * Stop the polling, any pending callbacks must be canceled by the
     * caller.
     * @return {AsyncFactory} Returns a reference to itself for chaining.
     */
    AsyncFactory.prototype.stop = function() {
        $interval.cancel(this.intervalId);
        this.intervalId = null;
        this.skipped = 0;
        this.outstanding = false;

        return this;
    };

    /**
     * Returns true when asyncFactory is running.
     * @returns {boolean}
     */
    AsyncFactory.prototype.isActive = function() {
        return !!this.intervalId;
    };

    return AsyncFactory;
}]);

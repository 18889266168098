/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name HealthMonitorCollection
 * @description
 *
 *     Regular collection besides the fact we always need to hide few `internal` healthmonitor
 *     types such as HEALTH_MONITOR_GSLB and HEALTH_MONITOR_GSLB_LOCAL.
 */
angular.module('aviApp').factory('HealthMonitorCollection', [
'Collection', 'HealthMonitor',
function(Collection, HealthMonitor) {
    class HealthMonitorCollection extends Collection {
        constructor(args = {}) {
            const { params = {}, ...restArgs } = args;
            const extendedArgs = {
                ...restArgs,
                params: {
                    is_federated: false,
                    type: 'HEALTH_MONITOR_GSLB,HEALTH_MONITOR_GSLB_LOCAL',
                    exclude: 'type',
                    ...params,
                },
            };

            super(extendedArgs);
        }
    }

    angular.extend(HealthMonitorCollection.prototype, {
        objectName_: 'healthmonitor',
        itemClass_: HealthMonitor,
        windowElement_: 'prof-healthmonitor-create',
        serverDefaultsOverride_: {
            type: 'HEALTH_MONITOR_PING',
        },
    });

    return HealthMonitorCollection;
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/* typeahead works with search pills
 * for communication between search pills and typeahead */
angular.module('aviApp').service('logSearchMediator', function() {
    this.ta = {
        opened: false,
        suggClicked: false, //or timeoutId
        escClicked: false, //or timeoutId
        tabClicked: false, //or timeoutId
    };
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import './app-about-modal.less';
import * as l10n from './app-about-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const componentName = 'app-about-modal';

/**
 * @class
 * @constructor
 * @memberOf module:avi/app
 * @mixes module:avi/app.appAboutModalBindings
 * @see {@link module:avi/app.appAboutModalComponent}
 */
class AppAboutModalController {
    constructor(
        initialDataService,
        l10nService,
    ) {
        /**
         * @type {initialDataService}
         * @protected
         */
        this.initialDataService_ = initialDataService;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    $onInit() {
        const {
            controllerVersion,
            controllerBuild,
            controllerBuildDate,
            controllerPatch,
        } = this.initialDataService_;

        /**
         * Controller's version
         * @type {string}
         */
        this.controllerVersion = controllerVersion;

        /**
         * Controller's build
         * @type {number}
         */
        this.controllerBuild = controllerBuild;

        /**
         * Controller's build date
         * @type {string}
         */
        this.controllerBuildDate = controllerBuildDate;

        /**
         * Controller's patch.
         * @type {string}
         */
        this.controllerPatch = controllerPatch;
    }
}

AppAboutModalController.$inject = [
    'initialDataService',
    'l10nService',
];

/**
 * @name appAboutModalComponent
 * @memberOf module:avi/app
 * @property {module:avi/app.AppAboutModalController} controller
 * @desc
 *      Modal Component for displaying current Application version info.
 *      [verion, build, build date, patch etc]
 * @author AlexM, chitra
 */
angular.module('avi/app').component('appAboutModal', {
    controller: AppAboutModalController,
    /**
     * @mixin appAboutModalBindings
     * @memberOf module:avi/app
     * @property {Function} closeModal method to be called while closing the modal.
     */
    bindings: {
        closeModal: '&',
    },
    templateUrl:
        `src/components/modals/${componentName}/${componentName}.html`,
});

/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { IAppLearningParams } from 'generated-types';
import { IMessageItemData, MessageItem } from 'ajs/modules/data-model';

interface IAppLearningParamsData extends IMessageItemData {
    config: IAppLearningParams;
}

export class AppLearningParamsConfigItem extends MessageItem {
    public data: IAppLearningParamsData;

    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'AppLearningParams',
            ...args,
        };

        super(extendedArgs);
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name getGridMetricFieldConfig
 * @author Alex Malitsky
 * @description
 *
 *     Generates {@link grid} or {@link collectionGrid} field configuration object for metric chart
 *     columns. Takes a full series name to be shown. Can use a passed title or
 *     {@link NamesHelper#getTitle} as a fallback option.
 *
 */
angular.module('aviApp').service('getGridMetricFieldConfig', ['NamesHelper',
function(NamesHelper) {
    /**
     * @param {Series#name|Object} gridFieldConfig - When string passed we take it as a metric
     * id, for objects `require` property (also metric_id) is required.
     * @returns {GridFieldConfig|null} - null returned when wrong argument is passed.
     */
    return gridFieldConfig => {
        const defConfig = {
            metricChart: true,
            disabled(item) {
                return !item.dataFieldIsApplicable(this.require);
            },
        };

        let metricName;

        if (angular.isString(gridFieldConfig)) {
            metricName = gridFieldConfig;
        } else if (angular.isObject(gridFieldConfig) && 'require' in gridFieldConfig) {
            metricName = gridFieldConfig.require;
        } else {
            return null;
        }

        //we want to populate some default values based on the metricName in both param cases
        const config = {
            require: metricName,
            name: metricName,
            title: NamesHelper.getTitle(metricName),
        };

        if (angular.isObject(gridFieldConfig)) {
            angular.extend(config, gridFieldConfig);
        }

        return angular.extend(defConfig, config);
    };
}]);

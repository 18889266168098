/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @typedef {Object} UserProfileCollection
 * @description
 *     Collection of {UserProfile} items.
 */
angular.module('aviApp').factory('UserProfileCollection', ['Collection', 'UserProfile',
(Collection, UserProfile) => {
    const UserProfileCollection = class extends Collection {};

    angular.extend(UserProfileCollection.prototype, {
        objectName_: 'useraccountprofile',
        itemClass_: UserProfile,
        windowElement_: 'user-profile-create',
    });

    return UserProfileCollection;
}]);

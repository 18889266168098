/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'healthmonitor';
const componentName = 'healthmonitor-authentication';
const prefix = `${moduleName}.${componentName}`;

export const authenticationHeader = `${prefix}.authenticationHeader`;
export const authenticationTypeInputLabel = `${prefix}.authenticationTypeInputLabel`;
export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;

export const ENGLISH = {
    [authenticationHeader]: 'Authentication',
    [authenticationTypeInputLabel]: 'Authentication Type',
    [usernameInputLabel]: 'Username',
    [passwordInputLabel]: 'Password',
};

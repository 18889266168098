/**
 * @module ScriptModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { Collection } from 'ajs/modules/data-model';

/**
 * @description
 *
 *   Control Script collection
 *
 * @author Rachit Aggarwal
 */
export class ControlScriptCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'alertscriptconfig',
            objectType: 'Alertscriptconfig',
            permissionName: 'PERMISSION_ALERTCONFIG',
            windowElement: 'prof-controlscripts-create',
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('ControlScript');
    }
}

ControlScriptCollection.ajsDependencies = [
    'ControlScript',
];

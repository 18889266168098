const moduleName = 'ajs-legacy';
const componentName = 'bgp-peer-create-controller';
const prefix = `${moduleName}.${componentName}`;

export const removeBgpPeeringConfirmMessage = `${prefix}.removeBgpPeeringConfirmMessage`;
export const disableBgpPeeringBtnLabel = `${prefix}.disableBgpPeeringBtnLabel`;
export const editBgpHeader = `${prefix}.editBgpHeader`;
export const createBgpHeader = `${prefix}.createBgpHeader`;
export const timersHeader = `${prefix}.timersHeader`;
export const keepaliveIntervalInputLabel = `${prefix}.keepaliveIntervalInputLabel`;
export const holdTimeInputLabel = `${prefix}.holdTimeInputLabel`;
export const secInnerText = `${prefix}.secInnerText`;
export const placementNetworkInputLabel = `${prefix}.placementNetworkInputLabel`;
export const placementNetworkInputPlaceholder = `${prefix}.placementNetworkInputPlaceholder`;
export const removePeerBtnLabel = `${prefix}.removePeerBtnLabel`;
export const ipv4PrefixInputLabel = `${prefix}.ipv4PrefixInputLabel`;
export const ipv4PeerInputLabel = `${prefix}.ipv4PeerInputLabel`;
export const ipv6PrefixInputLabel = `${prefix}.ipv6PrefixInputLabel`;
export const ipv6PeerInputLabel = `${prefix}.ipv6PeerInputLabel`;
export const remoteAsInputLabel = `${prefix}.remoteAsInputLabel`;
export const multihopInputLabel = `${prefix}.multihopInputLabel`;
export const md5SecretInputLabel = `${prefix}.md5SecretInputLabel`;
export const advertiseVipCheckboxLabel = `${prefix}.advertiseVipCheckboxLabel`;
export const advertiseSnatCheckboxLabel = `${prefix}.advertiseSnatCheckboxLabel`;
export const advertisementIntervalLabel = `${prefix}.advertisementIntervalLabel`;
export const connectionTimerLabel = `${prefix}.connectionTimerLabel`;
export const addNewPeerBtnLabel = `${prefix}.addNewPeerBtnLabel`;
export const sendCommunityCheckboxLabel = `${prefix}.sendCommunityCheckboxLabel`;
export const communityInputLabel = `${prefix}.communityInputLabel`;
export const addCommunityStringBtnLabel = `${prefix}.addCommunityStringBtnLabel`;
export const bgpAsInputLabel = `${prefix}.bgpAsInputLabel`;
export const localPreferenceLabel = `${prefix}.localPreferenceLabel`;
export const asPathPrependLabel = `${prefix}.asPathPrependLabel`;
export const saveBtnLabel = `${prefix}.saveBtnLabel`;

export const ENGLISH = {
    [removeBgpPeeringConfirmMessage]: 'BGP Peering will be permanently removed. Proceed?',
    [disableBgpPeeringBtnLabel]: 'Disable BGP Peering',
    [editBgpHeader]: 'Edit BGP',
    [createBgpHeader]: 'Create BGP',
    [timersHeader]: 'Timers',
    [keepaliveIntervalInputLabel]: 'Keepalive Interval',
    [holdTimeInputLabel]: 'Hold Time',
    [secInnerText]: 'sec',
    [placementNetworkInputLabel]: 'Placement Network',
    [placementNetworkInputPlaceholder]: 'Select Network',
    [removePeerBtnLabel]: 'Remove Peer',
    [ipv4PrefixInputLabel]: 'IPv4 Prefix',
    [ipv4PeerInputLabel]: 'IPv4 Peer',
    [ipv6PrefixInputLabel]: 'IPv6 Prefix',
    [ipv6PeerInputLabel]: 'IPv6 Peer',
    [remoteAsInputLabel]: 'Remote AS',
    [multihopInputLabel]: 'Multihop',
    [md5SecretInputLabel]: 'MD5 Secret',
    [advertiseVipCheckboxLabel]: 'Advertise VIP',
    [advertiseSnatCheckboxLabel]: 'Advertise SNAT',
    [advertisementIntervalLabel]: 'Advertisement Interval',
    [connectionTimerLabel]: 'Connection Timer',
    [addNewPeerBtnLabel]: '+ Add New Peer',
    [sendCommunityCheckboxLabel]: 'Send Community',
    [communityInputLabel]: 'Community',
    [addCommunityStringBtnLabel]: 'Add Community String',
    [bgpAsInputLabel]: 'BGP AS',
    [localPreferenceLabel]: 'Local Preference',
    [asPathPrependLabel]: 'AS-Path Prepend',
    [saveBtnLabel]: 'Save',
};

const moduleName = 'ajs-legacy';
const componentName = 'string-match';
const prefix = `${moduleName}.${componentName}`;

export const stringHeader = `${prefix}.stringHeader`;
export const criteriaInputLabel = `${prefix}.criteriaInputLabel`;
export const groupInputLabel = `${prefix}.groupInputLabel`;
export const removeMatchButtonLabel = `${prefix}.removeMatchButtonLabel`;

export const ENGLISH = {
    [stringHeader]: 'String',
    [criteriaInputLabel]: 'Criteria',
    [groupInputLabel]: 'Group',
    [removeMatchButtonLabel]: 'Remove Match',
};

/**
 * Module for HealthMonitor
 * @module HealthmonitorModule
 * @preferred
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';

import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    HealthMonitor,
    healthmonitorAuthenticationComponentOptions,
} from '.';

const healthmonitorModule = angular.module('avi/healthmonitor');

const factories = [{
    factory: HealthMonitor,
    name: 'HealthMonitor',
}];

const components = [{
    name: 'healthmonitorAuthentication',
    options: healthmonitorAuthenticationComponentOptions,
}];

factories.forEach(({ name, factory }) => {
    initAjsDependency(
        healthmonitorModule,
        'factory',
        name,
        factory,
    );
});

components.forEach(({ name, options }) => healthmonitorModule.component(name, options));

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { L10nService } from '@vmw/ngx-vip';
import {
    CONFIDENCE_LEVELS,
    TMinConfidenceLabelsHash,
    TMinConfidenceSliderLabelHash,
    WafApplicationLearningService,
} from '../../services';
import { WafPolicy } from '../../factories';
import './waf-application-learning.component.less';
import template from './waf-application-learning.component.html';

import * as l10n from './waf-application-learning.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class WafApplicationLearningController {
    /**
     * WafPolicy Item.
     */
    public editable: WafPolicy;

    /**
     * Called when creating a new learning group.
     */
    public onCreateLearningGroup: () => {};

    /**
     * Tuple of min/max values for learning sampling.
     */
    public learningSamplingPercentRange: [number, number];

    /**
     * Min confidence slider value.
     */
    public minConfidence: number;

    /**
     * Hash for displaying labels for slider values.
     */
    public minConfidenceSliderLabelsHash: TMinConfidenceSliderLabelHash;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Hash for displaying min confidence labels.
     */
    private minConfidenceLabelsHash: TMinConfidenceLabelsHash;

    constructor(
        private wafApplicationLearningService: WafApplicationLearningService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onInit(): void {
        this.learningSamplingPercentRange = this.getLearningSamplingPercentRange();
        this.minConfidenceSliderLabelsHash = this.getMinConfidenceSliderLabelsHash();
        this.minConfidenceLabelsHash = this.getMinConfidenceLabelsHash();
        this.minConfidence = this.getSliderValueByMinConfidenceEnum();
    }

    /**
     * Returns the state of the enable app learning switch, 1 for enabled, 0 for disabled.
     */
    public getAppLearningState(): number {
        return this.editable.appLearning ? 1 : 0;
    }

    /**
     * Click handler for the enable app learning switch. Flips the current value.
     */
    public handleAppLearningClick(): void {
        this.editable.appLearning = !this.editable.appLearning;

        if (this.editable.appLearning) {
            this.editable.setDefaultAppLearningParams();
        }
    }

    /**
     * Returns the label of the 'min_confidence' enum value.
     */
    public getMinConfidenceLabel(): string {
        return this.minConfidenceLabelsHash[this.editable.minConfidence];
    }

    /**
     * Change handler for the 'min_confidence' slider. The slider shows 4 values (Low, Probable,
     * High, and Very High).
     */
    public handleChangeMinConfidence(): void {
        this.editable.minConfidence = this.getMinConfidenceEnum();
    }

    /**
     * Returns true if the warning with the 'Create Learning Group' link should be shown.
     */
    public showLearningGroupWarning(): boolean {
        return this.editable.appLearning && !this.editable.hasLearningGroup();
    }

    /**
     * Creates a WafPolicyPsmGroup item with learning enabled.
     */
    public createLearningPsmGroup(): void {
        this.onCreateLearningGroup();
    }

    /**
     * Returns the range of allowed values for the 'sampling_percent' field.
     */
    private getLearningSamplingPercentRange(): [number, number] {
        return this.wafApplicationLearningService.getLearningSamplingPercentRange();
    }

    /**
     * Returns a hash of slider values to labels.
     */
    private getMinConfidenceSliderLabelsHash(): TMinConfidenceSliderLabelHash {
        return this.wafApplicationLearningService.getMinConfidenceSliderLabelHash();
    }

    /**
     * Returns a hash of labels based on configured confidence percentages. The labels contain
     * either 'None' or something like 'Low (70-90%)', where the '(70-90%)' part is taken from
     * previously configured values in the 'confidence_override' object.
     */
    private getMinConfidenceLabelsHash(): TMinConfidenceLabelsHash {
        let confidenceOverride;

        const config = this.editable.getConfig();
        const { confidence_override: confidenceOverrideConfigItem } = config as any;

        if (confidenceOverrideConfigItem) {
            confidenceOverride = confidenceOverrideConfigItem.config;
        }

        return this.wafApplicationLearningService.getMinConfidenceLabelsHash(confidenceOverride);
    }

    /**
     * Returns the slider value based on the configured enum. Called in $onInit.
     */
    private getSliderValueByMinConfidenceEnum(): number {
        const { minConfidence } = this.editable;

        return this.wafApplicationLearningService.getSliderValueByMinConfidenceEnum(
            minConfidence as CONFIDENCE_LEVELS,
        );
    }

    /**
     * Returns the 'min_confidence' enum value to set based on the slider value.
     * @returns {string}
     */
    private getMinConfidenceEnum(): string {
        return this.wafApplicationLearningService.getMinConfidenceEnumBySliderValue(
            this.minConfidence,
        );
    }
}

WafApplicationLearningController.$inject = [
    'wafApplicationLearningService',
    'l10nService',
];

/**
 * @name wafApplicationLearning
 * @memberOf module:avi/waf
 * @property {module:avi/waf.WafApplicationLearningController} controller
 * @property {module:avi/waf.wafApplicationLearningBindings} bindings
 * @description
 *
 *     Component for WAF Application Learning fields.
 *
 * @author alextsg
 */
export const wafApplicationLearningOptions = {
    /**
     * @mixin wafApplicationLearningBindings
     * @memberOf module:avi/waf
     * @property {WafPolicy} editable - WAFPolicy item.
     * @property {Function} onCreateLearningGroup - Called when creating a new learning group.
     */
    bindings: {
        editable: '<',
        onCreateLearningGroup: '&',
    },
    controller: WafApplicationLearningController,
    template,
};

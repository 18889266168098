/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */
import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import './full-modal-config-header.component.less';

/**
 * @description Component to display a header in the config modal.
 * @author alextsg
 */
@Component({
    selector: 'full-modal-config-header',
    templateUrl: './full-modal-config-header.component.html',
})
export class FullModalConfigHeaderComponent {
    /**
     * Text to show in the header, ex. the title of the modal.
     */
    @Input() public headerText = '';

    /**
     * Called when the user clicks the close button.
     */
    @Output() public onClose = new EventEmitter();

    /**
     * Handler for clicking the close button.
     */
    public handleClose(): void {
        this.onClose.emit();
    }
}

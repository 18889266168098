/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './ping-access-agent-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class PingAccessAgentModalController {
    constructor(
        PingAccessAgentProfileCollection,
        schemaService,
        Regex,
        l10nService,
    ) {
        this.pingAccessAgentProfileCollection_ = new PingAccessAgentProfileCollection();

        this.fileUploadHelpText = schemaService
            .getFieldDescription('PingAccessAgent', 'properties_file_data');
        this.Regex = Regex;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onDestroy() {
        this.pingAccessAgentProfileCollection_.destroy();
    }
}

PingAccessAgentModalController.$inject = [
    'PingAccessAgentProfileCollection',
    'schemaService',
    'Regex',
    'l10nService',
];

/**
 * @ngdoc component
 * @name pingAccessAgentModal
 * @param {PingAcccessAgentProfile} editable
 * @desc
 *     Modal component for creating PingAccessAgent Profile
 *     by uploading its agent properties file.
 */
angular.module('aviApp').component('pingAccessAgentModal', {
    bindings: {
        editable: '<',
    },
    controller: PingAccessAgentModalController,
    templateUrl: 'src/components/modals/ping-access-agent-modal/ping-access-agent-modal.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'waf';
const componentName = 'waf-exclude-list-entry';
const prefix = `${moduleName}.${componentName}`;

export const subnetLabel = `${prefix}.subnetLabel`;
export const pathLabel = `${prefix}.pathLabel`;
export const matchElementLabel = `${prefix}.matchElementLabel`;
export const caseSensitiveLabel = `${prefix}.caseSensitiveLabel`;
export const caseInsensitiveLabel = `${prefix}.caseInsensitiveLabel`;
export const regexMatchLabel = `${prefix}.regexMatchLabel`;
export const exactMatchLabel = `${prefix}.exactMatchLabel`;

export const ENGLISH = {
    [subnetLabel]: 'Subnet',
    [pathLabel]: 'Path',
    [matchElementLabel]: 'Match Element',
    [caseSensitiveLabel]: 'Case Sensitive',
    [caseInsensitiveLabel]: 'Case Insensitive',
    [regexMatchLabel]: 'Regex Match',
    [exactMatchLabel]: 'Exact Match',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'ordered-grid';
const prefix = `${moduleName}.${componentName}`;

export const addRuleBtnLabel = `${prefix}.addRuleBtnLabel`;
export const emptyItemsFoundMessage = `${prefix}.emptyItemsFoundMessage`;

export const ENGLISH = {
    [addRuleBtnLabel]: 'Add rule',
    [emptyItemsFoundMessage]: 'No items found.',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './license-summary.component.less';

import {
    LicenseSummaryService,
} from 'ajs/components/pages/administration/settings/licensing-page/license-summary';
import { LicenseTierSwitchModalComponent } from 'ng/modules/licensing';
import { LicensingPageController } from '../licensing-page.component';
import * as l10n from './license-summary.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { UPDATE_LICENSE_SUMMARY } = LicensingPageController;

const LicenseTierType = {
    BASIC: 'BASIC',
    ENTERPRISE: 'ENTERPRISE',
    ENTERPRISE_16: 'ENTERPRISE_16',
    ENTERPRISE_18: 'ENTERPRISE_18',
    ESSENTIALS: 'ESSENTIALS',
};

/**
 * @constructor
 * @memberOf module:avi/licensing
 */
class LicenseSummaryController {
    /**
     * Property holding all license tier types.
     */
    licenseTierType = LicenseTierType;

    /**
     * True when license summary is being loaded.
     * @protected
     */
    busy_ = false;

    /**
     * @type {module:avi/licensing.LicenseUsageByType[]}
     */
    licenseSummaryData = [];

    constructor(
        $scope,
        LicenseUsage,
        systemConfigService,
        systemInfoService,
        defaultValues,
        $window,
        licenseTierComparisonDocSrc,
        l10nService,
    ) {
        this.$scope = $scope;
        this.LicenseUsage_ = LicenseUsage;
        this.systemConfig = systemConfigService;
        this.systemInfo = systemInfoService;
        this.defaultValues_ = defaultValues;
        this.$window_ = $window;
        this.licenseTierComparisonDocSrc_ = licenseTierComparisonDocSrc;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        /**
         * Message to be displayed on alert message.
         */
        this.licenseInfoAlertMessage = l10nService.getMessage(l10nKeys.unlockMoreFeaturesMessage);
    }

    /**
     * Getter for busy flag.
     * @returns {boolean}
     */
    get busy() {
        return this.busy_;
    }

    /**
     * Setter for busy flag.
     * @param {boolean} isBusy
     */
    set busy(isBusy) {
        this.busy_ = !!isBusy;
    }

    /**
     * Getter for default license tier summary data.
     * @return {Object.<string, number>|undefined}
     */
    get defaulTierSummaryData() {
        return this.licenseSummaryData[0]?.tiers[this.defaultLicenseTier];
    }

    /**
     * Returns true when license tier type is of enterprise type.
     * @return {boolean}
     */
    get isEnterpriseLicenseTier() {
        return this.defaultLicenseTier.startsWith(this.licenseTierType.ENTERPRISE);
    }

    /**
     * @listens module:avi/licensing#UPDATE_LICENSE_SUMMARY
     * @override
     */
    $onInit() {
        this.licenseData = new this.LicenseUsage_();
        this.getLicenseSummaryData_();

        //Fires upon user updating data in parent controller
        this.$scope.$on(UPDATE_LICENSE_SUMMARY, this.getLicenseSummaryData_);
    }

    /**
     * Makes api call for licenseSummary.
     * @protected
     */
    getLicenseSummaryData_ = () => {
        this.busy = true;

        this.licenseData.load()
            .then(() => {
                this.setLicenseTypeList_(this.licenseData.config);
            })
            .finally(() => {
                this.busy = false;
            });
    };

    /**
     * Builds {@link module:avi/licensing.LicenseSummaryRowData[]} for summary list in view.
     * @param {module:avi/licensing.LicenseHash} licenseHash
     * @param {module:avi/licensing.LicenseType[]} licenseTypes
     * @param {module:avi/licensing.LicenseTierType[]} licenseTierTypes
     * @protected
     */
    setLicenseTypeList_({
        licenseHash,
        licenseTypes,
        licenseTierTypes,
    }) {
        this.licenseSummaryData =
            LicenseSummaryService.getLicenseListByType(licenseHash, licenseTypes, licenseTierTypes);
    }

    /**
     * Upon tier is successfully changed, reload systemConfig, systemInfo,
     * defaultValues and re-fetch summary
     * data.
     */
    reloadData = () => {
        this.systemConfig.load();
        this.systemInfo.load(true);
        this.defaultValues_.load(true);
        this.getLicenseSummaryData_();
    }

    /**
     * When the page header cog button is clicked, open a full modal for licensing configurations.
     */
    openTierChangeModal() {
        this.systemConfig.edit(LicenseTierSwitchModalComponent, {
            licenseTiersUsageData: this.licenseSummaryData[0].tiers,
            onSaveSuccess: this.reloadData,
        });
    }

    /**
     * Opens new tab with license tier comparison pdf.
     */
    openTierComparisonDocTab() {
        this.$window_.open(this.licenseTierComparisonDocSrc_, '_blank');
    }

    /** @override */
    $onDestroy() {
        this.licenseData.destroy();
    }
}

LicenseSummaryController.$inject = [
    '$scope',
    'LicenseUsage',
    'systemConfigService',
    'systemInfoService',
    'defaultValues',
    '$window',
    'licenseTierComparisonDocSrc',
    'l10nService',
];

const componentTag = 'license-summary';
const templateUrl = 'src/components/pages/administration/settings/licensing-page/' +
    `${componentTag}/${componentTag}.component.html`;

/**
 * @name licenseSummaryComponent
 * @memberOf module:avi/licensing
 * @property {module:avi/licensing.LicenseSummaryController} controller
 * @author Akul Aggarwal, Zhiqian Liu
 * @description Summary read-only table for licenses used and available.
 */
angular.module('avi/licensing').component('licenseSummary', {
    bindings: {
        defaultLicenseTier: '@',
    },
    controller: LicenseSummaryController,
    templateUrl,
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').factory('RoleCollection', ['Collection', 'Role',
function(Collection, Role) {
    const RoleCollection = class extends Collection {
        constructor(oArgs) {
            super(oArgs);
            this.oArgs = oArgs || {};
        }

        isCreatable() {
            return 'isCreatable' in this.oArgs ?
                !!this.oArgs.isCreatable : super.isCreatable();
        }
    };

    angular.extend(RoleCollection.prototype, {
        objectName_: 'role',
        itemClass_: Role,
        windowElement_: 'adm-role-create',
        allDataSources_: {
            list: {
                source: 'ListCollDataSource',
                transformer: 'RoleListDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        },
        serverDefaultsOverride_: {
            name: '',
        },
    });

    return RoleCollection;
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  ErrorPageBodyCollection
 * @description  Collection of {WafPolicy} items.
 */
const ErrorPageBodyCollection = (Collection, ErrorPageBody) => {
    class ErrorPageBodyCollection extends Collection {

    }

    angular.extend(ErrorPageBodyCollection.prototype, {
        objectName_: 'errorpagebody',
        itemClass_: ErrorPageBody,
        windowElement_: 'error-page-body-modal',
    });

    return ErrorPageBodyCollection;
};

ErrorPageBodyCollection.$inject = [
        'Collection',
        'ErrorPageBody',
];

angular.module('aviApp').factory('ErrorPageBodyCollection', ErrorPageBodyCollection);

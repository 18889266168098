/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    LANGUAGES,
    USER_SETTINGS_DISPLAY_TIMEZONE_UPDATE,
} from '../../constants/my-account.constant';

MyAccountController.$inject = [
    '$scope',
    'myAccount',
    'AviModal',
    '$state',
    '$stateParams',
    'AviMessage',
    'notificationService',
    'fullModalService',
    'localeService',
    'i18nContext',
];

function MyAccountController(
    $scope,
    myAccount,
    AviModal,
    $state,
    $stateParams,
    AviMessage,
    notificationService,
    fullModalService,
    localeService,
    i18nContext,
) {
    $scope.$parent.modalScope = $scope;

    /**
     * @type {MyAccount}
     */
    this.myAccount = myAccount;
    this.myAccount.load();

    /**
     * @type {AviModal}
     */
    this.AviModal = AviModal;

    /**
     * @type {AviMessage}
     */
    this.AviMessage = AviMessage;

    /**
     * @type {boolean}
     */
    this.busy = false;

    /**
     * @type {string}
     */
    this.errors = '';

    /**
     * @type {$StateProvider}
     */
    this.$state = $state;

    /**
     * @type {Object}
     */
    this.$stateParams = $stateParams;

    this.notificationService_ = notificationService;

    /**
     * @type {fullModalService}
     */
    this.fullModalService_ = fullModalService;

    /**
     * @type {localeService}
     */
    this.localeService_ = localeService;

    /**
     * @type {function(this:MyAccountController)}
     */
    this.errorHandler = this.errorHandler.bind(this);

    /**
     * True if app is running in development mode.
     * Used to show language switcher.
     * @todo Remove once i18n is live.
     * @type {boolean}
     */
    this.showLanguageSelector = process.env.NODE_ENV !== 'production' || i18nContext.i18nEnabled;

    /**
     * @type {DropDownOption[]}
     */
    this.languageOptions = LANGUAGES;

    myAccount.getControllerProperties();
}

/**
 * Handles server API errors.
 * @param {Object} response
 */
MyAccountController.prototype.errorHandler = function(response) {
    this.errors = response.data.error;
    this.busy = false;
};

/**
 * Saves user preferences and account data to server.
 */
MyAccountController.prototype.save = function() {
    const { myAccount } = this;

    this.busy = true;
    this.errors = '';

    return Promise.all([
        myAccount.saveAccount(),
        myAccount.saveUIProperty(),
        myAccount.saveControllerProperties(),
    ]).then(
        () => {
            const {
                language,
                uiProperty,
                uiPropertyClone,
            } = myAccount;

            if (uiProperty.useUTCTime !== uiPropertyClone.useUTCTime) {
                myAccount.trigger(USER_SETTINGS_DISPLAY_TIMEZONE_UPDATE);
            }

            // todo check current state for applicability
            this.$stateParams.timeframe = uiProperty.defaultTimeframe;

            this.close();

            if (this.localeService_.getCurrentLanguage() !== language) {
                myAccount.onLanguageChange();
            }
        },
        this.errorHandler,
    );
};

/**
 * Cancels and reverts any changes.
 */
MyAccountController.prototype.cancel = function() {
    this.myAccount.revertChanges();
    this.close();
};

/**
 * Dismisses all modal windows.
 */
MyAccountController.prototype.close = function() {
    this.busy = false;
    this.errors = '';
    this.AviMessage.destroyAll();
    this.AviModal.destroyAll();
    this.notificationService_.removeAll();
    this.fullModalService_.removeAllModals();
};

angular.module('avi/app')
    .controller('MyAccountController', MyAccountController);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as caseCommentTemplate from './case-comment.component.partial.html';
import './case-comment.component.less';

/**
 * @constructor
 * @memberOf module:avi/cportal
 * @desc Component to show comment
 * @mixes module:avi/cportal.caseCommentBindings
 * @author Ashish Verma
 * @see {@link module:avi/cportal.CaseCommentComponent}
 */
class CaseCommentController {}

/**
 * @mixin caseCommentBindings
 * @memberOf module:avi/cportal
 * @property {module:avi/cportal.CaseComment} comment
 */
const caseCommentBindings = {
    comment: '<',
};

/**
 * @ngdoc component
 * @name CaseCommentComponent
 * @memberOf module:avi/cportal
 * @property {module:avi/cportal.CaseCommentController} controller
 * @property {module:avi/cportal.caseCommentBindings} bindings
 */
angular.module('avi/cportal').component('caseComment', {
    bindings: caseCommentBindings,
    controller: CaseCommentController,
    template: caseCommentTemplate,
});

/**
 * Policies module
 * @module PoliciesModule
 * @preferred
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    dnsMxRecordsComponentOptions,
    dnsTxtRecordsComponentOptions,
    MatchTargetConfigItem,
} from '.';

const policiesModule = angular.module('avi/policies');

const factories = [
    {
        factory: MatchTargetConfigItem,
        name: 'MatchTargetConfigItem',
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(policiesModule, 'factory', name, factory);
});

const components = [{
    name: 'dnsMxRecords',
    options: dnsMxRecordsComponentOptions,
}, {
    name: 'dnsTxtRecords',
    options: dnsTxtRecordsComponentOptions,
}];

components.forEach(({ name, options }) => policiesModule.component(name, options));

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function clusterNodeRuntimeCollDataSourceFactory(ListCollDataSource) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.ListCollDataSource
     * @author Ashish Verma
     * @desc ListCollDataSource of {@link module:avi/app.ClusterNodeRuntimeCollection
     *       ClusterNodeRuntimeCollection}.
     */
    class ClusterNodeRuntimeCollDataSource extends ListCollDataSource {
        /** @override */
        getRequestParams_() {
            const params = super.getRequestParams_(this.params_);

            params['objectName_'] = [
                this.owner_.objectName_,
                'runtime',
            ];

            return params;
        }
    }

    const defaultFields = [
        {
            id: 'runtime',
            preserved: true,
            subscribers: ['__mandatory_field'],
        },
    ];

    const defaultParams = {};

    Object.assign(ClusterNodeRuntimeCollDataSource.prototype, {
        hasSearch: false,
        hasSorting: false,
        hasPagination: false,
        defaultFields_: defaultFields,
        defaultParams_: defaultParams,
    });

    return ClusterNodeRuntimeCollDataSource;
}

clusterNodeRuntimeCollDataSourceFactory.$inject = [
    'ListCollDataSource',
];

angular.module('avi/app').factory(
    'ClusterNodeRuntimeCollDataSource',
    clusterNodeRuntimeCollDataSourceFactory,
);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc filter
 * @name  filterByHash
 * @description
 *     Filter items by provided hash. By default, return items existing in the hash. If "true" is
 *     provided as the third argument, return items not in the hash.
 * @param {Object|Object[]|string[]} items - Input array that the filter is being applied to.
 * @param {Object} hash - Hash used to filter for items.
 * @param {string=} property - Property of each item in the array that should be looked up in hash.
 *    If undefined or null, take item as a whole.
 * @param {boolean} notInHash - If true, return item not in hash.
 */
angular.module('aviApp').filter('filterByHash', () => {
    return (items, hash, property, notInHash = false) => {
        if (!angular.isObject(hash)) {
            return items;
        }

        return _.filter(items, item => {
            let value = item;

            if (property && property in item) {
                value = item[property];
            }

            return notInHash ? !(value in hash) : value in hash;
        });
    };
});

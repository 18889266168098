/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as collectionDropdownTemplate from './collection-dropdown.partial.html';
import * as l10n from './CollectionDropdown.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc directive
 * @name collectionDropdown
 * @restrict E
 *
 * @param {string|string[]} ngModel - String with full Item ref (URL) or array of such strings.
 * @param {Collection} options - Collection of Items we are working with.
 * @param {Function=} ngChange - Callback to be called on ngModel change.
 * @param {boolean=} ngDisabled - Dropdown is disabled when this value evaluates to true.
 * @param {string=} allowClear - Button to reset the value is available for user when evaluates
 *     to true.
 * @param {number=} max - Max number of selected Items required to pass form validation when working
 *     as multiselect.
 * @param {number=} min - Min number of selected Items required to pass form validation when working
 *     as multiselect.
 * @param {string=} multiple - When set to any string dropdown will support selection of multiple
 *     Items and ngModel will have an array type.
 * @param {string} search - Search field for options list is available when evaluated to true.
 * @param {string=} searchFieldPlaceholder - Placeholder for search bar.
 * @param {Object} createParams - Parameters passed into options.create().
 * @param {boolean=} allowEdit - Won't have edit button if attribute is set and evaluates to false.
 * @param {string=} placeholder
 * @param {boolean=} allowCreate - Shows/hides item create button in the dropdown.
 * @param {Function=} confirmChange - Function that returns a promise. If the promise is resolved,
 *     sets the ngModel to the selected value. Used when changing the value results in clearing a
 *     set of fields, so we want to confirm with the user if he actually wants to make the change.
 * @param {DropDownOption[]} customOptions - List of static options.
 * @param {string?} optionsPopoverClassName - Optional classname for options popover.
 * @description Dropdown form element
 *
 *     Dropdown working with Collection. Supports items search, creation and editing.
 *
 *     Has two modes - regular and `multiple`. For the regular ngModel keeps a single string (ref),
 *     for multiple there is an array of strings (refs).
 *
 *     Internally dropdown is using an array of corresponding collection Items as a ngModelCtrl
 *     view value. For `multiple` mode it can keep more than one option.
 *
 *     Supports static options as well.
 *     optional `customOptions` param accepts a list of DropDownOption.
 *     They would stay irrespective of the loading of collection.
 *     Search is not applicable to static options.
 *
 * @example
 * <collection-dropdown
 *     ng-model="editable.data.config.value"
 *     options="PoolCollection"
 *     placeholder="Select Pool"></collection-dropdown>
 */
angular.module('aviApp').directive('collectionDropdown', [
'$timeout', '$compile', 'popoverFactory', '$q', 'ObjectTypeItem', 'Item', 'l10nService',
function($timeout, $compile, popoverFactory, $q, ObjectTypeItem, Item, l10nService) {
    function collDropdownLink(scope, $elm, attr, ngModelCtrl) {
        scope.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        /**
         * Wrapped around Collection.createItem. Created Item is NOT a part of Collection.
         * @param {string} ref - Item's ref to get an id/slug. Also need name for the label.
         * @param {Item.data.config=} itemConfig
         * @returns {Item|DropDownOption}
         * @inner
         */
        const createItem = (ref, itemConfig = {
            name: ref.name() || ref,
            url: ref,
        }) => {
            if (scope.customOptions && scope.customOptions.length) {
                // When ng-model receives custom option value
                // just find the item and return it.
                const selectedItem = _.findWhere(scope.customOptions, { value: ref });

                if (selectedItem) {
                    return selectedItem;
                }
            }

            return scope.options.createNewItem({
                id: ref.slug(),
                data: { config: itemConfig },
            }, true);
        };

        /**
         * Returns an Item ref or in case it is empty builds it up using id and name.
         * Returns value in case of Custom option.
         * @param {Item|DropDownOption} item
         * @return {string}
         * @inner
         */
        const getItemRef = item => {
            if (isCustomOption_(item)) {
                return item.value;
            }

            return item.getRef() || `${item.id}#${item.getName()}`;
        };

        /**
         * Hash of selected item ids. Used to filter out or highlight selected items.
         * @type {{Item.id: true}}
         * @inner
         */
        let selectedUuidHash = {};

        /**
         * For filtering and highlighting purposes we need to keep a relevant hash of all selected
         * item ids.
         * @param {Item|Item.id[]|Item.id|Item[]} [items=]
         * @inner
         */
        const updateSelectedUuidHash = items => {
            selectedUuidHash = {};
            scope.selectedUuidHash = selectedUuidHash;

            if (!angular.isUndefined(items)) {
                if (!angular.isArray(items)) {
                    items = [items];
                }

                items.forEach(item => {
                    let id;

                    if (_.isObject(item)) {
                        id = isCustomOption_(item) ? item.value : item.id;
                    } else {
                        // Item will be a string (ngModal value) on load.
                        id = item.slug() || item;
                    }

                    selectedUuidHash[id] = true;
                });
            }
        };

        /**
         * Checks whether we use multiselect mode for this dropdown.
         * @returns {boolean}
         * @public
         */
        scope.isMultiSelect = () => !_.isUndefined(attr.multiple);

        const { isMultiSelect } = scope;

        /**
         * Used in a template to render labels of selected items.
         * @public
         */
        scope.ngModelCtrl = ngModelCtrl;

        /**
         * Considered empty if we have no values in array. Used by ng-required and inner isEmpty
         * function.
         * @params {Item[]} viewValue
         * @returns {boolean}
         * @inner
         */
        ngModelCtrl.$isEmpty = viewValue => !viewValue.length;

        /**
         * Considered empty if we have no values in array.
         * @returns {boolean}
         * @public
         */
        scope.isEmpty = () => ngModelCtrl.$isEmpty(ngModelCtrl.$viewValue);

        const { isEmpty } = scope;

        /**
         * Translates an array of selected Items into an array or single ref/url or undefined.
         * viewValue > modelValue.
         * @param {Item[]} viewVal
         * @returns {Item.data.config.url|Item.data.config.url[]|undefined}
         * @inner
         */
        //TODO no undefined should be returned by parser - causes much pain
        function parseItemsList(viewVal) {
            if (isMultiSelect()) { //array
                return isEmpty() ? undefined : viewVal.map(getItemRef);
            } else { //string
                return isEmpty() ? undefined : getItemRef(viewVal[0]);
            }
        }

        /**
         * Translates a list or single ref/url into a list of selected Items.
         * modelValue > viewValue
         * @param {Item.data.config.url|Item.data.config.url[]} modelVal
         * @returns {Item[]}
         * @inner
         */
        function formatItemsList(modelVal) {
            const viewVal = [];

            if (modelVal) {
                if (angular.isString(modelVal)) {
                    viewVal.push(createItem(modelVal));
                } else if (angular.isArray(modelVal)) {
                    modelVal.forEach(ref => viewVal.push(createItem(ref)));
                } else {
                    modelVal = undefined;
                }
            }

            updateSelectedUuidHash(modelVal);

            return viewVal;
        }

        ngModelCtrl.$parsers.push(parseItemsList);
        ngModelCtrl.$formatters.push(formatItemsList);

        //need to allow `undefined` to be returned by parser so that our listeners do run
        ngModelCtrl.$overrideModelOptions({
            updateOn: 'default',
            allowInvalid: true,
        });

        const listeners = ngModelCtrl.$viewChangeListeners;

        listeners.length = 0;//remove regular ng-change behavior

        //every viewValue update will run those
        listeners.push(
            () => {
                if (!isMultiSelect()) {
                    scope.ngChange({ selected: ngModelCtrl.$viewValue[0] });
                } else {
                    scope.ngChange({ selected: ngModelCtrl.$viewValue });
                }
            },
            () => updateSelectedUuidHash(ngModelCtrl.$viewValue),
            () => {
                //since parser returns `undefined` input will be considered invalid, fix it here
                if (isEmpty()) {
                    $timeout(() => {
                        ngModelCtrl.$setValidity('parse', true);
                    });
                }
            },
        );

        /**
         * Validates number of entries for multiple only.
         * @param {Item.data.config.url|Item.data.config.url[]} modelValue
         * @param {Item[]} viewValue
         * @return {boolean}
         * @inner
         */
        const lengthValidator = (modelValue, viewValue) => {
            const len = viewValue.length,
                min = +attr.min || 0,
                max = +attr.max || Infinity;

            return len >= min && len <= max;
        };

        if (!isMultiSelect() && (attr.min || attr.max)) {
            ngModelCtrl.$validators.multiple = lengthValidator;
        }

        /**
         * Create new Item text label.
         * @type {string|undefined}
         * @public
         */
        scope.labelCreate = attr.labelCreate;

        const elContainer = $elm.find('.dropdown-container');
        let $scrollable;

        function calcViewportOffsetAndLimit(isInitial) {
            const maxHeight = 150, //max-height of scrollable from less
                defRowHeight = 30;

            let offset = 0,
                viewportSize = Math.ceil(maxHeight / defRowHeight);

            if (!isInitial && $scrollable) {
                const row = _.sample($scrollable.find('> ul > li'));
                const rowHeight = row ? $(row).outerHeight() : defRowHeight;

                viewportSize = Math.ceil(
                    Math.max(maxHeight, $scrollable.height()) / rowHeight,
                );

                offset = Math.floor($scrollable.scrollTop() / rowHeight);
            }

            return {
                offset,
                viewportSize,
            };
        }

        /**
         * Notifies Collection with viewport size and offset of items scrolled above the
         * viewport top.
         * @param {boolean=} isInitial - When `true` boolean value is passed sets
         *     default size of dropdown viewport. Otherwise event object will be passed as an
         *     argument.
         * @inner
         */
        function updateCollectionOffsetAndLimit(isInitial) {
            isInitial = typeof isInitial === 'boolean' && isInitial || false;

            if (isInitial || popover.popover) {
                const sizeAndOffset = calcViewportOffsetAndLimit(isInitial);

                scope.options.updateViewportSize(sizeAndOffset.viewportSize);
                scope.options.updateItemsVisibility(undefined, sizeAndOffset.offset);
            }
        }

        const PopoverClass = class extends popoverFactory {
            constructor(config) {
                super(config);

                this.popoverContent_ = require('./collection-dropdown-options.partial.html');
            }

            reposition() {
                const width = elContainer.width();

                this.popover.width(width);
                this.config.width = width;
                super.reposition(true);
            }

            show() {
                this.config.html = $compile(this.popoverContent_)(scope);
                super.show($elm);
                //sets the focus on search field
                this.config.html
                    .find('.dropdown-search')
                    .trigger('focus');
                $scrollable = this.popover.find('.scrollable');
                $scrollable.on('scroll', updateCollectionOffsetAndLimit);
                scope.filter = '';
                scope.options.setSearch();
                scope.options.load(undefined, true);
            }
        };

        const popoverClassName = `collDropdown ${scope.optionsPopoverClassName || ''}`;

        const popover = new PopoverClass({
            className: popoverClassName,
            //max height, should match styles dropdown.less>dropDownOptions>.options>.scrollable
            height: 220,
            width: 0,
            repositioning: true,
            removeAfterHide: true,
        });

        /** Hotkeys */
        elContainer.off('keydown').on('keydown', function(event) {
            switch (event.keyCode) {
                case 40: {
                    if (!popover.isVisible()) {
                        scope.showOptions();
                    } else if (!isMultiSelect() && !isEmpty()) {
                        const nextItem = getSiblingItem(1);

                        if (nextItem) {
                            scope.selectItem(nextItem);
                        }
                    }

                    break;
                }

                case 38: {
                    if (popover.isVisible() && !isMultiSelect() && !isEmpty()) {
                        const prevItem = getSiblingItem(-1);

                        if (prevItem) {
                            scope.selectItem(prevItem);
                        }
                    }

                    break;
                }

                case 69:
                    scope.editSelectedItem();
                    break;

                case 67:
                    scope.options.isCreatable() && scope.options.create();
                    break;

                case 27:
                    scope.hideOptions();
                    event.preventDefault();
                    event.stopPropagation();
                    break;

                case 13:
                    scope.hideOptions();
                    break;
            }
        });

        $timeout(() => {
            //need config for isEditable()
            //TODO when multiple and allowEdit we might need to load all the items
            if ((scope.allowEdit || angular.isUndefined(scope.allowEdit)) &&
                ngModelCtrl.$viewValue[0] && ngModelCtrl.$viewValue.length === 1) {
                ngModelCtrl.$viewValue[0].load(['config'], true);
            }
        });

        /**
         * Opens create modal window. Wrapper around Item.create method.
         * @public
         */
        scope.create = function() {
            const params = {};

            if (angular.isObject(scope.createParams)) {
                angular.extend(params, scope.createParams);
            }

            scope.options.create(undefined, params)
                .then(item => scope.selectItem(item));

            scope.hideOptions();
        };

        /**
         * We have a create button in the dropdown menu unless allowCreate is set to false or
         * Collection.isCreatable returns false.
         * @returns {boolean}
         * @public
         */
        scope.showCreateButton = function() {
            return (angular.isUndefined(scope.allowCreate) || !!scope.allowCreate) &&
                scope.options.isCreatable();
        };

        /**
         * Checks whether user has reached limit of selected items for multiselect mode.
         * @returns {boolean} - False for regular dropdown.
         * @public
         */
        scope.multipleLimitReached = () => {
            return isMultiSelect() && +attr.max && ngModelCtrl.$viewValue.length >= +attr.max ||
                false;
        };

        const { multipleLimitReached } = scope;

        /**
         * Shows options panel.
         * @public
         */
        scope.showOptions = function() {
            if (!scope.ngDisabled && !multipleLimitReached()) {
                popover.show($elm);
            }
        };

        /**
         * Hides options panel.
         * @public
         */
        scope.hideOptions = () => {
            popover.hide();
        };

        /**
         * Returns Item#data#config of an item/ObjectTypeItem.
         * Incase of ObjectTypeItem, we call `dataToSave` to flatten the messageItem
         * data-model conversions.
         * @param {Item|ObjectTypeItem} item
         * @returns {Object} item#data#config
         */
        function getItemConfig(item) {
            if (item instanceof ObjectTypeItem) {
                item.beforeEdit();

                return item.dataToSave();
            }

            return item.getConfig();
        }

        /**
         * item select event handler.
         * @param {Item|DropDownOption} item
         * @public
         */
        scope.selectItem = function(item) {
            const isCustomOption = isCustomOption_(item);
            const itemId = scope.getItemId(item);

            if (!(itemId in selectedUuidHash)) {
                let newItem;

                if (!isCustomOption) {
                    const itemConfig = getItemConfig(item);

                    newItem = createItem(getItemRef(item), itemConfig);
                } else {
                    newItem = item;
                }

                let newViewVal = [];

                if (isMultiSelect()) {
                    newViewVal = ngModelCtrl.$viewValue.concat(newItem);
                } else {
                    newViewVal.push(newItem);
                }

                let promise = $q.when();

                if (angular.isFunction(scope.confirmChange)) {
                    promise = scope.confirmChange({
                        selected: newViewVal,
                    });
                }

                promise.then(() => {
                    ngModelCtrl.$setViewValue(newViewVal);
                    ngModelCtrl.$render();
                });
            }
        };

        /**
         * For single mode - returns the next item after one which is selected. Dropdown has to
         * have a selected value to make this work since we are iterating through selected
         * siblings.
         * @param {number} direction - +1 for the next and -1 for the previous Item to be selected.
         * @returns {Item}
         * @inner
         */
        // TODO: Fix: Currently keyboard navigation of options doesn't work.
        // Reason: Auto focus on search box.
        const getSiblingItem = function(direction) {
            const collection = scope.options;
            const customOptions = scope.customOptions || [];
            const selectedItemId = scope.getItemId(ngModelCtrl.$viewValue[0]);

            const dropdownItems = [
                ...customOptions,
                ...collection.items,
            ];

            const totalNumberOfItems = collection.getTotalNumberOfItems() + customOptions.length;

            let selectedIndex = _.findIndex(collection.items, item => item.id === selectedItemId);

            if (selectedIndex === -1) {
                selectedIndex = _.findIndex(customOptions,
                    option => option.value === selectedItemId);
            } else if (selectedIndex !== -1 && customOptions.length) {
                selectedIndex += customOptions.length;
            }

            const siblingIndex = selectedIndex + direction;

            if (selectedIndex !== -1 && siblingIndex >= 0 &&
                siblingIndex < totalNumberOfItems) {
                return dropdownItems[siblingIndex];
            }
        };

        /**
         * Returns currently selected item's display label.
         * @return {string}
         */
        scope.getSelectedItemLabel = () => scope.getItemLabel(ngModelCtrl.$viewValue[0]);

        /**
         * Returns label of the item being passed.
         * Incase of custom-options, returns label value,
         * Otherwise, Calls getName method as the argument is an instance of Item.
         * @param {Item|DropDownOption} item
         * @return {string}
         */
        scope.getItemLabel = item => (isCustomOption_(item) ? item.label : item.getName());

        /**
         * Returns true if an option is a custom option.
         * @return {boolean}
         * @protected
         */
        const isCustomOption_ = option => {
            return !(option instanceof Item || option instanceof ObjectTypeItem);
        };

        /**
         * Removes one of the selected items.
         * @param {number} index - Selected for removal index of Item within viewValue array.
         * @param {ng.$event} $event
         * @public
         */
        scope.removeSelectedItem = function(index, $event) {
            $event.stopPropagation();

            const viewValue = ngModelCtrl.$viewValue.concat();

            viewValue.splice(index, 1);
            ngModelCtrl.$setViewValue(viewValue);
            ngModelCtrl.$render();
        };

        /**
         * Returns true for selected options.
         * @param {Item|DropDownOption} item
         * @returns {boolean}
         * @public
         */
        scope.optionSelected = item => {
            const itemId = scope.getItemId(item);

            return itemId in selectedUuidHash;
        };

        /**
         * Filters collection, debouncing multiple calls for options in a dropdown.
         * @param {string} filter - Search token.
         * @public
         */
        scope.doSearch = _.debounce(filter => scope.options.search(filter), 500);

        /**
         * Checks whether edit functionality is available. Only for regular and multiselect with
         * one Item selected.
         * If the selected item is a custom option, Edit is not allowed.
         * @returns {boolean}
         * @public
         */
        scope.isEditable = () => {
            if (isCustomOption_(ngModelCtrl.$viewValue[0])) {
                return false;
            }

            return !scope.ngDisabled && (angular.isUndefined(scope.allowEdit) ||
                !!scope.allowEdit) && !isEmpty() && ngModelCtrl.$viewValue.length === 1 &&
                ngModelCtrl.$viewValue[0].isEditable();
        };

        /**
         * Item.edit wrapper. Available for regular mode and multiselect which only one Item
         * selected.
         * @public
         */
        scope.editSelectedItem = () => {
            if (scope.isEditable()) {
                ngModelCtrl.$viewValue[0].edit();
            }
        };

        /**
         * Returns id incase of item or value incase of Custom option.
         * @return {string}
         */
        scope.getItemId = item => (isCustomOption_(item) ? item.value : item.id);

        //TODO update items we keep in on load
        scope.options.bind('collectionLoadSuccess', updateCollectionOffsetAndLimit);

        updateCollectionOffsetAndLimit(true);

        scope.$on('$destroy', () => {
            scope.options.unbind('collectionLoadSuccess', updateCollectionOffsetAndLimit);
            popover.remove();
        });
    }

    return {
        scope: {
            ngModel: '=',
            options: '=',
            createParams: '=',
            ngChange: '&',
            ngDisabled: '=',
            allowClear: '<',
            search: '@',
            searchFieldPlaceholder: '@?',
            allowEdit: '=',
            allowCreate: '=',
            prepend: '@',
            confirmChange: '&?',
            placeholder: '@?',
            customOptions: '<?',
            optionsPopoverClassName: '@?',
        },
        restrict: 'E',
        template: collectionDropdownTemplate,
        require: 'ngModel',
        link: collDropdownLink,
    };
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './scheduler-passphrase-settings.component.less';

const componentName = 'scheduler-passphrase-settings';

/**
 * @constructor
 * @memberOf module:avi/system
 * @mixes module:avi/system.schedulerPassphraseSettingsComponentBindings
 * @see {@link module:avi/system.schedulerPassphraseSettingsComponent}
 */
class SchedulerPassphraseSettingsController {
    constructor() {
        /**
         * Data model for passphrase confirmation.
         * @type {string}
         */
        this.passphraseConfirmation = '';

        /**
         * Used only for ng-class of invalid-passphrase input field in the template.
         * True when passphrase and the passphrase confirmation match.
         * @type {boolean}
         */
        this.passphrasesMatched = true;
    }

    /** @override */
    $onInit() {
        this.setNgModelControllerListeners_();
    }

    /**
     * Set listeners for ngModelController.
     * @protected
     */
    setNgModelControllerListeners_() {
        const { ngModelCtrl } = this;

        ngModelCtrl.$validators.checkPassphrasesMatch = this.checkPassphrasesMatch_;

        ngModelCtrl.$render = this.onPassphraseChangedByParent_;
    }

    /**
     * When passphrase is changed programatically, set ngModel to pristine state as if user hasn't
     * touched the data yet as well as set passphrase confirmation to empty string. Being set to
     * $render to be called when both $modelValue and $viewValue are changed programatically.
     * @protected
     */
    onPassphraseChangedByParent_ = () => {
        const { ngModelCtrl } = this;

        this.passphraseConfirmation = '';
        this.passphrasesMatched = true;
        ngModelCtrl.$setPristine();
    }

    /**
     * Check whether passed-in passphrase model value matches passphrase confirmation value.
     * Being set to $validators to be called whenever $modelValue is updated.
     * @param {string} modelValue - Passphrase model value to check match on.
     * @return {boolean}
     * @protected
     */
    checkPassphrasesMatch_ = modelValue => {
        if (this.ngModelCtrl.$dirty) {
            this.passphrasesMatched = modelValue === this.passphraseConfirmation;
        }

        return this.passphrasesMatched;
    }

    /**
     * Fire when passphrase is modified. Set $modelValue by calling $setViewValue() to avoid
     * setting $modelValue directly.
     */
    onPassphraseChange() {
        const { $viewValue: viewValue } = this.ngModelCtrl;

        // will call validators
        this.ngModelCtrl.$setViewValue(viewValue);
    }

    /**
     * Fire when confirmation passphrase is modified.
     * Validate whether the passphrase matches the confirmation passphrase.
     */
    onPassphraseConfirmationChange() {
        this.ngModelCtrl.$validate();
    }
}

/**
 * @name schedulerPassphraseSettingsComponent
 * @memberOf module:avi/system
 * @property {module:avi/system.schedulerPassphraseSettingsComponentBindings} bindings
 * @property {module:avi/system.SchedulerPassphraseSettingsController} controller
 * @desc
 *     Passphrase/Confirm Passphrase settings component. This component contains two input fields -
 *     passphrase and confirmation passphrase. From the reader's perspective, $modelValue is set by
 *     calling $setViewValue() with $viewValue which is bound to passphrase input. If the
 *     $modelValue of this component is updated by operations from somewhere else, the confirmation
 *     passphrase will be set to an empty string.
 * @author Aravindh Nagarajan, Zhiqian Liu
 */
angular.module('avi/system').component('schedulerPassphraseSettings', {
    /**
     * @mixin schedulerPassphraseSettingsComponentBindings
     * @memberof module:avi/system
     * @property {boolean=} oneColumnLayout - Vertical layout.
     */
    bindings: {
        oneColumnLayout: '<?',
    },
    require: {
        ngModelCtrl: 'ngModel',
    },
    controller: SchedulerPassphraseSettingsController,
    templateUrl:
        'src/components/modals/administration/system/scheduler-create/' +
        `${componentName}/${componentName}.component.html`,
});

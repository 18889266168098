/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name HSGraphEdge
 * @author Alex Malitsky
 * @description
 *
 *     Item class for HealthScore graph edge.
 */
angular.module('aviApp').factory('HSGraphEdge', ['Item',
function(Item) {
    return class HSGraphEdge extends Item {
        constructor(args = {}) {
            args.objectName = 'HSGraphNode';
            super(args);
        }

        /** @override */
        getIdFromData_(...data) {
            return this.constructor.getIdFromData(...data);
        }

        /**
         * Static getIdFromData.
         * @static
         */
        static getIdFromData({ config }) {
            const { source, target } = config;

            return `${source}|${target}`;
        }

        /** @override */
        getName() {
            return this.id;
        }
    };
}]);

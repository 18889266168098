/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'logs';
const componentName = 'out-of-band-log-list-expander';
const prefix = `${moduleName}.${componentName}`;

export const summaryLabel = `${prefix}.summaryLabel`;
export const poolNameLabel = `${prefix}.poolNameLabel`;
export const poolUuidLabel = `${prefix}.poolUuidLabel`;
export const seSourcePortLabel = `${prefix}.seSourcePortLabel`;
export const totalTimeLabel = `${prefix}.totalTimeLabel`;
export const serverNameLabel = `${prefix}.serverNameLabel`;
export const serverIpPortLabel = `${prefix}.serverIpPortLabel`;
export const serversTriedLabel = `${prefix}.serversTriedLabel`;
export const requestLabel = `${prefix}.requestLabel`;
export const responseLabel = `${prefix}.responseLabel`;
export const httpMethodLabel = `${prefix}.httpMethodLabel`;
export const httpVersionLabel = `${prefix}.httpVersionLabel`;
export const uriPathLabel = `${prefix}.uriPathLabel`;
export const uriQueryLabel = `${prefix}.uriQueryLabel`;
export const requestLengthLabel = `${prefix}.requestLengthLabel`;
export const httpResponseCodeLabel = `${prefix}.httpResponseCodeLabel`;
export const responseLengthLabel = `${prefix}.responseLengthLabel`;
export const headersSentToServerLabel = `${prefix}.headersSentToServerLabel`;
export const headersReceivedFromServerLabel = `${prefix}.headersReceivedFromServerLabel`;

export const ENGLISH = {
    [summaryLabel]: 'Summary',
    [poolNameLabel]: 'Pool Name',
    [poolUuidLabel]: 'Pool UUID',
    [seSourcePortLabel]: 'SE Source Port',
    [totalTimeLabel]: 'Total Time',
    [serverNameLabel]: 'Server Name',
    [serverIpPortLabel]: 'Server IP:Port',
    [serversTriedLabel]: 'Servers Tried',
    [requestLabel]: 'Request',
    [responseLabel]: 'Response',
    [httpMethodLabel]: 'HTTP Method',
    [httpVersionLabel]: 'HTTP Version',
    [uriPathLabel]: 'URI Path',
    [uriQueryLabel]: 'URI Query',
    [requestLengthLabel]: 'Request Length',
    [httpResponseCodeLabel]: 'HTTP Response Code',
    [responseLengthLabel]: 'Response Length',
    [headersSentToServerLabel]: 'Headers Sent to Server',
    [headersReceivedFromServerLabel]: 'Headers Received from Server',
};

/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { UpdatableBase } from 'ajs/modules/data-model';
import { WafPSMLocationConfigItem, WafPSMRuleConfigItem } from '.';
import { WafPolicyPsmGroupMetricSumData } from '../waf.types';

type PsmGroupMetricId = WafPSMLocationConfigItem['name'] | WafPSMRuleConfigItem['ruleId'];
type WafPolicyPsmGroupMetricIdData = Record<PsmGroupMetricId, WafPolicyPsmGroupMetricSumData>;

export class WafPolicyPsmGroupMetric extends UpdatableBase {
    public config: WafPolicyPsmGroupMetricIdData;

    public metricIds: string[];

    public id: string;

    protected objectName_: string;

    constructor(args: any = {}) {
        const { id, metricIds } = args;
        const allDataSources = {
            list: {
                source: 'WafPolicyPsmGroupMetricDataSource',
                transformer: 'WafPolicyPsmGroupMetricDataTransformer',
                transport: 'CollMetricsDataTransport',
                fields: [...metricIds],
            },
        };

        Object.assign(args, {
            defaultDataSources: 'list',
            isStatic: true,
            allDataSources,
        });

        super(args);

        this.metricIds = metricIds;
        this.id = id;
        this.objectName_ = 'wafpolicypsmgroup';
    }

    /**
     * Returns the metrics object by an id, ex. a rule_id or a location name.
     */
    public getMetricsById(id: PsmGroupMetricId): WafPolicyPsmGroupMetricSumData {
        return this.config?.[id] || {};
    }

    /**
     * Returns the sum number for an id and a metric.
     */
    public getSumById(id: PsmGroupMetricId, metricName: string): number {
        const metrics = this.getMetricsById(id);

        return metrics?.[metricName] || NaN;
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'collection-dropdown-custom';
const prefix = `${moduleName}.${componentName}`;

export const setCustomValueLabel = `${prefix}.setCustomValueLabel`;
export const customValueLabel = `${prefix}.customValueLabel`;
export const orPickOneStringIpGroupsLabel = `${prefix}.orPickOneStringIpGroupsLabel`;
export const searchInputPlaceholder = `${prefix}.searchInputPlaceholder`;
export const emptyValidEntriesLabel = `${prefix}.emptyValidEntriesLabel`;

export const ENGLISH = {
    [setCustomValueLabel]: 'Set Custom Value',
    [customValueLabel]: 'Custom Value',
    [orPickOneStringIpGroupsLabel]: 'Or pick one of the following String/IP Groups',
    [searchInputPlaceholder]: 'Search',
    [emptyValidEntriesLabel]: '-- no valid entries --',
};

/**
 * Module containing update related components.
 * @preferred
 * @module UpdateModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import './update-node-status-card.component.less';

import {
    Component,
    Input,
} from '@angular/core';

import {
    AviConfirmService,
    SchemaService,
} from 'ajs/modules/core';

import { ITUpgradeNode } from 'ajs/modules/upgrade';
import { L10nService } from '@vmw/ngx-vip';
import { UpgradeStateGroup } from '../../upgrade.types';
import * as l10n from './update-node-status-card.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Panel card for update pages.
 * @author Akul Aggarwal, Zhiqian Liu
 */
@Component({
    selector: 'update-node-status-card',
    templateUrl: './update-node-status-card.component.html',
})
export class UpdateNodeStatusCardComponent {
    @Input() public node: ITUpgradeNode;

    /**
     * Boolean determining if card's middle part is extended.
     */
    public showExpanded = true;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    public constructor(
        private aviConfirmService: AviConfirmService,
        private schemaService: SchemaService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Shows/hides middle portion of card.
     */
    public toggleExpansion(): void {
        this.showExpanded = !this.showExpanded;
    }

    /**
     * Gets description from enum prop to display state.
     */
    public getNodeStatus(): string {
        return this.schemaService.getEnumValue(
            'UpgradeFsmState',
            this.node.state,
        ).description;
    }

    /**
     * Returns 'type' attr to pass to 'avi-icon' component.
     */
    public getAviIconType(): string {
        const { stateGroup } = this.node;

        switch (stateGroup) {
            case UpgradeStateGroup.GROUP_ERROR:
                return 'error';

            case UpgradeStateGroup.GROUP_COMPLETED:
                return 'success';

            default:
                return 'warning';
        }
    }

    /**
     * Opens upgrade transcript modal.
     */
    public openTranscriptModal(): void {
        const {
            node: {
                id: nodeId,
                nodeType,
            },
        } = this;

        this.aviConfirmService.prompt('upgrade-transcript-modal-container', {
            nodeId,
            nodeType,
        });
    }

    /**
     * Returns label of state group for card.
     */
    public getNodeStateGroupLabel(): string {
        const { stateGroup } = this.node;

        switch (stateGroup) {
            case UpgradeStateGroup.GROUP_ERROR:
                return 'Failed';

            case UpgradeStateGroup.GROUP_COMPLETED:
                return 'Successful';

            default:
                return 'Unknown';
        }
    }
}

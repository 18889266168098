const moduleName = 'ajs-legacy';
const componentName = 'nat-rule-modal';
const prefix = `${moduleName}.${componentName}`;

export const editNatRuleModalHeader = `${prefix}.editNatRuleModalHeader`;
export const newNatRuleModalHeader = `${prefix}.newNatRuleModalHeader`;
export const generalHeader = `${prefix}.generalHeader`;
export const ruleEnableInputLabel = `${prefix}.ruleEnableInputLabel`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const matchRequiredTooltipLabel = `${prefix}.matchRequiredTooltipLabel`;
export const matchHeader = `${prefix}.matchHeader`;
export const actionHeader = `${prefix}.actionHeader`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const typeInputPlaceholder = `${prefix}.typeInputPlaceholder`;

export const ENGLISH = {
    [editNatRuleModalHeader]: 'Edit NAT Rule: {0}',
    [newNatRuleModalHeader]: 'New NAT Rule: {0}',
    [generalHeader]: 'General',
    [ruleEnableInputLabel]: 'Rule {0}',
    [nameInputLabel]: 'Name',
    [matchRequiredTooltipLabel]: 'At least one match is required.',
    [matchHeader]: 'Match ({0})',
    [actionHeader]: 'Action',
    [typeInputLabel]: 'Type',
    [typeInputPlaceholder]: 'Select Type',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name HSMGroupCollection
 * @description
 *
 *     Collection of HSGroup items.
 **/
angular.module('aviApp').factory('HSMGroupCollection', [
'Collection', 'HSMGroup',
function(Collection, HSMGroup) {
    class HSMGroupCollection extends Collection {}

    angular.extend(HSMGroupCollection.prototype, {
        objectName_: 'hardwaresecuritymodulegroup',
        itemClass_: HSMGroup,
        windowElement_: 'hsm-group-modal',
    });

    return HSMGroupCollection;
}]);

/**
 * @module WebhookModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { Component, Input } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { Webhook } from '../../../../../js/services/items/Webhook';
import * as l10n from './webhook-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the Webhook modal.
 * @author alextsg
 */
@Component({
    selector: 'webhook-modal',
    templateUrl: './webhook-modal.component.html',
})
export class WebhookModalComponent {
    @Input() public editable: Webhook;

    /**
     * Layout for clrForm.
     */
    public verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').factory('SchedulerCollection', ['Collection', 'Scheduler',
(Collection, Scheduler) => {
    const SchedulerCollection = class extends Collection {};

    angular.extend(SchedulerCollection.prototype, {
        objectName_: 'scheduler',
        itemClass_: Scheduler,
        windowElement_: 'scheduler-create',
        defaultViewportSize_: 1,
    });

    return SchedulerCollection;
}]);

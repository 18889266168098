/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { isUndefined } from 'underscore';
import './waf-policy-psm-location-modal.less';
import * as l10n from './waf-policy-psm-location-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @alias waf/wafPolicyPsmLocationModal
 * @private
 */
class WafPolicyPsmLocationModalComponent {
    constructor(
        Regex,
        l10nService,
    ) {
        this.Regex = Regex;
        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns true if the location is being edited, contrary to creation.
     * @returns {boolean}
     */
    isEditing() {
        return !isUndefined(this.editable.getIndex());
    }

    /**
     * Handler for the user clicking the close button.
     */
    handleCancel() {
        this.onCancel();
    }

    /**
     * Handler for the user clicking the submit button.
     */
    handleSubmit() {
        this.onSubmit();
    }
}

WafPolicyPsmLocationModalComponent.$inject = [
    'Regex',
    'l10nService',
];

/**
 * @ngdoc component
 * @name wafPolicyPsmLocationModal
 * @module waf/wafPolicyPsmLocationModal
 * @desc Component for editing a WAF Policy PSM Location.
 * @param {WafPSMLocationConfigItem} editable
 * @param {Function} onSubmit - Called when the user clicks the submit button.
 * @param {Function} onCancel - Called when the user closes the modal.
 * @param {Function} closeModal - Function to call to close the modal.
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @param {string[]} breadcrumbs - Array of strings to be displayed as breadcrumbs.
 * @param {string} psmGroupId - PSM Group UUID.
 * @author alextsg
 */
angular.module('avi/waf').component('wafPolicyPsmLocationModal', {
    bindings: {
        editable: '<',
        onSubmit: '&',
        onCancel: '&',
        closeModal: '&',
        preventEdit: '<',
        breadcrumbs: '<',
        psmGroupId: '@',
    },
    controller: WafPolicyPsmLocationModalComponent,
    templateUrl: 'src/components/modals/waf-policy-psm-location-modal/' +
        'waf-policy-psm-location-modal.component.html',
});

/** @module WafModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { isUndefined } from 'underscore';
import { WafMode } from 'generated-types';
import { IAviDataGridConfig } from 'ng/shared/components';
import { IEnumValue, SchemaService } from 'ajs/modules/core';
import { RepeatedMessageItem } from 'ajs/modules/data-model';
import {
    WafExcludeListEntryConfigItem,
    WafRuleConfigItem,
    WafRuleOverridesConfigItem,
} from 'ajs/modules/waf';
import * as l10n from './waf-rule-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the WafRule message item modal.
 * @author alextsg
 */
@Component({
    selector: 'waf-rule-modal',
    templateUrl: './waf-rule-modal.component.html',
})
export class WafRuleModalComponent implements OnInit {
    /**
     * WafRuleConfigItem instance.
     */
    @Input()
    public editable: WafRuleConfigItem | WafRuleOverridesConfigItem;

    /**
     * Mode setting on the parent WafPolicy item.
     */
    @Input()
    public parentMode: WafMode;

    /**
     * If true, allows configuring the mode on the WafRule level.
     */
    @Input()
    public modeDelegationAllowed = false;

    /**
     * String to be displayed as the modal header.
     */
    @Input()
    public modalHeaderKey: string;

    /**
     * Name of the CRS rule. Applicable only when this modal is editing a CRS rule override, since
     * the name isn't part of the WafRuleOverrides config object.
     */
    @Input()
    public crsRuleName: string;

    /**
     * Rule field of the CRS rule. Applicable only when this modal is editing a CRS rule override,
     * since the rule string isn't part of the WafRuleOverrides config object.
     */
    @Input()
    public crsRuleString: string;

    /**
     * Emitted when the user wants to save the modal.
     */
    @Output()
    public onSubmit = new EventEmitter();

    /**
     * Emitted when the user wants to cancel editing the modal.
     */
    @Output()
    public onCancel = new EventEmitter();

    /**
     * Emitted after saving the rule to set the rule position.
     */
    @Output()
    public setRulePosition = new EventEmitter();

    /**
     * AviDataGrid config for the group WafExcludeList.
     */
    public excludeListGridConfig: IAviDataGridConfig;

    /**
     * Enum value and label of the WafMode WAF_MODE_DETECTION_ONLY enum. Used for radio buttons.
     */
    public readonly detectionModeEnumValue: IEnumValue;

    /**
     * Enum value and label of the WafMode WAF_MODE_ENFORCEMENT enum. Used for radio buttons.
     */
    public readonly enforcementModeEnumValue: IEnumValue;

    /**
     * Enum value and label of the parent WafPolicy item's mode configuration. Used for radio
     * buttons.
     */
    public parentModeEnumValue: IEnumValue;

    /**
     * Property used as the ngModel value of the mode configuration radio buttons.
     */
    public mode: string;

    /**
     * True if editing a rule override instead of a WAF rule. If editing an override, the name
     * and rule fields are readonly.
     */
    public isWafRuleOverride: boolean;

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private l10nService: L10nService,
        private schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.excludeListGridConfig = {
            getRowId(index): number {
                return index;
            },
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleSubnet),
                    id: 'name',
                    transform(excludeListEntry: WafExcludeListEntryConfigItem) {
                        return excludeListEntry.subnet || '-';
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.columnTitlePath),
                    id: 'url',
                    transform(excludeListEntry: WafExcludeListEntryConfigItem) {
                        return excludeListEntry.path || '-';
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleMatchElement),
                    id: 'url',
                    transform(excludeListEntry: WafExcludeListEntryConfigItem) {
                        return excludeListEntry.matchElement || '-';
                    },
                },
            ],
            multipleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.actionLabelRemove),
                    onClick: (excludeListEntries: WafExcludeListEntryConfigItem[]) => {
                        excludeListEntries.forEach((entry: WafExcludeListEntryConfigItem) => {
                            this.removeExcludeListEntry(entry);
                        });
                    },
                },
            ],
            singleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.actionLabelEdit),
                    shape: 'pencil',
                    onClick: (excludeListEntry: WafExcludeListEntryConfigItem) => {
                        this.editExcludeListEntryMessageItem(excludeListEntry);
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.actionLabelRemove),
                    shape: 'trash',
                    onClick: (excludeListEntry: WafExcludeListEntryConfigItem) => {
                        this.removeExcludeListEntry(excludeListEntry);
                    },
                },
            ],
        };

        this.detectionModeEnumValue = schemaService.getEnumValue(
            'WafMode',
            WafMode.WAF_MODE_DETECTION_ONLY,
        );

        this.enforcementModeEnumValue = schemaService.getEnumValue(
            'WafMode',
            WafMode.WAF_MODE_ENFORCEMENT,
        );
    }

    /** @overriide */
    public ngOnInit(): void {
        const parentModeLabel = this.l10nService.getMessage(
            l10nKeys.usePolicyModeLabel,
            [this.getParentPolicyModeLabel()],
        );

        this.parentModeEnumValue = {
            label: parentModeLabel,
            value: 'NONE',
            description: '',
        };

        const { mode } = this.editable.config;

        this.mode = !isUndefined(mode) ? mode : this.parentModeEnumValue.value;

        this.isWafRuleOverride = this.editable instanceof WafRuleOverridesConfigItem;
    }

    /**
     * Called when the mode configuration radio buttons have been changed.
     */
    public handleModeChange(): void {
        if (this.mode === this.parentModeEnumValue.value) {
            this.editable.mode = undefined;
        } else {
            this.editable.mode = this.mode as WafMode;
        }
    }

    /**
     * Handler for saving the modal.
     */
    public handleSubmit(): void {
        this.onSubmit.emit();
        this.setRulePosition.emit();
    }

    /**
     * Handler to cancel editing the modal.
     */
    public handleCancel(): void {
        this.onCancel.emit();
    }

    /**
     * Returns the WafExcludeListEntry RepeatedMessageItem.
     */
    public get excludeList(): RepeatedMessageItem<WafExcludeListEntryConfigItem> {
        return this.editable.config.exclude_list;
    }

    /**
     * Called to add a WafExcludeListEntry item to the WafExcludeList.
     */
    public addExcludeListEntry(): void {
        this.editable.addExcludeListEntryMessageItem();
    }

    /**
     * Returns the name of the rule.
     */
    public get name(): string {
        return this.isWafRuleOverride ? this.crsRuleName : this.editable.getName();
    }

    /**
     * Called to edit a WafExcludeListEntry item in the WafExcludeList.
     */
    private editExcludeListEntryMessageItem(excludeListEntry: WafExcludeListEntryConfigItem): void {
        this.editable.editExcludeListEntryMessageItem(excludeListEntry);
    }

    /**
     * Called to remove a WafExcludeListEntry item from the WafExcludeList.
     */
    private removeExcludeListEntry(excludeListEntry: WafExcludeListEntryConfigItem): void {
        this.editable.removeExcludeListEntry(excludeListEntry);
    }

    /**
     * Returns the label to be used for the parent WafPolicy's mode configuration.
     */
    private getParentPolicyModeLabel(): string {
        return this.schemaService.getEnumValue('WafMode', this.parentMode).label;
    }
}

/**
 * @module LaunchConfigModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { ObjectTypeItem } from 'ajs/modules/data-model';
import { LaunchConfigModalComponent } from '../../../angular/modules/launch-config/components';
import { withFullModalMixin } from '../../utilities/mixins';

interface IAutoScaleLaunchConfigConfig {
    name?: string;
    image_id?: string;
    use_external_asg?: boolean;
}

interface IAutoScaleLaunchConfigData {
    config: IAutoScaleLaunchConfigConfig;
}

export class AutoScaleLaunchConfig extends withFullModalMixin(ObjectTypeItem) {
    public data: IAutoScaleLaunchConfigData;

    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'autoscalelaunchconfig',
            objectType: 'AutoScaleLaunchConfig',
            permissionName: 'PERMISSION_AUTOSCALE',
            windowElement: LaunchConfigModalComponent,
        };

        super(extendedArgs);
    }

    /**
     * @override
     */
    public dataToSave(): IAutoScaleLaunchConfigConfig {
        const config = super.dataToSave();

        // eslint-disable-next-line no-extra-parens
        (config as IAutoScaleLaunchConfigConfig).image_id =
            // eslint-disable-next-line no-extra-parens
            (config as IAutoScaleLaunchConfigConfig).image_id || 'default';

        return config;
    }
}

const moduleName = 'ajs-legacy';
const componentName = 'dropdown';
const prefix = `${moduleName}.${componentName}`;

export const searchInputPlaceholder = `${prefix}.searchInputPlaceholder`;
export const emptyValidEntriesLabel = `${prefix}.emptyValidEntriesLabel`;
export const clearSelectedBtnLabel = `${prefix}.clearSelectedBtnLabel`;

export const ENGLISH = {
    [searchInputPlaceholder]: 'Search',
    [emptyValidEntriesLabel]: '-- no valid entries --',
    [clearSelectedBtnLabel]: 'Clear selected',
};

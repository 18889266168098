const moduleName = 'ajs-legacy';
const componentName = 'pool-health-monitors-selector';
const prefix = `${moduleName}.${componentName}`;

export const selectHealthMonitorPlaceholder = `${prefix}.selectHealthMonitorPlaceholder`;
export const addActiveMonitorButton = `${prefix}.addActiveMonitorButton`;

export const ENGLISH = {
    [selectHealthMonitorPlaceholder]: 'Select a Health Monitor',
    [addActiveMonitorButton]: 'Add Active Monitor',
};

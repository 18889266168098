/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'waf-exceptions-modal';
const prefix = `${moduleName}.${componentName}`;

export const groupNameLabel = `${prefix}.groupNameLabel`;
export const ruleNameLabel = `${prefix}.ruleNameLabel`;
export const existingExceptionsLabel = `${prefix}.existingExceptionsLabel`;
export const anySubnetInputLabel = `${prefix}.anySubnetInputLabel`;
export const anyPathInputLabel = `${prefix}.anyPathInputLabel`;
export const anyMatchInputLabel = `${prefix}.anyMatchInputLabel`;
export const newExceptionsLabel = `${prefix}.newExceptionsLabel`;
export const noNewExceptionsCanBeAddedMessage = `${prefix}.noNewExceptionsCanBeAddedMessage`;
export const subnetInputPlaceholder = `${prefix}.subnetInputPlaceholder`;
export const andSeparatorLabel = `${prefix}.andSeparatorLabel`;
export const uriPathInputPlaceholder = `${prefix}.uriPathInputPlaceholder`;
export const matchElementInputPlaceholder = `${prefix}.matchElementInputPlaceholder`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const addExceptionHeader = `${prefix}.addExceptionHeader`;

export const ENGLISH = {
    [groupNameLabel]: 'Group Name',
    [ruleNameLabel]: 'Rule Name',
    [existingExceptionsLabel]: 'Existing Exceptions',
    [anySubnetInputLabel]: 'ANY SUBNET',
    [anyPathInputLabel]: 'ANY PATH',
    [anyMatchInputLabel]: 'ANY MATCH ELEMENT',
    [newExceptionsLabel]: 'New Exceptions',
    [noNewExceptionsCanBeAddedMessage]: 'No new exceptions can be added',
    [subnetInputPlaceholder]: 'Any Subnet',
    [andSeparatorLabel]: 'and',
    [uriPathInputPlaceholder]: 'Any Path',
    [matchElementInputPlaceholder]: 'Any Match Element',
    [saveButtonLabel]: 'Save',
    [cancelButtonLabel]: 'Cancel',
    [addExceptionHeader]: 'Add Exception',
};

/**
 * @module PoliciesModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { without } from 'underscore';
import { IGridConfig } from 'ajs/components/common/grid/grid-data-model';
import { IDnsMxRdata } from 'generated-types';
import * as Regex from 'ng/utils/regex.utils';

import template from './dns-mx-records.component.html';
import './dns-mx-records.component.less';

type TRegex = typeof Regex;

interface IMxRecordGridConfig extends IGridConfig {
    Regex: TRegex;
}

const hostTemplate = `<input
    type="text"
    class="full-width"
    placeholder="Host"
    ng-pattern="::config.Regex.dnsName"
    ng-model="row.host"
/>`;

const priorityTemplate = `<input
    type="number"
    config-field-input-validation
    object-type="DnsRecord"
    field-name="mx_records"
    placeholder="Priority"
    ng-model="row.priority"
/>`;

/**
 * @description
 *     Component to render mx records in dns record config modal.
 * @author Amit Dubey
 */
class DnsMxRecordsController {
    /**
     * Regex for host field
     */
    public regex: TRegex;

    /**
     * Grid config for MX Records
     */
    public mxRecordsGridConfig: Partial<IMxRecordGridConfig>;

    /**
     * Mx records array.
     */
    public mxRecords: IDnsMxRdata[];

    constructor(Regex: TRegex) {
        this.regex = Regex;
    }

    /**
     * @override
     */
    public $onInit(): void {
        this.mxRecordsGridConfig = {
            id: 'dns-mx-records',
            fields: [{
                name: 'host',
                title: 'Host',
                template: hostTemplate,
            }, {
                name: 'priority',
                title: 'Priority',
                template: priorityTemplate,
            }],
            multipleactions: [{
                title: 'Remove',
                do: (rows: IDnsMxRdata[]) => {
                    this.mxRecords =
                        without(
                            this.mxRecords,
                            ...rows,
                        );

                    return true;
                },
            }],
            layout: {
                hideDisplaying: true,
                hideSearch: true,
            },
            Regex: this.regex,
        };
    }

    /**
     * Function to add new field for mx record
     */
    public addMxRecord(): void {
        this.mxRecords.push({
            host: '',
        });
    }
}

DnsMxRecordsController.$inject = [
    'Regex',
];

export const dnsMxRecordsComponentOptions = {
    bindings: {
        mxRecords: '=',
    },
    controller: DnsMxRecordsController,
    template,
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './authentication.less';
import * as l10n from './AuthenticationController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('AuthenticationController', [
'AviModal',
'AuthProfile',
'$q',
'$templateCache',
'systemConfigService',
'welcomeService',
'l10nService',
function(
    AviModal,
    AuthProfile,
    $q,
    $templateCache,
    systemConfig,
    welcomeService,
    l10nService,
) {
    const vm = this;
    let authProfile = null;

    vm.settings = null;

    vm.ui = {
        busy: false,
    };

    this.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    vm.gridConfig = {
        id: 'auth-mapping-rules',
        props: {
            l10nKeys,
        },
        fields: [{
            name: 'authorization',
            title: l10nService.getMessage(l10nKeys.columntTitleAuthorization),
            template: require('./auth-mapping-rule-authorization.partial.html'),
        }, {
            name: 'assignment',
            title: l10nService.getMessage(l10nKeys.columntTitleAssignment),
            template: require('./auth-mapping-rule-assignment.partial.html'),
        }],
        rowId: 'index',
        singleactions: [{
            title: l10nService.getMessage(l10nKeys.actionBtnEdit),
            class: 'icon-pencil-4',
            do(row) {
                vm.openTenantRoleMappingModal(row);
            },
        }],
        multipleactions: [{
            title: l10nService.getMessage(l10nKeys.actionBtnDelete),
            do(rows) {
                deleteRules(rows);

                return true;
            },
        }],
        withReordering: true,
        renderAll: true, //due to rows cell height not being constant
    };

    /**
     * Returns the string to be displayed for Group matches.
     * @param  {Object} match - group_match object containing criteria and groups.
     * @return {string}
     */
    function getGroupString(match) {
        let output = '';
        const { criteria, groups } = match;

        switch (criteria) {
            case 'AUTH_MATCH_CONTAINS':
                output += l10nService.getMessage(l10nKeys.memberOfPrefixLabel);
                break;

            case 'AUTH_MATCH_DOES_NOT_CONTAIN':
                output += l10nService.getMessage(l10nKeys.notMemberOfPrefixLabel);
                break;
        }

        return output + groups.join(', ');
    }

    /**
     * Returns a comma-separated string of names of selected tenants based on tenant_refs.
     * @param  {String[]} refs - Array of tenant_refs.
     * @return {String}
     */
    function getNames(refs) {
        return refs.map(ref => ref.name()).join(', ');
    }

    vm.gridConfig.templateFunctions = {
        getNames,
        getGroupString,
    };

    /**
     * Swaps rules in the grid up and down, updating their indices.
     * @param {number} from
     * @param {number} to
     */
    this.onRowMove = function(from, to) {
        const
            { mapping_rules: rules } = this.settings.admin_auth_configuration,
            targetRule = rules[from],
            swappedRule = rules[to],
            { index: targetIndex } = targetRule,
            { index: swapIndex } = swappedRule;

        targetRule.index = swapIndex;
        swappedRule.index = targetIndex;

        rules[from] = swappedRule;
        rules[to] = targetRule;

        saveSystemConfig(vm.settings);
    };

    /**
     * Deletes mapping rules.
     * @param  {Object} rules - Mapping rule.
     */
    function deleteRules(rules) {
        const indexHash = {};

        rules.forEach(function(rule) {
            indexHash[rule.index] = true;
        });

        const { admin_auth_configuration: adminAuthConfig } = vm.settings;

        adminAuthConfig.mapping_rules = adminAuthConfig.mapping_rules.filter(function(rule) {
            return !(rule.index in indexHash);
        });

        saveSystemConfig(vm.settings);
    }

    /**
     * Gets system configuration data with request using Auth service.
     */
    function loadSystemConfig() {
        vm.ui.busy = true;

        systemConfig.load().then(() => {
            const { admin_auth_configuration: adminAuthConfiguration } = systemConfig.getConfig();
            let promise;

            if (adminAuthConfiguration) {
                adminAuthConfiguration.mapping_rules = adminAuthConfiguration.mapping_rules || [];
                promise = loadAuthProfile(adminAuthConfiguration.auth_profile_ref.slug());
            }

            return $q.when(promise);
        }).finally(() => {
            vm.settings = angular.copy(systemConfig.getConfig());
            vm.ui.authType = setAuthType();
            vm.ui.busy = false;
        });
    }

    /**
     * Loads Auth Profile in order to get its type.
     * @param  {string} slug - Slug of Auth Profile.
     * @return {ng.$q.promise}
     */
    function loadAuthProfile(slug) {
        if (authProfile) {
            authProfile.destroy();
        }

        authProfile = new AuthProfile({
            id: slug,
        });

        return authProfile.load();
    }

    /**
     * Returns the Auth type label to be displayed.
     * @return {string} Auth type label.
     */
    function setAuthType() {
        let output = l10nService.getMessage(l10nKeys.localAuthType);

        if (vm.settings.admin_auth_configuration && authProfile) {
            if (authProfile.data && authProfile.data.config) {
                output = authProfile.data.config.type.enumeration('AUTH_PROFILE_');
            } else {
                output = l10nService.getMessage(l10nKeys.remoteAuthType);
            }
        }

        return output;
    }

    /**
     * Makes a request through Auth service to update system configuration settings.
     * @param  {Object} settings - System configuration settings.
     */
    function saveSystemConfig(settings) {
        vm.ui.busy = true;

        systemConfig.legacySave(settings).then(() => {
            vm.settings = angular.copy(systemConfig.getConfig());
        }).finally(() => {
            vm.ui.busy = false;
        });
    }

    /**
     * Opens modal to change Auth type.
     */
    vm.openAuthTypeModal = function() {
        AviModal.open('adm-authentication-type', {
            onSuccess: loadSystemConfig,
        });
    };

    /**
     * Opens modal to add/edit mapping rule.
     * @param  {Object=} rule - Mapping rule.
     */
    vm.openTenantRoleMappingModal = function(rule) {
        AviModal.open('adm-authentication-tenant-role-mapping-create', {
            onSuccess: loadSystemConfig,
            rule,
            settings: vm.settings,
            authProfileType: authProfile.data.config['type'],
        });
    };

    /**
     * On initialization.
     */
    loadSystemConfig();
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function serverHealthScoreMonitorListDataTransportFactory(ListDataTransport) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.ListDataTransport
     * @author Alex Malitsky, Ashish Verma
     * @desc
     *
     *    We have to make two calls at the same time since list of health monitors is made of
     *     normal ones and `internal` which come from a different source. Hence to generate a list
     *     of them we have to get both in one DataTransport. This is a bad approach caused by poor
     *     backend API design.
     */
    class ServerHealthScoreMonitorListDataTransport extends ListDataTransport {
        /**
         * Returns two ordinary list-type URLs where one has `hmonstat` as a last `path` part and
         * another `internal`, all other parameters are the same.
         * @param {ListDataTransportRequestParams} params
         * @private
         */
        getRequestUrl_(params) {
            //only part after the last slash is different between calls
            return ['hmonstat', 'internal'].map(postfix => {
                const params_ = angular.copy(params);

                params_['objectName_'].push(postfix);

                return super.getRequestUrl_(params_);
            });
        }
    }

    return ServerHealthScoreMonitorListDataTransport;
}

serverHealthScoreMonitorListDataTransportFactory.$inject = [
    'ListDataTransport',
];

angular.module('avi/app')
    .factory(
        'ServerHealthScoreMonitorListDataTransport',
        serverHealthScoreMonitorListDataTransportFactory,
    );

/**
 * @module IpamModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { Collection } from 'ajs/modules/data-model';
import { AviPermissionResource } from 'generated-types';
import { CustomIpamDnsProfileModalComponent } from 'ng/modules/ipam';
import { customIpamDnsProfileToken } from '.';

/**
 * @desc CustomIpamDnsProfile collection class
 *
 * @author Aravindh Nagarajan
 */
export class CustomIpamDnsCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'customipamdnsprofile',
            objectType: 'CustomIpamDnsProfile',
            windowElement: CustomIpamDnsProfileModalComponent,
            permissionName: AviPermissionResource.PERMISSION_CUSTOMIPAMDNSPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(customIpamDnsProfileToken);
    }
}

CustomIpamDnsCollection.ajsDependencies = [
    customIpamDnsProfileToken,
];

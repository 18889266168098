/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { aviMediumDateFormat } from 'ng/shared/pipes/avi-date.pipe';

/**
 * @typedef {string} AviMediumDate
 * Readable format: 'MMM DD,YYYY HH:MM a'
 */

/**
 * Uses Moment library to format time before formatting.
 * @inner
 * @param {string} timestamp
 * @param {boolean} MyAccount.useUtc
 * @return {moment.Moment}
 */
function momentizeTime(timestamp, useUtc) {
    return useUtc ? moment.utc(timestamp) : moment(timestamp);
}

angular.module('avi/app')
    /**
     * Flexible date filter returning formatted string.
     * @param {string|Moment} timestamp - takes in standard format according to ISO_8601,
     *     or instance of moment time (but latter is for legacy reasons, and discouraged)
     * @param {?string} format - desired format for output, has default set
     * @return {undefined|AviMediumDate|string}
     */
    .filter('aviDate', ['myAccount', '$filter', function(myAccount, $filter) {
        return function(timestamp, format = aviMediumDateFormat) {
            if (!timestamp) { return; }

            const timezone = myAccount.uiProperty.useUTCTime ? '+0000' : '';

            // Note: Following supports legacy code, and is discouraged from further use
            if (timestamp instanceof moment) {
                timestamp = timestamp.format();
            }

            return $filter('date')(timestamp, format, timezone);
        };
    }])

    /**
     * @ngdoc filter
     * @name prettyTimeWithMs
     * @param {number} timestamp - Unix timestamp.
     * Returns the formatted date for given timestamp.
     */
    .filter('prettyTimeWithMs', ['myAccount', function(myAccount) {
        return function(timestamp) {
            if (!timestamp) { return; }

            const time = momentizeTime(timestamp, myAccount.useUtc);

            return time.format('YYYY-MM-DD, h:mm:ss:SS a');
        };
    }])

    .filter('prettyTimeShort', ['myAccount', function(myAccount) {
        return function(timestamp) {
            if (!timestamp) { return; }

            const time = momentizeTime(timestamp, myAccount.useUtc);

            return time.format('h:mm:ss a');
        };
    }])

    .filter('noYearTime', ['myAccount', function(myAccount) { //logs list
        return function(timestamp) {
            if (!timestamp) { return; }

            const time = momentizeTime(timestamp, myAccount.useUtc);

            return time.format('MM/DD h:mm:ss A');
        };
    }])

    /**
     * Returns the difference between the current time and the time passed in.
     * @param {string} timestamp - Date string.
     * @param {boolean} useUtc - True to force UTC, in cases where the time passed in is
     * always in UTC format, regardless of the account settings due to backend constraints.
     */
    .filter('prettyTimeAgo', ['myAccount', function(myAccount) {
        return function(timestamp, useUtc) {
            if (!timestamp) {
                return;
            }

            const time = momentizeTime(timestamp, myAccount.useUtc);

            return time.fromNow();
        };
    }])

    .filter('smartPrettyTime', ['$stateParams', 'myAccount', function($stateParams, myAccount) {
        return function(timestamp) {
            let timeFormat;

            if ($stateParams.timeframe === 'rt' || $stateParams.timeframe === '6h') {
                timeFormat = 'h:mm:ss a';
            } else if ($stateParams.timeframe === '1y' ||
            $stateParams.timeframe === '1q') {
                timeFormat = 'YYYY-MM-DD, h:mm:ss a';
            } else {
                timeFormat = 'MM-DD h:mm:ss a';
            }

            const time = momentizeTime(timestamp, myAccount.useUtc);

            return time.format(timeFormat);
        };
    }])

    /**
     * @ngdoc filter
     * @name uptime
     * @params {number|string|undefined} from - Unix timestamp.
     * @params {number|string=} to - Unix timestamp.
     * @returns {string} String representation of duration between from and to dates.
     * @description Makes string for the difference between current time and one from
     * database (UNIX timestamp). Need to support second argument to allow live-updated templates.
     **/
    .filter('uptime', ['initialDataService', function(initialDataService) {
        return function(from, to = moment().format('X')) {
            let years,
                months,
                days,
                hours,
                minutes,
                secs,
                str = '';

            if (from) {
                from = moment(from, 'X').add(
                    initialDataService.controllerTimeDifference || 0,
                    'seconds',
                );

                if (from.isValid()) {
                    if (!_.isUndefined(to)) {
                        to = moment(to, 'X');

                        if (!to.isValid()) {
                            to = moment();
                        }
                    } else {
                        to = moment();
                    }

                    secs = Math.max(0, moment(to).diff(from, 's'));

                    years = Math.floor(secs / (3600 * 24 * 365));
                    secs -= years * 3600 * 24 * 365;

                    months = Math.floor(secs / (3600 * 24 * 30));
                    secs -= months * 3600 * 24 * 30;

                    days = Math.floor(secs / (3600 * 24));
                    secs -= days * 3600 * 24;

                    hours = Math.floor(secs / 3600);
                    secs -= hours * 3600;

                    minutes = Math.floor(secs / 60);
                    secs -= minutes * 60;

                    if (years) {
                        str = `${years}Y ${months}M `;
                    } else if (months) {
                        str = `${months}M ${days}D `;
                    } else if (days) {
                        str = `${days}D ${hours}h `;
                    } else if (hours) {
                        str = `${hours}h ${minutes}m `;
                    } else if (minutes) {
                        str = `${minutes}m `;
                    } else if (secs) {
                        str = `${secs}s `;
                    }
                }
            }

            return str;
        };
    }]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function windowLocationFactory($window) {
    return $window.location;
}

windowLocationFactory.$inject = [
    '$window',
];

/**
 * @ngdoc service
 * @name windowLocation
 * @description
 *
 *     This is needed mostly for convenient window.location mocking in unit tests.
 */
angular.module('avi/deps')
    .factory('windowLocation', windowLocationFactory);

const moduleName = 'ajs-legacy';
const componentName = 'alert-grid-config';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleTimestamp = `${prefix}.columnTitleTimestamp`;
export const columnTitleResourceName = `${prefix}.columnTitleResourceName`;
export const columnTitleLevel = `${prefix}.columnTitleLevel`;
export const columnTitleSummary = `${prefix}.columnTitleSummary`;
export const actionTitleDelete = `${prefix}.actionTitleDelete`;
export const actionTitleExpand = `${prefix}.actionTitleExpand`;
export const columnTitleMetric = `${prefix}.columnTitleMetric`;
export const columnTitleValue = `${prefix}.columnTitleValue`;
export const columnTitleReportTime = `${prefix}.columnTitleReportTime`;
export const columnTitleEndTime = `${prefix}.columnTitleEndTime`;
export const columnTitleStep = `${prefix}.columnTitleStep`;

export const ENGLISH = {
    [columnTitleMetric]: 'Metric',
    [columnTitleValue]: 'Value',
    [columnTitleReportTime]: 'Report Time',
    [columnTitleEndTime]: 'End Time',
    [columnTitleStep]: 'Step',
    [columnTitleTimestamp]: 'Timestamp',
    [columnTitleResourceName]: 'Resource Name',
    [columnTitleLevel]: 'Level',
    [columnTitleSummary]: 'Summary',
    [actionTitleDelete]: 'Delete',
    [actionTitleExpand]: 'Expand',
};

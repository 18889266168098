/**
 * Accounts module
 * @module AccountsModule
 * @preferred
 */
/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */
import angular from 'angular';

import {
    authenticationTenantRoleMappingController,
    authenticationTypeController,
    roleController,
    systemConfigurationEditAccessController,
    tenantModalController,
    tenantSettingsController,
} from '.';

const accountsModule = angular.module('avi/accounts');

const controllers = [
    {
        controller: roleController,
        name: 'RoleController',
    },
    {
        controller: authenticationTypeController,
        name: 'AuthenticationTypeController',
    },
    {
        controller: authenticationTenantRoleMappingController,
        name: 'AuthenticationTenantRoleMappingController',
    },
    {
        controller: systemConfigurationEditAccessController,
        name: 'SystemConfigurationEditAccessController',
    },
    {
        controller: tenantSettingsController,
        name: 'TenantSettingsController',
    },
    {
        controller: tenantModalController,
        name: 'TenantModalController',
    },
];

controllers.forEach(({ name, controller }) => accountsModule.controller(name, controller));

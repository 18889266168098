/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'waf-policy-modal';
const prefix = `${moduleName}.${componentName}`;

export const editWafPolicyHeader = `${prefix}.editWafPolicyHeader`;
export const newWafPolicyHeader = `${prefix}.newWafPolicyHeader`;
export const settingsTabTitle = `${prefix}.settingsTabTitle`;
export const generalSectionHeader = `${prefix}.generalSectionHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const wafProfileInputLabel = `${prefix}.wafProfileInputLabel`;
export const wafProfileInputPlaceholder = `${prefix}.wafProfileInputPlaceholder`;
export const policyModeLabel = `${prefix}.policyModeLabel`;
export const allowModeDelegationCheckboxLabel = `${prefix}.allowModeDelegationCheckboxLabel`;
export const paranoiaLevelInputLabel = `${prefix}.paranoiaLevelInputLabel`;
export const paranoiaLevelInputPlaceholder = `${prefix}.paranoiaLevelInputPlaceholder`;
export const learningTabTitle = `${prefix}.learningTabTitle`;
export const applicationLearningSectionHeader = `${prefix}.applicationLearningSectionHeader`;
export const allowlistTabTitle = `${prefix}.allowlistTabTitle`;
export const allowlistRulesLabel = `${prefix}.allowlistRulesLabel`;
export const addRuleButtonLabel = `${prefix}.addRuleButtonLabel`;
export const positiveSecurityTabTitle = `${prefix}.positiveSecurityTabTitle`;
export const applicationRulesTabTitle = `${prefix}.applicationRulesTabTitle`;
export const positiveSecuritySectionHeader = `${prefix}.positiveSecuritySectionHeader`;
export const signaturesTabTitle = `${prefix}.signaturesTabTitle`;
export const preCrsRulesLabel = `${prefix}.preCrsRulesLabel`;
export const preCrsRulesPlaceholder = `${prefix}.preCrsRulesPlaceholder`;
export const crsRulesLabel = `${prefix}.crsRulesLabel`;
export const newReleaseLabel = `${prefix}.newReleaseLabel`;
export const newCrsInfoLabel = `${prefix}.newCrsInfoLabel`;
export const applyLatestCrsLabel = `${prefix}.applyLatestCrsLabel`;
export const downloadButtonLabel = `${prefix}.downloadButtonLabel`;
export const crsVersionInputLabel = `${prefix}.crsVersionInputLabel`;
export const resetModeOverrideButtonLabel = `${prefix}.resetModeOverrideButtonLabel`;
export const crsRulesPlaceholder = `${prefix}.crsRulesPlaceholder`;
export const postCrsRulesLabel = `${prefix}.postCrsRulesLabel`;
export const postCrsRulesPlaceholder = `${prefix}.postCrsRulesPlaceholder`;

export const ENGLISH = {
    [editWafPolicyHeader]: 'Edit WAF Policy: {0}',
    [newWafPolicyHeader]: 'New WAF Policy: {0}',
    [settingsTabTitle]: 'Settings',
    [generalSectionHeader]: 'General',
    [nameInputLabel]: 'Name',
    [wafProfileInputLabel]: 'WAF Profile',
    [wafProfileInputPlaceholder]: 'Select WAF Profile',
    [policyModeLabel]: 'Policy Mode',
    [allowModeDelegationCheckboxLabel]: 'Allow Mode Delegation',
    [paranoiaLevelInputLabel]: 'Paranoia Level',
    [paranoiaLevelInputPlaceholder]: 'Select Paranoia Level',
    [learningTabTitle]: 'Learning',
    [applicationLearningSectionHeader]: 'Application Learning',
    [allowlistTabTitle]: 'Allowlist',
    [allowlistRulesLabel]: 'ALLOWLIST RULES ({0})',
    [addRuleButtonLabel]: 'Add Rule',
    [positiveSecurityTabTitle]: 'Positive Security',
    [applicationRulesTabTitle]: 'Application Rules',
    [positiveSecuritySectionHeader]: 'POSITIVE SECURITY',
    [signaturesTabTitle]: 'Signatures',
    [preCrsRulesLabel]: 'PRE-CRS RULES',
    [preCrsRulesPlaceholder]: 'No Pre-CRS Groups Have Been Created',
    [crsRulesLabel]: 'CRS RULES',
    [newReleaseLabel]: 'New Release!',
    [newCrsInfoLabel]: '{0} Available.',
    [applyLatestCrsLabel]: 'Please apply the latest CRS.',
    [downloadButtonLabel]: 'Download Now',
    [crsVersionInputLabel]: 'CRS Version',
    [resetModeOverrideButtonLabel]: 'Reset Mode Overrides',
    [crsRulesPlaceholder]: 'CRS Version Contains No Rules',
    [postCrsRulesLabel]: 'POST-CRS RULES',
    [postCrsRulesPlaceholder]: 'No Post-CRS Groups Have Been Created',
};

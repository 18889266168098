/**
 * @module ServiceEngineGroup
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { L10nService } from '@vmw/ngx-vip';
import { AviConfirmService, StringService } from 'ajs/modules/core';
import { Collection } from 'ajs/modules/data-model';
import { SEGroup } from '../../factories/se-group.item';
import template from './segroup-cluster-host-config.component.html';
import * as l10n from './segroup-cluster-host-config.component.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCollection = typeof Collection;

class SegroupClusterHostConfigController {
    /**
     * SEGroup Item. Component binding.
     */
    public segroup: SEGroup;

    /**
     * Cloud Id to be set in Collection params.
     */
    public cloudId: string;

    /**
     * Host collection used in dropdown.
     */
    public hostCollection: Collection;

    /**
     * Cluster collection used in dropdown.
     */
    public clusterCollection: Collection;

    public readonly l10nKeys = l10nKeys;

    /**
     * Store previous list of clusters.
     * Will be used to update cluster refs when user denies the change.
     */
    private prevClusterRefs: string[];

    constructor(
        private readonly stringService: StringService,
        private readonly l10nService: L10nService,
        private aviConfirmService: AviConfirmService,
        private Collection: TCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onInit(): void {
        this.prevClusterRefs = this.segroup.vcenterClusterRefs?.concat() || [];

        this.hostCollection = new this.Collection({
            objectName: 'vimgrhostruntime',
            sortBy: 'name',
            params: {
                fields: 'name, tenant_ref',
                'cloud_ref.uuid': this.cloudId,
            },
        });
        this.clusterCollection = new this.Collection({
            objectName: 'vimgrclusterruntime',
            sortBy: 'name',
            params: {
                fields: 'name, tenant_ref',
                'cloud_ref.uuid': this.cloudId,
            },
        });
        this.setHostCollectionFiltersSearchParams();
    }

    /**
     * Handler for change in cluster.
     */
    public handleVCenterClustersChange() : void {
        const { segroup } = this;
        const { vcenterClusterRefs: clusterRefs, vcenterHostRefs: hostRefs } = segroup;

        if (clusterRefs?.length) {
            if (!hostRefs?.length) {
                this.setHostCollectionFiltersSearchParams();
                this.prevClusterRefs = clusterRefs.concat();
            } else {
                const { l10nService } = this;

                /**
                 * Confirm if user wants to change cluster when Hosts were already selected.
                 * If so, clear hosts list to avoid scope of hosts issue.
                 * Else, revert the change in cluster.
                 */
                this.aviConfirmService.confirm({
                    message: l10nService.getMessage(l10nKeys.clusterChangeWarning),
                    headerText: l10nService.getMessage(l10nKeys.warningTitle),
                    confirmButtonText: l10nService.getMessage(l10nKeys.continueMessage),
                    isWarning: true,
                }).then(() => {
                    segroup.vcenterHostRefs = [];
                    this.prevClusterRefs = clusterRefs.concat();
                    this.setHostCollectionFiltersSearchParams();
                }, () => {
                    segroup.vcenterClusterRefs = this.prevClusterRefs.concat();
                });
            }
        } else {
            this.prevClusterRefs = [];
            this.setHostCollectionFiltersSearchParams();
        }
    }

    /**
     * Handler for toggling include and exclude of cluster.
     */
    public handleClusterModeChange() : void {
        const { segroup } = this;
        const { vcenterClusterRefs: clusterRefs, vcenterHostRefs: hostRefs } = segroup;

        if (clusterRefs?.length && hostRefs?.length) {
            const { l10nService } = this;

            /**
             * Confirm if user wants to change include/exclude mode
             * when Hosts were already selected.
             * If so, clear hosts list to avoid scope of hosts issue.
             * Else, revert the change in include/exclude mode.
             */
            this.aviConfirmService.confirm({
                message: l10nService.getMessage(l10nKeys.clusterChangeWarning),
                headerText: l10nService.getMessage(l10nKeys.warningTitle),
                confirmButtonText: l10nService.getMessage(l10nKeys.continueMessage),
                isWarning: true,
            }).then(() => {
                segroup.vcenterHostRefs = [];
            }, () => {
                segroup.vcenterClusters.include =
                    !segroup.vcenterClusters.include;
            });
        }

        this.setHostCollectionFiltersSearchParams();
    }

    /** @override */
    public $onDestroy(): void {
        this.hostCollection.destroy();
        this.clusterCollection.destroy();
    }

    /**
     * Sets search and exclude search params to get filtered results
     * as per selected mode and clusters scope.
     */
    private setHostCollectionFiltersSearchParams() : void {
        const { vcenterClusterRefs: clusterRefs } = this.segroup;
        const formattedSearchParam = this.getFormattedHostCollectionSearchParam(clusterRefs);
        const excludeParam = !this.segroup.vcenterClusters.include ?
            'search' : undefined;

        this.hostCollection.setParams({
            search: formattedSearchParam,
            exclude: excludeParam,
        });
    }

    /**
     * Returns formatted search param.
     */
    private getFormattedHostCollectionSearchParam(clusterRefs: string[] = []) : string {
        let formattedSearchParam = '';

        formattedSearchParam = clusterRefs.map((clusterRef: string) => {
            return `(cluster_uuid,${this.stringService.slug(clusterRef)})`;
        }).join('|');

        return formattedSearchParam;
    }
}

SegroupClusterHostConfigController.$inject = [
    'stringService',
    'l10nService',
    'AviConfirmService',
    'Collection',
];

/**
 * @name SegroupClusterHostConfigController
 * @description Component for configuring a Clusters, Hosts in the SEGroup modal.
 * Supported for vCenter cloud type only.
 * @author Rachit Aggarwal
 */
export const segroupClusterHostConfigOptions = {
    /**
     * @property {SEGroup} segroup - SEGroup item.
     * @property {string} cloudId.
     */
    bindings: {
        segroup: '<',
        cloudId: '<',
    },
    controller: SegroupClusterHostConfigController,
    template,
};

/**
 * @module CloudModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    Inject,
    Input,
    OnInit,
} from '@angular/core';
import { IAviDropdownOption } from 'ng/shared/components';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { Item } from 'ajs/modules/data-model';
import { SchemaService } from 'ajs/modules/core';
import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { ITEM_ID_TOKEN } from 'ng/shared/shared.constants';
import { CLOUD_TYPE, ICloud } from '../../cloud.types';
import './cloud-modal.component.less';
import * as l10n from './cloud-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the Cloud modal.
 * @author alextsg
 */
@Component({
    selector: 'cloud-modal',
    templateUrl: './cloud-modal.component.html',
})
export class CloudModalComponent implements OnInit {
    /**
     * Cloud Item.
     */
    @Input() public editable: ICloud;

    /**
     * Used to set a default cloud type.
     */
    @Input() public overrideCloudType?: CLOUD_TYPE;

    /**
     * Layout for clrForm.
     */
    public verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Cloud type dropdown options.
     */
    public cloudTypeOptions: IAviDropdownOption[] = [];

    /**
     * License tier dropdown options.
     */
    public licenseTierOptions: IAviDropdownOption[] = [];

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * True if the Cloud Item is being edited, not created.
     */
    public readonly isEditing: boolean;

    public constructor(
    // eslint-disable-next-line @typescript-eslint/indent
        @Inject(SchemaService) schemaService: SchemaService,
        l10nService: L10nService,
        @Inject(ITEM_ID_TOKEN)
        itemId: string,
    ) {
        const cloudTypes = schemaService.getEnumValues('CloudType');
        const licenseTiers = schemaService.getEnumValues('LicenseTierType');

        this.cloudTypeOptions = createOptionsFromEnumProps(cloudTypes);
        this.licenseTierOptions = createOptionsFromEnumProps(licenseTiers);

        this.isEditing = Boolean(itemId);

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.overrideCloudType) {
            this.editable.onCloudTypeChange(this.overrideCloudType);
            this.editable.setPristine();
        }
    }

    /**
     * Adds an Item as a child of Cloud to the MacroStack.
     */
    public addCloudChildToMacroStack(item: Item): void {
        this.editable.addChildToMacroStack(item);
    }

    /**
     * Deletes an Item from the MacroStack.
     */
    public deleteCloudChildFromMacroStack(item: Item): void {
        this.editable.deleteChildFromMacroStack(item);
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { OPENSTACK_KEYSTONE_SERVICE_TOKEN } from 'ajs/modules/core';

const tenantCollectionFactory = (Collection, Tenant, openstackKeystoneService, defaultValues) => {
    class TenantCollection extends Collection {
        /**
         * @override
         */
        isCreatable() {
            // Enforce to disable editing remote users if keystone auth is enabled
            if (openstackKeystoneService.keystoneAuthEnabled) {
                return false;
            }

            return super.isCreatable();
        }

        /**
         * @override
         */
        getDefaultItemConfig_() {
            const defaultConfig = angular.merge({
                config_settings: defaultValues.getDefaultItemConfigByType('tenantconfiguration'),
            },
            this.serverDefaultsOverride_);

            // Then override it with customizable defaults
            if (angular.isObject(this.defaults_)) {
                angular.merge(defaultConfig, this.defaults_);
            }

            return defaultConfig;
        }
    }

    const defaultDataSources_ = [
        'inventory',
        'keystone-auth',
        'users',
    ];

    const allDataSources_ = {
        inventory: {
            source: 'ListCollDataSource',
            transformer: 'TenantInventoryDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
        'keystone-auth': {
            source: 'KeystoneIsEnabledCollDataSource',
            transformer: 'RevisedDataTransformer',
            transport: 'KeystoneIsEnabledDataTransport',
            fields: ['keystone'],
            dependsOn: 'config',
        },
        users: {
            source: 'TenantUsersCollDataSource',
            transformer: 'TenantUsersDataTransformer',
            transport: 'ListDataTransport',
            fields: ['users'],
            dependsOn: 'config',
        },
        'collection-metrics': {
            source: 'CollMetricsCollDataSource',
            transformer: 'CollMetricsDataTransformer',
            transport: 'CollMetricsDataTransport',
            fields: [
                'tenant_stats.avg_num_backend_servers',
                'tenant_stats.avg_num_se_cores',
                'tenant_stats.max_total_se_throughput',
                'tenant_stats.sum_total_vs_client_bytes',
                'tenant_stats.max_num_ses',
                'tenant_stats.avg_num_ses',
                'tenant_stats.max_num_sockets',
                'tenant_stats.avg_num_sockets',
            ],
            dependsOn: 'config',
        },
    };

    Object.assign(TenantCollection.prototype, {
        objectName_: 'tenant-inventory',
        windowElement_: 'adm-tenant-create',
        itemClass_: Tenant,
        isStatic_: false,
        defaultDataSources_,
        allDataSources_,
    });

    return TenantCollection;
};

tenantCollectionFactory.$inject = [
    'Collection',
    'Tenant',
    OPENSTACK_KEYSTONE_SERVICE_TOKEN,
    'defaultValues',
];

/**
 * @ngdoc service
 * @name TenantCollection
 * @module services/TenantCollection
 * @description
 *
 *     Collection of {Tenant} items.
 *
 */
angular.module('aviApp').factory('TenantCollection', tenantCollectionFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './waf-profile-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name wafProfileList
 * @description Component for the Waf Policy List page.
 */
class WafProfileListController {
    constructor(
        WafProfileCollection,
        CRUDGridConfig,
        l10nService,
    ) {
        this._WafProfileCollection = WafProfileCollection;
        this._CRUDGridConfig = CRUDGridConfig;

        this.l10nService_ = l10nService;
        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        const { l10nService_: l10nService } = this;

        this.gridConfig = new this._CRUDGridConfig();
        this.gridConfig.collection = new this._WafProfileCollection({
            params: {
                fields: 'name,tenant_ref',
            },
        });
        this.gridConfig.fields = [{
            name: 'data.config.name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            sortBy: 'name',
        }];

        const { objectName } = this.gridConfig.collection;

        this.gridConfig.id = `${objectName}-list-page`;
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

WafProfileListController.$inject = [
    'WafProfileCollection',
    'CRUDGridConfig',
    'l10nService',
];

angular.module('avi/waf').component('wafProfileList', {
    controller: WafProfileListController,
    template:
        `<div class="panel templatesList">
            <collection-grid config="$ctrl.gridConfig"></collection-grid>
        </div>`,
});

const moduleName = 'ip-reputation-db';
const componentName = 'ip-reputation-db-list-page-header';
const prefix = `${moduleName}.${componentName}`;

export const ipReputationTitle = `${prefix}.ipReputationTitle`;
export const registerControllerMessage = `${prefix}.registerControllerMessage`;
export const registerControllerButtonLabel = `${prefix}.registerControllerButtonLabel`;
export const enableIPReputationSyncMessage = `${prefix}.enableIPReputationSyncMessage`;
export const editPulseSettingsButtonLabel = `${prefix}.editPulseSettingsButtonLabel`;

export const ENGLISH = {
    [ipReputationTitle]: 'IP Reputation',
    [registerControllerMessage]: 'Register your controller and easily stay current with IP ' +
        'Reputation updates.',
    [registerControllerButtonLabel]: 'Register Controller',
    [enableIPReputationSyncMessage]: 'Keep IP Reputation DB up-to-date by enabling IP Reputation ' +
        'auto-update in Pulse.',
    [editPulseSettingsButtonLabel]: 'Edit Pulse Settings',
};

/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    ContentChild,
    Input,
} from '@angular/core';
import classnames from 'classnames';
import { InputFieldDirective } from '../../directives/input-field';
import './avi-input-container.component.less';

/**
 * @description Component to wrap a label (or avi-label-with-tooltip) and an input element, used to
 *     replace Clarity's clr-input-container component.
 *
 * @author alextsg
 */
@Component({
    selector: 'avi-input-container',
    templateUrl: './avi-input-container.component.html',
})
export class AviInputContainerComponent {
    /**
     * Sets the noMarginTop property.
     */
    @Input('noMarginTop')
    private set setNoMarginTop(noMarginTop: boolean | '') {
        this.noMarginTop = noMarginTop === '' || noMarginTop;
    }

    /**
     * Optional Helper-text.
     * To be displayed below input field.
     */
    @Input()
    public helperText ?= '';

    /**
     * Optional Error text.
     * To be displayed when ngModel becomes invalid.
     */
    @Input()
    public errorText ?= '';

    /**
     * Ref of input field.
     * Used to get the validity.
     */
    @ContentChild(InputFieldDirective)
    private inputFieldRef: InputFieldDirective;

    /**
     * If true, removes the margin-top from the top-level div
     */
    public noMarginTop = false;

    /**
     * Returns true when input field is valid.
     */
    public isValid(): boolean {
        return this.inputFieldRef.isValid();
    }

    /**
     * Returns the classes to be set on the top level div.
     */
    public get topLevelClassNames(): string {
        return classnames(
            'avi-input-container',
            'clr-wrapper',
            this.noMarginTop && 'avi-input-container--no-margin-top',
        );
    }
}

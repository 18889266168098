/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './avi-icon.component.less';
import template from './avi-icon.partial.html';

const componentName = 'avi-icon';

/**
 * Hash to convert desired effect into sl- postfix, for simple line icons.
 * @type {{success: string, warning: string, error: string, info: string}}
 * @private
 */
const typeToIcon = {
    success: 'icon-check',
    error: 'icon-minus',
    warning: 'icon-exclamation',
    info: 'icon-info',
};

/**
 * @alias module:component/aviIcon
 * @private
 */
class AviIconController {
    /** @override */
    $onInit() {
        const
            { type } = this,
            typeProp = typeToIcon[type] || type || typeToIcon.success;

        this.iconTypeClass = `sl-${typeProp}`;
        this.statusClass = `${componentName}--${type || 'success'}`;
    }
}

/**
 * @ngdoc component
 * @name aviIcon
 * @module component/aviIcon
 * @param {string=} type - type of icon to fill in for sl-(type), type available in local hash
 * @author Akul Aggarwal
 * @description
 *
 *     Component to generate a 'sl-' icon.
 *
 */
angular.module('avi/component-kit').component('aviIcon', {
    bindings: {
        type: '@?',
    },
    transclude: true,
    controller: AviIconController,
    template,
});

const moduleName = 'shared';
const componentName = 'helper-text-tooltip-icon';
const prefix = `${moduleName}.${componentName}`;

export const rangeLabel = `${prefix}.rangeLabel`;
export const specialValuesLabel = `${prefix}.specialValuesLabel`;

export const ENGLISH = {
    [rangeLabel]: 'Range',
    [specialValuesLabel]: 'Special Values',
};

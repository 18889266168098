/**
 * @module CloudModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { NsxtTransportZoneType } from 'generated-types';
import { isUndefined } from 'underscore';
import { IAviDataGridRow, IAviDropdownOption } from 'ng/shared/components';
import { createDropdownOption } from 'ng/shared/utils';
import {
    ManagementNetworkConfigConfigItem,
    NsxtConfigurationConfigItem,
    Tier1LogicalRouterInfoConfigItem,
} from 'ajs/modules/cloud';

import { L10nService } from '@vmw/ngx-vip';

import './management-network-config.component.less';
import * as l10n from './management-network-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for NSXT Management Network configuration.
 * @author Zhiqian Liu, alextsg
 */
@Component({
    selector: 'management-network-config',
    templateUrl: './management-network-config.component.html',
})
export class ManagementNetworkConfigComponent implements OnInit, OnChanges {
    /**
     * ManagementNetworkConfig Config Item.
     */
    @Input()
    public editable: ManagementNetworkConfigConfigItem;

    /**
     * NsxtConfigurationConfigItem used for making requests for Segment ID lists.
     */
    @Input()
    public nsxtConfiguration: NsxtConfigurationConfigItem;

    /**
     * True if NSX-T Manager address and credentials have been set and succussfully connected.
     */
    @Input()
    public credentialsConnected: boolean;

    /**
     * NsxtConfigurationConfigItem used for making requests for Segment ID lists.
     */
    @Input()
    public transportZoneOptions: IAviDropdownOption[];

    /**
     * Tier1 Logical Router dropdown options.
     */
    @Input()
    public tier1DropdownOptions: IAviDropdownOption[];

    /**
     * Map from transport zone IDs to the respective transport zone type.
     */
    @Input()
    public tzIDToTypeMap = new Map<string, NsxtTransportZoneType>();

    /**
     * Segment dropdown options. Hold either options of OVERLAY Tier1LR segments or VLAN segments.
     */
    public segmentDropdownOptions: IAviDropdownOption[] = [];

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Hash of OVERLAY Tier1 Logical Router IDs to Segment ID dropdown options.
     */
    private overlaySegmentIDOptionsHash: Record<string, IAviDataGridRow[]> = {};

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.isOverlay) {
            this.setOverlaySegmentOptions();
        } else if (this.isVlan) {
            this.setVlanSegmentOptions();
        }
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { tier1DropdownOptions } = changes;

        if (tier1DropdownOptions && !tier1DropdownOptions.firstChange) {
            this.overlaySegmentIDOptionsHash = {};
        }
    }

    /**
     * Check if the transport zone type is VLAN.
     */
    public get isVlan(): boolean {
        return this.editable.isTransportZoneType(NsxtTransportZoneType.VLAN);
    }

    /**
     * Check if the transport zone type is OVERLAY.
     */
    public get isOverlay(): boolean {
        return this.editable.isTransportZoneType(NsxtTransportZoneType.OVERLAY);
    }

    /**
     * Return the Tier1LogicalRouterInfo ConfigItem for OVERLAY transport zone.
     */
    public get overlaySegment(): Tier1LogicalRouterInfoConfigItem {
        return this.editable.overlaySegment;
    }

    /**
     * Handler for changing transport zone.
     */
    public handleTransportZoneChange(): void {
        const { transportZone } = this.editable;
        const tzType = this.tzIDToTypeMap.get(transportZone);

        this.editable.transportZoneType = tzType;
        this.editable.onTransportZoneChange();

        if (this.isVlan) {
            this.setVlanSegmentOptions();
        } else if (this.isOverlay) {
            this.overlaySegmentIDOptionsHash = {};
        }
    }

    /**
     * Handler for changing a Tier1 Logical Router ID in the Tier1LogicalRouterInfo entry. Reset the
     * Segment ID and reset Segment ID dropdown options according to the current tier1 ID.
     * Called under OVERLAY transport zone.
     */
    public handleChangeTier1(): void {
        this.segmentDropdownOptions = [];
        this.editable.overlaySegment.clearSegmentID();
        this.setOverlaySegmentOptions();
    }

    /**
     * Disable transport zone selection if credentials are not connected or the nsxtConfiguration
     * ConfigItem is busy.
     */
    public disableTransportZone(): boolean {
        return !this.credentialsConnected || this.nsxtConfiguration.busy;
    }

    /**
     * Disable OVERLAY segment ID selection if the nsxtConfiguration ConfigItem is busy or the
     * Tier1LR ID has not been selected..
     */
    public disableTier1SegmentID(): boolean {
        return this.nsxtConfiguration.busy || this.isOverlay && !this.isTier1IDSet();
    }

    /**
     * Returns the helper text on the Management Network Segment Segment ID dropdown.
     */
    public getSegmentIDHelperText(): string {
        return this.isTier1IDSet() ?
            '' :
            this.l10nService.getMessage(this.l10nKeys.segmentDropdownHelperText);
    }

    /**
     * True if the Tier 1 Logical Router ID is configured.
     */
    private isTier1IDSet(): boolean {
        return !isUndefined(this.overlaySegment.config.tier1_lr_id);
    }

    /**
     * Fetch a list of Segment IDs and sets dropdown options for VLAN transport zone.
     */
    private async setVlanSegmentOptions(): Promise<void> {
        const { transportZone } = this.editable;
        const segments =
            await this.nsxtConfiguration.fetchVlanSegments(transportZone) || [];

        this.segmentDropdownOptions = segments.map(({ id, name }) => {
            return createDropdownOption(id, name);
        });
    }

    /**
     * Sets Segment ID options fetched from segmentIDOptionsHash. If the options don't exist, fetch
     * them through an API call asynchronously.
     */
    private async setOverlaySegmentOptions(): Promise<void> {
        const { transportZone } = this.editable;
        const { tier1_lr_id: tier1LRID } = this.editable.overlaySegment.config;

        if (this.overlaySegmentIDOptionsHash[tier1LRID]) {
            this.segmentDropdownOptions = this.overlaySegmentIDOptionsHash[tier1LRID];

            return Promise.resolve();
        } else {
            const segments = await this.nsxtConfiguration
                .fetchSegmentsByTier1LRID(transportZone, tier1LRID) || [];
            const segmentOptions = segments.map(({ id, name }) => {
                return createDropdownOption(id, name);
            });

            this.overlaySegmentIDOptionsHash[tier1LRID] = segmentOptions;
            this.segmentDropdownOptions = segmentOptions;
        }
    }
}

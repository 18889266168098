/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { CLOUD_TYPE } from 'ng/modules/cloud/cloud.types';
import '../../../../less/pages/infrastructure/serviceengine-config.less';
import * as l10n from './ServiceEngineConfigurationController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('ServiceEngineConfigurationController', [
'$scope', 'SEGroupCollection', 'Cloud', '$templateCache', 'AviModal', 'Vlan',
'VRFContextCollection', 'getSubnetString', 'ServiceEngine', 'l10nService',
(
    $scope,
    SEGroupCollection,
    Cloud,
    $templateCache,
    AviModal,
    Vlan,
    VRFContextCollection,
    getSubnetString,
    ServiceEngine,
    l10nService,
) => {
    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.seGroupCollection = new SEGroupCollection({
        isStatic: true,
        showTenantName: true,
    });

    $scope.cloud = null;

    const vrfCollection = new VRFContextCollection();

    $scope.cloudDataLoading = false;

    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    $scope.init = function() {
        const config = $scope.editable.getConfig();
        const cloudId = config.cloud_ref.slug();

        $scope.managementNetworkRows = [];

        if ('mgmt_vnic' in config) {
            $scope.managementNetworkRows.push(config.mgmt_vnic);
        }

        $scope.dataNetworkRows = config.data_vnics;

        $scope.vnicRows = $scope.managementNetworkRows.concat(config.data_vnics || []);
        $scope.vlanInterfaceRows = getVlanInterfaceRows($scope.vnicRows);

        $scope.seGroupCollection.setParams({ 'cloud_ref.uuid': cloudId });

        const cloud = new Cloud({ id: cloudId });

        $scope.cloud = cloud;
        $scope.dataNetworks.cloud = cloud;
        $scope.networksGridConfig.cloud = cloud;
        $scope.cloudDataLoading = true;

        cloud.load()
            .then(onCloudDataLoad)
            .finally(() => $scope.cloudDataLoading = false);

        vrfCollection.setParams({ 'cloud_ref.uuid': cloudId });
    };

    function onCloudDataLoad() {
        const cloudType = $scope.cloud.getVtype();

        if (cloudType === CLOUD_TYPE.CLOUD_LINUXSERVER) {
            $scope.networksGridConfig.fields[2].template = `
                <div class="pt10 pb10">
                    <div class="control-group">
                        <label class="flex-checkbox">
                            <checkbox
                                class="sel-dhcp"
                                ng-model="row.dhcp_enabled"
                            ></checkbox>
                            <span>IPv4 DHCP</span>
                        </label>
                    </div>
                    <div class="control-group">
                        <label class="flex-checkbox">
                            <checkbox ng-model="row.ip6_autocfg_enabled">
                            </checkbox>
                            <span>IPv6 DHCP</span>
                        </label>
                    </div>
                </div>`;
        }
    }

    const vrfColumn = {
        name: 'vrf_ref',
        title: 'VRF',
        template:
            `<collection-dropdown
                class="full-width"
                options="config.vrfCollection"
                search="true"
                allow-create="false"
                allow-edit="false"
                ng-model="row.vrf_ref">
            </collection-dropdown>`,
    };

    $scope.managementNetwork = {
        id: 'management-network-list',
        vrfCollection,
        fields: [{
            name: 'adapter',
            title: l10nService.getMessage(l10nKeys.columnTitleInterface),
            transform(row) {
                return row.adapter === 'Unknown' ? row.if_name : row.adapter;
            },
        }, {
            name: 'network_name',
            title: l10nService.getMessage(l10nKeys.columnTitleNetworkName),
        }, {
            name: 'mac_address',
            title: l10nService.getMessage(l10nKeys.columnTitleMacAddress),
        }, {
            name: 'dhcp_enabled',
            title: 'DHCP',
            template: '{{ row.dhcp_enabled  | booleanLabel: "enabled" }}',
        }, {
            title: l10nService.getMessage(l10nKeys.columnTitleIPAddress),
            name: 'ip_address',
            template:
                `<span
                    class="sel-ip-addr"
                    ng-repeat="nw in row.vnic_networks track by $index">
                    {{$index > 0 ? ', ' : ''}} {{ nw.ip | subnetString }}
                 </span>`,
        }, vrfColumn],
        rowId: ServiceEngine.getVnicId,
        layout: {
            hideDisplaying: true,
            hideSearch: true,
        },
        renderAll: true,
    };

    $scope.dataNetworks = {
        id: 'data-networks-list',
        vrfCollection,
        props: {
            l10nKeys,
        },
        fields: [{
            name: 'adapter',
            title: l10nService.getMessage(l10nKeys.columnTitleInterface),
            sortBy: 'adapter',
            transform(row) {
                return row.adapter === 'Unknown' ? row.if_name : row.adapter;
            },
        }, {
            name: 'network_name',
            title: l10nService.getMessage(l10nKeys.columnTitleNetworkName),
            sortBy: 'network_name',
            transform(row) {
                return row.network_name ? row.network_name : row.linux_name;
            },
        }, {
            name: 'mac_address',
            title: l10nService.getMessage(l10nKeys.columnTitleMacAddress),
            sortBy: 'mac_address',
        }, {
            name: 'dhcp_enabled',
            title: 'DHCP',
            template: `
                <div class="pt10 pb10">
                    <div class="control-group">
                        <label class="flex-checkbox">
                            <checkbox class="sel-dhcp" ng-model="row.dhcp_enabled"></checkbox>
                            <span>IPv4 DHCP</span>
                        </label>
                    </div>
                    <div class="control-group">
                        <label class="flex-checkbox">
                            <checkbox ng-model="row.ip6_autocfg_enabled"></checkbox>
                            <span>IPv6 DHCP</span>
                        </label>
                    </div>
                </div>`,
        }, {
            name: 'ip_address',
            title: l10nService.getMessage(l10nKeys.columnTitleIPAddress),
            template: require('./se-edit-data-networks-grid-ip.partial.html'),
        }, vrfColumn],
        rowId: ServiceEngine.getVnicId,
        searchFields: [
            'adapter',
            'network_name',
            'mac_address',
            'linux_name',
            'if_name',
        ],
        defaultSorting: 'adapter',
        renderAll: true, //since we don't know row height in advance
    };

    $scope.networksGridConfig = {
        id: 'se-configuration-networks-list',
        vrfCollection,
        props: {
            l10nKeys,
        },
        fields: [{
            name: 'if_name',
            title: l10nService.getMessage(l10nKeys.columnTitleInterface),
            sortBy: 'if_name',
        }, {
            name: 'mac_address',
            title: l10nService.getMessage(l10nKeys.columnTitleMacAddress),
            sortBy: 'mac_address',
        }, {
            name: 'dhcp_enabled',
            title: 'DHCP',
            template: '{{ row.dhcp_enabled | booleanLabel: "enabled" }}',
        }, {
            name: 'ip_address',
            title: l10nService.getMessage(l10nKeys.columnTitleIPAddress),
            template: require('./se-edit-data-networks-grid-ip.partial.html'),
        }, vrfColumn],
        rowId: ServiceEngine.getVnicId,
        searchFields: [
            'adapter',
            'network_name',
            'mac_address',
        ],
        renderAll: true,
    };

    $scope.vlanInterfaceGridConfig = {
        id: 'vlan-interface-list',
        fields: [{
            name: 'if_name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
        }, {
            name: 'parent',
            title: l10nService.getMessage(l10nKeys.columnTitleParentInterface),
            transform(row) {
                const index = row.if_name.lastIndexOf(`.${row.vlan_id}`);

                return index > -1 ? row.if_name.slice(0, index) : row.if_name;
            },
        }, {
            name: 'vlan_id',
            title: 'VLAN',
        }, {
            name: 'ip_address',
            title: l10nService.getMessage(l10nKeys.columnTitleIPAddress),
            transform: parseVnicIps,
        }, {
            name: 'vrf_ref',
            title: 'VRF',
            template: '{{row.vrf_ref | name}}',
        }],
        singleactions: [{
            title: l10nService.getMessage(l10nKeys.editBtnLabel),
            class: 'icon-pencil-4',
            do: editVlan,
        }, {
            title: l10nService.getMessage(l10nKeys.deleteBtnLabel),
            class: 'icon-trash',
            do: removeVlan,
        }],
        layout: {
            hideDisplaying: true,
        },
        rowId: ServiceEngine.getVlanId,
        searchFields: [
            'vlan_id',
            'if_name',
        ],
        renderAll: true,
    };

    /**
     * Parses vnic_networks of VLAN interfaces for IP addresses to display in the grid.
     * @param  {Object} row - VLAN object.
     * @return {string} Parsed string of IP addresses.
     */
    function parseVnicIps(row) {
        if (Array.isArray(row.vnic_networks)) {
            const ret = [];

            row.vnic_networks.forEach(network => {
                if (network && network.ip) {
                    ret.push(getSubnetString(network.ip));
                }
            });

            return ret.join(', ');
        }

        return '';
    }

    /**
     * Goes through VNIC networks to get a list of all VLAN interfaces.
     * @param  {Object[]} networks - VNIC network object.
     * @return {Object[]} Array of VLAN interface objects.
     */
    function getVlanInterfaceRows(networks) {
        const vlanList = [];

        networks.forEach(net => {
            if (Array.isArray(net.vlan_interfaces)) {
                vlanList.push(...net.vlan_interfaces);
            }
        });

        return vlanList;
    }

    /**
     * Opens Create VLAN modal.
     */
    $scope.createVlan = function() {
        AviModal.open('infra-se-edit-vlan-create', {
            vlan: new Vlan($scope.vnicRows, null, $scope.cloud),
            closeVlan,
        });
    };

    /**
     * Opens Create VLAN modal for editing.
     */
    function editVlan(vlanRow) {
        AviModal.open('infra-se-edit-vlan-create', {
            vlan: new Vlan($scope.vnicRows, vlanRow, $scope.cloud),
            closeVlan,
        });
    }

    /**
     * Removes VLAN from VNIC network.
     * @param  {Object} vlanRow - VLAN object.
     */
    function removeVlan(vlanRow) {
        const vlan = new Vlan($scope.vnicRows, vlanRow, $scope.cloud);

        vlan.removeVlan();
        $scope.vlanInterfaceRows = getVlanInterfaceRows($scope.vnicRows);
    }

    /**
     * Closes VLAN modal and resets vlanInterfaceRows to update changes.
     */
    function closeVlan() {
        AviModal.destroy('infra-se-edit-vlan-create');
        $scope.vlanInterfaceRows = getVlanInterfaceRows($scope.vnicRows);
    }

    $scope.$on('$destroy', () => {
        $scope.seGroupCollection.destroy();
        $scope.cloud.destroy();
        vrfCollection.destroy();
    });
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'ipam';
const componentName = 'custom-ipam-dns-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const scriptInputLabel = `${prefix}.scriptInputLabel`;
export const addScriptParamsLabel = `${prefix}.addScriptParamsLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Custom IPAM/DNS Profile: {0}',
    [modalTitleNew]: 'New Custom IPAM/DNS Profile: {0}',
    [nameInputLabel]: 'Name',
    [scriptInputLabel]: 'Script',
    [addScriptParamsLabel]: 'Add Script Params',
};

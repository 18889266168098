const moduleName = 'ajs-legacy';
const componentName = 'vs-traffic-capture-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleDate = `${prefix}.columnTitleDate`;
export const columnTitleVirtualServiceName = `${prefix}.columnTitleVirtualServiceName`;
export const columnTitleSize = `${prefix}.columnTitleSize`;
export const actionBtnDelete = `${prefix}.actionBtnDelete`;
export const actionBtnDownload = `${prefix}.actionBtnDownload`;
export const captureConfigurationHeader = `${prefix}.captureConfigurationHeader`;
export const stopTrafficCaptureButtonLabel = `${prefix}.stopTrafficCaptureButtonLabel`;
export const captureStoppedDataLabel = `${prefix}.captureStoppedDataLabel`;
export const captureRunningDataLabel = `${prefix}.captureRunningDataLabel`;
export const captureFilePreparingDataLabel = `${prefix}.captureFilePreparingDataLabel`;
export const completedCapturesHeader = `${prefix}.completedCapturesHeader`;

export const ENGLISH = {
    [columnTitleDate]: 'Date',
    [columnTitleVirtualServiceName]: 'Virtual Service Name',
    [columnTitleSize]: 'Size',
    [actionBtnDelete]: 'Delete',
    [actionBtnDownload]: 'Download',
    [captureConfigurationHeader]: 'Capture Configuration',
    [stopTrafficCaptureButtonLabel]: 'Click to stop traffic capture.',
    [captureStoppedDataLabel]: 'Capture is Stopped',
    [captureRunningDataLabel]: 'Capture is Running',
    [captureFilePreparingDataLabel]: 'Capture file is being prepared',
    [completedCapturesHeader]: 'Completed Captures',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name  clientIpMatch
 * @module match/clientIpMatch
 * @description Component for configuring a ClientIpMatch Config Item.
 * @param {ClientIpMatchConfigItem} clientIpMatch
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @author alextsg
 */
angular.module('avi/policies').component('clientIpMatch', {
    bindings: {
        clientIpMatch: '<',
        preventEdit: '<',
    },
    templateUrl: 'src/components/forms/match-config/matches/' +
        'client-ip-match/client-ip-match.component.html',
});

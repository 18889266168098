/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc constant
 * @type {Function}
 * @name orderByEnumText
 * @returns {Object[]}
 * @description
 *
 *     Orders object's keys by it's object[key].options.text.value values.
 *     When text is not set uses keys instead.
 */
angular.module('aviApp').factory('orderByEnumText', ['naturalSort',
    function(naturalSort) {
        function orderByEnumText(enumDict) {
            return Object.keys(enumDict)
                .sort((a, b) => {
                    const
                        aTextObj = enumDict[a]['options']['text'],
                        bTextObj = enumDict[b]['options']['text'],
                        aText = aTextObj ? aTextObj['value'] : a,
                        bText = bTextObj ? bTextObj['value'] : b;

                    return naturalSort(aText, bText);
                })
                .map(key => ({
                    key,
                    value: enumDict[key],
                }));
        }

        return orderByEnumText;
    },
]);

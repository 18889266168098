/**
 * @module IpamModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { CustomIpamDnsProfile } from 'ajs/modules/ipam';

import * as l10n from './custom-ipam-dns-profile-modal.l10n';

const { ENGLISH, ...l10nKeys } = l10n;

/**
 * @description Custom IPAM/DNS Profile full- modal component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'custom-ipam-dns-profile-modal',
    templateUrl: './custom-ipam-dns-profile-modal.component.html',
})
export class CustomIpamDnsProfileModalComponent {
    /**
     * CustomIpamDnsProfile instance.
     */
    @Input()
    public editable: CustomIpamDnsProfile;

    /**
     * CustomIpamDnsProfile object type.
     */
    public readonly objectType = 'CustomIpamDnsProfile';

    /**
     * Path of uploaded scripts.
     */
    public readonly filePath = 'controller://ipamdnsscripts';

    /**
     * API to upload scripts.
     */
    public readonly fileApi = '/api/fileservice/ipamdnsscripts';

    /**
     * Layout for ICAP modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(ENGLISH);
    }
}

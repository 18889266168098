/**
 * @module ServiceEngineGroup
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { L10nService } from '@vmw/ngx-vip';
import { pluck } from 'underscore';
import { IAviDropdownOption } from 'ng/shared/components';
import { createDropdownOption } from 'ng/utils/dropdown.utils';
import { StringService } from 'ajs/modules/core';
import { SEGroup } from '../../factories/se-group.item';
import template from './segroup-nsxt-placement-scope-config-container.component.html';
import * as l10n from './segroup-nsxt-placement-scope-config-container.l10n';
import './segroup-nsxt-placement-scope-config-container.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class SegroupNsxtPlacementScopeConfigContainerController {
    /**
     * SEGroup Item. Component binding.
     */
    public segroup: SEGroup;

    /**
     * List of vcenterserver refs for the user to select. Component binding.
     */
    public vcenterServerRefs: string[];

    /**
     * List of selected vcenterserver refs that should be hidden from selection.
     */
    public selectedVcenterServerRefs: string[];

    /**
     * List of vcenterserver dropdown options, created from this.vcenterServerRefs.
     */
    public vcenterServerDropdownOptions: IAviDropdownOption[];

    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly stringService: StringService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onInit(): void {
        this.vcenterServerDropdownOptions = this.createVcenterServersDropdownOptions();
        this.selectedVcenterServerRefs = this.getSelectedVcenterServerRefs();
    }

    /**
     * Called to add a new vcenterserver PlacementScopeConfig.
     */
    public handleAddVcenterPlacementScopeConfig(): void {
        this.segroup.addVcenterPlacementScopeConfig();
    }

    /**
     * Called when a vcenterserver has been selected from the dropdown.
     */
    public handleSelectVcenterServer(): void {
        this.selectedVcenterServerRefs = this.getSelectedVcenterServerRefs();
    }

    /**
     * Called to remove a vcenterserver PlacementScopeConfig.
     * @param index - Index to remove.
     */
    public handleRemoveVcenterPlacementScopeConfig(index: number): void {
        this.segroup.removeVcenterPlacementScopeConfig(index);
        this.selectedVcenterServerRefs = this.getSelectedVcenterServerRefs();
    }

    /**
     * Returns true to show the Add vCenter button.
     */
    public showAddVcenterButton(): boolean {
        const { vcenters } = this.segroup;

        return !vcenters || vcenters.length < this.vcenterServerDropdownOptions.length;
    }

    /**
     * Returns a list of vcenterserver dropdown options created from this.vcenterServerRefs.
     */
    private createVcenterServersDropdownOptions(): IAviDropdownOption[] {
        return this.vcenterServerRefs.map(serverRef => {
            return createDropdownOption(serverRef, this.stringService.name(serverRef));
        });
    }

    /**
     * Returns a list of configured vcenterserver refs.
     */
    private getSelectedVcenterServerRefs(): string[] {
        return pluck(this.segroup.vcenters, 'vcenter_ref');
    }
}

SegroupNsxtPlacementScopeConfigContainerController.$inject = [
    'stringService',
    'l10nService',
];

/**
 * @name segroupNsxtPlacementScopeConfigContainer
 * @memberOf module:avi/service-engine-group
 * @property {module:avi/service-engine-group.SegroupNsxtPlacementScopeConfigContainerController}
 *     controller
 * @property {module:avi/service-engine-group.segroupNsxtPlacementScopeConfigContainerBindings}
 *     bindings
 * @description Component for configuring a list of VCenter Placement Scope configs in the SEGroup
 *     modal.
 * @author alextsg
 */
export const segroupNsxtPlacementScopeConfigContainerOptions = {
    /**
     * @mixin segroupNsxtPlacementScopeConfigContainerBindings
     * @memberOf module:avi/service-engine-group
     * @property {SEGroup} segroup - SEGroup item.
     * @property {string[]} vcenterServerRefs - List of vCenter Server refs.
     */
    bindings: {
        segroup: '<',
        vcenterServerRefs: '<',
    },
    controller: SegroupNsxtPlacementScopeConfigContainerController,
    template,
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const authenticationPolicyConfigItemFactory = MessageItem => {
    /**
     * @constructor
     * @memberOf module:avi/policies/sso
     * @extends module:avi/dataModel.MessageItem
     * @desc AuthenticationPolicy MessageItem class.
     * @author Aravindh Nagarajan
     */
    class AuthenticationPolicyConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'AuthenticationPolicy',
                ...args,
            };

            super(extendedArgs);
        }

        /**
         * Returns a new AuthenticationRule ConfigItem instance.
         * @param {Object} [config=null] - Config object.
         * @return {module:avi/policies/sso.AuthenticationRuleConfigItem}
         */
        createNewRule(config = null) {
            return this.createChildByField_('authn_rules', config, true);
        }

        /**
         * Adds a rule to the list of rules. If the rule has an index, it means that an existing
         * rule was modified so the existing rule should be replaced. If not, add the new rule to
         * the end of the list.
         * @param {module:avi/policies/sso.AuthenticationRuleConfigItem} rule - Rule to add.
         */
        addRule(rule) {
            const { authn_rules: rules } = this.config;
            const indexField = rule.getIndex();

            // New rule doesn't have an index.
            if (_.isUndefined(indexField)) {
                const maxIndex = rules.getMaxIndex();
                const newIndex = _.isNaN(maxIndex) ? 0 : maxIndex + 1;

                rule.setIndex(newIndex);
                rules.add(rule);
            } else {
                const arrayIndex = rules.getArrayIndexWithIndexField(indexField);

                rules.config[arrayIndex] = rule;
            }
        }

        /**
         * Getter for the total number of rules configured in a authentication policy.
         * @return {number}
         */
        get totalRuleCount() {
            const { authn_rules: rules } = this.config;

            return rules.count;
        }

        /**
         * Returns true if an authentication policy has rules.
         * @return {boolean}
         */
        hasRules() {
            return !this.config.authn_rules.isEmpty();
        }

        /**
         * Clears default Auth profile Ref.
         */
        clearDefaultAuthProfile() {
            delete this.config.default_auth_profile_ref;
        }
    }

    return AuthenticationPolicyConfigItem;
};

authenticationPolicyConfigItemFactory.$inject = [
    'MessageItem',
];

angular
    .module('avi/policies/sso')
    .factory('AuthenticationPolicyConfigItem', authenticationPolicyConfigItemFactory);

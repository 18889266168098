/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './ssl-tls-profile-version-type-selector.l10n';

const componentName = 'ssl-tls-profile-version-type-selector';
const componentPath = 'src/components/modals/templates/security/ssl-tls-profile-modal/';
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class SslProfileVersionController {
    constructor(schemaService, l10nService) {
        /**
         * sslVersionTypeEnums Enumvalues defined in Schema.
         * @type {EnumValue[]}
         */
        this.sslVersionTypeEnums = schemaService.getEnumValues('SSLVersionType');

        /**
         * sslVersionTypesHash Enumvalues defined in Schema.
         * @type {Object.<string, Object>}
         */
        this.sslVersionTypesHash = {};

        const { sslVersionTypesHash, sslVersionTypeEnums } = this;

        sslVersionTypeEnums.forEach(({ value, label }) => {
            sslVersionTypesHash[value] = {
                className: value.enumeration('SSL_VERSION_'),
                enabled: false,
                hidden: false,
                label,
            };
        });

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    $onInit() {
        const {
            sslVersionTypesHash,
            enabledVersionTypes,
        } = this;
        const enabledVersionsHash = _.invert(enabledVersionTypes);

        _.each(sslVersionTypesHash, (versionData, version) => {
            versionData.enabled = version in enabledVersionsHash;
        });
    }

    /**
     * @override
     * @param {Object} changes
     */
    $onChanges(changes) {
        if (changes.supportedVersionTypes) {
            const { currentValue, previousValue } = changes.supportedVersionTypes;
            const supportedVersionsUpdated = !changes.supportedVersionTypes.isFirstChange() &&
                !_.isEqual(previousValue, currentValue);

            if (supportedVersionsUpdated) {
                this.supportedVersionTypes = currentValue;
                this.updateVersionTypesState_();
            }
        }
    }

    /**
     * Maps each key in sslVersionTypesHash with the current supported version list
     * and updates it's current state.
     * @protected
     */
    updateVersionTypesState_() {
        const {
            sslVersionTypesHash,
            supportedVersionTypes,
        } = this;
        const supportedVersionHash = _.invert(supportedVersionTypes);

        _.each(sslVersionTypesHash, (versionData, version) => {
            versionData.hidden = !(version in supportedVersionHash);
            versionData.enabled = false;
        });

        this.onVersionChange();
    }

    /**
     * Triggers the provided callback method.
     * It's called when versions are getting toggled and the list gets updated by parent component.
     */
    onVersionChange() {
        const { sslVersionTypesHash } = this;
        const sslVersionKeys = Object.keys(sslVersionTypesHash);

        const updatedVersions = sslVersionKeys.filter(version =>
            sslVersionTypesHash[version].enabled);

        this.onChange({ updatedVersions });
    }
}

SslProfileVersionController.$inject = [
    'schemaService',
    'l10nService',
];

/**
 * @ngdoc component
 * @name sslTlsProfileVersionTypeSelector
 * @param {string[]} enabledVersionTypes - list of SSLProfile types which are to be enabled.
 *      This is being used only during $onInit and not watched for further changes.
 * @param {string[]} supportedVersionTypes - list of SSLProfile types currently supported.
 * @param {Function} onChange - callback to be called to update
 *     current SSLProfile's accepted_versions property.
 *     It accepts the version list which in turn updates the relevant config accepted_versions.
 * @author Chitra
 * @desc Modal component for selecting/unselecting SSL Profile accepted versions.
 */
angular.module('aviApp').component('sslTlsProfileVersionTypeSelector', {
    bindings: {
        supportedVersionTypes: '<',
        enabledVersionTypes: '<',
        onChange: '&',
    },
    controller: SslProfileVersionController,
    templateUrl: `${componentPath}/${componentName}/${componentName}.html`,
});

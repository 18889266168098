/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @fileoverview Constants for DNS ip types.
 */

export const IP_TYPE_V4 = 'IPV4';
export const IP_TYPE_V6 = 'IPV6';
export const IP_TYPE_ANY = 'ANYIP';

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const ipamDnsInternalProfileConfigItemFactory = (MessageItem, defaultValues) => {
    /**
     * @class
     * @extends module:avi/dataModel.MessageItem
     * @memberOf module:avi/ipam
     * @description IpamDnsInternalProfile ConfigItem class.
     * @author alextsg
     */
    class IpamDnsInternalProfileConfigItem extends MessageItem {
        /**
         * @constructor
         */
        constructor(args) {
            const extendedArgs = {
                objectType: 'IpamDnsInternalProfile',
                ...args,
            };

            super(extendedArgs);
        }

        /**
         * We want to add a single DnsServiceDomain by default.
         * @function
         * @override
         */
        get defaultConfigOverride_() { //eslint-disable-line class-methods-use-this
            const type = this.objectType.toLowerCase();
            const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};
            const { defaultDnsServiceDomainConfig } = this;

            return {
                ...defaultConfig,
                dns_service_domain: [{ ...defaultDnsServiceDomainConfig }],
            };
        }

        /**
         * Returns the default DnsServiceDomain config object.
         * @function
         * @returns {Object}
         */
        get defaultDnsServiceDomainConfig() {
            const messageMapProps = this.messageMap['dns_service_domain'];

            if (!messageMapProps) {
                return {};
            }

            const { objectType } = messageMapProps;
            const type = objectType.toLowerCase();
            const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};

            return defaultConfig;
        }

        /**
         * Returns the DnsServiceDomain repeated config item.
         * @function
         * @returns {module:avi/dataModel.RepeatedMessageItem}
         */
        get dnsServiceDomains() {
            return this.config.dns_service_domain;
        }

        /**
         * Adds a DnsServiceDomain config item.
         */
        addDnsRecord() {
            this.config.dns_service_domain.add();
        }

        /**
         * Getter for usable network config.
         */
        get usableNetworksConfig() {
            return this.config.usable_networks.config || [];
        }

        /**
         * Removes a DnsServiceDomain config item.
         * @param {number=} index - Index of the DnsServiceDomain to remove.
         */
        removeDnsRecord(index = 0) {
            this.config.dns_service_domain.remove(index);
        }

        /**
         * Adds an empty usable network.
         */
        addUsableNetwork() {
            this.config.usable_networks.add();
        }

        /**
         * Removes Network ref from network list.
         * @param {number=} index
         */
        removeUsableNetwork(index = 0) {
            this.config.usable_networks.remove(index);
        }
    }

    return IpamDnsInternalProfileConfigItem;
};

ipamDnsInternalProfileConfigItemFactory.$inject = [
    'MessageItem',
    'defaultValues',
];

angular
    .module('avi/ipam')
    .factory('IpamDnsInternalProfileConfigItem', ipamDnsInternalProfileConfigItemFactory);

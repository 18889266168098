/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const natPolicyCollectionFactory = (Collection, NatPolicy) => {
    /**
     * @constructor
     * @memberOf module:avi/policies/nat
     * @extends module:avi/dataModel.Collection
     * @desc NAT policy Collection.
     * @author Aravindh Nagarajan
     */
    class NatPolicyCollection extends Collection {}

    angular.extend(NatPolicyCollection.prototype, {
        objectName_: 'natpolicy',
        itemClass_: NatPolicy,
        windowElement_: 'nat-policy-modal',
    });

    return NatPolicyCollection;
};

natPolicyCollectionFactory.$inject = [
    'Collection',
    'NatPolicy',
];

angular.module('avi/policies/nat').factory('NatPolicyCollection', natPolicyCollectionFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'scripts';
const componentName = 'rate-limiters';
const prefix = `${moduleName}.${componentName}`;

export const nameInputLabel = `${prefix}.nameInputLabel`;
export const countInputLabel = `${prefix}.countInputLabel`;
export const burstSizeInputLabel = `${prefix}.burstSizeInputLabel`;
export const periodInputLabel = `${prefix}.periodInputLabel`;
export const addBtnLabel = `${prefix}.addBtnLabel`;
export const maxInnerText = `${prefix}.maxInnerText`;
export const secInnerText = `${prefix}.secInnerText`;

export const ENGLISH = {
    [nameInputLabel]: 'Name',
    [countInputLabel]: 'Count',
    [burstSizeInputLabel]: 'Burst Size',
    [periodInputLabel]: 'Period',
    [addBtnLabel]: '+ Add',
    [maxInnerText]: 'max',
    [secInnerText]: 'sec',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function openstackNetworkFactory(
    Item,
) {
    class OpenstackNetwork extends Item {
        /**
         * @override
         */
        isEditable() {
            return false;
        }
    }

    OpenstackNetwork.prototype.objectName = 'openstack-get-tenant-networks';

    return OpenstackNetwork;
}

openstackNetworkFactory.$inject = [
    'Item',
];

/**
 * @ngdoc service
 * @name OpenstackNetwork
 * @author Chitra
 * @description OpenstackNetwork item.
 */
angular.module('aviApp')
    .factory('OpenstackNetwork', openstackNetworkFactory);

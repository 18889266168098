/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './vs-waf-page.less';

/**
 * @ngdoc component
 * @name vsWafPage
 * @params {VirtualService} vs - Loaded VS instance, provided by UI Router.
 * @description
 *
 *     VS WAF Analytics: two charts and list of top enforcements.
 */
const componentName = 'vs-waf-page';

class VSWafController {
    constructor(
        $rootScope,
        $element,
        myAccount,
    ) {
        this.$rootScope_ = $rootScope;
        this.$elem_ = $element;
        this.myAccount = myAccount;

        this.wafEnforcedSeriesName = 'l7_client.avg_waf_attacks';
        this.wafEnforcedPctSeriesName = 'l7_client.pct_waf_attacks';

        this.chartMetricConfigs_ = [{
            name: this.wafEnforcedSeriesName,
            series: [
                this.wafEnforcedSeriesName,
                this.wafEnforcedPctSeriesName,
            ],
        }];

        this.chartMetricConfigs_
            .forEach(metricConfig => metricConfig.subscriber = componentName);

        this.chartTitle = 'WAF Hits';
        this.chartPadding = {
            left: 25,
            right: 30,
        };
    }

    $onInit() {
        this.$elem_.addClass(componentName);

        const { vs } = this;

        vs.collMetricsSubscribe(this.chartMetricConfigs_);

        vs.startCollMetricsAsync();

        setTimeout(
            () => this.$rootScope_.$broadcast('$repaintViewport'),
        );
    }

    $onDestroy() {
        this.vs.collMetricsUnSubscribe(componentName);
    }
}

VSWafController.$inject = [
    '$rootScope',
    '$element',
    'myAccount',
];

angular.module('avi/vs').component('vsWafPage', {
    bindings: {
        vs: '<resolveVS',
    },
    controller: VSWafController,
    templateUrl: 'src/components/pages/application/vs/vs-waf-page/vs-waf-page.html',
});

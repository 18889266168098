/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import cssobj from 'cssobj';

/**
 * @ngdoc service
 * @name cssobj
 * @description
 *
 *     Wrapper on top of https://github.com/cssobj/cssobj.
 */
angular.module('avi/deps').factory('cssobj', () => cssobj);

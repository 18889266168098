const moduleName = 'update';
const componentName = 'update-node-status-card';
const prefix = `${moduleName}.${componentName}`;

export const updateOperationLabel = `${prefix}.updateOperationLabel`;
export const updateFromVersionLabel = `${prefix}.updateFromVersionLabel`;
export const updateOperationStartTimeLabel = `${prefix}.updateOperationStartTimeLabel`;

export const ENGLISH = {
    [updateOperationLabel]: 'Operation',
    [updateFromVersionLabel]: 'From',
    [updateOperationStartTimeLabel]: 'Started',
};

/**
 * @module SystemModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { SMTPType } from 'generated-types';
import { IAviDropdownOption } from 'ng/shared/components';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { SchemaService } from 'ajs/modules/core';
import { EmailConfigurationConfigItem } from 'ajs/modules/system';
import * as l10n from './email-smtp-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the Webhook modal.
 * @author alextsg
 */
@Component({
    selector: 'email-smtp-modal',
    templateUrl: './email-smtp-modal.component.html',
})
export class EmailSmtpModalComponent {
    /**
     * EmailConfigurationConfigItem instance.
     */
    @Input()
    public editable: EmailConfigurationConfigItem;

    /**
     * Called when the user wants to save the modal.
     */
    @Output()
    public onSubmit = new EventEmitter();

    /**
     * Called when the user wants to cancel editing the modal.
     */
    @Output()
    public onCancel = new EventEmitter();

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * List of SMTP type dropdown options.
     */
    public smtpTypeOptions: IAviDropdownOption[] = [];

    public readonly SMTPType = {
        [SMTPType.SMTP_ANONYMOUS_SERVER]: SMTPType.SMTP_ANONYMOUS_SERVER,
        [SMTPType.SMTP_LOCAL_HOST]: SMTPType.SMTP_LOCAL_HOST,
        [SMTPType.SMTP_NONE]: SMTPType.SMTP_NONE,
        [SMTPType.SMTP_SERVER]: SMTPType.SMTP_SERVER,
    };

    constructor(
        l10nService: L10nService,
        schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const smtpTypes = schemaService.getEnumValues('SMTPType');

        this.smtpTypeOptions = createOptionsFromEnumProps(smtpTypes);
    }

    /**
     * Returns true if the configured type matches a specific type.
     */
    public isSmtpType(type: SMTPType): boolean {
        return this.editable.smtpType === type;
    }

    /**
     * Handler for saving the modal.
     */
    public handleSubmit(): void {
        this.onSubmit.emit();
    }

    /**
     * Handler to cancel editing the modal.
     */
    public handleCancel(): void {
        this.onCancel.emit();
    }
}

/**
 * @module WelcomeModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    Inject,
    Injectable,
    Type,
} from '@angular/core';

import {
    FullModalService,
    IFullModalLayout,
} from 'ng/modules/core';

import {
    BackupConfiguration,
    BackupConfigurationCollection,
    SystemConfig,
} from 'ajs/modules/system';

import {
    DefaultValues,
    StringService,
} from 'ajs/modules/core';

import {
    Observable,
    Subject,
} from 'rxjs';

import { StateService } from '@uirouter/core';
import { Cloud } from 'ajs/js/services/items/Cloud';
import { appStates } from 'ajs/js/constants/app-config/app-state.constants';
import { L10nService } from '@vmw/ngx-vip';
import { WelcomeModalComponent } from '../components';
import * as l10n from './welcome.service.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCloud = typeof Cloud;
type TSystemConfig = typeof SystemConfig;
type TBackupConfigurationCollection = typeof BackupConfigurationCollection;

const { DEFAULT_STATE } = appStates;

/**
 * Service to setup welcome modal system/backup configuration and open/close the modal.
 *
 * @author Aravindh Nagarajan
 */
@Injectable()
export class WelcomeService {
    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * SystemConfig Item instance.
     */
    public systemConfig: SystemConfig = null;

    /**
     * BackupConfiguration Item instance.
     */
    public backupConfig: BackupConfiguration = null;

    /**
     * BackupConfiguration collection instance.
     * Used to load default backupConfig.
     */
    private backupConfigCollection: BackupConfigurationCollection = null;

    /**
     * Subject to control welcome modal close.
     */
    private readonly welcomeCompleteSubject = new Subject<boolean>();

    constructor(
        private readonly fullModalService: FullModalService,
        private readonly defaultValues: DefaultValues,
        private readonly stringService: StringService,
        private readonly $state: StateService,
        @Inject(SystemConfig)
        private readonly SystemConfig: TSystemConfig,
        @Inject(BackupConfigurationCollection)
        private readonly BackupConfigurationCollection: TBackupConfigurationCollection,
        @Inject(Cloud)
        private readonly Cloud: TCloud,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns an observable to allow subscriptions to welcome modal close.
     */
    public get welcomeCompleteSubject$(): Observable<boolean> {
        return this.welcomeCompleteSubject.asObservable();
    }

    /**
     * Method to open welcome modal.
     */
    public async openWelcomeModal(): Promise<void> {
        await this.getDataModel();

        this.fullModalService.addModal(this.getWelcomeModalProps());
    }

    /**
     * Method to close welcome modal.
     */
    public closeModal(): void {
        this.fullModalService.removeModalByComponent(WelcomeModalComponent as Type<Component>);
        this.reset();
    }

    /**
     * Returns true if welcome modal is still open.
     */
    public isWelcomeModalOpen(): boolean {
        return this.fullModalService.isOpen(WelcomeModalComponent as Type<Component>);
    }

    /**
     * Navigates to DEFAULT_STATE.
     */
    public navigateToAppDefaultState(): void {
        this.$state.go(DEFAULT_STATE);
    }

    /**
     * Navigates to cloud-list page and open default cloud edit modal.
     */
    public async editDefaultCloud(): Promise<void> {
        await this.$state.go('authenticated.infrastructure.cloud-list');

        const defaultCloudRef = this.defaultValues.getSystemObjectRefByName(
            'cloud',
            'Default-Cloud',
        );

        const defaultCloudUuid = this.stringService.slug(defaultCloudRef);

        const defaultCloud = new this.Cloud({
            id: defaultCloudUuid,
            loadOnEdit: false,
        });

        await defaultCloud.load();

        return defaultCloud.edit(undefined, {
            fromWelcomeModal: true,
        });
    }

    /**
     * Initialize and load systemConfig instance.
     */
    private async initSystemConfig(): Promise<void> {
        const systemConfig = new this.SystemConfig();

        await systemConfig.load();

        this.systemConfig = systemConfig;
    }

    /**
     * Prepares welcome modal data.
     */
    private async getDataModel(): Promise<void> {
        await Promise.all([
            this.initSystemConfig(),
            this.initBackupConfig(),
        ]);
    }

    /**
     * Init backup configurations.
     */
    private async initBackupConfig(): Promise<void> {
        // TODO: AV-88821 > uncomment the code below once AV-85756 is fixed
        // get the id of the initial backup configuration object
        // const initialBackupConfigId = defaultValues.getSystemObjectRefByName(
        //     'backupconfiguration',
        //     // this is the default name of the initial backup configuration object
        //     'Backup-Configuration',
        // ).slug();

        // backupConfig = new BackupConfiguration({ id: initialBackupConfigId });

        // TODO: AV-88821 > delete once AV-85756 is fixed
        this.backupConfigCollection = new this.BackupConfigurationCollection({
            limit: 1,
            isStatic: true,
        });

        // TODO: AV-88821 > delete once AV-85756 is fixed
        await this.backupConfigCollection.load();

        this.backupConfig =
            this.backupConfigCollection.itemList[0] as any as BackupConfiguration;
    }

    /**
     * Returns props to be passed to the FullModal.
     */
    private getWelcomeModalProps(): IFullModalLayout {
        const { l10nService } = this;

        return {
            component: WelcomeModalComponent as Type<Component>,
            componentProps: {
                systemConfig: this.systemConfig,
                backupConfig: this.backupConfig,
                onWelcomeModalClose: this.onWelcomeModalClose,
                onWelcomeModalDismiss: this.onWelcomeModalDismiss,
            },
            getName: () => l10nService.getMessage(l10nKeys.welcomeLabel),
            getDescription: () => '',
        };
    }

    /**
     * Callback for welcome modal close.
     *
     * @param openCloudModal True, if user wants to open cloud modal after completion.
     */
    private onWelcomeModalClose = (openCloudModal: boolean): void => {
        this.welcomeCompleteSubject.next(openCloudModal);
        this.welcomeCompleteSubject.complete();
    };

    /**
     * Callback for welcome modal dismiss.
     *
     * Just closes the modal.
     * Will not complete welcomeCompleteSubject.
     */
    private onWelcomeModalDismiss = (): void => {
        this.welcomeCompleteSubject.next();
    };

    /**
     * Destroy and reset item and collection instances.
     */
    private reset(): void {
        this.backupConfig.destroy();
        this.systemConfig.destroy();
        this.backupConfigCollection.destroy();

        this.backupConfig = null;
        this.systemConfig = null;
        this.backupConfigCollection = null;
    }
}

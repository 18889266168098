/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').factory('eventSearchParser', function() {
    /**
     * Returns Logs API query filter based on pegjs parsed object.
     * @param {Object} parsed - pegjs parsed result.
     * @return {string} - Logs API query filter.
     */
    function getQueryFilter(parsed) {
        if (parsed) {
            const {
                op,
                field,
                wildcard,
                quoted,
                query,
                array,
            } = parsed;

            let quote = '';

            if (quoted === 'double' || quoted === 'single' || wildcard || array) {
                quote = '"';
            }

            return `${op}(${field},${quote}${query}${quote})`;
        }
    }

    /**
     * Parses search term to logs API filters.
     * @param {string} search - Search term.
     * @return {string[]} - Logs API filters.
     */
    function parse(search) {
        const ret = [];
        let parsed;

        try {
            parsed = avi.QueryParser.parse(`event_all: ${search}`);
        } catch (e) {
            search = JSON.stringify(search).slice(1, -1);
            parsed = avi.QueryParser.parse(`event_all: ${search}`);
        }

        if (Array.isArray(parsed)) {
            const ors = [];

            for (let i = 0; i < parsed.length; i++) {
                const val = parsed[i];

                if (val === 'OR') {
                    const q1 = getQueryFilter(parsed[i - 1]);
                    const q2 = getQueryFilter(parsed[i + 1]);

                    if (q1 && q2) {
                        if (!_.contains(ors, q1)) {
                            ors.push(q1);
                        }

                        if (!_.contains(ors, q2)) {
                            ors.push(q2);
                        }

                        const j = ret.indexOf(q1);

                        if (j > -1) {
                            ret.splice(j, 1);
                        }

                        const k = ret.indexOf(q2);

                        if (k > -1) {
                            ret.splice(k, 1);
                        }

                        i++;
                    }
                } else {
                    const filter = getQueryFilter(val);

                    if (filter) {
                        ret.push(filter);
                    }
                }
            }

            if (ors.length) {
                ret.push(`OR(${ors.join()})`);
            }
        }

        return ret;
    }

    return { parse };
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'tenant-settings-service';
const prefix = `${moduleName}.${componentName}`;

export const tenantReadAccessToSeMessage = `${prefix}.tenantReadAccessToSeMessage`;
export const tenantNoAccessToSeMessage = `${prefix}.tenantNoAccessToSeMessage`;
export const ipRoutesDomainPerTenantMessage = `${prefix}.ipRoutesDomainPerTenantMessage`;
export const ipRoutesDomainShareTenantMessage = `${prefix}.ipRoutesDomainShareTenantMessage`;
export const seProviderContextShareMessage = `${prefix}.seProviderContextShareMessage`;
export const seProviderContextNotShareMessage = `${prefix}.seProviderContextNotShareMessage`;
export const emptyDataLabel = `${prefix}.emptyDataLabel`;

export const ENGLISH = {
    [tenantReadAccessToSeMessage]: 'Tenant has Read Access to Service Engines',
    [tenantNoAccessToSeMessage]: 'Tenant has No Access to Service Engines',
    [ipRoutesDomainPerTenantMessage]: 'Per tenant IP route domain.',
    [ipRoutesDomainShareTenantMessage]: 'Share IP route domain across tenants.',
    [seProviderContextShareMessage]: 'Service Engines are managed within the provider context, shared across tenants.',
    [seProviderContextNotShareMessage]: 'Service Engines are managed within the tenant context, not shared across tenants.',
    [emptyDataLabel]: 'N/A',
};

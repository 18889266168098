/**
 * Module containing Cloud components.
 * @preferred
 * @module CloudModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
    ClrRadioModule,
    ClrTextareaModule,
} from '@clr/angular';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import {
    CloudModalComponent,
    DataNetworkConfigComponent,
    IpamDnsConfigurationComponent,
    LicenseSelectorDirective,
    ManagementNetworkConfigComponent,
    NsxtConfigurationComponent,
    NsxtEditCredentialsDialogComponent,
    VcenterServerEditCredentialsDialogComponent,
    VCenterServerModalComponent,
    VCenterServersListComponent,
} from '.';

const cloudComponents = [
    CloudModalComponent,
    DataNetworkConfigComponent,
    IpamDnsConfigurationComponent,
    ManagementNetworkConfigComponent,
    NsxtConfigurationComponent,
    NsxtEditCredentialsDialogComponent,
    VcenterServerEditCredentialsDialogComponent,
    VCenterServerModalComponent,
    VCenterServersListComponent,
];

const cloudDirectives = [
    LicenseSelectorDirective,
];

@NgModule({
    declarations: [
        ...cloudComponents,
        ...cloudDirectives,
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        ClrInputModule,
        ClrRadioModule,
        ClrTextareaModule,
        ClrFormsModule,
        ClrCheckboxModule,
        AviFormsModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class CloudModule {}

const moduleName = 'ajs-legacy';
const componentName = 'alert-action-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleAlertLevel = `${prefix}.columnTitleAlertLevel`;
export const columnTitleSyslog = `${prefix}.columnTitleSyslog`;
export const columnTitleEmail = `${prefix}.columnTitleEmail`;
export const columnTitleSNMPTrap = `${prefix}.columnTitleSNMPTrap`;
export const columnTitleControlScript = `${prefix}.columnTitleControlScript`;
export const columnTitleExternalOnly = `${prefix}.columnTitleExternalOnly`;
export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const generalInformationHeader = `${prefix}.generalInformationHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const alertLevelInputLabel = `${prefix}.alertLevelInputLabel`;
export const emailInputLabel = `${prefix}.emailInputLabel`;
export const createEmailNotificationLabel = `${prefix}.createEmailNotificationLabel`;
export const selectEmailNotificationPlaceholder = `${prefix}.selectEmailNotificationPlaceholder`;
export const syslogLabel = `${prefix}.syslogLabel`;
export const createSyslogNotificationLabel = `${prefix}.createSyslogNotificationLabel`;
export const selectSyslogNotificationPlaceholder = `${prefix}.selectSyslogNotificationPlaceholder`;
export const snmpTrapLabel = `${prefix}.snmpTrapLabel`;
export const createSNMPTrapNotificationLabel = `${prefix}.createSNMPTrapNotificationLabel`;
export const selectSNMPTrapNotificationPlaceholder = `${prefix}.selectSNMPTrapNotificationPlaceholder`;
export const controlScriptLabel = `${prefix}.controlScriptLabel`;
export const createControlScriptProfileLabel = `${prefix}.createControlScriptProfileLabel`;
export const selectControlScriptProfilePlaceholder = `${prefix}.selectControlScriptProfilePlaceholder`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;
export const noneValue = `${prefix}.noneValue`;
export const yesValue = `${prefix}.yesValue`;
export const noValue = `${prefix}.noValue`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleAlertLevel]: 'Alert Level',
    [columnTitleSyslog]: 'Syslog',
    [columnTitleEmail]: 'Email',
    [columnTitleSNMPTrap]: 'SNMP Trap',
    [columnTitleControlScript]: 'Control Script',
    [columnTitleExternalOnly]: 'External Only',
    [modalHeaderEdit]: 'Edit Alert Action: {0}',
    [modalHeaderNew]: 'New Alert Action: {0}',
    [generalInformationHeader]: 'General Information',
    [nameInputLabel]: 'Name',
    [alertLevelInputLabel]: 'Alert Level',
    [emailInputLabel]: 'Email',
    [createEmailNotificationLabel]: 'Create Email Notification',
    [selectEmailNotificationPlaceholder]: 'Select Email Notification',
    [syslogLabel]: 'Syslog',
    [createSyslogNotificationLabel]: 'Create Syslog Notification',
    [selectSyslogNotificationPlaceholder]: 'Select Syslog Notification',
    [snmpTrapLabel]: 'SNMP Trap',
    [createSNMPTrapNotificationLabel]: 'Create SNMP Trap Notification',
    [selectSNMPTrapNotificationPlaceholder]: 'Select SNMP Trap Notification',
    [controlScriptLabel]: 'ControlScript',
    [createControlScriptProfileLabel]: 'Create ControlScript Profile',
    [selectControlScriptProfilePlaceholder]: 'Select ControlScript Profile',
    [cancelButtonLabel]: 'Cancel',
    [saveButtonLabel]: 'Save',
    [noneValue]: '-None-',
    [yesValue]: 'Yes',
    [noValue]: 'No',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @description Alert Metric collection item.
 */
angular.module('aviApp').factory('AlertMetricsItem', ['Item',
function(Item) {
    return class AlertMetricsItem extends Item {
        constructor(args) {
            super(args);
            this.objectName = 'analytics/metric_id';
        }

        /** @override */
        isEditable() {
            return false;
        }
    };
}]);

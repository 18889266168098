/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('charts').factory('colors', [function() {
    /**
     * Colors cache for chart elements.
     */
    class Colors {
        /**
         * Creates CSS HSL color string.
         * @param {number} h
         * @param {number} s
         * @param {number} l
         * @return {string}
         */
        static hsl(h = 0, s = 0, l = 0) {
            return `hsl(${h}, ${s}%, ${l}%)`;
        }

        /**
         * Generates array of HSL colors.
         * @return {string[]}
         */
        static generateHslColors() {
            const hsl = [];

            for (let i = 0; i < 345; i += 7) {
                const mod = i % 2 === 0;

                hsl.push(Colors.hsl(i, mod ? 60 : 40, mod ? 60 : 40));
            }

            return hsl;
        }

        /**
         * Hashes string to an integer using djb2 algorithm.
         * @param {string} str
         * @return {number}
         */
        static djb2Hash(str) {
            let hash = 5381;
            let char;

            for (let i = 0; i < str.length; i++) {
                char = str.charCodeAt(i);
                hash = (hash << 5) + hash + char;
            }

            return Math.abs(hash);
        }

        constructor() {
            this.hslColors = Colors.generateHslColors();
            this.hslColorHash = {};
        }

        /**
         * Returns HSL color based on provided string.
         * @param {string} key
         * @return {?string}
         */
        getHslColor(key) {
            if (key in this.hslColorHash) {
                return this.hslColorHash[key];
            } else if (typeof key === 'string') {
                const cs = this.hslColors;
                const offset = 9;
                const i = (Colors.djb2Hash(key) + offset) % cs.length;

                return this.hslColorHash[key] = cs[i];
            }
        }
    }

    return new Colors();
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name DnsPolicyRuleAllowDropActionConfig
 * @description DnsPolicyRuleAllowDropActionConfig, maps to DnsRuleActionAllowDrop in protobof.
 */
const dnsPolicyRuleAllowDropActionConfigFactory = ActionConfigItem => {
    class DnsPolicyRuleAllowDropActionConfig extends ActionConfigItem {
        /** @override */
        toString() {
            let output = '';
            const { allow, reset_conn: resetConn } = this.getConfig();

            output += `${allow ? 'Allow ' : 'Drop '} Query`;

            if (resetConn) {
                output += '; Reset TCP Connection';
            }

            return output;
        }
    }

    angular.extend(DnsPolicyRuleAllowDropActionConfig.prototype, {
        type: 'DnsRuleActionAllowDrop',
        name: 'Allow/Drop Query',
        defaultPath: 'dnsrule.action.allow',
    });

    return DnsPolicyRuleAllowDropActionConfig;
};

dnsPolicyRuleAllowDropActionConfigFactory.$inject = [
        'ActionConfigItem',
];

angular.module('aviApp').factory(
    'DnsPolicyRuleAllowDropActionConfig', dnsPolicyRuleAllowDropActionConfigFactory,
);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @alias module:match/methodMatch
 * @private
 */
class MethodMatchComponent {
    constructor(dropDownUtils) {
        this.methods = dropDownUtils.getEnumDropdownOptions('HTTPMethod');
    }
}

MethodMatchComponent.$inject = [
    'dropDownUtils',
];

/**
 * @ngdoc component
 * @name  methodMatch
 * @module match/methodMatch
 * @description Component for configuring a MethodMatch Config Item.
 * @param {MethodMatchConfigItem} methodMatch
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @author alextsg
 */
angular.module('avi/policies').component('methodMatch', {
    bindings: {
        methodMatch: '<',
        preventEdit: '<',
    },
    controller: MethodMatchComponent,
    templateUrl: 'src/components/forms/match-config/matches/method-match/' +
        'method-match.component.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name  gslbNonAviModal
 * @description  Modal component for GSLB non-Avi site.
 * @param  {Gslb} editable
 * @param  {number=} siteIndex - Index of the site being modified.
 */
class GslbNonAviModalController {
    $onInit() {
        const config = this.editable.getConfig();

        if (!('url' in config) || !angular.isUndefined(this.siteIndex) &&
                !(this.siteIndex in config[this.editable.constructor.nonAviSitesConfigPropName])) {
            this.siteIndex = 0;
        }

        if (!angular.isUndefined(this.siteIndex)) {
            this.site = this.editable.getNonAviSiteByIndex(this.siteIndex);
        }
    }
}

angular.module('aviApp').component('gslbNonAviModal', {
    bindings: {
        editable: '<',
        siteIndex: '@',
    },
    controller: GslbNonAviModalController,
    templateUrl: 'src/components/infrastructure/gslb/gslb-non-avi-modal/' +
            'gslb-non-avi-modal.html',
});

/**
 * @module avi/core
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { isString, isUndefined } from 'underscore';
import { Component, Type } from '@angular/core';
import {
    FullModalService,
    FULL_MODAL_SERVICE_TOKEN,
    IFullModalLayout,
} from 'ng/modules/core';
import { AjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    Item,
    MessageItem,
    ObjectTypeItem,
    TWindowElement,
} from 'ajs/modules/data-model';
import { Constructor } from '../../../declarations/globals.d';

export interface IItemParams {
    editable: ObjectTypeItem | Item | MessageItem;
}

/**
 * @description
 *     Mixin to extend a BaseClass with methods for rendering a full modal component. The reason
 *     this is a mixin rather than a subclass is so we don't have to limit the superclass to
 *     being an ObjectTypeItem or an Item. Supports ES5 and ES6 classes only. Pure functions are not
 *     supported.
 * @author alextsg
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, require-jsdoc
export function withFullModalMixin<T extends Constructor<AjsDependency>>(BaseClass: T) {
    return class FullModalItem extends BaseClass {
        public static ajsDependencies = [
            FULL_MODAL_SERVICE_TOKEN,
        ];

        /**
         * Modal component to open.
         */
        protected readonly windowElement: TWindowElement;

        /**
         * ObjectType defined in the protobuf.
         */
        protected readonly objectType: string;

        constructor(...args: any[]) {
            super(...args);

            const [{ windowElement, objectType }] = args;

            this.windowElement = windowElement || this.windowElement;
            this.objectType = objectType || this.objectType;

            if (isUndefined(this.objectType)) {
                throw new Error('FullModal can\'t be used without objectType');
            }
        }

        /**
         * @override
         */
        public openModal(
            windowElement: TWindowElement = this.windowElement,
            params: IItemParams,
        ): void {
            if (isString(windowElement)) {
                throw new Error('Trying to use fullModalService with a string type windowElement');
            }

            const fullModalService: FullModalService =
                this.getAjsDependency_(FULL_MODAL_SERVICE_TOKEN);

            fullModalService.addModal(this.getFullModalProps(params, windowElement));
        }

        /**
         * @override
         */
        public closeModal(windowElement: TWindowElement = this.windowElement): void {
            if (isString(windowElement)) {
                throw new Error('Trying to use fullModalService with a string type windowElement');
            }

            const fullModalService: FullModalService =
                this.getAjsDependency_(FULL_MODAL_SERVICE_TOKEN);

            fullModalService.removeModalByComponent(windowElement);
        }

        /**
         * Returns props to be passed to the FullModal.
         * @param params - Params passed to Item.openModal.
         * @param modalComponent - Full modal component to be opened.
         */
        protected getFullModalProps(
            params: IItemParams,
            modalComponent?: Type<Component>,
        ): IFullModalLayout {
            return {
                component: modalComponent,
                componentProps: {
                    ...params,
                },
                getDescription: () => this.getModalBreadcrumbDescription(params),
                getName: () => this.getModalBreadcrumbTitle(params),
                icon: this.getModalBreadcrumbIcon(),
            };
        }

        /**
         * Returns the icon name to be displayed in the breadcrumb.
         */
        protected getModalBreadcrumbIcon(): string {
            return 'file';
        }

        /**
         * Returns the string to be displayed as the breadcrumb title.
         */
        protected getModalBreadcrumbTitle(params: IItemParams): string {
            return this.objectType;
        }

        /**
         * Returns the string to be displayed as the breadcrumb description.
         */
        protected getModalBreadcrumbDescription(params: IItemParams): string {
            return params.editable.getName();
        }
    };
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './missing-cert-issuer-icon.component.less';

/**
 * Html for popover config.
 */
const popoverHtml = `
    <avi-notification
        type="warning"
        class="missing-cert-issuer-icon-popup__avi-notification"
        no-close="true"
        >
        <div>
            <div class="missing-cert-issuer-icon-popup__avi-notification-header">
                <h3>Issuer Certificate Missing</h3>
            </div>
            <div>
                <span>
                    {{ $ctrl.certName }}
                </span>
            </div>
        </div>
    </avi-notification>`;

/**
 * @mixin missingCertIssuerIconBindings
 * @memberOf module:avi/app
 * @param {string} certName - name of the missing certificate.
 */

/**
 * @class
 * @constructor
 * @memberOf module:avi/app
 * @mixes module:avi/app.missingCertIssuerIconBindings
 * @see {@link module:avi/app.missingCertIssuerIconComponent}
 */
class MissingCertIssuerIconController {
    constructor(popoverFactory, $compile, $scope) {
        this.$scope_ = $scope;
        this.$compile_ = $compile;
        this.PopoverFactory_ = popoverFactory;

        /**
         * Instance of Popover factory to show tooltiop.
         * @type {popoverFactory|null}
         */
        this.popover_ = null;
    }

    /**
     * create popover instance for missing ca certs.
     * @return {PopoverFactory}
     * @protected
     */
    createPopoverInstance_() {
        const popoverConfig = {
            className: 'missing-cert-issuer-icon-popup',
            position: 'top',
            removeAfterHide: true,
            hide: {
                onEscape: true,
            },
            carat: true,
            html: this.$compile_(popoverHtml)(this.$scope_),
        };

        return new this.PopoverFactory_(popoverConfig);
    }

    /**
     * @override
     */
    $onInit() {
        this.popover_ = this.createPopoverInstance_();
    }

    /**
     * Show tooltip on mouse enter
     */
    onMouseEnter($event) {
        this.popover_.show($event.target);
    }

    /**
     * Hide tooltip on mouse leave
     */
    onMouseLeave() {
        this.popover_.hide();
    }

    /**
     * @override
     */
    $onDestroy() {
        this.popover_.remove();
    }
}

MissingCertIssuerIconController.$inject = [
    'popoverFactory',
    '$compile',
    '$scope',
];

/**
 * @name missingCertIssuerIconComponent
 * @memberOf module:avi/app
 * @property {module:avi/app.MissingCertIssuerIconController} controller
 * @property {module:avi/app.missingCertIssuerIconBindings} bindings
 * @description Notify the user if a intermediate CA Certificate is missing
 * @author Ashish Verma
 */
angular.module('avi/app').component('missingCertIssuerIcon', {
    bindings: {
        certName: '<',
    },
    controller: MissingCertIssuerIconController,
    templateUrl: 'src/components/templates/security/ssl-tls-certificates/' +
        'missing-cert-issuer-icon/missing-cert-issuer-icon.component.html',
});

/**
 * @module CoreModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { findWhere } from 'underscore';
import { AjsDependency } from 'ajs/js/utilities/ajsDependency';
import { AviModalType } from './avi-modal';

export interface IErrorMessageObject {
    count: number;
    text: string;
}

/**
 * @description
 * Service to keep all current error messages and show an alert modal window.
 * @author Rohit Gaikwad, Zhiqian Liu, Alex Malitsky
 */
export class AviAlertService extends AjsDependency {
    /**
     * Array of error messages objects having text and counter.
     */
    private readonly messages: IErrorMessageObject[] = [];

    /**
     * List of messages in string format to be rendered by avi-alert component.
     * Has to be immutable so that aviAlert could re-render the list on the binding update.
     */
    private readonly aviAlertMessages: string[] = [];
    private readonly backendErrorMsgFilter: (error: string | object) => string;

    constructor() {
        super();

        const $filter = this.getAjsDependency_('$filter');
        const $rootScope = this.getAjsDependency_('$rootScope');

        this.backendErrorMsgFilter = $filter('backendErrorMsg');

        $rootScope.$on('userLoggedOut', this.clear);
        $rootScope.$on('setContext', this.clear);
    }

    /**
     * Appends an error's text to the list and shows an alert modal if it was hidden.
     * @todo currently aviAlert won't appear when user is logged out but error would get
     *     added to the stack
     */
    public throw = (message: any): void => {
        // can be null on cancelled requests, ignore those
        if (!message || typeof message === 'object' && message.silent) {
            return;
        }

        const msgText = this.backendErrorMsgFilter(message);
        const sameMessage = findWhere(this.messages, { text: msgText });

        if (sameMessage) {
            sameMessage.count++;
        } else {
            this.messages.unshift({
                count: 1,
                text: msgText,
            });
        }

        this.updateAviAlertMessages();

        const aviModal = this.getAjsDependency_('AviModal');
        const alertModalId = 'avi-alert';

        if (!aviModal.isOpen(alertModalId)) {
            aviModal.open(
                alertModalId,
                {
                    message: this.aviAlertMessages, // has to be passed by reference
                    onClose: () => this.clear(),
                    closeModal: () => aviModal.destroy(alertModalId),
                },
                'avi-prompt',
                AviModalType.AVI_ALERT_OR_CONFIRM,
            );
        }
    };

    /**
     * Empties the list of errors.
     */
    public clear = (): void => {
        this.messages.length = 0;
        this.aviAlertMessages.length = 0;
    };

    /**
     * Flatten out errors into the list of strings to be rendered via avi-alert component.
     */
    private updateAviAlertMessages(): void {
        const { aviAlertMessages } = this;

        aviAlertMessages.length = 0;

        this.messages
            .forEach(({ text: aviAlertMessage, count }) => {
                if (count > 1) {
                    aviAlertMessage += ` (x${count})`;
                }

                aviAlertMessages.push(aviAlertMessage);
            });
    }
}

AviAlertService.ajsDependencies = [
    '$filter',
    '$rootScope',
    'AviModal',
];

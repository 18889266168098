/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name httpParamFullSerializer
 * @description
 *
 *     Encodes query URL part (keys and values) when serializing em to URL.
 *
 *     Full copy of angular $HttpParamSerializerProvider.$get but instead of `encodeUriQuery`
 *     'window.encodeURIComponent' is used. Also parameters aren't ordered by name.
 *
 *     Copied from angular v1.6.
 */
angular.module('aviApp').factory('httpParamFullSerializer', ['$window',
function($window) {
    const { encodeURIComponent } = $window;

    const serializeValue = v => {
        if (angular.isObject(v)) {
            return angular.isDate(v) ? v.toISOString() : angular.toJson(v);
        }

        return v;
    };

    return function(params) {
        if (!params) {
            return '';
        }

        const parts = [];

        _.each(params, (value, key) => {
            if (value === null || angular.isUndefined(value)) {
                return;
            }

            if (Array.isArray(value)) {
                value.forEach(v =>
                    parts.push(
                        `${encodeURIComponent(key)}=${encodeURIComponent(serializeValue(v))}`,
                    ));
            } else {
                parts.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(serializeValue(value))}`,
                );
            }
        });

        return parts.join('&');
    };
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './shared-vs-vip.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name sharedVsVip
 * @description
 *     Component used to set the 'vs_vip_ref' on Virtual Service creation. A 'vs_vip_ref' can be set
 *     only on creation, when the user is able to select a 'vs_vip_ref' from a CollectionDropdown.
 *     When editing a Virtual Service, the 'vs_vip_ref' can neither be changed to another ref nor
 *     removed.
 * @param {VirtualService} virtualservice
 */
class SharedVsVipController {
    constructor(
        Collection,
        VsVip,
        l10nService,
    ) {
        this.VsVip = VsVip;
        this.vsCollection = new Collection({
            objectName: 'virtualservice',
            params: {
                join: 'vsvip_ref',
            },
        });

        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        const cloudRef = this.virtualservice.getCloudRef();

        if (cloudRef) {
            this.vsCollection.setParams({
                'cloud_ref.uuid': cloudRef.slug(),
            });
        }

        this.vsVip = this.virtualservice.getConfig()['vsvip_ref_data'];
        this.vipAddresses = this.vsVip.getVipAddresses();
    }

    $onDestroy() {
        this.vsCollection.destroy();
    }

    /**
     * Handler for ngChange event on CollectionDropdown change. Sets this.vipAddresses
     * based on the vip and dnsInfo of the selected VsVip object.
     * @param {Item} item - Item instance.
     */
    handleVsVipChange(item) {
        const config = item.getConfig();

        if (config.vsvip_ref_data) {
            const vsConfig = this.virtualservice.getConfig();

            vsConfig.vsvip_ref = config.vsvip_ref;
            this.vsVip = new this.VsVip({
                data: {
                    config: config.vsvip_ref_data,
                },
            });
            vsConfig.vsvip_ref_data = this.vsVip;
            this.vipAddresses = this.vsVip.getVipAddresses();
        } else {
            this.vipAddresses = [];
        }

        this.vipAddressesStr = this.vipAddresses.join(', ');
    }
}

SharedVsVipController.$inject = [
    'Collection',
    'VsVip',
    'l10nService',
];

angular.module('aviApp').component('sharedVsVip', {
    controller: SharedVsVipController,
    bindings: {
        virtualservice: '=',
    },
    templateUrl: 'src/components/applications/virtualservice/virtualservice-vip-address/' +
            'shared-vs-vip/shared-vs-vip.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './GSLBEditController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name GSLBEditController
 * @author Alex Malitsky
 * @description
 *
 *     Corresponding modal window provides four layout types:
 *       - initial with local Site creds, name and GSLB subomains (creation)
 *       - with just GSLB subdomains (main edit)
 *       - for local\remote Site edit (Site edit and creation)
 *       - DNS VS dropdown for GslbSite#dns_vses (second tab of edit).
 *
 *     Important thing about transition from regular view to DNS VS selection is the fact that GSLB
 *     instance is being saved before going to DNS tab. That means that we need to redefine some
 *     controller variables and manually call Item#beforeEdit.
 *
 */
//TODO add local and remote controllers creds check
angular.module('aviApp').controller('GSLBEditController', [
'$scope', '$element', 'GSLBSiteFingerprint', 'secretStubStr', 'l10nService',
function($scope, $element, GSLBSiteFingerprint, secretStubStr, l10nService) {
    /**
     * Provides ability to compare initial GslbSite config with an updated one. Used to figure
     * out whether password field is required to be provided by user.
     * @type {GSLBSiteFingerprint}
     * @inner
     */
    const gslbSiteFingerprint = new GSLBSiteFingerprint();

    $scope.$parent.modalScope = $scope;//AviModal thing

    l10nService.registerSourceBundles(dictionary);

    $scope.l10nKeys = l10nKeys;

    /**
     * Has an index of editable GslbSite in GslbConfig#sites.
     * @type {number|undefined}
     * @public
     */
    let siteIndex;

    /**
     * There are two tabs of this modal - main and DNS VS selection.
     * @type {string|undefined}
     */
    $scope.currentTab = undefined;//default view when undefined

    $scope.init = function() {
        const config = $scope.editable.getConfig();

        if (!('url' in config) || !_.isUndefined($scope.siteIndex) &&
            ($scope.siteIndex < 0 || $scope.siteIndex > config['sites'].length - 1)) {
            $scope.siteIndex = 0;
        }

        if (!_.isUndefined($scope.siteIndex)) {
            siteIndex = $scope.siteIndex;
            $scope.site = $scope.editable.getAviSiteByIndex(siteIndex);
            gslbSiteFingerprint.save($scope.site);
        }

        //figure out whether we need to show dns domains settings
        $scope.showSettings = !('url' in config) || _.isUndefined(siteIndex); //subdomains
    };

    /**
     * Adds IpAddr to GslbSite#ip_addresses. Button click event handler.
     * @public
     */
    $scope.addIpAddr = function() {
        $scope.site.ip_addresses.push({
            addr: '',
            type: 'V4',
        });

        setTimeout(() => $element.find('div.ip-address-wrapper input').last().trigger('focus'), 5);
    };

    /**
     * Adds subdomains to GslbConfig#dns_configs. Button click event handler.
     * @public
     */
    $scope.addGSLBSubdomain = function() {
        $scope.editable.getConfig().dns_configs.push({ domain_name: '' });
        setTimeout(() => $element.find('div.dns-rule-wrapper input').last().trigger('focus'), 5);
    };

    /**
     * Returns the modal name depending on layout type.
     * @returns {string} - Can have HTML tags in it.
     */
    $scope.getModalTitle = function() {
        const config = $scope.editable.getConfig(),
            { site } = $scope;
        let title = '';

        if (config) {
            if (!('url' in config)) {
                title = l10nService.getMessage(l10nKeys.newGslbConfigurationTitle);
            } else if (_.isUndefined(siteIndex)) {
                title = l10nService.getMessage(l10nKeys.editGslbConfigurationTitle);
            } else if (!site['cluster_uuid']) {
                title = l10nService.getMessage(l10nKeys.newGslbSiteTitle);
            } else {
                title = l10nService.getMessage(l10nKeys.eidtGslbSiteTitle);

                if ($scope.currentTab === 'DNS') {
                    title = `<i class="icon-arrow-left"></i>
                        ${l10nService.getMessage(l10nKeys.eidtGslbSiteTitle, [title])}`;
                }
            }
        }

        return title;
    };

    /**
     * GslbSite#password is required field only for initial controller
     * cluster configuration and after its "significant" change made by user.
     * @returns {boolean}
     * @public
     */
    $scope.isSitePasswordRequired = function() {
        const { site } = $scope,
            config = $scope.editable.getConfig();
        let isRequired = true;

        if (!_.isUndefined(config)) {
            isRequired = !('url' in config) || site && (!site['cluster_uuid'] ||
                gslbSiteFingerprint.hasChanged(site));
        }

        return isRequired;
    };

    /**
     * Submit button click event handler. Upon click we either switch view from regular to DNS VS
     * selection or submit an Item. If GslbSite#password is provided we validate
     * credentials first and set/update GslbSite#cluster_uuid. When switching to DNS VS
     * list we load VS names at first from corresponding GslbSite.
     * @param {boolean=} isDone - True for regular save button but false when we wanna switch to
     *     NDS VS selection layout.
     * @public
     */
    $scope.submit = function(isDone) {
        /**
         * On switch to DNS VS selection tab we need to empty VS collection, set appropriate
         * GslbSiteConfig#cluster_uuid param, redefine $scope.site and mark GSLB Item as
         * pristine since it just got saved.
         * @inner
         */
        function switchToDNStab(callBeforeEdit = false) {
            //editable.data.config got rewritten by successful save event
            if (callBeforeEdit) {
                gslb.beforeEdit();
            }

            $scope.site = $scope.editable.getAviSiteByIndex(siteIndex);

            gslb.getSiteDNSVSNames(siteIndex)
                .then(() => {
                    gslb.setPristine();
                    $scope.currentTab = 'DNS';
                });
        }

        const
            { editable: gslb } = $scope,
            { site } = $scope;

        let promise;

        //only dns domains settings or DNS VSs - submit only, isDone doesn't matter but should
        // be true
        if (_.isUndefined(siteIndex) || $scope.currentTab === 'DNS') {
            gslb.submit();
        } else if (site.password && site.password !== secretStubStr) {
            promise = gslb.verifySiteAndSave(siteIndex, !!isDone);

            if (!isDone) {
                promise.then(() => switchToDNStab(true));
            }
        } else if (isDone) {
            gslb.submit();
        } else if (gslb.modified()) {
            gslb.save().then(() => switchToDNStab(true));
        } else {
            switchToDNStab();
        }
    };

    /**
     * For DNS VS selection tab only. Event handler for going back to the main view.
     * @public
     */
    //TODO revert DNS VSes selection ?
    $scope.back = function() {
        const { site } = $scope;

        if (site && $scope.currentTab === 'DNS') {
            $scope.currentTab = undefined;
            gslbSiteFingerprint.save(site);
            $scope.editable.setPristine();
        }
    };
}]);

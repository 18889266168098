/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function snmpProfileCollectionFactory(Collection, SnmpProfile) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Collection
     * @author Ram Pal
     * @desc Collection of {@link module:avi/app.SnmpProfile SnmpProfiles}.
     */
    class SnmpProfileCollection extends Collection {}

    Object.assign(SnmpProfileCollection.prototype, {
        objectName_: 'snmptrapprofile',
        itemClass_: SnmpProfile,
        windowElement_: 'adm-snmp-create',
    });

    return SnmpProfileCollection;
}

snmpProfileCollectionFactory.$inject = [
    'Collection',
    'SnmpProfile',
];

angular.module('avi/app')
    .factory('SnmpProfileCollection', snmpProfileCollectionFactory);

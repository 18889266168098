/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './error-page-body-modal.l10n';
import './error-page-body-modal.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name errorPageBodyModal
 * @param {ErrorPagebody} editable
 * @description
 *     Error Page Body config modal.
 */
class ErrorPageBodyModalController {
    constructor(
        Regex,
        l10nService,
    ) {
        this.Regex = Regex;
        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }
}

ErrorPageBodyModalController.$inject = [
    'Regex',
    'l10nService',
];

angular.module('aviApp').component('errorPageBodyModal', {
    controller: ErrorPageBodyModalController,
    bindings: {
        editable: '<',
    },
    templateUrl: 'src/components/modals/error-page-body-modal/error-page-body-modal.html',
});

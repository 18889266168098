/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './StringGroupController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('StringGroupController', [
'$scope',
'CRUDGridConfig',
'StringGroupCollection',
'l10nService',
function(
    $scope,
    CRUDGridConfig,
    StringGroupCollection,
    l10nService,
) {
    /**
    * Get keys from source bundles for template usage.
    */
    $scope.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    const gridConfig = {
        collection: new StringGroupCollection(),
    };

    const { objectName } = gridConfig.collection;

    gridConfig.id = `${objectName}-list-page`;

    gridConfig.fields = [
        {
            name: 'name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            template: '<span class="sel-string-grp-name">{{row.getName()}}</span>',
            sortBy: 'name',
        }, {
            name: 'type',
            title: l10nService.getMessage(l10nKeys.columnTitleType),
            template: '{{row.getConfig().type !== "SG_TYPE_STRING" ? "map" : "string"}}',
        }, {
            name: 'kv_length',
            title: l10nService.getMessage(l10nKeys.columnTitleNumberOfStrings),
            template: '<span class="sel-string-grp-num">{{row.getConfig().kv.length}}</span>',
        },
    ];

    gridConfig.singleactions = [{
        title: l10nService.getMessage(l10nKeys.actionBtnEdit),
        class: 'icon-pencil-4',
        hidden: row => !row.isEditable() && !row.isClonable(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }];

    $scope.gridConfig = CRUDGridConfig(gridConfig);
}]);

/**
 * Module containing form components.
 * @preferred
 * @module AviFormsModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'ng/shared/shared.module';
import { NotificationModule } from 'ng/modules/notification/notification.module';
import {
    CredentialsVerificationComponent,
    CredentialsVerificationDialogComponent,
} from './components';

const aviFormsComponents = [
    CredentialsVerificationComponent,
    CredentialsVerificationDialogComponent,
];

@NgModule({
    declarations: [
        ...aviFormsComponents,
    ],
    exports: [
        CredentialsVerificationComponent,
        CredentialsVerificationDialogComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NotificationModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AviFormsModule {}

/**
 * @module NotificationModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import './vs-faults.component.less';
import { isUndefined } from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './vs-faults.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export interface IGroupedServerFault {
    poolRef: string;
    faults: string[];
}

export interface IVSGroupedFaults {
    generic?: string[];
    server?: IGroupedServerFault[];
}

/**
 * Displays Virtual Service faults.
 * @author alextsg
 */
@Component({
    selector: 'vs-faults',
    templateUrl: './vs-faults.component.html',
})
export class VSFaultsComponent {
    /**
     * Object containing generic and server faults.
     */
    @Input() public faults: IVSGroupedFaults = {};

    /**
     * Called to close the notification.
     */
    @Output() public onClose = new EventEmitter();

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Close handler for closing the notification.
     */
    public handleClose(): void {
        this.onClose.emit();
    }

    /**
     * Returns true if generic faults exist.
     */
    public showGenericFaults(): boolean {
        const { generic } = this.faults;

        return !isUndefined(generic) && generic.length > 0;
    }

    /**
     * Returns true if server faults exist.
     */
    public showServerFaults(): boolean {
        const { server } = this.faults;

        return !isUndefined(server) && server.length > 0;
    }

    /**
     * trackBy function for tracking faults.
     */
    public trackByFault(index: number, fault: string): string {
        return fault;
    }

    /**
     * trackBy function for tracking server faults.
     */
    public trackByServerFault(index: number, serverFault: IGroupedServerFault): string {
        return serverFault.poolRef;
    }
}

/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import './expander-with-actions.less';

export interface IExpanderAction {
    iconClassName: string;
    title: string;
    confirmationMessage?: string;
    confirmationButtonProps?: IExpanderConfirmationButtonProps;
    onClick?(): void;
    hidden?(): boolean;
}

interface IExpanderConfirmationButtonProps {
    confirmationButtonClassName?: string;
    actions: IExpanderConfirmationButtonAction[];
}

interface IExpanderConfirmationButtonAction {
    label: string;
    onClick(): void;
}

interface IConfirmationProps {
    message: string;
    confirmationButtonProps: IExpanderConfirmationButtonProps;
}

/**
 * @description Component for an expander with action buttons.
 * @example
 *     <expander-with-actions
 *         [actions]="expanderActions"
 *     >
 *         <opener>
 *             <div>EXPAND ME</div>
 *         </opener>
 *         <content>
 *             <div>EXPANDED</div>
 *         </content>
 *     </expander-with-actions>
 * @author alextsg
 */
@Component({
    selector: 'expander-with-actions',
    templateUrl: './expander-with-actions.component.html',
})

export class ExpanderWithActionsComponent implements OnInit {
    /**
     * Text to be displayed. Optional, as a transcluded element can be used instead.
     */
    @Input() public label?: string;

    /**
     * Class to be added to the opener element.
     */
    @Input() public openerClassName?: string;

    /**
     * Actions to be shown in the expander.
     */
    @Input() public actions: IExpanderAction[] = [];

    /**
     * Allows the parent to set the expanded state.
     */
    @Input() public expanded = false;

    /**
     * Called when the expand state has changed.
     */
    @Output() public onToggleExpand = new EventEmitter<boolean>();

    public showingConfirmation = false;
    public confirmationProps: IConfirmationProps;
    public hover = false;

    /**
     * Returns default confirmationProps.
     */
    public static getDefaultConfirmationProps(): IConfirmationProps {
        return {
            confirmationButtonProps: {
                actions: [],
                confirmationButtonClassName: '',
            },
            message: '',
        };
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        this.confirmationProps = ExpanderWithActionsComponent.getDefaultConfirmationProps();
    }

    /**
     * Returns true if actions exist.
     */
    public hasActions(): boolean {
        return Boolean(this.actions.length);
    }

    /**
     * Handler passed to ExpanderComponent. Called when user toggles the expander.
     */
    public handleToggleExpand(expanded = false): void {
        this.expanded = expanded;
        this.onToggleExpand.emit(expanded);
    }

    /**
     * Returns the name of the icon class to use.
     */
    public getCaretIconClass(): string {
        return this.expanded ? 'sl-icon-arrow-up' : 'sl-icon-arrow-down';
    }

    /**
     * Handler for the mouse hover on the opener element.
     */
    public handleHover(hover = false): void {
        this.hover = hover;
    }

    /**
     * Called when an expander button is clicked.
     */
    public handleClickExpanderButton(action: IExpanderAction): void {
        if (action.onClick) {
            try {
                action.onClick();
            } catch (error) {
                throw new Error(`action.onClick failed: ${error}`);
            }
        } else {
            const { confirmationMessage, confirmationButtonProps } = action;

            this.showingConfirmation = true;
            this.confirmationProps.message = confirmationMessage;
            this.confirmationProps.confirmationButtonProps = confirmationButtonProps;
        }
    }

    /**
     * Called when the cancel button in a confirmation is clicked.
     */
    public resetConfirmation(): void {
        this.showingConfirmation = false;
        this.confirmationProps = ExpanderWithActionsComponent.getDefaultConfirmationProps();
    }

    /**
     * Handler for clicking a confirmation button.
     */
    public handleConfirm($event: MouseEvent, action: IExpanderConfirmationButtonAction): void {
        $event.stopPropagation();

        try {
            action.onClick();
        } catch (error) {
            throw new Error(`action.onClick failed: ${error}`);
        }

        this.resetConfirmation();
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const dataTransportByPostFactory = ListDataTransport => {
    class DataTransportByPost extends ListDataTransport {
        /** @constructor */
        constructor(args = {}) {
            args.httpMethod = 'post';
            super(args);
        }

        /**
         * Returns the payload object for POST request.
         * @override
         */
        getRequestPayload_(params) {
            return params || undefined;
        }

        /**
         * Need to convert includeRefs_ to include_refs before
         * forming queryParams.
         * @property {Object} params
         * @returns {Object}
         * @override
         */
        getQueryParams_(params) {
            params = angular.copy(params);

            if (params['includeRefs_']) {
                params['include_refs'] = params['includeRefs_'];
            }

            delete params['includeRefs_'];

            return super.getQueryParams_(params);
        }
    }

    return DataTransportByPost;
};

dataTransportByPostFactory.$inject = [
    'ListDataTransport',
];

/**
 * @ngdoc factory
 * @name  DataTransportByPost
 * @author Chitra
 * @description
 *      DataTransport layer using POST method.
 *      The payload params except include_name and include_refs are being passed through
 *      POST payload.
 */
angular.module('aviApp')
    .factory('DataTransportByPost', dataTransportByPostFactory);

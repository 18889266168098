/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'network';
const componentName = 'static-ip-pool-configuration';
const prefix = `${moduleName}.${componentName}`;

export const staticIpAddressPoolLabel = `${prefix}.staticIpAddressPoolLabel`;
export const useStaticIpForBothCheckboxLabel = `${prefix}.useStaticIpForBothCheckboxLabel`;
export const useStaticIpForBothCheckboxTooltip = `${prefix}.useStaticIpForBothCheckboxTooltip`;
export const useForVipsLabel = `${prefix}.useForVipsLabel`;
export const useForServiceEnginesLabel = `${prefix}.useForServiceEnginesLabel`;
export const addButtonLabel = `${prefix}.addButtonLabel`;

export const ENGLISH = {
    [staticIpAddressPoolLabel]: 'Static IP Address Pool',
    [useStaticIpForBothCheckboxLabel]: 'Use Static IP Address for VIPs and SE',
    [useStaticIpForBothCheckboxTooltip]:
        'Static IPs are used for both VIP and Service Engine vNIC IP allocation',
    [useForVipsLabel]: 'Use For VIPs',
    [useForServiceEnginesLabel]: 'Use for Service Engines',
    [addButtonLabel]: 'Add Static IP Address Pool',
};

/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
    debounce,
    every,
    isUndefined,
} from 'underscore';
import { Collection, Item } from 'ajs/modules/data-model';
import {
    DropdownModelValue,
    IAviDropdownOption,
} from '../avi-dropdown';
import { AviDropdownButtonPosition, IAviDropdownButtonAction } from '../avi-dropdown-button';
import { normalizeValue } from '../../utils';
import './avi-collection-dropdown.component.less';

/**
 * Returns the dropdown option for an item.
 */
const getIDropdownItemOption = (item: Item): IAviDropdownOption => ({
    label: item.getName(),
    value: item.getRef(),
});

/**
 * Used for paginated loading from a collection.
 */
const PAGE_SIZE = 8;

/**
 * @description Dropdown component that retrieves options from a Collection.
 * @author alextsg
 */
@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AviCollectionDropdownComponent),
        },
    ],
    selector: 'avi-collection-dropdown',
    templateUrl: './avi-collection-dropdown.component.html',
})
export class AviCollectionDropdownComponent implements ControlValueAccessor, OnInit, OnDestroy {
    /**
     * Placeholder shown when no option(s) have been selected.
     */
    @Input() public placeholder = 'Select';

    /**
     * required - True if the dropdown is a required input.
     */
    @Input('required') private set setRequired(required: boolean | '') {
        this.required = required === '' || Boolean(required);
    }

    /**
     * Collection instance.
     */
    @Input() public collection: Collection;

    /**
     * If true, hides the search input.
     */
    @Input() public hideSearch = false;

    /**
     * True to disallow creating a new Item.
     */
    @Input() public disableCreate = false;

    /**
     * True to disallow editing a selected Item.
     */
    @Input() public disableEdit = false;

    /**
     * Params to be passed to collection.create.
     */
    @Input() public createParams = {};

    /**
     * True to allow multiple selection.
     */
    @Input() public multiple = false;

    /**
     * List of actions in the actions menu.
     */
    @Input() public actions: IAviDropdownButtonAction[];

    /**
     * Position of the actions menu tooltip.
     */
    @Input() public actionPosition = AviDropdownButtonPosition.TOP_RIGHT;

    /**
     * Flag to show a value as readonly. Differs from disabled in that the input field will be
     * displayed similarly to a label.
     */
    @Input() public readonly ? = false;

    /**
     * Set through 'required' binding. Makes form field required.
     */
    public required = false;

    /**
     * Set through 'disabled' binding. Disables input and button.
     */
    public disabled = false;

    /**
     * Total number of loaded items.
     */
    private totalItems = 0;

    /**
     * An instance of Item created from the selected ref.
     */
    private selectedItem: Item;

    /**
     * Value being get/set as the ngModel value.
     */
    private modelValue: DropdownModelValue;

    /**
     * Default create action for the button menu.
     */
    private defaultCreateAction: IAviDropdownButtonAction = {
        label: 'Create',
        onClick: () => this.create(),
        disabled: () => this.disableCreate || !this.collection.isCreatable(),
    };

    /**
     * Default edit action for the button menu.
     */
    private defaultEditAction: IAviDropdownButtonAction = {
        label: 'Edit',
        onClick: () => this.selectedItem.edit(),
        disabled: () => this.disableEdit || !this.selectedItem || !this.selectedItem.isEditable(),
        hidden: () => this.multiple || !this.selectedItem,
    };

    constructor() {
        this.handleSearch = debounce(this.handleSearch, 500);
    }

    /** @override */
    public ngOnInit(): void {
        this.collection.updateViewportSize(PAGE_SIZE);
        this.collection.updateItemsVisibility(undefined, this.totalItems);
        this.setSelectedItem();

        if (isUndefined(this.actions)) {
            this.actions = this.getDefaultActions();
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        if (this.selectedItem instanceof Item) {
            this.selectedItem.destroy();
        }
    }

    /**
     * Called when the options list has scrolled to the end. Loads new items.
     */
    public handleScrollEnd(totalItems: number): void {
        if (totalItems > this.totalItems) {
            this.collection.updateItemsVisibility(undefined, totalItems);
            this.totalItems = totalItems;
        }
    }

    /**
     * Called when an option has been selected. Sets the Item instance(s).
     */
    public handleSelect(): void {
        this.setSelectedItem();
    }

    /**
     * Creates dropdown options from the collection's Items.
     */
    public get options(): IAviDropdownOption[] {
        return this.collection.itemList.map(getIDropdownItemOption);
    }

    /**
     * Called when the options menu has been opened or closed. When opened, loads the collection.
     */
    public handleOptionsOpenedChange(opened: boolean): void {
        if (opened) {
            this.totalItems = 0;
            this.collection.load(undefined, true);
        }
    }

    /**
     * Creates a new Item.
     */
    public async create(): Promise<void> {
        const createdItem = await this.collection.create(undefined, { ...this.createParams });
        const createdRef = createdItem.getRef();

        if (this.value instanceof Array) {
            this.value = [...this.value, createdRef];
        } else if (this.multiple) {
            this.value = [createdRef];
        } else {
            this.value = createdRef;
        }
    }

    /**
     * Called to make an HTTP request search for Items.
     */
    public handleSearch = (searchTerm: string): void => {
        this.totalItems = 0;
        this.collection.search(searchTerm);
    };

    /**
     * Called to remove a selected value. Applicable for multiple-selection.
     */
    public handleRemoveValue(ref: DropdownModelValue): void {
        if (!(this.value instanceof Array)) {
            throw new Error('Can\'t remove value from a non-multiple-selection CollectionDropdown');
        }

        this.value = this.value.filter(value => value !== ref);
        this.setSelectedItem();
    }

    /**
     * Getter for the modelValue.
     */
    public get value(): DropdownModelValue {
        return this.modelValue;
    }

    /**
     * Setter for the modelValue. If multiple is set to true, then the modelValue takes the form of
     * an array. Otherwise it's just a string.
     */
    public set value(val: DropdownModelValue) {
        if (this.modelValue !== val) {
            const normalizedValue = normalizeValue(val);

            this.modelValue = normalizedValue;
            this.onChange(normalizedValue);
        }

        this.onTouched();
    }

    /**
     * Returns true if every action is disabled.
     */
    public allActionsDisabled(): boolean {
        return every(this.actions, action => {
            if (typeof action.disabled !== 'function') {
                return false;
            }

            try {
                return action.disabled();
            } catch (error) {
                throw new Error(`action.disabled failed: ${error}`);
            }
        });
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
    */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: DropdownModelValue) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(value: DropdownModelValue): void {
        this.modelValue = normalizeValue(value);
        this.setSelectedItem();
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /**
     * Function that is called by the forms API when the control status changes to or from
     * 'DISABLED'. Depending on the status, it enables or disables the appropriate DOM element.
     */
    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /*************************************************************************/

    /**
     * Returns a set of default actions if none are passed in.
     */
    private getDefaultActions(): IAviDropdownButtonAction[] {
        const actions: IAviDropdownButtonAction[] = [];

        if (!this.disableCreate && this.collection.isCreatable()) {
            actions.push(this.defaultCreateAction);
        }

        if (!this.disableEdit && !this.multiple) {
            actions.push(this.defaultEditAction);
        }

        return actions;
    }

    /**
     * Sets the selected Item based on the selected ref.
     */
    private setSelectedItem(): void {
        if (this.selectedItem instanceof Item) {
            this.selectedItem.destroy();
        }

        if (!this.multiple && typeof this.value === 'string') {
            this.selectedItem = this.getSelectedItem(this.value);
        } else {
            this.selectedItem = undefined;
        }
    }

    /**
     * Returns an Item instance based on a ref.
     */
    private getSelectedItem = (value: string): Item => {
        // eslint-disable-next-line no-extra-parens
        return this.collection.createNewItem({ id: (value as any).slug() }, true) as Item;
    };

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: DropdownModelValue): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};
}

/** @module CportalModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */
import { L10nService } from '@vmw/ngx-vip';
import { IAviDropdownOption } from 'ng/shared/components';
import { createDropdownOption } from 'ng/shared/utils';
import { StringService } from 'ajs/modules/core';
import { CportalService, CPORTAL_SERVICE_TOKEN } from '../../services/cportal.service';

import {
    AFFECTED_VERSION,
    ASSIGNEE,
    PORTAL_MODES,
    REPORTER,
    systemTagTypes,
    systemTypeLabels,
    TARGET_VERSION,
} from '../../../core/constants';

import {
    ICase,
    ICustomTagGridConfig,
    ISystemTagData,
    ITagRow,
    TJiraMetaData,
} from './case-custom-tags.type';

import template from './case-custom-tags.component.html';
import './case-custom-tags.component.less';
import * as l10n from './case-custom-tags.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export const CASE_CUSTOM_TAGS_COMPONENT_TOKEN = 'caseCustomTags';

/**
 * @description Component for case system and custom tags.
 * @author Rohit Gaikwad
 */
class CaseCustomTagsController {
    public gridConfig: Partial<ICustomTagGridConfig>;

    /**
     * Tag options for system tags.
     */
    public jiraFieldOptions: ISystemTagData = {};
    public ngModelCtrl: ng.INgModelController;

    /**
     * Tag Lable for add button.
     */
    public addtagButtonLabel: string;
    /**
     * Defines the portal modes.
     * PORTAL_MODES = SYSTEST is for syatem tags used for jira.
     * PORTAL_MODES = SALESFORCE is for custom tags used for salesforce.
     */
    public portalMode: PORTAL_MODES;
    /**
     * Stores the selected system tag values in case of SYSTEST portalMode.
     */
    public selectedSystemTags?: string[] = [];
    /**
     * Parent case object.
     */
    public case: ICase;
    /**
     * Stores the system/jira tag types.
     */
    private metaData: TJiraMetaData;
    /**
     * List of system/jira tag keys.
     */
    private systemTypes: string[];

    constructor(
        private readonly stringService: StringService,
        private readonly cportalService: CportalService,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onInit(): void {
        if (this.portalMode === PORTAL_MODES.SYSTEST) {
            this.addtagButtonLabel = this.l10nService.getMessage(l10nKeys.addSystemTagLabel);
            this.getMetadata();
        } else if (this.portalMode === PORTAL_MODES.SALESFORCE) {
            this.addtagButtonLabel = this.l10nService.getMessage(l10nKeys.addCustomTagLabel);
        }

        this.createGridConfig();

        const { ngModelCtrl } = this;

        ngModelCtrl.$parsers.push(this.setTags);
    }

    /**
     * handle system and custom tag remove events
     */
    public removeTagRow(row: ITagRow): void {
        const { type } = row;
        const config = this.case.getConfig();
        const index = config.tagRows.indexOf(row);

        this.case.removeTagRow(index);

        // Remove the previously selected tag value for SYSTEST mode.
        if (this.portalMode === PORTAL_MODES.SYSTEST) {
            const typeIndex = this.selectedSystemTags.indexOf(type);

            this.selectedSystemTags.splice(typeIndex, 1);
        }

        this.ngModelCtrl.$viewValue = this.case.getConfig().tagRows;
        this.onViewValueChange();
    }

    /**
     * Handle on system tag type change.
     */
    public onSystemTagTypeChange = (row: ITagRow): void => {
        const { type, value } = row;
        const config = this.case.getConfig();
        const rowIndex = config.tagRows.indexOf(row);

        this.selectedSystemTags[rowIndex] = type;
        row.value = value || '';

        this.onViewValueChange();
        this.createSystemTagValuesDropdown(type);
    };

    /**
     * Handle on custom tag type & value change.
     */
    public onCustomTagChange = (row: ITagRow): void => {
        this.onViewValueChange();
    };

    /**
     * Return hidden options for selected types.
     * Hide the already selected system tags.
     */
    public getHiddenOptions = (row: ITagRow): string[] => {
        return this.selectedSystemTags;
    };

    /**
     * Returns true if case is closed
     */
    public isCaseClosed(): boolean {
        return this.case.isCaseClosed();
    }

    /**
     * Add new tag row.
     */
    public addTagRow(): void {
        this.case.addTagRow();
        this.ngModelCtrl.$viewValue = this.case.getConfig().tagRows;
    }

    /**
     * Updates modal value according to the viewValue. Won't see changes otherwise cause we
     * mess with inner properties, not reference itself.
     */
    private onViewValueChange(): void {
        const { ngModelCtrl } = this;

        ngModelCtrl.$setViewValue([].concat(ngModelCtrl.$viewValue));
    }

    /**
     * Set Custom/System tag and value as key pair in string format from selected tags rows.
     */
    private setTags = (): string => {
        const config = this.case.getConfig();
        const selectedTagsAndValues = config.tagRows
            .map(({ type, value }) => `${type}=${value}`)
            .join();

        return selectedTagsAndValues;
    };

    /**
     * Create gridConfig for system tag and custom tag
     */
    private createGridConfig(): void {
        const deleteActionTitle = this.l10nService.getMessage(l10nKeys.deleteActionTitle);

        this.gridConfig = {
            id: 'case-tags',
            singleactions: [{
                title: deleteActionTitle,
                class: 'icon-trash',
                do: (row: ITagRow) => this.removeTagRow(row),
            }],
            layout: {
                hideDisplaying: true,
                hideSearch: true,
            },
        };

        if (this.portalMode === PORTAL_MODES.SYSTEST) {
            this.gridConfig.fields = [{
                name: 'type',
                title: 'Type',
                template: `
                    <dropdown
                        class="full-width"
                        ng-model="row.type"
                        placeholder="{{ ::config.props.l10nKeys.systemTagTypeLabel | vtranslate }}"
                        ng-change="config.props.onSystemTagTypeChange(row)"
                        options="config.props.jiraFieldOptions.typeDropdownOptions"
                        hidden-options="config.props.getHiddenOptions(row)"
                        search="true"
                        required
                    ></dropdown>
                `,
            }, {
                name: 'value',
                title: 'Value',
                template: `
                    <dropdown
                        class="full-width"
                        ng-model="row.value"
                        placeholder="{{ ::config.props.l10nKeys.valueLabel | vtranslate }}"
                        options="config.props.jiraFieldOptions[row.type].valueDropdownOptions"
                        ng-change="config.props.onSystemTagTypeChange(row)"
                        search="true"
                        required
                    ></dropdown>
                `,
            }];

            this.gridConfig.props = {
                jiraFieldOptions: this.jiraFieldOptions,
                l10nKeys,
                onSystemTagTypeChange: this.onSystemTagTypeChange,
                getHiddenOptions: this.getHiddenOptions,
            };
        } else if (this.portalMode === PORTAL_MODES.SALESFORCE) {
            this.gridConfig.fields = [{
                name: 'type',
                title: 'Type',
                template: `
                    <input
                        class="full-width"
                        type="text"
                        ng-model="row.type"
                        placeholder="{{ ::config.props.l10nKeys.customTagTypeLabel | vtranslate }}"
                        ng-change="config.props.onCustomTagChange()"
                        required
                    >
                `,
            }, {
                name: 'value',
                title: 'Value',
                template: `
                    <input
                        class="full-width"
                        type="text"
                        ng-model="row.value"
                        placeholder="{{ ::config.props.l10nKeys.valueLabel | vtranslate }}"
                        ng-change="config.props.onCustomTagChange()"
                        required
                    >
                `,
            }];

            this.gridConfig.props = {
                l10nKeys,
                onCustomTagChange: this.onCustomTagChange,
            };
        }
    }

    /**
     * Get meta data for system/jira tag types.
     * Meta data is used to build the system tag types dropdown.
     * This meta data is required in case of SYSTEST portal mode.
     */
    private getMetadata(): Promise<void> {
        return this.cportalService.getMetaData()
            .then((data: TJiraMetaData) => {
                this.metaData = data;

                const metaDataKeys = Object.keys(this.metaData)
                    .filter(type => type !== 'users' && type !== 'version');

                this.systemTypes = [
                    ...metaDataKeys,
                    ...systemTagTypes,
                ];

                this.createTagRows();
            });
    }

    /**
     * Create system tag type dropdown options
     */
    private createSystemTagTypeDropdownOptions(): void {
        this.jiraFieldOptions.typeDropdownOptions = this.systemTypes
            .map(key => {
                const dropdownLabel = systemTypeLabels[key] || key;

                return createDropdownOption(
                    key,
                    this.stringService.enumeration(dropdownLabel, ''),
                );
            });
    }

    /**
     * create tag row data from tagRows array.
     */
    private createTagRows(): void {
        const config = this.case.getConfig();

        this.selectedSystemTags = [];
        this.createSystemTagTypeDropdownOptions();

        config.tagRows.forEach((tagRow, index) => {
            const { type } = tagRow;

            this.selectedSystemTags[index] = type;
            this.jiraFieldOptions[type] = {};
            this.createSystemTagValuesDropdown(type);
        });
    }

    /**
     * Retruns dropdown options for given sytem tag type
     */
    private getTypeDropdownOptions(type: string): IAviDropdownOption[] {
        return Object.values(this.metaData[type])
            .map(({ name }) => createDropdownOption(name));
    }

    /**
     * Create dropdown option for provided system tag type.
     */
    private createSystemTagValuesDropdown(type: string): void {
        this.jiraFieldOptions[type] = this.jiraFieldOptions[type] || {};

        switch (type) {
            case ASSIGNEE:
            case REPORTER:
                this.jiraFieldOptions[type].valueDropdownOptions =
                    this.getTypeDropdownOptions('users');
                break;

            case AFFECTED_VERSION:
            case TARGET_VERSION:
                this.jiraFieldOptions[type].valueDropdownOptions =
                    this.getTypeDropdownOptions('version');
                break;

            default:
                this.jiraFieldOptions[type].valueDropdownOptions =
                    Object.values(this.metaData[type])
                        .map((
                            {
                                name,
                                description,
                            },
                        ) => createDropdownOption(
                            name,
                            name,
                            description,
                        ));
        }
    }
}

CaseCustomTagsController.$inject = [
    'stringService',
    CPORTAL_SERVICE_TOKEN,
    'l10nService',
];

export const caseCustomTagsOptions = {
    bindings: {
        case: '<editable',
        portalMode: '<',
    },
    require: {
        ngModelCtrl: 'ngModel',
    },
    controller: CaseCustomTagsController,
    template,
};

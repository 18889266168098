/**
 * @module IPReputationDBModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { Injectable } from '@angular/core';

import {
    ALBServicesRegistrationStatus,
    IALBServicesStatus,
} from 'generated-types';

import { CportalService } from 'ajs/modules/cportal/services';

export enum IPReputationDBRegistrationStatus {
    UNREGISTERED = 'UNREGISTERED',
    DISABLED = 'DISABLED',
    ENABLED = 'ENABLED',
}

@Injectable()
export class IPReputationDBListPageContainerService {
    constructor(
        private readonly cportalService: CportalService,
    ) {}

    /**
     * Returns the IP Reputation status from pulse settings. If the controller is not registered
     * with pulse, returns UNREGISTERED. If registered, but the feature has not been opted-in, then
     * the status is DISABLED. If registered and opted-in, returns ENABLED.
     */
    public async getRegistrationStatus(): Promise<IPReputationDBRegistrationStatus> {
        const albserviceStatus: IALBServicesStatus =
            this.cportalService.getRegistrationStatusDetails();
        const {
            registration_status: registrationStatus,
        } = albserviceStatus;

        if (registrationStatus !== ALBServicesRegistrationStatus.ALBSERVICES_REGISTERED) {
            return IPReputationDBRegistrationStatus.UNREGISTERED;
        }

        const info = await this.cportalService.getPortalInfo();
        const { feature_opt_in_status: optInStatus } = info;

        if (!optInStatus.enable_ip_reputation) {
            return IPReputationDBRegistrationStatus.DISABLED;
        }

        return IPReputationDBRegistrationStatus.ENABLED;
    }
}

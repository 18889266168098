const moduleName = 'ajs-legacy';
const componentName = 'virtual-service-analytics-controller';
const prefix = `${moduleName}.${componentName}`;

export const virtualServiceMetricsHeader = `${prefix}.virtualServiceMetricsHeader`;
export const poolsLabel = `${prefix}.poolsLabel`;

export const ENGLISH = {
    [virtualServiceMetricsHeader]: 'Virtual Service Metrics',
    [poolsLabel]: 'Pools',
};

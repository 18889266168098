/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

//TODO drop this
angular.module('aviApp').factory('ChartConfig', [
    function() {
        /**
         * Used by controllers to create dynamic charting pages
         * expected by PerformanceChart, CardList and other directives
         * @param {Array} config List of cards config objects, order is preserved
         */
        const ChartConfig = function(config) {
            if (!Array.isArray(config)) {
                throw new Error('ChartConfig must be an array');
            }

            this.cards = config.concat();

            // Used by Overlay.js to communicate with chartWithOverlaysTables directive
            this.selectedDot = {
                type: null,
                dot: null,
            };

            this.updateView();
            this.setActiveCard();
        };

        /**
         * Recalculate cardHash and cardIds for new config or when
         * shape of config changes
         */
        ChartConfig.prototype.updateView = function() {
            const cardHash = {};

            this.cards.forEach(function(card) {
                cardHash[card.id] = card;
                card.visible = true;
            });
            this.cardHash = cardHash;
        };

        /**
         * Gets a card by id or active card if no id given
         * @param  {string=} id card id or in not passed, get active card
         * @return card object with id or active card object
         */
        ChartConfig.prototype.getCard = function(id) {
            if (!id) {
                return this.cardHash[this.active];
            } else {
                return this.cardHash[id];
            }
        };

        /**
         * Returns the formatted for display string of the summaryTitle
         * for the card, otherwise the first series is used
         * @param  {string} id the id of the card, defaults to active card
         * @return {string}    User visible string for card
         */
        ChartConfig.prototype.getCardTitle = function(id) {
            const { metric } = this.getCard(id);

            return metric && metric.getTitle() || '';
        };

        /**
         * Returns noValueLabel string from chart card property
         * @param  {string} id the id of the card, defaults to active card
         * @return {string} human readable string for no value label
         */
        ChartConfig.prototype.getNoValueLabel = function(id) {
            const card = this.getCard(id);

            return card.noValueLabel || 'No Value';
        };

        /**
         * Sets the active card given an id
         * @param {string} id id of card, if not found, will select first card
         */
        ChartConfig.prototype.setActiveCard = function(id) {
            if (id in this.cardHash) {
                this.active = id;
            } else {
                this.active = this.cards[0].id;
            }
        };

        /**
         * Removes a card by id, updates active to first card if deleted card is currently active
         * @param  {string} id the card id to remove
         */
        ChartConfig.prototype.removeCard = function(id, skipUpdateView) {
            let indexToRemove;

            _.find(this.cards, function(element, index) {
                if (element.id === id) {
                    indexToRemove = index;

                    return true;
                }
            });

            this.cards.splice(indexToRemove, 1);
            delete this.cardHash[id];

            if (this.active === id) {
                // Removed active card, go to first card in list
                this.active = this.cards[0].id;
            }

            if (!skipUpdateView) {
                this.updateView();
            }
        };

        /**
         * Iterates through cards and visible child cards, returning data keys
         * @return {Array} List of data key strings to be passed to load function
         */
        ChartConfig.prototype.getMetrics = function() {
            const metricsArray = [];

            // Determine what metrics to load based on what config is left
            this.cards.forEach(function(metric) {
                if (metric.series) {
                    metric.series.forEach(function(metricName) {
                        metricsArray.push(metricName);
                    });
                }

                if (metric.errorSeries) {
                    metric.errorSeries.forEach(function(metricName) {
                        metricsArray.push(metricName);
                    });
                }

                if (metric.totalSeries) {
                    metricsArray.push(metric.totalSeries);
                }

                if (metric.errorTotal) {
                    metricsArray.push(metric.errorTotal);
                }
            });

            return _.unique(metricsArray);
        };

        return ChartConfig;
    }]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './pool-group-list-expander.less';
import * as l10n from './pool-group-list-expander.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
/**
 * @ngdoc component
 * @name  poolGroupListExpander
 * @description
 *     Displays a grid of pools belonging to the Pool Group sorted by priority_label and ratio.
 * @param {PoolGroup} row - PoolGroup item.
 */

class Controller {
    constructor(PoolGroup, PoolCollection, l10nService) {
        this.PoolGroup_ = PoolGroup;
        this.PoolCollection_ = PoolCollection;
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
        this.l10nService_ = l10nService;
    }

    $onInit() {
        /**
         * A mapping of priority_label values to numbers used for rowClasses.
         * @type {Object}
         * @example
         *     { '10': 2, '5': 0, '2': 1, '1': 0 }
         */
        this.priorityLabelHash = {};

        const { l10nService_: l10nService } = this;

        this.gridConfig = {
            id: 'pool-group-list-expander',
            rowId: 'pool_ref',
            fields: [{
                name: 'name',
                title: l10nService.getMessage(l10nKeys.columnTitleName),
                template:
                    `<span
                        ng-if="!row.pool.getVSId()"
                        avi-form-label="'Pool is not used by any VS'">
                        {{ row.pool_ref.name() }}
                    </span>
                    <a
                        ng-if="row.pool.getVSId()"
                        ui-sref="^.pool-detail.analytics({
                        vsId: row.pool.getVSId(),
                        poolId: row.pool_ref.slug()
                    })">
                        {{ row.pool_ref.name() }}
                    </a>`,
            }, {
                name: 'status',
                title: l10nService.getMessage(l10nKeys.columnTitleStatus),
                template:
                    `<avi-healthscore
                        class="pool-group-status"
                        item="row.pool"
                        stop-async-on-hide="true" />`,
            }, {
                name: 'ratio',
                title: l10nService.getMessage(l10nKeys.columnTitleRatio),
                template: '{{ row.ratio }}',
                sortBy: 'ratio',
            }, {
                name: 'deployment_state',
                title: l10nService.getMessage(l10nKeys.columnTitleDeploymentState),
                template: '{{ row.deployment_state.enumeration() }}',
            }, {
                name: 'priority_label',
                title: l10nService.getMessage(l10nKeys.columnTitlePriority),
                template: '{{ row.priority_label || \'N/A\' }}',
                sortBy: this.PoolGroup_.gridSortByPriority,
            }],
            rowClass: row => {
                if (this.priorityLabelHash[row.priority_label] === 0) {
                    return 'background-blue';
                } else {
                    return `background-gray-${this.priorityLabelHash[row.priority_label]}`;
                }
            },
        };

        const membersCopy = angular.copy(this.row.data.config.members);

        this.gridRows = this.PoolGroup_.sortMembersByPriorityLabel(membersCopy);
        this.setPriorityLabelHash(this.gridRows);

        this.poolCollection_ = new this.PoolCollection_({
            objectName: 'pool-inventory',
            params: {
                referred_by: `poolgroup:${this.row.data.config.uuid}`,
                join: undefined,
            },
            dataFields: ['config', 'runtime', 'health'],
            bind: {
                collectionLoadSuccess: () => {
                    this.gridRows.forEach(row => {
                        row.pool = this.poolCollection_.itemById[row.pool_ref.slug()];
                    });
                },
            },
        });

        this.poolCollection_.load();
    }

    /**
     * Function that populates the priorityLabelHash for adding a rowClass which sets the
     * background color of the rows according to the priority_label.
     * @param  {Object[]} members - Array of sorted PoolGroup members.
     */
    setPriorityLabelHash(members) {
        let currentPriority = 0;

        members.forEach(member => {
            if (!(member['priority_label'] in this.priorityLabelHash)) {
                this.priorityLabelHash[member.priority_label] = currentPriority;
                currentPriority++;
            }
        });
    }

    $onDestroy() {
        this.poolCollection_.destroy();
    }
}

Controller.$inject = [
    'PoolGroup',
    'PoolCollection',
    'l10nService',
];

angular.module('aviApp').component('poolGroupListExpander', {
    bindings: {
        row: '<',
    },
    controller: Controller,
    template:
        `<grid
            config="$ctrl.gridConfig"
            rows="$ctrl.gridRows">
        </grid>`,
});

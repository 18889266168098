/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc directive
 * @name gslbPoolPriorityField
 * @author Alex Malitsky
 * @param {GslbPoolConfig#priority} value
 * @restrict A
 * @description
 *
 *    Simple input wrapper to avoid code duplication.
 *
 */

angular.module('aviApp').directive('gslbPoolPriorityField', ['Regex',
function(Regex) {
    return {
        templateUrl: 'src/views/partials/application/gslb-pool-priority-field.html',
        restrict: 'A',
        controller: ['$scope', scope => {
            scope.Regex = Regex;
        }],
        scope: {
            value: '=',
        },
    };
}]);

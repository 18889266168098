/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './user-modal.component.less';
import * as l10n from './user-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @constructor
 * @memberOf module:avi/accounts
 * @mixes module:avi/accounts.userModalBindings
 * @see {@link module:avi/accounts.userModalComponent userModalComponent}
 */
class UserModalController {
    constructor(
        UserProfileCollection,
        Auth,
        l10nService,
    ) {
        /**
         * @type {UserProfileCollection}
         */
        this.userProfileCollection = new UserProfileCollection();

        /**
         * @type {module:avi/core.Auth}
         * @protected
         */
        this.auth_ = Auth;

        /**
         * To invalidate Modal form when password and confirm password dont match.
         * @type {boolean}
         */
        this.invalidPassword = false;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns true if Loggedin user is a super user.
     * @returns {boolean}
     */
    isSuperUser() {
        return this.auth_.isSuperUser();
    }

    /**
     * Fires on password change.
     * @param {boolean} invalidPassword
     */
    onPasswordChange(invalidPassword) {
        this.invalidPassword = invalidPassword;
    }

    /** @override */
    $onDestroy() {
        this.userProfileCollection.destroy();
    }
}

UserModalController.$inject = [
    'UserProfileCollection',
    'Auth',
    'l10nService',
];
/**
 * @name userModalComponent
 * @memberOf module:avi/accounts
 * @property {module:avi/accounts.userModalBindings} bindings
 * @property {module:avi/accounts.UserModalController} controller
 * @desc
 *      Modal component for creating/editing a User.
 * @author Aravindh Nagarajan
 */
angular.module('avi/accounts').component('userModal', {
    /**
     * @mixin userModalBindings
     * @memberof module:avi/accounts
     * @property {module:avi/accounts.User} editable User item config
     */
    bindings: {
        editable: '<',
    },
    controller: UserModalController,
    templateUrl:
        'src/components/modals/administration/accounts/user/user-modal.component.html',
});

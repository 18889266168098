/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc directive
 * @name header
 * @restrict E
 */
angular.module('aviApp').directive('header', [
'$state', '$filter',
function($state, $filter) {
    function headerLink(scope) {
        const
            stateIncludesFilter = $filter('currentStateIncludes'),
            hasAlerts = stateIncludesFilter('alerts'),
            hasEvents = stateIncludesFilter('events'),
            hasLogs = stateIncludesFilter('logs'),
            isInsights = stateIncludesFilter('hs-insights'),
            stateContext = $filter('stateContext')($state.$current);

        scope.showAsSE = showAs('serviceengine-detail');
        scope.showAsVS = showAs('virtualservice-detail');
        scope.showAsPool = showAs('pool-detail');

        scope.hideMetricValueTypeSelector = hasLogs || hasEvents || hasAlerts || isInsights;
        scope.hideTimeframeSelector = isInsights;

        function showAs(context) {
            return stateContext === context || $state.includes(`**.${context}.**`);
        }
    }

    return {
        restrict: 'E',
        scope: true,
        link: headerLink,
        template: require('../../views/infrastructure/header.partial.html'),
    };
}]);

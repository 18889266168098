/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './IpAddrGroupController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('IpAddrGroupController', [
'$scope',
'CRUDGridConfig',
'IpAddrGroupCollection',
'l10nService',
function(
    $scope,
    CRUDGridConfig,
    IpAddrGroupCollection,
    l10nService,
) {
    /**
    * Get keys from source bundles for template usage.
    */
    $scope.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    const gridConfig = {
        collection: new IpAddrGroupCollection(),
    };

    const { objectName } = gridConfig.collection;

    gridConfig.id = `${objectName}-list-page`;
    gridConfig.props = { l10nKeys };
    gridConfig.fields = [
        {
            name: 'name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            template: '<span class="sel-ipaddr-grp-name">{{row.getName()}}</span>',
            sortBy: 'name',
        }, {
            name: 'num',
            title: l10nService.getMessage(l10nKeys.columnTitleIpAddressesOrRanges),
            template: `
            <span class="sel-ipaddr-grp-num">
                {{ row.getConfig().apic_epg_name ? "EPG" :
                row.getConfig().country_codes ?
                '${l10nService.getMessage(l10nKeys.countryCodeDataLabel)}' :
                (row.getConfig().addrs.length + row.getConfig().ranges.length +
                row.getConfig().prefixes.length) }}
                </span>`,
        }, {
            name: 'country_code',
            title: l10nService.getMessage(l10nKeys.columnTitleCountryCodesOrEpg),
            template: '<span>' +
                '{{ row.getConfig().country_codes ? row.getConfig().country_codes.join(", ") :' +
                'row.getConfig().apic_epg_name ? row.getConfig().apic_epg_name : "" }}</span>',
        },
    ];

    gridConfig.singleactions = [{
        title: l10nService.getMessage(l10nKeys.actionBtnEdit),
        class: 'icon-pencil-4',
        hidden: row => !row.isEditable() && !row.isClonable(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }];

    $scope.gridConfig = CRUDGridConfig(gridConfig);
}]);

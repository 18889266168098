/**
 * @module NatModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { NatRuleConfigItem } from 'ajs/js/services/config-items/nat-policy';
import { IExpanderAction } from 'ng/shared/components/expander-with-actions';
import './nat-rule.component.less';

@Component({
    selector: 'nat-rule',
    templateUrl: './nat-rule.component.html',
})
export class NatRuleComponent {
    /**
     * NatRuleConfigItem instance.
     */
    @Input() public rule: NatRuleConfigItem;

    /**
     * Fires on delete.
     */
    @Output() public onDelete: EventEmitter<void> = new EventEmitter();

    /**
     * Fires on edit.
     */
    @Output() public onEdit: EventEmitter<NatRuleConfigItem> = new EventEmitter();

    /**
     * List of actions(delete/edit) for rule expander.
     */
    public expanderActions: IExpanderAction[];

    constructor() {
        this.expanderActions = [];
    }

    /** @override */
    public ngOnInit(): void {
        const ruleName = this.rule.getName();

        this.expanderActions = [
            {
                confirmationButtonProps: {
                    actions: [
                        {
                            label: 'Delete',
                            onClick: () => this.handleDelete(),
                        },
                    ],
                    confirmationButtonClassName: 'avi-btn avi-btn-danger',
                },
                confirmationMessage: `Are you sure you want to delete ${ruleName}?`,
                iconClassName: 'sl-icon-trash',
                title: 'Delete',
            }, {
                iconClassName: 'sl-icon-note',
                onClick: () => this.handleEdit(),
                title: 'Edit',
            },
        ];
    }

    /**
     * Returns the state of the enable switch, 1 for enabled, 0 for disabled.
     */
    public getEnableState(): number {
        return this.rule.config.enable ? 1 : 0;
    }

    /**
     * Click handler for the enable switch. Flips the current value.
     */
    public handeEnableClick(): void {
        this.rule.config.enable = !this.rule.config.enable;
    }

    /**
     * Click handler for editing a NAT rule.
     */
    public handleEdit(): void {
        this.onEdit.emit(this.rule);
    }

    /**
     * Click handler for deleting a NAT rule.
     */
    public handleDelete(): void {
        this.onDelete.emit();
    }
}

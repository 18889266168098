/** @module WafModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    Input,
} from '@angular/core';
import { RepeatedMessageItem } from 'ajs/modules/data-model';
import { WafExcludeListEntryConfigItem } from 'ajs/modules/waf';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './waf-exclude-list.l10n';
import './waf-exclude-list.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for displaying the Exclude List of a WAF Rule or Group.
 * @author alextsg
 */
@Component({
    selector: 'waf-exclude-list',
    templateUrl: './waf-exclude-list.component.html',
})
export class WafExcludeListComponent {
    /**
     * WafExcludeListEntryConfigItem RepeatedMessageItem instance.
     */
    @Input()
    public wafExcludeList: RepeatedMessageItem<WafExcludeListEntryConfigItem>;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}

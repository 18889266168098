/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './EventDetails.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc directive
 * @name eventDetails
 * @param {{event_details: Object}} details - Event details object provided by the back-end.
 * @description Event details for the events grid expander. Have two layouts here - one for
 * config-update event when we show two configs along each other with highlighted differences
 * and another one for all other types when we just represent the event details object as a list.
 */
angular.module('aviApp').directive('eventDetails', [
    'objectToHtmlList',
    'l10nService',
    function(
        objectToHtmlList,
        l10nService,
    ) {
        function link(scope, elem) {
            /**
             * Get keys from source bundles for template usage.
             */
            scope.l10nKeys = l10nKeys;

            l10nService.registerSourceBundles(dictionary);

            /**
             * We use here only two event types: "other" and "config-update". Template provides two
             * different layouts for those.
             * @type {string}
             */
            scope.eventType = 'other';

            /**
             * Orders array by serverIp and switches it into hash for Pool's Servers (pool config
             * edit event).
             * @param {Object} config
             * @inner
             */
            function transformUpdateConfigBeforeComparison(config) {
                if ('servers' in config && Array.isArray(config['servers'])) {
                    config.servers = _.reduce(config.servers, function(hash, server) {
                        let serverId = server.ip.addr;

                        if ('port' in server) {
                            serverId += `:${server.port}`;
                        }

                        hash[serverId] = server;

                        return hash;
                    }, {});
                }
            }

            if (scope.details.event_details['config_update_details']) {
                scope.eventType = 'config-update';

                const newConfig = scope.details
                    .event_details['config_update_details'].old_resource_data &&
                    JSON.parse(scope.details
                        .event_details['config_update_details'].new_resource_data) ||
                        {};

                const oldConfig = scope.details
                    .event_details['config_update_details'].old_resource_data &&
                    JSON.parse(scope.details
                        .event_details['config_update_details'].old_resource_data) ||
                        {};

                [oldConfig, newConfig].forEach(transformUpdateConfigBeforeComparison);

                scope.prevConfig = oldConfig;
                scope.newConfig = newConfig;
            } else if (scope.details.event_details.crs_update_details ||
            scope.details.event_details.crs_deployment_failure ||
            scope.details.event_details.crs_deployment_success
            ) {
                const {
                    event_details: eventDetails,
                    event_description: eventDescription,
                } = scope.details;
                const crsDetails =
                eventDetails.crs_update_details ||
                eventDetails.crs_deployment_failure ||
                eventDetails.crs_deployment_success;

                scope.eventType = 'crs-update';
                scope.crsList = crsDetails.crs_info;
                scope.eventDescription = eventDescription;
            } else {
                const {
                    event_details: eventDetails,
                    event_description: description,
                } = scope.details;
                const [eventDetailKey] = Object.keys(eventDetails);
                const eventDetailsCopy = angular.copy(eventDetails);

                if (scope.details.event_details['avi_cloud_crs_details']) {
                    scope.crsUrl = eventDetailsCopy['avi_cloud_crs_details'].url;
                    delete eventDetailsCopy['avi_cloud_crs_details'].url;
                }

                scope.description = description;

                scope.eventDetailKey = eventDetailKey;
                scope.eventDetailValue = objectToHtmlList(eventDetailsCopy[eventDetailKey]);
            }

            //when used inside grid expander
            elem.on('$destroy', function() {
                scope.$destroy();
            });
        }

        return {
            restrict: 'E',
            scope: {
                details: '=',
            },
            link,
            templateUrl: 'src/views/application/partials/event-details.html',
        };
    }]);

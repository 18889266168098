/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './PoolGroupListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('PoolGroupListController', [
'CRUDGridConfig',
'PoolGroupCollection',
'l10nService',
function(
    CRUDGridConfig,
    PoolGroupCollection,
    l10nService,
) {
    this.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    this.gridConfig = new CRUDGridConfig();
    this.gridConfig.collection = new PoolGroupCollection();

    const { objectName } = this.gridConfig.collection;

    this.gridConfig.id = `${objectName}-list-page`;

    this.gridConfig.fields = [{
        name: 'data.config.name',
        title: l10nService.getMessage(l10nKeys.columnTitleName),
        sortBy: 'name',
    }, {
        name: 'number_of_pools',
        title: l10nService.getMessage(l10nKeys.columnTitleNumberOfPools),
        template: '{{ row.data.config.members.length || 0 }}',
    }, {
        name: 'data.virtualservices',
        title: l10nService.getMessage(l10nKeys.columnTitleVirtualServices),
        transform(row) {
            const { virtualservices } = row.data;

            return _.pluck(virtualservices, 'name').join(', ');
        },
    }, {
        name: 'cloud',
        title: l10nService.getMessage(l10nKeys.columnTitleCloud),
        template: '{{ row.getCloudRef() | name }}',
        visibility: 'optional',
    }];

    this.gridConfig.expandedContainerTemplate = '<pool-group-list-expander row="row"/>';
}]);

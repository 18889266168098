const moduleName = 'waf';
const componentName = 'waf-learning-link';
const prefix = `${moduleName}.${componentName}`;

export const title = `${prefix}.title`;
export const enabledLearningStatusLabel = `${prefix}.enabledLearningStatusLabel`;
export const disabledLearningStatusLabel = `${prefix}.disabledLearningStatusLabel`;
export const minUpdateIntervalText = `${prefix}.minUpdateIntervalText`;
export const emptyUpdateIntervalText = `${prefix}.emptyUpdateIntervalText`;
export const updateIntervalSettingLabel = `${prefix}.updateIntervalSettingLabel`;
export const samplingPercentageEmptyText = `${prefix}.samplingPercentageEmptyText`;
export const samplingPercentageSettingLabel = `${prefix}.samplingPercentageSettingLabel`;
export const enableWafLearningWarningText = `${prefix}.enableWafLearningWarningText`;
export const viewLearningDataButtonLabel = `${prefix}.viewLearningDataButtonLabel`;

export const ENGLISH = {
    [title]: 'WAF LEARNING',
    [enabledLearningStatusLabel]: 'Enabled',
    [disabledLearningStatusLabel]: 'Disabled',
    [minUpdateIntervalText]: '{0} Min',
    [emptyUpdateIntervalText]: 'N/A',
    [updateIntervalSettingLabel]: 'Update Intervals',
    [samplingPercentageEmptyText]: 'N/A',
    [samplingPercentageSettingLabel]: 'Sampling',
    [enableWafLearningWarningText]: 'To use this feature enable WAF Learning in WAF Policy.',
    [viewLearningDataButtonLabel]: 'VIEW LEARNING DATA',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'string-group-create-controller';
const prefix = `${moduleName}.${componentName}`;

export const editStringGroupModalHeader = `${prefix}.editStringGroupModalHeader`;
export const newStringGroupModalHeader = `${prefix}.newStringGroupModalHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const keyValuePairInputLabel = `${prefix}.keyValuePairInputLabel`;
export const mapInformationHeader = `${prefix}.mapInformationHeader`;
export const stringInformationHeader = `${prefix}.stringInformationHeader`;
export const keyInputLabel = `${prefix}.keyInputLabel`;
export const stringInputLabel = `${prefix}.stringInputLabel`;
export const valueInputLabel = `${prefix}.valueInputLabel`;
export const addMapButtonLabel = `${prefix}.addMapButtonLabel`;
export const addStringButtonLabel = `${prefix}.addStringButtonLabel`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;
export const stringColumnLabel = `${prefix}.stringColumnLabel`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const keyColumnLabel = `${prefix}.keyColumnLabel`;
export const valueColumnLabel = `${prefix}.valueColumnLabel`;
export const duplicateValuesAlert = `${prefix}.duplicateValuesAlert`;
export const replaceDuplicateConfirmation = `${prefix}.replaceDuplicateConfirmation`;
export const discardedCurrentValuesConfirmation = `${prefix}.discardedCurrentValuesConfirmation`;

export const ENGLISH = {
    [editStringGroupModalHeader]: 'Edit String Group: {0}',
    [newStringGroupModalHeader]: 'New String Group: {0}',
    [nameInputLabel]: 'Name',
    [keyValuePairInputLabel]: 'Key Value Pair',
    [mapInformationHeader]: 'Map Information',
    [stringInformationHeader]: 'String Information',
    [keyInputLabel]: 'Key',
    [stringInputLabel]: 'String',
    [valueInputLabel]: 'Value',
    [addMapButtonLabel]: 'Add Map',
    [addStringButtonLabel]: 'Add String',
    [cancelButtonLabel]: 'Cancel',
    [saveButtonLabel]: 'Save',
    [stringColumnLabel]: 'String',
    [removeButtonLabel]: 'Remove',
    [keyColumnLabel]: 'Key',
    [valueColumnLabel]: 'Value',
    [duplicateValuesAlert]: 'One or more strings being imported already existed. Those have been over-written by the newly provided instances of the strings.',
    [replaceDuplicateConfirmation]: 'String already exists. Do you want to replace the existing one anyway?',
    [discardedCurrentValuesConfirmation]: 'Current values will be discarded. Are you sure you want to continue?',
};

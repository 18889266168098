/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'response-policy';
const prefix = `${moduleName}.${componentName}`;

export const ruleNameDuplicatedMessage = `${prefix}.ruleNameDuplicatedMessage`;
export const ruleNameAlreadyInUseMessage = `${prefix}.ruleNameAlreadyInUseMessage`;
export const clientIpMatchLabel = `${prefix}.clientIpMatchLabel`;
export const servicePortMatchLabel = `${prefix}.servicePortMatchLabel`;
export const protocolTypeMatchLabel = `${prefix}.protocolTypeMatchLabel`;
export const httpMethodMatchLabel = `${prefix}.httpMethodMatchLabel`;
export const httpVersionMatchLabel = `${prefix}.httpVersionMatchLabel`;
export const pathMatchLabel = `${prefix}.pathMatchLabel`;
export const queryMatchLabel = `${prefix}.queryMatchLabel`;
export const headersMatchLabel = `${prefix}.headersMatchLabel`;
export const cookieMatchLabel = `${prefix}.cookieMatchLabel`;
export const hostHeaderMatchLabel = `${prefix}.hostHeaderMatchLabel`;
export const locationHeaderMatchLabel = `${prefix}.locationHeaderMatchLabel`;
export const httpStatusMatchLabel = `${prefix}.httpStatusMatchLabel`;
export const responseHeaderMatchLabel = `${prefix}.responseHeaderMatchLabel`;
export const modifyHeaderActionLabel = `${prefix}.modifyHeaderActionLabel`;
export const rewriteLocationHeaderActionLabel = `${prefix}.rewriteLocationHeaderActionLabel`;
export const createRuleActionLabel = `${prefix}.createRuleActionLabel`;
export const deleteRuleActionLabel = `${prefix}.deleteRuleActionLabel`;
export const menuActionLabel = `${prefix}.menuActionLabel`;
export const addHttpResponseRuleHeader = `${prefix}.addHttpResponseRuleHeader`;
export const matchingRulesHeader = `${prefix}.matchingRulesHeader`;
export const actionHeader = `${prefix}.actionHeader`;

export const ENGLISH = {
    [ruleNameDuplicatedMessage]: '{0} - duplicated',
    [ruleNameAlreadyInUseMessage]: 'Rule name already in use',
    [clientIpMatchLabel]: 'Client IP',
    [servicePortMatchLabel]: 'Service Port',
    [protocolTypeMatchLabel]: 'Protocol Type',
    [httpMethodMatchLabel]: 'HTTP Method',
    [httpVersionMatchLabel]: 'HTTP Version',
    [pathMatchLabel]: 'Path',
    [queryMatchLabel]: 'Query',
    [headersMatchLabel]: 'Headers',
    [cookieMatchLabel]: 'Cookie',
    [hostHeaderMatchLabel]: 'Host Header',
    [locationHeaderMatchLabel]: 'Location Header',
    [httpStatusMatchLabel]: 'HTTP Status',
    [responseHeaderMatchLabel]: 'Response Header',
    [modifyHeaderActionLabel]: 'Modify Header',
    [rewriteLocationHeaderActionLabel]: 'Rewrite Location Header',
    [createRuleActionLabel]: 'Create rule',
    [deleteRuleActionLabel]: 'Delete',
    [menuActionLabel]: 'Menu',
    [addHttpResponseRuleHeader]: 'Add HTTP Response Rule',
    [matchingRulesHeader]: 'Matching Rules',
    [actionHeader]: 'Action',
};

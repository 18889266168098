/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name MetricsDimensionListCollDataSource
 * @description
 *
 *   Setting params for analytics/metrics GET call. Reloads list on Timeframe change event.
 */
angular.module('avi/vs/security').factory('MetricsDimensionListCollDataSource', [
'ListCollDataSource', 'Timeframe',
function(ListCollDataSource, Timeframe) {
    class MetricsDimensionListCollDataSource extends ListCollDataSource {
        constructor(args) {
            super(args);

            this.protectedUpdateInterval_ = true;
            this.updateInterval_ = 60;

            this.setStepAndLimitParams_();
        }

        /**
         * To get timeseries for each dimensionId we need to pass a list of dimension with
         * 'metric_timestamp' entry. When we want aggregated data it has to be skipped.
         * @override
         **/
        getRequestParams_(requestType) {
            const
                params = super.getRequestParams_(requestType),
                { owner_: collection } = this;

            params['dimensions'] = `metric_timestamp,${collection.dimension_}`;

            return params;
        }

        /**
         * Sets step and limit DS parameters based on currently selected Timeframe.
         * @protected
         */
        setStepAndLimitParams_() {
            const { step, limit } = Timeframe.selected();

            this.params_['step'] = step;
            this.params_['limit'] = limit;
        }

        /** @override */
        onTimeframeChange_() {
            super.onTimeframeChange_();
            this.setStepAndLimitParams_();
            this.load();
        }
    }

    angular.extend(MetricsDimensionListCollDataSource.prototype, {
        hasSearch: false,
        hasSorting: false,
        hasPagination: false,
        hasTotalNumberOfItems: false,
        defaultParams_: {
            metric_id: 'dos.avg_attack_count',
            dimension_aggregation: 'avg',
            pad_missing_data: false,
        },
    });

    return MetricsDimensionListCollDataSource;
}]);

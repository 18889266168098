/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').directive('ipOrGroupList', [
'$timeout', 'IpAddrGroupCollection', 'Regex', 'RangeParser',
function($timeout, IpAddrGroupCollection, Regex, RangeParser) {
    return {
        scope: {
            addrs: '=',
            ranges: '=',
            prefixes: '=',
            groups: '=',
            ngRequired: '=',
            ngDisabled: '=',
            hideOr: '=',
            placeholder: '@',
        },
        restrict: 'E',
        templateUrl: 'src/views/components/ip-or-group-list.html',
        link(scope, elm, attr) {
            scope.ipAddrGroupCollection = new IpAddrGroupCollection();
            scope.all = {};
            scope.Regex = Regex;
            scope.isRequired = !_.isUndefined(attr['required']);

            let combineAllowed = true;

            scope.combineSourceData = function() {
                if (!combineAllowed) {
                    return;
                }

                scope.all.items = _.map(scope.addrs, function(item) {
                    return {
                        type: 'custom',
                        data: item.addr,
                    };
                })
                    .concat(_.map(scope.ranges, function(item) {
                        return {
                            type: 'custom',
                            data: `${item.begin.addr}-${item.end.addr}`,
                        };
                    }))
                    .concat(_.map(scope.prefixes, function(item) {
                        return {
                            type: 'custom',
                            data: `${item.ip_addr.addr}/${item.mask}`,
                        };
                    }))
                    .concat(_.map(scope.groups, function(ref) {
                        return {
                            type: 'group',
                            data: ref,
                        };
                    }));

                // By default there should be at least 1 item
                if (!scope.all.items || !scope.all.items.length) {
                    scope.all.items = [{
                        type: 'custom',
                        data: '',
                    }];
                }
            };

            scope.combineSourceData();

            scope.splitIpAndGroups = function() {
                combineAllowed = false;
                scope.addrs = [];
                scope.ranges = [];
                scope.prefixes = [];
                scope.groups = [];

                _.each(scope.all.items, function(item) {
                    if (!item || !item.data) {
                        return;
                    }

                    if (item.type == 'custom') {
                        const ips = item.data.split(',');

                        ips.forEach(function(ip) {
                            const rangeOrIp = RangeParser.ipRange2Json(ip.trim());

                            if (rangeOrIp) {
                                if (rangeOrIp.begin) { // assume scope is range
                                    scope.ranges.push(rangeOrIp);
                                } else if (rangeOrIp.mask) { // assume scope is prefix
                                    scope.prefixes.push(rangeOrIp);
                                } else {
                                    scope.addrs.push(rangeOrIp);
                                }
                            }
                        });
                    } else {
                        scope.groups.push(item.data);
                    }
                });

                $timeout(function() {
                    combineAllowed = true;
                });
            };
        },
    };
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import ErrorStackParser from 'error-stack-parser';

/**
 * @ngdoc service
 * @name ErrorStackParser
 * @description
 *
 *     Wrapper for error-stack-parser dependency.
 */
angular.module('avi/deps').factory('ErrorStackParser', () => ErrorStackParser);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './generate-sso-token-message.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name generateSsoTokenMessage
 * @description Message that allows a user to generate an authentication token.
 * @param {Function} onCloseMessage - Called when the message is closed.
 */
class GenerateSsoTokenMessageController {
    constructor($http, Auth, l10nService) {
        this._$http = $http;
        this._Auth = Auth;
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this.generated = false;
        this.user = this._Auth.getUsername();
        this.data = {};
        this.error = null;
    }

    /**
     * Makes a request to generate an authentication token.
     */
    generateToken() {
        const payload = {
            hours: this.hours,
        };

        this.data = {};
        this.error = null;
        this._$http.post('/api/user-token', payload)
            .then(({ data }) => {
                this.generated = true;
                this.data = data;
            })
            .catch(({ data }) => this.error = data.error);
    }

    /**
     * Handles Input element click event to select all text inside the element.
     * @param {MouseEvent} event
     */
    focusInput(event) {
        const { target } = event;

        if (target instanceof HTMLInputElement) {
            target.trigger('focus');
            target.select();
        }
    }
}

GenerateSsoTokenMessageController.$inject = [
    '$http',
    'Auth',
    'l10nService',
];

angular.module('aviApp').component('generateSsoTokenMessage', {
    bindings: {
        onCloseMessage: '&',
    },
    controller: GenerateSsoTokenMessageController,
    templateUrl: 'src/components/messages/generate-sso-token-message/' +
            'generate-sso-token-message.html',
});

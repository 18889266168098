/** @module GslbModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { Collection } from 'ajs/modules/data-model';
import { DevLoggerService } from 'ng/modules/core';

const allDataSources = {
    list: {
        source: 'GSLBServicePoolMembersInventoryCollDataSource',
        transformer: 'GSLBServicePoolMembersInventoryDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config', 'runtime'],
    },
};

/**
 * @description
 *
 *     Collection of GSLBPoolMembers presented by GSLBService inventory API. Updated list is not
 *     merged into existent but is rendered from scratch and all Items we had before that get
 *     removed.
 * @author Ram Pal
 */
export class GSLBPoolMemberCollection extends Collection {
    public readonly gslbServiceId: string;
    private readonly devLoggerService: DevLoggerService;

    constructor(args: any = {}) {
        super(args);

        this.itemClass_ = this.getAjsDependency_('GSLBPoolMember');
        this.devLoggerService = this.getAjsDependency_('devLoggerService');

        if (args.gslbServiceId) {
            this.gslbServiceId = args.gslbServiceId;
        } else {
            this.devLoggerService
                .warn('gslbServiceId is needed to instantiate GSLBPoolMemberCollection collection');
        }
    }
}

Object.assign(GSLBPoolMemberCollection.prototype, {
    objectName_: 'gslbServiceMember',
    allDataSources_: allDataSources,
    isStatic_: false,
});

GSLBPoolMemberCollection.ajsDependencies = [
    'GSLBPoolMember',
    'devLoggerService',
];

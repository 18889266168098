/**
 * @module CloudModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { IDataNetworkConfig, NsxtTransportZoneType } from 'generated-types';
import {
    IMessageItemData,
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model';
import { NsxtTier1SegmentConfigConfigItem, Tier1LogicalRouterInfoConfigItem } from '.';

type TDataNetworkConfigConfigPartial = Omit<IDataNetworkConfig, 'tier1_segment_config'>;

interface IDataNetworkConfigConfig extends TDataNetworkConfigConfigPartial {
    tier1_segment_config?: NsxtTier1SegmentConfigConfigItem;
}

interface IDataNetworkConfigConfigData extends IMessageItemData {
    config: IDataNetworkConfigConfig;
}

export const DATA_NETWORK_CONFIG_CONFIG_ITEM_TOKEN = 'DataNetworkConfigConfigItem';

export class DataNetworkConfigConfigItem extends MessageItem {
    public data: IDataNetworkConfigConfigData;

    /** @constructor */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'DataNetworkConfig',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter for data transport zone type overlay or vlan.
     * Return undefined if not configured.
     */
    public get transportZoneType(): NsxtTransportZoneType | undefined {
        return this.config.tz_type;
    }

    /**
     * Setter for transport zone type.
     */
    public set transportZoneType(type: NsxtTransportZoneType) {
        this.config.tz_type = type;
    }

    /**
     * Getter for the name of transport zone path for avi service engines.
     * Return undefined if not configured.
     */
    public get transportZone(): string | undefined {
        return this.config.transport_zone;
    }

    /**
     * Setter for transport zone.
     */
    public set transportZone(transportZone: string) {
        this.config.transport_zone = transportZone;
    }

    /**
     * Getter for tier1 segment config item.
     * Defined only when transport zone type is OVERLAY.
     */
    public get tier1SegmentConfig(): NsxtTier1SegmentConfigConfigItem | undefined {
        return this.config.tier1_segment_config;
    }

    /**
     * Getter for Tier1LogicalRouterInfo RepeatedMessageItem under manual mode of tier1_segment.
     * Defined only when transport zone type is OVERLAY.
     */
    public get manualTier1LRs(): RepeatedMessageItem<Tier1LogicalRouterInfoConfigItem> | undefined {
        return this.tier1SegmentConfig?.manualModeConfig.config.tier1_lrs;
    }

    /**
     * Check current transport zone type against the given one.
     */
    public isTransportZoneType(type: NsxtTransportZoneType): boolean {
        return this.transportZoneType === type;
    }

    /**
     * Remove the configured transport zone and related data.
     */
    public clearTransportZoneData(): void {
        this.config.transport_zone = undefined;
        this.config.tz_type = undefined;

        this.onTransportZoneChange();
    }

    /**
     * Set or remove fields when transport zone type changes.
     */
    public onTransportZoneChange(): void {
        this.resetFieldsByTranportZoneType();
    }

    /**
     * Reset fields that are mutually exclusive based on transport zone type.
     */
    private resetFieldsByTranportZoneType(): void {
        const { tz_type: tzType } = this.config;

        switch (tzType) {
            case NsxtTransportZoneType.OVERLAY:
                if (this.tier1SegmentConfig) {
                    this.tier1SegmentConfig.removeManualTier1LRs();
                } else {
                    this.safeSetNewChildByField('tier1_segment_config');
                }

                delete this.config.vlan_segments;
                break;

            case NsxtTransportZoneType.VLAN:
                this.config.vlan_segments = [];
                delete this.config.tier1_segment_config;
                break;

            default:
                delete this.config.vlan_segments;
                delete this.config.tier1_segment_config;
        }
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @typedef {Object} Cluster
 * @description  Cluster item
 */
function clusterFactory(Item) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Item
     * @author Ashish Verma
     * @param oArgs {Object} - Configuration object for creation of an {@link Item}.
     * @desc Cluster Item. Doesn't have a real id (have only one in a system).
     */
    class Cluster extends Item {
        /** @override */
        getIdFromData_() {
            return 'default';//fakeId for single instance
        }

        /** @override */
        urlToSave() {
            return `/api/${this.objectName}?include_name`;
        }

        /** @override */
        loadConfig() {
            const
                params = this.getLoadParams(),
                headers = this.getLoadHeaders_();

            this.cancelRequests('config');

            const api = `/api/${this.objectName + (params.length ? `?${params.join('&')}` : '')}`;

            return this.request('get', api, undefined, headers, 'config')
                .then(rsp => {
                    if (rsp.data.config) {
                        _.each(rsp.data, (prop, name) => {
                            this.data[name] = prop;
                        });
                    } else {
                        this.data.config = rsp.data;
                    }

                    // There should be at least 3 nodes
                    const data = this.data.config;
                    const { nodes } = data;
                    const len = nodes.length;

                    if (len < 3) {
                        for (let i = len; i < 3; i++) {
                            nodes[i] = {};
                        }
                    }

                    return rsp;
                });
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.data.config);

            config.nodes = _.filter(config.nodes, node => {
                let res = false;

                if (node.ip && node.ip.addr) {
                    if (!node.name) {
                        node.name = node.ip.addr;
                    }

                    res = true;
                }

                return res;
            });

            return config;
        }
    }

    Object.assign(Cluster.prototype, {
        objectName: 'cluster',
        windowElement: 'cluster-edit',
    });

    return Cluster;
}

clusterFactory.$inject = [
    'Item',
];

angular.module('avi/app').factory('Cluster', clusterFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function certificateManagementCollectionFactory(Collection, CertificateManagement) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Collection
     * @author Ram Pal
     * @desc Collection of {@link module:avi/app.CertificateManagement CertificateManagements}.
     */
    class CertificateManagementCollection extends Collection {}

    Object.assign(CertificateManagementCollection.prototype, {
        objectName_: 'certificatemanagementprofile',
        itemClass_: CertificateManagement,
        windowElement_: 'prof-certificate-management-create',
    });

    return CertificateManagementCollection;
}

certificateManagementCollectionFactory.$inject = [
    'Collection',
    'CertificateManagement',
];

angular.module('avi/app')
    .factory('CertificateManagementCollection', certificateManagementCollectionFactory);

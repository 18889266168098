const moduleName = 'ajs-legacy';
const componentName = 'user-credentials-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleCredentialType = `${prefix}.columnTitleCredentialType`;
export const verifyHostBtnLabel = `${prefix}.verifyHostBtnLabel`;
export const downloadPublicKeyBtnLabel = `${prefix}.downloadPublicKeyBtnLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleCredentialType]: 'Credentials Type',
    [verifyHostBtnLabel]: 'Verify Host',
    [downloadPublicKeyBtnLabel]: 'Download Public Key',
};

const moduleName = 'ajs-legacy';
const componentName = 'app-about-modal';
const prefix = `${moduleName}.${componentName}`;

export const aboutAviVantageTitle = `${prefix}.aboutAviVantageTitle`;
export const aviVantageLabel = `${prefix}.aviVantageLabel`;
export const versionLabel = `${prefix}.versionLabel`;
export const buildLabel = `${prefix}.buildLabel`;
export const dateLabel = `${prefix}.dateLabel`;
export const controllerPatchVersionLabel = `${prefix}.controllerPatchVersionLabel`;

export const ENGLISH = {
    [aboutAviVantageTitle]: 'About Avi Vantage',
    [aviVantageLabel]: 'Avi Vantage',
    [versionLabel]: 'Version: {0}',
    [buildLabel]: 'Build: {0}',
    [dateLabel]: 'Date: {0}',
    [controllerPatchVersionLabel]: 'Controller patch version: {0}',
};

/**
 * Module for customer portal
 * @module avi/cportal
 * @preferred
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';

import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import { IBaseRequestPromise, Item } from 'ajs/modules/data-model';

interface IPortalAssetContact {
    account_id: string;
    phone: string;
    email: string;
    name: string;
}

interface IPortalProactiveSupportDefaults {
    attach_core_dump: boolean;
    attach_tech_support: boolean;
    case_severity: string;
}

interface IPortalFeatureOptInStatus {
    enable_auto_download_waf_signatures: boolean;
    enable_waf_signatures_notifications: boolean;
    enable_auto_case_creation_on_se_failure: boolean;
    enable_auto_case_creation_on_system_failure: boolean;
}

interface IPortalInfo {
    asset_contact: IPortalAssetContact;
    url: string;
    uuid: string;
    proactive_support_defaults: IPortalProactiveSupportDefaults;
    portal_url: string;
    feature_opt_in_status: IPortalFeatureOptInStatus;
    polling_interval: number;
}

/**
 * @desc portalInfo Item.
 * @author Ashish Verma
 */
class PortalInfo extends Item<IPortalInfo> {
    constructor(args: {}) {
        const extendedArgs = {
            ...args,
            id: 'default',
            objectName: 'albservicesconfig',
            windowElement: 'cportal-info-modal',
        };

        super(extendedArgs);
    }

    /**
     * Return asset contact
     */
    public getAssetContact(): IPortalAssetContact {
        const config = this.getConfig();

        return config.asset_contact;
    }

    /**
     * Returns a API URI to save an object.
     * @override
     */
    public urlToSave(): string {
        return `/api/${this.objectName}`;
    }

    /**
     * Load portalInfo config
     * @override
     */
    protected loadConfig(): IBaseRequestPromise<IPortalInfo> {
        const url = `/api/${this.objectName}`;
        const requestConfig = {
            group: 'config',
            method: 'GET',
            url,
        };

        this.cancelRequests('config');

        return this.request<IPortalInfo>(requestConfig)
            .then(this.onConfigLoad_.bind(this));
    }
}

initAjsDependency(
    angular.module('avi/cportal'),
    'factory',
    'PortalInfo',
    PortalInfo,
);

export { PortalInfo };

/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

import { isNumber } from 'underscore';

/**
 * Returns validatorFunction for configFieldInputValidation directive.
 *
 * @author Aravindh Nagarajan
 */
export function configFieldInputValidator(min = -Infinity, max = Infinity): ValidatorFn {
    /**
     * Returns null if it's a valid value (>= min && <= max).
     * Returns validationError if input is invalid.
     */
    return (control: AbstractControl): ValidationErrors | null => {
        const { value } = control;

        const errorObject = {
            configFieldInputValidation: value,
        };

        if (isNumber(min) && value < min) {
            return errorObject;
        }

        if (isNumber(max) && value > max) {
            return errorObject;
        }

        return null;
    };
}

const moduleName = 'ajs-legacy';
const componentName = 'cloud-status-modal-controller';
const prefix = `${moduleName}.${componentName}`;

export const cloudStatusDetailsHeader = `${prefix}.cloudStatusDetailsHeader`;
export const loadingLabel = `${prefix}.loadingLabel`;
export const unableAccessEsxHostsHeader = `${prefix}.unableAccessEsxHostsHeader`;
export const columnTitleHostName = `${prefix}.columnTitleHostName`;
export const columnTitlePowerStatus = `${prefix}.columnTitlePowerStatus`;
export const columnTitleMaintenanceMode = `${prefix}.columnTitleMaintenanceMode`;
export const columnTitleConnectedToVcenter = `${prefix}.columnTitleConnectedToVcenter`;
export const columnTitleAccessibleFromController = `${prefix}.columnTitleAccessibleFromController`;
export const columnTilteQuarantined = `${prefix}.columnTilteQuarantined`;
export const onStatusLabel = `${prefix}.onStatusLabel`;
export const offStatusLabel = `${prefix}.offStatusLabel`;
export const yesStatusLabel = `${prefix}.yesStatusLabel`;
export const noStatusLabel = `${prefix}.noStatusLabel`;
export const errorsInManagementNetworkHeader = `${prefix}.errorsInManagementNetworkHeader`;
export const overlappingSubnetsHeader = `${prefix}.overlappingSubnetsHeader`;
export const columnTitleNetworkName = `${prefix}.columnTitleNetworkName`;
export const columnTitleSubnet = `${prefix}.columnTitleSubnet`;

export const ENGLISH = {
    [cloudStatusDetailsHeader]: 'Cloud Status Details',
    [loadingLabel]: 'Loading...',
    [unableAccessEsxHostsHeader]: 'Unable to Access {0} ESX Hosts',
    [columnTitleHostName]: 'Host Name',
    [columnTitlePowerStatus]: 'Power Status',
    [columnTitleMaintenanceMode]: 'Maintenance Mode',
    [columnTitleConnectedToVcenter]: 'Connected to vCenter',
    [columnTitleAccessibleFromController]: 'Accessible from Controller',
    [columnTilteQuarantined]: 'Quarantined',
    [onStatusLabel]: 'ON',
    [offStatusLabel]: 'OFF',
    [yesStatusLabel]: 'Yes',
    [noStatusLabel]: 'No',
    [errorsInManagementNetworkHeader]: 'Errors in Management Network',
    [overlappingSubnetsHeader]: 'Overlapping Subnets',
    [columnTitleNetworkName]: 'Network Name',
    [columnTitleSubnet]: 'Subnet',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './dropdown-list-option.component.less';

const template = '<div class="dropdown-list-option" ' +
    'ng-click="$ctrl.onClick({ value: $ctrl.option.value })">{{ ::$ctrl.option.label }}</div>';

/**
 * @constructor
 * @memberOf module:avi/component-kit
 * @mixes module:avi/component-kit.dropdownListOptionBindings
 */
class DropdownListOptionComponentController {}

/**
 * @mixin dropdownListOptionComponentBindings
 * @memberOf module:avi/component-kit
 * @property {module:avi/component-kit.DropdownListOption} option - Option object.
 * @property {Function} onClick - Function to be called with option value.
 */

/**
 * @name DropdownListOptionComponent
 * @memberOf module:avi/component-kit
 * @property {module:avi/component-kit.dropdownListOptionComponentBindings} bindings
 * @property {module:avi/component-kit.DropdownListOptionComponentController} controller
 * @description
 *
 *     The component to build a default dropdown list option.
 *
 * @see {@link module:avi/component-kit.DropdownListComponentController}
 * @author Zhiqian Liu
 */
angular.module('avi/component-kit').component('dropdownListOption', {
    bindings: {
        option: '<',
        onClick: '&',
    },
    controller: DropdownListOptionComponentController,
    template,
});

/**
 * @module avi/core
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    DialogService,
    DIALOG_SERVICE_TOKEN,
    FullModalService,
    NotificationService,
} from 'ng/modules/core';

export const USER_SESSION_EVENT_LISTENERS_SERVICE_TOKEN = 'userSessionEventListenersService';

/**
 * @desc Service that sets Angular service listeners on the AJS $rootScope to perform a clean-up on
 *     user logout or setContext changes.
 * @author alextsg
 */
export class UserSessionEventListenersService {
    constructor(
        private readonly $rootScope: ng.IRootScopeService,
        private readonly fullModalService: FullModalService,
        private readonly dialogService: DialogService,
        private readonly notificationService: NotificationService,
    ) {}

    /**
     * Sets listeners on $rootScope to remove FullModal modals.
     */
    public setListeners(): void {
        this.$rootScope.$on('userLoggedOut', this.reset);
        this.$rootScope.$on('setContext', this.reset);
    }

    /**
     * Resets Angular services.
     */
    private reset = (): void => {
        this.fullModalService.removeAllModals();
        this.dialogService.removeAll();
        this.notificationService.removeAll();
    };
}

UserSessionEventListenersService.$inject = [
    '$rootScope',
    'fullModalService',
    DIALOG_SERVICE_TOKEN,
    'notificationService',
];

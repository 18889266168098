/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { AjsDependency } from 'ajs/js/utilities/ajsDependency';
import { uiOnlyPermissions } from 'ng/common/ui-only-permissions';

interface IHorIframeQueryParams {
    session_id: string;
    csrf_token: string;
    tenant_name: string;
    redirect: string;
    read_only?: string;
    permissions?: string;
}

export interface IHorIframeInitialState {
    name: string;
    params: object;
}

// todo: move to auth.js once that is typescript
export enum ObjectAccessLevel {
    NO_ACCESS = 0,
    READ_ACCESS = 1,
    WRITE_ACCESS = 2,
}

interface IPermissionsHash {
    [key: string]: ObjectAccessLevel;
}

const permissionsRegex = /(\w+),((?:NO|READ|WRITE)_ACCESS)/;

/**
 * @constructor
 * @desc
 *
 *     Service responsible for parsing authentication query params from URL for OpenStack Horizon
 *     (iframe) use case.
 *
 *     When applicable provides convenience methods to retrieve data passed in processed form.
 *
 *     Main idea it that authentication, tenant selection and default state activation will be
 *     performed based on data passed via URL instead of the normal flow. Also some additional
 *     permission restrictions can be applied.
 *
 *     Used by {@link module:avi/core.Auth}.
 *
 * @author Alex Malitsky
 */
export class HorizonIframeService extends AjsDependency {
    private initialized = false;
    private isActive = false;

    private sessionId = '';
    private csrfToken = '';

    // underscore due to having public getter with the same name
    private tenantName_ = '';
    private readOnly = false;
    private state: IHorIframeInitialState | null = null;
    private permissionsHash: IPermissionsHash = null;

    /**
     * Does all the query params parsing on app instantiation. Based on the query params passed
     * service will figure if OS Horizon mode is active or not. Can be called only once.
     * @param queryParams
     */
    public init(queryParams: IHorIframeQueryParams): void {
        if (this.initialized) {
            throw Error('already initialized');
        }

        this.initialized = true;

        const {
            session_id: sessionId,
            csrf_token: token,
            tenant_name: tenantName,
            read_only: readOnly,
            permissions,
            redirect,
        } = queryParams;

        if (sessionId && token && tenantName && redirect) {
            this.state = this.findStateByString(redirect);

            if (this.state) {
                this.isActive = true;
            }
        }

        if (!this.isActive) {
            return;
        }

        this.sessionId = sessionId;
        this.csrfToken = token;
        this.tenantName_ = tenantName;

        // If `read_only` flag is not present in queryParams, set its value to undefined,
        // Since Backend treats `read_only=undefined` to be the same as `read_only=true`.
        // Set `read_only=false` only when its passed as such.
        // see this thread for more info on this:
        // https://github.com/avinetworks/avi-dev/pull/75506#discussion_r556200411
        this.readOnly = readOnly ? readOnly.toLowerCase() === 'true' : undefined;

        this.permissionsHash = this.getPermissionsHash(permissions);
    }

    /**
     * Return state name and params based on the "redirect" path passed via URL query param.
     * @param path
     */
    private findStateByString(path: string): IHorIframeInitialState | null {
        const $window = this.getAjsDependency_('$window');

        let pathUrl = $window.decodeURIComponent(path);

        const questionMarkPos = pathUrl.indexOf('?');

        if (questionMarkPos > -1) {
            pathUrl = pathUrl.slice(0, questionMarkPos);
        }

        const $state = this.getAjsDependency_('$state');
        const states = $state.get();

        let stateName = '';
        let stateParams = null;

        for (const state of states) {
            if (!state.abstract) {
                const $$state = state.$$state();

                // some black magic is going on - internal UI router API
                const params = $$state.url.exec(pathUrl);

                if (params) {
                    ({ name: stateName } = state);
                    stateParams = { ...params };

                    break;
                }
            }
        }

        if (!stateName) {
            return null;
        }

        return {
            name: stateName,
            params: stateParams,
        };
    }

    /**
     * Return permissions hash based on URL query param parsed.
     * @param permissions
     */
    private getPermissionsHash(permissions: string): IPermissionsHash {
        const uiPermissionPrefix = 'UI_';
        const regex = new RegExp(permissionsRegex, 'gi');
        const hash = {};

        let match = null;

        // Strip `UI_` prefix off to get the list of UI-only permissions supported before v20.1.1.
        const oldUIOnlyPermissionSet = new Set(
            uiOnlyPermissions.map(permission => permission.replace(uiPermissionPrefix, '')),
        );

        // eslint-disable-next-line no-cond-assign
        while (match = regex.exec(permissions)) {
            const [, objectType, accessType] = match;

            let permission = objectType;

            // Appends UI_ prefix to old uiOnlyPermissions passed in queryParams
            if (oldUIOnlyPermissionSet.has(objectType)) {
                permission = `${uiPermissionPrefix}${objectType}`;
            }

            // TODO check for garbage permission types
            hash[permission] = accessType;
        }

        return hash;
    }

    /**
     * Return permissions hash stored by the service.
     */
    public get permissions(): IPermissionsHash {
        return this.permissionsHash;
    }

    /**
     * Return true when OS Horizon iframe mode is used.
     */
    public get active(): boolean {
        return this.isActive;
    }

    /**
     * Return payload for the login API call. Instead of username and password sessionId and
     * CSRF token will be used. ReadOnly param will be kept by the user's session on the backend.
     */
    public get loginPayload(): object {
        const {
            sessionId,
            csrfToken,
            readOnly,
        } = this;

        return {
            csrf_token: csrfToken,
            read_only: readOnly,
            session_id: sessionId,
        };
    }

    /**
     * Return app state user should be redirected to upon login.
     */
    public get initialAppState(): IHorIframeInitialState {
        return this.state;
    }

    /**
     * Return tenant name to be selected upon login.
     */
    public get tenantName(): string {
        return this.tenantName_;
    }
}

HorizonIframeService.ajsDependencies = [
    '$window',
    '$state',
];

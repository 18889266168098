/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './virtualserviceSecurityDashboard.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  virtualserviceSecurityDashboard
 * @description  Displays metrics related to a Virtual Service in the Security page.
 */
/** @alias virtualserviceSecurityDashboard */
function virtualserviceSecurityDashboardController(
        $templateCache,
        ChartConfig,
        AviModal,
        DisplayValue,
        myAccount,
        l10nService,
) {
    this.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    const metrics = [{
        // L4 Attacks and L7 Attacks
        name: 'avg_attack_count',
        series: [
                'l4_client.avg_network_dos_attacks',
                'l4_client.avg_application_dos_attacks',
        ],
    }, {
        // Attack Duration
        name: 'dos.sum_attack_duration',
        series: 'dos.sum_attack_duration',
        dimension_aggregation: 'METRICS_DIMENSION_AGG_SUM',
    }, {
        // Blocked Connections
        name: 'l4_client.avg_policy_drops',
        series: 'l4_client.avg_policy_drops',
    }, {
        // SSL Dashboard Stats
        // Failed Transactions
        name: 'l7_client.pct_ssl_failed_connections',
        series: 'l7_client.pct_ssl_failed_connections',
    }, {
        // Failed Transactions
        name: 'l7_client.avg_ssl_errors',
        series: 'l7_client.avg_ssl_errors',
    }, {
        // Certificate Pie Chart
        name: 'certificate_pie_chart',
        series: [
                'l7_client.avg_ssl_rsa_non_pfs',
                'l7_client.avg_ssl_ecdsa_non_pfs',
                'l7_client.avg_ssl_rsa_pfs',
                'l7_client.avg_ssl_ecdsa_pfs',
        ],
    }, {
        name: 'avg_ssl_errors',
        series: [
                'l7_client.avg_ssl_handshake_protocol_errors',
                'l7_client.avg_ssl_handshake_network_errors',
                'l7_client.avg_ssl_handshakes_timedout',
        ],
    }];

    this.certificatePieChartConfig = {
        title: l10nService.getMessage(l10nKeys.certificateTitle),
        metricName: 'certificate_pie_chart',
        series: [
                'l7_client.avg_ssl_rsa_non_pfs',
                'l7_client.avg_ssl_ecdsa_non_pfs',
                'l7_client.avg_ssl_rsa_pfs',
                'l7_client.avg_ssl_ecdsa_pfs',
        ],
        highlight: [{
            title: 'PFS',
            combine: ['l7_client.avg_ssl_ecdsa_pfs', 'l7_client.avg_ssl_rsa_pfs'],
            className: 'chart-color-blue',
        }, {
            title: l10nService.getMessage(l10nKeys.nonPfsTitle),
            combine: ['l7_client.avg_ssl_ecdsa_non_pfs', 'l7_client.avg_ssl_rsa_non_pfs'],
            className: 'chart-color-purple',
        }],
    };

    this.tlsVersionPieChartConfig = {
        title: l10nService.getMessage(l10nKeys.tlsVersionTitle),
        metricName: 'vs_ssl_tls_version',
        series: [
                'l7_client.avg_ssl_ver_tls10',
                'l7_client.avg_ssl_ver_tls11',
                'l7_client.avg_ssl_ver_tls12',
                'l7_client.avg_ssl_ver_tls13',
        ],
    };

    // Tooltip for Failed Transactions
    this.securityTransactionsTooltipTemplate = require(
        './security-transactions-tooltip.partial.html',
    );

    this.$onInit = function() {
        this.config = new ChartConfig([{
            id: '',
            series: [],
        }]);

        const metricsCopy = angular.copy(metrics);

        this.virtualservice.collMetricsSubscribe(metricsCopy)
            .then(() => {
                const metricsNames = _.pluck(metrics, 'name');

                const config = this.virtualservice.createChartsConfig(metricsNames);

                this.config = new ChartConfig(config);

                return this.virtualservice.startCollMetricsAsync();
            });
    };

    /**
     * Returns a rate number and unit based on the series and metric.
     * @param  {string} metricName - Name of the metric that the series belongs to. We need the
     *     metric because when we are interested in the max, we are taking the point in time
     *     when the metric is at max, not the time when the series is at max.
     * @param  {string} seriesName - Name of the series we want to get the rate for.
     * @return {string} Returns the rate and the unit of the series.
     */
    this.getMetricRate = function(metricName, seriesName) {
        const
            { virtualservice: vs } = this,
            metric = vs.getMetric(metricName),
            series = metric.getSeries(seriesName);

        const valuesToDisplayType = myAccount.getValuesDisplayType();

        const displayValue = DisplayValue.createCardValueUnit(series, valuesToDisplayType);
        let { value } = displayValue;

        if (value === 'N/A' || value === 'NA') {
            return l10nService.getMessage(l10nKeys.emptyValueLabel);
        }

        if (valuesToDisplayType === 'max') {
            const seriesStats = series.getStats();

            value = series.getValue('exact', seriesStats.maxTimestamp);
        }

        return `${value ? Math.num2abbrStr(value, 2) : value}${displayValue.unit}`;
    };

    /**
     * Opens SSL modal showing patch notes.
     */
    this.openSSLPatchesModal = function() {
        AviModal.open('virtualservice-security-ssl-patches');
    };

    this.$onDestroy = function() {
        // Don't unsubscribe the first metric as it's used for the sparkline charts.
        this.virtualservice.collMetricsUnSubscribe(metrics.slice(1));
    };
}

virtualserviceSecurityDashboardController.$inject = [
    '$templateCache',
    'ChartConfig',
    'AviModal',
    'DisplayValue',
    'myAccount',
    'l10nService',
];

/**
 * @ngdoc component
 * @name virtualserviceSecurityDashboard
 */
angular.module('aviApp').component('virtualserviceSecurityDashboard', {
    bindings: {
        virtualservice: '<',
    },
    controller: virtualserviceSecurityDashboardController,
    templateUrl: 'src/components/applications/virtualservice/' +
            'virtualservice-security-dashboard/virtualservice-security-dashboard.html',
});

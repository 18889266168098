/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').factory('Backup', ['Item', '$window', (Item, $window) => {
    const Backup = class extends Item {
        /**
         * Makes request to download backup configuration file.
         * @param  {string} type - Used to specify local backup or remote backup. Local by default.
         */
        downloadBackup(type) {
            let location = this.data.config.local_file_url;

            if (type === 'remote') {
                location = this.data.config.remote_file_url;
            }

            $window.location.assign(`/api/fileservice?uri=${location}`);
        }
    };

    angular.extend(Backup.prototype, {
        objectName: 'backup',
        windowElement: 'backup-create',
    });

    return Backup;
}]);

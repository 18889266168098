/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as aviFormItemTemplate from './avi-form-item.component.partial.html';

// TODO: Find a solution to make it work with collection-dropdown upon instantiation
/**
 * @constructor
 * @memberOf module:avi/component-kit
 * @mixes module:avi/component-kit.aviFormItemBindings
 * @see {@link module:avi/component-kit.aviFormItem}
 */
class AviFormItemController {
    constructor() {
        /**
         * Form instance
         * @type {angular.IFormController | null}
         */
        this.form = null;
    }

    /**
     * Returns true when controlle passed is marked as required.
     * @param {Object} control - Control of the fields.
     */
    static isRequired(control) {
        const { required } = control.$validators;

        // no validator means there is no required or ngRequired attribute
        if (!required) {
            return false;
        }

        // btw $$attr is an internal angularJs API
        const { required: requiredAttr } = control.$$attr;

        return Boolean(requiredAttr);
    }

    /**
     * Handle required check.
     * @returns {boolean}
     * @public
     */
    hasRequiredFormControl() {
        const [control] = this.form.$getControls();

        //With collection-dropdown upon instantiation we are getting this value undefined.
        //For those senarios we are returning default value as false.
        if (!control) {
            return false;
        }

        return AviFormItemController.isRequired(control);
    }
}

/**
 * @mixin aviFormItemBindings
 * @memberOf module:avi/component-kit
 * @property {string=} label - name of label.
 * @property {string=} objectType - name of object type.
 * @property {string=} fieldName - name of the field of given object type.
 * @property {string=} description - we can add hard coded Description for form label.
 * @property {string=} controlId - control Id of label for "for" attribute.
 */
const bindings = {
    label: '@?',
    description: '@?',
    objectType: '@?',
    fieldName: '@?',
    controlId: '@?',
};

/**
 * @name aviFormItem
 * @memberOf module:avi/component-kit
 * @property {module:avi/component-kit.AviFormItemController} controller
 * @property {module:avi/component-kit.aviFormItemBindings} bindings
 * @desc Component used to get form item details by providing object Type and
 *       field name of the schema.pb.
 *       We can not use this component with collection-dropdown as it breaks the code.
 * @author Alex Malitsky, Ashish Verma
 * @example <caption>Passing object-type and field-name to get label Description</caption>
 * <avi-form-item
 *      object-type="Cloud"
 *      field-name="name"
 *      label="Name"
 * >
 *      <input
 *          type="text"
 *          ng-pattern="::Regex.objName"
 *          placeholder="Name"
 *          ng-model="editable.getConfig().name"
 *      />
 * </avi-form-item>
 *
 * @example <caption>Passing description to get label Description</caption>
 * <avi-form-item
 *      description="sample description for name"
 *      label="Name"
 * >
 *      <input
 *          type="text"
 *          ng-pattern="::Regex.objName"
 *          placeholder="Name"
 *          ng-model="editable.getConfig().name"
 *      />
 * </avi-form-item>
 */
angular.module('avi/component-kit')
    .component('aviFormItem', {
        controller: AviFormItemController,
        bindings,
        transclude: true,
        template: aviFormItemTemplate,
    });

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @module avi/policies/sso
 */
import * as l10n from './sso-policy-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
/**
 * @constructor
 * @memberOf module:avi/policies/sso
 * @mixes module:avi/policies/sso.ssoPolicyModalComponentBindings
 * @see {@link module:avi/policies/sso.ssoPolicyModalComponent}
 */

class SSOPolicyModalController {
    constructor(
        schemaService,
        Regex,
        l10nService,
    ) {
        /**
         * SSO policy types.
         * @type {EnumValue[]}
         */
        this.policyTypes = schemaService.getEnumValues('SSOPolicyType');

        this.Regex = Regex;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Sets type and updates modal state accordingly.
     * @param {string} type - SSOPolicyType enum.
     */
    setType(type) {
        this.editable.type = type;
    }
}

SSOPolicyModalController.$inject = [
    'schemaService',
    'Regex',
    'l10nService',
];

/**
 * @name ssoPolicyModalComponent
 * @memberOf module:avi/policies/sso
 * @property {module:avi/policies/sso.SSOPolicyModalController} controller
 * @property {module:avi/policies/sso.ssoPolicyModalComponentBindings} bindings
 * @desc SSO Policy Modal Component
 * @author Aravindh Nagarajan, Alex Malitsky
 */
angular.module('avi/policies/sso').component('ssoPolicyModal', {
    /**
     * @mixin ssoPolicyModalComponentBindings
     * @memberOf module:avi/policies/sso
     * @property {module:avi/policies/sso.SSOPolicy} editable - SSOPolicy ObjectTypeItem.
     */
    bindings: {
        editable: '<',
    },
    controller: SSOPolicyModalController,
    templateUrl: 'src/components/modals/sso-policy-modal/sso-policy-modal.html',
});

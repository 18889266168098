/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { Component } from '@angular/core';
import './avi-textarea-container.component.less';

/**
 * @description Component to wrap a label (or avi-label-with-tooltip) and a textarea element, used
 *     to replace Clarity's clr-textarea-container component.
 * @author alextsg
 */
@Component({
    selector: 'avi-textarea-container',
    templateUrl: './avi-textarea-container.component.html',
})
export class AviTextareaContainerComponent {}

/**
 * @module LoginModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { Component } from '@angular/core';

import {
    animate,
    style,
    transition,
    trigger,
} from '@angular/animations';

import { Auth, IAdminUserSetupPayload } from 'ajs/modules/core';
import { L10nService } from '@vmw/ngx-vip';
import { StateService } from '@uirouter/core';
import './admin-user-setup.component.less';

import * as l10n from './admin-user-setup.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Default password for admin user.
 * From`/packaging/bootstrap/default_password` file.
 */
const DEFAULT_PASSWORD = '58NFaGDJm(PJH0G';

/**
 * Administrator username.
 */
const ADMINISTRATOR_USERNAME = 'admin';

/**
 * @description Component to setup admin user credentials.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'admin-user-setup',
    templateUrl: './admin-user-setup.component.html',
    animations: [
        trigger(
            'fadeIn',
            [
                transition(':enter', [
                    style(
                        {
                            opacity: 0,
                        },
                    ),
                    animate(
                        '500ms',
                        style(
                            {
                                opacity: 1,
                            },
                        ),
                    ),
                ]),
            ],
        ),
    ],
})
export class AdminUserSetupComponent {
    /**
     * Administrator's username - readonly field.
     */
    public readonly username = ADMINISTRATOR_USERNAME;

    /**
     * New Password.
     */
    public password: string;

    /**
     * Model for Password confirmation.
     */
    public confirmPassword: string;

    /**
     * Optional email field.
     */
    public email: string;

    /**
     * Model for error message.
     */
    public errorMessage: string;

    /**
     * Flag to show loader on credentials change.
     */
    public setupInProgress: boolean;

    /**
     * Flag to disable submit button if passwords dont match.
     */
    public validPassword = false;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Next state after credentials update.
     */
    private readonly targetState: string;

    constructor(
        private readonly authService: Auth,
        private readonly $state: StateService,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.targetState = authService.getAfterAdminSetupTargetState();
    }

    /**
     * Compares password and confirmPassword values and
     * disables submit button if they dont match by setting
     * `validPassword` to true.
     */
    public comparePasswords(): void {
        this.validPassword = this.password === this.confirmPassword;
    }

    /**
     * Make password validity checks and makes call to update credentials.
     */
    public async createAccount(): Promise<void> {
        this.setupInProgress = true;
        this.errorMessage = '';

        try {
            this.checkPasswordValidity();

            // We are doing two things here.
            // login with default password & password update.

            // there maybe a case where login call is successful but
            // password update fails.

            // When user fixes new password and click on submit,
            // we shouldnt make direct login requests since we already logged in from the first try.
            // if we did, UI AuthService will reject it without any error message.
            // and we will get stuck on the same page.

            // So if authService says we are already logged-in, try a failSafe logout.
            // this is necessary because in UI - we might have a userProfile set,
            // but there is a possibility that the session might have expired.
            if (this.authService.isLoggedIn()) {
                await this.authService.failSafeLogout();
            }

            await this.loginWithDefaultPassword();
            await this.updateAdministratorCredentials();

            this.$state.go(this.targetState);
        } catch (e) {
            // e.message for UI failures.
            // e.error for Login HTTP Req failures.
            // e.data.error for other HTTP Req failures.
            this.errorMessage = e?.message || e?.error || e?.data?.error;
        } finally {
            this.setupInProgress = false;
        }
    }

    /**
     * UI check for password validity.
     * @throws if password and username are same.
     */
    private checkPasswordValidity(): void {
        if (this.username === this.password) {
            const errorMessage =
                this.l10nService.getMessage(this.l10nKeys.samePasswordUsernameErrorMessage);

            throw new Error(errorMessage);
        }
    }

    /**
     * Logins with default password.
     */
    private loginWithDefaultPassword(): ng.IPromise<void> {
        return this.authService.login(this.username, DEFAULT_PASSWORD);
    }

    /**
     * Makes Request to update admin credentials
     */
    private updateAdministratorCredentials(): ng.IHttpPromise<void> {
        const {
            username,
            password,
            email,
        } = this;

        const payload: IAdminUserSetupPayload = {
            username,
            password,
            email,
            old_password: DEFAULT_PASSWORD,
        };

        return this.authService.updateAdministratorCredentials(payload);
    }
}

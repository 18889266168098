/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').controller('SystemConfigurationUpgradeController', [
'$scope', '$http',
function($scope, $http) {
    $scope.headers = $http.defaults.headers.common;
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const controllerSiteCollectionFactory = (Collection, ControllerSite) => {
    class ControllerSiteCollection extends Collection {}

    Object.assign(ControllerSiteCollection.prototype, {
        objectName_: 'controllersite',
        itemClass_: ControllerSite,
    });

    return ControllerSiteCollection;
};

controllerSiteCollectionFactory.$inject = [
    'Collection',
    'ControllerSite',
];

/**
 * @ngdoc factory
 * @name  ControllerSiteCollection
 * @description  Collection of {ControllerSite} items.
 * @author Zhiqian Liu
 */
angular.module('aviApp').factory('ControllerSiteCollection', controllerSiteCollectionFactory);

const moduleName = 'ajs-legacy';
const componentName = 'ipam-dns-internal-profile-config';
const prefix = `${moduleName}.${componentName}`;

export const aviVantageDnsConfigurationHeader = `${prefix}.aviVantageDnsConfigurationHeader`;
export const ttlInputLabel = `${prefix}.ttlInputLabel`;
export const secInnerText = `${prefix}.secInnerText`;
export const domainNameInputLabel = `${prefix}.domainNameInputLabel`;
export const recordTtlInputLabel = `${prefix}.recordTtlInputLabel`;
export const recordTtlInputPlaceholder = `${prefix}.recordTtlInputPlaceholder`;
export const removeDnsServiceDomainButtonLabel = `${prefix}.removeDnsServiceDomainButtonLabel`;
export const addDnsServiceDomainButtonLabel = `${prefix}.addDnsServiceDomainButtonLabel`;
export const aviVantageIpamConfigurationHeader = `${prefix}.aviVantageIpamConfigurationHeader`;
export const cloudForUsableNetworkFieldDescription = `${prefix}.cloudForUsableNetworkFieldDescription`;
export const cloudForUsableNetworkInputLabel = `${prefix}.cloudForUsableNetworkInputLabel`;
export const cloudForUsableNetworkInputPlaceholder = `${prefix}.cloudForUsableNetworkInputPlaceholder`;
export const usableNetworkInputLabel = `${prefix}.usableNetworkInputLabel`;
export const usableNetworkInputPlaceholder = `${prefix}.usableNetworkInputPlaceholder`;
export const removeUsableNetworkButtonLabel = `${prefix}.removeUsableNetworkButtonLabel`;
export const addUsableNetworkButtonLabel = `${prefix}.addUsableNetworkButtonLabel`;

export const ENGLISH = {
    [aviVantageDnsConfigurationHeader]: 'Avi Vantage DNS Configuration',
    [ttlInputLabel]: 'Default Record TTL for all domains',
    [secInnerText]: 'Sec',
    [domainNameInputLabel]: 'Domain Name',
    [recordTtlInputLabel]: 'Override Record TTL for this domain',
    [recordTtlInputPlaceholder]: 'Default Record TTL',
    [removeDnsServiceDomainButtonLabel]: 'Remove DNS Service Domain',
    [addDnsServiceDomainButtonLabel]: '+ Add DNS Service Domain',
    [aviVantageIpamConfigurationHeader]: 'Avi Vantage IPAM Configuration',
    [cloudForUsableNetworkFieldDescription]: 'Select Cloud to use for Usable Network',
    [cloudForUsableNetworkInputLabel]: 'Cloud for Usable Network',
    [cloudForUsableNetworkInputPlaceholder]: 'Select Cloud',
    [usableNetworkInputLabel]: 'Usable Network',
    [usableNetworkInputPlaceholder]: 'Select Network',
    [removeUsableNetworkButtonLabel]: 'Remove Usable Network',
    [addUsableNetworkButtonLabel]: '+ Add Usable Network',
};

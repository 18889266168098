/**
 * @module IpamModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { AviPermissionResource, ICustomIpamDnsProfile } from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import {
    MessageItem,
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model';
import { CustomIpamDnsProfileModalComponent } from 'ng/modules/ipam';

interface ICustomDnsProfileData {
    config: ICustomIpamDnsProfile;
}

export const customIpamDnsProfileToken = 'CustomIpamDnsProfile';

/**
 * @description CustomIpamDnsProfile Item class.
 *
 * @author Aravindh Nagarajan
 */
export class CustomIpamDnsProfile extends withFullModalMixin(ObjectTypeItem) {
    public data: ICustomDnsProfileData;
    public getConfig: () => ICustomIpamDnsProfile;

    constructor(args: {}) {
        const extendedArgs = {
            objectType: 'CustomIpamDnsProfile',
            windowElement: CustomIpamDnsProfileModalComponent,
            permissionName: AviPermissionResource.PERMISSION_CUSTOMIPAMDNSPROFILE,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter for script_params.
     */
    public get scriptParams(): RepeatedMessageItem<MessageItem> {
        const { script_params: scriptParams } = this.config;

        return scriptParams as RepeatedMessageItem<MessageItem>;
    }
}

Object.assign(CustomIpamDnsProfile.prototype, {
    objectName: 'customipamdnsprofile',
});

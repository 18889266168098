/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc constant
 * @type Function
 * @description
 *     Returns a subnet string given a subnet object with ip_addr and mask properties. If format
 *     doesn't match, returns undefined.
 */
/**
 * @param {IpAddrPrefix} subnetObj - Subnet object.
 * @returns {string|undefined}
 **/
function getSubnetString(subnetObj) {
    if (angular.isObject(subnetObj) &&
            angular.isObject(subnetObj.ip_addr) &&
            !angular.isUndefined(subnetObj.mask)) {
        return `${subnetObj.ip_addr.addr}/${subnetObj.mask}`;
    }
}

angular.module('aviApp').constant('getSubnetString', getSubnetString);

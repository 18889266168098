/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

export const WAF_PSMLOCATION_SUM_MATCHED_METRIC = 'waf_psmlocation.sum_matched';
export const WAF_PSMRULE_SUM_MATCHED_METRIC = 'waf_psmrule.sum_matched';
export const WAF_PSMRULE_SUM_EVALUATED_METRIC = 'waf_psmrule.sum_evaluated';

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../../../less/pages/administration/system.less';
import * as l10n from './BackupController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('BackupController', [
'$scope',
'SchedulerCollection',
'BackupCollection',
'l10nService',
function(
    $scope,
    SchedulerCollection,
    BackupCollection,
    l10nService,
) {
    this.schedulerCollection = new SchedulerCollection({
        params: {
            join: 'backupconfiguration:backup_config_ref',
            name: 'Default-Scheduler',
        },
    });

    const backupCollection = new BackupCollection();

    /**
    * Get keys from source bundles for template usage.
    */
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    /**
     * Load the schedulerCollection, then set this.subscriber to the default Subscriber item.
     */
    this.schedulerCollection.load()
        .then(() => {
            if (this.schedulerCollection.getNumberOfItems()) {
                [this.scheduler] = this.schedulerCollection.items;
                this.scheduler.bind('itemSaveSuccess', () => this.scheduler.load());
            }
        });

    const { objectName } = backupCollection;

    this.gridConfig = {
        id: `${objectName}-list-page`,
        collection: backupCollection,
        fields: [{
            name: 'data.config.timestamp',
            title: l10nService.getMessage(l10nKeys.columnTitleTimestamp),
        }, {
            name: 'local_file_url',
            title: l10nService.getMessage(l10nKeys.columnTitleLocalFile),
            template:
                `<span ng-show="::!row.data.config.local_file_url">
                    ${l10nService.getMessage(l10nKeys.emptyDataObject)}
                </span>
                <a
                    ng-show="::row.data.config.local_file_url"
                    href
                    ng-click="row.downloadBackup('local')">
                    {{ ::row.data.config.local_file_url }}
                </a>`,
        }, {
            name: 'remote_file_url',
            title: l10nService.getMessage(l10nKeys.columnTitleRemoteFile),
            template:
                `<span ng-show="::!row.data.config.remote_file_url">
                    ${l10nService.getMessage(l10nKeys.emptyDataObject)}
                </span>
                <span ng-show="::row.data.config.remote_file_url">
                    {{ ::row.data.config.remote_file_url }}
                </span>`,
        }],
        layout: {
            includeCreateButton: false,
        },
    };

    /**
     * Returns a description of the frequency.
     * @return {string} Ex. 'Back up every 1 day, 6 maximum backups stored'.
     */
    this.getFrequencyString = () => {
        const { config } = this.scheduler.data;

        return l10nService.getMessage(l10nKeys.backupFrequencyLabel, [
            config.frequency,
            config.frequency_unit.enumeration('SCHEDULER_FREQUENCY_UNIT_').toLowerCase(),
            config.backup_config_ref_data.maximum_backups_stored,
        ]);
    };

    /**
     * Returns a string of fields related to the remote backup configuration.
     * @return {string}
     */
    this.getRemoteString = () => {
        const
            backupConfig = this.scheduler.data.config.backup_config_ref_data,
            {
                remote_hostname: remoteHostname,
                ssh_user_ref: sshUserRef,
                remote_directory: remoteDirectory,
            } = backupConfig;

        const output = [];

        if (remoteHostname) {
            output.push(l10nService.getMessage(l10nKeys.serverAddressNameLabel, [remoteHostname]));
        }

        if (sshUserRef) {
            output.push(l10nService.getMessage(l10nKeys.userNameLabel, [sshUserRef.name()]));
        }

        if (remoteDirectory) {
            output.push(l10nService.getMessage(l10nKeys.directoryNameLabel, [remoteDirectory]));
        }

        return output.join(', ');
    };

    $scope.$on('$destroy', () => {
        backupCollection.destroy();
        this.schedulerCollection.destroy();
    });
}]);

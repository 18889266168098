/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    CloudCollection,
    cloudTypeSelectorOptions,
    ContentLibConfigConfigItem,
    DataNetworkConfigConfigItem,
    DATA_NETWORK_CONFIG_CONFIG_ITEM_TOKEN,
    ManagementNetworkConfigConfigItem,
    MANAGEMENT_NETWORK_CONFIG_CONFIG_ITEM_TOKEN,
    NsxtConfigurationConfigItem,
    NsxtTier1SegmentConfigConfigItem,
    NsxtTier1SegmentManualModeConfigItem,
    Tier1LogicalRouterInfoConfigItem,
    VCenterServer,
    VCenterServerCollection,
} from '.';

const cloudModule = angular.module('avi/cloud');
const factories = [
    {
        dependency: NsxtConfigurationConfigItem,
        name: 'NsxtConfigurationConfigItem',
    },
    {
        dependency: Tier1LogicalRouterInfoConfigItem,
        name: 'Tier1LogicalRouterInfoConfigItem',
    },
    {
        dependency: NsxtTier1SegmentConfigConfigItem,
        name: 'NsxtTier1SegmentConfigConfigItem',
    },
    {
        dependency: NsxtTier1SegmentManualModeConfigItem,
        name: 'NsxtTier1SegmentManualModeConfigItem',
    },
    {
        dependency: CloudCollection,
        name: 'CloudCollection',
    },
    {
        dependency: ContentLibConfigConfigItem,
        name: 'ContentLibConfigConfigItem',
    },
    {
        dependency: VCenterServer,
        name: 'VCenterServer',
    },
    {
        dependency: VCenterServerCollection,
        name: 'VCenterServerCollection',
    },
    {
        dependency: DataNetworkConfigConfigItem,
        name: DATA_NETWORK_CONFIG_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: ManagementNetworkConfigConfigItem,
        name: MANAGEMENT_NETWORK_CONFIG_CONFIG_ITEM_TOKEN,
    },
];

const components = [
    {
        name: 'cloudTypeSelector',
        options: cloudTypeSelectorOptions,
    },
];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(cloudModule, 'factory', name, dependency);
});

components.forEach(({ name, options }) => cloudModule.component(name, options));

/**
 * Module for SSO Policy
 * @module avi/policies/sso
 * @preferred
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */
import angular from 'angular';

import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import { ObjectTypeItem } from 'ajs/modules/data-model';

interface ISSOPolicyConfig {
    authentication_policy: IAuthenticationPolicy;
    authorization_policy: object;
    type: string;
}

interface IAuthenticationPolicy {
    config: {
        default_auth_profile_ref: string;
    };
}

enum SSOPolicyTypes {
    PINGACCESS = 'SSO_TYPE_PINGACCESS',
    SAML = 'SSO_TYPE_SAML',
}

/**
 * @desc SSO policy objectTypeItem.
 * @author Alex Malitsky, Aravindh Nagarajan
 */
class SSOPolicy extends ObjectTypeItem {
    /**
     * Returns policy type.
     */
    public get type(): string {
        return this.getConfig().type || '';
    }

    /**
     * Sets type of the policy.
     */
    public set type(type: string) {
        this.getConfig().type = type;
    }

    /**
     * Getter for authenticationPolicy.
     */
    public get authenticationPolicy(): (IAuthenticationPolicy | null) {
        return this.getConfig().authentication_policy || null;
    }

    /** Overriding getConfig()'s return type */
    public getConfig: () => ISSOPolicyConfig;

    constructor(args: {}) {
        const extendedArgs = {
            ...args,
            objectName: 'ssopolicy',
            objectType: 'SSOPolicy',
            permissionName: 'PERMISSION_SSOPOLICY',
            windowElement: 'sso-policy-modal',
        };

        super(extendedArgs);
    }

    public beforeEdit(): void {
        if (!this.getConfig().authentication_policy) {
            this.setNewChildByField('authentication_policy');
        }

        if (!this.getConfig().authorization_policy) {
            this.setNewChildByField('authorization_policy');
        }
    }

    /**
     * Returns default_auth_profile_ref of the nested authentication policy.
     */
    public getDefaultAuthProfileRef(): string {
        const { authentication_policy: authenticationPolicy } = this.getConfig();

        return authenticationPolicy.config.default_auth_profile_ref || '';
    }
}

initAjsDependency(
    angular.module('avi/policies/sso'),
    'factory',
    'SSOPolicy',
    SSOPolicy,
);

export {
    SSOPolicy,
    SSOPolicyTypes,
};

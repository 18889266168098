const moduleName = 'shared';
const componentName = 'full-modal-config';
const prefix = `${moduleName}.${componentName}`;

export const cancelBtnLabel = `${prefix}.cancelBtnLabel`;
export const saveBtnLabel = `${prefix}.saveBtnLabel`;

export const ENGLISH = {
    [cancelBtnLabel]: 'Cancel',
    [saveBtnLabel]: 'Save',
};

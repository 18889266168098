/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const pathMatchConfigItemFactory = (MessageItem, schemaService) => {
    /**
     * @alias module:config-item/PathMatchConfigItem
     * @private
     */
    class PathMatchConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'PathMatch',
                ...args,
            };

            super(extendedArgs);

            this.matchCaseLabelsHash_ = schemaService.getEnumValueLabelsHash('MatchCase');
            this.operationLabelsHash_ = schemaService.getEnumValueLabelsHash('StringOperation');
        }

        /**
         * Returns a list of values that should be searchable.
         */
        get searchableValues() {
            return [
                ...this.stringGroupNames,
            ];
        }

        /**
         * Returns the label to display for this.config.match_case.
         * @returns {string}
         */
        get matchCaseLabel() {
            const { match_case: matchCase } = this.config;

            return this.matchCaseLabelsHash_[matchCase];
        }

        /**
         * Returns the label to display for this.config.match_criteria.
         * @returns {string}
         */
        get matchCriteriaLabel() {
            const { match_criteria: matchCriteria } = this.config;

            return this.operationLabelsHash_[matchCriteria];
        }

        /**
         * Returns the label to display for this.config.match_str.
         * @returns {string}
         */
        get matchStrings() {
            const { match_str: matchString = [] } = this.config;

            return matchString.join(', ');
        }

        /**
         * Returns string_group_refs as comma seperated names.
         * @returns {string[]}
         */
        get stringGroupNames() {
            const { string_group_refs: stringGroupRefs = [] } = this.config;

            return stringGroupRefs.map(stringGroupRef => stringGroupRef.name());
        }

        /**
         * Returns a string representation of the config data.
         * @returns {string}
         */
        toString() {
            const {
                matchStrings,
                stringGroupNames = [],
            } = this;

            let displayValues = matchStrings;

            if (stringGroupNames.length) {
                displayValues =
                    `${displayValues ? `${displayValues}, ` : ''}${stringGroupNames.join(', ')}`;
            }

            return `${this.matchCaseLabel}, ${this.matchCriteriaLabel} ${displayValues}`;
        }
    }

    return PathMatchConfigItem;
};

pathMatchConfigItemFactory.$inject = [
    'MessageItem',
    'schemaService',
];

/**
 * @ngdoc factory
 * @name  PathMatchConfigItem
 * @description  PathMatch ConfigItem class.
 * @module config-item/PathMatchConfigItem
 * @author alextsg
 */
angular.module('avi/policies').factory('PathMatchConfigItem', pathMatchConfigItemFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const pingAccessAgentProfileFactory = Item => {
    class PingAccessAgentProfile extends Item {
    }

    angular.extend(PingAccessAgentProfile.prototype, {
        objectName: 'pingaccessagent',
        windowElement: 'ping-access-agent-modal',
    });

    return PingAccessAgentProfile;
};

pingAccessAgentProfileFactory.$inject = [
    'Item',
];

/**
 * @ngdoc factory
 * @name  PingAccessAgent
 * @description  PingAccessAgent item.
 */
angular.module('aviApp').factory('PingAccessAgentProfile', pingAccessAgentProfileFactory);

/**
 * @module NotificationModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import './controller-faults.component.less';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './controller-faults.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Displays controller faults.
 * @author alextsg
 */
@Component({
    selector: 'controller-faults',
    templateUrl: './controller-faults.component.html',
})
export class ControllerFaultsComponent {
    @Input() public faults: string[] = [];

    @Output() public onClose = new EventEmitter();

    /**
     * Used in ngFor for repeating through the faults.
     */
    public fault = '';

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * trackBy Function.
     */
    public trackByFault(index: number, fault: string): string {
        return fault;
    }

    /**
     * Close handler for closing the notification.
     */
    public handleClose(): void {
        this.onClose.emit();
    }
}

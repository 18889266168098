const moduleName = 'ajs-legacy';
const componentName = 'nat-policy-modal';
const prefix = `${moduleName}.${componentName}`;

export const editNatPolicyModalHeader = `${prefix}.editNatPolicyModalHeader`;
export const newNatPolicyModalHeader = `${prefix}.newNatPolicyModalHeader`;
export const generalHeader = `${prefix}.generalHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const rulesHeader = `${prefix}.rulesHeader`;
export const ruleCountText = `${prefix}.ruleCountText`;
export const addRuleButtonLabel = `${prefix}.addRuleButtonLabel`;

export const ENGLISH = {
    [editNatPolicyModalHeader]: 'Edit NAT Policy: {0}',
    [newNatPolicyModalHeader]: 'New NAT Policy: {0}',
    [generalHeader]: 'General',
    [nameInputLabel]: 'Name',
    [descriptionInputLabel]: 'Description',
    [rulesHeader]: 'Rules',
    [ruleCountText]: '{0, plural, one{(# Rule)} other{(# Rules)}}',
    [addRuleButtonLabel]: 'Add Rule',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @alias module:match/hostHdrMatch
 * @private
 */
class HostHdrMatchComponent {
    constructor(dropDownUtils, Regex) {
        this.criteria = dropDownUtils.getEnumDropdownOptions('HdrMatchOperation');
        this.Regex = Regex;
    }
}

HostHdrMatchComponent.$inject = [
    'dropDownUtils',
    'Regex',
];

/**
 * @ngdoc component
 * @name  hostHdrMatch
 * @module match/hostHdrMatch
 * @description Component for configuring a HostHdrMatch Config Item.
 * @param {HostHdrMatchConfigItem} hostHdrMatch
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @author alextsg
 */
angular.module('avi/policies').component('hostHdrMatch', {
    bindings: {
        hostHdrMatch: '<',
        preventEdit: '<',
    },
    controller: HostHdrMatchComponent,
    templateUrl: 'src/components/forms/match-config/matches/' +
        'host-hdr-match/host-hdr-match.component.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as tooltipDotsTemplate from './tooltip-dots.partial.html';

/**
 * @ngdoc service
 * @name aviApp:Tooltip
 * @description
 *     Allows easy creation of line, circle, and label tooltips
 *     Needs a chart object (from ChartService) for scales
 *     Needs an array for calculation of position (i.e. the values array from a dataSeries object)
 *     Needs a settings object (which can be empty)
 *     Needs an element that it can attach to.
 *     Settings
 *     label -- if true, will create a tooltip with text label (see performance chart for usage)
 *     line -- if true, just draws a line (like in the very smallest sparklines)
 *     There are a whole lot of other overrides that you can pass in (e.g. radius, labelColor)
 *     but it's probably best not to mess with it in here. (I'll take these override settings out
 *     when I get a chance -wk)
 */
angular.module('aviApp').factory('Tooltip', [
'$stateParams', '$interpolate', 'NamesHelper', 'Convert', 'myAccount',
function($stateParams, $interpolate, NamesHelper, Convert, myAccount) {
    const circleRadius = 6;

    function Tooltip(args) {
        const { data: series, settings } = args;

        this.chartSettings = NamesHelper.getChartSettings(series.getSeriesId());
        this.data = series.getData();
        this.settings = settings || {};
        this.chart = args.chart;
        this.g = this.chart.g;
        this.x = this.chart.x;
        this.y = this.chart.y;
        this.type = args.type;

        const { y } = this;

        this.elm = this.g.append('g').classed('tooltip', true);

        if (this.type === 'line') {
            this.line = this.elm.append('svg:line')
                .attr('x0', 0)
                .attr('x1', 0)
                .attr('y0', function() {
                    return y.range()[1];
                })
                .attr('y1', function() {
                    return y.range()[0];
                })
                .attr('stroke', 'grey');
        } else {
            this.circleG = this.elm.append('g')
                .classed(series.getColorClassName(), true);

            this.circleG.append('circle')
                .attr('r', circleRadius)
                .attr('cx', 0)
                .attr('cy', 0)
                .attr('fill', 'white')
                .classed('sparkline-outerCircle', true);

            this.circleG.append('circle')
                .attr('r', circleRadius - 2)
                .attr('cx', 0)
                .attr('cy', 0)
                .classed('sparkline-innerCircle', true);

            // If we're using a label
            if (this.type === 'label') {
                this.label = {};
                this.label.textGroup = $('<div class = "graph-tooltip graph-overlay"></div>');
                this.label.textGroup.on('click', function() {
                    elm.find('.performance-chart-graph').trigger('click');
                });

                if (this.stuck) {
                    this.label.textGroup.addClass('stuck');
                }

                const elm = $('body');

                elm.append(this.label.textGroup);
                this.label.textGroup.hide();
            }
        }
    }

    Tooltip.prototype.updatePosition = function(point, text, stuck) {
        if (this.destroyed) {
            return;
        }

        this.stuck = stuck;
        this.show();

        const xPos = this.x(point.timestamp);
        const yPos = this.type === 'line' ? 0 : this.y(point.value + (point.y0 || 0));

        this.elm
            .attr('transform', `translate(${xPos},${yPos})`)
            .classed('stuck', this.stuck);

        if (text && this.type === 'label') {
            this.resizeLabel(xPos, yPos, point, text);
        }

        return point;
    };

    Tooltip.prototype.toggleStuck = function() {
        this.stuck = !this.stuck;
        this.elm.classed('stuck', this.stuck);

        if (this.label) {
            this.label.textGroup.toggleClass('stuck');
            $('body').find('.graph-tooltip-triangle').toggleClass('stuck');
        }
    };

    Tooltip.prototype.resizeLabel = function(xPos, yPos, point, text) {
        if (this.destroyed || !this.label) {
            return;
        }

        if (text && !Array.isArray(text)) {
            text = [text];
        }

        if (!text || !text.length) {
            return;
        }

        const r = this.chart.elm.find('.reason-string');

        if (point && point.reason) {
            r.text(point.reason);
        } else {
            r.text('');
        }

        // tg is text group
        const tg = this.label.textGroup;

        tg.show();
        tg.empty();

        const tri = $('<div class="graph-tooltip-triangle graph-tooltip-outer-triangle"></div>');
        const innerTri =
            $('<div class="graph-tooltip-triangle graph-tooltip-inner-triangle"></div>');

        if (this.stuck) {
            tri.addClass('stuck');
            innerTri.addClass('stuck');
        }

        // ---- removing old label -----//
        const p = $('body');

        p.find('.graph-tooltip-triangle').remove();
        p.append(tri);
        p.append(innerTri);

        if (text && text.length) {
            // The format of the time that we have depends on what type of graph we're looking at
            let timeFormat;

            // If it's past 6 hours or real time we don't care about the day or the year
            if ($stateParams.timeframe === 'rt' || $stateParams.timeframe === '6h') {
                timeFormat = 'h:mm:ss a';
            } else if ($stateParams.timeframe === '1y' || $stateParams.timeframe === '1q') {
                timeFormat = 'YYYY-MM-DD, h:mm:ss a';
            } else {
                timeFormat = 'MM-DD h:mm:ss a';
            }

            const t = myAccount.uiProperty.useUTCTime ?
                moment.utc(point.timestamp) : moment(point.timestamp);

            tg.append(`<div class = "tooltip-date">${t.format(timeFormat)}</div>`);
        }

        function getLegendHtml(label, value, units, legendClass) {
            const html =
                `<span class="legend-value">
                    <div>${label}</div>
                    <div class="value">
                        ${value}
                        <span class="units">${units}</span>
                    </div>
                </span>`;

            return `<span class="tooltip-legend">
                <div class="chart-color tooltip-legend-color ${legendClass}"></div>
                ${html}
            </span>`;
        }

        const dots = {};
        // Constructing the label
        let hasDots = false;

        _.each(text, function(t) {
            const { data: series } = t;

            const innerPoint = series.getDataPoint(point.timestamp);

            if (!innerPoint && process.env.NODE_ENV !== 'production') {
                console.warn('Did not find other point that matched');

                return;
            }

            const name = series.getTitle();
            const valUnit = Convert.getVal(innerPoint.value, series.getUnits());
            const val = valUnit.value.toFixed(1);
            const units = valUnit.unit;
            const className = series.getColorClassName();

            tg.append(getLegendHtml(name, val, units, className));

            _.each(innerPoint.dots, function(v, k) {
                if (!dots[k]) {
                    dots[k] = v;
                } else if (k === 'anomalies') {
                    dots[k] += v;
                }

                if (v) {
                    hasDots = true;
                }
            });
        });

        const
            { chartSettings } = this,
            [{ metric }] = text,
            totalSeries = metric.getSeriesByType('total');

        if (totalSeries && (!chartSettings || !chartSettings.excludeTotals)) {
            const { value, unit } = Convert.getVal(
                totalSeries.getValue('exact', point.timestamp),
                totalSeries.getUnits(),
            );

            tg.append(
                getLegendHtml(
                    'Total:',
                    value.toFixed(1),
                    unit,
                    totalSeries.getColorClassName(),
                ),
            );
        }

        if (hasDots) {
            const dotTemplate = $interpolate(tooltipDotsTemplate);
            const dotElm = dotTemplate(dots);

            tg.append(dotElm);
        }

        const legends = tg.find('.tooltip-legend');

        if (legends && legends.length === 1) {
            legends.css('width', '100%');
        }

        // ---------- Keeping box from going off to the right or left ----------------- //
        // have xPos, tg -- main div
        const elmHeight = tg.height();
        const elmWidth = tg.width();
        const { chart } = this;
        const chartPadding = chart.settings.padding;
        const chartWidth = chart.elm.width() - chartPadding.left - chartPadding.right;
        let left;

        // If we're off to the right
        if (elmWidth / 2 + xPos > chartWidth + chartPadding.right) {
            left = chartWidth + chartPadding.left + chartPadding.right - elmWidth;
        // If we're off to the left
        } else if (xPos + chartPadding.left < elmWidth / 2) {
            left = 0;
        } else {
            left = xPos - elmWidth / 2 + chartPadding.left;
        }

        const chartPagePosition = this.chart.elm.offset();
        const chartLeft = chartPagePosition.left;
        const chartTop = chartPagePosition.top;
        const textGroupTop = `${chartPadding.top + yPos - elmHeight - 20 + chartTop}px`;
        const textGroupLeft = `${left + chartLeft}px`;

        // The position of the main div
        tg.css({
            top: textGroupTop,
            left: textGroupLeft,
            opacity: 1,
        });

        // We position the triangle relative to the main elm rather than the label so that
        // it's easier to move them indepently
        // and that when we're going off the screen to the right or to the left,
        // it's easy to only adjust the main div (tg)
        const triTop = `${chartPadding.top + yPos - 18 + chartTop}px`;
        const triLeft = `${chartPadding.left + xPos - 10 + chartLeft}px`;

        tri.css({
            top: triTop,
            left: triLeft,
        });

        // triangle is 10px wide
        const innerTriTop = `${chartPadding.top + yPos - 20 + chartTop}px`;
        const innerTriLeft = `${chartPadding.left + xPos - 10 + chartLeft}px`;

        innerTri.css({
            top: innerTriTop,
            left: innerTriLeft,
        });
    };

    Tooltip.prototype.hide = function() {
        if (this.destroyed) { return; }

        this.elm.style('opacity', 0);

        if (this.label && this.label.textGroup) {
            $('body').find('.graph-tooltip-triangle').hide();
            this.label.textGroup.hide();
        }
    };

    Tooltip.prototype.show = function() {
        if (this.destroyed) {
            return;
        }

        this.elm.style('opacity', 1);

        if (this.label && this.label.textGroup) {
            $('body').find('.graph-tooltip-triangle').show();
            this.label.textGroup.show();
        }
    };

    // Cleaning it up so that it's sure to be garbage collected
    Tooltip.prototype.destroy = function() {
        this.data = null;

        if (this.elm) {
            this.elm.remove();
        }

        if (this.tg) {
            this.tg.remove();
        }

        if (this.label && this.label.textGroup) {
            $('body').find('.graph-tooltip-triangle').remove();
            this.label.textGroup.remove();
            this.label.textGroup = null;
        }

        const self = this;

        _.each(this, function(val, key) {
            self[key] = null;
        });

        this.destroyed = true;
    };

    return Tooltip;
}]);

const moduleName = 'welcome';
const componentName = 'welcome-backup-passphrase';
const prefix = `${moduleName}.${componentName}`;

export const passphraseLabel = `${prefix}.passphraseLabel`;
export const confirmPassphraseLabel = `${prefix}.confirmPassphraseLabel`;

export const ENGLISH = {
    [passphraseLabel]: 'Passphrase',
    [confirmPassphraseLabel]: 'Confirm Passphrase',
};

/**
 * @module avi/core
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { MessageBase } from 'ajs/modules/data-model';
import { Constructor } from '../../../declarations/globals.d';

type TMessageItemConfig = Record<string, any>;

export interface IIndexItem {
    getIndex?(): number;
    setIndex?(index: number): void;
}

/**
 * @description
 *     Mixin to extend a MessageItem having index property.
 *     Not all MessageItems have Index property.
 *     Use only when you are know MessageItem supports index property.
 * @author Rachit Aggarwal
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, require-jsdoc
export function withIndexMixin<BaseClass extends Constructor<MessageBase>>(BaseClass: BaseClass) {
    class WithIndexItem extends BaseClass implements IIndexItem {
        public getConfig: () => TMessageItemConfig;

        /**
         * Returns the index of the MessageItem, which typically exists for repeated MessageItems.
         */
        public getIndex(config: TMessageItemConfig = this.getConfig()): number | undefined {
            return config.index;
        }

        /**
         * Sets the index property on a config.
         */
        public setIndex(index = 0): void {
            this.getConfig().index = index;
        }
    }

    return WithIndexItem;
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').controller('CertificateManagementListController', [
'$scope', 'CertificateManagementCollection', 'CRUDGridConfig',
function($scope, CertificateManagementCollection, CRUDGridConfig) {
    const vm = this;

    vm.collection = new CertificateManagementCollection();

    vm.gridConfig = new CRUDGridConfig();
    vm.gridConfig.collection = this.collection;

    const { objectName } = vm.gridConfig.collection;

    vm.gridConfig.id = `${objectName}-list-page`;

    vm.gridConfig.fields = [{
        name: 'getConfig().name',
        title: 'Name',
        sortBy: 'name',
    }, {
        name: 'control_script_name',
        title: 'Control Script',
        template: '{{ row.controlScriptName || "-" }}',
    }, {
        name: 'script_params',
        title: 'Custom Params',
        template: '{{ row.scriptParams.length || "-" }}',
    }];

    vm.gridConfig.expandedContainerTemplate = `
        <certificate-management-expander certificate-management="row">
        </certificate-management-expander>`;

    vm.gridConfig.expanderDisabled = row => !row.hasScriptParams;

    vm.gridConfig.singleactions = [{
        title: 'Edit',
        class: 'icon-pencil-4',
        hidden: row => !row.isEditable() && !row.isClonable(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }];
}]);

/**
 * Module containing scripts related components.
 * @preferred
 * @module ScriptsModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { IRateLimiter } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import template from './rate-limiters.component.html';
import './rate-limiters.component.less';
import * as l10n from './rate-limiters.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/* Local type to represent defaultValues service type */
type DefaultValues = any;

/**
 * @description Component to show list of rate limiters.
 * @author Ram Pal
 */
class RateLimitersController {
    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    private rateLimiters: IRateLimiter[];
    private defaultValues: DefaultValues;

    constructor(
        defaultValues: DefaultValues,
        l10nService: L10nService,
    ) {
        this.defaultValues = defaultValues;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Function to add single rate limiter object with default values.
     */
    public addRateLimiter(): void {
        const rateLimiter = this.defaultValues.getDefaultItemConfig('ratelimiter');

        this.rateLimiters.push(rateLimiter);
    }

    /**
     * Function to remove rate limiter object by index.
     */
    public removeRateLimiter(index: number): void {
        this.rateLimiters.splice(index, 1);
    }
}

RateLimitersController.$inject = [
    'defaultValues',
    'l10nService',
];

export const rateLimitersComponentOptions = {
    bindings: {
        rateLimiters: '<',
    },
    controller: RateLimitersController,
    template,
};

/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { Pipe, PipeTransform } from '@angular/core';
import { booleanLabelFilter } from 'ajs/modules/core/services/boolean-label';

@Pipe({ name: 'booleanLabel' })
export class BooleanLabelPipe implements PipeTransform {
    /**
     * Pipe that transforms boolean values into text labels. Default is "True" and "False".
     */
    public transform(boolVal: boolean, type?: string, lowerCase?: boolean): string {
        return booleanLabelFilter(boolVal, type, lowerCase);
    }
}

/**
 * @module VsModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { Collection } from 'ajs/modules/data-model';

/**
 * @description
 *
 *   VsVip collection
 *
 * @author Ram Pal
 */
export class VsVipCollection extends Collection {
    constructor(args = {}) {
        const allDataSources = {
            list: {
                source: 'ListCollDataSource',
                transformer: 'VsVipInventoryDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        };

        const extendedArgs = {
            allDataSources,
            objectName: 'vsvip-inventory',
            searchFields: ['name', 'addr'],
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('VsVip');
    }
}

VsVipCollection.ajsDependencies = [
    'VsVip',
];

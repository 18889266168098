/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { MatchExtendableConfigItem } from 'ajs/modules/data-model';

export class WafPSMLocationMatchConfigItem extends MatchExtendableConfigItem {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'WafPSMLocationMatch',
            ...args,
        };

        super(extendedArgs);
    }
}

WafPSMLocationMatchConfigItem.ajsDependencies = [
    'MatchExtendableConfigItem',
];

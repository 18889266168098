/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'logs';
const componentName = 'icap-log-violation';
const prefix = `${moduleName}.${componentName}`;

export const icapViolationsSectionTitleLabel = `${prefix}.icapViolationsSectionTitleLabel`;
export const fileNameLabel = `${prefix}.fileNameLabel`;
export const threatNameLabel = `${prefix}.threatNameLabel`;
export const violationResolutionLabel = `${prefix}.violationResolutionLabel`;

export const ENGLISH = {
    [icapViolationsSectionTitleLabel]: 'ICAP Violations ({0})',
    [fileNameLabel]: 'File Name',
    [threatNameLabel]: 'Threat Name',
    [violationResolutionLabel]: 'Violation Resolution',
};

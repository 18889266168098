/**
 * @module Angular-Core
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

/* eslint-disable @typescript-eslint/no-empty-interface */
/* tslint:disable no-empty-interface */

import { pluck } from 'underscore';
import { IGroupedServerFault, IVSGroupedFaults } from 'ng/modules/notification';

export interface IFault {
    id: string;
    type?: string;
    faults: IServerFault[] | IGenericFault[];
}

export interface IGenericFault {
    description: string;
    name: string;
}

export interface IServerFault {
    pool_ref: string;
    servers: IServer[];
}

interface IServer extends IGenericFault {}

type Faults = IGenericFault[] | IServer[];

/**
 * ID for server-related faults.
 */
const SERVER_FAULTS = 'server_faults';

/**
 * Returns a list of fault descriptions.
 */
const getDescriptions = (faults: Faults = []): string[] => pluck(faults, 'description');

/**
 * Returns a list of server fault descriptions. Server faults are different from other faults since
 * they are more deeply nested.
 */
const getServerFaultsDescriptions = (serverFaults: IServerFault[] = []): string[] => {
    return serverFaults.reduce((acc, fault) => {
        return [
            ...acc,
            ...getDescriptions(fault.servers),
        ];
    }, []);
};

/**
 * Returns an object with a pool_ref and its faults.
 */
const getServerFaultDescriptionsByPool = (
    serverFaults: IServerFault[] = [],
): IGroupedServerFault[] => {
    return serverFaults.map(fault => {
        return {
            poolRef: fault.pool_ref,
            faults: getDescriptions(fault.servers),
        };
    });
};

/**
 * Returns an array of all Virtual Service fault descriptions.
 */
export const getFlattenedVirtualServiceFaultDescriptions = (items: IFault[] = []): string[] => {
    return items.reduce((acc, item) => {
        const descriptions = item.id === SERVER_FAULTS ?
            getServerFaultsDescriptions(item.faults as IServerFault[]) :
            getDescriptions(item.faults as IGenericFault[]);

        return [
            ...acc,
            ...descriptions,
        ];
    }, []);
};

/**
 * Returns a hash of generic faults and server faults.
 */
export const getGroupedVirtualServiceFaultDescriptions = (
    items: IFault[] = [],
): IVSGroupedFaults => {
    return items.reduce((acc: IVSGroupedFaults, item) => {
        if (item.id === SERVER_FAULTS) {
            const { server = [] } = acc;

            return {
                ...acc,
                server: [
                    ...server,
                    ...getServerFaultDescriptionsByPool(item.faults as IServerFault[]),
                ],
            };
        } else {
            const { generic = [] } = acc;

            return {
                ...acc,
                generic: [
                    ...generic,
                    ...getDescriptions(item.faults as IGenericFault[]),
                ],
            };
        }
    }, {});
};

const moduleName = 'ajs-legacy';
const componentName = 'pool-fail-action-setter';
const prefix = `${moduleName}.${componentName}`;

export const failActionLabel = `${prefix}.failActionLabel`;
export const selectFailActionPlaceholder = `${prefix}.selectFailActionPlaceholder`;
export const statusCodeLabel = `${prefix}.statusCodeLabel`;
export const selectStatusCodePlaceholder = `${prefix}.selectStatusCodePlaceholder`;
export const uploadFileLabel = `${prefix}.uploadFileLabel`;

export const ENGLISH = {
    [failActionLabel]: 'Fail Action',
    [selectFailActionPlaceholder]: 'Select fail action',
    [statusCodeLabel]: 'Status Code',
    [selectStatusCodePlaceholder]: 'Select status code',
    [uploadFileLabel]: 'Upload File',
};

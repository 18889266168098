/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

// DataTransformer and all it's descendants are deprecated TODO drop
function dataTransformerFactory(Base) {
    /**
     * @constructor
     * @memberof module:avi/dataModel
     * @extends module:avi/dataModel.Base
     * @author Ashish Verma
     */
    class DataTransformer extends Base {
        /**
         * Model is executing this function to ask the field to participate in a call group
         * @param data - model data hash, used to figure out the earliest start
         *      time for the call and also may do other preparations
         * @param groupCall - the object that the fields put their parameters in
         *      to participate in the request
         */
        beforeCall(data, groupCall) {
            return null;
        }

        /**
         * Model is executing this function when the call already happened and the
         * purpose of the call is to share the response data between the fields each
         * field is taking whatever data it needsto generate the right value
         * @param data - model data hash, here used to update fields value
         * @param groupCall - the group call object, at this point it contains
         *      server response that the field want to access to generate value
         */
        afterCall(data, groupCall) {}
    }

    DataTransformer.prototype.name = null;

    return DataTransformer;
}

dataTransformerFactory.$inject = [
    'Base',
];

angular.module('avi/dataModel').factory('DataTransformer', dataTransformerFactory);

/**
 * @module avi/core
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import template from './avi-alert.component.html';

/**
 * @description
 *      Component to display alert message.
 *
 * @author Aravindh Nagarajan, Alex Malitsky
 */
class AviAlertController {
    /**
     * Warning message(s) to be displayed.
     */
    public readonly message: string | string[];

    /**
     * Text for alert modal dismiss button.
     */
    public readonly confirmButtonText = 'Dismiss';

    /**
     * Status of alert message modal.
     */
    public readonly isWarning = true;

    /**
     * To be fired on modal close
     */
    public onClose: () => void;

    /**
     * Function binding to close modal.
     */
    public closeModal: () => void;

    /**
     * Dismisses alert modal.
     */
    public dismiss(): void {
        this.onClose();
        this.closeModal();
    }

    /**
     * Returns true to render the list of alerts.
     */
    public get hasList(): boolean {
        return Array.isArray(this.message);
    }
}

export const aviAlertOptions = {
    bindings: {
        message: '<',
        onClose: '&',
        closeModal: '&',
    },
    controller: AviAlertController,
    template,
};

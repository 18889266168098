/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    ContentChild,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
    ConnectedOverlayPositionChange,
    ConnectedPosition,
    FlexibleConnectedPositionStrategy,
    Overlay,
    OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Cancelable, debounce } from 'underscore';
import {
    BOTTOM_CONNECTED_POSITION,
    BOTTOM_LEFT_CONNECTED_POSITION,
    BOTTOM_RIGHT_CONNECTED_POSITION,
    TOP_CONNECTED_POSITION,
    TOP_LEFT_CONNECTED_POSITION,
    TOP_RIGHT_CONNECTED_POSITION,
} from './avi-tooltip.constants';

const defaultPositionsPriority = [
    TOP_CONNECTED_POSITION,
    TOP_RIGHT_CONNECTED_POSITION,
    BOTTOM_RIGHT_CONNECTED_POSITION,
    TOP_LEFT_CONNECTED_POSITION,
    BOTTOM_LEFT_CONNECTED_POSITION,
    BOTTOM_CONNECTED_POSITION,
];

/**
 * @ngdoc directive
 * @name AviTooltipDirective
 * @description Directive for displaying tooltip content over an origin element.
 * @author alextsg
 */
@Directive({ selector: '[avi-tooltip]' })
export class AviTooltipDirective implements OnInit, OnDestroy {
    /**
     * Event emitted when the tooltip has been toggled.
     */
    @Output() public openedChange = new EventEmitter<boolean>();

    /**
     * @param positionsPriority - List of positions to prioritize.
     */
    @Input('positionsPriority') private set positions(positions: ConnectedPosition[]) {
        this.positionsPriority = positions || defaultPositionsPriority;
    }

    /**
     * @param showOnClick - True if the tooltip content should show when the origin element is
     * clicked.
     */
    @Input() private showOnClick = false;

    /**
     * @param delay - Amount of time in milliseconds before the tooltip content is rendered.
     */
    @Input() private delay = 0;

    /**
     * @param backdropClass - ClassName added to the backdrop element.
     */
    @Input() private backdropClass = '';

    /**
     * Used for the parent to dictate if the tooltip content should be opened or closed. Observable
     * to be subscribed to, returns true to open and false to close.
     */
    @Input() private tooltipControl$ = new Observable<boolean>();

    /**
     * Used for updating the overlay position. It seems like Angular CDK doesn't know when the
     * overlay size has changed after opening (like if we make an HTTP request when the overlay is
     * open and the size grows after we load information), and the position needs to be updated
     * after the size changes.
     */
    @Input() private overlaySizeChange$ = new Observable<void>();

    /**
     * @param hideTooltip - True to prevent rendering the tooltip.
     */
    @Input() private hideTooltip = false;

    /**
     * @param overlayMaxWidth - Max width of the overlay. Used, for example, to limit the width of
     *     dropdown options.
     */
    @Input() private overlayMaxWidth?: number;

    /**
     * @param onPositionChange - Called when the tooltip render position has changed.
     */
    @Output() private onPositionChange = new EventEmitter<ConnectedPosition>();

    @ContentChild('aviTooltipContent')
    private tooltipContent: TemplateRef<HTMLElement>;

    public debouncedAttach: (() => void) & Cancelable;

    private positionsPriority: ConnectedPosition[] = defaultPositionsPriority;
    private isAttached = false;
    private templatePortal: TemplatePortal<HTMLElement>;
    private overlayRef: OverlayRef;
    private backdropSubscription: Subscription;
    private overlayPositionSubscription: Subscription;
    private tooltipControlSubscription: Subscription;

    /**
     * Subscription for overlay size changes.
     */
    private overlaySizeChangeSubscription: Subscription;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private overlay: Overlay,
        private elementRef: ElementRef,
        private zone: NgZone,
    ) {}

    /**
     * Listener for the click event. Attaches the tooltip if this.showOnClick is true.
     */
    @HostListener('click') public handleClick(): void {
        if (this.showOnClick) {
            this.debouncedAttach();
        }
    }

    /**
     * Listener for the mouseenter event. Attaches the tooltip if this.showOnClick is false.
     */
    @HostListener('mouseenter') public show(): void {
        if (!this.showOnClick) {
            this.debouncedAttach();
        }
    }

    /**
     * Listener for the mouseout event. Detaches the tooltip.
     */
    @HostListener('mouseleave') public hide(): void {
        if (!this.showOnClick) {
            this.detach();
        }
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        this.debouncedAttach = debounce(this.attach, this.delay);
    }

    /**
     * @override
     * Used to detect changes to the overlay maxWidth for resizing.
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (this.overlayRef && changes.overlayMaxWidth) {
            const { overlayMaxWidth } = changes;
            const { currentValue } = overlayMaxWidth;

            this.overlayRef.updateSize({ maxWidth: currentValue });
        }
    }

    /**
     * @override
     */
    public ngOnDestroy(): void {
        this.backdropSubscription.unsubscribe();
        this.overlayPositionSubscription.unsubscribe();
        this.tooltipControlSubscription.unsubscribe();
        this.overlaySizeChangeSubscription.unsubscribe();

        this.debouncedAttach.cancel();
        this.overlayRef.dispose();
    }

    /**
     * @override
     */
    public ngAfterContentInit(): void {
        const positionStrategy: FlexibleConnectedPositionStrategy = this.overlay.position()
            .flexibleConnectedTo(this.elementRef)
            .withPositions(this.positionsPriority)
            .withGrowAfterOpen(true);

        this.overlayRef = this.overlay.create({
            backdropClass: this.backdropClass,
            hasBackdrop: this.showOnClick,
            maxWidth: this.overlayMaxWidth,
            positionStrategy,
        });

        this.templatePortal = new TemplatePortal(this.tooltipContent, this.viewContainerRef);

        this.backdropSubscription = this.overlayRef.backdropClick()
            .subscribe(this.handleBackdropClick);

        this.overlayPositionSubscription = positionStrategy.positionChanges
            .subscribe(this.handlePositionChange);

        this.tooltipControlSubscription = this.tooltipControl$
            .subscribe(this.handleTooltipControlChange);

        this.overlaySizeChangeSubscription = this.overlaySizeChange$
            .subscribe(this.handleOverlaySizeChange);
    }

    /**
     * Attaches the tooltip.
     */
    private attach = (): void => {
        if (this.hideTooltip) {
            return;
        }

        if (!this.isAttached) {
            this.overlayRef.attach(this.templatePortal);
            this.isAttached = true;
            this.openedChange.emit(this.isAttached);
        }
    };

    /**
     * Detaches the tooltip.
     */
    private detach(): void {
        this.debouncedAttach.cancel();

        if (this.isAttached) {
            this.overlayRef.detach();
            this.isAttached = false;
            this.openedChange.emit(this.isAttached);
        }
    }

    /**
     * Handler for a position change. If a change happens, emits the onPositionChange EventEmitter.
     */
    private handlePositionChange = (positionChange: ConnectedOverlayPositionChange): void => {
        this.zone.run(() => this.onPositionChange.emit(positionChange.connectionPair));
    };

    /**
     * Handler for clicking the backdrop, which appears when this.showOnClick is true, so that
     * clicking the backdrop hides the popup.
     */
    private handleBackdropClick = (): void => {
        if (this.showOnClick) {
            this.detach();
        }
    };

    /**
     * Handler for tooltipControl changes, which is handled by the parent.
     */
    private handleTooltipControlChange = (showTooltip: boolean): void => {
        if (showTooltip) {
            this.debouncedAttach();
        } else {
            this.detach();
        }
    };

    /**
     * Handler for overlay size changes. Updates the position if the position is no longer valid
     * after the size change.
     */
    private handleOverlaySizeChange = (): void => {
        if (this.overlayRef && this.isAttached) {
            setTimeout(() => this.overlayRef.updatePosition());
        }
    };
}

const moduleName = 'network';
const componentName = 'configured-network-subnet-expander';
const prefix = `${moduleName}.${componentName}`;

export const ipSubnetLabel = `${prefix}.ipSubnetLabel`;
export const staticIpPoolsLabel = `${prefix}.staticIpPoolsLabel`;

export const ENGLISH = {
    [ipSubnetLabel]: 'IP Subnet (Configured)',
    [staticIpPoolsLabel]: 'Static IP Pools (Type)',
};

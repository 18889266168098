/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const openstackNetworkCollectionFactory = (Collection, OpenstackNetwork) => {
    class OpenstackNetworkCollection extends Collection {
    }

    const dataSources = {
        list: {
            source: 'ListCollDataSource',
            transformer: 'OpenstackNetworkDataTransformer',
            transport: 'DataTransportByPost',
            fields: ['config'],
        },
    };

    Object.assign(OpenstackNetworkCollection.prototype, {
        objectName_: 'openstack-get-tenant-networks',
        itemClass_: OpenstackNetwork,
        allDataSources_: dataSources,
    });

    return OpenstackNetworkCollection;
};

openstackNetworkCollectionFactory.$inject = [
    'Collection',
    'OpenstackNetwork',
];

/**
 * @ngdoc factory
 * @name OpenstackNetworkCollection
 * @author Chitra
 * @extends module:avi/dataModel.Collection
 * @description Collection of Openstack networks.
 *
 **/
angular.module('aviApp')
    .factory('OpenstackNetworkCollection', openstackNetworkCollectionFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name Metric
 * @description
 *
 *     Analytics data layer. Used by collMetrics transport of {@link UpdatableItem}
 *     and {@link Collection}.
 *
 *     Creates a "config" object for collection metrics API call and passes the pre-filtered
 *     response to nested series. We have few types of series - regular (might be multiple),
 *     errors (multiple), errorTotal (up to one) and total (up to one).
 *
 *     Easiest case is only one series - no extra processing should be made.
 *
 *     When we have few regular we might need to calc a total series to be able to show a
 *     summarized version of all the stacked series.
 *
 *     Error series are not being stacked along with regular ones but we show them as another
 *     layer in front of regular ones.
 *
 */
//TODO figure how to configure what and when to pass into nested Series as itemId/itemRef
//TODO call initialize on timeframe change or better support series list updates on the fly
//TODO swith off automatic updates when needed

angular.module('avi/metrics').service('Metric', [
'$q', '$injector', 'Base', 'Timeframe', 'NamesHelper', 'backendDateStringFormat',
function($q, $injector, Base, Timeframe, NamesHelper, backendDateStringFormat) {
    /**
     * @class
     * @extends Base
     **/
    class Metric extends Base {
        constructor(args = {}) {
            super(args);

            /**
             * Name and unique ID of the Metric. Can get from args.name with 'coll.' substring
             * at the beginning because of sharing {@link DataTransformers} dictionary with
             * previous metric version.
             * @type {string}
             */
            // TODO rename to id
            this.name = args.name.indexOf('coll.') === 0 && args.name.slice(5) || args.name;

            /**
             * Title to be shown on UI.
             * @type {string}
             * @protected
             */
            this.title_ = args.title || NamesHelper.totalSeriesTitle(this.getId()) || '';

            /**
             * Instance of Item when needed.
             * @type {Item|undefined}
             */
            this.item = args.item;

            if (this.item) {
                this.itemRef = this.item.getRef();
                this.itemId = this.item.id;
                this.itemType = this.item.getItemType();
            } else {
                this.itemRef = args.itemRef || '';
                this.itemId = args.itemId || this.itemRef.slug();
                this.itemType = args.itemType || '';
            }

            /**
             * Who is listening to this metric, to be able to unsubscribe for each listener.
             * @type {string[]}
             */
            this.subscribers = args.subscribers;

            /**
             * @type {Object}
             * @property {number} step - Number of seconds between data points of a time series.
             * @property {number} limit - Number of data points (of a time series) we want to
             * fetch from the backend.
             * @property {Moment|undefined} lastPoint - Latest date of a data point we have for
             * all(!) series of a Metric. Undefined when don't have any data. Used for
             * incremental updates.
             * @property {function|!number|undefined} frequency - Frequency of updates in
             * seconds between API calls. Function must return a number of seconds or
             * undefined. When
             * undefined {@link Timeframe.selected#interval} will be used.
             */
            this.params = {};

            if (args.params) {
                this.setParams(args.params);
            }

            /**
             * Hash of all series (normal and error) we keep tracking for this Metric.
             * @type {{Series.id: Series}}
             */
            this.seriesHash_ = {};

            /**
             * List of all series but total.
             * @type {Series[]}
             */
            this.series = [];

            /**
             * To pick up the next color for metric with multiple series.
             * @type {number}
             * @private
             */
            this.seriesIndex_ = 0;

            /**
             * List of error series full ids we keep tracking. Don't participate in total
             * calculations.
             * @type {string[]}
             * @protected
             */
            this.errorsSeries_ = args.errorsSeries ?
                args.errorsSeries.map(seriesName => this.getFullSeriesId_(seriesName)) :
                [];

            /**
             * Full id of the stacked error series. Data is provided by the backend.
             * @type {string}
             * @protected
             */
            this.errorsTotal_ = args.errorsTotal ? this.getFullSeriesId_(args.errorsTotal) : '';

            /** @type {string} */
            this.aggregation = args.dimension_aggregation || args.aggregation || '';

            /**
             * Sometimes we do send aggregation as API param but Series come in non-aggregated
             * form nonetheless. To support such case args.isAggregated should be set to true.
             * @type {boolean}
             * @protected
             */
            this.isAggregated_ = false;

            if (this.aggregation) {
                this.isAggregated_ = angular.isUndefined(args.isAggregated) || !!args.isAggregated;
            }

            /** @type {string} */
            this.dimensions = args.dimensions || '';

            if (this.aggregation && angular.isNumber(args['dimensionLimit'])) {
                this.dimensionLimit = args['dimensionLimit'];
            } else {
                /**
                 * @type {number}
                 */
                this.dimensionLimit = 0;
            }

            /**
             * Can apply custom set of key&value pairs on top of traditional request object.
             * @type {Object|null}
             */
            if (angular.isObject(args.filters)) {
                this.filters_ = angular.copy(args.filters);
            } else {
                this.filters_ = null;
            }

            if (args.seriesId) {
                /**
                 * For metrics having multiple series with one seriesId but different itemId/objIds
                 * we can set the seriesId to be used on new series creation.
                 * @type {string}
                 */
                this.seriesId_ = args.seriesId;
            } else {
                this.seriesId_ = '';
            }

            /**
             * When set to false backend response will dictate the list of series this Metric has.
             * @type {boolean}
             * @protected
             */
            this.staticSeriesList_ = true;

            if (!angular.isUndefined(args.staticSeriesList)) {
                this.staticSeriesList_ = !!args.staticSeriesList;
            }

            /**
             * Boolean flag if we have decided to make a request at {@link Metric.beforeCall}
             * and expect to get the back-end response on afterCall. Used to avoid expensive
             * search of our response in a pile of responses (with collection API for
             * instance) and to figure out situations when back-end is not able to provide
             * any data to fulfill the request we've sent but got nothing back.
             * @type {boolean}
             * @private
             */
            this._waitingForResponse = false;

            if (!angular.isUndefined(args.stackedSeries)) {
                /**
                 * Previously when we had more than one series we also used to compute a total
                 * series which was a sum of all regular ones. Now we've added an option to have
                 * multiple series on one chart wo stacking em. This boolean flag is setting which
                 * option do we use.
                 * @type {boolean}
                 * @private
                 */
                this.stackedSeries_ = !!args.stackedSeries;
            } else {
                this.stackedSeries_ = Array.isArray(args.series) && args.series.length > 1;
            }

            /**
             * Series object for the calculated total Series. Only for metric with 'stackedSeries'.
             * @type {Series|null}
             */
            this.totalSeries = null;

            /**
             * @type {string}
             * @protected
             */
            this.seriesConstructorName_ = this.seriesConstructorName_ || '';

            if (args.seriesConstructorName) {
                this.seriesConstructorName_ = args.seriesConstructorName;
            } else if (!this.seriesConstructorName_) {
                // doesn't look like a great idea, feel free to drop if needed
                this.seriesConstructorName_ = this.isAggregated_ ? 'AggSeries' : 'Series';
            }

            /**
             * Series class.
             * @type {Object}
             * @protected
             */
            this.SeriesConstructor_ = $injector.get(this.seriesConstructorName_);

            // Can create metrics by passing seriesId as name in which case it will be used as a
            // single series name.
            if (!args.series && !this.seriesId_ && !this.stackedSeries_) {
                args.series = args.name;
            }

            this.addSeries(
                [].concat(args.series, args.errorsSeries, args.errorsTotal),
            );

            this.setParams_();

            const SupplDataConstructor = $injector.get('SupplMetricData');

            this.supplData = new SupplDataConstructor({
                metric: this,
                loadOnCreate: !!args.withSupplData,
            });
        }

        /**
         * Makes anything before actual call and charts drawing.
         * Needed when we have flexible set of series for one metric.
         * TODO Not scalable as we sometimes need on-fly updates for the seriesHash.
         * @returns {ng.$q.promise}
         * @abstract
         */
        initialize() {
            return $q.when(true);
        }

        /**
         * Returns readable metric name (in contrast to id which is metric.name).
         * @returns {string}
         * @public
         */
        getTitle() {
            if (this.title_) {
                return this.title_;
            }

            return (this.totalSeries ?
                this.totalSeries.getTitle() : this.getMainSeries().getTitle()) || '';
        }

        /**
         * Should return a "config" object that is sufficient for making an analytics API call.
         * @returns {Object}
         * @abstract
         * @returns {null|Object}
         */
        requestConfig() {
            return null;
        }

        /**
         * Returns config object for the anomalies API request.
         * @returns {{series: string}}
         */
        anomaliesConfig() {
            return {
                series: this.getSeriesIdList(),
            };
        }

        /**
         * Returns a total series for complex metrics or single data series for regular one.
         * Filters out error series.
         * @public
         * @returns {Series|null}
         */
        getMainSeries() {
            // stackedSeries can be true but when there is just one series totalSeries is not set
            if (this.stackedSeries_ && this.totalSeries) {
                return this.totalSeries;
            } else {
                const regularSeries = this.getSeriesByType('regular');

                return regularSeries.length ? regularSeries[0] : null;
            }
        }

        /**
         * Adds a list of series into the metric. Doesn't handle error series.
         * @param {string|string[]|SeriesConfig|SeriesConfig[]} seriesIds - Short string form only.
         * @returns {boolean} - True when list of series got updated, false otherwise.
         * @public
         */
        //TODO handle addition of error series
        addSeries(seriesIds) {
            let added = false;

            if (!seriesIds) {
                return added;
            }

            const {
                seriesHash_: seriesHash,
                SeriesConstructor_: SeriesConstructor,
            } = this;

            if (!Array.isArray(seriesIds)) {
                seriesIds = [seriesIds];
            }

            seriesIds.forEach(seriesConfig => {
                if (seriesConfig) {
                    const seriesId = this.getFullSeriesId_(seriesConfig);

                    if (!this.hasSeries(seriesId)) {
                        added = true;

                        const newSeries = new SeriesConstructor(
                            this.getNewSeriesConfig_(seriesConfig),
                        );

                        this.seriesIndex_++;

                        seriesHash[seriesId] = newSeries;
                        this.series.push(newSeries);
                    } else {
                        console.error(
                            `Can't add series "${seriesId}" which is already in metrics`,
                            this,
                        );
                    }
                }
            });

            if (added) {
                this.addOrRemoveTotalSeries_();
                this.postProcessing();
                this.params.lastUpdate = this.getLastPointTime(true);
            }

            return added;
        }

        /**
         * Returns an arguments object to instantiate a new metric Series. Main thing to be
         * passed is a series id. Besides we might want to pass an itemId and/or objId so that
         * series can find it's response in a list of all series responses received for this
         * metric.
         * @param {SeriesConfig|string} seriesConfig - In string form must provide a series Id.
         *     Object form can have any properties which will be put on top of default
         *     series arguments configuration.
         * @returns {SeriesConfig}
         * @protected
         */
        getNewSeriesConfig_(seriesConfig) {
            const
                {
                    aggregation,
                    isAggregated_: isAggregated,
                    dimensionLimit,
                    seriesId_: seriesId,
                } = this,
                fullSeriesConfig = {
                    aggregation,
                    isAggregated,
                };

            if (dimensionLimit) {
                fullSeriesConfig['dimension_limit'] = dimensionLimit;
            }

            if (seriesId) {
                fullSeriesConfig['seriesId'] = seriesId;
            }

            fullSeriesConfig.id = this.getFullSeriesId_(seriesConfig);

            if (!this.staticSeriesList_) {
                fullSeriesConfig.colorClassName =
                    NamesHelper.getColorClassNameByGroupIndex(this.seriesIndex_);
            }

            if (angular.isString(seriesConfig)) {
                seriesConfig = this.seriesIdToConfig_(seriesConfig);
            }

            angular.extend(fullSeriesConfig, seriesConfig);

            return fullSeriesConfig;
        }

        /**
         * Updates a list of series to the passed one. Triggers an event when list actually
         * got updated. Doesn't handle error series in any way.
         * @param {string[]|string|SeriesConfig|SeriesConfig[]} seriesIds - "Short" and full
         *     series id forms are supported for deletion/preserving of existent series but only
         *     short form is supported for series to be created.
         * @returns {boolean} True if list was updated.
         * @public
         */
        //TODO errors, totalErrors, other stuff (left/right, readable names)?
        updateSeriesList(seriesIds) {
            const
                presentSeriesFullIds = this.getSeriesFullIdList_(),
                receivedSeriesFullIds = [],
                newSeriesFullIds = [],
                newSeriesConfigs = [];

            let changed = false;

            if (!Array.isArray(seriesIds)) {
                seriesIds = [seriesIds];
            }

            seriesIds.forEach(seriesConfig => {
                let fullSeriesId;

                // fullId came for one of the existent series
                if (angular.isString(seriesConfig) && seriesConfig in this.seriesHash_) {
                    fullSeriesId = seriesConfig;
                } else {
                    fullSeriesId = this.getFullSeriesId_(seriesConfig);
                }

                receivedSeriesFullIds.push(fullSeriesId);

                if (!this.hasSeries(fullSeriesId)) {
                    newSeriesFullIds.push(fullSeriesId);
                    newSeriesConfigs.push(seriesConfig);
                }
            });

            if (newSeriesConfigs.length) {
                changed = this.addSeries(newSeriesConfigs);
            }

            const toRemove = _.difference(presentSeriesFullIds, receivedSeriesFullIds);

            if (toRemove.length) {
                const removed = this.removeSeries(toRemove);

                changed = changed || removed;
            }

            if (changed) {
                this.trigger(Metric.SERIES_LIST_UPDATE_EVENT, {
                    added: newSeriesFullIds,
                    removed: toRemove,
                }, this);
            }

            return changed;
        }

        /**
         * Removes a list of series from the seriesHash. Error series are supported.
         * @param {string|string[]} seriesIds - Short and full series id forms are supported.
         * @returns {boolean}
         * @public
         */
        removeSeries(seriesIds) {
            let removed = false;

            if (!seriesIds) {
                return removed;
            }

            if (!Array.isArray(seriesIds)) {
                seriesIds = [seriesIds];
            }

            const {
                seriesHash_: seriesHash,
                errorsTotal_: errorsTotal,
                errorsSeries_: errorsSeries,
            } = this;

            seriesIds.forEach(seriesId => {
                if (this.hasSeries(seriesId)) {
                    const
                        { series: seriesList } = this,
                        fullSeriesId = seriesId in seriesHash ?
                            seriesId : this.getFullSeriesId_(seriesId),
                        series = this.getSeries(fullSeriesId);

                    series.destroy();
                    delete seriesHash[fullSeriesId];

                    let pos;

                    if ((pos = seriesList.indexOf(series)) !== -1) {
                        seriesList.splice(pos, 1);
                    }

                    if (fullSeriesId === errorsTotal) {
                        this.errorsTotal_ = '';
                    } else if (pos = _.contains(errorsSeries, fullSeriesId)) {
                        errorsSeries.splice(pos, 1);
                    }

                    removed = true;
                }
            });

            if (removed) {
                this.addOrRemoveTotalSeries_();
                this.postProcessing();
                this.params.lastUpdate = this.getLastPointTime(true);
            }

            return removed;
        }

        /**
         * Removes all regular, error and total series from metric instance.
         * @public
         */
        // drop errorSeries_, totalError props as well?
        removeAllSeries() {
            this.updateSeriesList([]);

            if (this.totalSeries) {
                this.totalSeries.destroy();
                this.totalSeries = null;
            }

            this.params.lastUpdate = undefined;
            this._waitingForResponse = false;

            this.seriesIndex_ = 0;
        }

        /**
         * When adding or removing series we might need to add or drop total series.
         * @protected
         */
        addOrRemoveTotalSeries_() {
            if (this.stackedSeries_) {
                const
                    errorsQ = this.errorsSeries_.length + (this.errorsTotal_ && 1 || 0),
                    totalQ = this.series.length;

                if (totalQ - errorsQ > 1) {
                    if (!this.totalSeries) {
                        this.totalSeries = new this.SeriesConstructor_(
                            this.getNewSeriesConfig_({
                                id: this.getId(),
                                seriesId: this.getId(),
                                title: this.title_,
                            }),
                        );
                    }
                } else if (this.totalSeries) {
                    this.totalSeries.destroy();
                    this.totalSeries = null;
                }
            } else if (this.totalSeries) {
                this.totalSeries.destroy();
                this.totalSeries = null;
            }
        }

        /**
         * Convenience method to get either particular or all the series.
         * @param {string=} seriesId - Can be short or full series id sting form.
         * @returns {Series|Series[]}
         * @public
         */
        getSeries(seriesId) {
            if (seriesId) {
                const { seriesHash_: seriesHash } = this;
                let fullSeriesId;

                if (seriesId in seriesHash ||
                    (fullSeriesId = this.getFullSeriesId_(seriesId)) &&
                    fullSeriesId in seriesHash) {
                    return seriesHash[fullSeriesId || seriesId];
                }

                return [];
            } else {
                return this.series.concat();
            }
        }

        /**
         * Returns list of series by series type.
         * @param {string=} type - Supported values: normal, regular, error, errorTotal, total.
         * @returns {Series|Series[]}
         * @public
         */
        getSeriesByType(type = 'regular') {
            const
                series = this.getSeries(),
                errorIdsHash = _.invert(this.errorsSeries_);

            switch (type) {
                case 'regular':
                    if (this.errorsTotal_) {
                        errorIdsHash[this.errorsTotal_] = true;
                    }

                    return series.filter(
                        series => !(series.getId() in errorIdsHash),
                    );

                case 'error':
                    return series.filter(
                        series => series.getId() in errorIdsHash,
                    );

                case 'errorTotal':
                    return this.errorsTotal_ ? this.seriesHash_[this.errorsTotal_] : null;

                case 'total':
                    return this.stackedSeries_ ? this.totalSeries : null;
            }

            return [];
        }

        /**
         * Returns a total or single error series.
         * @returns {Series|null}
         * @public
         */
        getErrorSeries() {
            return this.getSeriesByType('errorTotal') ||
                this.getSeriesByType('error')[0] || null;
        }

        /**
         * Returns series config based on the short series id. Short series id helps to lookup,
         * add or remove Series within the Metric by some meaningful string. When all series are
         * fetched for one Item and objId then actual seriesId will be a "short series id" (this
         * is the most common case). But if Metric is fetching same series for the different
         * items or objects within one item, short id would be itemId or objId respectively.
         * @param {string} seriesId
         * @returns {SeriesConfig}
         * @private
         */
        seriesIdToConfig_(seriesId) {
            return { seriesId };
        }

        /**
         * Returns a string used as a key of {@link Metric.seriesHash_}. Also there is a legacy
         * code problem of {@link UpdatableItem} which is putting all of the series into one
         * hash therefore if different metric series ids overlap the latest wins.
         * @param {string|SeriesConfig} config - Short series id form only.
         * @returns {string} - Unique full series id to be used as a key in seriesHash and
         *     {@link Item#data} (when applicable).
         * @protected
         */
        getFullSeriesId_(config) {
            if (angular.isString(config)) {
                config = this.seriesIdToConfig_(config);
            }

            const
                { seriesId, objId, dimensionId } = config,
                itemId = config.itemId || this.itemId;

            let fullId = `${itemId}:${seriesId}`;

            if (objId) {
                fullId += `:${objId}`;
            }

            if (dimensionId) {
                fullId += `:${dimensionId}`;
            }

            return fullId;
        }

        /**
         * Returns the list of all metric series. Used to form the request configuration
         * object for analytics collection metrics API call.
         * @returns {string[]}
         * @public
         */
        getSeriesIdList() {
            return _.unique(
                this.series.map(series => series.getSeriesId()),
            );
        }

        /**
         * Returns a list of all full ids of series present in this metric.
         * @returns {string[]}
         * @protected
         */
        getSeriesFullIdList_() {
            return this.series.map(series => series.getId());
        }

        /**
         * Returns the list of all objIds used by all series of this metric. Implicitly assume
         * that all of them are fetching data of objects wthin one or any items.
         * Used by request configuration object.
         * @returns {string[]}
         * @protected
         */
        getObjIdList_() {
            return _.unique(
                this.series
                    .map(series => series.getObjectId())
                    .filter(Boolean),
            );
        }

        /**
         * Returns a list of itemIds which are used by metric series.
         * @returns {string[]}
         */
        getItemIdList_() {
            return _.unique(
                this.series
                    .map(series => series.getItemId())
                    .filter(Boolean),
            );
        }

        /**
         * Returns the selected time frame settings object possibly modified by item this metric
         * belongs to.
         * @returns {Object}
         * @protected
         */
        getTimeFrameSettings_() {
            const
                { item } = this,
                selected = Timeframe.selected();

            if (item && item.hasCustomTimeFrameSettings()) {
                const customSettings = item.getCustomTimeFrameSettings(selected.key);

                if (customSettings) {
                    return angular.extend({}, selected, customSettings);
                }
            }

            return selected;
        }

        /**
         * Sets the step, limit, lastPoint and lastUpdate params. Removes series from or flushes
         * their data depending on Metric type.
         * @protected
         */
        setParams_() {
            const
                { params } = this,
                { step, limit } = this.getTimeFrameSettings_();

            if (params.step !== step || params.limit !== limit) {
                params.lastUpdate = undefined;
                params.lastPoint = undefined;
                params.step = step;
                params.limit = limit;

                if (this.staticSeriesList_) {
                    this.emptySeriesData_();
                } else {
                    this.removeAllSeries();
                }

                this.seriesIndex_ = 0;
                this.trigger(Metric.PARAMS_UPDATE_EVENT, step, limit);
            }
        }

        /**
         * Removes all series data.
         * @protected
         */
        // empty supplData too
        emptySeriesData_() {
            this.series.forEach(series => series.emptyData());

            if (this.totalSeries) {
                this.totalSeries.emptyData();
            }
        }

        /**
         * Returns a config object for metrics and anomalies requests. Or undefined if there
         * is no need in taking part in next call.
         * @returns {Object|null}
         * @public
         */
        beforeCall() {
            const { params } = this;

            //last call wasn't successful (or won't be processed), meaning lastUpdate is
            //misleading
            if (this._waitingForResponse) {
                params.lastUpdate = params.lastPoint || undefined;
            }

            this._waitingForResponse = false;

            this.setParams_();

            if (this.needAnUpdate_()) {
                const
                    requestConfig = this.requestConfig(),
                    anomaliesConfig = this.anomaliesConfig();

                if (requestConfig) {
                    const
                        { step, limit } = params,
                        time = moment();

                    this._waitingForResponse = true;

                    params.lastUpdate = time;

                    const request = {
                        series: {
                            step,
                            limit,
                        },
                        anomalies: {},
                    };

                    if (params.lastPoint && !this.isAggregated_) {
                        request.series.start =
                            encodeURI(moment.utc(params.lastPoint).toISOString());
                        request.anomalies.start = request.series.start;
                    }

                    angular.extend(request.series, requestConfig);
                    angular.extend(request.anomalies, anomaliesConfig);

                    return request;
                }
            }

            return null;
        }

        /**
         * Returns true if Metric should participate in the next update. Checks whether 'step'
         * and 'period'/frequency secs have passed since last update.
         * @returns {boolean}
         * @protected
         */
        needAnUpdate_() {
            const { params } = this;

            if (params.disable) {
                return false;
            }

            const { lastUpdate } = params;

            if (!lastUpdate) {
                return true;
            }

            const {
                frequency,
                lastPoint,
                step,
            } = params;

            const time = moment();

            const mightHaveNewDataPoint = this.isAggregated_ || !lastPoint ||
                time.diff(lastPoint, 's') > step;

            if (!mightHaveNewDataPoint) {
                return false;
            }

            let period;

            if (angular.isFunction(frequency)) {
                period = frequency();
            } else {
                period = frequency;
            }

            if (!angular.isNumber(period)) {
                period = Timeframe.selected().interval;
            }

            return time.diff(lastUpdate, 's') >= period;
        }

        /**
         * Should find response for all series of this Metric. Is needed as multiple Metrics can
         * use one API transport call to fetch the data. Further filtering takes place on a
         * Series level.
         * @param {Object} rsp - Series data.
         * @param {Object} anomRsp - Anomalies data.
         * @returns {Object} - Having series and anomalies properties.
         * @abstract
         */
        filterResponse(rsp, anomRsp) {
            return {
                series: rsp,
                anomalies: anomRsp,
            };
        }

        /**
         * Called by the transport callback providing API call response data.
         * @param {Object} rsp - Series data.
         * @param {Object} anomRsp - Anomalies data.
         */
        afterCall(rsp, anomRsp) {
            if (this._waitingForResponse) {
                this._waitingForResponse = false;

                const filteredRsp = this.filterResponse(rsp, anomRsp);

                this.processResponse(filteredRsp);
                this.postProcessing();
            }
        }

        /**
         * Goes through all tracked Series calling {@link Series.process} with filtered response
         * objects.
         * @param {Object} rsp.series - Series data from API response.
         * @param {Object} rsp.anomalies - Anomalies data from API response.
         */
        processResponse(rsp) {
            const
                { limit, step } = this.params,
                updatedSeriesIds = [];

            if (!this.staticSeriesList_) {
                this.addSeriesToListBasedOnRsp_(rsp);
            }

            this.series.forEach(series => {
                series.process(rsp.series, rsp.anomalies, step, limit);
                updatedSeriesIds.push(series.id);
            });

            if (!this.staticSeriesList_) {
                this.removeEmptySeries_();
            }

            if (updatedSeriesIds.length) {
                this.trigger(Metric.SERIES_UPDATE_EVENT, updatedSeriesIds, this);
            }
        }

        /**
         * Any postprocessing on a Metric level can be done here. Series already have the most
         * current data. Currently we calculate the total series data here and update the
         * lastPoint value.
         */
        postProcessing() {
            this.processTotalSeries_();
            this.updateLastPoint_();
        }

        /**
         * Since we tend to use incremental updates we need to know when we should start from on
         * the next API call. We go through all Series of the {@link Metric.seriesHash_} checking
         * the latest point dates. The earliest of them will become out lastPoint. If at
         * least one of Series doesn't have one we assume that we have no lastPoint at all
         * and will ask the back-end for the whole set of data on the next API call.
         * @protected
         */
        updateLastPoint_() {
            let latestPoint;

            _.find(this.series, series => {
                const latestSeriesPoint = series.getLatestPointTime(true);

                if (latestSeriesPoint) {
                    latestPoint = latestPoint && Math.min(latestPoint, latestSeriesPoint) ||
                        latestSeriesPoint;
                } else {
                    latestPoint = undefined;

                    return true;//meaning break;
                }
            });

            this.params.lastPoint = latestPoint;
        }

        /**
         * Sums up all regular series values to get totalSeries values. We make up the
         * backend response here from the processed series data we already have. It will be
         * processed by the totalSeries in it's usual manner.
         * @protected
         */
        processTotalSeries_() {
            const {
                totalSeries,
                series: seriesList,
            } = this;

            if (!totalSeries) {
                return;
            }

            const {
                step,
                limit,
            } = this.params;

            const
                values = [],
                [regularSeries] = seriesList;

            if (!regularSeries.hasData()) {
                totalSeries.emptyData();

                return;
            }

            const
                totalAnomaliesHash = {},
                anomalyHeaderDescription = [];

            // get totalAnomalies values
            seriesList.forEach(series => {
                const { anomalies } = series;

                anomalyHeaderDescription.push(series.getSeriesId());
                anomalies.forEach(anomaly => {
                    const { timestamp: anomalyHashKey, value } = anomaly;

                    if (totalAnomaliesHash[anomalyHashKey]) {
                        totalAnomaliesHash[anomalyHashKey]++;
                    } else {
                        totalAnomaliesHash[anomalyHashKey] = value;
                    }
                });
            });

            const header = angular.copy(
                regularSeries.getHeader(),
            );

            const anomaliesHeader = {
                name: totalSeries.getSeriesId(),
                metric_description: anomalyHeaderDescription.join(),
                entity_ref: header.entity_ref,
            };

            // to pass usual Series filter function
            header.name = totalSeries.getSeriesId();
            header.metric_description = 'total series made up by UI';
            header.statistics = null;

            // we want to update everything what might be not be settled yet
            let totalTimestamp;

            const totalSeriesParams = totalSeries.getParams();

            // step and limit hasn't changed, update is incremental
            if (totalSeriesParams.step === step && totalSeriesParams.limit === limit) {
                totalTimestamp = totalSeries.getLatestPointTime(true);
            }

            const
                regularSeriesLatestTimestamp = regularSeries.getLatestPointTime(),
                totalAnomalySeriesDataPoints = [];

            if (regularSeriesLatestTimestamp &&
                (!totalTimestamp || totalTimestamp < regularSeriesLatestTimestamp)) {
                if (!totalTimestamp) {
                    totalTimestamp = regularSeries.getFirstPoint().timestamp - step * 1000;
                }

                while (totalTimestamp < regularSeriesLatestTimestamp) {
                    totalTimestamp += step * 1000;

                    let
                        value = 0,
                        noData = true;

                    const timestamp = moment.utc(totalTimestamp).format(backendDateStringFormat);

                    const { errorsTotal_: errorsTotalSeriesId } = this;

                    // we consider value 'real' even if just one of these
                    // have value and all others are fake
                    // eslint-disable-next-line no-loop-func
                    seriesList.forEach(series => {
                        const seriesId = series.getId();

                        if (seriesId === errorsTotalSeriesId) {
                            return;
                        }

                        // TODO filter out error series from the total calculations

                        const dataPoint = series.getDataPoint(totalTimestamp);

                        if (dataPoint) {
                            if (noData && !dataPoint.noData) {
                                noData = false;
                            }

                            value += dataPoint.value;
                        } else if (process.env.NODE_ENV !== 'production') {
                            console.error(
                                `Series ${series.getId()} has no time point ` +
                                `${moment.utc(totalTimestamp).toISOString()}`,
                            );
                        }
                    });

                    const anomalyValue = totalAnomaliesHash[totalTimestamp] || 0;

                    const anomaliesDataPoint = {
                        timestamp,
                        value: anomalyValue,
                    };

                    const dataPoint = {
                        timestamp,
                        value,
                    };

                    if (noData) {
                        dataPoint['is_null'] = true;
                    }

                    totalAnomalySeriesDataPoints.push(anomaliesDataPoint);
                    values.push(dataPoint);
                }
            }

            totalSeries.process(
                {
                    header,
                    data: values,
                },
                {
                    header: anomaliesHeader,
                    data: totalAnomalySeriesDataPoints,
                },
                step,
                limit,
            );
        }

        /**
         * Adds series to metric based on response.
         * @param {{series: Series[]}} rsp
         * @protected
         */
        addSeriesToListBasedOnRsp_(rsp) {
            const { series } = rsp;

            const seriesConfigList = series ? series.map(
                ({ header }) => this.getSeriesConfigBySeriesHeader_(header),
            ) : [];

            const newSeriesConfigs = seriesConfigList.filter(
                config => !this.hasSeries(config),
            );

            this.addSeries(newSeriesConfigs);
        }

        /**
         * When list of series is based on API response we want to drop series which have not a
         * single real point. Can't do it on update event cause of incremental updates nature.
         * @protected
         */
        removeEmptySeries_() {
            const toBeRemoved = this.series.filter(
                series => !series.getLatestPoint(true),
            );

            const toBeRemovedIds = toBeRemoved.map(
                series => series.getId(),
            );

            this.removeSeries(toBeRemovedIds);
        }

        /**
         * Translates SeriesHeader to SeriesConfig.
         * @param {SeriesHeader} header
         * @returns {SeriesConfig}
         * @private
         */
        getSeriesConfigBySeriesHeader_(header) {
            const {
                name: seriesId,
                obj_id: objId,
                entity_uuid: itemId,
                entity_ref: itemRef,
                pool_uuid: poolId,
                dimension_data: dimensionData,
            } = header;

            let dimensionId = '';

            if (dimensionData) {
                dimensionId = dimensionData[0]['dimension_id'];
            }

            return {
                seriesId,
                objId,
                itemId: poolId || itemId || itemRef && itemRef.slug(),
                dimensionId,
            };
        }

        /**
         * Method to get either exact metric value based on a timestamp or statistic such
         * as avg, total or sum. Since metric might have few series default behaviour is to use
         * `main` series or one which name had been passed as an argument.
         * @param {SeriesDisplayValueType} type
         * @param {string=} seriesName - When passed value will be provided by that series.
         * @param {number|Moment=} timestamp - Works with 'exact' type only.
         * @returns {number|string|undefined}
         * @public
         */
        getValue(type, seriesName, timestamp) {
            if (type) {
                const series = seriesName ? this.getSeries(seriesName) : this.getMainSeries();

                return series ? series.getValue(type, timestamp) : undefined;
            }
        }

        /**
         * To render {@link timingTiles} of the Metric.
         * @returns {timingTilesConfig|null}
         * @public
         * @abstract
         */
        getTimingTilesConfig() {
            return null;
        }

        /**
         * Returns the most recent time point present in all series belonging to this Metric.
         * @returns {Moment|undefined}
         * @public
         */
        getLastPointTime() {
            return this.params.lastPoint;
        }

        /**
         * Returns metric Id.
         * @returns {string}
         * @public
         */
        getId() {
            return this.name;
        }

        /**
         * If no argument passed returns true if metric has any series, false otherwise. When
         * series name is passed will check for that particular series in a hash. Doesn't
         * work for totalSeries.
         * @param {string=} seriesId - Short and full series ids are supported.
         * @returns {boolean}
         * @public
         */
        hasSeries(seriesId) {
            if (!seriesId) {
                return !!this.series.length;
            }

            const { seriesHash_: seriesHash } = this;

            return seriesId in seriesHash || this.getFullSeriesId_(seriesId) in seriesHash;
        }

        /**
         * Checks whether Metric has series with data.
         * @returns {boolean}
         * @public
         */
        hasData() {
            const series = this.getMainSeries();

            return series && series.hasData() || false;
        }

        /**
         * Starts loading events, alert, anomalies, etc for the current instance.
         * @returns {ng.$q.promise}
         * @public
         */
        loadSupplData() {
            return this.supplData.activate();
        }

        /**
         * Stops loading events, alert, anomalies, etc for the current instance.
         * @returns {ng.$q.promise}
         * @public
         */
        stopSupplDataLoad() {
            return this.supplData.stopUpdates();
        }

        /**
         * Returns true if metric is waiting for an update.
         * @returns {boolean}
         * @public
         */
        isLoading() {
            return this._waitingForResponse;
        }

        /**
         * Sets filters to be used as extra params for API calls.
         * @param {{string: string}} filtersHash - Key value pairs.
         * @public
         */
        setFilters(filtersHash) {
            let updated = false;

            if (angular.isObject(filtersHash)) {
                if (!_.isEqual(filtersHash, this.filters_)) {
                    this.filters_ = angular.copy(filtersHash);
                    updated = true;
                }
            } else if (this.filters_) {
                this.filters_ = null;
                updated = true;
            }

            if (updated) {
                this.removeAllSeries();
            }
        }

        /**
         * Changes internal Metric params. For now is used to change frequency of updates only.
         * Doesn't affect request params directly.
         * @param {Object} params
         * @public
         */
        setParams(params) {
            if (angular.isObject(params)) {
                angular.extend(this.params, params);
            }
        }
    }

    //series list update event name
    Metric.SERIES_LIST_UPDATE_EVENT = 'seriesListUpdate';

    //if any of series got updated
    Metric.SERIES_UPDATE_EVENT = 'seriesUpdate';

    //if step and limit got updated
    Metric.PARAMS_UPDATE_EVENT = 'paramsUpdateEvent';

    //if main Item this metric is about got updated
    //TODO trigger, need method to change the main Metric item
    Metric.ITEM_UPDATE_EVENT = 'itemUpdateEvent';

    return Metric;
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @typedef {Object} UserProfile
 * @description
 *     Used to configure user account access settings.
 */
angular.module('aviApp').factory('UserProfile', ['Item', Item => {
    const UserProfile = class extends Item {};

    angular.extend(UserProfile.prototype, {
        objectName: 'useraccountprofile',
        windowElement: 'user-profile-create',
    });

    return UserProfile;
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './DnsServiceController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name  DnsServiceController
 * @description
 *     Controller for Administration -> Settings -> DNS Service page.
 *     Works with a local copy of SystemConfig.data.dns_virtualservice_refs array which is
 *     updated on successful save event.
 */
//TODO preserve empty placeholders added by user on save events
class DnsServiceController {
    constructor(
            defaultValues,
            systemConfig,
            VirtualServiceCollection,
            AviConfirmService,
            VirtualService,
            l10nService,
    ) {
        this.systemConfig_ = systemConfig;
        this.AviConfirmService_ = AviConfirmService;
        this.l10nKeys = l10nKeys;
        this.l10nService_ = l10nService;

        l10nService.registerSourceBundles(dictionary);

        this.virtualServiceCollection = new VirtualServiceCollection({
            isStatic: true,
            params: {
                'application_profile_ref.type': 'APPLICATION_PROFILE_TYPE_DNS',
            },
        });

        defaultValues.load().then(
            () => {
                this.virtualServiceCollection.setNewItemDefaults({
                    application_profile_ref:
                            defaultValues.getSystemObjectRefByName(
                                'applicationprofile', 'System-DNS',
                            ),
                    network_profile_ref:
                            defaultValues.getSystemObjectRefByName(
                                'networkprofile', 'System-UDP-Per-Pkt',
                            ),
                    services:
                            [VirtualService.getDefaultServiceConfig({ port: 53 })],
                });
            },
        );

        this.updateDNSVSrefs_();
    }

    /**
     * Updates the local copy of dns_virtualservice_refs array.
     * @private
     */
    updateDNSVSrefs_() {
        this.dnsVSRefs = this.systemConfig_.data.config['dns_virtualservice_refs'].concat();
        this.updateFilters();
    }

    /**
     * Drops VS ref from the list.
     * @param {string} ref - VS.ref
     * @private
     */
    deleteDnsRefs_(ref) {
        if (ref) {
            this.systemConfig_.patch({
                delete: {
                    dns_virtualservice_refs: [ref],
                },
            });
        }
    }

    /**
     * Handles removing {VirtualService} from the dns_virtualservice_refs list when trash icon
     * is clicked and removes that ref from the list.
     * @param {string} ref - VS.ref
     * @param {number} index - Ref index in DNSVSrefs array.
     */
    handleRemove(ref, index) {
        const { l10nService_: l10nService } = this;

        if (!ref) {
            this.dnsVSRefs.splice(index, 1);
            this.updateFilters();
        } else {
            this.AviConfirmService_
                .confirm(l10nService.getMessage(l10nKeys.discardConfirmMessage))
                .then(() => {
                    this.dnsVSRefs.splice(index, 1);
                    this.updateFilters();
                    this.deleteDnsRefs_(ref);
                });
        }
    }

    /**
     * Updates filter params for collection API.
     */
    updateFilters() {
        this.virtualServiceCollection.setParams({
            'uuid.in': this.dnsVSRefs.map(val => val && val.slug()).join(),
            exclude: 'uuid.in',
        });
    }

    /**
     * Saves configuration settings. Called when VS got changed.
     * @public
     **/
    save() {
        this.updateFilters();
        this.systemConfig_.patch({
            replace: {
                dns_virtualservice_refs: this.dnsVSRefs.concat(),
            },
        });
    }

    /**
     * Disables inputs and buttons when updated Items is being saved.
     * @returns {boolean}
     */
    isBusy() {
        return this.systemConfig_.busy;
    }

    $onDestroy() {
        this.virtualServiceCollection.destroy();
    }
}

DnsServiceController.$inject = [
        'defaultValues',
        'loadedSystemConfigService',
        'VirtualServiceCollection',
        'AviConfirmService',
        'VirtualService',
        'l10nService',
];

angular.module('aviApp').controller('DnsServiceController', DnsServiceController);

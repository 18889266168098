const moduleName = 'ajs-legacy';
const componentName = 'upload-packages-controller';
const prefix = `${moduleName}.${componentName}`;

export const selectHsmPackageUploadMessage = `${prefix}.selectHsmPackageUploadMessage`;
export const selectFileBtnLabel = `${prefix}.selectFileBtnLabel`;
export const uploadFileBtnLabel = `${prefix}.uploadFileBtnLabel`;
export const cancelUploadBtnLabel = `${prefix}.cancelUploadBtnLabel`;
export const errorLabel = `${prefix}.errorLabel`;

export const ENGLISH = {
    [selectHsmPackageUploadMessage]: 'Please select the HSM package to upload.',
    [selectFileBtnLabel]: 'Select File',
    [uploadFileBtnLabel]: 'Upload File',
    [cancelUploadBtnLabel]: 'Cancel Upload',
    [errorLabel]: 'Error: {0}',
};

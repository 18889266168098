/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function alertActionFactory(Item) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Item
     * @author Ram Pal
     * @desc Alert actions.
     */
    class AlertAction extends Item {}

    Object.assign(AlertAction.prototype, {
        objectName_: 'actiongroupconfig',
        windowElement_: 'adm-alertaction-create',
    });

    return AlertAction;
}

alertActionFactory.$inject = [
    'Item',
];

angular.module('avi/app')
    .factory('AlertAction', alertActionFactory);

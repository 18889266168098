/**
 * @module CoreModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    copy,
    IHttpPromise,
    IHttpResponse,
    IPromise,
    IRootScopeService,
} from 'angular';

import { diff as deepDiff } from 'deep-diff';
import { debounce, isUndefined } from 'underscore';
import { Base } from 'ajs/modules/data-model';
import { HttpWrapper, IHttpWrapperRequestConfig } from 'ajs/modules/core';
import { IControllerProperties } from 'generated-types';
import { StateService } from '@uirouter/core';
import { getResolvablePromise } from 'ng/shared/utils';

interface IUserAccount {
    confirm_password?: string;
    email: string;
    full_name: string;
    is_superuser: boolean;
    local: boolean;
    name: string;
    password?: string;
    username: string;
}

type TGridData = {
    [key: string]: IGridInfo;
} & {
    pageSize?: number;
};

export interface IGridInfo {
    columnSortOrder?: string;
    displayFields?: string[];
    columnWidths?: Record<string, number>;
}

interface ILogData {
    savedSearch?: string[],
    sidebarActiveTab?: string;
    hideLogSummaries?: boolean;
}

interface IUIProperty {
    appDashboard?: Record<string, string>;
    defaultTimeframe?: string;
    grid?: TGridData;
    language?: string;
    logs?: ILogData;
    performanceControls?: Record<string, boolean>;
    showMetricsLogs?: boolean; // deprecated, for prior 16.3v
    sideRailOpen?: boolean;
    useUTCTime?: boolean;
    valuesToDisplay?: string;
}

const CONTROLLER_PROPERTIES_URL = '/api/controllerproperties';
const UI_PROPERTIES_URL = '/api/userpreferences';
const USER_ACCOUNT_URL = '/api/useraccount';
const VALUES_TO_DISPLAY_CHANGE_EVENT = 'MyAccount valuesToDisplayChange';

/**
 *
 * @desc Service to load and maintain controllerProperties, userPreferences & userAccount details.
 *
 * @author Aravindh Nagarajan
 */
export class MyAccount extends Base {
    /**
     * UI Property object.
     */
    public uiProperty: IUIProperty = {};
    /**
     * UI Property clone - to check for change.
     */
    private uiPropertyClone: IUIProperty = {};
    /**
     * Current user account data.
     */
    private accountData = {} as unknown as IUserAccount;
    /**
     * Current user account data clone - to check for mutation.
     */
    private accountDataClone = {} as unknown as IUserAccount;
    /**
     * Controller properties.
     */
    private controllerProperties: IControllerProperties;
    /**
     * Promise used when loading user account and preferences.
     */
    private loadPromise: IPromise<void> | null = null;
    /**
     * Promise used when loading user account and preferences.
     */
    private savePromise: IPromise<void> | null = null;
    /**
     * Indicates initial loading is complete. Has to be false by default.
     */
    private loaded = false;

    private readonly httpWrapper: HttpWrapper;

    constructor() {
        super();

        /**
         * Prevent saveUIProperty HTTP request from being invoked too often.
         */
        this.saveUIProperty = debounce(this.saveUIProperty.bind(this), 0);

        const HttpWrapper = this.getAjsDependency_('HttpWrapper');

        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Informs if user set app to use UTC time.
     */
    public get useUtc(): boolean {
        return Boolean(this.uiProperty.useUTCTime);
    }

    /**
     * Getter for preferred language.
     */
    public get language(): string | undefined {
        return this.uiProperty.language;
    }

    /**
     * Getter for grid settings.
     */
    public get grid(): TGridData | undefined {
        return this.uiProperty.grid;
    }

    /**
     * Reload UI state on user language preference change. So that we can load the language pack
     * and recompile all the template expressions.
     */
    public onLanguageChange(): void {
        const $state: StateService = this.getAjsDependency_('$state');

        $state.reload();
    }

    /**
     * Loads user preferences and account data.
     */
    public load(): IPromise<void> {
        if (this.loadPromise) {
            return this.loadPromise;
        } else if (this.savePromise) {
            return this.savePromise.then(() => this.load_());
        } else {
            return this.load_();
        }
    }

    /**
     * Loads controller properties.
     */
    public getControllerProperties(): IPromise<void> {
        const controllerPropertiesRequestConfig: IHttpWrapperRequestConfig = {
            method: 'GET',
            url: CONTROLLER_PROPERTIES_URL,
        };

        return this.httpWrapper.request(controllerPropertiesRequestConfig)
            .then(({ data }: IHttpResponse<IControllerProperties>) => {
                this.controllerProperties = data;
            });
    }

    /**
     * Saves controller properties.
     */
    public saveControllerProperties(): IHttpPromise<any> {
        const controllerPropertiesSaveConfig: IHttpWrapperRequestConfig = {
            method: 'PUT',
            url: CONTROLLER_PROPERTIES_URL,
            data: this.controllerProperties,
        };

        return this.httpWrapper.request(controllerPropertiesSaveConfig);
    }

    /**
     * Returns boolean value indicating initial load is complete. Doesn't prevent from loading
     * multiple times, but in most cases application does not need to reload user preferences
     * multiple times.
     */
    public isLoaded(): boolean {
        return this.loaded;
    }

    /**
     * Checks if original data matches modified data.
     * @returns - True if user preferences or account data doesn't match original
     *     copies of those objects.
     */
    public isModified(): boolean {
        return Boolean(deepDiff(this.uiProperty, this.uiPropertyClone)) ||
            Boolean(deepDiff(this.accountData, this.accountDataClone));
    }

    /**
     * Reverts changes to stored cloned object.
     */
    public revertChanges(): void {
        this.uiProperty = this.uiPropertyClone;
        this.accountData = this.accountDataClone;
    }

    /**
     * Checks if specified UI property exists within {@link this.uiProperty} object and returns
     * value of that property.
     * @param propertyName - Name of the property to find.
     * @returns Value assigned to specified property name.
     */
    public hasUIProperty(propertyName: string): boolean | any {
        return propertyName in this.uiProperty && this.uiProperty[propertyName];
    }

    /**
     * Checks asynchronously if specified ui property exists. Doesn't do nested properties
     * checks.
     * @param propertyName - Property name of ui_property.
     * @returns - Promise resolves specified property name value.
     */
    public checkUIProperty(propertyName: string): IPromise<any> {
        const promise = getResolvablePromise<any>();

        if (this.loaded) {
            promise.resolve(this.hasUIProperty(propertyName));
        } else {
            this.load().then(() => {
                promise.resolve(this.hasUIProperty(propertyName));
            });
        }

        return promise;
    }

    /**
     * Checks multiple ui property names as arguments.
     * @param values - Repeated string arguments.
     * @returns - Promise that resolves to an array of values from
     *     specified arguments.
     */
    public checkUIProperties(...values: string[]): IPromise<any[]> {
        return Promise.all(values.map(value => this.checkUIProperty(value)));
    }

    /**
     * Saves user preferences to server.
     */
    public saveUIProperty(): IPromise<void> {
        const { uiProperty } = this;

        const payload = {
            ui_property: '',
        };

        if (Object.keys(uiProperty).length) {
            payload.ui_property = JSON.stringify(uiProperty);
        }

        const uiPropertiesSaveRequestConfig: IHttpWrapperRequestConfig = {
            url: UI_PROPERTIES_URL,
            method: 'PUT',
            data: payload,
        };

        this.savePromise = this.httpWrapper.request(uiPropertiesSaveRequestConfig)
            .then(() => this.savePromise = null);

        return this.savePromise;
    }

    /**
     * Saves account data to server.
     */
    public saveAccount(): IHttpPromise<void> {
        const userAccountSaveRequestConfig: IHttpWrapperRequestConfig = {
            url: USER_ACCOUNT_URL,
            method: 'PUT',
            data: this.accountData,
        };

        return this.httpWrapper.request(userAccountSaveRequestConfig);
    }

    /**
     * Checks if account password and confirm password match.
     * @returns true if password and confirm password match or password is empty.
     */
    public passwordsMatch(): boolean {
        if (this.accountData && this.accountData.password) {
            return this.accountData.password === this.accountData.confirm_password;
        }

        return true;
    }

    /**
     * Changes uiProperty.valuesToDisplay by metricValuesSelector and saves setting.
     */
    public setValuesToDisplay(value: string): void {
        this.uiProperty.valuesToDisplay = value;
        this.trigger(VALUES_TO_DISPLAY_CHANGE_EVENT, value);
        this.saveUIProperty();
    }

    /**
     * Changes uiProperty.valuesToDisplay by metricValuesSelector and saves setting.
     */
    public saveDefaultTimeframe(value: string): void {
        this.uiProperty.defaultTimeframe = value;
        this.saveUIProperty();
    }

    /**
     * Returns "valuesToDisplay" value (avg/total/peak).
     */
    public getValuesDisplayType(): string {
        return this.uiProperty.valuesToDisplay;
    }

    /**
     * Toggles sidebar state.
     * @param desiredState - When true is passed will open sidebar, when false is
     *     passed will close it.
     */
    public toggleSidebar(desiredState?: string): void {
        const { uiProperty } = this;
        const { sideRailOpen: prevValue } = uiProperty;
        const newValue = isUndefined(desiredState) ? !prevValue : !!desiredState;

        const $rootScope: IRootScopeService = this.getAjsDependency_('$rootScope');

        if (prevValue !== newValue) {
            uiProperty.sideRailOpen = newValue;
            this.saveUIProperty();

            setTimeout(() => {
                $rootScope.$broadcast('repaint');
                $rootScope.$broadcast('$repaintViewport');
            }, 49);
        }
    }

    /**
     * Loads user preferences and account data.
     */
    // eslint-disable-next-line no-underscore-dangle
    private load_(): IPromise<void> {
        const userAccountRequestConfig: IHttpWrapperRequestConfig = {
            method: 'GET',
            url: USER_ACCOUNT_URL,
        };

        const uiPropertiesRequestConfig: IHttpWrapperRequestConfig = {
            method: 'GET',
            url: UI_PROPERTIES_URL,
        };

        const requests = [
            this.httpWrapper.request(userAccountRequestConfig),
            this.httpWrapper.request(uiPropertiesRequestConfig),
        ];

        this.loadPromise = Promise.all(requests)
            .then((resp: [IHttpResponse<IUserAccount>, IHttpResponse<Record<string, string>>]) => {
                this.dataHandler(resp);
            })
            .finally(() => this.loadPromise = null);

        return this.loadPromise;
    }

    /**
     * Checks all required ui_profile properties which are used throughout the app and fulfills
     * them with default values when not present. Calls MyAccount#saveUIProperty when it got
     * updated.
     */
    private uiPropertyTransformAfterLoad(): void {
        let hasChangedUIProperty = false;

        if (!this.hasUIProperty('defaultTimeframe')) {
            hasChangedUIProperty = true;
            this.uiProperty.defaultTimeframe = '6h';
        }

        if (!this.hasUIProperty('valuesToDisplay')) {
            hasChangedUIProperty = true;
            this.uiProperty.valuesToDisplay = 'avg';
        }

        if (!('sideRailOpen' in this.uiProperty)) {
            hasChangedUIProperty = true;
            this.uiProperty.sideRailOpen = true;
        }

        if (!this.hasUIProperty('logs') || !('savedSearch' in this.uiProperty.logs) ||
            !('sidebarActiveTab' in this.uiProperty.logs)) {
            hasChangedUIProperty = true;

            const appLogsProp = this.uiProperty.logs || {};

            if (!('savedSearch' in appLogsProp)) {
                appLogsProp.savedSearch = [];
            }

            if (!('sidebarActiveTab' in appLogsProp)) {
                appLogsProp.sidebarActiveTab = '1';
            }

            this.uiProperty.logs = appLogsProp;
        }

        // migration for pre 16.3 versions
        if (!isUndefined(this.uiProperty.showMetricsLogs)) {
            hasChangedUIProperty = true;

            if (!('hideLogSummaries' in this.uiProperty.logs)) {
                this.uiProperty.logs.hideLogSummaries =
                        !this.uiProperty.showMetricsLogs;
            }

            delete this.uiProperty.showMetricsLogs;
        }

        if (!this.hasUIProperty('appDashboard') ||
            !('viewType' in this.uiProperty.appDashboard)) {
            hasChangedUIProperty = true;

            const appDashProp = this.uiProperty.appDashboard || {};

            appDashProp.viewType = 'list';
            this.uiProperty.appDashboard = appDashProp;
        }

        if (!this.hasUIProperty('grid')) {
            this.uiProperty.grid = {};
        } else if (!this.uiProperty.grid.pageSize) {
            this.uiProperty.grid.pageSize = 30;
        }

        if (!this.hasUIProperty('language')) {
            /**
             * Default language expected to be english
             */
            this.uiProperty.language = 'en_US';
        }

        if (hasChangedUIProperty) {
            this.saveUIProperty();
        }
    }

    /**
     * Handles user account and user preferences data. Checks required ui_profile fields and
     * fulfills them when not present. Also sets Timeframe#defaultValue_.
     * @param userAccount - Server data for user account and user preferences data.
     * @param prefs
     */
    private dataHandler([
        { data: userAccount },
        { data: prefs },
    ]: [
        IHttpResponse<IUserAccount>,
        IHttpResponse<Record<string, string>>,
    ]): void {
        this.loaded = true;
        this.loadPromise = null;
        this.accountData = userAccount;

        const uiProperty = prefs || {};
        const timeFrame = this.getAjsDependency_('Timeframe');

        uiProperty.ui_property = uiProperty.ui_property ?
            JSON.parse(uiProperty.ui_property) : {};

        this.uiProperty = uiProperty.ui_property as IUIProperty;

        this.uiPropertyTransformAfterLoad();

        timeFrame.setDefaultValue(this.uiProperty.defaultTimeframe);
        timeFrame.set(this.uiProperty.defaultTimeframe);

        this.accountDataClone = copy(this.accountData);
        this.uiPropertyClone = copy(this.uiProperty);
    }
}

MyAccount.ajsDependencies = [
    '$rootScope',
    'Timeframe',
    'vipService',
    '$state',
    'HttpWrapper',
];

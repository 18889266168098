/**
 * @module UpdateModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ClrLoadingState } from '@cds/core/button';
import { Observable, Subscription } from 'rxjs';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './compliance-mode-confirmation.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export interface IComplianceModeConfig {
    enableFips: boolean;
    enableCommonCriteria: boolean;
}

export interface IComplianceModeConfirmationStatus {
    busy: boolean;
    error: string | null;
}

@Component({
    selector: 'compliance-mode-confirmation',
    templateUrl: './compliance-mode-confirmation.component.html',
})
export class ComplianceModeConfirmationComponent implements OnInit, OnDestroy {
    /**
     * If true, automatically sets enableFips to true and disables changing that setting. True if
     * FIPS has been configured but is non-operational.
     */
    @Input()
    public overrideEnableFips = false;

    /**
     * If true, automatically sets enableCommonCriteria to true and disables changing that setting.
     * True if Common Criteria has been configured but is non-operational.
     */
    @Input()
    public overrideEnableCommonCriteria = false;

    /**
     * Observable used to track the status of the component. Contains the busy and error properties.
     */
    @Input()
    public status$ = new Observable<IComplianceModeConfirmationStatus>();

    /**
     * Called on modal close.
     */
    @Output()
    public onClose = new EventEmitter();

    /**
     * Called on modal submit.
     */
    @Output()
    public onSubmit = new EventEmitter<IComplianceModeConfig>();

    /**
     * Loading state of the submit button. Set to 'loading' when submitting the request.
     */
    public loadingState: ClrLoadingState = ClrLoadingState.default;

    /**
     * Error state of the component. Set to a backend error when the request to enable FIPS fails.
     */
    public error: string | null = null;

    /**
     * ngModel value to configure FIPS mode.
     */
    public enableFips = false;

    /**
     * ngModel value to configure Common Criteria.
     */
    public enableCommonCriteria = false;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Subscription to the status$ observable. Unsubscribed on component's destroy event.
     */
    private statusSubscription: Subscription;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.overrideEnableFips) {
            this.enableFips = true;
        }

        if (this.overrideEnableCommonCriteria) {
            this.enableCommonCriteria = true;
        }

        this.statusSubscription = this.status$.subscribe(
            (status: IComplianceModeConfirmationStatus) => {
                this.error = status.error;
                this.loadingState = status.busy ? ClrLoadingState.loading : ClrLoadingState.default;
            },
        );
    }

    /** @override */
    public ngOnDestroy(): void {
        this.statusSubscription.unsubscribe();
    }

    /**
     * Called when the checkbox for enabling FIPS has been toggled. If enable FIPS is turned off,
     * Common Criteria cannot be enabled.
     */
    public handleEnableFipsChange(): void {
        if (!this.enableFips) {
            this.enableCommonCriteria = false;
        }
    }

    /**
     * Called if the user confirms changing to this CRS version.
     */
    public handleSubmit(): void {
        const { enableFips, enableCommonCriteria } = this;

        this.onSubmit.emit({
            enableFips,
            enableCommonCriteria,
        });
    }

    /**
     * Called if the user decides not to change to this CRS version.
     */
    public handleCancel(): void {
        this.onClose.emit();
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './ssl-certificate-imported-information.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name sslCertificateImportedInformation
 * @description
 *     Displays ssl certificate imported information.
 */
class SslCertificateImportedInformationController {
    constructor(l10nService) {
        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }
}

SslCertificateImportedInformationController.$inject = [
    'l10nService',
];

angular.module('aviApp').component('sslCertificateImportedInformation', {
    bindings: {
        certificate: '<',
    },
    controller: SslCertificateImportedInformationController,
    templateUrl: 'src/components/modals/templates/security/ssl-tls-certificates/' +
            'ssl-certificate-imported-information/ssl-certificate-imported-information.html',
});

/**
 * @module IpamModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import {
    ClrFormsModule,
    ClrInputModule,
} from '@clr/angular';

import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'ng/shared/shared.module';

import { CustomIpamDnsProfileModalComponent } from '.';

const ipamComponents = [
    CustomIpamDnsProfileModalComponent,
];

/**
 * @description
 *
 *      Angular Module for IPAM related components.
 *
 * @author Aravindh Nagarajan
 */
@NgModule({
    declarations: [
        ...ipamComponents,
    ],
    imports: [
        FormsModule,
        SharedModule,
        BrowserAnimationsModule,
        ClrInputModule,
        ClrFormsModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class IpamModule {}

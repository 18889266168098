/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  ErrorPageProfileCollection
 * @description  Collection of {WafPolicy} items.
 */
const ErrorPageProfileCollection = (Collection, ErrorPageProfile) => {
    class ErrorPageProfileCollection extends Collection {}

    angular.extend(ErrorPageProfileCollection.prototype, {
        objectName_: 'errorpageprofile',
        itemClass_: ErrorPageProfile,
        windowElement_: 'error-page-profile-modal',
    });

    return ErrorPageProfileCollection;
};

ErrorPageProfileCollection.$inject = [
        'Collection',
        'ErrorPageProfile',
];

angular.module('aviApp').factory('ErrorPageProfileCollection', ErrorPageProfileCollection);

/**
 * Notifications module
 * @module NotificationsModule
 * @preferred
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    ControllerFault,
    ControllerFaultCollection,
    FaultService,
    snmpController,
    syslogController,
} from '.';

const notificationsModule = angular.module('avi/notifications');

const controllers = [
    {
        controller: snmpController,
        name: 'SnmpController',
    },
    {
        controller: syslogController,
        name: 'SyslogController',
    },
];

controllers.forEach(({ name, controller }) => notificationsModule.controller(name, controller));

const factories = [
    {
        name: 'ControllerFault',
        factory: ControllerFault,
    },
    {
        name: 'ControllerFaultCollection',
        factory: ControllerFaultCollection,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(notificationsModule, 'factory', name, factory);
});

const services = [
    {
        name: 'FaultService',
        service: FaultService,
    },
];

services.forEach(({ name, service }) => {
    initAjsDependency(notificationsModule, 'service', name, service);
});

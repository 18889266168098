/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './user-card.less';
import * as l10n from './user-card.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const componentName = 'user-card';

const myAccountModalId = 'prof-myaccount-edit';

/**
 * @class
 * @constructor
 * @memberOf module:avi/navigation
 * @mixes module:avi/navigation.userCardBindings
 * @see {@link  module:avi/navigation.userCardComponent}
 */
class UserCardController {
    constructor(
        Auth,
        AviModal,
        l10nService,
        stringService,
        initialDataService,
    ) {
        this.AviModal_ = AviModal;
        this.Auth_ = Auth;
        this.l10nKeys = l10nKeys;
        this.aviModalService_ = AviModal;

        /**
         * User's last login detail.
         * @type {string}
         */
        this.lastLogin = '';

        /**
         * Logged in user's information.
         * @type {Object}
         */
        this.userData = Auth.getCurrentUserData();

        /**
         * @type {StringService}
         * @protected
         */
        this.stringService_ = stringService;

        /**
         * @type {initialDataService}
         * @protected
         */
        this.initialDataService_ = initialDataService;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onChanges({ userActivity }) {
        if (userActivity.currentValue) {
            this.setLastLogin_(userActivity.currentValue);
        }
    }

    /**
     * Sets last login of user in readable format.
     * Will be called only for the users with 'PERMISSION_USER' access.
     * @param {UserActivity} userActivity
     * @protected
     */
    setLastLogin_(userActivity) {
        let lastLogin = '';

        /**
         * Time difference between controller and client clocks.
         * @type {number}
         */
        const { controllerTimeDifference } = this.initialDataService_;

        if (userActivity && userActivity.last_login_timestamp) {
            lastLogin += moment
                .utc(userActivity.last_login_timestamp)
                .add(controllerTimeDifference, 'seconds')
                .fromNow();
        }

        this.lastLogin = this.stringService_.capitalize(lastLogin);
    }

    /**
     * Opens the Account modal popup.
     */
    handleOpenMyAccount() {
        this.aviModalService_.open(myAccountModalId);
    }

    /** @override */
    $onDestroy() {
        const { aviModalService_: aviModalService } = this;

        if (aviModalService.isOpen(myAccountModalId)) {
            aviModalService.destroy(myAccountModalId);
        }
    }
}

UserCardController.$inject = [
    'Auth',
    'AviModal',
    'l10nService',
    'stringService',
    'initialDataService',
];

/**
 * @name userCardComponent
 * @memberOf module:avi/navigation
 * @property {module:avi/navigation.UserCardController} controller
 * @property {module:avi/navigation.userCardBindings} bindings
 * @desc
 *      Component for List item containing user account details
 *      (name, email, last login information).
 * @author Aravindh Nagarajan
 */
angular.module('avi/navigation').component('userCard', {
    /**
     * @mixin userCardBindings
     * @memberOf module:avi/navigation
     * @property {UserActivity} userActivity Loggedin user's activity information
     */
    bindings: {
        userActivity: '<',
    },
    controller: UserCardController,
    templateUrl:
        `src/components/avi-header/user-menu/${componentName}/${componentName}.html`,
});

const moduleName = 'ajs-legacy';
const componentName = 'service-engine-group-list-expander';
const prefix = `${moduleName}.${componentName}`;

export const serviceEnginesHeader = `${prefix}.serviceEnginesHeader`;
export const virtualServicesHeader = `${prefix}.virtualServicesHeader`;
export const emptyListMessage = `${prefix}.emptyListMessage`;

export const ENGLISH = {
    [serviceEnginesHeader]: 'Service Engines',
    [virtualServicesHeader]: 'Virtual Services',
    [emptyListMessage]: 'None',
};

/**
 * @module CloudModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { Cloud } from 'ajs/js/services/items/Cloud';

export enum CLOUD_TYPE {
    CLOUD_NONE = 'CLOUD_NONE',
    CLOUD_VCENTER = 'CLOUD_VCENTER',
    CLOUD_OPENSTACK = 'CLOUD_OPENSTACK',
    CLOUD_AWS = 'CLOUD_AWS',
    CLOUD_VCA = 'CLOUD_VCA',
    CLOUD_LINUXSERVER = 'CLOUD_LINUXSERVER',
    CLOUD_AZURE = 'CLOUD_AZURE',
    CLOUD_GCP = 'CLOUD_GCP',
    CLOUD_NSXT = 'CLOUD_NSXT',
}

/**
 * Additional properties defined here as Cloud exists in a JS file, so extended properties are not
 * able to be picked up.
 */
export interface ICloud extends Cloud {
    config: any;
    data: any;
    id: string;
    getConfig(): ICloud['config'];
    getName(): string;
    getRef(): string;
    setPristine(): void;
    submit(): Promise<void>;
}

/** @module WafModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { IAviDataGridConfig } from 'ng/shared/components';
import { RepeatedMessageItem } from 'ajs/modules/data-model';
import {
    WafExcludeListEntryConfigItem,
    WafRuleGroupConfigItem,
    WafRuleGroupOverridesConfigItem,
} from 'ajs/modules/waf';
import * as l10n from './waf-rule-group-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the WafRuleGroup message item modal.
 * @author alextsg
 */
@Component({
    selector: 'waf-rule-group-modal',
    templateUrl: './waf-rule-group-modal.component.html',
})
export class WafRuleGroupModalComponent implements OnInit {
    /**
     * WafRuleGroupConfigItem instance.
     */
    @Input()
    public editable: WafRuleGroupConfigItem | WafRuleGroupOverridesConfigItem;

    /**
     * String to be shown as the modal header.
     */
    @Input()
    public modalHeaderKey: string;

    /**
     * Emitted when the user wants to save the modal.
     */
    @Output()
    public onSubmit = new EventEmitter();

    /**
     * Emitted when the user wants to cancel editing the modal.
     */
    @Output()
    public onCancel = new EventEmitter();

    /**
     * Emitted after submitting the modal and to reposition the group.
     */
    @Output()
    public setGroupPosition = new EventEmitter();

    /**
     * AviDataGrid config for the group WafExcludeList.
     */
    public excludeListGridConfig: IAviDataGridConfig;

    /**
     * True if editing a group override instead of a WAF group. If editing an override, the name
     * field is readonly.
     */
    public isWafRuleGroupOverride = false;

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);

        this.excludeListGridConfig = {
            getRowId(index): number {
                return index;
            },
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleSubnet),
                    id: 'name',
                    transform(excludeListEntry: WafExcludeListEntryConfigItem) {
                        return excludeListEntry.subnet || '-';
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.columnTitlePath),
                    id: 'url',
                    transform(excludeListEntry: WafExcludeListEntryConfigItem) {
                        return excludeListEntry.path || '-';
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleMatchElement),
                    id: 'url',
                    transform(excludeListEntry: WafExcludeListEntryConfigItem) {
                        return excludeListEntry.matchElement || '-';
                    },
                },
            ],
            multipleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.actionTitleRemove),
                    onClick: (excludeListEntries: WafExcludeListEntryConfigItem[]) => {
                        excludeListEntries.forEach((entry: WafExcludeListEntryConfigItem) => {
                            this.removeExcludeListEntry(entry);
                        });
                    },
                },
            ],
            singleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.actionTitleEdit),
                    shape: 'pencil',
                    onClick: (excludeListEntry: WafExcludeListEntryConfigItem) => {
                        this.editExcludeListEntryMessageItem(excludeListEntry);
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.actionTitleRemove),
                    shape: 'trash',
                    onClick: (excludeListEntry: WafExcludeListEntryConfigItem) => {
                        this.removeExcludeListEntry(excludeListEntry);
                    },
                },
            ],
        };
    }

    /** @override */
    public ngOnInit(): void {
        this.isWafRuleGroupOverride = this.editable instanceof WafRuleGroupOverridesConfigItem;
    }

    /**
     * Handler for saving the modal.
     */
    public handleSubmit(): void {
        this.onSubmit.emit();
        this.setGroupPosition.emit();
    }

    /**
     * Handler to cancel editing the modal.
     */
    public handleCancel(): void {
        this.onCancel.emit();
    }

    /**
     * Returns the WafExcludeList repeatedMessageItem.
     */
    public get excludeList(): RepeatedMessageItem<WafExcludeListEntryConfigItem> {
        return this.editable.config.exclude_list;
    }

    /**
     * Called to add a WafExcludeListEntry item to the WafExcludeList.
     */
    public addExcludeListEntry(): void {
        this.editable.addExcludeListEntryMessageItem();
    }

    /**
     * Called to edit a WafExcludeListEntry item.
     */
    private editExcludeListEntryMessageItem(excludeListEntry: WafExcludeListEntryConfigItem): void {
        this.editable.editExcludeListEntryMessageItem(excludeListEntry);
    }

    /**
     * Called to remove a WafExcludeListEntry item.
     */
    private removeExcludeListEntry(excludeListEntry: WafExcludeListEntryConfigItem): void {
        this.editable.removeExcludeListEntry(excludeListEntry);
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './user-modal-tenant-settings.less';
import * as l10n from './user-modal-tenant-settings.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @constructor
 * @memberOf module:avi/accounts
 * @mixes module:avi/accounts.userModalTenantSettingsBindings
 * @see {@link module:avi/accounts.userModalTenantSettingsComponent}
 */
class UserModalTenantSettingsController {
    constructor(
        Auth,
        RoleCollection,
        TenantCollection,
        l10nService,
    ) {
        this.roleCollection = new RoleCollection({
            params: {
                tenant: Auth.getCurrentTenantRef().slug(),
            },
        });

        this.tenantCollection = new TenantCollection({
            isStatic: true,
            defaultDataSources: ['inventory'],
        });

        /**
         * True if the current user is admin.
         * Used to restrict tenant creation for admin user.
         * @type {boolean}
         */
        this.isAdminUser = Auth.getUsername() === 'admin';

        /**
         * List of individual Tenant Refs.
         * Passed down to `user-modal-default-tenant-settings` component.
         * @type {string[]}
         */
        this.individualTenants = [];

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onInit() {
        this.updateSelectedTenantsFilter_();
    }

    /**
     * Remove a row from access map and update tenantCollection.
     * @param {number} Index of the item to be deleted
     */
    removeTenantRoleMap(index) {
        this.user.removeTenantRoleMap(index);
        this.updateSelectedTenantsFilter_();
    }

    /**
     * Fires on tenant change.
     * @param {module:avi/accounts.CompressedTenantAccess} tenantAccess
     */
    onTenantChange(tenantAccess) {
        this.updateSelectedTenantsFilter_();
    }

    /**
     * Update tenantCollection to filter out already selected tenants.
     * @protected
     */
    updateSelectedTenantsFilter_() {
        const accessList = this.user.getIndividualTenantAccessList();

        const selectedTenants = accessList.reduce((tenants, access) => {
            const { tenant_ref: tenantRef = '' } = access;

            if (tenantRef) {
                tenants.push(tenantRef.slug());
            }

            return tenants;
        }, []);

        if (selectedTenants.length) {
            this.tenantCollection.setParams({
                'uuid.in': selectedTenants.join(),
                exclude: 'uuid.in',
            });
        } else {
            this.tenantCollection.setParams({
                'uuid.in': undefined,
                exclude: undefined,
            });
        }

        this.setIndividualTenantList_();
    }

    /**
     * Sets individualTenants list.
     * individualTenants is passed to defaultTenantSettings component.
     * @protected
     */
    setIndividualTenantList_() {
        const accessList = this.user.getIndividualTenantAccessList();

        this.individualTenants = _.chain(accessList)
            .pluck('tenant_ref')
            .compact()
            .value();
    }

    /** @override */
    $onDestroy() {
        [
            this.roleCollection,
            this.tenantCollection,
        ].forEach(collection => collection.destroy());
    }
}

UserModalTenantSettingsController.$inject = [
    'Auth',
    'RoleCollection',
    'TenantCollection',
    'l10nService',
];
/**
 * @name  userModalTenantSettingsComponent
 * @memberOf module:avi/accounts
 * @property {module:avi/accounts.userModalTenantSettingsBindings} bindings
 * @property {module:avi/accounts.UserModalTenantSettingsController} controller
 * @description
 *      Component for multiple tenant-role selection in
 *      Administration/Accounts/User/Create-Edit modal.
 * @author Aravindh Nagarajan
 */
angular.module('avi/accounts').component('userModalTenantSettings', {
    /**
     * @mixin userModalTenantSettingsBindings
     * @memberof module:avi/accounts
     * @property {module:avi/accounts.User} user
     */
    bindings: {
        user: '<',
    },
    controller: UserModalTenantSettingsController,
    templateUrl: 'src/components/modals/administration/accounts/' +
        'user/user-modal-tenant-settings/user-modal-tenant-settings.html',
});

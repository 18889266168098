/**
 * @module NetworkModule
 * @preferred
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    ConfiguredNetwork,
    ConfiguredNetworkCollection,
    networkListPageOptions,
    staticIpPoolConfigurationComponentOptions,
    SubnetListNetworkCollection,
} from '.';

const networkModule = angular.module('avi/network');

const factories = [{
    name: 'ConfiguredNetwork',
    factory: ConfiguredNetwork,
}, {
    name: 'ConfiguredNetworkCollection',
    factory: ConfiguredNetworkCollection,
}, {
    factory: SubnetListNetworkCollection,
    name: 'SubnetListNetworkCollection',
}];

const components = [{
    name: 'networkListPage',
    options: networkListPageOptions,
}, {
    name: 'staticIpPoolConfiguration',
    options: staticIpPoolConfigurationComponentOptions,
}];

factories.forEach(({ name, factory }) => {
    initAjsDependency(
        networkModule,
        'factory',
        name,
        factory,
    );
});

components.forEach(({ name, options }) => networkModule.component(name, options));

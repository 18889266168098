/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name  subtabNav
 * @param {Function} tabChange - Function to be called when a tab is selected.
 * @description
 *     Component displaying a list of tabs, clickable to change the active tab.
 * @example
 *     <subtab-nav>
 *         <subtab title="Tab 1">
 *             <div>Tab 1 content</div>
 *         </subtab>
 *         <subtab
 *             title="Tab 2"
 *             hide-tab="$ctrl.isHidden()">
 *             <div>Tab 2 content</div>
 *         </subtab>
 *     </subtab-nav>
 */
class SubtabNavController {
    constructor() {
        this.tabs = [];
        this.selectedTab = null;
    }

    /**
     * Selects a tab.
     * @param {SubtabTab} tab
     */
    selectTab(tab) {
        this.selectedTab = tab;
        this.tabChange({ tab });
    }

    /**
     * Adds a tab to this.tabs, used by the subtabTab child component $onInit.
     * @param {SubtabTab} tab
     */
    addTab(tab) {
        if (!this.selectedTab && !tab.hideTab) {
            this.selectTab(tab);
        }

        this.tabs.push(tab);
    }

    /**
     * Resets the selected tab since it might be hidden.
     * @param {SubtabTab} tab
     */
    resetSelectedTab(tab) {
        if (this.selectedTab === tab) {
            _.any(this.tabs, tab => {
                if (!tab.hideTab) {
                    this.selectTab(tab);

                    return true;
                }
            });
        }
    }
}

angular.module('aviApp').component('subtabNav', {
    transclude: true,
    bindings: {
        tabChange: '&',
    },
    controller: SubtabNavController,
    templateUrl: 'src/components/common/subtab/subtab-nav/subtab-nav.html',
});

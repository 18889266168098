const moduleName = 'ajs-legacy';
const componentName = 'avi-healthscore-popup';
const prefix = `${moduleName}.${componentName}`;

export const trendingHealthScoreLabel = `${prefix}.trendingHealthScoreLabel`;
export const performanceLabel = `${prefix}.performanceLabel`;
export const anomalyPenaltyLabel = `${prefix}.anomalyPenaltyLabel`;
export const resourcePenaltyLabel = `${prefix}.resourcePenaltyLabel`;
export const securityPenaltyLabel = `${prefix}.securityPenaltyLabel`;
export const healthScoreLabel = `${prefix}.healthScoreLabel`;
export const viewHealthBtnLabel = `${prefix}.viewHealthBtnLabel`;
export const resolvePlacementIssueBtnLabel = `${prefix}.resolvePlacementIssueBtnLabel`;
export const popupStateLabel = `${prefix}.popupStateLabel`;
export const popupReasonLabel = `${prefix}.popupReasonLabel`;

export const ENGLISH = {
    [trendingHealthScoreLabel]: 'Trending Health Score ({0})',
    [performanceLabel]: 'Performance',
    [anomalyPenaltyLabel]: 'Anomaly Penalty',
    [resourcePenaltyLabel]: 'Resource Penalty',
    [securityPenaltyLabel]: 'Security Penalty',
    [healthScoreLabel]: 'Health Score',
    [viewHealthBtnLabel]: 'View Health',
    [resolvePlacementIssueBtnLabel]: 'Resolve Placement Issue',
    [popupStateLabel]: '<strong>State: </strong>{0}',
    [popupReasonLabel]: '<strong>Reason: </strong>{0}',
};

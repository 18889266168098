/**
 * Module containing core services and components.
 * @preferred
 * @module CoreModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    AppStateHandler,
    appStateTree,
    Auth,
    AviAlertService,
    AviConfirmService,
    AviModalService,
    booleanLabelFilterFactory,
    DefaultValues,
    HorizonIframeService,
    HttpWrapper,
    InitialDataService,
    L10nKeysService,
    LicenseBasedFeaturesService,
    MyAccount,
    naturalSort,
    OpenstackKeystoneService,
    OPENSTACK_KEYSTONE_SERVICE_TOKEN,
    SchemaService,
    StateAccessMapService,
    StateManager,
    StringService,
    SystemInfoService,
    translateFilter,
    Upload,
    UserSessionEventListenersService,
    USER_SESSION_EVENT_LISTENERS_SERVICE_TOKEN,
} from '.';

import { initCoreModule } from './core.init';
import { aviAjsConfirmOptions, aviAlertOptions } from './components';
import { cdsAlertOnCloseChangeDirective } from './directives';

const coreModule = angular.module('avi/core');

const ajsDependencyFactories = [{
    factory: HttpWrapper,
    name: 'HttpWrapper',
}, {
    factory: Upload,
    name: 'Upload',
}];

const ajsDependencyServices = [{
    name: 'Auth',
    service: Auth,
}, {
    name: 'defaultValues',
    service: DefaultValues,
}, {
    name: 'initialDataService',
    service: InitialDataService,
}, {
    name: 'myAccount',
    service: MyAccount,
}, {
    name: 'aviAlertService',
    service: AviAlertService,
}, {
    name: 'stringService',
    service: StringService,
}, {
    name: 'horizonIframeService',
    service: HorizonIframeService,
}, {
    name: 'stateManagerService',
    service: StateManager,
}, {
    name: 'stateAccessMapService',
    service: StateAccessMapService,
}, {
    name: 'systemInfoService',
    service: SystemInfoService,
}];

const services = [{
    service: AviConfirmService,
    name: 'AviConfirmService',
}, {
    service: AviModalService,
    name: 'AviModal',
}, {
    service: SchemaService,
    name: 'schemaService',
}, {
    name: 'appStateHandler',
    service: AppStateHandler,
}, {
    service: L10nKeysService,
    name: 'l10nKeysService',
}, {
    service: LicenseBasedFeaturesService,
    name: 'licenseBasedFeaturesService',
}, {
    service: UserSessionEventListenersService,
    name: USER_SESSION_EVENT_LISTENERS_SERVICE_TOKEN,
}, {
    service: OpenstackKeystoneService,
    name: OPENSTACK_KEYSTONE_SERVICE_TOKEN,
}];

const constants = [{
    name: 'naturalSort',
    constant: naturalSort,
}, {
    name: 'appStateTree',
    constant: appStateTree,
}];

const directives = [
    {
        directiveFactory: cdsAlertOnCloseChangeDirective,
        name: 'cdsAlertOnCloseChange',
    },
];

const components = [
    {
        name: 'aviAjsConfirm',
        options: aviAjsConfirmOptions,
    }, {
        name: 'aviAlert',
        options: aviAlertOptions,
    },
];

const filters = [{
    name: 'booleanLabel',
    filter: booleanLabelFilterFactory,
}, {
    name: 'vtranslate',
    filter: translateFilter,
}];

ajsDependencyFactories.forEach(({ name, factory }) => {
    initAjsDependency(
        coreModule,
        'factory',
        name,
        factory,
    );
});

ajsDependencyServices.forEach(({
    name,
    service: Service,
}) => {
    initAjsDependency(
        coreModule,
        'service',
        name,
        Service,
    );
});

services.forEach(({
    name,
    service: Service,
}) => {
    coreModule.service(name, Service);
});

constants.forEach(({ name, constant }) => coreModule.constant(name, constant));

directives.forEach(({ name, directiveFactory }) => coreModule.directive(name, directiveFactory));

components.forEach(({ name, options }) => coreModule.component(name, options));

filters.forEach(({ name, filter }) => coreModule.filter(name, filter));

coreModule.run(initCoreModule);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc directive
 * @name logFilterNumber
 * @description
 *
 *     Numeric value with popover to pick the operator which on click turns into logs filter.
 */
angular.module('aviApp').directive('logFilterNumber', [
'$filter', '$compile', 'popoverFactory',
function($filter, $compile, PopoverFactory) {
    const logFilterNumberLink = function(scope, elem) {
        const { value } = scope;

        scope.valueIsMissing = function() {
            const { value } = scope;

            return value === '' || angular.isUndefined(value);
        };

        if (scope.valueIsMissing()) {
            scope.displayValue = 'N/A';
        } else if (scope.format === 'bytes') {
            scope.displayValue = Math.formatBytes(value);
        } else if (scope.format === 'duration') {
            scope.displayValue = $filter('ms2str')(value);
        } else {
            scope.displayValue = value;
        }

        const popover = new PopoverFactory({
            height: 170,
            width: 80,
            repositioning: true,
            removeAfterHide: true,
            carat: true,
            hide: {
                outClick: true,
                onEscape: true,
                onWinResize: true,
                parentMouseOut: true,
                innerClick: true,
            },
        });

        let popoverShowTimeout = null;

        const showPopover = function() {
            if (!popover.config.html) {
                popover.setTemplate(
                    $compile(
                        `<log-filter-number-popover
                            on-click="onClick(operator)"
                            value="displayValue"/>`,
                    )(scope),
                );

                //to render template so that it can return right sizing
                scope.$apply();
            }

            popover.show(elem);

            popoverShowTimeout = null;
        };

        const onMouseEnter = function() {
            if (!scope.valueIsMissing() && !popoverShowTimeout && !popover.isVisible()) {
                popoverShowTimeout = setTimeout(showPopover, 250);
            }
        };

        const onMouseLeave = function() {
            clearTimeout(popoverShowTimeout);
            popoverShowTimeout = null;
        };

        elem.on('mouseenter', onMouseEnter).on('mouseleave', onMouseLeave);

        scope.onClick = function(operator) {
            const filter = `${scope.key}${operator}${scope.value}`;

            scope.onUpdate({ str: filter });
        };

        scope.$on('$destroy', () => {
            popover.remove();
            clearTimeout(popoverShowTimeout);
        });
    };

    return {
        restrict: 'A',
        scope: {
            key: '@',
            value: '@',
            onUpdate: '&',
            format: '@',
        },
        link: logFilterNumberLink,
        template:
            `<span
                ng-if="::format !== 'duration'"
                ng-class="{'log-filter-number_value__missing' : valueIsMissing()}">
                {{::displayValue}}
            </span>
            <avi-value
                ng-if="::format === 'duration'"
                value="::value"
                type="duration">
            </avi-value>`,
    };
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'waf';
const componentName = 'waf-crs-rule';
const prefix = `${moduleName}.${componentName}`;

export const ruleWithCustomModeAndExceptionsConfiguredMessage = `${prefix}.ruleWithCustomModeAndExceptionsConfiguredMessage`;
export const ruleWithExceptionsConfiguredMessage = `${prefix}.ruleWithExceptionsConfiguredMessage`;
export const ruleWithCustomModeConfiguredMessage = `${prefix}.ruleWithCustomModeConfiguredMessage`;
export const emptyDataLabel = `${prefix}.emptyDataLabel`;
export const showRuleButtonLabel = `${prefix}.showRuleButtonLabel`;
export const ruleInputPlaceholder = `${prefix}.ruleInputPlaceholder`;
export const hideRuleButtonLabel = `${prefix}.hideRuleButtonLabel`;
export const enabledLabel = `${prefix}.enabledLabel`;
export const deactivatedLabel = `${prefix}.deactivatedLabel`;
export const statusLabel = `${prefix}.statusLabel`;
export const ruleModeLabel = `${prefix}.ruleModeLabel`;
export const ruleLabel = `${prefix}.ruleLabel`;
export const userOverridesHeader = `${prefix}.userOverridesHeader`;
export const originalHeader = `${prefix}.originalHeader`;
export const removeIconTitle = `${prefix}.removeIconTitle`;
export const hideOriginalButtonLabel = `${prefix}.hideOriginalButtonLabel`;
export const showOriginalButtonLabel = `${prefix}.showOriginalButtonLabel`;

export const ENGLISH = {
    [ruleWithCustomModeAndExceptionsConfiguredMessage]: 'This rule has a custom mode and exceptions configured.',
    [ruleWithExceptionsConfiguredMessage]: 'This rule has exceptions configured.',
    [ruleWithCustomModeConfiguredMessage]: 'This rule has a custom mode configured.',
    [emptyDataLabel]: 'N/A',
    [showRuleButtonLabel]: 'Show Rule',
    [ruleInputPlaceholder]: 'Enter Rule',
    [hideRuleButtonLabel]: 'Hide Rule',
    [enabledLabel]: 'Enabled',
    [deactivatedLabel]: 'Deactivated',
    [statusLabel]: 'Status',
    [ruleModeLabel]: 'Rule Mode',
    [ruleLabel]: 'Rule',
    [userOverridesHeader]: 'User Overrides',
    [originalHeader]: 'Original',
    [removeIconTitle]: 'Remove Override',
    [hideOriginalButtonLabel]: 'Hide Original',
    [showOriginalButtonLabel]: 'Show Original',
};

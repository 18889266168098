/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Directive,
    ElementRef,
    Injector,
    Input,
} from '@angular/core';

import { UpgradeComponent } from '@angular/upgrade/static';

/**
 * @desc Upgraded version of 'progress-bar' component, to make work in angular.
 *
 * @author Aravindh Nagarajan
 */
@Directive({
    selector: 'progress-bar',
})
export class ProgressBarDirective extends UpgradeComponent {
    @Input()
    public className?: string;

    @Input()
    public message?: string;

    @Input()
    public percentage: number;

    @Input()
    public title?: string;

    @Input()
    public transitionFinished?: boolean;

    @Input()
    public type?: string;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('progressBar', elementRef, injector);
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('aviApp').factory('IpAddrGroupCollection', [
'Collection', 'IpAddrGroup',
function(Collection, IpAddrGroup) {
    const IpAddrGroupCollection = class extends Collection {};

    angular.extend(IpAddrGroupCollection.prototype, {
        objectName_: 'ipaddrgroup',
        itemClass_: IpAddrGroup,
        windowElement_: 'prof-ipaddrgroup-create',
        sortBy_: 'name',
    });

    return IpAddrGroupCollection;
}]);

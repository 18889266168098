/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './clusterEdit.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @constructor
 * @memberOf module:avi/system
 * @mixes {module:avi/system.clusterEditComponentBindings}
 * @see {@link module:avi/system.clusterEditComponent}
 */
class ClusterEditController {
    constructor(
        regex,
        initialDataService,
        l10nService,
    ) {
        this.regex = regex;
        this.initialDataService_ = initialDataService;

        /** @type {boolean} */
        this.passwordRequired = false;

        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        if (this.initialDataService_.isAwsCloud) {
            this.passwordRequired = true;
        }
    }
}

ClusterEditController.$inject = [
    'Regex',
    'initialDataService',
    'l10nService',
];

/**
 * @ngdoc component
 * @name clusterEditComponent
 * @memberOf module:avi/system
 * @property {module:avi/system.ClusterEditController} controller
 * @property {module:avi/system.clusterEditComponentBindings} bindings
 * @desc Component for cluster-edit modal.
 * @author Alex Malitsky
 */
angular.module('avi/system').component('clusterEdit', {
    /**
     * @mixin clusterEditComponentBindings
     * @memberOf module:avi/system
     * @property {module:avi/system.Cluster} editable
     * @property {Function} closeModal - Closes cluster-edit modal.
     */
    bindings: {
        editable: '<',
        closeModal: '&',
    },
    controller: ClusterEditController,
    templateUrl: 'src/components/modals/administration/controller/cluster-edit.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    IP_TYPE_ANY,
    IP_TYPE_V4,
    IP_TYPE_V6,
} from 'ajs/js/constants/DNS.constants';

/**
 * @constructor
 * @memberOf module:avi/app
 * @desc DNS Lookup service to resolve domain names to ip(s).
 * @author Ram Pal
 */
class DNSLookupService {
    constructor($http, $q, $timeout, Regex, naturalSort) {
        this.$http_ = $http;
        this.$q_ = $q;
        this.$timeout_ = $timeout;
        this.regex_ = Regex;
        this.naturalSort_ = naturalSort;
        this._singleLookupCancel = $q.defer();

        /**
         * Timeout promise object.
         * @type {Object|null}
         * @protected
         */
        this.timer_ = null;

        /**
         * Promise object.
         * @type {Object|null}
         * @protected
         */
        this.promise_ = null;
    }

    /**
     * Makes a request to resolve a domain name. Used when concurrent lookups are not desired, so
     * the canceller promise is set here instead of externally.
     * @param {string} domain - Domain to look up
     * @return {Promise<string[]>}
     */
    singleLookup(domain) {
        // Cancel previous lookup
        this._singleLookupCancel.resolve();
        this._singleLookupCancel = this.$q_.defer();

        return this.lookup(domain, this._singleLookupCancel.promise);
    }

    /**
     * Simply makes request to the server to get ips
     * @param domain - VS data object
     * @param cancellerPromise - a promise that is going to be passed to http call
     *     to be able to cancel it
     * @param {string=} cloudId - Optional cloud_uuid
     * @param {string=} ipType - Flag to get ipv4s, ipv6s or both values
     * @param {boolean?} poolResolution - True if lookup is happening for pool object
     * @return {Promise<string[]>}
     */
    lookup(
        domain,
        cancellerPromise,
        cloudId,
        ipType = IP_TYPE_V4,
        poolResolution,
    ) {
        let promise;

        if (typeof domain !== 'string' || domain === '') {
            promise = [];
        } else if (this.checkIP(domain) || this.checkIPWithPort(domain)) {
            promise = [domain];
        } else if (domain && domain.split(',').length && this.checkMultipleDomains(domain)) {
            promise = [domain];
        } else {
            const config = {
                timeout: cancellerPromise,
                params: {
                    cloud_uuid: cloudId,
                },
            };

            // Webapp needs to know when the resolution is happening for pool objects
            if (poolResolution) {
                config.params.pool = true;
            }

            promise = this.$http_.get(`/api/dnslookup/${domain}`, config)
                .then(rsp => {
                    const sortedIpList = [];

                    if (ipType === IP_TYPE_V4 || ipType === IP_TYPE_ANY) {
                        const { ips } = rsp.data;

                        if (ips) {
                            sortedIpList.push(...this.sortIps_(ips));
                        }
                    }

                    if (ipType === IP_TYPE_V6 || ipType === IP_TYPE_ANY) {
                        const { ip6s } = rsp.data;

                        if (ip6s) {
                            sortedIpList.push(...this.sortIps_(ip6s));
                        }
                    }

                    return sortedIpList;
                });
        }

        return this.$q_.when(promise);
    }

    /**
     * Checks to see if string is valid IP address or IP range.
     * @param  {string} domain - IP address or IP address range.
     * @return {boolean} True if string contains valid IP addresses. False otherwise.
     */
    checkIP(domain) {
        return (new RegExp(this.regex_.listOfIpsRanges)).test(domain);
    }

    /**
     * Checks for valid <IP address>:<port> formatted string.
     * @param  {string} domain - Expected to be in <IP>:<port> format.
     * @return {boolean} Returns true if string is valid. False if otherwise.
     */
    checkIPWithPort(domain) {
        const parts = domain && domain.replace(/\s/g, '').split(':');

        if (_.isUndefined(parts) || parts.length !== 2) {
            return false;
        }

        return (new RegExp(this.regex_.ip))
            .test(parts[0]) && (new RegExp(this.regex_.port)).test(parts[1]);
    }

    /**
     * Returns true if entire list of domains matches either IP address, range,
     * or <ip>:<port> format. Returns false if a single IP string is invalid.
     * @param  {string} domains - String of comma-separated IP addresses or ranges.
     * @return {boolean}
     */
    checkMultipleDomains(domains) {
        const addresses = domains.replace(/\s/g, '').split(',');
        const self = this;

        return !_.some(addresses, function(address) {
            let valid = false;

            if (self.checkIPWithPort(address) || self.checkIP(address)) {
                valid = true;
            }

            return !valid;
        });
    }

    /**
     * Debouncing lookup call
     * @param {string} domain - Domain or ip string, in case of domain it's
     *     gonna make a resolution call
     * @param {string=} cloudId - current Cloud's uuid.
     * @param {boolean?} poolResolution - True if lookup is happening for pool object
     * @return {object} - see return statement for details
     */
    lookupDelayed(domain, cloudId, poolResolution) {
        const cancellerDeferred = this.$q_.defer();
        const self = this;
        const deferred = this.$q_.defer();

        this.$timeout_.cancel(this.timer_);

        if (this.promise_) {
            this.promise_.reject();
        }

        this.timer_ = this.$timeout_(function() {
            self.lookup(domain, cancellerDeferred.promise, cloudId, undefined, poolResolution)
                .then(function(ips) {
                    deferred.resolve(ips);
                });
        }, 1000);
        this.promise_ = deferred;

        return {
            // Just the promise to be able to catch response
            promise: deferred.promise,
            // Cancel method in case response not neded anymore
            // cancelling timeout and http call
            cancel(reason) {
                cancellerDeferred.resolve(reason);
                self.$timeout_.cancel(this.timer_);
            },
        };
    }

    /**
     * Function to sort list using natural sort.
     * @param {Object[]} ipList - list of ip's.
     * @return {Object[]}
     * @protected
     */
    sortIps_(ipList) {
        return ipList.sort((ipaddressA, ipaddressB) => this.naturalSort_(ipaddressA, ipaddressB));
    }
}

DNSLookupService.$inject = [
    '$http',
    '$q',
    '$timeout',
    'Regex',
    'naturalSort',
];

angular.module('avi/app').service('DNS', DNSLookupService);

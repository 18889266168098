/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'shared';
const componentName = 'rbac-label-grid';
const prefix = `${moduleName}.${componentName}`;

export const header = `${prefix}.header`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const keyFieldPlaceholder = `${prefix}.keyFieldPlaceholder`;
export const valuesFieldPlaceholder = `${prefix}.valuesFieldPlaceholder`;
export const keyDuplicationErrorMsg = `${prefix}.keyDuplicationErrorMsg`;
export const keyColumnLabel = `${prefix}.keyColumnLabel`;
export const valuesColumnLabel = `${prefix}.valuesColumnLabel`;
export const removeButtonLabel = `${prefix}.removeBtnLabel`;

export const ENGLISH = {
    [header]: 'RBAC Key & Value',
    [addButtonLabel]: 'Add',
    [keyFieldPlaceholder]: 'Enter Key',
    [valuesFieldPlaceholder]: 'Enter Values',
    [keyDuplicationErrorMsg]: 'System does not support duplicate Keys. Please update or delete.',
    [keyColumnLabel]: 'Key',
    [valuesColumnLabel]: 'Value(s)',
    [removeButtonLabel]: 'Remove',
};

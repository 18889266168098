/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc controller
 * @name ServiceEngineController
 */
angular.module('aviApp').controller('ServiceEngineController', [
'$scope', 'resolveSE', 'myAccount', 'resolveCloud', 'Auth',
function($scope, se, myAccount, cloudPromise, Auth) {
    $scope.myAccount = myAccount;

    cloudPromise.then(cloud => {
        if (!$scope.$$destroyed) {
            $scope.cloud = cloud;
        } else {
            cloud.destroy();
        }
    });

    $scope.se = se;
    $scope.Auth = Auth;

    /** @deprecated */
    $scope.ServiceEngine = se;

    $scope.$on('$destroy', () => {
        se.destroy();

        const { cloud } = $scope;

        if (cloud) {
            cloud.destroy();
        }
    });
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './bgp-peering-list.less';
import * as l10n from './bgp-peering-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name bgpPeeringList
 * @description Component for BGP Peering List page.
 */
class BgpPeeringListController {
    constructor(bgpPeeringService, AviModal, infraCloudState, Auth, l10nService) {
        this._bgpPeeringService = bgpPeeringService;
        this._AviModal = AviModal;
        this._infraCloudState = infraCloudState;
        this.Auth = Auth;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        this.bgpPeeringForVrfMessageList =
            l10nService.getSplitedMessage(l10nKeys.bgpPeeringForVrfMessage);
    }

    $onInit() {
        this.vrfContextList = [];
        this.vrfContextNames = ['global'];

        this.handleVrfCollectionChange();

        this._bgpPeeringService.emitter.onAny(() => {
            this.vrfContextList = this._bgpPeeringService.getVrfContextList();
        });

        this._bgpPeeringService.emitter.on('saved', () => {
            this._bgpPeeringService.load(this.vrfContextNames);
        });

        this.handleVrfCollectionChange = this.handleVrfCollectionChange.bind(this);
        this._infraCloudState.on('vrfContextCollectionChange', this.handleVrfCollectionChange);
    }

    /**
     * Checks if BGP can be modified using specified tenant.
     * @param {string} tenantRef
     * @return {boolean}
     */
    isInTenant(tenantRef) {
        if (angular.isString(tenantRef)) {
            return tenantRef.name() === this.Auth.getTenantName();
        }
    }

    /**
     * Loads the VRF Context list based on the VRF Context names.
     */
    loadBgpPeeringService() {
        if (this.vrfContextCollection.hasCustomVRFContext()) {
            this.vrfContextNames = this.vrfContextCollection.items
                .reduce((acc, vrf) => {
                    const name = vrf.getName();

                    return name !== 'management' ? acc.concat(name) : acc;
                }, []);
        } else {
            this.vrfContextNames = ['global'];
        }

        this._bgpPeeringService.load(this.vrfContextNames);
    }

    /**
     * Handler for 'vrfContextCollectionChange' event. Reloads the VRF Context lists.
     */
    handleVrfCollectionChange() {
        const cloudRef = this._infraCloudState.getCloudRef();
        const slug = cloudRef && cloudRef.slug();

        this._bgpPeeringService.setCloudId(slug);
        this.vrfContextCollection = this._infraCloudState.getVrfContextCollection();
        this.loadVRFCollection_();
    }

    /**
     * Opens the modal to edit the BGP Peer.
     * @param {string} vrfName - VRF name.
     */
    editBgpProfile(vrfName) {
        const { _AviModal: AviModal, _infraCloudState: infraCloudState } = this;

        AviModal.open('infra-bgp-peer-create', {
            vrfName,
            Cloud: infraCloudState.getCloud(),
        });
    }

    /**
     * Since collection is carried over all tabs of Routing section we want to make sure it
     * loads what we need it to load for the component. Also kicks off page rendering.
     * @protected
     */
    loadVRFCollection_() {
        this.vrfContextCollection.updateViewportSize(200, true, true);
        this.vrfContextCollection.load()
            .then(() => this.loadBgpPeeringService());
    }

    $onDestroy() {
        const {
            _infraCloudState: infraCloudState,
        } = this;

        this._bgpPeeringService.emitter.removeAllListeners();

        infraCloudState.unbind(
            'vrfContextCollectionChange',
            this.handleVrfCollectionChange,
        );
    }
}

BgpPeeringListController.$inject = [
    'bgpPeeringService',
    'AviModal',
    'infraCloudState',
    'Auth',
    'l10nService',
];

angular.module('aviApp').component('bgpPeeringList', {
    controller: BgpPeeringListController,
    templateUrl: 'src/components/pages/infrastructure/bgp-peering-list/' +
        'bgp-peering-list.html',
});

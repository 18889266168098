/** @WafModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    any,
    findIndex,
    identity,
    isEqual,
    isUndefined,
    pick,
} from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import { WafExcludeListEntryModalComponent } from 'ng/modules/waf';
import { withFullModalMixin } from 'ajs/utils/mixins';
import {
    IMessageItemData,
    IpAddrPrefixConfigItem,
    MessageItem,
} from 'ajs/modules/data-model';
import { IWafExcludeListEntry } from 'generated-types';
import { WafExclusionTypeConfigItem } from './waf-exclusion-type.config-item.factory';
import * as l10n from '../waf.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TWafExcludeListEntryConfigPartial = Omit<
IWafExcludeListEntry,
'uri_match_criteria' | 'match_element_criteria'
>;

interface IWafExcludeListEntryConfig extends TWafExcludeListEntryConfigPartial {
    // TODO: Remove in 21.1.1 with MessageItem generic.
    uri_match_criteria?: WafExclusionTypeConfigItem;
    match_element_criteria?: WafExclusionTypeConfigItem;
    client_subnet?: IpAddrPrefixConfigItem;
}

interface IIWafExcludeListEntryData extends IMessageItemData {
    config: IWafExcludeListEntryConfig;
}

export const WAF_EXCLUDE_LIST_ENTRY_CONFIG_ITEM_TOKEN = 'WafExcludeListEntryConfigItem';

export class WafExcludeListEntryConfigItem extends withFullModalMixin(MessageItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    public data: IIWafExcludeListEntryData;

    private l10nService: L10nService;

    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'WafExcludeListEntry',
            windowElement: WafExcludeListEntryModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Checks to see if an exception alredy exists within a list of exceptions. Returns an
     * index >= 0 if exists, otherwise returns -1.
     * @param exception - contains client_subnet, uri_path, and match_element properties.
     * @param exceptions - Either a single exception or a list of
     *     exceptions.
     */
    public static hasMatchingException(
        exception: IWafExcludeListEntryConfig,
        exceptions: WafExcludeListEntryConfigItem | WafExcludeListEntryConfigItem[] |
        IWafExcludeListEntryConfig | IWafExcludeListEntryConfig[] = [],
    ): number {
        const exceptionsList = Array.isArray(exceptions) ? exceptions : [exceptions];
        const strippedException = pick(exception, identity);

        return findIndex(exceptionsList, configuredException => {
            const strippedConfiguredException = configuredException instanceof MessageItem ?
                configuredException.getDataToSave() :
                pick(configuredException, identity);

            return isEqual(strippedConfiguredException, strippedException);
        });
    }

    /** @override */
    public canFlatten(): boolean {
        return this.isValid();
    }

    /**
     * Returns the client_subnet IpAddrPrefixConfigItem.
     */
    public get clientSubnet(): IpAddrPrefixConfigItem {
        return this.config.client_subnet;
    }

    /**
     * Returns the uri_match_criteria WafExclusionTypeConfigItem.
     */
    public get pathMatchCriteria(): WafExclusionTypeConfigItem {
        return this.config.uri_match_criteria;
    }

    /**
     * Returns the match_element_criteria WafExclusionTypeConfigItem.
     */
    public get matchElementCriteria(): WafExclusionTypeConfigItem {
        return this.config.match_element_criteria;
    }

    /**
     * Returns the case sensitive setting on the path match.
     */
    public get pathCaseSensitive(): boolean {
        return this.pathMatchCriteria.isCaseSensitive;
    }

    /**
     * Returns the regex match setting on the path match.
     */
    public get pathRegexMatch(): boolean {
        return this.pathMatchCriteria.isRegexMatch;
    }

    /**
     * Returns the case sensitive setting on the match element.
     */
    public get matchElementCaseSensitive(): boolean {
        return this.matchElementCriteria.isCaseSensitive;
    }

    /**
     * Returns the regex match setting on the match element.
     */
    public get matchElementRegexMatch(): boolean {
        return this.matchElementCriteria.isRegexMatch;
    }

    /**
     * Sets the case sensitive setting on the path match.
     */
    public setPathMatchCaseSensitive(sensitive = false): void {
        this.pathMatchCriteria.setCaseSensitive(sensitive);
    }

    /**
     * Sets the regex match setting on the path match.
     */
    public setPathMatchRegexMatch(regexMatch = false): void {
        this.pathMatchCriteria.setRegexMatch(regexMatch);
    }

    /**
     * Sets the case sensitive setting on the match element.
     */
    public setMatchElementCaseSensitive(sensitive = false): void {
        this.matchElementCriteria.setCaseSensitive(sensitive);
    }

    /**
     * Sets the regex match setting on the match element.
     */
    public setMatchElementRegexMatch(regexMatch = false): void {
        this.matchElementCriteria.setRegexMatch(regexMatch);
    }

    /**
     * Returns a string representation of the subnet.
     */
    public get subnet(): string | undefined {
        return this.config.client_subnet?.subnet;
    }

    /**
     * Returns a string representation of the path.
     */
    public get path(): string | undefined {
        return this.config.uri_path;
    }

    /**
     * Returns a string representation of the match element.
     * @return {string}
     */
    public get matchElement(): string | undefined {
        return this.config.match_element;
    }

    /**
     * Returns true if the exclude list entry has any field populated.
     */
    public isValid(): boolean {
        const { client_subnet: clientSubnet } = this.config;
        const fields = [
            'uri_path',
            'match_element',
        ];

        return any(fields, field => !isUndefined(this.config[field])) ||
            clientSubnet && clientSubnet.isValid();
    }

    /**
     * Returns true if an exception matches the configured exception.
     * @param {Object} exception - Exception containing subnet, path, and match element.
     * @return {boolean}
     */
    public hasMatchingException(exception: IWafExcludeListEntryConfig): boolean {
        const { config } = this;

        return WafExcludeListEntryConfigItem.hasMatchingException(exception, config) > -1;
    }

    /**
     * Returns the uri_match_criteria configItem config object.
     */
    public getUriMatchCriteriaConfig(): Record<string, any> {
        return this.config.uri_match_criteria && this.config.uri_match_criteria.config;
    }

    /**
     * Returns the match_element_criteria configItem config object.
     */
    public getMatchElementCriteriaConfig(): Record<string, any> {
        return this.config.match_element_criteria && this.config.match_element_criteria.config;
    }

    /**
     * Returns a string representation of the configured subnet.
     */
    public getSubnet(): string {
        return this.config.client_subnet.subnet;
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.wafExcludeListEntryBreadcrumbTitle);
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'client_subnet',
            'uri_match_criteria',
            'match_element_criteria',
        ];
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../../less/components/range-slider.less';

const doc = document;
const html = doc.documentElement;
const win = window;

angular.module('aviApp').directive('rangeSlider', function() {
    return {
        scope: {
            min: '=',
            max: '=',
            limitMin: '=',
            limitMax: '=',
            value: '=',
            ngChange: '&',
        },
        restrict: 'A',
        compile,
        templateUrl: 'src/views/components/range-slider.html',
    };
});

function compile(element, attrs) {
    element.addClass('range-slider');
    element.attr({
        tabIndex: 0,
        role: 'slider',
    });

    return postLink;
}

function postLink(scope, element, attrs, ngModel) {
    const el = element[0];
    const thumb = angular.element(el.querySelector('.range-slider-thumb'));
    const thumbEl = thumb[0];
    const track = angular.element(el.querySelector('.range-slider-track'));
    const activeTrack = angular.element(el.querySelector('.range-slider-track-fill'));

    let mouseX = 0;
    let startX = 0;
    let rect = {};

    win.addEventListener('resize', onWindowResize);

    track.on('click', trackClick);

    function measure() {
        rect = track[0].getBoundingClientRect();
    }

    setTimeout(measure, 0);

    function trackClick(e) {
        let px = e.pageX - rect.left;

        px = Math.max(0, Math.min(rect.width, px));
        setValueFromPercent(px / rect.width);
    }

    function onWindowResize() {
        measure();
    }

    const unWatchValue = scope.$watchGroup(['value', 'max'], function(newValue, oldValue) {
        for (let i = 0; i < newValue.length; i++) {
            if (newValue[i] !== oldValue[i]) {
                redraw();
                break;
            }
        }
    });

    function getPercent() {
        return scope.value / (scope.max - scope.min);
    }

    /**
     * Wrapper for Math.min.
     * Returns NaN if any parameter isn’t a number and can’t be converted into one.
     * @return {number|NaN}
     */
    scope.mathMin = function(...args) {
        return Math.min(...args);
    };

    function redraw() {
        const val = getPercent();

        activeTrack.css({
            right: `${100 - val * 100}%`,
        });
        thumb.css({
            left: `${val * 100}%`,
        });
    }

    redraw();

    function removeListeners() {
        html.removeEventListener('mouseup', removeListeners);
        html.removeEventListener('mousemove', docMouseMove);
        win.removeEventListener('blur', removeListeners);
    }

    function setValueFromPercent(p) {
        const lowerLimit = scope.limitMin;
        const upperLimit = scope.limitMax;
        const { max, min } = scope;

        if (!angular.isUndefined(lowerLimit)) {
            const ll = lowerLimit / (max - min);

            if (p < ll) {
                p = ll;
            }
        }

        if (!angular.isUndefined(upperLimit)) {
            const ul = upperLimit / (max - min);

            if (p > ul) {
                p = ul;
            }
        }

        scope.$apply(function() {
            scope.value = scope.min + p * (scope.max - scope.min);
        });
    }

    function docMouseMove(e) {
        e.stopPropagation();

        const px = e.pageX;
        const dx = startX + px - mouseX - rect.left;
        const val = Math.max(0, Math.min(dx, rect.width));
        const p = val / rect.width;

        setValueFromPercent(p);
    }

    function mouseDown(e) {
        e.stopPropagation();
        e.preventDefault();
        mouseX = e.pageX;
        startX = thumb.offset().left;
        html.addEventListener('mouseup', removeListeners);
        html.addEventListener('mousemove', docMouseMove);
        win.addEventListener('blur', removeListeners);
    }

    thumbEl.addEventListener('mousedown', mouseDown, true);

    scope.$on('$destroy', function() {
        thumbEl.removeEventListener('mousedown', mouseDown, true);
        win.removeEventListener('resize', onWindowResize);
        track.off('click', trackClick);
        removeListeners();
        unWatchValue();
    });
}

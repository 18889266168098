/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './nat-policy-list.component.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Module for NAT Policy related components and services.
 * @module avi/policies/nat
 */

const componentName = 'nat-policy-list';

/**
 * @constructor
 * @memberOf module:avi/policies/nat
 * @see {@link module:avi/policies/nat.natPolicyListComponent}
 */
class NatPolicyListController {
    constructor(
        $element,
        NatPolicyCollection,
        CRUDGridConfig,
        l10nService,
    ) {
        this.$element_ = $element;
        this.NatPolicyCollection_ = NatPolicyCollection;
        this.CRUDGridConfig_ = CRUDGridConfig;

        /**
        * Get keys from source bundles for template usage.
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
        this.l10nService_ = l10nService;
    }

    /** @override */
    $onInit() {
        this.$element_.addClass(componentName);

        const { l10nService_: l10nService } = this;

        const gridConfigOptions = {
            collection: new this.NatPolicyCollection_(),
            props: {
                l10nKeys,
            },
            fields: [{
                name: 'name',
                title: l10nService.getMessage(l10nKeys.columnTitleName),
                template: '{{ row.getName() }}',
                sortBy: 'name',
            },
            {
                name: 'rules',
                title: l10nService.getMessage(l10nKeys.columnTitleRulesCount),
                template: '{{ row.rulesCount }}',
            }],
            expandedContainerTemplate: require('./nat-policy-list-expander.partial.html'),
            permission: 'PERMISSION_NATPOLICY',
        };

        const { objectName } = gridConfigOptions.collection;

        gridConfigOptions.id = `${objectName}-list-page`;

        this.gridConfig = new this.CRUDGridConfig_(gridConfigOptions);
    }

    /** @override */
    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

NatPolicyListController.$inject = [
    '$element',
    'NatPolicyCollection',
    'CRUDGridConfig',
    'l10nService',
];

/**
 * @name natPolicyListComponent
 * @memberOf module:avi/policies/nat
 * @desc NAT Policy list page component
 * @author Aravindh Nagarajan
 */
angular.module('avi/policies/nat').component('natPolicyList', {
    controller: NatPolicyListController,
    template:
        `<div class="templatesList">
            <collection-grid config="$ctrl.gridConfig"></collection-grid>
        </div>`,
});

/**
 * @module DataModelModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { map, size } from 'underscore';
import { ListCollDataSource } from './list-coll-data-source.factory';

/**
 * @desc
 *
 *     There are three main differences between InventoryCollDataSource and ListCollDataSource
 *     which it extends.
 *
 *     First thing to notice is an extra property `fields_` of ListDataTransportRequestParams.
 *     This one will be finally transformed into query URL part as 'include=field1,field2'.
 *
 *     InventoryCollDataSource uses step and limit values as request parameters meaning that
 *     it has to listen to {@link Timeframe} change events and update them accordingly.
 *
 *     InventoryCollDataSource can work as a main source for `config` data and be used to
 *     generate an ordered list of Items (exactly as ListCollDataSource) or it can provide
 *     additional data such as runtime, alert or health_score and fulfill existent Items
 *     with it.
 *
 * @author Alex Malitsky, Ashish Verma, Ram Pal
 */
export class InventoryCollDataSource extends ListCollDataSource {
    public dataFields_: Record<string, string>;
    public params_: any;

    constructor(oArgs: any) {
        super(oArgs);
        this.setStepAndLimitParams();
    }

    /** @override */
    public getRequestParams(): any {
        const params = super.getRequestParams_(this.params_);

        // eslint-disable-next-line no-underscore-dangle
        params.fields_ = map(this.fields_, (field: any) => {
            return field.id in this.dataFields_ && this.dataFields_[field.id] || field.id;
        });

        return params;
    }

    /**
     * Sets step and limit DS parameters based on currently selected Timeframe.
     */
    protected setStepAndLimitParams(): void {
        const timeFrame = this.getAjsDependency_('Timeframe');

        const tf = timeFrame.selected();

        this.params_.step = tf.step;
        this.params_.limit = tf.limit;
    }

    /**
     * Event handler for {@link Timeframe} change event. Calls setSetAndLimitParams_ and load if
     * we have some active fields different from `config` (which doesn't depend on step&limit
     * values).
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    protected onTimeframeChange_(): void {
        this.setStepAndLimitParams();

        super.onTimeframeChange_();

        if (size(this.fields_) > 1 || !('config' in this.fields_)) {
            this.load();
        }
    }
}

const dataFields = {
    health: 'health_score',
};

Object.assign(InventoryCollDataSource.prototype, {
    defaultFields_: [],
    dataFields_: dataFields,
});

InventoryCollDataSource.ajsDependencies = [
    'Timeframe',
];

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc directive
 * @restrict E
 * @name cardList
 * @param {boolean} e2eSeriesAvailable - Render Not Available for end_to_end metric
 *      if value is false.
 * @description
 *
 *     Iterates through all cards in config, creating appropriate sparkline cards.
 *
 */
angular.module('aviApp').directive('cardList', [
'myAccount', function(myAccount) {
    const cardListLink = scope => {
        scope.myAccount = myAccount;
        scope.ui = {};

        scope.filterBySeries = ({ id }) => {
            const { seriesList } = scope;

            return !seriesList || _.contains(seriesList, id);
        };
    };

    return {
        scope: {
            item: '<',
            config: '<',
            header: '@',
            seriesList: '<',
            title: '@',
            e2eSeriesAvailable: '<?',
        },
        restrict: 'E',
        templateUrl: 'src/views/components/card-list.html',
        link: cardListLink,
    };
}]);

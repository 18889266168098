/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function sslProfileCollectionFactory(Collection, SSLProfile) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Collection
     * @author Ram Pal
     * @desc Collection of {@link module:avi/app.SSLProfile SSLProfiles}.
     */
    class SSLProfileCollection extends Collection {}

    const serverDefaultsOverride = {
        tags: [],
    };

    Object.assign(SSLProfileCollection.prototype, {
        objectName_: 'sslprofile',
        itemClass_: SSLProfile,
        windowElement_: 'ssl-tls-profile-modal',
        serverDefaultsOverride_: serverDefaultsOverride,
    });

    return SSLProfileCollection;
}

sslProfileCollectionFactory.$inject = [
    'Collection',
    'SSLProfile',
];

angular.module('avi/app')
    .factory('SSLProfileCollection', sslProfileCollectionFactory);

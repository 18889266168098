/**
 * @module VsModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { IVip } from 'generated-types';
import { OperStateToTypeMap } from '../vs.constants';
import { IExtendedVipRuntimeDetail } from '../vs.types';
import { InventoryDataTransformer } from '../../data-model';

/**
 * @desc
 *
 *   DataTransformer for the VsVipCollection. Currently runtime object contains vip_id and
 *   other runtime properties such as oper_status etc. Here we populate runtime object with
 *   ip_address from vip object for the matching oper_statuses.
 *
 * @author Ram Pal
 */
export class VsVipInventoryDataTransformer extends InventoryDataTransformer {
    public responseListOffsetControl_: (resp: any, request: any) => any;

    /** @override */
    public processResponse(resp: any, request: any): any {
        resp = this.responseListOffsetControl_(resp, request);

        const { data: { results } } = resp;

        if (Array.isArray(results)) {
            results.forEach((vsVip: Record<string, any>) => {
                const { config: { vip: vips }, runtime: vipsRuntime } = vsVip;

                const ipAddressByIdMap = new Map();

                vips.forEach(({
                    vip_id: vipId,
                    ip_address: ipAddress,
                }: IVip) => { ipAddressByIdMap.set(vipId, ipAddress); });

                vipsRuntime.forEach((vipRuntimeData: IExtendedVipRuntimeDetail) => {
                    const { vip_id: vipId, oper_status: { state } } = vipRuntimeData;

                    vipRuntimeData.ip_address = ipAddressByIdMap.get(vipId);
                    vipRuntimeData.operStateType = OperStateToTypeMap.get(state);
                });
            });
        }

        return resp;
    }
}

const moduleName = 'ajs-legacy';
const componentName = 'data-script-set-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleEventTypes = `${prefix}.columnTitleEventTypes`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleEventTypes]: 'Event Types',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function autoScalePolicyItemFactory(Item) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Item
     * @author Ram Pal
     * @desc AutoScalePolicy item
     */
    class AutoScalePolicyItem extends Item {
        constructor(oArgs) {
            super(oArgs);
            this.objectName = 'serverautoscalepolicy';
            this.windowElement = 'app-pool-autoscale-policy';
        }

        /**
        * @override
        */
        dataToSave() {
            const config = angular.copy(this.data.config);

            if (!config.intelligent_autoscale) {
                config.intelligent_scaleout_margin = undefined;
                config.intelligent_scalein_margin = undefined;
            }

            return config;
        }
    }

    return AutoScalePolicyItem;
}

autoScalePolicyItemFactory.$inject = [
    'Item',
];

angular.module('avi/app')
    .factory('AutoScalePolicyItem', autoScalePolicyItemFactory);

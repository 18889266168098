/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'network-profile-modal-controller';
const prefix = `${moduleName}.${componentName}`;

export const editTcpUdpProfileHeader = `${prefix}.editTcpUdpProfileHeader`;
export const newTcpUdpProfileHeader = `${prefix}.newTcpUdpProfileHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const tcpProxySettingsHeader = `${prefix}.tcpProxySettingsHeader`;
export const autoLearnInputLabel = `${prefix}.autoLearnInputLabel`;
export const customInputLabel = `${prefix}.customInputLabel`;
export const timeoutHeader = `${prefix}.timeoutHeader`;
export const idleConnectionInputLabel = `${prefix}.idleConnectionInputLabel`;
export const keepAliveRadioOption = `${prefix}.keepAliveRadioOption`;
export const ageOutIdleConnectonsRadioOption = `${prefix}.ageOutIdleConnectonsRadioOption`;
export const idleDurationInputLabel = `${prefix}.idleDurationInputLabel`;
export const ignoreTimeWaitInputLabel = `${prefix}.ignoreTimeWaitInputLabel`;
export const ignoreTimeWaitInputPlaceholder = `${prefix}.ignoreTimeWaitInputPlaceholder`;
export const retransmissionBehaviorHeader = `${prefix}.retransmissionBehaviorHeader`;
export const maxRetransmissionInputLabel = `${prefix}.maxRetransmissionInputLabel`;
export const maxCountInputPlaceholder = `${prefix}.maxCountInputPlaceholder`;
export const maxSynRetransmissionInputLabel = `${prefix}.maxSynRetransmissionInputLabel`;
export const bufferManagementHeader = `${prefix}.bufferManagementHeader`;
export const receiveWindowInputLabel = `${prefix}.receiveWindowInputLabel`;
export const maxSegmentSizeHeader = `${prefix}.maxSegmentSizeHeader`;
export const useInterfaceMtuInputLabel = `${prefix}.useInterfaceMtuInputLabel`;
export const useInterfaceMtuInputPlaceholder = `${prefix}.useInterfaceMtuInputPlaceholder`;
export const qosTrafficEngineeringHeader = `${prefix}.qosTrafficEngineeringHeader`;
export const passThroughInputLabel = `${prefix}.passThroughInputLabel`;
export const naglesAlgorithmInputLabel = `${prefix}.naglesAlgorithmInputLabel`;
export const tcpFastPathSettingsHeader = `${prefix}.tcpFastPathSettingsHeader`;
export const enableSynProtectionInputLabel = `${prefix}.enableSynProtectionInputLabel`;
export const sessionIdleTimeoutInputLabel = `${prefix}.sessionIdleTimeoutInputLabel`;
export const secInnerText = `${prefix}.secInnerText`;
export const udpFastPathSettingsHeader = `${prefix}.udpFastPathSettingsHeader`;
export const natClientIpAddressInputLabel = `${prefix}.natClientIpAddressInputLabel`;
export const perPacketLoadbalancingInputLabel = `${prefix}.perPacketLoadbalancingInputLabel`;
export const udpProxySettingsHeader = `${prefix}.udpProxySettingsHeader`;
export const secondsInnerText = `${prefix}.secondsInnerText`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;
export const rbacHeader = `${prefix}.rbacHeader`;

export const ENGLISH = {
    [editTcpUdpProfileHeader]: 'Edit TCP/UDP Profile: {0}',
    [newTcpUdpProfileHeader]: 'New TCP/UDP Profile: {0}',
    [nameInputLabel]: 'Name',
    [descriptionInputLabel]: 'Description',
    [typeInputLabel]: 'Type',
    [tcpProxySettingsHeader]: 'TCP Proxy Settings',
    [autoLearnInputLabel]: 'Auto Learn',
    [customInputLabel]: 'Custom',
    [timeoutHeader]: 'Timeout',
    [idleConnectionInputLabel]: 'Idle Connections',
    [keepAliveRadioOption]: 'TCP keepalive',
    [ageOutIdleConnectonsRadioOption]: 'Age Out Idle Connections',
    [idleDurationInputLabel]: 'Idle Duration',
    [ignoreTimeWaitInputLabel]: 'Ignore Time Wait',
    [ignoreTimeWaitInputPlaceholder]: 'Duration',
    [retransmissionBehaviorHeader]: 'Retransmission Behavior',
    [maxRetransmissionInputLabel]: 'Max Retransmissions',
    [maxCountInputPlaceholder]: 'Max #',
    [maxSynRetransmissionInputLabel]: 'Max SYN Retransmissions',
    [bufferManagementHeader]: 'Buffer Management',
    [receiveWindowInputLabel]: 'Receive Window',
    [maxSegmentSizeHeader]: 'Max Segment Size',
    [useInterfaceMtuInputLabel]: 'Use Interface MTU',
    [useInterfaceMtuInputPlaceholder]: 'Size',
    [qosTrafficEngineeringHeader]: 'QOS & Traffic Engineering',
    [passThroughInputLabel]: 'Pass Through',
    [naglesAlgorithmInputLabel]: 'Nagles Algorithm',
    [tcpFastPathSettingsHeader]: 'TCP Fast Path Settings',
    [enableSynProtectionInputLabel]: 'Enable SYN Protection',
    [sessionIdleTimeoutInputLabel]: 'Session Idle Timeout',
    [secInnerText]: 'sec',
    [udpFastPathSettingsHeader]: 'UDP Fast Path Settings',
    [natClientIpAddressInputLabel]: 'NAT Client IP Address (SNAT)',
    [perPacketLoadbalancingInputLabel]: 'Per Packet Load Balancing',
    [udpProxySettingsHeader]: 'UDP Proxy Settings',
    [secondsInnerText]: 'Seconds',
    [cancelButtonLabel]: 'Cancel',
    [saveButtonLabel]: 'Save',
    [rbacHeader]: 'Role-Based Access Control (RBAC)',
};

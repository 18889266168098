/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const authenticationRuleConfigItemFactory = (MessageItem, defaultValues) => {
    /**
     * @constructor
     * @memberOf module:avi/policies/sso
     * @desc AuthenticationRule MessageItem class.
     * @extends module:avi/dataModel.MessageItem
     * @author Aravindh Nagarajan
     */
    class AuthenticationRuleConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'AuthenticationRule',
                ...args,
            };

            super(extendedArgs);
        }

        /**
         * @override
         * @protected
         */
        requiredFields() {
            return ['match'];
        }

        /**
         * @override
         */
        get defaultConfigOverride_() {
            const type = this.objectType.toLowerCase();
            const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};
            const { match, ...defaults } = defaultConfig;

            return {
                ...defaults,
                match: null,
            };
        }

        /**
         * Getter for the number of matches in authentication rule.
         * @returns {number}
         */
        get matchCount() {
            return this.config.match ? this.config.match.matchCount : 0;
        }

        /**
         * Getter for the type of action configured in auth rule.
         * @returns {string}
         */
        get actionType() {
            return this.config.action ? this.config.action.config.type : '';
        }

        /**
         * Getter for enable value of rule.
         * @return {boolean}
         */
        get enable() {
            return this.config.enable;
        }

        /**
         * Getter for match config item of a rule.
         * @return {module:avi/policies/sso.AuthenticationMatchConfigItem|null}
         */
        get match() {
            return this.config.match || null;
        }
    }

    return AuthenticationRuleConfigItem;
};

authenticationRuleConfigItemFactory.$inject = [
    'MessageItem',
    'defaultValues',
];

angular
    .module('avi/policies/sso')
    .factory('AuthenticationRuleConfigItem', authenticationRuleConfigItemFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../../less/components/unit-info.less';
import * as l10n from './UnitInfo.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/* Headers of network units with buttons like edit and info popovers. Used in navbar-tops. */
//TODO move metrics info to SE load method if it can be used anywhere else. +Rebuild @am
angular.module('aviApp').directive('serviceEngineInfo', [
'popupHelper', 'Auth', 'ServiceEngine', 'getSubnetString', 'l10nService',
function(popupHelper, Auth, ServiceEngine, getSubnetString, l10nService) {
    function link(scope, elm) {
        popupHelper(scope, elm);
        scope.getSubnetString = getSubnetString;
        scope.getSEVnicId = ServiceEngine.getVnicId;
        scope.getSEVlanId = ServiceEngine.getVlanId;
        scope.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        //to show/hide white background around header of Unit
        scope.$watch('display.showPopup', function(display) {
            if (display) {
                elm.addClass('popup-opened');
            } else {
                elm.removeClass('popup-opened');
            }
        });

        scope.Auth = Auth;

        scope.displayInterfaces = function() {
            scope.display.showInterfaces = !scope.display.showInterfaces;
        };

        scope.activeVnicInterfaces = scope.item.getInterfaceList('vnic');
        scope.activeVlanInterfaces = scope.item.getInterfaceList('vlan');

        /**
         * Used to generate an empty array based on number of sockets so that we can ng-repeat
         * over it.
         * @param  {number} num - Number of sockets.
         * @return {Array}
         */
        scope.getSockets = function(num) {
            return new Array(num);
        };

        /**
         * Returns values of disk and memory usage as values or percentage.
         * @param {string} resourceType - memory or disk.
         * @param {boolean=} asValue - By default function returns percentage. Pass truthy value
         *     as second parameter to get original value.
         * @returns {string} Returns string as function is only used to fulfill a template.
         */
        scope.getResourceUsage = function(resourceType, asValue) {
            const
                { item } = scope,
                config = item.getConfig(),
                resources = config && config['resources'],
                metricName = resourceType === 'memory' ? 'se_stats.avg_mem_usage' :
                    'se_stats.avg_disk1_usage',
                metric = item.getMetric(metricName),
                series = metric && metric.getMainSeries();

            let res = '0';

            if (resources && series && series.hasData()) {
                if (asValue && resourceType in resources) {
                    res = series.getValue('avg') * resources[resourceType] / 100;
                    res = res.toFixed(resourceType !== 'memory' ? 1 : 0);
                } else { //percents which we get from the backend
                    res = series.getValue('avg').toFixed(1);
                }
            }

            return res;
        };

        scope.unsubscribe = function() {
            scope.item.collMetricsUnSubscribe('unit-info');
        };

        scope.onHover = function() {
            const metrics = [{
                name: 'se_stats.avg_mem_usage',
                series: 'se_stats.avg_mem_usage',
                subscriber: 'unit-info',
            }, {
                name: 'se_stats.avg_disk1_usage',
                series: 'se_stats.avg_disk1_usage',
                subscriber: 'unit-info',
            }];

            if (!scope.item.busy) { //when busy we are loading item right now
                scope.item.loadConfig();
            }

            //get fresh data immediately on a hover event
            scope.item.collMetricsSubscribe(metrics).then(function() {
                scope.item.startCollMetricsAsync();
            });
        };

        scope.$on('$destroy', scope.unsubscribe);
    }

    return {
        restrict: 'A',
        scope: {
            item: '=',
        },
        templateUrl: 'src/views/components/infrastructure/service-engine-info.html',
        link,
    };
}]);

/**
 * @ngdoc directive
 * @name unitInfo
 * @restrict A
 * @param {Item} item
 * @param {Pool=} pool
 */
angular.module('aviApp').directive('unitInfo', ['Auth', 'popupHelper',
function(Auth, popupHelper) {
    function unitInfoLink(scope, elm, attr) {
        const reload = _.throttle(function() {
            scope.item.load();
        }, 9999, { trailing: false });

        scope.Auth = Auth;
        popupHelper(scope, elm);

        const { unitInfo: itemType } = attr;

        if (itemType === 'pool') {
            elm.on('mouseenter', reload);
        }

        //to show/hide white background around header of Unit
        scope.$watch('display.showPopup', function(display) {
            if (display) {
                elm.addClass('popup-opened');
            } else {
                elm.removeClass('popup-opened');
            }
        });
    }

    function templateUrl($elem, attr) {
        let url = 'src/views/components/';
        const dict = {
            pool: 'pool-info',
            server: 'server-info',
            gslbservice: 'gslbservice-info',
        };

        if (dict[attr.unitInfo]) {
            url += dict[attr.unitInfo];
        } else {
            url += 'wrongUnitTypePassedToUnitInfoDirective';
            console.error('unitInfo directive: wrong item type was passed');
        }

        return `${url}.html`;
    }

    return {
        restrict: 'A',
        scope: {
            item: '=',
            pool: '=', //pass pool for server
        },
        templateUrl,
        link: unitInfoLink,
    };
}]);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './vrf-context-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
/**
 * @ngdoc component
 * @name vrfContextList
 * @description Component for the VRF Context page.
 */

class VrfContextListController {
    constructor(CRUDGridConfig, infraCloudState, l10nService) {
        this._infraCloudState = infraCloudState;

        l10nService.registerSourceBundles(dictionary);

        const gridFields = [{
            name: 'data.config.name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            sortBy: 'name',
        }];

        this.gridConfig = new CRUDGridConfig({
            fields: gridFields,
        });

        this.handleVrfCollectionChange = this.handleVrfCollectionChange.bind(this);

        this._infraCloudState.on(
            'vrfContextCollectionChange',
            this.handleVrfCollectionChange,
        );
    }

    $onInit() {
        this.gridConfig.collection =
                this._infraCloudState.getVrfContextCollection();
    }

    /**
     * Handler for 'vrfContextCollectionChange' event. Resets the gridConfig collection.
     */
    handleVrfCollectionChange() {
        this.gridConfig.collection = this._infraCloudState.getVrfContextCollection();
    }

    $onDestroy() {
        const { _infraCloudState: infraCloudState } = this;
        const vrfContextCollection = infraCloudState.getVrfContextCollection();

        // to reset offset since same collection is used by other routing pages (sic!)
        vrfContextCollection.updateItemsVisibility(undefined, 0, true);

        infraCloudState.unbind(
            'vrfContextCollectionChange',
            this.handleVrfCollectionChange,
        );
    }
}

VrfContextListController.$inject = [
    'CRUDGridConfig',
    'infraCloudState',
    'l10nService',
];

angular.module('aviApp').component('vrfContextList', {
    controller: VrfContextListController,
    template:
        `<div class="infrastructure-routing">
            <collection-grid
                config="$ctrl.gridConfig"
                class="sel-segroup-list segroup">
            </collection-grid>
        </div>`,
});

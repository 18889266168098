/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import angular from 'angular';
import { IP_TYPE_ANY } from 'ajs/js/constants/DNS.constants';
import { parseIpString } from 'ng/shared/utils/ip-parser.utils';

/**
 * @memberOf module:avi/gslb
 * @mixes module:avi/gslb.gslbPoolMemberDomainNameComponentBindings
 * @desc
 *
 *     Populates two fields of GslbPoolMember:
 *     - "ip"
 *       - when FQDN is provided will perform FQDN resolution and render dropdown
 *         to select one of those. By default picks first IP in the list.
 *       - when IP address is provided will wipe out fqdn field and populate IP field with provided
 *         input
 *     - "fqdn" field is populated when non-ip formatted input is provided in "FQDN or IP input"
 *       field
 *
 *     Watches member reference update to resolve IPs by FQDN (when applicable).
 *
 *     Doesn't expect ip and fqdn properties to be messed with from outside. Hence doesn't watch em.
 *
 * @see {@link module:avi/gslb.gslbPoolMemberDomainNameComponent}
 */
class GslbPoolMemberDomainNameController {
    constructor(
        $q,
        Regex,
        DNS,
    ) {
        this.$q_ = $q;
        this.dnsService_ = DNS;

        this.anyIpRegex_ = Regex.anyIP;
        this.anyIPHostnameRegex = Regex.anyIPHostname;

        /** @type {string|undefined} */
        this.inputValue = '';

        /**
         * List of IPs resolved by FQDN provided.
         * @type {string[]}
         */
        this.resolutions = [];

        /** @type boolean */
        this.busy = false;

        /** @type {$q.defer|null} */
        this.cancelLookupDeferred_ = null;
    }

    /**
     * Sets ip address passed.
     * @param {string=} [value=undefined]
     * @protected
     */
    setIpAddress_(value) {
        const { member } = this;

        if (value) {
            member.ip = parseIpString(value);
        } else {
            member.ip = undefined;
        }
    }

    /**
     * Set ip address and fqdn fields. When input is valid IP address, set ip address and reset
     * fqdn to undefined. Else, set fqdn with the value provided and use DNS lookup to populate
     * list of ip addresses. First will be picked up as a value.
     */
    onInputValueChange() {
        const {
            inputValue,
            member,
            resolutions,
        } = this;

        resolutions.length = 0;

        if (!inputValue) {
            this.setIpAddress_();
            member.fqdn = undefined;

            return;
        }

        const { anyIpRegex_: ipRegex } = this;

        if (ipRegex.test(inputValue)) {
            this.setIpAddress_(inputValue);
            member.fqdn = undefined;
        } else { // FQDN case
            this.setIpAddress_();
            member.fqdn = inputValue;

            this.resolveFqdn_(inputValue)
                .then(ips => {
                    this.resolutions = ips;

                    const [firstIp] = ips;

                    if (firstIp) {
                        this.setIpAddress_(firstIp);
                    }
                });
        }
    }

    /**
     * Cancels ongoing fqdn lookup if any.
     * @protected
     */
    cancelPreviousFqdnResolution_() {
        if (this.cancelLookupDeferred_) {
            this.cancelLookupDeferred_.resolve();
        }

        this.cancelLookupDeferred_ = null;
    }

    /**
     * Perform dns lookup by fqdn and return list of resolved ips.
     * @param {string} fqdn - domain name to be resolved
     * @return {Promise<string[]>}
     * @protected
     */
    resolveFqdn_(fqdn) {
        this.cancelPreviousFqdnResolution_();

        const cancelLookupDeferred = this.$q_.defer();

        this.cancelLookupDeferred_ = cancelLookupDeferred;
        this.busy = true;

        const { dnsService_: dnsService } = this;

        return dnsService.lookup(fqdn, cancelLookupDeferred.promise, undefined, IP_TYPE_ANY)
            .finally(() => {
                this.cancelLookupDeferred_ = null;
                this.busy = false;
            });
    }

    /** @override */
    $onChanges({
        member: memberChange,
    }) {
        const { currentValue: member } = memberChange;
        const { fqdn, ip: { addr: ip } } = member;

        this.inputValue = fqdn || ip;
        this.resolutions.length = 0;

        if (fqdn) {
            this.resolveFqdn_(fqdn)
                .then(ips => this.resolutions = ips);
        }
    }

    /** @override */
    $onDestroy() {
        this.cancelPreviousFqdnResolution_();
    }
}

GslbPoolMemberDomainNameController.$inject = [
    '$q',
    'Regex',
    'DNS',
];

const templateUrl =
    'src/components/gslb/forms/gslb-pool-member-domain-name/gslb-pool-member-domain-name.html';

/**
 * @mixin gslbPoolMemberDomainNameComponentBindings
 * @memberOf module:avi/gslb
 * @property {Object} member - GslbPoolMember protobuf object.
 */
const bindings = {
    member: '<',
};

/**
 * @name gslbPoolMemberDomainNameComponent
 * @memberOf module:avi/gslb
 * @property {module:avi/gslb.GslbPoolMemberDomainNameController} controller
 * @property {module:avi/gslb.gslbPoolMemberDomainNameComponentBindings} bindings
 * @author Alex Malitsky
 */
angular.module('avi/gslb')
    .component('gslbPoolMemberDomainName', {
        bindings,
        controller: GslbPoolMemberDomainNameController,
        templateUrl,
    });

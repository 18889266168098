/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  GeoProfileCollection
 * @description  Collection of {GeoProfile} items.
 */
const GeoProfileCollectionFactory = (Collection, GeoProfile) => {
    class GeoProfileCollection extends Collection {}

    angular.extend(GeoProfileCollection.prototype, {
        objectName_: 'gslbgeodbprofile',
        itemClass_: GeoProfile,
        windowElement_: 'geo-profile-modal',
    });

    return GeoProfileCollection;
};

GeoProfileCollectionFactory.$inject = [
        'Collection',
        'GeoProfile',
];

angular.module('aviApp').factory('GeoProfileCollection', GeoProfileCollectionFactory);

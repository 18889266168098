/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name fullLogDetails
 * @param {Object} log - Log entry received from analytics/logs API. Protobuf ApplicationLog.
 * @param {Function} closeModal - Method provided by AviModal to close the modal used to render
 *     component.
 * @author Alex Malitsky
 * @description
 *
 *     There are four headers string properties in a log object:
 *         - request, received from client
 *         - request, sent to server after optional Avi processing
 *         - response, received from server
 *         - response, sent to client after optional Avi processing
 *
 *     Request and response headers are compared before and after processing (if any). If one of
 *     compared is truncated no diff will be shown - just will be rendered side by side.
 */
import './full-log-details.less';

class FullLogDetailsController {
    constructor(httpHeadersTextToHash) {
        this.httpHeadersTextToHash_ = httpHeadersTextToHash;

        /**
         * Dictionary to render headersHash later on.
         * @type {Object[]}
         * @property {string} key
         * @property {string} header
         * @private
         */
        this.types_ = [
            {
                key: 'all_request_headers',
                header: 'Headers received from client',
            }, {
                key: 'headers_sent_to_server',
                header: 'Headers sent to server',
            }, {
                key: 'headers_received_from_server',
                header: 'Headers received from server',
            }, {
                key: 'all_response_headers',
                header: 'Headers sent to client',
            },
        ];

        /**
         * For each type we keep it's hash (parsed from string), column header and truncated
         * flag.
         * @type {{hash: Object, header: string, truncated: boolean}}
         * @public
         */
        this.headersHash = {};
    }

    $onInit() {
        const { log } = this;

        this.types_.forEach(({ key, header }) => {
            const headersString = log[key];

            this.headersHash[key] = { header };

            const data = this.headersHash[key];

            if (headersString) {
                data.hash = this.httpHeadersTextToHash_(headersString);

                const ellipsisPos = headersString.lastIndexOf('...');

                data.truncated = ellipsisPos !== -1 && ellipsisPos + 3 === headersString.length;

                if (data.truncated) {
                    data.header += ' (truncated)';
                }
            }
        });

        /**
         * Returns true if one of the request headers lists got truncated - no diff will be
         * shown.
         * @type {boolean}
         * @public
         */
        this.requestHeadersTruncated = this.headersHash['all_request_headers'].truncated ||
            this.headersHash['headers_sent_to_server'].truncated;

        /**
         * Returns true if one of the response headers lists got truncated - no diff will be
         * shown.
         * @type {boolean}
         * @public
         */
        this.responseHeadersTruncated = this.headersHash['all_response_headers'].truncated ||
            this.headersHash['headers_received_from_server'].truncated;
    }
}

FullLogDetailsController.$inject = ['httpHeadersTextToHash'];

angular.module('aviApp').component('fullLogDetails', {
    bindings: {
        log: '<',
        closeModal: '&',
    },
    controller: FullLogDetailsController,
    templateUrl: 'src/components/applications/full-log-details/full-log-details.html',
});

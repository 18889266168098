/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    IWafApplicationSignatureAppVersion,
    IWafApplicationSignatureProvider,
} from 'generated-types';
import { Item } from 'ajs/modules/data-model';
import { naturalSort } from 'ajs/modules/core';

const naturalSortToken = 'naturalSort';
const wafApplicationSignatureProviderToken = 'wafapplicationsignatureprovider';

type TNaturalSort = typeof naturalSort;

/**
 * @description
 *
 *   Waf Application Signature Provider item.
 *
 * @author Rachit Aggarwal
 */
export class WafApplicationSignatureProvider extends Item {
    public getConfig: () => IWafApplicationSignatureProvider;

    private naturalSort: TNaturalSort;

    constructor(args = {}) {
        super(args);

        this.naturalSort = this.getAjsDependency_(naturalSortToken);
    }

    /** @override */
    public transformAfterLoad() : void {
        if (!this.getConfig().available_applications) {
            this.getConfig().available_applications = [];
        }
    }

    /**
     * Returns available application from config.
     */
    public get availableApplications():
    IWafApplicationSignatureProvider['available_applications'] {
        const applications = this.getConfig().available_applications;

        return applications.sort(this.applicationsComparator);
    }

    /**
     * Returns the error to be displayed in the tooltip, if any.
     */
    public get serviceStatusError(): string | undefined {
        return this.getConfig().service_status?.error;
    }

    /**
     * Comprator function for sorting allApplicationOptions.
     */
    private applicationsComparator = (
        app1: IWafApplicationSignatureAppVersion,
        app2: IWafApplicationSignatureAppVersion,
    ): number => {
        const applicationName1 = app1.application as string;
        const applicationName2 = app2.application as string;

        return this.naturalSort(applicationName1, applicationName2);
    };
}

WafApplicationSignatureProvider.ajsDependencies = [
    naturalSortToken,
];

Object.assign(WafApplicationSignatureProvider.prototype, {
    objectName: wafApplicationSignatureProviderToken,
    permissionName_: 'PERMISSION_WAFPOLICY',
});

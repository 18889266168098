/**
 * @module NatModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { NatRuleConfigItem } from 'ajs/js/services/config-items/nat-policy';

@Component({
    selector: 'nat-rules-list',
    templateUrl: './nat-rules-list.component.html',
})
export class NatRulesListComponent {
    /**
     * List of NatRuleConfigItems.
     */
    @Input() public rules: RepeatedMessageItem<NatRuleConfigItem>;

    /**
     * Fires on edit.
     */
    @Output() public onEdit: EventEmitter<NatRuleConfigItem> = new EventEmitter();

    /**
     * Click handler for editing a NAT rule.
     */
    public handleEdit(rule: NatRuleConfigItem): void {
        this.onEdit.emit(rule);
    }

    /**
     * Click handler for deleting a NAT rule.
     */
    public deleteRule(i: number): void {
        this.rules.remove(i);
    }

    /**
     * Handler for the drag-and-drop event.
     * Moves dragged rule to a new position in the list.
     */
    public handleDrop(dragEvent: CdkDragDrop<void>): void {
        const { previousIndex, currentIndex } = dragEvent;
        const rule = this.rules.at(previousIndex);

        this.rules.moveItem(rule, currentIndex);
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { UtcMismatchNotificationComponent } from '../../angular/modules/notification';

/**
 * Module for Avi App. It's the root module for Avi App.
 * @module avi/app
 */

const UTC_MISMATCH_NOTIFICATION_ID = 'utc-mismatch-notification';

/**
 * @constructor
 * @memberOf module:avi/app
 * @see {@link  module:avi/app.aviRootComponent aviRootComponent}
 */
class AviRootController {
    constructor($scope, Auth, notificationService, initialDataService) {
        /**
         * @type {module:avi/core.Auth}
         * @protected
         */
        this.Auth_ = Auth;

        /**
         * @type {angular.$scope}
         * @protected
         */
        this.$scope_ = $scope;

        this.notificationService_ = notificationService;

        /**
         * @type {initialDataService}
         * @protected
         */
        this.initialDataService_ = initialDataService;
    }

    /**
     * @override
     */
    $onInit() {
        /**
         * Time difference between controller and client clocks in seconds.
         * @type {number}
         */
        const { controllerTimeDifference } = this.initialDataService_;

        if (Math.abs(controllerTimeDifference) > 120) {
            this.notificationService_.add({
                id: UTC_MISMATCH_NOTIFICATION_ID,
                component: UtcMismatchNotificationComponent,
            });
        }
    }

    /**
     * @override
     */
    $onDestroy() {
        if (this.notificationService_.has(UTC_MISMATCH_NOTIFICATION_ID)) {
            this.notificationService_.remove(UTC_MISMATCH_NOTIFICATION_ID);
        }
    }
}

AviRootController.$inject = [
    '$scope',
    'Auth',
    'notificationService',
    'initialDataService',
];

/**
 * @name aviRootComponent
 * @property {module:avi/app.AviRootController} controller
 * @memberOf module:avi/app
 * @description Application root component.
 * @author alextsg, chitra
 */
angular.module('avi/app').component('aviRoot', {
    controller: AviRootController,
    templateUrl: 'src/components/avi-root/avi-root.html',
});

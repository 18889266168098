/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../../waf-rules-log.less';
import '../../waf-log-entry.less';
import '../../waf-log-match-element.less';
import * as l10n from './waf-app-rule-log.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @constructor
 * @memberOf module:avi/logs
 * @mixes module:avi/logs.wafAppRuleLogComponentBindings
 * @see {@link module:avi/logs.wafAppRuleLogComponent}
 */
class WafAppRuleLogController {
    constructor(l10nService) {
        /**
         * Get keys from source bundles for template usage.
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Some WAF groups don't have group names, and this.groupName will be in the format of
     * '__N/A:<number>' (where the number is an index) so that groups remain unique in the parent
     * hash. For those groups 'N/A' will be shown as the group name.
     * @see {@link module:avi/logs.WafLogsService.ruleGroupReducer_}
     * @returns {string}
     */
    getGroupName() {
        return this.groupName.indexOf('__N/A:') === 0 ? 'N/A' : this.groupName;
    }
}

WafAppRuleLogController.$inject = [
    'l10nService',
];

/**
 * @name wafAppRuleLogsListComponent
 * @memberOf module:avi/logs
 * @property {module:avi/logs.WafAppRuleLogController} controller
 * @property {module:avi/logs.wafAppRuleLogComponentBindings} bindings
 * @description Component for an Application Rule Log.
 * @author Rajawant Prajapati
 */
angular.module('avi/logs').component('wafAppRuleLog', {
    /**
     * @mixin wafAppRuleLogComponentBindings
     * @memberOf module:avi/logs
     * @property {string} groupName - Name of the WAF Group.
     * @property {WafRule[]} rules - Rules belonging to the WAF Group.
     * @property {boolean} expanded - Boolean to expand/collapse all.
     */
    bindings: {
        groupName: '@',
        rules: '<',
        expanded: '<',
    },
    controller: WafAppRuleLogController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-logs/waf-logs/' +
        'waf-app-rule-logs-list/waf-app-rule-log/waf-app-rule-log.component.html',
});

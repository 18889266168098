/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import classnames from 'classnames';
import './tri-switch.less';

declare type Size = 'small';

@Component({
    selector: 'tri-switch',
    templateUrl: './tri-switch.component.html',
})
export class TriSwitchComponent {
    @Input() public state: number;
    @Input() public showGrayed = false;
    @Input() public disabled = false;
    @Input() public size?: Size;

    @Output() public onClick = new EventEmitter();

    /**
     * Returns classnames based on component bindings.
     */
    public get classNames(): string {
        return classnames({
            'tri-switch--disabled': this.disabled,
            'tri-switch--gray': this.showGrayed,
            'tri-switch--off': this.state === 0,
            'tri-switch--small': this.size === 'small',
        });
    }

    /**
     * Tri-switch click handler
     */
    public handleClick($event: MouseEvent): void {
        $event.stopPropagation();
        this.onClick.emit();
    }
}

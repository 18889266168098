/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'shared-vs-vip';
const prefix = `${moduleName}.${componentName}`;

export const virtualServiceForVipSharingInputLabel = `${prefix}.virtualServiceForVipSharingInputLabel`;
export const virtualServiceForVipSharingInputPlaceholder = `${prefix}.virtualServiceForVipSharingInputPlaceholder`;
export const vipIpAddressesInputLabel = `${prefix}.vipIpAddressesInputLabel`;
export const vipIpAddressesInputPlaceholder = `${prefix}.vipIpAddressesInputPlaceholder`;
export const applicationDomainNameInputLabel = `${prefix}.applicationDomainNameInputLabel`;
export const applicationDomainNameInputPlaceholder = `${prefix}.applicationDomainNameInputPlaceholder`;

export const ENGLISH = {
    [virtualServiceForVipSharingInputLabel]: 'Virtual Service for VIP Sharing',
    [virtualServiceForVipSharingInputPlaceholder]: 'Select Virtual Service for VIP Sharing',
    [vipIpAddressesInputLabel]: 'VIP Addresses',
    [vipIpAddressesInputPlaceholder]: 'Shared VIP Address',
    [applicationDomainNameInputLabel]: 'Application Domain Name',
    [applicationDomainNameInputPlaceholder]: 'Shared Application Domain Name',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name  sslCertificateSigningRequest
 * @param {string} signingRequest - Text used as a certificate signing request.
 * @param {function} onChange - Updates parent with certificate information.
 * @description  Allows user to upload/copy-paste a certificate used in the CSR workflow.
 */
function Controller(clipboardService) {
    this.$onInit = function() {
        this.base64 = true;
    };

    this.copyToClipboard = function(message, target) {
        clipboardService.copy(message, target);
    };

    /**
     * Highlights the textarea text on focus.
     * @param  {Event} $event - jQuery event.
     */
    this.onFocus = function($event) {
        $event.target.select();
    };

    /**
     * Clears the certificate. Called when user changes radio button from upload to copy-paste.
     */
    this.clearCertificate = function() {
        this.certificate = undefined;
        this.handleCertificateChange();
    };

    /**
     * Calls onChange to update certificate data in a parent component.
     */
    this.handleCertificateChange = function() {
        this.onChange({
            base64: this.base64,
            certificate: this.certificate,
        });
    };
}

Controller.$inject = [
    'clipboardService',
];

angular.module('aviApp').component('sslCertificateSigningRequest', {
    bindings: {
        signingRequest: '@',
        onChange: '&',
    },
    controller: Controller,
    templateUrl: 'src/components/modals/templates/security/ssl-tls-certificates/' +
            'ssl-certificate-signing-request/ssl-certificate-signing-request.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'cportal';
const componentName = 'case-custom-tags';
const prefix = `${moduleName}.${componentName}`;

export const addSystemTagLabel = `${prefix}.addSystemTagLabel`;
export const addCustomTagLabel = `${prefix}.addCustomTagLabel`;
export const systemTagTypeLabel = `${prefix}.systemTagTypeLabel`;
export const customTagTypeLabel = `${prefix}.customTagTypeLabel`;
export const valueLabel = `${prefix}.valueLabel`;
export const deleteActionTitle = `${prefix}.deleteActionTitle`;

export const ENGLISH = {
    [addSystemTagLabel]: 'Add System Tag',
    [addCustomTagLabel]: 'Add Custom Tag',
    [systemTagTypeLabel]: 'System Type',
    [customTagTypeLabel]: 'Custom Type',
    [valueLabel]: 'Value',
    [deleteActionTitle]: 'Delete',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { SSOPolicyTypes } from '../../../../../js/services/items/SSOPolicy';

const { SAML: SSO_TYPE_SAML } = SSOPolicyTypes;

/**
 * @constructor
 * @memberOf module:avi/vs
 * @mixes module:avi/vs.samlPolicySettingsBindings
 * @see {@link module:avi/vs.samlPolicySettingsComponent samlPolicySettingsComponent}
 */
class SamlPolicySettingsController {
    constructor(
        CertificateCollection,
        SSOPolicyCollection,
    ) {
        /**
         * @type {module:avi/app.CertificateCollection}
         */
        this.certificateCollection = new CertificateCollection({
            params: {
                type: 'SSL_CERTIFICATE_TYPE_VIRTUALSERVICE',
            },
        });

        /**
         * @type {SSOPolicyCollection}
         */
        this.ssoPolicyCollection = new SSOPolicyCollection({
            params: {
                type: SSO_TYPE_SAML,
            },
            defaults: {
                type: SSO_TYPE_SAML,
            },
        });
    }

    /** @override */
    $onDestroy() {
        [
            this.certificateCollection,
            this.ssoPolicyCollection,
        ].forEach(collection => collection.destroy());
    }
}

SamlPolicySettingsController.$inject = [
    'CertificateCollection',
    'SSOPolicyCollection',
];

/**
 * @name samlPolicySettingsComponent
 * @memberOf module:avi/vs
 * @description
 *      VS Access Policy -> SAML Settings.
 *      Only applicable for HTTP app profile type.
 * @param {module:avi/vs.samlPolicySettingsBindings} bindings
 * @param {module:avi/vs.SamlPolicySettingsController} controller
 * @author Aravindh Nagarajan, Alex Malitsky
 */
angular.module('avi/vs').component('samlPolicySettings', {
    /**
     * @mixin samlPolicySettingsBindings
     * @memberOf module:avi/vs
     * @property {Object} samlSpConfig VirtualService#data#config#saml_sp_config
     * @property {string} ssoPolicyRef VirtualService#data#config#sso_policy_ref
     */
    bindings: {
        samlSpConfig: '=',
        ssoPolicyRef: '=',
    },
    controller: SamlPolicySettingsController,
    templateUrl:
        'src/components/applications/virtualservice/vs-access-policy-settings/' +
        'saml-policy-settings/saml-policy-settings.component.html',
});

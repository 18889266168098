/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './custom-params-list.component.less';
import * as l10n from './custom-params-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const componentName = 'custom-params-list';

/**
 * @constructor
 * @memberOf module:avi/app
 * @mixes module:avi/app.customParamsListComponenetBindings
 */
class CustomParamsListController {
    constructor(
        MessageItem,
        l10nService,
    ) {
        this.MessageItem_ = MessageItem;

        /**
        * Get keys from source bundles for template usage
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);

        this.addButtonLabel = this.addButtonLabel ||
            l10nService.getMessage(l10nKeys.addCustomParamsButtonLabel);
    }

    /**
     * Return config object according to the type of item passed.
     * @param {MessageItem|Object} paramsObj - CustomParams config-item/object.
     * @return {Object}
     */
    getParamsObjConfig(paramsObj) {
        return paramsObj instanceof this.MessageItem_ ? paramsObj.config : paramsObj;
    }
}

CustomParamsListController.$inject = [
    'MessageItem',
    'l10nService',
];

/**
 * @mixin customParamsListComponentBindings
 * @memberOf module:avi/app
 * @property {MessageItem[]} paramsList - List holding CustomParams config items.
 * @property {function} onAddEntry - Function for adding one entity into the list.
 * @property {function} onRemoveEntry - Function for removing one entity from the list.
 * @property {boolean=} [hasSensitive=false] - Flag deciding whether to have 'Sensitive' checkbox.
 * @property {boolean=} [hasDynamic=false] - Flag deciding whether to have 'Dynamic' checkbox.
 * @property {string=} addButtonLabel - Label for the adding button.
 */

/**
 * @name customParamsListComponent
 * @property {module:avi/app.CustomParamsListController} controller
 * @property {module:avi/app.customParamsListComponenetBindings} bindings
 * @memberOf module:avi/app
 * @description
 *      Component to provide list to configure groups of
 *      custom params for (custom) IPAM/DNS profiles.
 * @author Zhiqian Liu
 */
angular.module('avi/app').component('customParamsList', {
    controller: CustomParamsListController,
    bindings: {
        paramsList: '<',
        onAddEntry: '&',
        onRemoveEntry: '&',
        hasSensitive: '<?',
        hasDynamic: '<?',
        addButtonLabel: '@?',
    },
    templateUrl:
        `src/components/templates/profiles/${componentName}/${componentName}.component.html`,
});

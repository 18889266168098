const moduleName = 'ajs-legacy';
const componentName = 'dns-infoblox-usable-domains';
const prefix = `${moduleName}.${componentName}`;

export const usableDomainsInputLabel = `${prefix}.usableDomainsInputLabel`;
export const usableDomainsInputPlaceholder = `${prefix}.usableDomainsInputPlaceholder`;
export const removeUsableDomainButtonLabel = `${prefix}.removeUsableDomainButtonLabel`;
export const addUsableDomainButtonLabel = `${prefix}.addUsableDomainButtonLabel`;

export const ENGLISH = {
    [usableDomainsInputLabel]: 'Usable Domain',
    [usableDomainsInputPlaceholder]: 'Select Usable Domain',
    [removeUsableDomainButtonLabel]: 'Remove Usable Domain',
    [addUsableDomainButtonLabel]: '+ Add Usable Domain',
};

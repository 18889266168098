/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './traffic-clone-profile-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/** @alias trafficCloneProfileModal */
class TrafficCloneProfileModalController {
    constructor(
        Regex,
        Cloud,
        schemaService,
        l10nService,
    ) {
        this.Regex = Regex;
        this.Cloud = Cloud;

        /**
         * CloneServer field subnet description.
         * @type {string}
         */
        this.cloneServerSubnetDescription = schemaService
            .getFieldDescription('CloneServer', 'subnet');

        /**
         * CloneServer field network_uuid description.
         * @type {string}
         */
        this.cloneServernetworkUuidDescription = schemaService
            .getFieldDescription('CloneServer', 'network_uuid');

        /**
        * Get keys from source bundles for template usage
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this.cloudIsSet = !!(this.editable.id || this.editable.getCloudRef());
    }

    /**
     * Returns true if the network should be shown as a dropdown, in cases of write access
     * clouds like vCenter, OpenStack, and AWS. Otherwise, returns false to show an input for
     * the subnet.
     * @return {boolean}
     */
    showNetworkDropdown() {
        if (this.cloudType) {
            return this.Cloud.hasSubnetList(this.cloudType);
        }

        return this.editable.allowNetworkConfiguration();
    }

    /**
     * Called by cloudSetter component. Sets the "cloud_ref" property in this.editable.
     * @param {string} cloudRef - Cloud item url.
     * @param {Cloud.data.config} cloudConfig - Cloud config.
     * @public
     */
    setCloud(cloudRef, cloudConfig) {
        this.editable.setCloudRef(cloudRef, cloudConfig);
        this.cloudIsSet = true;
    }
}

TrafficCloneProfileModalController.$inject = [
    'Regex',
    'Cloud',
    'schemaService',
    'l10nService',
];

/**
 * @ngdoc component
 * @name  trafficCloneProfileModal
 * @description
 *     Component for Traffic Clone Profile modal.
 * @param {TrafficCloneProfile} editable
 * @param {Function} closeModal - Closes modal.
 * @param {string=} cloudType - Cloud type this traffic profile belongs to. Optional.
 */
angular.module('aviApp').component('trafficCloneProfileModal', {
    controller: TrafficCloneProfileModalController,
    bindings: {
        editable: '<',
        closeModal: '&',
        cloudType: '<?',
    },
    templateUrl: 'src/components/templates/profiles/traffic-clone-profile/' +
        'traffic-clone-profile-modal/traffic-clone-profile-modal.html',
});

/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */
import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { WafPolicyAllowlistRuleConfigItem } from 'ajs/modules/waf';
import { MatchTargetConfigItem, TMatchField } from 'ajs/modules/policies';
import { L10nService } from '@vmw/ngx-vip';
import {
    WafPolicyAllowlistRuleExpanderContentService,
} from './waf-policy-allowlist-rule-expander-content.service';
import './waf-policy-allowlist-rule-expander-content.less';

import * as l10n from './waf-policy-allowlist-rule-expander.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @desc Displays match and action information about a WAF allowlist rule.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-allowlist-rule-expander-content',
    templateUrl: './waf-policy-allowlist-rule-expander-content.component.html',
})
export class WafPolicyAllowlistRuleExpanderContentComponent implements OnInit {
    @Input() public rule: WafPolicyAllowlistRuleConfigItem;

    public match: MatchTargetConfigItem;
    public actionLabels: string;
    public matchFields: TMatchField[];
    public field: string;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private expanderService: WafPolicyAllowlistRuleExpanderContentService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const { match, actions } = this.rule.config;

        this.match = match;
        this.actionLabels = this.expanderService.getActionLabels(actions);

        // We filter out client_ip because for the other match fields, we can just use
        // $ctrl.match.config[field].toString() to get the string representation of the
        // ConfigItem's data, but with client_ip, we need to get the string representation from
        // flatProps_ in the config, since the IpOrStringGroup component doesn't work with
        // ConfigItems.
        // eslint-disable-next-line no-extra-parens
        this.matchFields = (match.fields as TMatchField[]).filter((field: TMatchField) => {
            return field !== 'client_ip';
        });
    }

    /**
     * Callback to ngFor-track by
     */
    public trackbyIndex(index: number): number {
        return index;
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function analyticsProfileCollectionFactory(Collection, AnalyticsProfile) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Collection
     * @author Ram Pal
     * @desc Collection of {@link module:avi/app.AnalyticsProfile AnalyticsProfiles}.
     */
    class AnalyticsProfileCollection extends Collection {}

    Object.assign(AnalyticsProfileCollection.prototype, {
        objectName_: 'analyticsprofile',
        itemClass_: AnalyticsProfile,
        windowElement_: 'analytics-profile-modal',
    });

    return AnalyticsProfileCollection;
}

analyticsProfileCollectionFactory.$inject = [
    'Collection',
    'AnalyticsProfile',
];

angular.module('avi/app')
    .factory('AnalyticsProfileCollection', analyticsProfileCollectionFactory);

/**
 * Avi core services and components
 * @module avi/core
 * @preferred
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { find, isString } from 'underscore';
import { StateService } from '@uirouter/core';
import { AjsDependency } from 'ajs/utils/ajsDependency';

import {
    IAppRouterState,
    IAppState,
    IToStateParams,
} from 'ajs/js/services/appStates.types';

import { Auth } from '../auth';
import { StateAccessMapService } from '..';

/**
 * @description
 *      Service to find a closest allowed state when App/User is trying to load
 *      an invalid state (i.e., States without access/ Object detail pages from
 *      different tenants.)
 * @author Aravindh Nagarajan
 */
export class StateManager extends AjsDependency {
    private $state: StateService;
    private auth: Auth;

    constructor() {
        super();

        this.$state = this.getAjsDependency_('$state');
        this.auth = this.getAjsDependency_('Auth');
    }

    /**
     * Returns true if state is an object detail page state.
     * All object detail states have `inTenantScope` set in their data.
     */
    private static isStateInTenantScope(state: IAppRouterState): boolean {
        const { data: stateData } = state;

        return !!stateData.inTenantScope;
    }

    /**
     * Returns true if toTenant and current tenant are same (or)
     * if toTenant is `All tenants` (*).
     * @param previousTenant Current Tenant name
     * @param newTenant Tenant name from toParams
     */
    private static newTenantIsSameOrAllTenants(previousTenant: string, newTenant: string): boolean {
        return newTenant === previousTenant || newTenant === '*';
    }

    /**
     * Returns the nearest accessible state.
     * @param state Search for accessible state will start from this state's neighbours.
     * @param toParams Tostate param.
     * @param fromTenant - Passed when switching tenant in the app.
     * @returns Closest accessibleState
     * @throws An exception if no closest accessibleState is found
     */
    public getClosestAllowedState(
        state: IAppRouterState | IAppRouterState['name'],
        toParams: IToStateParams,
        fromTenant = '',
    ): IAppRouterState {
        if (isString(state)) {
            state = this.getStateObject(state);
        }

        // Always stores previously processed state,
        // Just to backtrack, if we run into null.
        let prevState: IAppRouterState = state;

        // closestAllowedState will hold the final allowed state.
        let closestAllowedState: IAppRouterState = this.getParentState(state);

        // Loop till we find a parent with access.
        while (
            closestAllowedState &&
            !this.isAllowedState(closestAllowedState, toParams, fromTenant)
        ) {
            prevState = closestAllowedState;
            closestAllowedState = this.getParentState(closestAllowedState);
        }

        // Following block is for the case when we climb to `authenticated.application`
        // (dashboard) Page, but user still does not have access, so we should look for
        // the siblings (infrastructure, administration..) and find the relevant state
        // from there.
        if (closestAllowedState === null) {
            // Siblings can be in both sides (prev and next states),
            // Start searching left siblings first.
            closestAllowedState =
                this.findAllowedSiblingState(prevState, true);
        }

        // If no left siblings are accessible, look for right siblings.
        if (closestAllowedState === null) {
            closestAllowedState =
                this.findAllowedSiblingState(prevState, false);
        }

        if (closestAllowedState === null) {
            throw new Error('No valid state is found for this user.');
        }

        // If user has permission to an abstract state, he will surely have access to
        // one of its child state.
        if (this.isAbstract(closestAllowedState)) {
            closestAllowedState = this.getAllowedChildrenState(closestAllowedState);
        }

        return closestAllowedState;
    }

    /**
     * Returns true when user can access a state without any issues. Checks whether
     * user has permission to load the page.
     *
     * We can check permissions for current tenant only(!).
     * If different tenantName is passed via 'toParams' we can't return `true` cause
     * we don't have permissions mapping for other tenant.
     * @param state
     * @param toParams
     * @param fromTenant - Passed by tenant-selector component only.
     * @todo return false if toParams.tenantName !== currentTenant
     *     cause we don't have rules mapping
     */
    public isAllowedState(
        state: IAppRouterState | IAppRouterState['name'],
        toParams: IToStateParams,
        fromTenant = '',
    ): boolean {
        if (isString(state)) {
            state = this.getStateObject(state);
        }

        const currentTenant = this.auth.getTenantName();

        return this.checkUserPermissions(state) && (
            !StateManager.isStateInTenantScope(state) ||
            !fromTenant ||
            StateManager.newTenantIsSameOrAllTenants(fromTenant, currentTenant)
        );
    }

    /**
     * Finds accessible sibling of a state and returns it.
     * Returns null if it can't find any state with access.
     * If passed state is allowed, this method will return the same,
     * sibling will be returned only when passed state is not allowed
     * for the user.
     * @param state Starting point for search
     * @param moveLeft Direction of search: true for finding left sibling,
     *      false for right sibling
     */
    private findAllowedSiblingState(state: IAppRouterState, moveLeft: boolean): IAppRouterState {
        while (state && !this.checkUserPermissions(state)) {
            state = this.getSiblingState(state, moveLeft);
        }

        return state;
    }

    /**
     * If user has access to an abstract state (parent),
     * we need to look for one of its child state with access, since router cannot target
     * an abstract state.
     */
    private getAllowedChildrenState(state: IAppRouterState): IAppRouterState | null {
        const { children: childrenStates } = state;

        const childState: IAppState =
            find(childrenStates, state => this.checkUserPermissions(state));

        if (childState && this.isAbstract(childState)) {
            return this.getAllowedChildrenState(childState);
        }

        if (!childState) {
            return null;
        }

        // To maintain type consistency of State object across the code.
        return this.getStateObject(childState.name);
    }

    /**
     * Returns true if User has permission to load a state.
     */
    private checkUserPermissions(state: IAppRouterState): boolean {
        const { name: stateName } = state;

        const stateAccessMapService: StateAccessMapService =
            this.getAjsDependency_('stateAccessMapService');

        return stateAccessMapService.getStateAccess(stateName);
    }

    /**
     * Returns parentState of a state.
     * If no parentState is found, returns null.
     */
    private getParentState(state: IAppRouterState): IAppRouterState | null {
        const { data: stateData } = state;

        const {
            parentState: parentStateName,
        } = stateData;

        if (parentStateName) {
            return this.getStateObject(parentStateName);
        }

        return null;
    }

    /**
     * Returns Right or Left sibling state of a state.
     * By default left siblings(prevState) will be returned.
     * If no sibling is found, null is returned.
     * @param state
     * @param [moveLeft=true] True for left sibling, false for right sibling.
     */
    private getSiblingState(
        state: IAppRouterState,
        moveLeft = true,
    ): IAppRouterState | null {
        const { data: stateData } = state;

        const {
            nextState: nextStateName,
            previousState: previousStateName,
        } = stateData;

        const targetStateName = moveLeft ? previousStateName : nextStateName;

        if (targetStateName) {
            return this.getStateObject(targetStateName);
        }

        return null;
    }

    /**
     * Returns true, if passed state is an abstract state.
     */
    private isAbstract(state: IAppRouterState | IAppRouterState['name']): boolean {
        if (isString(state)) {
            state = this.getStateObject(state);
        }

        return !!state.abstract;
    }

    /**
     * Returns state object for a state name.
     */
    private getStateObject(stateName: IAppRouterState['name']): IAppRouterState {
        const $state: StateService = this.getAjsDependency_('$state');

        return $state.get(stateName);
    }
}

StateManager.ajsDependencies = [
    '$state',
    'Auth',
    'stateAccessMapService',
];

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  ProtocolParserCollection
 * @description  Collection of {ProtocolParser} items.
 */
const protocolParserCollectionFactory = (Collection, ProtocolParser) => {
    class ProtocolParserCollection extends Collection {}

    Object.assign(ProtocolParserCollection.prototype, {
        objectName_: 'protocolparser',
        itemClass_: ProtocolParser,
        windowElement_: 'protocol-parser-modal',
    });

    return ProtocolParserCollection;
};

protocolParserCollectionFactory.$inject = [
    'Collection',
    'ProtocolParser',
];

angular.module('aviApp').factory('ProtocolParserCollection', protocolParserCollectionFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import angular from 'angular';
import { Collection } from 'ajs/modules/data-model';
import { initAjsDependency } from 'ajs/utils/ajsDependency';

const WebhookToken = 'Webhook';

export class WebhookCollection extends Collection {
    constructor(args = {}) {
        args = Object.assign(
            {
                objectName: 'webhook',
                permissionName: 'PERMISSION_POOL',
                windowElement: 'webhook-modal',
            },
            args,
        );

        super(args);

        this.itemClass_ = this.getAjsDependency_(WebhookToken);
    }
}

WebhookCollection.ajsDependencies = [
    WebhookToken,
];

initAjsDependency(
    angular.module('avi/app'),
    'factory',
    'WebhookCollection',
    WebhookCollection,
);

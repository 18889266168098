const moduleName = 'waf';
const componentName = 'waf-policy-psm-locations-config';
const prefix = `${moduleName}.${componentName}`;

export const searchInputPlaceholder = `${prefix}.searchInputPlaceholder`;
export const nameLabel = `${prefix}.nameLabel`;
export const matchesLabel = `${prefix}.matchesLabel`;
export const addLocationButtonLabel = `${prefix}.addLocationButtonLabel`;
export const psmGroupBreadcrumb = `${prefix}.psmGroupBreadcrumb`;

export const ENGLISH = {
    [searchInputPlaceholder]: 'Search',
    [nameLabel]: 'Name',
    [matchesLabel]: 'Matches',
    [addLocationButtonLabel]: 'Add Location',
    [psmGroupBreadcrumb]: 'Positive Security Group: {0}',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name DataTransformers
 * @description
 *
 *     This is just a list of data transformer instances, working like a registry
 *     and can be injected and used to subscribe and manipulate the data
 *     Each data transformer is an object containing 2 functions, first one doing preparation
 *     for the data load and second one is transforming the responses to make the data presentation
 *     friendly.
 *
 *     LEGACY, avoid when possible by passing metricClassName to metric config object.
 *
 * @deprecated
 */
angular.module('aviApp').service('DataTransformers', [
'HealthDataTransformer', 'AlertDataTransformer', 'EndToEndTimeMetric', 'PoolServerVMMetric',
'PoolServersHsScatterMetric', 'VirtualServiceFaultDataTransformer', 'DnsPassThroughMetric',
'PoolServerVHostMetric',
function(HealthDataTransformer, AlertDataTransformer, EndToEndTimeMetric, PoolServerVMMetric,
PoolServersHsScatterMetric, VirtualServiceFaultDataTransformer, DnsPassThroughMetric,
PoolServerVHostMetric) {
    //legacy stuff here, deprecated TODO drop
    const hash = {
        // Getting health score data, requires making inventory call with health_score and runtime
        health: new HealthDataTransformer(),
        alert: new AlertDataTransformer(),
        faults: new VirtualServiceFaultDataTransformer(),
    };

    // collection metrics
    hash['vhost.vm_stats'] = PoolServerVHostMetric;
    hash['pools_servers_hs_scatter'] = PoolServersHsScatterMetric;
    hash['end_to_end'] = EndToEndTimeMetric;

    [
        'avg_cpu_usage',
        'avg_mem_usage',
        'avg_virtual_disk_throughput_usage',
    ].forEach(
        metricName => hash[`vm_stats.${metricName}`] = PoolServerVMMetric,
    );

    // for any of above series in one metric, not stacked
    hash['vm_stats'] = PoolServerVMMetric;

    hash['dns_client.avg_udp_passthrough_resp_time'] = DnsPassThroughMetric;

    return hash;
}]);

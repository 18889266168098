/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  SSOPolicyCollection
 * @description  Collection of {SSOPolicy} items.
 */
const collectionFactory = (Collection, SSOPolicy) => {
    class SSOPolicyCollection extends Collection {}

    angular.extend(SSOPolicyCollection.prototype, {
        objectName_: 'ssopolicy',
        itemClass_: SSOPolicy,
        windowElement_: 'sso-policy-modal',
    });

    return SSOPolicyCollection;
};

collectionFactory.$inject = [
    'Collection',
    'SSOPolicy',
];

angular.module('aviApp').factory('SSOPolicyCollection', collectionFactory);

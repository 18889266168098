/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const pingAccessAgentProfileCollectionFactory = (Collection, PingAccessAgentProfile) => {
    class PingAccessAgentProfileCollection extends Collection {
    }

    angular.extend(PingAccessAgentProfileCollection.prototype, {
        objectName_: 'pingaccessagent',
        windowElement_: 'ping-access-agent-modal',
        itemClass_: PingAccessAgentProfile,
    });

    return PingAccessAgentProfileCollection;
};

pingAccessAgentProfileCollectionFactory.$inject = [
    'Collection',
    'PingAccessAgentProfile',
];

/**
 * @ngdoc factory
 * @name  PingAccessProfileCollection
 * @description  Collection of {PingAccessAgent} items.
 */
angular.module('aviApp')
    .factory('PingAccessAgentProfileCollection', pingAccessAgentProfileCollectionFactory);

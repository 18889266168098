/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'virtualservice-vip-placement';
const prefix = `${moduleName}.${componentName}`;

export const virtualIpPlacementSettingsHeader = `${prefix}.virtualIpPlacementSettingsHeader`;
export const placementIpv4SubnetInputLabel = `${prefix}.placementIpv4SubnetInputLabel`;
export const placementIpv6SubnetInputLabel = `${prefix}.placementIpv6SubnetInputLabel`;
export const virtualIpHeader = `${prefix}.virtualIpHeader`;
export const placementNetworkInputLabel = `${prefix}.placementNetworkInputLabel`;
export const placementNetworkInputPlaceholder = `${prefix}.placementNetworkInputPlaceholder`;
export const removePlacementNetworkButtonLabel = `${prefix}.removePlacementNetworkButtonLabel`;
export const ipv4SubnetInputLabel = `${prefix}.ipv4SubnetInputLabel`;
export const ipv6SubnetInputLabel = `${prefix}.ipv6SubnetInputLabel`;
export const addPlacementNetworkButtonLabel = `${prefix}.addPlacementNetworkButtonLabel`;

export const ENGLISH = {
    [virtualIpPlacementSettingsHeader]: 'Virtual IP Placement Settings',
    [placementIpv4SubnetInputLabel]: 'Placement IPv4 Subnet',
    [placementIpv6SubnetInputLabel]: 'Placement IPv6 Subnet',
    [virtualIpHeader]: 'Virtual IP',
    [placementNetworkInputLabel]: 'Placement Network',
    [placementNetworkInputPlaceholder]: 'Select Placement Network',
    [removePlacementNetworkButtonLabel]: 'Remove Placement Network',
    [ipv4SubnetInputLabel]: 'IPv4 Subnet',
    [ipv6SubnetInputLabel]: 'IPv6 Subnet',
    [addPlacementNetworkButtonLabel]: 'Add Placement Network',
};

/**
 * Module containing CloudConnectorUser components.
 * @preferred
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    ClrFormsModule,
    ClrInputModule,
    ClrRadioModule,
    ClrTextareaModule,
} from '@clr/angular';
import { SharedModule } from 'ng/shared/shared.module';
import {
    GcpCredentialsComponent,
    NsxtCredentialsComponent,
    UserCredentialsModalComponent,
    VCenterCredentialsComponent,
} from '.';

const cloudConnectorUserComponents = [
    GcpCredentialsComponent,
    NsxtCredentialsComponent,
    UserCredentialsModalComponent,
    VCenterCredentialsComponent,
];

@NgModule({
    declarations: [
        ...cloudConnectorUserComponents,
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        ClrInputModule,
        ClrRadioModule,
        ClrTextareaModule,
        ClrFormsModule,
    ],
})
export class CloudConnectorUserModule {}

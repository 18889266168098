/**
 * @module Angular-Core
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

/**
 * @fileoverview Angular components need to be specified here to be downgraded and used in
 *     AngularJS.
 */
import angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

import {
    AviNotificationComponent,
    AviNotificationsPortalComponent,
    UtcMismatchNotificationComponent,
} from './angular/modules/notification';
import {
    AviConfirmationComponent,
    AviDialogsPortalComponent,
} from './angular/modules/dialog';
import {
    AviDropdownButtonComponent,
    AviDropdownComponent,
    AviLabelWithTooltipComponent,
    AviStatePageComponent,
    CopyrightComponent,
    ExpanderComponent,
    ExpanderWithActionsComponent,
    FullModalComponent,
    GradientLoaderComponent,
    HelperTextTooltipIconComponent,
    RbacLabelGridAjsWrapperComponent,
    TriSwitchComponent,
} from './angular/shared/components';
import {
    WafCrsExpanderOpenerComponent,
    WafCrsRuleGroupComponent,
    WafExcludeListComponent,
    WafLearningLinkComponent,
    WafModeComponent,
    WafPolicyAllowlistRulesListComponent,
    WafPolicyApplicationRulesComponent,
    WafPolicyPsmGroupsConfigComponent,
    WafPolicyPsmLocationsConfigComponent,
    WafPolicyPsmRulesConfigComponent,
    WafUserOverridesBadgeComponent,
} from './angular/modules/waf/components';
import { NatRulesListComponent } from './angular/modules/nat-policy/components';
import { LicenseSummaryCardComponent } from './angular/modules/licensing';
import {
    RecentUpdatesListComponent,
    SystemUpdateAboutCardComponent,
    UpdateCardAboutComponent,
    UpgradeTranscriptModalComponent,
} from './angular/modules/update';
import {
    MainMenuComponent,
    StateButtonComponent,
} from './angular/modules/header';
import {
    ConfiguredNetworkSubnetExpanderComponent,
    NetworkListExpanderComponent,
} from './angular/modules/network';

/**
 * List out Angular components to downgrade here. Names must be listed explicitly and not generated
 * automatically from the component class since that information gets stripped in the dist build.
 */
const downgradeComponents = [{
    ajsName: 'aviConfirmation',
    component: AviConfirmationComponent,
}, {
    ajsName: 'wafPolicyAllowlistRulesList',
    component: WafPolicyAllowlistRulesListComponent,
}, {
    ajsName: 'wafPolicyApplicationRules',
    component: WafPolicyApplicationRulesComponent,
}, {
    ajsName: 'wafPolicyPsmLocationsConfig',
    component: WafPolicyPsmLocationsConfigComponent,
}, {
    ajsName: 'wafPolicyPsmRulesConfig',
    component: WafPolicyPsmRulesConfigComponent,
}, {
    ajsName: 'wafPolicyPsmGroupsConfig',
    component: WafPolicyPsmGroupsConfigComponent,
}, {
    ajsName: 'expander',
    component: ExpanderComponent,
}, {
    ajsName: 'expanderWithActions',
    component: ExpanderWithActionsComponent,
}, {
    ajsName: 'gradientLoader',
    component: GradientLoaderComponent,
}, {
    ajsName: 'recentUpdatesList',
    component: RecentUpdatesListComponent,
}, {
    ajsName: 'updateCardAbout',
    component: UpdateCardAboutComponent,
}, {
    ajsName: 'utcMismatchNotification',
    component: UtcMismatchNotificationComponent,
}, {
    ajsName: 'aviNotification',
    component: AviNotificationComponent,
}, {
    ajsName: 'helperTextTooltipIcon',
    component: HelperTextTooltipIconComponent,
}, {
    ajsName: 'aviStatePage',
    component: AviStatePageComponent,
}, {
    ajsName: 'aviLabelWithTooltip',
    component: AviLabelWithTooltipComponent,
}, {
    ajsName: 'natRulesList',
    component: NatRulesListComponent,
}, {
    ajsName: 'triSwitch',
    component: TriSwitchComponent,
}, {
    ajsName: 'aviNotificationsPortal',
    component: AviNotificationsPortalComponent,
}, {
    ajsName: 'aviDropdown',
    component: AviDropdownComponent,
}, {
    ajsName: 'fullModal',
    component: FullModalComponent,
}, {
    ajsName: 'aviDropdownButton',
    component: AviDropdownButtonComponent,
}, {
    ajsName: 'copyright',
    component: CopyrightComponent,
}, {
    ajsName: 'mainMenu',
    component: MainMenuComponent,
}, {
    ajsName: 'stateButton',
    component: StateButtonComponent,
}, {
    ajsName: 'wafLearningLink',
    component: WafLearningLinkComponent,
}, {
    ajsName: 'licenseSummaryCard',
    component: LicenseSummaryCardComponent,
}, {
    ajsName: 'networkListExpander',
    component: NetworkListExpanderComponent,
}, {
    ajsName: 'configuredNetworkSubnetExpander',
    component: ConfiguredNetworkSubnetExpanderComponent,
}, {
    ajsName: 'rbacLabelGridAjsWrapper',
    component: RbacLabelGridAjsWrapperComponent,
}, {
    ajsName: 'upgradeTranscriptModal',
    component: UpgradeTranscriptModalComponent,
}, {
    ajsName: 'aviDialogsPortal',
    component: AviDialogsPortalComponent,
}, {
    ajsName: 'systemUpdateAboutCard',
    component: SystemUpdateAboutCardComponent,
}, {
    ajsName: 'wafCrsExpanderOpener',
    component: WafCrsExpanderOpenerComponent,
}, {
    ajsName: 'wafExcludeList',
    component: WafExcludeListComponent,
}, {
    ajsName: 'wafMode',
    component: WafModeComponent,
}, {
    ajsName: 'wafCrsRuleGroup',
    component: WafCrsRuleGroupComponent,
}, {
    ajsName: 'wafUserOverridesBadge',
    component: WafUserOverridesBadgeComponent,
}];

downgradeComponents.forEach(componentData => {
    const { ajsName, component } = componentData;

    if (!ajsName) {
        throw new Error(`Angular JS name needed for ${component}`);
    }

    angular.module('aviApp').directive(ajsName, downgradeComponent({ component }));
});

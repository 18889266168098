/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'update';
const componentName = 'compliance-mode-confirmation';
const prefix = `${moduleName}.${componentName}`;

export const complianceModeHeader = `${prefix}.complianceModeHeader`;
export const changeWarningMessage = `${prefix}.changeWarningMessage`;
export const complianceModeMessage = `${prefix}.complianceModeMessage`;
export const enableFipsCheckboxLabel = `${prefix}.enableFipsCheckboxLabel`;
export const enableCommonCriteriaCheckboxLabel = `${prefix}.enableCommonCriteriaCheckboxLabel`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const continueButtonLabel = `${prefix}.continueButtonLabel`;

export const ENGLISH = {
    [complianceModeHeader]: 'Compliance Mode',
    [changeWarningMessage]: 'This change is disruptive and will trigger warm start of Control ' +
        'and Data planes. Please confirm you would like to proceed.',
    [complianceModeMessage]: 'Enabling FIPS in Compliance mode will restrict SSH and TLS ciphers ' +
        'and protocol versions. Enabling Common Criteria in Compliance mode will disallow TLS ' +
        '1.0 and OCSP Stapling, in addition to FIPS mode restrictions.',
    [enableFipsCheckboxLabel]: 'Enable FIPS',
    [enableCommonCriteriaCheckboxLabel]: 'Enable Common Criteria',
    [cancelButtonLabel]: 'Cancel',
    [continueButtonLabel]: 'Yes, continue',
};

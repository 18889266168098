/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

class GslbServiceListController {
    constructor(
        Auth,
        CRUDGridConfig,
        GSLBServiceCollection,
    ) {
        this.Auth = Auth;
        this.CRUDGridConfig = CRUDGridConfig;
        this.GSLBServiceCollection = GSLBServiceCollection;
    }

    $onInit() {
        this.setGridConfig_();
    }

    /**
     * Creates gridConfig.
     * @protected
     */
    setGridConfig_() {
        const param = this.gslb_ ? { gslb: this.gslb_ } : {};

        this.collection_ = new this.GSLBServiceCollection(param);

        const gridConfigTemplate = {
            collection: this.collection_,
            fields: [{
                name: 'name',
                title: 'Name',
                template:
                    `<a ui-sref="^.gslbservice-detail.members({ gslbServiceId: '{{ row.id }}' })">
                    {{ row.getName() }}</a>`,
                visibility: 'm',
            }, {
                name: 'domain_name',
                title: 'App Domain Name',
                transform(row) {
                    return row.getDomainNames().join(', ');
                },
                visibility: 'm',
            }, {
                name: 'enable_state',
                title: 'Enable State',
                template: '{{row.getConfig().enabled | booleanLabel: "enabled"}}',
                visibility: 'm',
            }, {
                name: 'status',
                require: 'runtime, health',
                title: 'Status',
                template: `<avi-healthscore
                item="row"
                type="gslbservice"
                stop-async-on-hide="true" />`,
                visibility: 'm',
            }, {
                name: 'sp_status',
                require: 'runtime',
                title: 'Site Persistence Status',
                template: "{{row.getRuntimeData()['sp_oper_status']['state'] | unitStateLabel}}",
            }],
            layout: {
                includeTimeframeSelector: true,
                disabledCreateButtonLabel: this.getDisabledCreateButtonTitle_(),
            },
            createActions: [{
                label: 'Basic Setup',
                onClick: () => {
                    this.collection_.create(undefined, { basicEditMode: 'active-active' });
                },
            }, {
                label: 'Advanced Setup',
                onClick: () => this.collection_.create(),
            }],
        };

        this.gslbServiceGridConfig = this.CRUDGridConfig(gridConfigTemplate);

        const { objectName } = this.collection_;

        this.gslbServiceGridConfig.id = `${objectName}-list-page`;

        this.gslbServiceGridConfig.multipleactions.push(
            GslbServiceListController.getEnablementActionConfig_(true),
            GslbServiceListController.getEnablementActionConfig_(false),
        );
    }

    /**
     * Determines the hover text if edit button is disabled, depending on if is child site, or
     * if is missing appropriate permisssions for editing.
     * @return {string}
     * @protected
     */
    getDisabledCreateButtonTitle_() {
        const { gslb, Auth } = this;

        if (gslb) {
            return `GSLB Site "${gslb.getLeaderSiteName()}" is the leader`;
        }

        if (!Auth.isAllowed('PERMISSION_GSLB') && Auth.isAllowed('PERMISSION_GSLBSERVICE', 'w')) {
            return 'GSLB Config permissions must be set to read or write to create a GSLB Service';
        }
    }

    /**
     * Returns a multiple action config for the use of collection-grid.
     * @param {boolean} enable
     * @returns {Object} Grid multiple action config.
     * @protected
     */
    static getEnablementActionConfig_(enable) {
        let disabledPerRow;
        const doPerRow = row => !disabledPerRow(row) && row.setEnabledState(enable);

        if (enable) {
            disabledPerRow = row => row.isEnabled();
        } else {
            disabledPerRow = row => !row.isEnabled();
        }

        return {
            title: enable ? 'Enable' : 'Disable',
            disabled: rows => _.every(rows, disabledPerRow),
            do: rows => {
                rows.forEach(doPerRow);

                return true;
            },
        };
    }

    $onDestroy() {
        if (this.collection_) {
            this.collection_.destroy();
        }

        if (this.gslb_) {
            this.gslb_.destroy();
        }
    }
}

const componentTag = 'gslb-service-list';
const templateUrl =
    `src/components/pages/application/gslb/${componentTag}/${componentTag}.component.html`;

GslbServiceListController.$inject = [
    'Auth',
    'CRUDGridConfig',
    'GSLBServiceCollection',
];

/**
 * @ngdoc component
 * @name gslbServiceList
 * @param {GSLB|null} gslb_ - gslb instance, or null if unavailable due to permissions
 * @author Alex Malitsky, Akul Aggarwal
 * @description
 *
 *     Grid list of existing gslb services.
 */
angular.module('avi/gslb').component('gslbServiceList', {
    bindings: {
        gslb_: '<resolveGslb',
    },
    controller: GslbServiceListController,
    templateUrl,
});

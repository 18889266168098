/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

/**
 * @description Parses and formats comma-separated string of IP addresses.
 *
 * @author Aravindh Nagarajan
 */

import {
    Directive,
    ElementRef,
    forwardRef,
    HostListener,
    Provider,
    Renderer2,
} from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { IIpAddr } from 'generated-types';

import {
    formatIpAddrList,
    parseIpList,
} from '../../utils/ip-parser.utils';

const PARSE_IP_LIST_DIRECTIVE_ACCESSOR: Provider = {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ParseIpListDirective),
};

@Directive({
    selector: '[parseIpList]',
    providers: [
        PARSE_IP_LIST_DIRECTIVE_ACCESSOR,
    ],
})
export class ParseIpListDirective implements ControlValueAccessor {
    public constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef,
    ) {}

    /**
     * Listener for the input change event.
     * parses Input value and calls onChange to proceed with the new model value.
     */
    @HostListener('input', ['$event.target.value'])
    private onInputChange(value: string): void {
        const modelValue: IIpAddr[] | null = this.parse(value);

        this.onChange(modelValue);
    }

    /**
     * Writes the input display value with ipAddresses string.
     */
    public writeValue(value: IIpAddr[]): void {
        const { nativeElement: hostElement } = this.elementRef;
        const formattedIp = this.format(value);

        this.renderer.setProperty(hostElement, 'value', formattedIp);
    }

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     *
     * This method will be used to update ngModel value when user
     * changes input value.
     */
    private onChange: (value: string | IIpAddr[]) => void = (value: string) => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouch: () => void = () => {};

    /**
     * Formats IpAddr objects list into comma separated IP address string.
     */
    private format(val: IIpAddr[]): string {
        return formatIpAddrList(val);
    }

    /**
     * Parses IP addresses string into a list of IPAddr object.
     */
    private parse(val: string): IIpAddr[] {
        return parseIpList(val);
    }
}

const moduleName = 'icap-profile';
const componentName = 'icap-profile-list-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleServiceUri = `${prefix}.columnTitleServiceUri`;
export const columnTitlePoolGroup = `${prefix}.columnTitlePoolGroup`;
export const columnTitleFailAction = `${prefix}.columnTitleFailAction`;
export const columnTitleVendor = `${prefix}.columnTitleVendor`;
export const actionBtnDelete = `${prefix}.actionBtnDelete`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleServiceUri]: 'Service URI',
    [columnTitlePoolGroup]: 'Pool Group',
    [columnTitleFailAction]: 'Fail Action',
    [columnTitleVendor]: 'Vendor',
    [actionBtnDelete]: 'Delete',
};

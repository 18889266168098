/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'system';
const componentName = 'email-smtp-modal';
const prefix = `${moduleName}.${componentName}`;

export const emailSmtpHeader = `${prefix}.emailSmtpHeader`;
export const generalSectionHeader = `${prefix}.generalSectionHeader`;
export const localHostSectionHeader = `${prefix}.localHostSectionHeader`;
export const anonymousSmtpServerSectionHeader = `${prefix}.anonymousSmtpServerSectionHeader`;
export const smtpSourceFieldLabel = `${prefix}.smtpSourceFieldLabel`;
export const smtpSourceFieldPlaceholder = `${prefix}.smtpSourceFieldPlaceholder`;
export const emailAddressInputLabel = `${prefix}.emailAddressInputLabel`;
export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const smtpServerLabel = `${prefix}.smtpServerLabel`;
export const doNotUseTlsLabel = `${prefix}.doNotUseTlsLabel`;
export const smtpPortInputLabel = `${prefix}.smtpPortInputLabel`;
export const smtpServerNamePlaceholder = `${prefix}.smtpServerNamePlaceholder`;
export const portNumberInputLabel = `${prefix}.portNumberInputLabel`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;

export const ENGLISH = {
    [emailSmtpHeader]: 'Email/SMTP',
    [generalSectionHeader]: 'General',
    [localHostSectionHeader]: 'Local Host',
    [anonymousSmtpServerSectionHeader]: 'Anonymous SMTP Server',
    [smtpSourceFieldLabel]: 'SMTP Source',
    [smtpSourceFieldPlaceholder]: 'Select SMTP Source',
    [emailAddressInputLabel]: 'From Address',
    [usernameInputLabel]: 'Username',
    [passwordInputLabel]: 'Password',
    [smtpServerLabel]: 'SMTP Server',
    [doNotUseTlsLabel]: 'Do not use TLS',
    [smtpPortInputLabel]: 'SMTP Port',
    [smtpServerNamePlaceholder]: 'domain.com or 123.123.123.123',
    [portNumberInputLabel]: 'Port Number',
    [cancelButtonLabel]: 'Cancel',
    [saveButtonLabel]: 'Save',
};

/** @module GslbModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { Collection } from 'ajs/modules/data-model';

export class FederationCheckpointCollection extends Collection {
    constructor(args = {}) {
        const allDataSources = {
            list: {
                source: 'InventoryCollDataSource',
                transformer: 'InventoryDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        };

        const extendedArgs = {
            ...args,
            allDataSources,
            isStatic: false,
            objectName: 'federationcheckpoint-inventory',
            permissionName: 'PERMISSION_CONTROLLER',
            sortBy: '-date',
            windowElement: 'gslb-checkpoints-modal',
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('FederationCheckpoint');
    }
}

FederationCheckpointCollection.ajsDependencies = [
    'FederationCheckpoint',
];

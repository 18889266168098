/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../../../less/pages/administration/gslb.less';
import * as l10n from './GSLBController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
/**
 * @ngdoc controller
 * @name GSLBController
 * @author Alex Malitsky
 * @description
 *
 *     GslbSite lists come from GSLB.data.config.sites, hence this page works almost as
 *     modal window where you can change config by working with grid rows. If we drop GSLB
 *     config or there is no GSLB item in system we create a new one using defaults. Won't
 *     save it until user switches it on explicitly through the actual modal. And despite the
 *     fact that GSLB is an Item of a Collection only one can be present in a system.
 *
 *     We have three lists for GslbSites here: active, passive, and 3rd party members.
 *     All come through one GSLBSiteCollection, which loads full list and then updates
 *     corresponding arrays on loadSuccess events.
 */

angular.module('aviApp').controller('GSLBController', [
'$scope', '$window', 'GSLBCollection', 'GSLB', 'aviAlertService', 'GSLBSiteCollection',
'loadedSystemInfoService', 'l10nService',
function($scope, $window, GSLBCollection, GSLB, aviAlertService, GSLBSiteCollection,
         systemInfo, l10nService) {
    //just to load existent one if any and use defaults for creation
    const collection = new GSLBCollection();
    const self = this;

    this.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    /**
     * @type {GSLBSiteCollection}
     * @private
     */
    let gslbSiteCollection;

    /**
     * @type {GSLBSiteCollection}
     * @private
     */
    let gslbNonAviSiteCollection;

    /**
     * @type {GslbSiteConfig[]}
     */
    this.activeMembers = [];

    /**
     * @type {GslbSiteConfig[]}
     */
    this.passiveMembers = [];

    /**
     * @type {GslbThirdPartySiteConfig[]}
     */
    this.nonAviMembers = [];

    /**
     * Empties members lists.
     * @private
     */
    this.emptyMembersList_ = function() {
        this.activeMembers.length = 0;
        this.passiveMembers.length = 0;
    };

    /**
     * Empties non Avi sites list.
     * @private
     */
    this.emptyNonAviMembersList_ = function() {
        this.nonAviMembers.length = 0;
    };

    /**
     * Empties all lists of sites/members.
     * @private
     */
    this.emptyAllMembersLists_ = function() {
        this.emptyMembersList_();
        this.emptyNonAviMembersList_();
    };

    /**
     * Fires initially and on GSLB config drop event - to get a GSLB instance we are working with.
     * @inner
     */
    const loadGSLB = () => {
        this.isBusy = true;
        this.gslb = null;
        this.emptyAllMembersLists_();

        [gslbSiteCollection, gslbNonAviSiteCollection]
            .forEach(collection => collection && collection.destroy());

        gslbSiteCollection = undefined;
        gslbNonAviSiteCollection = undefined;

        collection.load().then(() => {
            if (collection.getNumberOfItems()) { //have one
                [this.gslb] = collection.items;
            } else { //create new one, need to (re)load local controller settings
                return GSLB.getLocalSiteConfig()
                    .then(site => {
                        collection.setDefaultItemConfigProps({
                            sites: [site],
                            leader_cluster_uuid: site.cluster_uuid,
                        });
                        this.gslb = collection.createNewItem(undefined, true);
                    });
            }
        }, function(err) {
            aviAlertService.throw(err.data);
        }).finally(() => {
            onGSLBload();
            this.isBusy = false;
        });
    };

    /**
     * Need to put GSLB sites from collection into active and passive member arrays on
     * collection load event.
     * @this {Collection}
     * @inner
     */
    const onGSLBSiteCollectionLoad = function() {
        const { gslb, activeMembers, passiveMembers } = self;

        self.emptyMembersList_();

        if (!gslb.busy) { //pending save - list of results might be outdated
            this.items.forEach(gslbSite => {
                if (gslbSite.isActiveMember()) {
                    activeMembers.push(gslbSite);
                } else {
                    passiveMembers.push(gslbSite);
                }
            });
        }
    };

    /**
     * Need to put non Avi GSLB sites from collection into non Avi members list.
     * @this {Collection}
     * @inner
     */
    const onGSLBNonAviSiteCollectionLoad = function() {
        const { gslb, nonAviMembers } = self;

        self.emptyNonAviMembersList_();

        if (!gslb.busy) { //pending save - list of results might be outdated
            nonAviMembers.push(...this.items);
        }
    };

    /**
     * Event handler for GSLB load success event. Defines a GSLBSite Collection and it's event
     * handlers.
     */
    function onGSLBload() {
        const { gslb } = self;

        if (gslb) {
            if (gslb.id) { //existent
                gslb.one('itemDropSuccess', () => {
                    loadGSLB();
                    systemInfo.load();
                });//in case of drop creates a new one
                gslb.on('itemSaveSuccess', self.emptyAllMembersLists_.bind(self));
                gslbSiteCollection = new GSLBSiteCollection({ gslb });
                gslbSiteCollection.on('collectionLoadSuccess', onGSLBSiteCollectionLoad);
                gslbSiteCollection.load();

                gslbNonAviSiteCollection = new GSLBSiteCollection({
                    gslb,
                    nonAvi: true,
                });

                self.gslbNonAviSiteCollection = gslbNonAviSiteCollection;

                gslbNonAviSiteCollection.on('collectionLoadSuccess',
                    onGSLBNonAviSiteCollectionLoad);
                gslbNonAviSiteCollection.load();
            } else { //default template to be saved later
                gslb.one('itemCreate', () => {
                    onGSLBload();
                    systemInfo.load();
                });
            }
        }
    }

    const gridConfig = {
        fields: [
            {
                name: 'data.config.name',
                title: l10nService.getMessage(l10nKeys.columnTitleName),
                visibility: 'm',
            }, {
                name: 'type',
                title: l10nService.getMessage(l10nKeys.columnTitleType),
                visibility: 'm',
                transform: site => {
                    const { config } = site.data || {};
                    let res;

                    if (config.isLeader) {
                        res = l10nService.getMessage(l10nKeys.leaderLabel);
                    } else if (config['member_type'].indexOf('ACTIVE') !== -1) {
                        res = l10nService.getMessage(l10nKeys.activeLabel);
                    } else {
                        res = l10nService.getMessage(l10nKeys.passiveLabel);
                    }

                    if (!config.enabled) {
                        res = l10nService.getMessage(l10nKeys.disabledLabel, [res]);
                    }

                    if (config.isLocal) {
                        res = l10nService.getMessage(l10nKeys.currentLabel, [res]);
                    }

                    return res;
                },
            }, {
                name: 'ip_address',
                title: l10nService.getMessage(l10nKeys.columnTitleIpAdress),
                visibility: 'm',
                transform(site) {
                    const { config } = site.data;
                    const strings = [];

                    if (config['address']) {
                        strings.push(config['address']);
                    }

                    if (angular.isArray(config['ip_addresses'])) {
                        config['ip_addresses'].forEach(ipAddr => {
                            strings.push(ipAddr['addr']);
                        });
                    }

                    return strings.join(', ');
                },
            }, {
                name: 'data.config.port',
                title: l10nService.getMessage(l10nKeys.columnTitlePort),
            }, {
                name: 'data.config.username',
                title: l10nService.getMessage(l10nKeys.columnTitleUsername),
                visibility: 'd',
            }, {
                name: 'dns_vs_uuids',
                title: 'DNS VSes',
                visibility: 'd',
                transform(site) {
                    const runtime = site.getRuntimeData() || {};

                    if (Array.isArray(runtime.dnsVSs)) {
                        const names = runtime.dnsVSs.map(slug => slug.name());

                        return names.join(', ');
                    }

                    return '';
                },
            }, {
                name: 'status',
                title: l10nService.getMessage(l10nKeys.columnTitleSiteStatus),
                visibility: 'd',
                template: '<avi-healthscore item="row" type="gslbsite"/>',
            }, {
                name: 'sw_version',
                title: l10nService.getMessage(l10nKeys.columnTitleSwVersion),
                visibility: 'd',
                template: `<span title="{{::row.data.runtime.swVersion.fullString}}">
                        {{::row.data.runtime.swVersion.version}}</span>`,
            },
        ],
        multipleactions: [
            {
                title: l10nService.getMessage(l10nKeys.actionBtnDelete),
                disabled(gslbSites) {
                    return _.every(gslbSites, gslbSite => !gslbSite.isDroppable());
                },
                do(gslbSites) {
                    const { collection } = _.sample(gslbSites);
                    const toBeDropped = [];

                    gslbSites.forEach(gslbSite => {
                        if (gslbSite.isDroppable()) {
                            toBeDropped.push(gslbSite.id);
                        }
                    });

                    if (toBeDropped.length) {
                        collection.dropSites(toBeDropped);
                    }

                    return !!toBeDropped.length;
                },
            }, {
                title: l10nService.getMessage(l10nKeys.actionBtnEnable),
                disabled(gslbSites) {
                    return _.every(gslbSites, gslbSite =>
                        gslbSite.isEnabled() || !gslbSite.isEditable());
                },
                do(gslbSites) {
                    const { collection } = _.sample(gslbSites);
                    const toBeUpdated = [];

                    gslbSites.forEach(gslbSite => {
                        if (gslbSite.isEditable() && !gslbSite.isEnabled()) {
                            toBeUpdated.push(gslbSite.id);
                        }
                    });

                    if (toBeUpdated.length) {
                        collection.enableSites(toBeUpdated);
                    }

                    return !!toBeUpdated.length;
                },
            }, {
                title: l10nService.getMessage(l10nKeys.actionBtnDisable),
                disabled(gslbSites) {
                    return _.every(gslbSites, gslbSite =>
                        !gslbSite.isEnabled() || !gslbSite.isEditable());
                },
                do(gslbSites) {
                    const { collection } = _.sample(gslbSites);
                    const toBeUpdated = [];

                    gslbSites.forEach(gslbSite => {
                        if (gslbSite.isEditable() && gslbSite.isEnabled()) {
                            toBeUpdated.push(gslbSite.id);
                        }
                    });

                    if (toBeUpdated.length) {
                        collection.disableSites(toBeUpdated);
                    }

                    return !!toBeUpdated.length;
                },
            },
        ],
        checkboxDisable: site => !site.isEditable() || site.isLeader(),
        singleactions: [
            {
                title: l10nService.getMessage(l10nKeys.actionBtnEdit),
                class: 'icon-pencil',
                hidden(gslbSite) {
                    return !gslbSite.isEditable();
                },
                do(site) {
                    site.edit();
                },
            }, {
                title: l10nService.getMessage(l10nKeys.actionBtnDelete),
                class: 'icon-trash',
                hidden(gslbSite) {
                    return !gslbSite.isDroppable();
                },
                do(gslbSite) {
                    gslbSite.drop();
                },
            },
        ],
        rowId: 'id',
        rowClass(gslbSite) {
            return gslbSite.isLeader() && 'is-owner' || '';
        },
        layout: {
            hideSearch: true,
        },
    };

    this.activeMembersGridConfig = angular.copy(gridConfig);
    this.passiveMembersGridConfig = angular.copy(gridConfig);
    this.nonAviMembersGridConfig = angular.copy(gridConfig);

    this.activeMembersGridConfig.id = 'gslb-members-list-page--active';
    this.passiveMembersGridConfig.id = 'gslb-members-list-page--passive';
    this.nonAviMembersGridConfig.id = 'gslb-members-list-page--non-avi';

    const replicationColumns = [{
        name: 'replication',
        title: l10nService.getMessage(l10nKeys.columnTitleReplication),
        visibility: 'd',
        template: `<span title="{{ row.replicationSyncState }}">
                       {{ row.replicationSyncState }}
                   </span>`,
    }];

    const replicationAction = {
        class: 'sl-icon-control-end',
        do(site) {
            site.kickoffReplication()
                .then(() => loadGSLB())
                .catch(err => {
                    aviAlertService.throw(err);
                });
        },
        hidden(site) {
            return !site.isReplicationPullEnabled;
        },
        title: l10nService.getMessage(l10nKeys.actionBtnKickoff),
    };

    this.activeMembersGridConfig.singleactions.push(replicationAction);

    this.activeMembersGridConfig.fields.push(...replicationColumns);

    this.nonAviMembersGridConfig.fields = [{
        name: 'data.config.name',
        title: l10nService.getMessage(l10nKeys.columnTitleName),
    }, {
        name: 'data.config.enabled',
        title: l10nService.getMessage(l10nKeys.columnTitleEnabled),
        sortBy: 'enabled',
        template: '{{ row.isEnabled() | booleanLabel:\'enabled\' }}',
    }];

    this.dropGSLB = function() {
        if ($window.confirm(l10nService.getMessage(l10nKeys.confirmMessage))) {
            this.gslb.drop();
        }
    };

    loadGSLB();

    $scope.$on('$destroy', () => {
        //setTimeOut is required to release watches, event handlers on collection and gslb
        //before destroying collection and gslb
        setTimeout(() => {
            collection.destroy();
            this.gslb && this.gslb.destroy();//if it newly created and not a part of collection
            //gslbSiteCollection is destroyed by gslb destroy
        });
    });
}]);

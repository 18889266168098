/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { isEmpty } from 'underscore';
import {
    AZURE_AUTHENTICATION_TYPE,
    CREDENTIALS_TYPE,
    SSH_AUTHENTICATION_TYPE,
    UserCredentialsModalComponent,
} from 'ng/modules/cloud-connector-user';
import { IItemParams, withFullModalMixin } from 'ajs/js/utilities/mixins';
import { ObjectTypeItem } from 'ajs/modules/data-model';
import {
    AzureServicePrincipalCredentialsConfigItem,
    AzureUserPassCredentialsConfigItem,
    GCPCredentialsConfigItem,
    NsxtCredentialsConfigItem,
    VCenterCredentialsConfigItem,
} from '.';

interface ICloudConnectorUserConfig {
    name?: string;
    azure_serviceprincipal?: AzureServicePrincipalCredentialsConfigItem;
    gcp_credentials?: GCPCredentialsConfigItem;
    azure_userpass?: AzureUserPassCredentialsConfigItem;
    nsxt_credentials?: NsxtCredentialsConfigItem;
    vcenter_credentials?: VCenterCredentialsConfigItem;
    public_key?: string;
    private_key?: string;
    passphrase?: string;
    password?: string;
}

interface ICloudConnectorUserParams extends IItemParams {
    credentialsType?: CREDENTIALS_TYPE;
    overrideCredentialsType?: CREDENTIALS_TYPE;
}

/**
 * Hash of field names containing config items tied to credentials/authentication types.
 */
const configItemFieldsHash = {
    [CREDENTIALS_TYPE.GCP]: 'gcp_credentials',
    [CREDENTIALS_TYPE.NSXT]: 'nsxt_credentials',
    [CREDENTIALS_TYPE.VCENTER]: 'vcenter_credentials',
    [AZURE_AUTHENTICATION_TYPE.SERVICE_PRINCIPAL]: 'azure_serviceprincipal',
    [AZURE_AUTHENTICATION_TYPE.USER_PASS]: 'azure_userpass',
};

interface ICloudConnectorUserConfigData {
    config: ICloudConnectorUserConfig;
}

export class CloudConnectorUser extends withFullModalMixin(ObjectTypeItem) {
    public data: ICloudConnectorUserConfigData;
    public getConfig: () => ICloudConnectorUserConfig;

    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'cloudconnectoruser',
            objectType: 'CloudConnectorUser',
            permissionName: 'PERMISSION_USER_CREDENTIAL',
            windowElement: UserCredentialsModalComponent,
        };

        super(extendedArgs);
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public getModalParams_(params: ICloudConnectorUserParams): ICloudConnectorUserParams {
        const { credentialsType: overrideCredentialsType, ...restParams } = params;

        return {
            ...restParams,
            overrideCredentialsType,
        };
    }

    /**
     * Sets the child message items according to type and resets fields.
     */
    public setType(type: CREDENTIALS_TYPE): void {
        // Clears SSH-related fields.
        this.clearSshFields();

        // Clears all other credentials fields.
        Object
            .values(configItemFieldsHash)
            .forEach((field: string) => this.clearChildConfigItem(field));

        if (type !== CREDENTIALS_TYPE.SSH) {
            this.setChildConfigItems(type);
        }
    }

    /**
     * Returns the currently configured credentials type.
     */
    public getType(): CREDENTIALS_TYPE {
        const { AZURE, GCP, NSXT, SSH, VCENTER } = CREDENTIALS_TYPE;
        const types = [AZURE, GCP, NSXT, VCENTER];

        return types.find(type => this.hasCredentials(type)) || SSH;
    }

    /**
     * Returns true if a specific credentials type and authentication type are used.
     */
    public hasCredentials(
        credentialsType: CREDENTIALS_TYPE,
        authenticationType?: SSH_AUTHENTICATION_TYPE | AZURE_AUTHENTICATION_TYPE,
    ): boolean {
        const { AZURE, GCP, NSXT, SSH, VCENTER } = CREDENTIALS_TYPE;

        switch (credentialsType) {
            case SSH:
                return this.hasSshCredentials(authenticationType as SSH_AUTHENTICATION_TYPE);

            case AZURE:
                return this.hasAzureCredentials(authenticationType as AZURE_AUTHENTICATION_TYPE);

            case GCP:
            case NSXT:
            case VCENTER:
                return this.hasValidConfigItem(configItemFieldsHash[credentialsType]);

            default:
                return false;
        }
    }

    /**
     * Clears SSH fields.
     */
    public clearSshFields(): void {
        this.clearKeys();
        delete this.config.password;
    }

    /**
     * Clears SSH credentials.
     */
    public clearKeys(): void {
        const { config } = this;

        delete config.private_key;
        delete config.passphrase;
        delete config.public_key;
    }

    /**
     * Generates new SSH key value pair.
     */
    public generateKey(): ng.IPromise<void> {
        this.clearKeys();

        return this.submit();
    }

    /**
     * Imports SSH private key.
     */
    public importKey(): ng.IPromise<void> {
        delete this.config.public_key;

        return this.submit();
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string { // eslint-disable-line class-methods-use-this
        return 'User Credentials';
    }

    /**
     * Returns true if SSH credentials have been configured.
     */
    private hasSshCredentials(authenticationType?: SSH_AUTHENTICATION_TYPE): boolean {
        switch (authenticationType) {
            case SSH_AUTHENTICATION_TYPE.USER_PASS:
                return this.hasSshUserpass();

            case SSH_AUTHENTICATION_TYPE.SSH_KEY:
                return this.hasSshKeys();

            default:
                return this.hasSshKeys() || this.hasSshUserpass();
        }
    }

    /**
     * Returns true if SSH keys exist.
     */
    private hasSshKeys(): boolean {
        const { config } = this;

        return 'private_key' in config || 'public_key' in config;
    }

    /**
     * Returns true if the SSH password is configured.
     */
    private hasSshUserpass(): boolean {
        return 'password' in this.config;
    }

    /**
     * Returns true if Azure credentials have been configured.
     */
    private hasAzureCredentials(authenticationType?: AZURE_AUTHENTICATION_TYPE): boolean {
        const { USER_PASS, SERVICE_PRINCIPAL } = AZURE_AUTHENTICATION_TYPE;
        const hasUserPass = this.hasValidConfigItem(configItemFieldsHash[USER_PASS]);
        const servicePrincipalField = configItemFieldsHash[SERVICE_PRINCIPAL];
        const hasServicePrincipal = this.hasValidConfigItem(servicePrincipalField);

        switch (authenticationType) {
            case USER_PASS:
                return hasUserPass;

            case SERVICE_PRINCIPAL:
                return hasServicePrincipal;

            default:
                return hasUserPass || hasServicePrincipal;
        }
    }

    /**
     * Returns true if the child config item exists and is valid.
     */
    private hasValidConfigItem(field: string): boolean {
        const configItem = this.config[field];

        return !isEmpty(configItem) && configItem.isValid();
    }

    /**
     * Clears the fields of a child config item.
     */
    private clearChildConfigItem(field: string): void {
        const configItem = this.config[field];

        if (configItem) {
            configItem.clearFields();
        }
    }

    /**
     * Creates new child config items if they don't already exist.
     */
    private setChildConfigItems(type: CREDENTIALS_TYPE): void {
        const { USER_PASS, SERVICE_PRINCIPAL } = AZURE_AUTHENTICATION_TYPE;

        switch (type) {
            case CREDENTIALS_TYPE.AZURE:
                this.safeSetNewChildByField(configItemFieldsHash[USER_PASS]);
                this.safeSetNewChildByField(configItemFieldsHash[SERVICE_PRINCIPAL]);
                break;

            default:
                this.safeSetNewChildByField(configItemFieldsHash[type]);
        }
    }
}

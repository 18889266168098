/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name poolGroupDeploymentPolicyPage
 * @description Component for Pool Group Deployment Policy page.
 */
class PoolGroupDeploymentPolicyPageController {
    constructor(CRUDGridConfig, PoolGroupDeploymentPolicyCollection) {
        this.collection = new PoolGroupDeploymentPolicyCollection();

        const { objectName } = this.collection;

        const config = {
            id: `${objectName}-list-page`,
            collection: this.collection,
            fields: [{
                name: 'data.config.name',
                title: 'Name',
            }, {
                name: 'data.config.target_test_traffic_ratio',
                title: 'Target Traffic Ratio',
            }, {
                name: 'data.config.evaluation_duration',
                title: 'Duration',
            }, {
                name: 'data.config.test_traffic_ratio_rampup',
                title: 'Ramup Ratio',
            }, {
                name: 'data.config.rules',
                title: 'Rules',
                template: '{{ row.getRules().length || 0 }}',
            }],
            layout: {
                hideEditColumns: true,
            },
        };

        this.gridConfig = CRUDGridConfig(config);
    }

    $onDestroy() {
        this.collection.destroy();
    }
}

PoolGroupDeploymentPolicyPageController.$inject = [
        'CRUDGridConfig',
        'PoolGroupDeploymentPolicyCollection',
];

angular.module('aviApp').component('poolGroupDeploymentPolicyPage', {
    controller: PoolGroupDeploymentPolicyPageController,
    template:
            `<div class="panel templatesList">
                <collection-grid config="$ctrl.gridConfig"></collection-grid>
            </div>`,
});

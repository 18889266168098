/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './ipam-dns-profiles-modal.less';

import {
    IPAMDNS_TYPE_INTERNAL,
    IPAMDNS_TYPE_AWS,
    IPAMDNS_TYPE_AWS_DNS,
    IPAMDNS_TYPE_CUSTOM_DNS,
    IPAMDNS_TYPE_AZURE_DNS,
} from 'ajs/js/constants/ipam-dns.constants';

import angular from 'angular';
import * as l10n from './ipam-dns-profiles-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class IpamDnsProfilesModalController {
    constructor(
        Auth,
        Regex,
        SubnetListNetworkCollection,
        CustomIpamDnsCollection,
        CloudCollection,
        schemaService,
        initialDataService,
        l10nService,
    ) {
        this.Auth = Auth;
        this.Regex = Regex;
        this.schemaService_ = schemaService;
        this.initialDataService_ = initialDataService;

        this.cloudCollection = new CloudCollection();
        this.customIpamDnsCollection = new CustomIpamDnsCollection();
        this.subnetListNetworkCollection = new SubnetListNetworkCollection();
        this.cloudRef = '';
        /**
        * Get keys from source bundles for template usage
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onInit() {
        this.step = 0;

        /**
         * Indicate whether credentials of a certain profile are being edited or not. Can be set to
         * true only when the profile supports credential verification.
         * @type {boolean}
         */
        this.editingCredentials = false;

        // IPAMDNS_TYPE_AWS, IPAMDNS_TYPE_AWS_DNS properties.
        this.useProxy = false;
        this.useIamAssumeRole = false;
        this.iamAssumeRoles = [];
        this.awsAvailabilityZones = [];
        this.awsAvailabilityZonesHash = {};
        this.azureCredentialsType = 'userpass';

        const valuesList = this.schemaService_.getEnumValues('IpamDnsType');
        const profileType = this.getProfileType();

        /**
         * Filters the available options in the Type dropdown based on the profile type.
         * For IPAM type, shows only IPAM profile options and for DNS type, the list would contain
         * only the DNS profile type options.
         */
        if (profileType.includes('_DNS')) {
            this.types = valuesList.filter(({ value }) => value.includes('_DNS'));
        } else {
            this.types = valuesList.filter(({ value }) => !value.includes('_DNS'));
        }

        if (this.editable.id) {
            const config = this.editable.getConfig();

            switch (profileType) {
                case IPAMDNS_TYPE_AWS:
                case IPAMDNS_TYPE_AWS_DNS: {
                    const { aws_profile: awsProfile } = config;

                    if (!angular.isUndefined(awsProfile.getConfig().iam_assume_role)) {
                        this.useIamAssumeRole = true;
                    }

                    awsProfile.getRegions()
                        .then(regions => this.awsRegions = regions);

                    break;
                }

                case IPAMDNS_TYPE_AZURE_DNS:
                    if (_.isEmpty(config.azure_profile.azure_userpass)) {
                        this.azureCredentialsType = 'serviceprincipal';
                    }

                    break;

                case IPAMDNS_TYPE_CUSTOM_DNS:
                    this.editable.getDomainList()
                        .then(domains => {
                            if (Array.isArray(domains)) {
                                this.domainList = domains.map(domain => {
                                    return {
                                        value: domain,
                                    };
                                });
                            }
                        });
                    break;
            }

            if (!_.isEmpty(config.proxy_configuration)) {
                this.useProxy = true;
            }
        } else {
            switch (profileType) {
                case IPAMDNS_TYPE_AZURE_DNS:
                    this.editingCredentials = true;
                    break;
            }
        }
    }

    /** @override */
    $onDestroy() {
        this.cloudCollection.destroy();
        this.subnetListNetworkCollection.destroy();
        this.customIpamDnsCollection.destroy();
    }

    /**
     * Sets Cloud UUID for Network list collection.
     * @param {Cloud} cloud - Cloud instance.
     */
    setCloud(cloud) {
        this.subnetListNetworkCollection.setParams({
            cloud_uuid: cloud.id,
        });
    }

    /**
     * Determine is the controller built on AWS or not.
     * @return {boolean}
     */
    isAwsController() {
        return this.initialDataService_.isAwsCloud;
    }

    /**
     * Called when Next is clicked, applies for AWS and Infoblox types where there are multiple
     * pages.
     * @param  {number} step - Step number
     */
    goToStep(step) {
        const {
            type,
            aws_profile: awsProfile,
        } = this.editable.getConfig();

        switch (type) {
            case IPAMDNS_TYPE_AWS:
            case IPAMDNS_TYPE_AWS_DNS:
                if (step === 1) {
                    if (awsProfile && awsProfile.getConfig().vpc_id) {
                        if (type === IPAMDNS_TYPE_AWS_DNS) {
                            this.editable.getAwsLists('domains')
                                .then(([vpcs, domains]) => {
                                    this.awsVpcList = vpcs;
                                    this.awsDomains = domains.map(domain => {
                                        return {
                                            value: domain,
                                        };
                                    });
                                    this.step = step;
                                });
                        } else {
                            this.editable.getAwsLists('zones')
                                .then(([vpcs, zones]) => {
                                    this.awsVpcList = vpcs;
                                    this.setAvailabilityZones(zones);
                                    this.step = step;
                                });
                        }
                    } else {
                        /**
                         * Only get VPCs list if user is creating a new AWS profile and doesn't
                         * have a vpc_id already set, since the lists of networks and domains
                         * change when the VPC selected changes.
                         */
                        this.editable.getAwsLists()
                            .then(([vpcs]) => {
                                this.awsVpcList = vpcs;
                                this.step = step;
                            });
                    }
                } else {
                    this.step = step;
                }

                break;

            default:
                this.step = step;
        }
    }

    /**
     * Called when user changes type of IPAM Profile.
     */
    onTypeChange() {
        this.editingCredentials = false;
        this.goToStep(0);

        const { editable } = this;
        const config = editable.getConfig();
        const { type } = config;

        editable.onTypeChange();

        switch (type) {
            case IPAMDNS_TYPE_AWS:
            case IPAMDNS_TYPE_AWS_DNS: {
                const { aws_profile: awsProfile } = config;

                if (angular.isUndefined(this.awsRegions)) {
                    awsProfile.getRegions()
                        .then(regions => this.awsRegions = regions);
                }

                break;
            }

            case IPAMDNS_TYPE_AZURE_DNS:
                this.editingCredentials = true;
                break;
        }

        if (type !== IPAMDNS_TYPE_INTERNAL) {
            config.allocate_ip_in_vrf = false;
        }
    }

    /**
     * Get ipam profile type.
     * @return {string}
     */
    getProfileType() {
        return this.editable.type;
    }

    /**
     * Indicates if "Next" button should be displayed for some config types.
     * @returns {boolean}
     */
    hasNext() {
        const { type } = this.editable.getConfig();

        return type.indexOf(IPAMDNS_TYPE_AWS) > -1;
    }

    /**
     * Called on change when user selects between IAM Roles and Access Keys radio buttons.
     * Resets AWS properties.
     */
    onIAMChange() {
        this.editable.getAwsProfile().clearAuthenticationFields();
    }

    /**
     * Called on change when user selects between credentials used for Azure configuration.
     */
    handleAzureCredentialsTypeChange() {
        this.editable.clearAzureCredentials();
    }

    /**
     * Handler for VPC change. Clears existing usable networks and usable domains. Gets the list
     * of domains or networks, depending on the AWS profile type.
     */
    handleVpcChange() {
        const { aws_profile: awsProfile } = this.editable.getConfig();

        awsProfile.clearUsableNetworks();
        awsProfile.clearUsableDomains();

        const config = this.editable.getConfig();

        if (config.type === IPAMDNS_TYPE_AWS_DNS) {
            this.editable.getAwsDomains()
                .then(domains => this.awsDomains = domains);
        } else if (config.type === IPAMDNS_TYPE_AWS) {
            this.editable.getAwsAvailabilityZones()
                .then(zones => this.setAvailabilityZones(zones));
        }
    }

    /**
     * Handler function for ng-change event on "Use Cross-Account AssumeRole" checkbox. Makes
     * request to get a list of IAM Assume Roles. If an error occurs, unchecks the checkbox.
     */
    handleUseIamAssumeRoleChange() {
        const { aws_profile: awsProfile } = this.editable.getConfig();

        if (this.useIamAssumeRole) {
            this.editable.getAwsIamAssumeRoles()
                .then(iamAssumeRoles => this.iamAssumeRoles = iamAssumeRoles)
                .catch(() => {
                    this.iamAssumeRoles = [];
                    this.useIamAssumeRole = false;
                    awsProfile.removeIamAssumeRole();
                });
        } else {
            awsProfile.removeIamAssumeRole();
        }
    }

    /**
     * Sets this.awsAvailabilityZones and this.awsAvailabilityZonesHash to be used to select
     * usable networks.
     * @param {Object[]} zones - List of availability zones.
     */
    setAvailabilityZones(zones = []) {
        this.awsAvailabilityZones = zones;
        this.awsAvailabilityZonesHash = {};
        zones.forEach(({ availability_zone, subnets }) => {
            this.awsAvailabilityZonesHash[availability_zone] = subnets;
        });
    }

    /**
     * Decide behaviors when profile credential editing starts.
     */
    onProfileCredentialEditStart() {
        this.editable.clearProfilePassword();
    }

    /**
     * Verify azure profile credentials.
     * @return {ng.$q.promise}
     */
    verifyAzureProfileCredentials() {
        return this.editable.verifyAzureCredentials();
    }
}

IpamDnsProfilesModalController.$inject = [
    'Auth',
    'Regex',
    'SubnetListNetworkCollection',
    'CustomIpamDnsCollection',
    'CloudCollection',
    'schemaService',
    'initialDataService',
    'l10nService',
];

/**
 * @ngdoc component
 * @name  ipamDnsProfilesModal
 * @description  Component for IPAM DNS Profile modal.
 *      It filters and shows the profile type list based on the pre-selected type.
 * @property {module:avi/profiles/ipam.IPAMProfile} editable - IPAMProfile object.
 * @author alextg, Chitra
 */
angular.module('avi/ipam').component('ipamDnsProfilesModal', {
    bindings: {
        editable: '<',
    },
    controller: IpamDnsProfilesModalController,
    templateUrl: 'src/components/templates/profiles/ipam-dns-profiles-modal/' +
        'ipam-dns-profiles-modal.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc service
 * @name VRFContext
 */
angular.module('aviApp').factory('VRFContext', [
'Item', 'Auth', function(Item, Auth) {
    class VRFContext extends Item {
        /** @override */
        dataToSave() {
            const
                config = angular.copy(this.getConfig()),
                { bgp_profile: bgpProfile } = config;

            if (bgpProfile && angular.isUndefined(bgpProfile['local_as'])) {
                delete config['bgp_profile'];
            }

            return config;
        }

        /**
         * @return {Boolean} Returns true if VRF Context is editable, false otherwise (for
         * global and management VRFs).
         * @override
         */
        isEditable() {
            return !this.isProtected() && Auth.hasTenantVrf && super.isEditable();
        }
    }

    angular.extend(VRFContext.prototype, {
        objectName: 'vrfcontext',
        windowElement: 'vrf-context-modal',
        restrictEditOnEssentialLicense_: false,
    });

    return VRFContext;
}]);

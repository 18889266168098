/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'log-input-pills';
const prefix = `${moduleName}.${componentName}`;

export const searchInputPlaceholder = `${prefix}.searchInputPlaceholder`;

export const ENGLISH = {
    [searchInputPlaceholder]: 'Search',
};

/**
 * @module HeaderModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    Inject,
    OnDestroy,
} from '@angular/core';

import {
    Observable,
    Subject,
} from 'rxjs';

import { ConnectedPosition } from '@angular/cdk/overlay';
import { StateService } from '@uirouter/core';
import { StateGroupPipe } from '../../pipes';
import './main-menu.component.less';

/**
 * Main-menu component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'main-menu',
    templateUrl: './main-menu.component.html',
    providers: [
        StateGroupPipe,
    ],
})
export class MainMenuComponent implements OnDestroy {
    /**
     * Main-menu position strategy.
     */
    public menuPositions: ConnectedPosition[] = [
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
        },
    ];

    /**
     * Returns an observable to allow subscriptions to show and hide menu.
     */
    public get menuControl$(): Observable<boolean> {
        return this.menuControlSubject.asObservable();
    }

    /**
     * Used to control main-menu toggling.
     */
    public isOpen = false;

    /**
     * Subject to control main-menu.
     */
    private menuControlSubject = new Subject<boolean>();

    public constructor(
        @Inject('$state') public $state: StateService,
        private stateGroupPipe: StateGroupPipe,
    ) {}

    /**
     * Returns main-menu icon.
     */
    public getMenuIcon(): string {
        return !this.isOpen ? 'icon-menu' : 'icon-cancel-2';
    }

    /**
     * Returns current state name.
     */
    public getCurrentStateName(): string {
        const { $current: currentState } = this.$state;

        return this.stateGroupPipe.transform(currentState);
    }

    /**
     * Fires on menu open/close.
     * @param isOpened - true if menu is open.
     */
    public handleOpenedChange(isOpened: boolean): void {
        this.isOpen = isOpened;
    }

    /**
     * Fires on menu click.
     */
    public onMenuSelect(): void {
        this.hideMenu();
    }

    /** @override */
    public ngOnDestroy(): void {
        this.hideMenu();
    }

    /**
     * Hides main-menu-list.
     */
    private hideMenu(): void {
        this.menuControlSubject.next(false);
    }
}

const moduleName = 'ajs-legacy';
const componentName = 'generate-sso-token-message';
const prefix = `${moduleName}.${componentName}`;

export const generateTokenMessage = `${prefix}.generateTokenMessage`;
export const generateTokenInputPlaceholder = `${prefix}.generateTokenInputPlaceholder`;
export const generateBtnLabel = `${prefix}.generateBtnLabel`;
export const tokenLabel = `${prefix}.tokenLabel`;
export const dismissLinkLabel = `${prefix}.dismissLinkLabel`;

export const ENGLISH = {
    [generateTokenMessage]: 'This is a token generated for user <strong>{0}</strong> and can be used for token authentication during login. This token will self-destruct in x hours.',
    [generateTokenInputPlaceholder]: 'Lifetime (in hours)',
    [generateBtnLabel]: 'Generate',
    [tokenLabel]: 'Token',
    [dismissLinkLabel]: 'Dismiss',
};

/** @module SecurityModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { SchemaService } from 'ajs/modules/core';
import { IOCSPResponseInfo } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import template from './certificate-expander.component.html';
import * as l10n from './certificate-expander.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class CertificateExpanderController {
    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly schemaService: SchemaService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    public getRevocationReasonLabel(
        revocationReason: IOCSPResponseInfo['revocation_reason'],
    ): string {
        if (revocationReason) {
            return this.schemaService.getEnumValue(
                'CertificateRevocationReason',
                revocationReason,
            ).label;
        }

        return '';
    }
}

CertificateExpanderController.$inject = [
    'schemaService',
    'l10nService',
];

export const certificateExpanderOptions = {
    bindings: {
        certificate: '<',
    },
    controller: CertificateExpanderController,
    template,
};

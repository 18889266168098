/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './authentication-rule.component.less';

const componentName = 'authentication-rule';

/**
 * @constructor
 * @memberOf module:avi/policies/sso
 * @mixes module:avi/policies/sso.authenticationRuleComponentBindings
 * @see {@link module:avi/policies/sso.authenticationRuleComponent}
 */
class AuthenticationRuleController {
    constructor() {
        /**
         * List of Expander actions.
         * @type {Object[]}
         */
        this.expanderActions = [];
    }

    /** @override */
    $onInit() {
        const ruleName = this.rule.getName();

        this.expanderActions = [
            {
                confirmationButtonProps: {
                    actions: [
                        {
                            label: 'Delete',
                            onClick: () => this.handleDelete(),
                        },
                    ],
                    confirmationButtonClassName: 'avi-btn avi-btn-danger',
                },
                confirmationMessage:
                    `Are you sure you want to delete ${ruleName}?`,
                iconClassName: 'sl-icon-trash',
                title: 'Delete',
            }, {
                iconClassName: 'sl-icon-note',
                onClick: () => this.handleEdit(),
                title: 'Edit',
            },
        ];
    }

    /**
     * Returns the state of the enable switch, 1 for enabled, 0 for disabled.
     * @returns {number}
     */
    getEnableState() {
        return this.rule.config.enable ? 1 : 0;
    }

    /**
     * Click handler for the enable switch. Flips the current value.
     */
    handeEnableClick() {
        this.rule.config.enable = !this.rule.config.enable;
    }

    /**
     * Click handler for editing an authentication rule.
     */
    handleEdit() {
        this.onEdit();
    }

    /**
     * Click handler for deleting an authentication rule.
     */
    handleDelete() {
        this.onDelete();
    }
}

/**
 * @name authenticationRuleComponent
 * @memberOf module:avi/policies/sso
 * @property {module:avi/policies/sso.AuthenticationRuleController} controller
 * @property {module:avi/policies/sso.authenticationRuleComponentBindings} bindings
 * @description
 *      Authentication Rule Component.
 * @author Aravindh Nagarajan
 */
angular.module('avi/policies/sso').component('authenticationRule', {
    /**
     * @mixin authenticationRuleComponentBindings
     * @memberOf module:avi/policies/sso
     * @property {module:avi/policies/sso.AuthenticationRuleConfigItem} rule
     * @property {Function} onEdit - method fires on editing a rule.
     * @property {Function} onDelete - method fires on deleting a rule.
     */
    bindings: {
        rule: '<',
        onEdit: '&',
        onDelete: '&',
    },
    controller: AuthenticationRuleController,
    templateUrl: 'src/components/modals/sso-policy-modal/authentication-policy/' +
        `authentication-rules-list/${componentName}/${componentName}.component.html`,
});

/**
 * @module CloudModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Type,
} from '@angular/core';
import { NsxtTransportZoneType } from 'generated-types';
import { Observable } from 'rxjs';
import { NsxtConfigurationConfigItem, VCenterServer } from 'ajs/modules/cloud';
import { CloudConnectorUserCollection } from 'ajs/modules/cloud-connector-user';
import { IAviDropdownOption } from 'ng/shared/components';
import { createDropdownOption } from 'ng/shared/utils';
import { ITEM_ID_TOKEN } from 'ng/shared/shared.constants';
import { L10nService } from '@vmw/ngx-vip';
import { IEditCredentialsConfig } from 'ng/modules/avi-forms';
import { NsxtEditCredentialsDialogComponent } from './nsxt-edit-credentials-dialog';

import * as l10n from './nsxt-configuration.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCloudConnectorUserCollection = typeof CloudConnectorUserCollection;

/**
 * @description Component for NsxtConfiguration configuration.
 * @author alextsg, Zhiqian Liu
 */
@Component({
    selector: 'nsxt-configuration',
    templateUrl: './nsxt-configuration.component.html',
})
export class NsxtConfigurationComponent implements OnInit, OnDestroy {
    /**
     * Cloud URL.
     */
    @Input()
    public cloudRef?: string;

    /**
     * NsxtConfiguration Config Item.
     */
    @Input()
    public editable: NsxtConfigurationConfigItem;

    /**
     * Called to add a VCenterServer.
     */
    @Output()
    public onAddVCenterServer: EventEmitter<VCenterServer> = new EventEmitter();

    /**
     * Called to delete a VCenterServer.
     */
    @Output()
    public onDeleteVCenterServer: EventEmitter<VCenterServer> = new EventEmitter();

    /**
     * CloudConnectorUserCollection instance.
     */
    public cloudConnectorUserCollection: CloudConnectorUserCollection;

    /**
     * Dropdown options for configurable transport zones.
     */
    public transportZoneDropdownOptions: IAviDropdownOption[] = [];

    /**
     * Map from transport zone IDs to the respective transport zone type.
     */
    public tzIDToTypeMap = new Map<string, NsxtTransportZoneType>();

    /**
     * Tier1 Logical Router dropdown options.
     */
    public tier1DropdownOptions: IAviDropdownOption[] = [];

    /**
     * Segment dropdown options.
     */
    public segmentDropdownOptions: IAviDropdownOption[] = [];

    /**
     * List of vCenter addresses.
     */
    public vcenterAddresses: string[] = [];

    /**
     * True if NSX-T Manager address and credentials have been set by the user. Used to display
     * helper messages for the user to first connect credentials.
     */
    public connected = false;

    /**
     * True if the Cloud Item is being edited, not created.
     */
    public readonly isEditing: boolean;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Credentials config passed to the CredentialsVerification component.
     */
    public editNsxtCredentialsConfig: IEditCredentialsConfig;

    constructor(
    // eslint-disable-next-line @typescript-eslint/indent
        @Inject('CloudConnectorUserCollection')
        CloudConnectorUserCollection: TCloudConnectorUserCollection,
        l10nService: L10nService,
        @Inject(ITEM_ID_TOKEN)
        itemId: string,
    ) {
        this.cloudConnectorUserCollection = new CloudConnectorUserCollection({
            params: {
                fields: 'name,tenant_ref',
                search: 'nsxt_credentials',
            },
            createParams: {
                credentialsType: 'NSXT',
            },
        });

        this.isEditing = Boolean(itemId);

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.isEditing) {
            this.connected = true;
            this.setTransportZoneOptions();
            this.setTier1Options();
            this.setVcenterAddresses();
        }

        this.setEditNsxtCredentialsConfig();
    }

    /** @override */
    public ngOnDestroy(): void {
        this.cloudConnectorUserCollection.destroy();
    }

    /**
     * Handler passed into a child component to reset VCenter addresses when the transport zone
     * changes under data_network_config.
     */
    public handleDataNetworkTZChange(): void {
        this.setVcenterAddresses();
    }

    /**
     * Handler for adding a VCenterServer. Passed to the VcenterServersListComponent.
     */
    public handleAddVCenterServer(vCenterServer: VCenterServer): void {
        this.onAddVCenterServer.emit(vCenterServer);
    }

    /**
     * Handler for deleting a VCenterServer. Passed to the VcenterServersListComponent.
     */
    public handleDeleteVCenterServer(vCenterServer: VCenterServer): void {
        this.onDeleteVCenterServer.emit(vCenterServer);
    }

    /**
     * Creates the config object passed to the CredentialsVerification component for editing
     * credentials.
     */
    private setEditNsxtCredentialsConfig(): void {
        const config = this.editable.getConfig();
        const editCredentialsConfig = {
            nsxt_credentials_ref: config.nsxt_credentials_ref,
            nsxt_url: config.nsxt_url,
        };

        this.editNsxtCredentialsConfig = {
            editCredentialsDialog: NsxtEditCredentialsDialogComponent as Type<Component>,
            editCredentialsDialogProps: {
                config: editCredentialsConfig,
                isEditing: this.isEditing,
                submit$: new Observable<void>(subscriber => {
                    this.editable.verifyNsxtLogin(editCredentialsConfig)
                        .then(() => {
                            subscriber.next();
                            subscriber.complete();

                            this.connected = true;
                            this.resetTransportZoneOptions();
                            this.resetTier1DropdownOptions();
                            this.editable.setNsxtLoginCredentials(editCredentialsConfig);
                            this.setEditNsxtCredentialsConfig();
                            // tier1 options is only determined by credentials
                            this.setTier1Options();

                            if (!this.isEditing) {
                                this.editable.clearTransportZoneData();
                            }

                            return this.setTransportZoneOptions();
                        })
                        .catch(error => subscriber.error(error));
                }),
                onCancel: () => {
                    this.editable.cancelVerifyNsxtLogin();
                    this.setEditNsxtCredentialsConfig();
                },
            },
        };
    }

    /**
     * Called to remove all transport zone options.
     */
    private resetTransportZoneOptions(): void {
        this.transportZoneDropdownOptions = [];
        this.tzIDToTypeMap.clear();
    }

    /**
     * Called to remove all Tier1 dropdown options.
     */
    private resetTier1DropdownOptions(): void {
        this.tier1DropdownOptions = [];
    }

    /**
     * Fetch a list of transport zones and sets dropdown options.
     */
    private async setTransportZoneOptions(): Promise<void> {
        try {
            const transportZones = await this.editable.fetchTransportZones() || [];

            this.transportZoneDropdownOptions = transportZones.map(({ id, name, tz_type }) => {
                let nameWithType: string;

                if (tz_type === NsxtTransportZoneType.OVERLAY) {
                    nameWithType = `${name} (Overlay)`;
                } else if (tz_type === NsxtTransportZoneType.VLAN) {
                    nameWithType = `${name} (VLAN)`;
                }

                return createDropdownOption(id, nameWithType);
            });

            transportZones.forEach(({ id, tz_type: type }) => this.tzIDToTypeMap.set(id, type));
        } catch (errors) {
            return Promise.reject(errors);
        }
    }

    /**
     * Fetches a list of Tier1 Logical Routers and sets dropdown options for child components.
     */
    private async setTier1Options(): Promise<void> {
        const tier1s = await this.editable.fetchTier1s() || [];

        this.tier1DropdownOptions = tier1s.map(({ id, name }) => createDropdownOption(id, name));
    }

    /**
     * Fetches a list of vCenter addresses.
     */
    private async setVcenterAddresses(): Promise<void> {
        const addresses = await this.editable.fetchVCenters() || [];

        this.vcenterAddresses = addresses;
    }
}

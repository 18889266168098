/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'waf';
const componentName = 'waf-exclude-list';
const prefix = `${moduleName}.${componentName}`;

export const exceptionsHeader = `${prefix}.exceptionsHeader`;

export const ENGLISH = {
    [exceptionsHeader]: 'Exceptions ({0})',
};

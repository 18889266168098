/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @constructor
 * @memberOf module:avi/policies/nat
 * @mixes module:avi/policies/nat.portMatchComponentBindings
 * @desc Controller for PortMatch Component.
 * @see {@link module:avi/policies/nat.portMatchComponent portMatchComponent}
 */
class PortMatchComponentController {
    constructor(Regex) {
        /**
         * Regular expression for Port validation.
         * @type {RegExp}
         */
        this.portRegex = Regex.port;
    }
}

PortMatchComponentController.$inject = [
    'Regex',
];

/**
 * @name portMatchComponent
 * @memberOf module:avi/policies/nat
 * @property {module:avi/policies/nat.portMatchComponentBindings} bindings
 * @property {module:avi/policies/nat.PortMatchComponentController} controller
 * @desc Component for PortMatch MessageItem selection.
 * @author Aravindh Nagarajan
 */
angular.module('avi/policies/nat').component('portMatch', {
    /**
     * @mixin portMatchComponentBindings
     * @memberOf module:avi/policies/nat
     * @property {module:avi/policies/nat.PortMatchConfigItem} portMatch
     * @property {boolean} preventEdit
     */
    bindings: {
        portMatch: '<',
        preventEdit: '<',
    },
    controller: PortMatchComponentController,
    templateUrl: 'src/components/forms/match-config/matches/' +
        'port-match/port-match.component.html',
});

/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { Component, Input } from '@angular/core';
import { SchemaService } from 'ajs/modules/core/services';
import { L10nService } from '@vmw/ngx-vip';
import './helper-text-tooltip-icon.less';
import * as l10n from './helper-text-tooltip-icon.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name HelperTextTooltipIconComponent
 * @description Component for an info icon that shows helpful text on hover.
 * @author alextsg, Zhiqian Liu
 */
@Component({
    selector: 'helper-text-tooltip-icon',
    templateUrl: './helper-text-tooltip-icon.component.html',
})
export class HelperTextTooltipIconComponent {
    /**
     * Custom text unrelated to Schema to be shown.
     */
    @Input()
    public tooltipText = '';

    /**
     * The Schema object type that the field belongs to.
     */
    @Input()
    public objectType = '';

    /**
     * The Schema field that contains the description text.
     */
    @Input()
    public fieldName = '';

    /**
     * The Schema enum type that the enum value belongs to.
     */
    @Input()
    public enumType = '';

    /**
     * The Schema enum value that maps to contains the description text.
     */
    @Input()
    public enumValue = '';

    /**
     * If true, hides the range text.
     */
    @Input()
    public hideRange = false;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private schemaService: SchemaService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns the description text for the field of an object, applicable when objectType and
     * fieldName are passed in.
     */
    public get fieldDescription(): string {
        if (this.isObjectField()) {
            return this.schemaService.getFieldDescription(this.objectType, this.fieldName);
        }

        if (this.isEnum()) {
            const enumValueObj = this.schemaService.getEnumValue(this.enumType, this.enumValue);

            return enumValueObj.description;
        }

        return '';
    }

    /**
     * Returns text for the range of valid values, applicable when objectType and fieldName are
     * passed in.
     */
    public get fieldRange(): string {
        if (this.isObjectField()) {
            return this.hideRange ?
                '' :
                this.schemaService.getFieldRangeAsText(this.objectType, this.fieldName);
        }

        return '';
    }

    /**
     * Returns text for special values.
     */
    public get fieldSpecialValues(): string {
        if (this.isObjectField()) {
            return this.schemaService.getFieldSpecialValuesAsText(this.objectType, this.fieldName);
        }

        return '';
    }

    /**
     * Decide whether the data type to fetch text from is an object field or not.
     */
    public isObjectField(): boolean {
        return Boolean(this.objectType && this.fieldName);
    }

    /**
     * Decide whether the data type to fetch text from is an enum or not.
     */
    private isEnum(): boolean {
        return Boolean(this.enumType && this.enumValue);
    }
}

/* eslint-disable import/first, import/order */
// tslint:disable:no-var-requires

// eslint-disable-next-line import/no-unresolved
import { IWindow } from './declarations/globals.d';

const globWindow = window as IWindow & typeof globalThis;

// not needed for the app (due to ProvidePlugin - doesn't work wo one)
// but required for unit-tests run and console access
globWindow.jQuery = jQuery;
globWindow.$ = jQuery;

import angular from 'angular';

// TODO remove
import 'jquery-knob';
import 'angular-sanitize';
import 'angular-vs-repeat';

import { UIRouter, UrlService } from '@uirouter/core';

// TODO replace with @angular/cdk/drag-drop
import 'angular-drag-and-drop-lists';

// top level
import underscore from 'underscore';

globWindow._ = underscore;

import moment from 'moment';

globWindow.moment = moment;

/**
 * Legacy, mostly for logs search and "debugMode" variable.
 * @deprecated
 */
globWindow.avi = {};

// Clarity design
import '@cds/core/icon';
import '@cds/core/alert/register';
import '@cds/core/modal/register';
import '@cds/core/button/register';
import '@cds/core/progress-circle/register';
import '@cds/core/tag/register';
import '@cds/core/search/register';

// import and override some of clarity's very generic class and elements styles
// this import has to be made from js to produce separate clarity CSS chunk on prod build
import './less/clr-design.less';
import './less/all.less';

import { setAngularLib } from '@angular/upgrade/static';

import {
    enableProdMode,
    NgModuleRef,
    NgZone,
} from '@angular/core';

if (process.env.NODE_ENV === 'production') {
    enableProdMode();
}

setAngularLib(angular);

import './js/app-config';

/**
 * Facilitates recursive require calls.
 * @see https://webpack.js.org/guides/dependency-management/#requirecontext
 * @inner
 */
function requireAll(require: __WebpackModuleApi.RequireContext): void {
    const requests = require.keys();

    requests.forEach(require);
}

// Regex is for all js files that don't end with .spec.js
// and yes, regex has to be copy-pasted every time
requireAll(require.context('./js/constants', true, /^(?!.*\.spec\.js$).*\.js$/));
requireAll(require.context('./js/utilities', true, /^(?!.*\.spec\.js$).*\.js$/));
requireAll(require.context('./js/services', true, /^(?!.*\.spec\.js$).*\.js$/));
requireAll(require.context('./js/controllers', true, /^(?!.*\.spec\.js$).*\.js$/));
requireAll(require.context('./js/directives', true, /^(?!.*\.spec\.js$).*\.js$/));
requireAll(require.context('./js/filters', true, /^(?!.*\.spec\.js$).*\.js$/));

requireAll(require.context('./components', true, /^(?!.*\.spec\.js$).*\.js$/));

// ts files from angularJs folders
import './js/index';
import './modules';

// Tells UI-Router that it should wait
// until all bootstrapping is complete before doing the initial URL synchronization.
angular.module('aviApp')
    .config(['$urlServiceProvider', ($urlService: UrlService) => $urlService.deferIntercept()]);

import { AppModule } from './angular/app.module';

import './downgrade-components';
import './downgrade-services';

import { platformBrowser } from '@angular/platform-browser';

/**
 * Starts UI router synchronization. To enable support for hybrid mode,
 * Method should be called after bootstrapping NgModule.
 */
function startUIRouter(platformRef: NgModuleRef<any>): void {
    // Get the UIRouter instance from DI to initialize the router
    const { urlService } = platformRef.injector.get(UIRouter);

    // Instruct UIRouter to listen to URL changes
    urlService.listen();
    urlService.sync();
}

/**
 * Initializes UI Router.
 */
function initUIRouter(platformRef: NgModuleRef<any>): void {
    platformRef.injector.get<NgZone>(NgZone).run(startUIRouter.bind(null, platformRef));
}

platformBrowser().bootstrapModule(AppModule).then(initUIRouter);

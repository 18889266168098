const moduleName = 'service-engine-group';
const componentName = 'segroup-cluster-host-config';
const prefix = `${moduleName}.${componentName}`;

export const clusterInputLabel = `${prefix}.clusterInputLabel`;
export const includeInputLabel = `${prefix}.includeInputLabel`;
export const excludeInputLabel = `${prefix}.excludeInputLabel`;
export const selectClusterInputPlaceholder = `${prefix}.selectClusterInputPlaceholder`;
export const hostInputLabel = `${prefix}.hostInputLabel`;
export const selectHostInputPlaceholder = `${prefix}.selectHostInputPlaceholder`;
export const clusterChangeWarning = `${prefix}.clusterChangeWarning`;
export const warningTitle = `${prefix}.warningTitle`;
export const continueMessage = `${prefix}.continueMessage`;

export const ENGLISH = {
    [clusterInputLabel]: 'Cluster',
    [includeInputLabel]: 'Include',
    [excludeInputLabel]: 'Exclude',
    [selectClusterInputPlaceholder]: 'Select a Cluster',
    [hostInputLabel]: 'Host',
    [selectHostInputPlaceholder]: 'Select a Host',
    [clusterChangeWarning]: 'Changing cluster will clear selected hosts.',
    [warningTitle]: 'Warning',
    [continueMessage]: 'yes, continue',
};

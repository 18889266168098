/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../../less/components/pie-chart.less';
import {
    svg as d3Svg,
    select as d3Select,
    layout as d3Layout,
} from 'd3';

const d3 = {
    svg: d3Svg,
    select: d3Select,
    layout: d3Layout,
};

/*
    pieChart takes an array of "value objects" with value, title, className.
    And updates hovered value with value object of a hovered arc
*/
angular.module('aviApp').directive('pieChart', function() {
    function link(scope, elm) {
        let outerArc,
            phantomArc;

        const width = Math.min(elm.width(), 150);
        const r = Math.floor(width / 2) - 1;

        let arc = d3.svg.arc().outerRadius(r);

        if (scope.highlight) {
            arc = d3.svg.arc().outerRadius(r - 6);
            outerArc = d3.svg.arc().innerRadius(r - 4).outerRadius(r);
            phantomArc = d3.svg.arc().outerRadius(r);
        }

        const svg = d3.select(elm[0])
            .append('svg:svg')
            .attr({
                width,
                height: width,
            })
            .append('g')
            .attr('transform', 'translate(0,0)');

        const chart = svg
            .append('g')
        // .attr('transform', 'translate(0,0');
            .attr('transform', `translate(${r + 1},${r + 1})`);

        const pie = d3.layout.pie()//how to get value from array
            .value(function(val) { return val.value; })
            .sort(null);

        function paintChart() {
            let arcs;

            chart.selectAll('*').remove();

            const emptySetOfData = !_.any(scope.values, function(val) {
                return val.value;
            });

            if (!emptySetOfData) {
                arcs = chart.selectAll('g.slice')
                    .data(pie(scope.values))
                    .enter().append('g')
                    .attr('class', 'slice');

                arcs.append('path')
                    .attr('d', arc)
                    .attr('class', function(d) {
                        return `pieChartSlice ${typeof d.data.className === 'undefined' ?
                            '' : d.data.className}`;
                    });

                if (scope.highlight) { // if array of slices to highlight is present
                    scope.highlight.forEach(function(highlight) {
                        arcs.append('path')
                            .attr('d', outerArc)
                            .attr('class', function(d) {
                                if (highlight.combine.indexOf(d.data.id) > -1) {
                                    return highlight.className;
                                }

                                return 'chart-color-clear';
                            })
                            .style('stroke-width', 0);
                    });
                }

                // --------- Mouse Events ----------//
                arcs.on('mouseout', function() {
                    scope.$apply('hovered = null');
                });
                arcs.on('mouseover', function(arc) {
                    scope.$apply(function() {
                        scope.hovered = arc.data;
                    });
                });
            } else {
                arcs = chart.selectAll('g.slice')
                    .data(pie([{ value: 1 }]))
                    .enter().append('g')
                    .attr('class', 'slice');

                if (scope.highlight) {
                    scope.highlight.forEach(function(highlight) {
                        arcs.append('path')
                            .attr('d', phantomArc)
                            .attr('class', 'pieChartSlice placeholder');
                    });
                } else {
                    arcs.append('path')
                        .attr('d', arc)
                        .attr('class', 'pieChartSlice placeholder');
                }
            }
        }

        //can use full listening as set of values and it's properties won't be too long
        scope.$watch('values', paintChart, true);
        scope.$on('repaint', paintChart);
    }

    return {
        restrict: 'A',
        scope: {
            values: '=', //array of value objects in form of {id, title, className, value}
            hovered: '=', //updates property with object of hovered arc
            highlight: '=', //array of slices to highlight
        },
        link,
    };
});

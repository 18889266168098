export interface IUpgradeNodeVersionHash {
    // Version of the node. A raw version data is denoted with format as
    // '20.1.1-5884-20200316.231632', '20.1.1' is the version.
    version?: string;

    // Build of the version. A raw version data is denoted with format as
    // '20.1.1-5884-20200316.231632', '5884' is the build.
    build?: string;

    // Date of the build. A raw version data is denoted with format as
    // '20.1.1-5884-20200316.231632', '20200316.231632' is the date.
    date?: string;

    // Patch version of the node with format as '2p1'.
    patchVersion?: string;

    // Major version of the patch. If a given patch version is
    // '2p1', then the '2' before the 'p' is the major patch version.
    majorPatchVersion?: string;

    // Minor version of the patch. If a given patch version is
    // '2p1', then the '1' after the 'p' is the minor patch version.
    minorPatchVersion?: string;
}

export enum UpgradeNodeType {
    CONTROLLER = 'NODE_CONTROLLER_CLUSTER',
    SE_GROUP = 'NODE_SE_GROUP',
    SE = 'NODE_SE_TYPE',
}

// Upgrade node state type, belonging to enum 'UpgradeFsmState'.
export enum UpgradeState {
    INIT = 'UPGRADE_FSM_INIT',
    STARTED = 'UPGRADE_FSM_STARTED',
    WAITING = 'UPGRADE_FSM_WAITING',
    IN_PROGRESS = 'UPGRADE_FSM_IN_PROGRESS',
    ENQUEUED = 'UPGRADE_FSM_ENQUEUED',
    ERROR = 'UPGRADE_FSM_ERROR',
    SUSPENDED = 'UPGRADE_FSM_SUSPENDED',
    ENQUEUE_FAILED = 'UPGRADE_FSM_ENQUEUE_FAILED',
    COMPLETED = 'UPGRADE_FSM_COMPLETED',
    ABORT_IN_PROGRESS = 'UPGRADE_FSM_ABORT_IN_PROGRESS',
    ABORTED = 'UPGRADE_FSM_ABORTED',
}

export enum UpgradeStateGroup {
    GROUP_INIT = 'UPGRADE_FSM_UI_INIT',
    GROUP_IN_PROGRESS = 'UPGRADE_FSM_UI_IN_PROGRESS',
    GROUP_COMPLETED = 'UPGRADE_FSM_UI_COMPLETED',
    GROUP_ERROR = 'UPGRADE_FSM_UI_ERROR',
}

const moduleName = 'ajs-legacy';
const componentName = 'ssl-certificate-create-application-system';
const prefix = `${moduleName}.${componentName}`;

export const editCertificateSslTlsModalHeader = `${prefix}.editCertificateSslTlsModalHeader`;
export const newCertificateSslTlsModalHeader = `${prefix}.newCertificateSslTlsModalHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const selfSignedButtonLabel = `${prefix}.selfSignedButtonLabel`;
export const importButtonLabel = `${prefix}.importButtonLabel`;
export const certificateInformationHeader = `${prefix}.certificateInformationHeader`;
export const commonNameInputLabel = `${prefix}.commonNameInputLabel`;
export const emailInputLabel = `${prefix}.emailInputLabel`;
export const organizationUnitInputLabel = `${prefix}.organizationUnitInputLabel`;
export const organizationUnitInputPlaceholder = `${prefix}.organizationUnitInputPlaceholder`;
export const organizationInputLabel = `${prefix}.organizationInputLabel`;
export const organizationInputPlaceholder = `${prefix}.organizationInputPlaceholder`;
export const localityInputLabel = `${prefix}.localityInputLabel`;
export const localityInputPlaceholder = `${prefix}.localityInputPlaceholder`;
export const stateNameInputLabel = `${prefix}.stateNameInputLabel`;
export const stateNameInputPlaceholder = `${prefix}.stateNameInputPlaceholder`;
export const countryInputLabel = `${prefix}.countryInputLabel`;
export const countryInputPlaceholder = `${prefix}.countryInputPlaceholder`;
export const subjectAlternatNameInputLabel = `${prefix}.subjectAlternatNameInputLabel`;
export const algorithmInputLabel = `${prefix}.algorithmInputLabel`;
export const keySizeInputLabel = `${prefix}.keySizeInputLabel`;
export const daysUntilExpirationInputLabel = `${prefix}.daysUntilExpirationInputLabel`;
export const certificateManagementProfileInputLabel = `${prefix}.certificateManagementProfileInputLabel`;
export const certificateManagementProfileCreateLabel = `${prefix}.certificateManagementProfileCreateLabel`;
export const certificateManagementProfileInputPlaceholder = `${prefix}.certificateManagementProfileInputPlaceholder`;
export const valueInputLabel = `${prefix}.valueInputLabel`;
export const isSensitiveInputLabel = `${prefix}.isSensitiveInputLabel`;
export const certificateInputLabel = `${prefix}.certificateInputLabel`;
export const pasteTextRadioOption = `${prefix}.pasteTextRadioOption`;
export const uploadFileRadioOption = `${prefix}.uploadFileRadioOption`;
export const pasteCertificateInputPlaceholder = `${prefix}.pasteCertificateInputPlaceholder`;
export const keyPemInputLabel = `${prefix}.keyPemInputLabel`;
export const pasteKeyInputPlaceholder = `${prefix}.pasteKeyInputPlaceholder`;
export const keyPassphraseInputLabel = `${prefix}.keyPassphraseInputLabel`;
export const keyPassphraseInputPlaceholder = `${prefix}.keyPassphraseInputPlaceholder`;
export const hsmCertificateHeader = `${prefix}.hsmCertificateHeader`;
export const hsmGroupInputLabel = `${prefix}.hsmGroupInputLabel`;
export const hsmGroupInputPlaceholder = `${prefix}.hsmGroupInputPlaceholder`;
export const hsmGroupCreateLabel = `${prefix}.hsmGroupCreateLabel`;
export const encryptedPrivateKeyInputLabel = `${prefix}.encryptedPrivateKeyInputLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;
export const validateButtonLabel = `${prefix}.validateButtonLabel`;

export const ENGLISH = {
    [editCertificateSslTlsModalHeader]: 'Edit Certificate (SSL/TLS): {0}',
    [newCertificateSslTlsModalHeader]: 'New Certificate (SSL/TLS): {0}',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'SSL/TLS Certificate Name',
    [typeInputLabel]: 'Type',
    [selfSignedButtonLabel]: 'Self Signed',
    [importButtonLabel]: 'Import',
    [certificateInformationHeader]: 'Certificate Information',
    [commonNameInputLabel]: 'Common Name',
    [emailInputLabel]: 'Email',
    [organizationUnitInputLabel]: 'Organization Unit',
    [organizationUnitInputPlaceholder]: 'Department name',
    [organizationInputLabel]: 'Organization',
    [organizationInputPlaceholder]: 'Company name',
    [localityInputLabel]: 'Locality or City',
    [localityInputPlaceholder]: 'Locality',
    [stateNameInputLabel]: 'State Name or Province',
    [stateNameInputPlaceholder]: 'State',
    [countryInputLabel]: 'Country',
    [countryInputPlaceholder]: 'Two letter country code',
    [subjectAlternatNameInputLabel]: 'Subject Alternate Name (SAN)',
    [algorithmInputLabel]: 'Algorithm',
    [keySizeInputLabel]: 'Key Size',
    [daysUntilExpirationInputLabel]: 'Days Until Expiration',
    [certificateManagementProfileInputLabel]: 'Certificate Management Profile',
    [certificateManagementProfileCreateLabel]: 'Create Certificate Management Profile',
    [certificateManagementProfileInputPlaceholder]: 'Select a Certificate Management Profile',
    [valueInputLabel]: 'Value',
    [isSensitiveInputLabel]: 'Sensitive',
    [certificateInputLabel]: 'Certificate',
    [pasteTextRadioOption]: 'Paste text',
    [uploadFileRadioOption]: 'Upload File',
    [pasteCertificateInputPlaceholder]: 'Paste your certificate here',
    [keyPemInputLabel]: 'Key (PEM) {0}',
    [pasteKeyInputPlaceholder]: 'Paste your key here',
    [keyPassphraseInputLabel]: 'Key Passphrase',
    [keyPassphraseInputPlaceholder]: 'SSL/TLS Passphrase',
    [hsmCertificateHeader]: 'HSM Certificate',
    [hsmGroupInputLabel]: 'HSM Group',
    [hsmGroupInputPlaceholder]: 'Select an HSM Group',
    [hsmGroupCreateLabel]: 'Create HSM Group',
    [encryptedPrivateKeyInputLabel]: 'Encrypted Private Key',
    [saveButtonLabel]: 'Save',
    [validateButtonLabel]: 'Validate',
};

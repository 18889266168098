/**
 * @module ScriptModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { Collection } from 'ajs/modules/data-model';

/**
 * @description
 *
 *   DataScriptSet collection
 *
 * @author Aravindh Nagarajan
 */
export class DataScriptSetCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'vsdatascriptset',
            windowElement: 'datascriptset-modal',
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('DataScriptSet');
    }
}

DataScriptSetCollection.ajsDependencies = [
    'DataScriptSet',
];

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function gatewayMonitorCollectionFactory(Collection, GatewayMonitor) {
    // GatewayMonitorCollection assumes VRF is global.
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Collection
     * @author Ram Pal
     * @desc Collection of {@link module:avi/app.GatewayMonitor GatewayMonitors}.
     */
    class GatewayMonitorCollection extends Collection {
        constructor(args) {
            super(args);
            this.vrf = args && args.vrf || 'global';
        }
    }

    const allDataSources = {
        list: {
            source: 'ListCollDataSource',
            transformer: 'GatewayMonitorListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    Object.assign(GatewayMonitorCollection.prototype, {
        objectName_: 'vrfcontext',
        windowElement_: 'infra-gateway-monitor-create',
        itemClass_: GatewayMonitor,
        defaultDataSources_: 'list',
        allDataSources_: allDataSources,
        restrictCreateOnEssentialLicense_: true,
    });

    return GatewayMonitorCollection;
}

gatewayMonitorCollectionFactory.$inject = [
    'Collection',
    'GatewayMonitor',
];

angular.module('avi/app')
    .factory('GatewayMonitorCollection', gatewayMonitorCollectionFactory);

/**
 * @module VCenterServerModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { Collection } from 'ajs/modules/data-model';
import { VCenterServer } from './vcenter-server.item.factory';

export class VCenterServerCollection extends Collection {
    public items: VCenterServer[];

    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'vcenterserver',
            permissionName: 'PERMISSION_CLOUD',
            windowElement: 'vcenter-server-modal',
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('VCenterServer');
    }
}

VCenterServerCollection.ajsDependencies = [
    'VCenterServer',
];

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './SystemConfigurationController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name SystemConfigurationController
 * @description
 *
 *     Controller for a few tabs within Administration > Settings.
 */
angular.module('aviApp').controller('SystemConfigurationController', [
'AviModal', 'loadedSystemConfigService', 'l10nService',
function(AviModal, systemConfig, l10nService) {
    this.systemConfig = systemConfig;//preloaded by router

    this.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    //opens different modals to change system settings
    this.editSmtpConfig = () => {
        systemConfig.editEmailSmtp();
    };

    this.editSystemAccessSettings = () => {
        systemConfig.edit('adm-sysconf-edit-access');
    };

    /**
     * Opens modal to validate email settings.
     */
    this.openValidateEmailModal = () => {
        AviModal.open('adm-email-validate', {
            type: 'smtp',
        });
    };

    /**
     * Returns a string of IP addresses, ranges, prefixes, and groups.
     * @param  {Object} obj - Object containing IP addrs, ranges, prefixes, and group_refs lists.
     * @return {string}
     * @public
     */
    this.getStringOfIps = function(obj) {
        if (!obj) {
            return l10nService.getMessage(l10nKeys.anyLabel);
        }

        let output = [];

        if (obj.addrs && obj.addrs.length) {
            output = output.concat(_.pluck(obj.addrs, 'addr'));
        }

        if (obj.ranges && obj.ranges.length) {
            output = output.concat(obj.ranges.map(function(range) {
                return `${range.begin.addr}-${range.end.addr}`;
            }));
        }

        if (obj.prefixes && obj.prefixes.length) {
            output = output.concat(obj.prefixes.map(function(prefix) {
                return `${prefix.ip_addr.addr}/${prefix.mask}`;
            }));
        }

        if (obj.group_refs && obj.group_refs.length) {
            output = output.concat(obj.group_refs.map(function(group) {
                return group.name();
            }));
        }

        return output.join(', ');
    };

    /**
     * Returns mail server and port string or N/A.
     * @returns {string}
     * @public
     */
    this.getSMTPServerCredsLabel = function() {
        const emailConfig = systemConfig.getEmailConfig();

        if (emailConfig?.mail_server_name) {
            return `${emailConfig['mail_server_name']}:${emailConfig['mail_server_port']}`;
        } else {
            return l10nService.getMessage(l10nKeys.emptyObjectLabel);
        }
    };

    /**
     * Returns true if the From Address field should be displayed on the Email SMTP page.
     * @returns {boolean}
     */
    this.showEmailConfigurationFromAddress = () => {
        return this.systemConfig.getConfig().email_configuration.hasFromEmail();
    };
}]);

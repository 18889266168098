/**
 * @module LicensingModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClrRadioModule } from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';
import { CoreModule } from 'ng/modules/core/core.module';

import {
    LicenseSummaryCardComponent,
    LicenseTierSelectCardComponent,
    LicenseTierSwitchModalComponent,
} from '.';

const licensingComponents = [
    LicenseSummaryCardComponent,
    LicenseTierSelectCardComponent,
    LicenseTierSwitchModalComponent,
];

/**
 * @description
 *
 *     Angular Module for Licesensing related service and components.
 *
 * @author Zhiqian Liu
 */
@NgModule({
    declarations: [
        ...licensingComponents,
    ],
    exports: [
        LicenseSummaryCardComponent,
        LicenseTierSelectCardComponent,
        LicenseTierSwitchModalComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ClrRadioModule,
        SharedModule,
        CoreModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class LicensingModule {}

/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { isUndefined } from 'underscore';
import { StringService } from 'ajs/modules/core';
import {
    DropdownModelValue,
} from '../../avi-dropdown';
import './avi-collection-dropdown-selected-values-container.component.less';

/**
 * Component for displaying either a single selected value or multiple selected values in the
 * AviCollectionDropdownComponent.
 * @author alextsg
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'avi-collection-dropdown-selected-values-container',
    templateUrl: './avi-collection-dropdown-selected-values-container.component.html',
})
export class AviCollectionDropdownSelectedValuesContainerComponent {
    /**
     * Selected value.
     */
    @Input() public value: DropdownModelValue;

    /**
     * True if the value is an array.
     */
    @Input() public multiple = false;

    /**
     * Called to remove a value.
     */
    @Output() public onRemoveValue = new EventEmitter<DropdownModelValue>();

    constructor(private readonly stringService: StringService) {}

    /**
     * Returns the name from the Item ref. If the name is not present, falls back on the slug of the
     * ref, and if that's not available, returns the ref itself.
     */
    public getName(ref?: DropdownModelValue): string {
        if (isUndefined(ref)) {
            return '';
        }

        return this.stringService.name(ref as string) ||
            this.stringService.slug(ref as string) ||
            ref as string;
    }

    /**
     * Click handler to remove a value.
     */
    public handleRemove(ref: DropdownModelValue): void {
        this.onRemoveValue.emit(ref);
    }

    /**
     * Trackby function for multiple values.
     */
    public trackByValue(index: number, value: DropdownModelValue): DropdownModelValue {
        return value;
    }
}

/**
 * @module IcapProfileModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { IIcapProfile } from 'generated-types';
import { ICAPProfileModalComponent } from 'ng/modules/icap-profile';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { ObjectTypeItem } from 'ajs/modules/data-model';

interface IIcapProfileData {
    config: IIcapProfile;
}

/**
 * @description
 *
 *   ICAP-Profile item.
 *
 * @author Aravindh Nagarajan
 */
export class IcapProfile extends withFullModalMixin(ObjectTypeItem) {
    public data: IIcapProfileData;
    public getConfig: () => IIcapProfile;

    constructor(args: {}) {
        const extendedArgs = {
            ...args,
            objectName: 'icapprofile',
            objectType: 'IcapProfile',
            permissionName: 'PERMISSION_ICAP',
            windowElement: ICAPProfileModalComponent,
        };

        super(extendedArgs);
    }
}

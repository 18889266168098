/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { MatchTargetConfigItem, TMatchField } from 'ajs/modules/policies';
import { IMessageItemData } from 'ajs/modules/data-model';

const supportedMatches = ['client_ip', 'method', 'path', 'host_hdr'];

interface IWafPolicyAllowlistRuleData extends IMessageItemData {
    config: {
        actions?: string[];
        enable?: boolean;
        match?: MatchTargetConfigItem;
        sampling_percent?: number;
    };
}

export class WafPolicyAllowlistRuleConfigItem extends MessageItem {
    public data: IWafPolicyAllowlistRuleData;

    constructor(args = {}) {
        super({
            objectType: 'WafPolicyAllowlistRule',
            ...args,
        });
    }

    /**
     * We want to remove 'match' from the default configuration so that the user can add matches
     * manually.
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): this['config'] {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const type = this.objectType.toLowerCase();
        const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};
        const { match, ...defaults } = defaultConfig;

        return {
            ...defaults,
            match: null,
        };
    }

    /**
     * Returns the list of supported matches. TODO: refactor to use Schema when
     * "match_target_options" is available.
     */
    public get supportedMatches(): string[] {
        return supportedMatches;
    }

    /**
     * Returns the number of matches configured.
     */
    public get matchCount(): number {
        return this.supportedMatches.filter(this.hasMatchByField).length;
    }

    /**
     * Returns the enable state.
     */
    public get enable(): boolean {
        return this.config.enable;
    }

    /**
     * Sets the enable state.
     */
    public set enable(enabled) {
        this.config.enable = enabled;
    }

    /**
     * Returns true if the match exists in this.config.match.
     */
    public hasMatchByField = (matchFieldName: TMatchField): boolean => {
        return this.config.match ? this.config.match.hasMatchByField(matchFieldName) : false;
    };
}

WafPolicyAllowlistRuleConfigItem.ajsDependencies = [
    'defaultValues',
];

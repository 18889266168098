/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function networkProfileCollectionFactory(Collection, NetworkProfile) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Collection
     * @author Ram Pal
     * @desc Collection of {@link module:avi/app.NetworkProfile NetworkProfiles}.
     */
    class NetworkProfileCollection extends Collection { }

    const serverDefaultsOverride = {
        profile: {
            type: 'PROTOCOL_TYPE_TCP_PROXY',
            tcp_fast_path_profile: {
                dsr_profile: undefined,
            },
            udp_fast_path_profile: {
                dsr_profile: undefined,
            },
        },
    };

    Object.assign(NetworkProfileCollection.prototype, {
        objectName_: 'networkprofile',
        itemClass_: NetworkProfile,
        windowElement_: 'prof-net-profile-create',
        defaults_: {
            profile: {
                type: 'PROTOCOL_TYPE_TCP_PROXY',
            },
        },
        serverDefaultsOverride_: serverDefaultsOverride,
    });

    return NetworkProfileCollection;
}

networkProfileCollectionFactory.$inject = [
    'Collection',
    'NetworkProfile',
];

angular.module('avi/app')
    .factory('NetworkProfileCollection', networkProfileCollectionFactory);

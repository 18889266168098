/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

angular.module('avi/serviceengine').factory('SEGroupCollection', [
'Collection', 'SEGroup',
function(Collection, SEGroup) {
    /**
     * @constructor
     * @extends module:avi/dataModel.Collection
     * @memberOf module:avi/serviceengine
     * @description Collection of {@link module:avi/serviceengine.SEGroup SEGroups}.
     * @author Alex Malitsky
     */
    class SEGroupCollection extends Collection {
        constructor(args = {}) {
            super(args);

            /**
             * Displays the tenant name in parentheses next to the SE Group name. False by
             * default, set to true in SE Create/Edit, when we want to show the tenant names in
             * the SE Group dropdown.
             * @type {boolean}
             */
            this.showTenantName = args.showTenantName || false;
        }
    }

    const dataSources = {
        inventory: {
            source: 'InventoryCollDataSource',
            transformer: 'SeGroupInventoryDataTransformer',
            transport: 'ListDataTransport',
            fields: [
                'config',
                'virtualservices',
                'serviceengines',
                'upgradestatus',
            ],
        },
    };

    angular.extend(SEGroupCollection.prototype, {
        objectName_: 'serviceenginegroup-inventory',
        itemClass_: SEGroup,
        windowElement_: 'infra-segroup-create',
        serverDefaultsOverride_: {
            vcenter_datastores: [],
            instance_flavor_info: undefined,
        },
        isStatic_: false,
        showTenantName: false,
        defaultDataSources_: 'inventory',
        allDataSources_: dataSources,
        restrictCreateOnEssentialLicense_: false,
    });

    return SEGroupCollection;
}]);

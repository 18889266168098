/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { IPAMDNS_TYPE_INFOBLOX } from 'ajs/js/constants/ipam-dns.constants';
import * as l10n from './ipam-dns-profiles-expander.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Returns a list of human-readable subnet strings.
 * @param {InfobloxSubnetConfigItem[]} subnets - List of infoblox subnets containing subnet and/or
 *     subnet6 IpAddrPrefix objects.
 * @returns {string[]}
 */
const flattenUsableSubnets = subnets => {
    return subnets.map(subnetConfigItem => {
        const { subnet, subnet6 } = subnetConfigItem.config;
        const subnetStrings = [];

        if (subnet.isValid()) {
            subnetStrings.push(subnet.toString());
        }

        if (subnet6.isValid()) {
            subnetStrings.push(subnet6.toString());
        }

        return `(${subnetStrings.join(', ')})`;
    });
};

/**
 * @ngdoc component
 * @constructor
 * @param {IPAMProfile} ipamProfile - IPAMProfile object of selected profile.
 * @description Component to display more fields of IPAM/DMS Profiles.
 */
class IpamDnsProfilesExpanderController {
    constructor(l10nService) {
        /**
         * Get keys from source bundles for template usage.
         */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    $onInit() {
        /**
         * @type {string[]}
         */
        this.configuredUsableSubnetsList = [];

        if (this.ipamProfile.type === IPAMDNS_TYPE_INFOBLOX) {
            const { configuredUsableSubnets: usableSubnets } = this.ipamProfile.currentProfile;

            if (usableSubnets) {
                this.configuredUsableSubnetsList = flattenUsableSubnets(usableSubnets);
            }
        }
    }
}

IpamDnsProfilesExpanderController.$inject = [
    'l10nService',
];

angular.module('avi/ipam').component('ipamDnsProfilesExpander', {
    bindings: {
        ipamProfile: '<',
    },
    controller: IpamDnsProfilesExpanderController,
    templateUrl: 'src/components/pages/templates/profiles/' +
        'ipam-dns-profiles-expander/ipam-dns-profiles-expander.html',
});

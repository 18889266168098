/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc constant
 * @name timeframes
 * @description
 * Hash of timeframes we use for two types of pages. 'def' for all pages but log, event and alerts.
 */

angular.module('aviApp').constant('timeframes', {
    rt: {
        def: true,
        log: false,
        insights: true,
    },
    '15m': {
        def: false,
        log: true,
    },
    '1h': {
        def: false,
        log: true,
    },
    '3h': {
        def: false,
        log: true,
    },
    '6h': {
        def: true,
        log: true,
        insights: true,
    },
    '1d': {
        def: true,
        log: true,
    },
    '1w': {
        def: true,
        log: true,
    },
    '1m': {
        def: true,
        log: true,
    },
    '1q': {
        def: true,
        log: true,
    },
    '1y': {
        def: true,
        log: true,
    },
    all: {
        def: false,
        log: true,
    },
    custom: {
        def: false,
        log: true,
    },
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../../less/components/messenger.less';

/**
 * @ngdoc directive
 * @name aviApp.directive:messenger
 * @element any
 * @description
 *     Registers a message element with {@link aviApp.service:messageService} which
 *     can be used to display and hide selected element.
 * @example
 *
 *     <div messenger="myMessage">
 *        Message body goes here....
 *        <!-- Optional button to let user dismiss the message -->
 *        <button
 *          class="message-dismiss-button"
 *          ng-click="dismissMessage()">
 *            <i class='icon-cancel></i>
 *        </button>
 *     </div>
 *
 *     Inside the controller:
 *
 *     messageService.show('myMessage');
 *     $scope.dismissMessage = function() {
 *          messageService.hide('myMessage');
 *     }
 */
angular.module('aviApp').directive('messenger', function() {
    return {
        restrict: 'A',
        controller: MessageController,
        controllerAs: 'messageCtrl',
    };
});

/**
 * Messenger directive controller constructor.
 * @param {MessageService} messageService
 * @param {angular.element} $element
 * @param {angular.$attrs} $attrs
 * @param {angular.$rootScope} $rootScope
 * @constructor
 */
function MessageController(messageService, $element, $attrs, $rootScope) {
    this.destroy = this.destroy.bind(this);

    this.messageService = messageService;
    this.messageId = $attrs['messenger'];

    $element.addClass('avi-message');
    messageService.register(this.messageId, $element);

    $rootScope.$on('setContext', () => {
        setTimeout(this.destroy);
    });

    $rootScope.$on('userLoggedOut', () => {
        setTimeout(this.destroy);
    });
}

MessageController.$inject = [
    'messageService',
    '$element',
    '$attrs',
    '$rootScope',
];

/**
 * Cleans up message controller when its no longer needed.
 */
MessageController.prototype.destroy = function() {
    this.messageService.remove(this.messageId);
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const componentPath = 'src/components/infrastructure/clouds/openstack';
const componentName = 'openstack-cloud-provider-vip-networks-settings';

class OpenstackCloudProviderVipNetworksSettingsController {
    constructor(
        OpenstackNetworkCollection,
    ) {
        this.OpenstackNetworkCollection_ = OpenstackNetworkCollection;
    }

    /**
     * Instantiates networkCollection dropdown with the given params.
     */
    $onInit() {
        /**
         * collection type to be passed to dropdown custom.
         * @type {string}
         */
        this.osNetworksCollectionType = 'group';

        this.networkCollection = new this.OpenstackNetworkCollection_({
            params: this.networkCollectionParams,
        });
    }

    /**
     * Checks for changes to the bound networkCollectionParams to reset the networkCollection.
     * @param  {Object} changes - angular onChanges object.
     */
    $onChanges(changes) {
        if (_.sample(changes).isFirstChange()) {
            return;
        }

        const { currentValue: networkParams } = changes.networkCollectionParams || {};

        if (!_.isEmpty(networkParams)) {
            this.networkCollection.setParams(networkParams);
        }
    }

    /**
     * Handler for clicking Add Provider VIP networks button.
     */
    addProviderVipNetwork() {
        this.providerVipNetworks.push({});
    }

    /**
     * Handler for remove icon provided for each Provider VIP Network - Tenant Network
     * select dropdown row under Provider VIP networks section.
     * @param {number} index - index of the item to be removed from provider_vip_networks.
     */
    removeProviderVipNetwork(index) {
        this.providerVipNetworks.splice(index, 1);
    }

    /**
     * Destroys networkCollection.
     */
    $onDestroy() {
        this.networkCollection.destroy();
    }
}

OpenstackCloudProviderVipNetworksSettingsController.$inject = [
    'OpenstackNetworkCollection',
];

/**
 * @ngdoc component
 * @name openStackCloudProvideVipNetworksSettings
 * @author Chitra
 * @param {Object[]} providerVipNetworks - ProviderVipNetworks reference.
 * @param {boolean} networksByRegionAvailable - flag to indicate whether networksbyRegion
 *     List is available or not.
 * @param {Object[]} tenants - List of available tenants.
 * @param {Object} networkCollectionParams - params for openstack_tenant_networks API.
 * @description
 *
 *     Mapper for Provider Vip Networks with Tenants for Openstack cloud configuration.
 */
angular.module('aviApp').component('openstackCloudProviderVipNetworksSettings', {
    bindings: {
        providerVipNetworks: '<',
        networksByRegionAvailable: '<?',
        tenants: '<',
        networkCollectionParams: '<',
    },
    controller: OpenstackCloudProviderVipNetworksSettingsController,
    templateUrl: `${componentPath}/${componentName}/${componentName}.html`,
});

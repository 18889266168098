/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { IAzureServicePrincipalCredentials } from 'generated-types';
import { IMessageItemData, MessageItem } from 'ajs/modules/data-model';

interface IAzureServicePrincipalCredentialsData extends IMessageItemData {
    config: IAzureServicePrincipalCredentials;
}

export class AzureServicePrincipalCredentialsConfigItem extends MessageItem {
    public data: IAzureServicePrincipalCredentialsData;

    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'AzureServicePrincipalCredentials',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public isValid(): boolean {
        return this.canFlatten();
    }

    /** @override */
    public canFlatten(): boolean {
        return Boolean(this.config.application_id);
    }

    /**
     * Deletes configured fields.
     */
    public clearFields(): void {
        delete this.config.application_id;
        delete this.config.authentication_token;
        delete this.config.tenant_id;
    }
}

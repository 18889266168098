/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */
import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
} from '@angular/core';
import { FullModalConfigComponent } from '../full-modal-config.component';

/**
 * @description
 * Component used to wrap a section in the full modal to create a tab for navigation. Adding tabs
 * dynamically is not supported - tabs are added when the full-modal is initialized.
 * @author alextsg
 */
@Component({
    selector: 'full-modal-tab-section',
    template: `
        <div
            class="full-modal-tab-section"
            id="{{ tabId }}"
        >
            <ng-content></ng-content>
        </div>
    `,
})
export class FullModalTabSectionComponent implements OnInit, OnDestroy {
    /**
     * Text to show in the header, ex. the title of the modal.
     */
    @Input()
    public tabTitle = '';

    /**
     * ID of the DOM element, created by replacing special characters and spaces of the tabTitle.
     */
    public tabId: string;

    constructor(
        private configComponent: FullModalConfigComponent,
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) {}

    /** @override */
    public ngOnInit(): void {
        this.renderer.addClass(
            this.elementRef.nativeElement,
            'full-modal-config__full-modal-tab-section',
        );

        this.tabId = this.tabTitle.replace(/[^\w]/gi, '-').toLowerCase();
        this.configComponent.addTab(this);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.configComponent.removeTab(this);
    }
}

const moduleName = 'ajs-legacy';
const componentName = 'cluster-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleManagementHostnames = `${prefix}.columnTitleManagementHostnames`;
export const columnTitleClusterIp = `${prefix}.columnTitleClusterIp`;
export const columnTitleRole = `${prefix}.columnTitleRole`;
export const columnTitleState = `${prefix}.columnTitleState`;
export const editBtnLabel = `${prefix}.editBtnLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleManagementHostnames]: 'Management Hostnames',
    [columnTitleClusterIp]: 'Cluster IP',
    [columnTitleRole]: 'Role',
    [columnTitleState]: 'State',
    [editBtnLabel]: 'Edit',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/* wrapper for log popover values to move part of clickable filters logic out of templates */
angular.module('aviApp').directive('logCalloutValue', function() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
            onClick: '&',
        },
        template: `
            <span title="{{data.display || data.value || data}}">
                <span ng-if="!data.param">
                    {{data.display || data.value || data}}
                </span>
                <span ng-if="data.param" 
                      log-filter-click 
                      display-value="{{data.display}}" 
                      value="data.value" 
                      min-value="{{data.value.start}}"
                      max-value="{{data.value.end}}" 
                      key="{{data.param}}" 
                      on-update="onClick({str:str})" 
                      contains="{{data.contains}}">
                </span>
            </span>`,
    };
});

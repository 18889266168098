/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { SSOPolicyTypes } from '../../../../../js/services/items/SSOPolicy';

const { PINGACCESS: SSO_TYPE_PINGACCESS } = SSOPolicyTypes;

/**
 * @constructor
 * @memberOf module:avi/vs
 * @mixes module:avi/vs.pingAccessPolicySettingsBindings
 * @see {@link module:avi/vs.pingAccessPolicySettingsComponent pingAccessPolicySettingsComponent}
 */
class PingAccessPolicySettingsController {
    constructor(SSOPolicyCollection) {
        /** @type {SSOPolicyCollection} */
        this.ssoPolicyCollection = new SSOPolicyCollection({
            params: {
                type: SSO_TYPE_PINGACCESS,
            },
            defaults: {
                type: SSO_TYPE_PINGACCESS,
            },
        });
    }

    /** @override */
    $onDestroy() {
        this.ssoPolicyCollection.destroy();
    }
}

PingAccessPolicySettingsController.$inject = [
    'SSOPolicyCollection',
];

/**
 * @name pingAccessPolicySettingsComponent
 * @memberOf module:avi/vs
 * @description
 *      VS Access Policy -> PingAccess Settings.
 *      Only applicable for HTTP app profile type.
 * @param {module:avi/vs.pingAccessPolicySettingsBindings} bindings
 * @param {module:avi/vs.PingAccessPolicySettingsController} controller
 * @author Aravindh Nagarajan, Alex Malitsky
 */
angular.module('avi/vs').component('pingAccessPolicySettings', {
    /**
     * @mixin pingAccessPolicySettingsBindings
     * @memberOf module:avi/vs
     * @property {string} ssoPolicyRef VirtualService#data#config#sso_policy_ref
     */
    bindings: {
        ssoPolicyRef: '=',
    },
    controller: PingAccessPolicySettingsController,
    templateUrl:
        'src/components/applications/virtualservice/vs-access-policy-settings/' +
        'ping-access-policy-settings/ping-access-policy-settings.component.html',
});

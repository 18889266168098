/** @module GslbModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { ObjectTypeItem } from 'ajs/modules/data-model';
import { IFederationCheckpoint } from 'generated-types';
import { GslbCheckpointsModalComponent } from 'ng/modules/gslb';

export class FederationCheckpoint extends withFullModalMixin(ObjectTypeItem) {
    public data: {
        config: IFederationCheckpoint;
        is_active?: boolean;
    };

    public getConfig: () => IFederationCheckpoint;

    public get isActive(): boolean {
        return this.data.is_active;
    }

    public get date(): IFederationCheckpoint['date'] {
        return this.getConfig().date;
    }

    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'federationcheckpoint',
            objectType: 'FederationCheckpoint',
            permissionName: 'PERMISSION_CONTROLLER',
            windowElement: GslbCheckpointsModalComponent,
        };

        super(extendedArgs);
    }

    /** @override */
    public updateItemData(newData: FederationCheckpoint['data']): boolean {
        this.clearAllButConfig();

        return super.updateItemData(newData);
    }

    /**
     * Clears data object of all but config. Needed, to remove stale inventory data before
     * update.
     */
    private clearAllButConfig(): void {
        const { config } = this.data;

        this.data = { config };
    }
}

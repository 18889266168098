const moduleName = 'licensing';
const componentName = 'license-tier-select-card';
const prefix = `${moduleName}.${componentName}`;

export const licenseHeader = `${prefix}.licenseHeader`;
export const numberOfLicenses = `${prefix}.numberOfLicenses`;

export const ENGLISH = {
    [licenseHeader]: '{0} License',
    [numberOfLicenses]: '{0} Service Core Licenses',
};

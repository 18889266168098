/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './user-menu.less';
import * as l10n from './user-menu.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const componentName = 'user-menu';
const appAboutModalComponentName = 'app-about-modal';

/**
 * Top position for the user and additional menus.
 * It should match the less variable `@aviTopHeaderHeight`
 * which is defined in `pixels.less`.
 * @inner
 */
const albHeaderHeight = '60px';

/**
 * @class
 * @constructor
 * @memberOf module:avi/navigation
 * @see {@link  module:avi/navigation.userMenuComponent}
 */
class UserMenuController {
    constructor(
        $scope,
        $compile,
        Auth,
        AviModal,
        messageService,
        popoverFactory,
        User,
        l10nService,
        initialDataService,
        cPortalService,
    ) {
        this.$scope_ = $scope;
        this.$compile_ = $compile;
        this.Auth = Auth;
        this.AviModal_ = AviModal;
        this.messageService_ = messageService;
        this.cPortalService_ = cPortalService;
        this.User_ = User;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        /**
         * initialData Service
         * @type {initialDataService}
         * @protected
         */
        this.initialDataService_ = initialDataService;

        /**
         * @type {string}
         */
        this.aviPortalUrl = '';

        /**
         * @type {string}
         * @protected
         */
        this.additionalMenuTemplate_ = require(
            './additional-menu.partial.html',
        );

        /**
         * @type {string}
         * @protected
         */
        this.userCardMenuTemplate_ = require(
            './user-card-menu.partial.html',
        );

        /**
         * Current user's activity.
         * @type {userActivity|null}
         */
        this.userActivity = null;

        /**
         * Extended popoverFactory.
         * @protected
         */
        this.UserMenuPopover_ =
            class UserMenuPopover extends popoverFactory {
                constructor(config = {}, positionTop) {
                    super(config);

                    /**
                     * Top position value of the popover.
                     * @type {string}
                     */
                    this.top_ = positionTop;
                }

                /** @override */
                reposition() {
                    super.reposition();

                    if (this.popover) {
                        this.popover.css({
                            top: this.top_,
                        });
                    }
                }
            };
    }

    /** @override */
    $onInit() {
        //Load useractivity only when current user has 'PERMISSION_USER' access.
        if (this.Auth.isAllowed('user')) {
            this.User_.loadUserActivity(this.Auth.getUsername())
                .then(activity => {
                    this.userActivity = activity;
                });
        }

        this.setPortalUrl_();

        this.$scope_.$on('repaint', this.onWindowResize_.bind(this));
    }

    /**
     * Function to set portal url.
     * @protected
     */
    setPortalUrl_() {
        this.cPortalService_.getPortalInfo().then(portalInfo => {
            this.aviPortalUrl = portalInfo.portal_url;
        });
    }

    /**
     * Compiles popover template and Returns an instance of UserMenuPopoverFactory_.
     * @param {string} popoverContent - Menu content
     * @return {Object}
     * @protected
     */
    createPopoverInstance_(popoverContent) {
        const $popoverContent = $(popoverContent);

        const popoverConfig = {
            className: `${componentName}-popover`,
            position: 'bottom',
            removeAfterHide: true,
            margin: 10,
            repositioning: true,
            hide: {
                onEscape: true,
                outClick: true,
                innerClick: true,
            },
            html: this.$compile_($popoverContent)(this.$scope_),
        };

        return new this.UserMenuPopover_(popoverConfig, albHeaderHeight);
    }

    /**
     * Gets the popover instance and displays menu.
     * @param {string} menuName Name of the menu to be displayed.
     * @param {angular.$event} event Menu icon click event.
     */
    showMenu(menuName, event) {
        let popover;

        switch (menuName) {
            case 'usercard':
                popover = this.getUserCardPopoverInstance_();
                break;

            case 'additional':
                popover = this.getAdditionalMenuPopoverInstance_();
                break;
        }

        popover.show(event.target);
    }

    /**
     * Returns popover instance for additional menu
     * Creates one if its undefined.
     * @return {popoverFactory}
     * @protected
     */
    getAdditionalMenuPopoverInstance_() {
        if (!this.additionalMenuPopover_) {
            this.additionalMenuPopover_ =
                this.createPopoverInstance_(this.additionalMenuTemplate_);
        }

        return this.additionalMenuPopover_;
    }

    /**
     * Returns popover instance for User-card menu.
     * Creates one if its undefined.
     * @return {popoverFactory}
     * @protected
     */
    getUserCardPopoverInstance_() {
        if (!this.userCardPopover_) {
            this.userCardPopover_ =
                this.createPopoverInstance_(this.userCardMenuTemplate_);
        }

        return this.userCardPopover_;
    }

    /**
     * Opens the dialog to generate a token for authentication.
     */
    openGenerateTokenModal() {
        const popupExists = this.messageService_.messageIsVisible('generateSsoTokenMessage');

        if (!popupExists) {
            const scope = this.$scope_.$new();

            scope.handleCloseMessage = () => {
                this.messageService_.hide('generateSsoTokenMessage');
                this.messageService_.remove('generateSsoTokenMessage');
                scope.$destroy();
            };

            const compiledMessage = this.$compile_(
                `<generate-sso-token-message
                    class="message wide animated fadeIn tokenGenerate"
                    on-close-message="handleCloseMessage()"
                    messenger="generateSsoTokenMessage">
                </generate-sso-token-message>`,
            )(scope);

            this.messageService_.append('generateSsoTokenMessage', compiledMessage);
        }
    }

    /**
     * Opens the About modal popup.
     */
    handleOpenAbout() {
        this.AviModal_.open(appAboutModalComponentName, null, 'avi-modal avi-about-modal animated');
    }

    /**
     * Logs out of the application.
     */
    handleLogout() {
        this.Auth.logout(true);
    }

    /**
     * Fires on window resize.
     * @protected
     */
    onWindowResize_() {
        this.closeMenuPopovers_();
    }

    /**
     * Removes popover menu if they are open.
     * @protected
     */
    closeMenuPopovers_() {
        const {
            additionalMenuPopover_,
            userCardPopover_,
        } = this;

        if (additionalMenuPopover_) {
            additionalMenuPopover_.remove();
        }

        if (userCardPopover_) {
            userCardPopover_.remove();
        }
    }

    /** @override */
    $onDestroy() {
        this.closeMenuPopovers_();

        const {
            AviModal_,
            messageService_,
        } = this;

        if (AviModal_.isOpen(appAboutModalComponentName)) {
            AviModal_.destroy(appAboutModalComponentName);
        }

        messageService_.remove('generateSsoTokenMessage');
    }
}

UserMenuController.$inject = [
    '$scope',
    '$compile',
    'Auth',
    'AviModal',
    'messageService',
    'popoverFactory',
    'User',
    'l10nService',
    'initialDataService',
    'CportalService',
];

/**
 * @name userMenuComponent
 * @memberOf module:avi/navigation
 * @property {module:avi/navigation.UserMenuController} controller
 * @desc
 *      Component for User menu. Displays Popover for additional and user card menu.
 *      Found in the header.
 * @author alextsg, Aravindh Nagarajan
 */
angular.module('avi/navigation').component('userMenu', {
    controller: UserMenuController,
    templateUrl: `src/components/avi-header/${componentName}/${componentName}.html`,
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'error-page-body-modal';
const prefix = `${moduleName}.${componentName}`;

export const editErrorPageBodyHeader = `${prefix}.editErrorPageBodyHeader`;
export const newErrorPageBodyHeader = `${prefix}.newErrorPageBodyHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const htmlContentInputLabel = `${prefix}.htmlContentInputLabel`;

export const ENGLISH = {
    [editErrorPageBodyHeader]: 'Edit Error Page Body: {0}',
    [newErrorPageBodyHeader]: 'New Error Page Body: {0}',
    [nameInputLabel]: 'Name',
    [htmlContentInputLabel]: 'HTML Content',
};

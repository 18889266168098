const moduleName = 'ajs-legacy';
const componentName = 'ip-addr-group-create-controller';
const prefix = `${moduleName}.${componentName}`;

export const editIpGroupModalHeader = `${prefix}.editIpGroupModalHeader`;
export const newIpGroupModalHeader = `${prefix}.newIpGroupModalHeader`;
export const ipGroupNameInputLabel = `${prefix}.ipGroupNameInputLabel`;
export const ipGroupNameInputPlaceholder = `${prefix}.ipGroupNameInputPlaceholder`;
export const ipInformationHeader = `${prefix}.ipInformationHeader`;
export const selectByIpAddressRadioOption = `${prefix}.selectByIpAddressRadioOption`;
export const selectByCountryCodeRadioOption = `${prefix}.selectByCountryCodeRadioOption`;
export const selectByEpgRadioOption = `${prefix}.selectByEpgRadioOption`;
export const ipAddressInputLabel = `${prefix}.ipAddressInputLabel`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const uploadFileButtonLabel = `${prefix}.uploadFileButtonLabel`;
export const countryCodesInputLabel = `${prefix}.countryCodesInputLabel`;
export const countryCodesInputPlaceholder = `${prefix}.countryCodesInputPlaceholder`;
export const selectEpgInputPlaceholder = `${prefix}.selectEpgInputPlaceholder`;
export const createIpGroupBtnLabel = `${prefix}.createIpGroupBtnLabel`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;
export const ipAddressColumnLabel = `${prefix}.ipAddressColumnLabel`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const fileHasNoValidIpAddressesErrorMessage = `${prefix}.fileHasNoValidIpAddressesErrorMessage`;
export const duplicateAddressErrorMessage = `${prefix}.duplicateAddressErrorMessage`;

export const ENGLISH = {
    [editIpGroupModalHeader]: 'Edit IP Group: {0}',
    [newIpGroupModalHeader]: 'New IP Group: {0}',
    [ipGroupNameInputLabel]: 'IP Group Name',
    [ipGroupNameInputPlaceholder]: 'Name',
    [ipInformationHeader]: 'IP Information',
    [selectByIpAddressRadioOption]: 'Select by IP Address',
    [selectByCountryCodeRadioOption]: 'Select by Country Code',
    [selectByEpgRadioOption]: 'Select by EPG',
    [ipAddressInputLabel]: 'IP Address',
    [addButtonLabel]: 'Add',
    [uploadFileButtonLabel]: 'Upload File',
    [countryCodesInputLabel]: 'Country Codes',
    [countryCodesInputPlaceholder]: 'Select Country Code',
    [selectEpgInputPlaceholder]: 'Select EPG',
    [createIpGroupBtnLabel]: 'Create Ip Group',
    [cancelButtonLabel]: 'Cancel',
    [saveButtonLabel]: 'Save',
    [ipAddressColumnLabel]: 'IP Address',
    [removeButtonLabel]: 'Remove',
    [fileHasNoValidIpAddressesErrorMessage]: 'File does not contain any valid IP addresses',
    [duplicateAddressErrorMessage]: 'IP Address, Range, or Mask already exists.',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'waf-psm-match-element-config-form';
const prefix = `${moduleName}.${componentName}`;

export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const subElementInputLabel = `${prefix}.subElementInputLabel`;
export const excludedCheckboxLabel = `${prefix}.excludedCheckboxLabel`;

export const ENGLISH = {
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Select Name',
    [subElementInputLabel]: 'Sub Element',
    [excludedCheckboxLabel]: 'Excluded',
};

/**
 * @module avi/core
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './avi-ajs-confirm.component.less';
import template from './avi-ajs-confirm.component.html';

// Default values for cancel and confirm buttons.
const defaultConfirmButtonText = 'Ok';
const defaultCancelButtonText = 'Cancel';

/**
 * @description
 *
 *     Component that serves as a generic dialog window when users need to be prompted whether they
 *     confirm to proceed with the current operation, with header and body message to be customized.
 *     Different from the ts component avi-confirmation, which provides the dialog prompt
 *     functionality in a broader manner by taking ng-content to give full customization, this
 *     component is powered by avi-confirmation yet with restriction of only allowing header and
 *     message texts to be determined by the parent.
 *
 * @author alextsg, Akul Aggarwal, Zhiqian Liu
 */
class AviAjsConfirmController {
    /**
     * Close modal through AviModal.
     */
    public closeModal: () => void;

    /**
     * Called if user clicks Cancel or closes Modal.
     */
    public onCancel: () => void;

    /**
     * Called if user clicks Confirm.
     */
    public onConfirm: () => void;

    /**
     * Message to be displayed for confirmation.
     */
    public message: string;

    /**
     * Message to be displayed in header.
     */
    public headerText?: string = 'Please Confirm';

    /**
     * Inform if modal is a Warning modal.
     */
    public isWarning?: boolean = false;

    /**
     * Text for Cancel button.
     */
    public cancelButtonText?: string = defaultCancelButtonText;

    /**
     * Text for OK/Confirm button.
     */
    public confirmButtonText?: string = defaultConfirmButtonText;

    /**
     * Confirmation text.
     */
    public confirmationText?: string = 'Would you like to continue?';

    /**
     * Confirm the behavior and close the modal when the Confirm button is clicked.
     */
    public confirm(): void {
        this.onConfirm();
        this.closeModal();
    }

    /**
     * Cancel the behavior and close the modal when the Cancel button is clicked.
     */
    public cancel(): void {
        this.onCancel();
        this.closeModal();
    }
}

export const aviAjsConfirmOptions = {
    bindings: {
        closeModal: '&',
        headerText: '<?',
        isWarning: '<?',
        message: '@',
        onCancel: '&',
        onConfirm: '&',
        cancelButtonText: '<?',
        confirmButtonText: '<?',
        confirmationText: '<?',
    },
    controller: AviAjsConfirmController,
    template,
};

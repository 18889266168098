/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @typedef {Object} PoolGroup
 * @property {PoolGroupMember} members - Pools belonging to the PoolGroup.
 *
 * @typedef {Object} PoolGroupMember
 * @property {number} ratio - Ratio of selecting eligible pools in the pool group.
 * @property {string} prioerity_label - Priority assigned to pools.
 */
angular.module('aviApp').factory('PoolGroup', [
'$q', 'Item', 'Collection', 'Pool', 'poolFailActionService',
function($q, Item, Collection, Pool, poolFailActionService) {
    class PoolGroup extends Item {
        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            config.fail_action = poolFailActionService.dataToSave(config.fail_action);

            const { markers } = config;

            if (markers) {
                // filter out RBAC entries with an empty key
                config.markers = markers.filter(({ key }) => key);

                // delete empty RABC label list
                if (markers.length === 0) {
                    delete config.markers;
                }
            }

            return config;
        }

        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            config.fail_action = poolFailActionService.beforeEdit(config.fail_action);

            const { markers } = config;

            if (!markers) {
                config.markers = [];
            }
        }

        /**
         * Returns the cloud_ref configured on the PoolGroup.
         * @return {string}
         */
        getCloudRef() {
            return this.getConfig()['cloud_ref'];
        }

        /**
         * Making an API call to figure what VRF context is used by the first pool in the list.
         * All pools of the poolGroup are using the same VRF context and poolGroup's config
         * doesn't have vrf_context_uuid property.
         * @returns {Promise} - to be resolved with a string
         */
        getVRFContextRef() {
            const { members } = this.getConfig();

            if (!members || !members.length) {
                return $q.reject('PoolGroup has no member to figure out VRF Context ref');
            }

            const
                [poolConfig] = members,
                { pool_ref: poolRef } = poolConfig;

            // can't use PoolCollection cause that one is inventory (no "fields" param support)
            // we could load single pool as well but such approach has the exact same problem
            const poolCollection = new Collection({
                objectName: 'pool',
                itemClass: Pool,
                limit: 1,
                params: {
                    fields: 'vrf_ref,tenant_ref',
                    uuid: poolRef.slug(),
                },
            });

            const promise = poolCollection.load()
                .then(() => {
                    const [pool] = poolCollection.items;

                    return pool.getVRFContextRef();
                });

            promise.finally(() => poolCollection.destroy());

            return promise;
        }

        /**
         * Updates fail action configuration based on the type passed.
         * @param {PoolFailActionConfig#type|undefined} type
         */
        onFailActionTypeChange(type) {
            const config = this.getConfig();

            poolFailActionService.onTypeChange(config.fail_action, type);
        }

        /**
         * Sort pool members based on priority_label, then by ratio. Used by poolGroupListExpander.
         * @param  {PoolGroupMember[]} members - Members of objects containing pool_ref, ratio, and
         *     priority_label.
         * @return {PoolGroupMember[]} Sorted list of members.
         */
        static sortMembersByPriorityLabel(members) {
            if (angular.isUndefined(members) || !members.length) {
                return [];
            }

            const membersCopy = angular.copy(members);

            return membersCopy.sort((a, b) => {
                const
                    priorityA = +a.priority_label || 0,
                    priorityB = +b.priority_label || 0;

                return priorityA === priorityB ? b.ratio - a.ratio : priorityB - priorityA;
            });
        }

        /**
         * Sort function that sorts two objects with priority_label and ratio properties.
         * @static
         * @param  {Object} a - Object containing 'priority' and optional 'ratio' property.
         * @param  {Object} b - Object containing 'priority' and optional 'ratio' property.
         * @return {number}
         */
        static sortByPriority(a, b) {
            const
                priorityA = _.isNaN(+a.priority) ? 0 : +a.priority,
                priorityB = _.isNaN(+b.priority) ? 0 : +b.priority;

            return priorityA === priorityB ? b.ratio - a.ratio : priorityB - priorityA;
        }

        /**
         * Returns a hash of priority values to Pool Group members.
         * @static
         * @param  {PoolGroup.members} members
         * @return {Object}
         */
        static getPriorityToMembersHash(members = []) {
            return members.reduce((acc, member) => {
                const priority = member.priority_label || '--';

                if (angular.isUndefined(acc[priority])) {
                    acc[priority] = [];
                }

                acc[priority].push(member);

                return acc;
            }, {});
        }

        /**
         * Returns an array of objects containing a priority property and list of Pools with that
         * priority. The list of pools are sorted by ratio.
         * @static
         * @param  {Object} priorityHash - Hash of priority values to Pool Group members.
         * @return {Object[]} List of Pools sorted by priority and ratio.
         */
        static priorityHashToSortedPools(priorityHash) {
            return _.reduce(priorityHash, (acc, members, priority) => {
                acc.push({
                    priority,
                    members: _.sortBy(members, 'ratio'),
                });

                return acc;
            }, []).sort(PoolGroup.sortByPriority);
        }

        /**
         * Converts a list of Pool Group members into a list of Pools sorted by priority then ratio.
         * @param  {PoolGroup.members} members
         * @return {Object[]} List of Pools sorted by priority and ratio.
         */
        static membersToSortedPools(members) {
            const priorityHash = PoolGroup.getPriorityToMembersHash(members);

            return PoolGroup.priorityHashToSortedPools(priorityHash);
        }

        /**
         * Matches pools to the pool_refs within members to be used in unit cards.
         * @param  {PoolGroup.members} members
         * @param  {Pool[]} pools - Array of Pool items.
         * @return {PoolGroup.members}
         */
        static matchPoolsToMembers(members, pools) {
            const poolHash = pools.reduce((acc, pool) => {
                acc[pool.id] = pool;

                return acc;
            }, {});

            if (angular.isArray(members) && members.length) {
                members.forEach(member => member.pool = poolHash[member.pool_ref.slug()]);
            }

            return members;
        }

        /**
         * Sort function used in grid config to sort by priority_label.
         * @param  {PoolGroupMember} a
         * @param  {PoolGroupMember} b
         * @return {number}
         */
        static gridSortByPriority(a, b) {
            const
                priorityA = _.isNaN(+a.priority_label) ? 0 : +a.priority_label,
                priorityB = _.isNaN(+b.priority_label) ? 0 : +b.priority_label;

            return priorityB - priorityA;
        }
    }

    angular.extend(PoolGroup.prototype, {
        objectName: 'poolgroup',
        windowElement: 'pool-group-create',
    });

    return PoolGroup;
}]);

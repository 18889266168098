/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './sslCertificateGridStatusColumn.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const STATUS_GOOD = 'status-good';
const STATUS_EXPIRING = 'status-expiring';
const STATUS_EXPIRED = 'status-expired';
const STATUS_PENDING = 'status-pending';

const dict = {
    SSL_CERTIFICATE_GOOD: STATUS_GOOD,
    SSL_CERTIFICATE_EXPIRY_WARNING: STATUS_EXPIRING,
    SSL_CERTIFICATE_EXPIRED: STATUS_EXPIRED,
    SSL_CERTIFICATE_PENDING: STATUS_PENDING,
    OCSP_UI_STATUS_OK: STATUS_GOOD,
    OCSP_UI_STATUS_WARNING: STATUS_EXPIRING,
    OCSP_UI_STATUS_ERROR: STATUS_EXPIRED,
};

/**
 * @ngdoc component
 * @name  sslCertificateGridStatusColumn
 * @description  Displays the status icon of the SSL Certificate along with a tooltip.
 * @param {Certificate} row - Certificate object.
 */
function Controller(schemaService, l10nService) {
    /**
     * Get keys from source bundles for template usage.
     */
    this.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    this.$onInit = function() {
        this.statusTooltip = require('./certificate-list-status-tooltip.partial.html');
    };

    /**
     * Returns the description string for the certificate's expiry status.
     */
    this.getExpiryStatus = function() {
        const { status } = this.row.getConfig();

        if (status === 'SSL_CERTIFICATE_PENDING') {
            const { description: statusDescription } = schemaService
                .getEnumValue('SSLCertificateStatus', status);

            return statusDescription;
        } else if (status === 'SSL_CERTIFICATE_FINISHED') {
            const {
                enableOcspStapling,
                expiryStatus,
                ocspErrorStatus,
            } = this.row;

            const { description: sslExpiryDescription } = schemaService
                .getEnumValue('SSLCertificateExpiryStatus', expiryStatus);

            if (enableOcspStapling) {
                const { value: ocspErrorStatusType } = this.getOcspUiStatus();
                const { description: ocspErrorStatusDescription } = schemaService
                    .getEnumValue('OCSPErrorStatus', ocspErrorStatus);

                if (expiryStatus === 'SSL_CERTIFICATE_EXPIRED') {
                    return sslExpiryDescription;
                } else if (ocspErrorStatusType === 'OCSP_UI_STATUS_ERROR') {
                    return ocspErrorStatusDescription;
                } else if (expiryStatus === 'SSL_CERTIFICATE_EXPIRY_WARNING') {
                    return sslExpiryDescription;
                }

                return ocspErrorStatusDescription;
            }

            return sslExpiryDescription;
        }
    };

    /**
     * Returns ocsp status for a particular row.
     */
    this.getOcspUiStatus = function() {
        const { ocspErrorStatus } = this.row;
        const ocspUiStatusList = schemaService.getEnumValues('OCSPUiStatus');

        return ocspUiStatusList.find(({ ocsp_ui_status_options: ocspUiStatusOptions }) => {
            const { allowed_ocsp_error_status: allowedOcspErrorStatusList } = ocspUiStatusOptions;

            return allowedOcspErrorStatusList.includes(ocspErrorStatus);
        });
    };

    /**
     * Returns the class name for the certificate's expiry status.
     */
    this.getExpiryStatusClass = function() {
        const { status } = this.row.getConfig();

        if (status === 'SSL_CERTIFICATE_PENDING') {
            return dict[status];
        }

        if (status === 'SSL_CERTIFICATE_FINISHED') {
            const { enableOcspStapling, expiryStatus } = this.row;

            if (enableOcspStapling) {
                const { value: ocspErrorStatusType } = this.getOcspUiStatus();

                if (expiryStatus === 'SSL_CERTIFICATE_EXPIRED') {
                    return dict[expiryStatus];
                } else if (ocspErrorStatusType === 'OCSP_UI_STATUS_ERROR') {
                    return dict[ocspErrorStatusType];
                } else if (expiryStatus === 'SSL_CERTIFICATE_EXPIRY_WARNING') {
                    return dict[expiryStatus];
                }

                return dict[ocspErrorStatusType];
            }

            return dict[expiryStatus];
        }
    };
}

Controller.$inject = [
    'schemaService',
    'l10nService',
];

angular.module('aviApp').component('sslCertificateGridStatusColumn', {
    bindings: {
        row: '<',
    },
    controller: Controller,
    templateUrl: 'src/components/templates/security/ssl-tls-certificates/' +
            'ssl-certificate-grid-status-column/ssl-certificate-grid-status-column.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name authProfilePage
 * @description Component for the Auth Profile page.
 */
class AuthProfilePageController {
    constructor(CRUDGridConfig, AuthProfileCollection, AviModal) {
        this._CRUDGridConfig = CRUDGridConfig;
        this._AuthProfileCollection = AuthProfileCollection;
        this._AviModal = AviModal;
    }

    $onInit() {
        this.gridConfig = new this._CRUDGridConfig();
        this.gridConfig.collection = new this._AuthProfileCollection();

        const { objectName } = this.gridConfig.collection;

        this.gridConfig.id = `${objectName}-list-page`;

        this.gridConfig.fields = [
            {
                name: 'data.config.name',
                title: 'Name',
                sortBy: 'name',
            }, {
                name: 'data.config.type',
                title: 'Type',
                template: '<span>{{ row.data.config.type | enum:"AUTH_PROFILE_" }}</span>',
                sortBy: 'type',
            },
        ];
        this.gridConfig.singleactions.push({
            title: 'Verify',
            class: 'icon-lostlog',
            do: row => {
                this._AviModal.open('prof-auth-profile-verify-ldap', {
                    ldap: row.data.config,
                });
            },
            hidden: row => row.getConfig().type !== 'AUTH_PROFILE_LDAP',
        }, {
            title: 'Verify',
            class: 'icon-lostlog',
            do: row => {
                this._AviModal.open('auth-profile-verify-saml', {
                    config: row.data.config,
                });
            },
            hidden: row => {
                const config = row.getConfig();

                /**
                 * Hide the verification icon for saml profile
                 * with eintity type as User-provided-entity
                 */
                if (config.type === 'AUTH_PROFILE_SAML') {
                    const { sp } = config.saml;

                    return sp.saml_entity_type === 'AUTH_SAML_APP_VS';
                }

                return true;
            },
        });
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

AuthProfilePageController.$inject = [
    'CRUDGridConfig',
    'AuthProfileCollection',
    'AviModal',
];

angular.module('aviApp').component('authProfilePage', {
    controller: AuthProfilePageController,
    template:
        `<div class="avi-main">
            <div class="panel templatesList">
                <collection-grid config="$ctrl.gridConfig"></collection-grid>
            </div>
        </div>`,
});

/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { isUndefined } from 'underscore';
import {
    IMessageItemData,
    MessageItem,
    RepeatedMessageItem,
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model';
import { WafPSMLocationMatchConfigItem } from './waf-psm-location-match.config-item.factory';
import { WafPSMRuleConfigItem } from './waf-psm-rule.config-item.factory';

interface IWafPSMLocationConfig {
    match?: WafPSMLocationMatchConfigItem;
    rules?: RepeatedMessageItem<WafPSMRuleConfigItem>;
}

interface IWafPSMLocationData extends IMessageItemData {
    config: IWafPSMLocationConfig;
}

export class WafPSMLocationConfigItem extends withEditChildMessageItemMixin(MessageItem) {
    public data: IWafPSMLocationData;

    protected readonly windowElement = 'waf-policy-psm-location-modal';

    constructor(args = {}) {
        super({
            objectType: 'WafPSMLocation',
            ...args,
        });
    }

    /**
     * We want to remove 'match' from the default configuration so that the user can add matches
     * manually.
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): IWafPSMLocationConfig {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues.getDefaultItemConfigByType(this.objectType) || {};
        const { match, ...defaults } = defaultConfig;

        return {
            ...defaults,
            match: null,
        };
    }

    /** @override */
    public modifyConfigDataAfterLoad(): void {
        if (isUndefined(this.config.match)) {
            this.setNewChildByField('match');
        }
    }

    /**
     * Returns the name of the location.
     */
    public get name(): string {
        return this.getName();
    }

    /**
     * Returns the number of matches configured.
     */
    public get matchCount(): number {
        return this.config.match.matchCount;
    }

    /**
     * Returns the rules RepeatedMessageItem.
     */
    public get rules(): RepeatedMessageItem<WafPSMRuleConfigItem> {
        return this.config.rules;
    }

    /**
     * Returns the number of rules in the config.
     */
    public get rulesCount(): number {
        return this.config.rules.count;
    }

    /**
     * Creates a new rule and opens the modal to edit it.
     */
    public addRule(modalBindings: Record<string, any>): void {
        this.addChildMessageItem({
            field: 'rules',
            modalBindings,
        });
    }

    /**
     * Edits a WafPSMRule config item.
     */
    public editRule(rule: WafPSMRuleConfigItem, modalBindings: Record<string, any>): void {
        this.editChildMessageItem({
            field: 'rules',
            messageItem: rule,
            modalBindings,
        });
    }
}

WafPSMLocationConfigItem.ajsDependencies = [
    'defaultValues',
];

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'dns-app-profile-domain-names';
const prefix = `${moduleName}.${componentName}`;

export const domainNamesHeader = `${prefix}.domainNamesHeader`;
export const domainNamesInputLabel = `${prefix}.domainNamesInputLabel`;
export const authoritativeDomainNamesHeader = `${prefix}.authoritativeDomainNamesHeader`;
export const serverInputLabel = `${prefix}.serverInputLabel`;
export const adminEmailInputLabel = `${prefix}.adminEmailInputLabel`;
export const adminEmailInputPlaceholder = `${prefix}.adminEmailInputPlaceholder`;

export const ENGLISH = {
    [domainNamesHeader]: 'Domain Names/Subdomains',
    [domainNamesInputLabel]: 'Valid subdomains',
    [authoritativeDomainNamesHeader]: 'Authoritative Domain Names',
    [serverInputLabel]: 'Default Server',
    [adminEmailInputLabel]: 'Default Admin Email',
    [adminEmailInputPlaceholder]: 'hostmaster',
};

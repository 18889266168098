/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    Inject,
    Input,
} from '@angular/core';
import {
    WafAction,
    WafPolicyPsmGroup,
} from 'ajs/modules/waf';
import { SchemaService } from 'ajs/modules/core';
import './waf-policy-psm-group-expander-content.less';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './waf-policy-psm-group-expander-content.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type ActionValueLabelHash = {
    [key in WafAction]: string;
};

/**
 * @ngdoc component
 * @name WafPolicyPsmGroupExpanderContentComponent
 * @module waf/WafPolicyPsmGroupExpanderContentComponent
 * @desc Component for displaying WAF PSM group expander content.
 * @param group - WafPolicyPsmGroup
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-group-expander-content',
    templateUrl: './waf-policy-psm-group-expander-content.component.html',
})
export class WafPolicyPsmGroupExpanderContentComponent {
    @Input() public group: WafPolicyPsmGroup;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    private actionValueLabelHash: ActionValueLabelHash;

    public constructor(
        @Inject(SchemaService) private schemaService: SchemaService,
        l10nService: L10nService,
    ) {
        this.actionValueLabelHash =
            this.schemaService.getEnumValueLabelsHash('WafAction') as ActionValueLabelHash;
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns text to display based on an action enum.
     * @param {string} value - Action enum from WafAction.
     * @returns {string} Text to display from the enum.
     */
    public getActionLabel(value: WafAction): string {
        return this.actionValueLabelHash[value];
    }
}

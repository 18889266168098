/**
 * @module VSModule
 * @preferred
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    OperStateToTypeMap,
    vipListPopoverOptions,
    VirtualServiceCollection,
    virtualServiceCreateBasicController,
    virtualServiceCreateCommonController,
    virtualServiceCreateController,
    vsAddressGridCellOptions,
    vsEnhancedVHChildHostsOptions,
    vsListVirtualHostingCellOptions,
    VsVip,
    vsVipAddressGridCellOptions,
    VsVipCollection,
    VsVipInventoryDataTransformer,
    vsVipListOptions,
} from '.';

const vsModule = angular.module('avi/vs');

const components = [
    {
        name: 'vsEnhancedVhChildHosts',
        options: vsEnhancedVHChildHostsOptions,
    },
    {
        name: 'vsListVirtualHostingCell',
        options: vsListVirtualHostingCellOptions,
    },
    {
        name: 'vsVipAddressGridCell',
        options: vsVipAddressGridCellOptions,
    },
    {
        name: 'vipListPopover',
        options: vipListPopoverOptions,
    },
    {
        name: 'vsAddressGridCell',
        options: vsAddressGridCellOptions,
    },
    {
        name: 'vsVipList',
        options: vsVipListOptions,
    },
];

const factories = [
    {
        dependency: VsVipInventoryDataTransformer,
        name: 'VsVipInventoryDataTransformer',
    },
    {
        dependency: VsVip,
        name: 'VsVip',
    },
    {
        dependency: VsVipCollection,
        name: 'VsVipCollection',
    },
    {
        dependency: VirtualServiceCollection,
        name: 'VirtualServiceCollection',
    },
];

const constants = [{
    name: 'OperStateToTypeMap',
    constant: OperStateToTypeMap,
}];

constants.forEach(({ name, constant }) => vsModule.constant(name, constant));

const controllers = [
    {
        name: 'VirtualServiceCreateController',
        controller: virtualServiceCreateController,
    },
    {
        name: 'VirtualServiceCreateBasicController',
        controller: virtualServiceCreateBasicController,
    },
    {
        name: 'VirtualServiceCreateCommonController',
        controller: virtualServiceCreateCommonController,
    },
];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(
        vsModule,
        'factory',
        name,
        dependency,
    );
});

components.forEach(({ name, options }) => vsModule.component(name, options));

controllers.forEach(({ name, controller }) => vsModule.controller(name, controller));

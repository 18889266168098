/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * Supported match types for AuthenticationMatch message item.
 * @typedef {string[]}
 * @inner
 */
const supportedMatches = ['client_ip', 'path', 'host_hdr'];

const authenticationMatchConfigItemFactory = MatchExtendableConfigItem => {
    /**
     * @constructor
     * @memberOf module:avi/policies/sso
     * @extends module:avi/dataModel.MatchExtendableConfigItem
     * @desc AuthenticationMatch MessageItem class.
     * @author Aravindh Nagarajan
     */
    class AuthenticationMatchConfigItem extends MatchExtendableConfigItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'AuthenticationMatch',
                ...args,
            };

            super(extendedArgs);
        }

        /**
         * Getter for the supported matches.
         * @return {string[]}
         */
        get supportedMatches() {
            return supportedMatches;
        }
    }

    return AuthenticationMatchConfigItem;
};

authenticationMatchConfigItemFactory.$inject = [
    'MatchExtendableConfigItem',
];

angular
    .module('avi/policies/sso')
    .factory('AuthenticationMatchConfigItem', authenticationMatchConfigItemFactory);

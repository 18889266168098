/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../../../less/pages/profile/network-profile.less';

import * as l10n from './NetworkProfileModalController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Network Profile Modal Controller function
 */
function NetworkProfileModalController(
    $scope,
    Regex,
    schemaService,
    l10nService,
) {
    $scope.$parent.modalScope = $scope;//AviModal thing
    $scope.Regex = Regex;
    $scope.protocolTypesEnums = schemaService.getEnumValues('ProtocolType');

    /**
    * Get keys from source bundles for template usage.
    */
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    /**
    * handle protocol type change.
    */
    $scope.onTypeChange = function(selected) {
        $scope.editable.getConfig().profile.type = selected;
        $scope.editable.removeDsrProfile();
    };
}

NetworkProfileModalController.$inject = [
    '$scope',
    'Regex',
    'schemaService',
    'l10nService',
];

/**
 * @ngdoc controller
 * @name  NetworkProfileModalController
 * @description Controller for Network profile modal.
 */
angular.module('aviApp').controller('NetworkProfileModalController', NetworkProfileModalController);

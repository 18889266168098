const moduleName = 'ajs-legacy';
const componentName = 'sensitive-field-rule-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const ruleToggleSwitchTooltip = `${prefix}.ruleToggleSwitchTooltip`;
export const matchHeader = `${prefix}.matchHeader`;
export const actionHeader = `${prefix}.actionHeader`;
export const enableActionInputLabel = `${prefix}.enableActionInputLabel`;
export const submitButtonLabel = `${prefix}.submitButtonLabel`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit Sensitive Field Rule: {0}',
    [modalHeaderNew]: 'New Sensitive Field Rule: {0}',
    [nameInputLabel]: 'Name',
    [ruleToggleSwitchTooltip]: 'Click to {0} this rule',
    [matchHeader]: 'Match',
    [actionHeader]: 'Action',
    [enableActionInputLabel]: 'Enable Action',
    [submitButtonLabel]: 'Submit',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function licenseUsageDataSourceFactory(DataSource) {
    /**
     * @constructor
     * @memberOf module:avi/licensing
     * @extends module:avi/dataModel.DataSource
     * @author Akul Aggarwal
     * @description DataSource to get license summary info.
     */
    class LicenseUsageDataSource extends DataSource {
        constructor(args) {
            angular.extend(args, {
                defaultFields: [
                    {
                        id: 'config',
                        preserved: true,
                        subscribers: ['__default_field'],
                    },
                ],
                defaultParams: {
                    limit: 365,
                    step: 86400,
                },
            });

            super(args);
        }

        /** @override */
        processResponse_({ data }) {
            this.owner_.config = data;
        }

        /** @override */
        getRequestParams_() {
            const params = super.getRequestParams_();

            params.objectName_ = this.owner_.objectName_;

            return params;
        }
    }

    return LicenseUsageDataSource;
}

licenseUsageDataSourceFactory.$inject = ['DataSource'];

angular.module('avi/licensing').factory('LicenseUsageDataSource', licenseUsageDataSourceFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './AlertActionController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('AlertActionController', [
'$scope', '$stateParams', 'Collection', 'AlertActionCollection', 'Regex',
'SnmpProfileCollection', 'CRUDGridConfig', 'ControlScriptCollection', 'l10nService',
function($scope, $stateParams, Collection, AlertActionCollection, Regex,
SnmpProfileCollection, CRUDGridConfig, ControlScriptCollection, l10nService) {
    /**
    * Get keys from source bundles for template usage
    */
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);
    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.gridConfig = new CRUDGridConfig();
    $scope.Regex = Regex;

    // Instantiate collection
    $scope.collection = new AlertActionCollection();

    $scope.EmailCollection = new Collection({
        objectName: 'alertemailconfig',
        windowElement: 'adm-email-create',
    });

    $scope.SyslogCollection = new Collection({
        objectName: 'alertsyslogconfig',
        windowElement: 'adm-syslog-create',
    });

    $scope.controlScriptCollection = new ControlScriptCollection();

    $scope.SnmpProfileCollection = new SnmpProfileCollection();

    /**
     * Configures CollectionGrid directive, works in conjunction with Collection instance
     * instantiated above.
     */
    $scope.gridConfig.collection = $scope.collection;
    $scope.gridConfig.fields = [
        {
            name: 'data.config.name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            sortBy: 'name',
        },
        {
            name: 'level',
            title: l10nService.getMessage(l10nKeys.columnTitleAlertLevel),
            transform(row) {
                return row.getConfig().level.enumeration('ALERT_');
            },
        },
        {
            name: 'syslog_config_ref',
            title: l10nService.getMessage(l10nKeys.columnTitleSyslog),
            transform(row) {
                return row.getConfig().syslog_config_ref ?
                    row.getConfig().syslog_config_ref.name() :
                    l10nService.getMessage(l10nKeys.noneValue);
            },
        },
        {
            name: 'actions.email_config_ref',
            title: l10nService.getMessage(l10nKeys.columnTitleEmail),
            transform(row) {
                return row.getConfig().email_config_ref ?
                    row.getConfig().email_config_ref.name() :
                    l10nService.getMessage(l10nKeys.noneValue);
            },
        },
        {
            name: 'actions.snmp_trap_profile_ref',
            title: l10nService.getMessage(l10nKeys.columnTitleSNMPTrap),
            transform(row) {
                return row.getConfig().snmp_trap_profile_ref ?
                    row.getConfig().snmp_trap_profile_ref.name() :
                    l10nService.getMessage(l10nKeys.noneValue);
            },
        },
        {
            name: 'actions.action_script_config_ref',
            title: l10nService.getMessage(l10nKeys.columnTitleControlScript),
            transform(row) {
                return row.getConfig().action_script_config_ref ?
                    row.getConfig().action_script_config_ref.name() :
                    l10nService.getMessage(l10nKeys.noneValue);
            },
        },
        {
            name: 'external_only',
            title: l10nService.getMessage(l10nKeys.columnTitleExternalOnly),
            template: `
                <span
                    class="sel-automatic"
                    ng-if="row.getConfig().external_only"
                >
                    ${l10nService.getMessage(l10nKeys.yesValue)}
                </span>
                <span
                    class="sel-automatic"
                    ng-if="!row.getConfig().external_only"
                >
                    ${l10nService.getMessage(l10nKeys.noValue)}
                </span>
            `,
        },

    ];

    const { objectName } = $scope.gridConfig.collection;

    $scope.gridConfig.id = `${objectName}-list-page`;

    $scope.$on('$destroy', () => {
        $scope.controlScriptCollection.destroy();
    });
}]);

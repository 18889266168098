/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { UpgradeNodeType, UPGRADE_STATE_POLLING_INTERVAL } from 'ajs/modules/upgrade';

const { NODE_CONTROLLER_CLUSTER } = UpgradeNodeType;

/**
 * @alias module:services/errorPageService
 * @private
 */
class ErrorPageService {
    constructor(Base, AsyncFactory, UpgradeNodeCollection) {
        this.base_ = new Base();
        this.AsyncFactory_ = AsyncFactory;
        this.UpgradeNodeCollection_ = UpgradeNodeCollection;

        this.clusterStateAsyncFactory_ = null;

        /**
         * Collection of UpgradeNode, used to fetch controller node and its upgrade states.
         * @type {UpgradeNodeCollection}
         */
        this.upgradeNodeCollection_ = null;
    }

    /**
     * Returns true if we are currently polling for the cluster state.
     * @returns {boolean}
     */
    get pollingActive() {
        return this.clusterStateAsyncFactory_ && this.clusterStateAsyncFactory_.isActive();
    }

    /**
     * Returns true if the controller upgrade process is active.
     * @returns {boolean}
     */
    get upgradeActive() {
        if (this.upgradeNodeCollection_.hasControllerNode()) {
            const controllerNode = this.upgradeNodeCollection_.getControllerNode();

            return controllerNode.isUpgradeInProgress();
        }

        return false;
    }

    /**
     * Getter for upgrade percentage.
     * @return {number}
     */
    get upgradeProgressPercentage() {
        const controllerNode = this.upgradeNodeCollection_.getControllerNode();

        return controllerNode.progressPercentage;
    }

    /**
     * Start polling for the upgrade state.
     */
    startPollingUpgradeState() {
        this.upgradeNodeCollection_ = new this.UpgradeNodeCollection_(
            {
                updateInterval: UPGRADE_STATE_POLLING_INTERVAL,
                params: {
                    node_type: NODE_CONTROLLER_CLUSTER,
                },
            },
        );

        this.upgradeNodeCollection_.load();
    }

    /**
     * Stop polling for the upgrade state.
     */
    stopPollingUpgradeState() {
        this.upgradeNodeCollection_.destroy();
    }

    /**
     * Begins polling for the cluster state.
     * @param {Function} callback - Function to be called on every successful request for the
     *     cluster state. Callback is called with the cluster status object.
     */
    startPollingClusterState(callback) {
        if (this.pollingActive) {
            this.stopPollingClusterState();
        }

        this.clusterStateAsyncFactory_ = new this.AsyncFactory_(this.getClusterState_(callback), {
            maxSkipped: 1,
            callback: () => this.base_.cancelRequests(),
        });

        this.clusterStateAsyncFactory_.start(9999);
    }

    /**
     * Stops polling for the cluster state.
     */
    stopPollingClusterState() {
        if (this.clusterStateAsyncFactory_) {
            this.clusterStateAsyncFactory_.stop();
        }

        this.base_.cancelRequests();
    }

    /**
     * Request for the cluster state.
     * @param {Function} callback - Function to be called on every successful request for the
     *     cluster state. Callback is called with the cluster status object.
     * @returns {Function}
     */
    getClusterState_(callback) {
        return () => {
            return this.base_.request('get', '/api/cluster/runtime')
                .then(({ data = {} }) => {
                    const { cluster_state: clusterState } = data;

                    callback(clusterState);
                });
        };
    }

    /**
     * Cancels requests for the cluster state as well as the upgrade status.
     */
    cancelRequests() {
        this.stopPollingClusterState();
        this.stopPollingUpgradeState();
    }
}

ErrorPageService.$inject = [
    'Base',
    'AsyncFactory',
    'UpgradeNodeCollection',
];

/**
 * @ngdoc service
 * @name ErrorPageService
 * @author alextsg
 * @module services/errorPageService
 * @desc Service for the errorPage component. Used to make requests to the controller for its
 *     status.
 */
angular.module('aviApp').service('errorPageService', ErrorPageService);

const moduleName = 'ajs-legacy';
const componentName = 'dns-ntp-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleKeyNumber = `${prefix}.columnTitleKeyNumber`;
export const columnTitleAlgorithm = `${prefix}.columnTitleAlgorithm`;
export const columnTitleKey = `${prefix}.columnTitleKey`;
export const columnTitleServer = `${prefix}.columnTitleServer`;
export const emptyDataLabel = `${prefix}.emptyDataLabel`;
export const dnsResolverLabel = `${prefix}.dnsResolverLabel`;
export const dnsSearchDomainLabel = `${prefix}.dnsSearchDomainLabel`;
export const ntpAuthenticationKeysLabel = `${prefix}.ntpAuthenticationKeysLabel`;
export const emptyObjectLabel = `${prefix}.emptyObjectLabel`;
export const ntpServersLabel = `${prefix}.ntpServersLabel`;

export const ENGLISH = {
    [columnTitleKeyNumber]: 'Key Number',
    [columnTitleAlgorithm]: 'Algorithm',
    [columnTitleKey]: 'Key',
    [columnTitleServer]: 'Server',
    [emptyDataLabel]: 'N/A',
    [dnsResolverLabel]: 'DNS Resolver(s)',
    [dnsSearchDomainLabel]: 'DNS Search Domain',
    [ntpAuthenticationKeysLabel]: 'NTP Authentication Keys',
    [emptyObjectLabel]: 'None',
    [ntpServersLabel]: 'NTP Servers',
};

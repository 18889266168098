/**
 * @module UpgradeModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { NodeType } from 'generated-types';
import template from './upgrade-transcript-modal-container.component.html';

/**
 * @description
 *     Container component for UpgradeTranscriptModal component.
 *
 *     As of now, we cannot invoke Angular component from aviModalService (even if its downgraded),
 *     So using this is as a wrapper for UpgradeTranscriptModal.
 *
 * @author Aravindh Nagarajan
 */
class UpgradeTranscriptModalContainerController {
    /**
     * Id of the upgrade node.
     */
    public nodeId: string;

    /**
     * Type of upgrade node (controller/SE Group).
     */
    public nodeType: NodeType;

    /**
     * To close modal.
     */
    public closeModal: () => void;

    /**
     * Handles modal close.
     */
    public handleClose(): void {
        this.closeModal();
    }
}

export const upgradeTranscriptModalContainerComponentOptions = {
    bindings: {
        nodeId: '@',
        nodeType: '@',
        closeModal: '&',
    },
    controller: UpgradeTranscriptModalContainerController,
    template,
};

/** @module WafModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { WafExcludeListEntryConfigItem } from 'ajs/modules/waf';
import * as l10n from './waf-exclude-list-entry-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the WafExcludeListEntry message item modal.
 * @author alextsg
 */
@Component({
    selector: 'waf-exclude-list-entry-modal',
    templateUrl: './waf-exclude-list-entry-modal.component.html',
})
export class WafExcludeListEntryModalComponent {
    /**
     * WafExcludeListEntryConfigItem instance.
     */
    @Input()
    public editable: WafExcludeListEntryConfigItem;

    /**
     * Text to show as the modal header.
     */
    @Input()
    public modalHeader: string;

    /**
     * Called when the user wants to save the modal.
     */
    @Output()
    public onSubmit = new EventEmitter();

    /**
     * Called when the user wants to cancel editing the modal.
     */
    @Output()
    public onCancel = new EventEmitter();

    /**
     * objectType used for schema references in the template.
     */
    public readonly objectType = 'WafExcludeListEntry';

    /**
     * Subnet ngModel value.
     * Need this as an interim solution until IpAddrPrefix message item configuration works
     * properly.
     */
    public clientSubnet: string;

    // Need the following properties as an interim solution in ngTrueValue/ngFalseValue equivalent
    // has been created for Angular.

    /**
     * Case Sensitive checkbox ngModel value for Path.
     */
    public pathCaseSensitive = false;

    /**
     * Regex Match checkbox ngModel value for Path.
     */
    public pathRegexMatch = false;

    /**
     * Case Sensitive checkbox ngModel value for Match Element.
     */
    public matchElementCaseSensitive = false;

    /**
     * Regex Match checkbox ngModel value for Match Element.
     */
    public matchElementRegexMatch = false;

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.clientSubnet = this.editable.clientSubnet.subnet || undefined;

        ({
            pathCaseSensitive: this.pathCaseSensitive,
            pathRegexMatch: this.pathRegexMatch,
            matchElementCaseSensitive: this.matchElementCaseSensitive,
            matchElementRegexMatch: this.matchElementRegexMatch,
        } = this.editable);
    }

    /**
     * Called on subnet ngModelChange. Updates the client subnet messageItem with the subnet value.
     */
    public handleClientSubnetChange(): void {
        this.editable.clientSubnet.subnet = this.clientSubnet;
    }

    /**
     * Handler for saving the modal.
     */
    public handleSubmit(): void {
        this.onSubmit.emit();
    }

    /**
     * Handler to cancel editing the modal.
     */
    public handleCancel(): void {
        this.onCancel.emit();
    }
}

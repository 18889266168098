/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc controller
 * @name  WebhookController
 * @description
 *     Controller for Webhook List view.
 */
class WebhookController {
    constructor(CRUDGridConfig, WebhookCollection) {
        this.collection = new WebhookCollection();

        const { objectName } = this.collection;

        const config = {
            id: `${objectName}-list-page`,
            collection: this.collection,
            fields: [{
                name: 'data.config.name',
                title: 'Name',
            }, {
                name: 'data.config.callback_url',
                title: 'Callback URL',
            }, {
                name: 'data.config.verification_token',
                title: 'Verification Token',
            }],
        };

        this.gridConfig = CRUDGridConfig(config);
    }

    $onDestroy() {
        this.collection.destroy();
    }
}

WebhookController.$inject = [
        'CRUDGridConfig',
        'WebhookCollection',
];

angular.module('aviApp').controller('WebhookController', WebhookController);

/**
 * @module SystemUpdateModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Type,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { Subject } from 'rxjs';
import { AviDatePipe, BooleanLabelPipe } from 'ng/shared/pipes';
import { DialogService } from 'ng/modules/core';
import { InitialDataService } from 'ajs/modules/core';
import { fipsOperationalHash } from 'ajs/modules/upgrade';
import {
    ISystemConfigurationComplianceMode,
    SystemConfig,
} from 'ajs/modules/system';

import {
    ComplianceModeConfirmationComponent,
    IComplianceModeConfig,
    IComplianceModeConfirmationStatus,
} from '../compliance-mode-confirmation';

import * as l10n from './system-update-about-card.l10n';
import './system-update-about-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const COMPLIANCE_MODE_CONFIRMATION_DIALOG_ID = 'compliance-mode-confirmation';

interface IRollbackOption {
    label: string;
    title: string;
    value: string;
}

/**
 * @description Component containing information about the system.
 * @author alextsg
 */
@Component({
    selector: 'system-update-about-card',
    templateUrl: './system-update-about-card.component.html',
})
export class SystemUpdateAboutCardComponent implements OnInit {
    /**
     * List of Rollback options for the dropdown button.
     */
    @Input()
    public rollbackOptions: IRollbackOption[] = [];

    /**
     * If true, shows the rollback dropdown button.
     */
    @Input()
    public enableRollback = false;

    /**
     * SystemConfig Item instance. Used to get the fips_mode and common_criteria_mode configuration
     * flags.
     */
    @Input()
    public systemConfig: SystemConfig;

    /**
     * FIPS operation flag. If false, it means that FIPS is currently not operational based on the
     * runtime status.
     */
    @Input()
    public fipsOperational: string;

    /**
     * Emitted when a rollback option is selected.
     */
    @Output()
    public onSelectRollbackOption = new EventEmitter<string>();

    /**
     * Controller build number.
     */
    public controllerBuild: number;

    /**
     * Controller version.
     */
    public controllerVersion: string;

    /**
     * Controller patch.
     */
    public controllerPatch: string;

    /**
     * Controller build date.
     */
    public controllerBuildDate: string;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly initialDataService: InitialDataService,
        private readonly aviDatePipe: AviDatePipe,
        private readonly dialogService: DialogService,
        private readonly booleanLabelPipe: BooleanLabelPipe,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const {
            controllerBuild,
            controllerPatch,
            controllerBuildDate,
            controllerVersion,
        } = this.initialDataService;

        this.controllerBuild = controllerBuild;
        this.controllerPatch = controllerPatch;
        this.controllerVersion = controllerVersion;
        this.controllerBuildDate = this.aviDatePipe.transform(controllerBuildDate);
    }

    /**
     * Called when a rollback option is selected.
     */
    public handleSelectRollbackOption(option: IRollbackOption): void {
        this.onSelectRollbackOption.emit(option.value);
    }

    /**
     * Returns the className to be set on the FIPS mode input element. Used to set the color of the
     * value - green for "Enabled" and red for "Disabled".
     */
    public get fipsModeSettingClassName(): string {
        return this.getComplianceModeSettingClassName(this.fipsMode);
    }

    /**
     * Returns the className to be set on the Common Criteria mode input element. Used to set the
     * color of the value - green for "Enabled" and red for "Disabled".
     */
    public get commonCriteriaModeSettingClassName(): string {
        return this.getComplianceModeSettingClassName(this.commonCriteriaMode);
    }

    /**
     * Used as the trackBy function for the rollback dropdown button options.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Called when the Compliance Mode button is clicked. Opens the Compliance Mode dialog
     * component.
     */
    public handleClickComplianceMode(): void {
        const complianceModeStatusSubject = new Subject<IComplianceModeConfirmationStatus>();

        this.dialogService.add({
            id: COMPLIANCE_MODE_CONFIRMATION_DIALOG_ID,
            component: ComplianceModeConfirmationComponent as Type<Component>,
            componentProps: {
                overrideEnableFips: this.fipsMode,
                overrideEnableCommonCriteria: this.commonCriteriaMode,
                status$: complianceModeStatusSubject.asObservable(),
                onSubmit: this.getHandleComplianceModeSubmit(complianceModeStatusSubject),
                onClose: () => {
                    this.dialogService.remove(COMPLIANCE_MODE_CONFIRMATION_DIALOG_ID);
                },
            },
        });
    }

    /**
     * Returns true if the Compliance Mode button should be shown.
     */
    public get showComplianceModeButton(): boolean {
        return !this.fipsMode ||
            !this.commonCriteriaMode ||
            this.fipsOperational === fipsOperationalHash.FIPS_FALSE;
    }

    /**
     * Returns the text to be displayed for the FIPS field.
     */
    public get fipsModeLabel(): string {
        return this.getComplianceModeSettingLabel(this.fipsMode);
    }

    /**
     * Returns the text to be displayed for the Common Criteria field.
     */
    public get commonCriteriaModeLabel(): string {
        return this.getComplianceModeSettingLabel(this.commonCriteriaMode);
    }

    /**
     * Returns true if the card footer should be shown.
     */
    public get showFooter(): boolean {
        return this.enableRollback || this.showComplianceModeButton;
    }

    /**
     * Returns the fips_mode property from the system configuration.
     */
    private get fipsMode(): boolean {
        return this.systemConfig.getConfig().fips_mode;
    }

    /**
     * Returns the common_criteria_mode property from the system configuration.
     */
    private get commonCriteriaMode(): boolean {
        return this.systemConfig.getConfig().common_criteria_mode;
    }

    /**
     * Returns a function passed to the Compliance Mode dialog as the onSubmit callback. Makes a
     * request to configure FIPS mode and closes the dialog if successful. On failure, updates the
     * complianceModeStatusSubject with an error state.
     */
    private getHandleComplianceModeSubmit(
        complianceModeStatusSubject: Subject<IComplianceModeConfirmationStatus>,
    ): (config: IComplianceModeConfig) => void {
        return (config: IComplianceModeConfig) => {
            const requestConfig: ISystemConfigurationComplianceMode = {
                fips_mode: config.enableFips,
                common_criteria_mode: config.enableCommonCriteria,
                // Force Compliance mode recovery in errored state. True if fipsMode was already
                // configured but is nonoperational.
                force: this.fipsOperational === fipsOperationalHash.FIPS_FALSE && this.fipsMode,
            };

            complianceModeStatusSubject.next({
                busy: true,
                error: null,
            });

            this.systemConfig.enableFipsMode(requestConfig)
                .then(() => {
                    this.dialogService.remove(COMPLIANCE_MODE_CONFIRMATION_DIALOG_ID);
                    complianceModeStatusSubject.next({
                        busy: false,
                        error: null,
                    });
                })
                .catch(({ data }) => {
                    complianceModeStatusSubject.next({
                        busy: false,
                        error: data.error,
                    });
                });
        };
    }

    /**
     * Returns the className to be set on the input element for either the FIPS mode or the Common
     * Criteria mode. Used to set the color of the value - green for "Enabled" and gray for
     * "Not Enabled".
     */
    private getComplianceModeSettingClassName(setting: boolean): string {
        const baseClassName = 'system-update-about-card__compliance-mode-setting';
        const notEnabledClassName = `${baseClassName}--not-enabled`;

        if (this.fipsOperational === fipsOperationalHash.FIPS_UNKNOWN) {
            return notEnabledClassName;
        }

        return setting ? `${baseClassName}--enabled` : notEnabledClassName;
    }

    /**
     * Returns the label to be shown for the FIPS mode or Common Criteria mode setting.
     */
    private getComplianceModeSettingLabel(setting: boolean): string {
        return this.fipsOperational === fipsOperationalHash.FIPS_UNKNOWN ?
            this.l10nService.getMessage(l10nKeys.inProgressLabel) :
            this.booleanLabelPipe.transform(setting, 'enabled');
    }
}

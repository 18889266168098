/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './geoloc-select.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const GeoLocSelectController = class {
    constructor(GeoProfileCollection, l10nService) {
        this.GeoProfileCollection = GeoProfileCollection;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this.setGeoLocSourceOptions_();
    }

    $onDestroy() {
        if (this.profileCollection_) {
            this.profileCollection_.destroy();
        }
    }

    /**
     * Decides which options are available for user to select for Geo Loc Sources.
     * @protected
     */
    setGeoLocSourceOptions_() {
        this.geoLocationOptions = [];
        //Options for geoLocation found in Schema.enums.GslbGeoLocationSource.values
        this.geoLocationOptions.push('GSLB_LOCATION_SRC_USER_CONFIGURED');

        if (this.isPoolMember) {
            this.profileCollection_ = new this.GeoProfileCollection({ limit: 1 });

            this.geoLocationOptions
                .push('GSLB_LOCATION_SRC_INHERIT_FROM_SITE');

            this.profileCollection_.load()
                .then(() => {
                    if (this.profileCollection_.getTotalNumberOfItems()) {
                        this.geoLocationOptions
                            .push('GSLB_LOCATION_SRC_FROM_GEODB');
                    }
                });
        }
    }

    /**
     * Resets location params on location source change event.
     * @public
     */
    onSourceChange() {
        this.site.location.location = {};
    }
};

GeoLocSelectController.$inject = [
    'GeoProfileCollection',
    'l10nService',
];

/**
 * @ngdoc component
 * @name geolocSelect
 * @param {GslbPoolMemberConfig|GslbSiteConfig|GslbThirdPartySiteConfig} site
 * @param {boolean=} isPoolMember
 * @description
 *
 *      GSLB Geo Location selector.
 */
angular.module('aviApp').component('geolocSelect', {
    bindings: {
        site: '<',
        isPoolMember: '<?',
    },
    controller: GeoLocSelectController,
    templateUrl: 'src/components/infrastructure/gslb/geoloc-select/geoloc-select.html',
});

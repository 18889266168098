/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './ClusterListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name ClusterListController
 */
angular.module('aviApp').controller('ClusterListController', [
'$scope',
'ClusterNodeCollection',
'Cluster',
'l10nService',
function(
    $scope,
    ClusterNodeCollection,
    Cluster,
    l10nService,
) {
    const collection = new ClusterNodeCollection();

    const { objectName } = collection;

    /**
    * Get keys from source bundles for template usage
    */
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    $scope.gridConfig = {
        id: `${objectName}-list-page`,
        collection,
        fields: [{
            require: 'config',
            name: 'name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            template: '{{row.data.config.name}}',

        }, {
            name: 'management_ip',
            title: l10nService.getMessage(l10nKeys.columnTitleManagementHostnames),
            template: '{{row.data.config.ip}}',
        }, {
            name: 'virtual_ip',
            title: l10nService.getMessage(l10nKeys.columnTitleClusterIp),
            template: '{{row.data.config.cluster_ip}}',
        }, {
            name: 'role',
            title: l10nService.getMessage(l10nKeys.columnTitleRole),
            template: '{{row.data.runtime.role.enumeration("CLUSTER_")}}',
        }, {
            name: 'state',
            title: l10nService.getMessage(l10nKeys.columnTitleState),
            require: 'runtime',
            template: '<span ng-if="row.data.runtime"><i class="icon-circle" ' +
                'ng-class="row.data.runtime.state === \'CLUSTER_ACTIVE\' ? \'active\' :' +
                '\'inactive\'"></i>{{row.data.runtime.state.enumeration("CLUSTER_")}}</span>' +
                '<span avi-loader ng-if="!row.data.runtime"></span>',
        }],
        layout: {
            hideSearch: true,
            hideEditColumns: true,
            lengthAsTotal: true,
        },
    };

    //reload collection on Cluster successful save event
    $scope.Cluster = new Cluster({
        id: 'default', //fake Id
        bind: {
            itemSaveSuccess() {
                collection.load();
            },
        },
    });

    $scope.$on('$destroy', () => {
        collection.destroy();
        $scope.Cluster.destroy();
    });
}]);

/**
 * Module for all things gslb related.
 * @preferred
 * @module GslbModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    ClrFormsModule,
    ClrInputModule,
    ClrTextareaModule,
} from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';
import { GslbCheckpointsModalComponent } from '.';

const gslbComponents = [
    GslbCheckpointsModalComponent,
];

@NgModule({
    declarations: [
        ...gslbComponents,
    ],
    imports: [
        CommonModule,
        ClrFormsModule,
        ClrInputModule,
        ClrTextareaModule,
        FormsModule,
        SharedModule,
    ],
})
export class GslbModule {}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './gslbsite-dns-vs-selector.less';

/**
 * @ngdoc component
 * @name gslbsiteDnsVsSelector
 * @description
 *
 *     Component to select DNS VSes per subdomain or default (for all subdomains wo
 *     corresponding matches).
 */
const componentName = 'gslbsite-dns-vs-selector';

class GslbSiteDNSVSSelector {
    constructor(
            $element,
            GSLBVSCollection,
            VirtualService,
            defaultValues,
            systemInfo,
    ) {
        this.$element = $element;
        this.systemInfo_ = systemInfo;
        this.GSLBVSCollection = GSLBVSCollection;

        this.dnsAppProfileRef_ =
                defaultValues.getSystemObjectRefByName('applicationprofile', 'System-DNS');

        this.udpNetProfileRef_ =
                defaultValues.getSystemObjectRefByName('networkprofile', 'System-UDP-Per-Pkt');

        this.dnsServiceDefaultConfig_ = VirtualService.getDefaultServiceConfig({ port: 53 });
    }

    $onInit() {
        this.$element.addClass(componentName);

        /**
         * List of subdomains used by this GSLB.
         * @type {string[]}
         */
        this.domains = this.gslb.getDNSDomainNames(true);

        const { cluster_uuid: clusterUuid } = this.site;

        /**
         * Only when editing local gslbSite we can create a new VS using the DNS dropdown.
         * @type {boolean}
         * @public
         */
        this.isLocalVSCollection = clusterUuid === this.systemInfo_.localClusterId;

        /**
         * VirtualService collection for the DNS VS dropdown. Can go to remote GSLB controller
         * cluster by setting a specific header parameter.
         * @type {Collection}
         * @public
         */
        this.DNSVSCollection = new this.GSLBVSCollection({
            gslbSiteId: clusterUuid,
            gslbTenant: this.gslb.getTenantId(),
            defaults: {
                application_profile_ref: this.dnsAppProfileRef_,
                network_profile_ref: this.udpNetProfileRef_,
                services: [this.dnsServiceDefaultConfig_],
            },
            params: {
                fields: 'name,tenant_ref',
                'application_profile_ref.type': 'APPLICATION_PROFILE_TYPE_DNS',
                headers_: { 'X-Avi-Internal-All-Tenants': true },
            },
        });

        const { ngModelCtrl } = this;

        ngModelCtrl.$isEmpty = this.ngModelIsEmpty_;

        this.ngModelCtrl.$parsers.push(this.handleDNSVSchanges_.bind(this));
        this.ngModelCtrl.$formatters.push(this.handleDNSVSchanges_.bind(this));
    }

    /**
     * Generates list of already existing VSIDs.
     * @param {Object[]} viewValues - list of vals displayed in UI from ngModelCtrl
     * @returns {string[]} - existing VSIDs
     * @private
     */
    getVSIDs_(viewValues) {
        return viewValues.map(val => val.dns_vs_uuid.slug());
    }

    /**
     * Handles changes on view or model for DNS VS's.
     * @param {Object[]} viewValues - list of vals displayed in UI from ngModelCtrl
     * @private
     */
    handleDNSVSchanges_(values) {
        if (values === undefined) {
            values = [];
        }

        this.updateListFilters_(this.getVSIDs_(values));

        return values;
    }

    /**
     * Updates parameters for collection to avoid displaying already selected VS's.
     * @param {string[]} idList - VSIDs already existing
     * @private
     */
    updateListFilters_(idList) {
        this.DNSVSCollection.setParams({
            exclude: 'uuid.in',
            'uuid.in': idList.join(),
        });
    }

    $onDestroy() {
        this.DNSVSCollection.destroy();
    }

    /**
     * Updates modal value according to the viewValue. Won't see changes otherwise cause we
     * mess with inner properties, not reference itself.
     */
    onViewValueChange() {
        const { ngModelCtrl } = this;

        ngModelCtrl.$setViewValue(angular.copy(ngModelCtrl.$viewValue));
    }

    /**
     * Adds DNS VS to the list.
     * @public
     */
    addDNSVS() {
        this.ngModelCtrl.$viewValue.push(
            this.gslb.getDefaultGslbSiteDNSVSConfig(),
        );

        this.onViewValueChange();
    }

    /**
     * Removes DNS VS which index has been passed from the list.
     * @param {number} index
     * @public
     */
    removeDNSVS(index) {
        this.ngModelCtrl.$viewValue.splice(index, 1);

        this.onViewValueChange();
    }

    /**
     * Considered empty when there is no single VS set.
     * @param {Object[]} viewValue
     * @returns {boolean}
     * @private
     */
    ngModelIsEmpty_(viewValue) {
        return !viewValue || !_.any(viewValue, ({ dns_vs_uuid: vsId }) => vsId);
    }
}

GslbSiteDNSVSSelector.$inject = [
    '$element',
    'GSLBVSCollection',
    'VirtualService',
    'defaultValues',
    'systemInfoService',
];

angular.module('avi/gslb').component('gslbsiteDnsVsSelector', {
    require: {
        ngModelCtrl: 'ngModel',
    },
    bindings: {
        site: '<',
        gslb: '<',
    },
    controller: GslbSiteDNSVSSelector,
    templateUrl:
            'src/components/gslb/forms/gslbsite-dns-vs-selector/gslbsite-dns-vs-selector.html',
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * All events related stuff - items, collection, controllers, etc.
 * Includes event list pages under VS, Pool, administration and operations.
 * @module avi/events
 */

import { sha1 } from 'object-hash';

function eventFactory(Item) {
    /**
     * @class
     * @constructor
     * @memberOf module:avi/events
     * @extends module:avi/dataModel.Item
     * @desc Event item.
     */
    class Event extends Item {
        constructor(oArgs = {}) {
            oArgs.objectName = oArgs.objectName || 'Event';
            super(oArgs);
        }

        /**
         * Hash of event type to corresponding property name with event details
         * @protected
         */
        static configTypes_ = {
            CONFIG_CREATE: 'config_create_details',
            CONFIG_UPDATE: 'config_update_details',
            CONFIG_DELETE: 'config_delete_details',
            USER_LOGIN: 'config_user_login_details',
            USER_LOGOUT: 'config_user_logout_details',
        };

        /**
         * Function to get ID out of Event#config. Expensive call!
         * @param {Object} config - Event#data#config.
         * @returns {string} - event id.
         */
        static getIdFromData({ config }) {
            return sha1(config);
        }

        /** @override */
        getIdFromData_(data) {
            return data && data.config && Event.getIdFromData(data) || '';
        }

        /**
         * Function to get the username of events configured in configTypes_.
         * @returns {string} - userName
         */
        getUserName() {
            const eventDetails = this.getEventDetails();
            const eventType = this.getType();
            const propName = Event.configTypes_[eventType];

            return propName ? eventDetails[propName].user : '';
        }

        /**
         * Returns the event type of each event.
         * @returns {string} - event type
         */
        getType() {
            return this.getConfig().event_id;
        }

        /**
         * Returns the event_details of each event.
         * @returns {Object} - event_details
         */
        getEventDetails() {
            return this.getConfig().event_details;
        }
    }

    return Event;
}

eventFactory.$inject = [
    'Item',
];

angular.module('avi/events')
    .factory('Event', eventFactory);

/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    AfterViewInit,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import {
    IAviDataGridConfig,
    IAviDataGridConfigField,
    IAviDataGridLayout,
    IAviDataGridMultipleaction,
    IAviDataGridRow,
    IAviDataGridSingleaction,
} from './avi-data-grid.types';
import './avi-data-grid.component.less';
import * as l10n from './avi-data-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const defaultLayout: IAviDataGridLayout = {
    showFooter: false,
};

/**
 * Calls either an action on a list of rows (multipleaction) or a single row (singleaction). We use
 * a try-catch here because if we were to think about AviDataGrid as a component from a third-party
 * library, we wouldn't want to rely on action.onClick being error-free and have the component break
 * down if it fails.
 */
const callAction = (
    action: IAviDataGridSingleaction | IAviDataGridMultipleaction,
    rows: IAviDataGridRow | IAviDataGridRow[],
): void => {
    try {
        action.onClick(rows);
    } catch (error) {
        throw new Error(`action failed: ${error}`);
    }
};

/**
 * @description
 *     Grid component, created as a wrapper around Clarity's Datagrid component.
 *
 *     When using custom templates for cells, we need to use templateRefs as creating a template
 *     from a string is not supported in Angular. This means that the parent component needs to
 *     create a templateRef variable, then pass it into config.fields in ngAfterViewInit. The outlet
 *     context contains the row and the index.
 *
 *     Please refer to avi-data-grid.stories.ts for an example.
 *
 *     Note: Clarity's datagrid has an issue with displaying columns when rows are empty. If you try
 *     to update fields while rows is an empty array, those fields will not get displayed. As a
 *     workaround, we use the afterViewInit flag to render the clarity datagrid, to allow the parent
 *     component to update its fields in its own ngAfterViewInit hook.
 *
 * @author alextsg
 */
@Component({
    selector: 'avi-data-grid',
    templateUrl: './avi-data-grid.component.html',
})
export class AviDataGridComponent implements OnInit, AfterViewInit, OnChanges {
    /**
     * Grid configuration object containing getRowId, multipleactions, etc.
     */
    @Input() public config: IAviDataGridConfig;

    /**
     * Data to be displayed in the grid.
     */
    @Input() public rows: IAviDataGridRow[] = [];

    /**
     * List of selected rows.
     */
    public selected: IAviDataGridRow[] | undefined;

    /**
     * Boolean set to true after the ngAfterViewInit hook has been called, used as a workaround to
     * fix Clarity datagrid issues.
     */
    public afterViewInit = false;

    /**
     * If true, displays the singleactions column.
     */
    public hasSingleactions = false;

    /**
     * Width of the singleactions column in pixels.
     */
    public singleactionsWidth = 0;

    /**
     * If true, shows the column containing the expander icon.
     */
    public showExpander = false;

    /**
     * Width in pixels to be used for icon cells.
     */
    public readonly iconWidth = 40;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Map for keeping track of which rows have been expanded, based on the rowId.
     */
    private readonly expandedRows = new Map<string | number, boolean>();

    public constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.selected = this.getDefaultSelected();
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.setDisplayProps();

        this.afterViewInit = true;
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { config } = changes;

        if (config) {
            if (config.firstChange) {
                return;
            }

            this.setDisplayProps();
        }
    }

    /**
     * Returns a list of column header fields.
     */
    public get fields(): IAviDataGridConfigField[] {
        return this.config?.fields || [];
    }

    /**
     * Returns a list of multipleactions - actions to be performed on selected data.
     */
    public get multipleactions(): IAviDataGridMultipleaction[] {
        return this.config?.multipleactions || [];
    }

    /**
     * Returns either the configured layout or the default layout.
     */
    public get layout(): IAviDataGridLayout {
        return this.config?.layout || { ...defaultLayout };
    }

    /**
     * Calls the action and clears selected rows.
     */
    public callMultipleaction(action: IAviDataGridMultipleaction): void {
        callAction(action, this.selected);
        this.selected = this.getDefaultSelected();
    }

    /**
     * Calls a singleaction on a row.
     */
    public callSingleaction(action: IAviDataGridSingleaction, row: IAviDataGridRow): void {
        callAction(action, row);
    }

    /**
     * trackBy function for each field.
     */
    public trackByFieldId(index: number, field: IAviDataGridConfigField): string {
        return field.id;
    }

    /**
     * trackBy function for row data.
     */
    public trackByRowId = (index: number, row: IAviDataGridRow): string | number => {
        return this.config?.getRowId(index, row);
    };

    /**
     * Called to get the value of disabled callback function on single-actions, if present.
     * Else do not disable actions.
     */
    public isSingleActionDisabled(action: IAviDataGridSingleaction, row: IAviDataGridRow): boolean {
        if (action.disabled) {
            return action.disabled(row);
        } else {
            return false;
        }
    }

    /**
     * Called to get the value of disabled callback function on multi-actions, if present.
     * Else do not disable actions.
     */
    public isMultipleActionDisabled(action: IAviDataGridMultipleaction): boolean {
        if (action.disabled) {
            return action.disabled(this.selected);
        } else {
            return false;
        }
    }

    /**
     * trackBy function for actions.
     */
    public trackByActionLabel(index: number, action: IAviDataGridMultipleaction): string {
        return action.label;
    }

    /**
     * Returns a placeholder message to be displayed if there are no items to display.
     */
    public get placeholderMessage(): string {
        return this.layout.placeholderMessage || 'We couldn’t find any objects!';
    }

    /**
     * Sets the expanded state on the expandedRows Map.
     */
    public toggleExpanded(rowIndex: number, row: IAviDataGridRow): void {
        const expanded = this.isExpanded(rowIndex, row);

        this.expandedRows.set(this.config.getRowId(rowIndex, row), !expanded);
    }

    /**
     * Retrieves the expanded state from the expandedRows Map.
     */
    public isExpanded(rowIndex: number, row: IAviDataGridRow): boolean {
        return Boolean(this.expandedRows.get(this.config.getRowId(rowIndex, row)));
    }

    /**
     * Returns the default value to use for the selected property, which is used by clr-datagrid.
     * If undefined, the checkboxes will not be rendered.
     */
    private getDefaultSelected(): IAviDataGridRow[] | undefined {
        return this.layout.hideCheckboxes ? undefined : [];
    }

    /**
     * Sets datagrid display properties based on the config. Called in initialization as well as on
     * config changes.
     */
    private setDisplayProps(): void {
        if (!this.config) {
            return;
        }

        const { singleactions = [], expandedContentTemplateRef } = this.config;
        const { length: singleactionsLength } = singleactions;

        this.singleactionsWidth = singleactionsLength * this.iconWidth;
        this.hasSingleactions = Boolean(singleactionsLength);
        this.showExpander = Boolean(expandedContentTemplateRef);
    }
}

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../../less/components/top-list-timing-section.less';

angular.module('aviApp').directive('topListTimingSection', function() {
    return {
        templateUrl: 'src/views/components/top-list-timing-section.html',
        restrict: 'AE',
        scope: true,
        link(scope, elm, attr) {
            scope.barheight = 10;

            if (attr.urlTimingSection) {
                scope.urlTimingSection = true;
            }
        },
    };
});

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/** @alias samlSettings */
class SamlSettingsController {
    constructor(
        schemaService,
        Regex,
    ) {
        this.Regex = Regex;
        this.schemaService = schemaService;
    }

    /** @override */
    $onInit() {
        this.types = this.schemaService.getEnumValues('AuthSamlEntityType');
    }

    /**
     * Check SAML entity type is of a particular one.
     * @param {string} type
     * @return {boolean}
     */
    isSamlEntityType(type) {
        return this.saml.sp.saml_entity_type === type;
    }
}

SamlSettingsController.$inject = [
    'schemaService',
    'Regex',
];

/**
 * @ngdoc component
 * @name samlSettings
 * @param {Object} saml - SAML config object (message SamlSettings)
 * @param {Function} onTypeChange - SAML type change event handler. "Type" variable
 *     should be passed with AuthSamlEntityType enum to be set.
 * @param {boolean} editMode - Disables SAML entity type change when true is passed.
 * @desc
 *     Configuration form for SAML Settings object (message SamlSettings).
 */
angular.module('aviApp').component('samlSettings', {
    bindings: {
        saml: '<',
        onTypeChange: '&',
        editMode: '<',
    },
    controller: SamlSettingsController,
    templateUrl: 'src/components/common/saml-settings/saml-settings.component.html',
});

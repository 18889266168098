/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'shared';
const componentName = 'async-file-upload-service';
const prefix = `${moduleName}.${componentName}`;

export const uploadCompleteStatusLabel = `${prefix}.uploadCompleteStatusLabel`;
export const processingStatusLabel = `${prefix}.processingStatusLabel`;

export const ENGLISH = {
    [uploadCompleteStatusLabel]: 'Upload Complete',
    [processingStatusLabel]: 'Processing...',
};

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function authProfileCollectionFactory(Collection, AuthProfile) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Collection
     * @author Ram Pal
     * @desc Collection of {@link module:avi/app.AuthProfile AuthProfiles}.
     */
    class AuthProfileCollection extends Collection {}

    Object.assign(AuthProfileCollection.prototype, {
        objectName_: 'authprofile',
        itemClass_: AuthProfile,
        windowElement_: 'prof-auth-profile-create',
    });

    return AuthProfileCollection;
}

authProfileCollectionFactory.$inject = [
    'Collection',
    'AuthProfile',
];

angular.module('avi/app')
    .factory('AuthProfileCollection', authProfileCollectionFactory);

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './authentication-policy.component.less';

import { SSOPolicyTypes } from '../../../../js/services/items/SSOPolicy';

//TODO import these from AuthProfile
const AUTH_PROFILE_TYPE_SAML = 'AUTH_PROFILE_SAML';
const AUTH_PROFILE_TYPE_PINGACESS = 'AUTH_PROFILE_PINGACCESS';

/**
 * Hash of auth profile type by SSO policy type.
 * @type {Object.<string, string>}
 * @private
 */
const authProfileTypeBySsoPolicyTypeHash = {
    [SSOPolicyTypes.SAML]: AUTH_PROFILE_TYPE_SAML,
    [SSOPolicyTypes.PINGACCESS]: AUTH_PROFILE_TYPE_PINGACESS,
};

const componentName = 'authentication-policy';

/**
 * @constructor
 * @memberOf module:avi/policies/sso
 * @mixes module:avi/policies/sso.authenticationPolicyComponentBindings
 * @see {@link module:avi/policies/sso.authenticationPolicyComponent}
 */
class AuthenticationPolicyController {
    constructor(AuthProfileCollection, AviModal) {
        this.authProfileCollection = new AuthProfileCollection();
        this.AviModal_ = AviModal;
    }

    /** @override */
    $onChanges(changes) {
        const { type: { currentValue: SSOPolicyType } } = changes;

        const isFirstChange = _.sample(changes).isFirstChange();

        if (SSOPolicyType) {
            this.onTypeChange_(SSOPolicyType, isFirstChange);
        }
    }

    /**
     * Updates modal state based on the type passed.
     * @param {string} type - SSOPolicyType enum.
     * @param {boolean} [isFirstChange=false] - True when its first change
     * @protected
     */
    onTypeChange_(type, isFirstChange = false) {
        const authProfileType = authProfileTypeBySsoPolicyTypeHash[type];
        const { authProfileCollection } = this;

        authProfileCollection.setParams({
            type: authProfileType,
        });

        authProfileCollection.setDefaultItemConfigProps({
            type: authProfileType,
        });

        if (!isFirstChange) {
            this.policy.clearDefaultAuthProfile();
        }
    }

    /**
     * Creates a new rule and opens the modal to edit it.
     */
    addAuthRule() {
        this.editRule(this.policy.createNewRule());
    }

    /**
     * Returns the number of rules in policy.
     * @return {number}
     */
    get totalRuleCount() {
        return this.policy.totalRuleCount;
    }

    /**
     * Edits a WafPSMRule config item.
     * @param {module:avi/policies/sso.AuthenticationRuleConfigItem} rule
     */
    editRule(rule) {
        this.AviModal_.open('authentication-rule-modal', {
            rule: rule.clone(),
            onSubmit: ['rule', rule => {
                this.policy.addRule(rule);
            }],
        });
    }

    /** @override */
    $onDestroy() {
        this.authProfileCollection.destroy();
    }
}

AuthenticationPolicyController.$inject = [
    'AuthProfileCollection',
    'AviModal',
];

/**
 * @name authenticationPolicyComponent
 * @memberOf module:avi/policies/sso
 * @property {module:avi/policies/sso.AuthenticationPolicyController} controller
 * @property {module:avi/policies/sso.authenticationPolicyComponentBindings} bindings
 * @description
 *      Authentication Policy component.
 * @author Aravindh Nagarajan
 */
angular.module('avi/policies/sso').component('authenticationPolicy', {
    /**
     * @mixin authenticationPolicyComponentBindings
     * @memberOf module:avi/policies/sso
     * @property {module:avi/policies/sso.AuthenticationPolicyConfigItem} policy
     * @property {string} type - Type of SSO Policy.
     */
    bindings: {
        policy: '<',
        type: '<',
    },
    controller: AuthenticationPolicyController,
    templateUrl:
        `src/components/modals/sso-policy-modal/${componentName}/${componentName}.component.html`,
});

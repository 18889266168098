/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const roleFactory = (Item, roleService) => {
    /**
     * @class
     * @name Role
     * @extends module:avi/dataModel.Item
     * @desc Role item.
     */
    class Role extends Item {
        /**
         * Transforms default privileges data structure into tree structure.
         * See {@link RoleService#getRoleCategories}.
         * @param {Object[]} privileges
         */
        static transformData(privileges) {
            const privilegeHashMap = roleService.createPrivilegeHashMap(privileges);

            return roleService.processRoles(roleService.getRoleCategories(), privilegeHashMap);
        }

        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            config.privileges_ = Role.transformData(config.privileges);

            return config;
        }

        /**
         * Transforms permissions tree structure into server privileges data structure and
         * appends the privileges which are not exposed to UI but added through CLI to
         * the config privileges list in order to avoid resetting them.
         * @returns {Object}
         * @override
         */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            const {
                privileges: currentPrivileges,
                privileges_: updatedPrivileges,
            } = config;

            const updatedPrivilegesHash = roleService.flattenRoles(updatedPrivileges);
            const currentPrivilegesHash = {};

            if (currentPrivileges) {
                currentPrivileges.forEach(privilege => {
                    currentPrivilegesHash[privilege.resource] = privilege;
                });
            }

            const mergedPrivileges = {
                ...currentPrivilegesHash,
                ...updatedPrivilegesHash,
            };

            config.privileges = Object.values(mergedPrivileges);

            delete config.privileges_;

            return config;
        }

        /**
         * Returns privileges from config
         * @return {Object[]}
         */
        getPrivileges() {
            let config = this.getConfig();

            if (!config.privileges_) {
                config = this.beforeEdit();
            }

            return config.privileges_;
        }
    }

    Object.assign(Role.prototype, {
        objectName: 'role',
        windowElement: 'adm-role-create',
    });

    return Role;
};

roleFactory.$inject = [
    'Item',
    'roleService',
];

/**
 * @ngdoc service
 * @alias Role.
 */
angular.module('aviApp').factory('Role', roleFactory);
